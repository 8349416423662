import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import LockRoundedIcon from '@mui/icons-material/LockRounded';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { SxProps, Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { AuthContext, SettingsContext } from '@teto/react-component-library';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Permission, TimeCard } from 'teto-client-api';
import TableColumnDefinition from '../../../../components/TetoGrid/TableColumnDefinition';
import ActionButton from '../../../PayPeriodsPage/components/ActionButton';
import PunchInIcon from '../CustomCells/PunchInIcon';
import ColumnValueFormatter from './ColumnValueFormatter';

interface SubGroupingDetailsProps {
  data: TimeCard;
  columns: TableColumnDefinition[];
  columnlist: string[];
  // eslint-disable-next-line no-unused-vars
  onDeleteClick: (data: TimeCard) => void;
  // eslint-disable-next-line no-unused-vars
  onEditClick: (data: TimeCard) => void;
}

const rootSx: SxProps<Theme> = {
  width: 'calc(100% - 16px)',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  columGap: '1px',
  borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
  padding: (theme) => `${theme.spacing(0.5)} 8px !important`,
};

const columnSx: SxProps<Theme> = {
  flexGrow: 3,
  padding: (theme) => theme.spacing(0.5),
};
const itemSx: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '100%',
  '& span': {
    lineHeight: 'normal',
  },
};

const actionSx: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  height: '100%',
  flexGrow: 1,
  padding: (theme) => theme.spacing(0.5),
  width: (theme) => theme.spacing(5),
  maxWidth: '27%',
};

const SubGroupingDetails = (props: SubGroupingDetailsProps) => {
  const { columns, columnlist, data, onDeleteClick, onEditClick } = props;
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const { settings } = useContext(SettingsContext);

  const canDelete =
    authContext.hasAnyLicense() &&
    authContext.hasPermission(Permission.Delete_MyTimeTracker_Timecards);

  const cellElements = useMemo(
    () =>
      columnlist.map((e) => {
        const columnDef = columns.filter((g) => g.name === e)[0];
        let value = data[e as keyof TimeCard];
        value = value === undefined || value === null ? '-' : value;

        return (
          <Grid sx={itemSx} key={`${columnDef.title}${value}`}>
            <Typography
              noWrap
              sx={columnSx}
              align="left"
              variant="body2"
              component="span"
            >
              {columnDef.title}:
            </Typography>
            <ColumnValueFormatter
              value={value}
              column={columnDef}
              settings={settings}
            />
          </Grid>
        );
      }),
    [columnlist, columns, data, settings]
  );

  return (
    <Box sx={rootSx}>
      {/* columns */}
      <Box sx={columnSx}>{cellElements}</Box>
      {/* collapse Icon */}
      <Box sx={actionSx}>
        <PunchInIcon id={data?.timecardPunchInID || data.startTime} />
        <ActionButton
          actionItems={[
            ...(data.locked !== 0
              ? [
                  {
                    componentName: t('generic.info'),
                    icon: <LockRoundedIcon color="action" />,
                    handleClick: () => onEditClick(data),
                    title: t('forms.lockedTimecards'),
                  },
                ]
              : []),
            ...(data.locked === 0
              ? [
                  {
                    componentName: t('generic.edit'),
                    title: t('forms.editTimecardButton'),
                    icon: <EditIcon color="primary" />,
                    handleClick: () => onEditClick(data),
                  },
                  ...(canDelete
                    ? [
                        {
                          componentName: t('generic.delete'),
                          title: t('generic.deleteRecord'),
                          icon: <DeleteIcon color="error" />,
                          handleClick: () => onDeleteClick(data),
                          confirm: {
                            type: 'okCancel',
                            title: t('dialogs.deleteRecord.title'),
                            content: t('dialogs.deleteRecord.content'),
                            data,
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                          } as any,
                        },
                      ]
                    : []),
                ]
              : []),
          ]}
        />
      </Box>
    </Box>
  );
};

export default SubGroupingDetails;
