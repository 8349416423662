import { Box, Popover, Typography, useTheme } from '@mui/material';
import { LatLngExpression, Map } from 'leaflet';
import React from 'react';
import MapWrapper from '../MapWrapper';

interface MapPopoverProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dataArr: any[];
  map: Map | undefined;
  setMap: React.Dispatch<React.SetStateAction<Map | undefined>>;
  mapCoordinates: LatLngExpression | undefined;
  mapModalEl: HTMLButtonElement | null;
  mapWidth: number;
  mapHeight: number;
  setMapModalEl: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>;
  setIsEditing?: React.Dispatch<React.SetStateAction<boolean>>;
}

const MapPopover = (props: MapPopoverProps) => {
  const {
    dataArr,
    map,
    mapCoordinates,
    mapModalEl,
    mapWidth,
    mapHeight,
    setMap,
    setMapModalEl,
    setIsEditing,
  } = props;
  const theme = useTheme();

  return (
    <Popover
      open={Boolean(mapModalEl)}
      anchorEl={mapModalEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      onClose={() => {
        setMapModalEl(null);
        if (setIsEditing) setIsEditing(false);
      }}
      PaperProps={{
        sx: {
          padding: theme.spacing(1.5),
          paddingTop: theme.spacing(0.5),
        },
      }}
    >
      <Typography variant="subtitle1">Location</Typography>
      <Box
        sx={{
          width: `${mapWidth}px`,
          height: `${mapHeight}px`,
          marginTop: theme.spacing(1),
        }}
      >
        <MapWrapper
          setMap={setMap}
          data={dataArr}
          map={map}
          coords={mapCoordinates}
          zoom={12}
        />
      </Box>
    </Popover>
  );
};

export default MapPopover;
