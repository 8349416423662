import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import {
  IconButtonProps,
  Tooltip,
  TooltipProps,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { ETOIconButton, ModalNavBarItem } from '@teto/react-component-library';
import React, { ReactEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

interface ActionButtonProps {
  actionItems: ActionItem[];
}

interface ConfirmDialogButton {
  label: string;
  onClick: (
    // eslint-disable-next-line no-unused-vars
    e: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined,
    // eslint-disable-next-line no-unused-vars
    data: object | undefined
  ) => void;
}

interface ConfirmDialogProps {
  open: boolean;
  leftButton?: ConfirmDialogButton;
  rightButton?: ConfirmDialogButton;
  title: string;
  content: string | React.ReactNode;
  data?: object;
}

type CustomConfirmDialog = {
  type: 'custom';
} & Omit<ConfirmDialogProps, 'open'>;

type YesNoConfirmDialog = {
  type: 'yesNo';
  title: string;
  content: string | React.ReactNode;
  data?: object;
  onNo: (
    // eslint-disable-next-line no-unused-vars
    e: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined
  ) => void;
};

export type OkCancelConfirmDialog = {
  type: 'okCancel';
  title: string;
  content: string | React.ReactNode;
  data?: object;
};

export interface ActionItem {
  componentName: string;
  icon: React.ReactNode;
  title?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleClick: ReactEventHandler | any;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
  color?: 'grey' | 'success' | 'error' | 'primary' | 'secondary' | 'warning';
  confirm?: CustomConfirmDialog | YesNoConfirmDialog | OkCancelConfirmDialog;
  buttonProps?: Omit<IconButtonProps, 'color' | 'size'>;
}

const ActionButton = (props: ActionButtonProps) => {
  const { actionItems } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      {isMobile && actionItems.length > 0 && (
        <ModalNavBarItem
          icon={
            <Tooltip title={t('generic.mobileActions')} leaveTouchDelay={700}>
              <MoreHorizRoundedIcon />
            </Tooltip>
          }
          modalMenuItems={actionItems}
        />
      )}

      {!isMobile &&
        actionItems.map((item: ActionItem) => (
          <ETOIconButton
            key={item.componentName}
            color={item.color ?? 'primary'}
            onClick={item.handleClick}
            size="medium"
            confirm={item.confirm}
            disabled={item.disabled}
            type={item.type}
            buttonProps={item.buttonProps}
            tooltipProps={
              {
                title: item.title,
                placement: 'bottom-end',
              } as TooltipProps
            }
          >
            {item.icon}
          </ETOIconButton>
        ))}
    </>
  );
};

export default React.memo(ActionButton);
