import ReactDataGrid from '@inovua/reactdatagrid-enterprise';
import {
  TypeColumn,
  TypeComputedProps,
} from '@inovua/reactdatagrid-enterprise/types';
import { Box, Typography, useTheme } from '@mui/material';
import {
  AppearanceContext,
  ETOCheckBox,
  MessageContext,
  SettingsContext,
} from '@teto/react-component-library';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import * as React from 'react';
import { useQuery } from 'react-query';
import useStyles from '../../../../components/TetoGrid/GridStyles';
import { reactDataGridLicenseKey } from '../../../../components/TetoGrid/Licensing';
import { getGraphQLClient } from '../../../../helpers/graphQL/graphQLClient';

interface BOMDistributionProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

const container = {
  overflow: 'auto',
  maxHeight: '90%',
  px: 1,
  '& .MuiTypography-subtitle1': {
    mb: 2,
  },
};

const BOMDistribution = (props: BOMDistributionProps) => {
  const { detail } = props;
  const messageContext = React.useContext(MessageContext);
  const settingsContext = React.useContext(SettingsContext);
  const inventoryId = isEmpty(detail.inventoryDetails)
    ? 0
    : detail.inventoryDetails[0].inventory.inventoryLocationId;
  const variables = {
    projectId: detail?.projectId,
    specId: detail?.specId,
    itemId: detail?.itemId,
    inventoryLocationId: inventoryId,
    processScheduleId: detail?.processScheduleId,
  };

  const _doQuery = async () => {
    const query = `query engItemMasterDistributionByItem(
      $projectId: Int!
      $specId: Float!
      $itemId: Int!
      $inventoryLocationId: Int!
      $processScheduleId: Int
    ) {
      engItemMasterDistributionByItem(
        projectId: $projectId
        specId: $specId
        itemId: $itemId
        inventoryLocationId: $inventoryLocationId
        processScheduleId: $processScheduleId
        skip: 0
        take: 10
        order: { requiredDate: DESC }
      ) {
        items {
          parentItemCompanyId
          itemCompanyId
          itemNumber
          itemCutLength
          qty
          weight
          custDescription
          requiredDate
          notes
          partNumberHierarchy
          partOrderDetailCustom1
          partOrderDetailCustom2
          partOrderDetailCustom3
          partOrderDetailCustom4
          partOrderDetailCustom5
          partOrderDetailCustom6
          partOrderDetailCustom7
          partOrderDetailCustom8
        }
      }
    }
    `;

    const response = await getGraphQLClient().performQuery(
      query,
      variables,
      (err) => messageContext.setError(err.messages.join('\n ')),
      (err) => {
        // eslint-disable-next-line no-console
        console.log(JSON.stringify(err));
        // messageContext.setError(err.messages.join('\n '))
      }
    );

    return response;
  };

  const bomDistributionQuery = useQuery(
    'bomDistribution',
    async () => _doQuery(),
    { enabled: true }
  );
  const data =
    bomDistributionQuery?.data?.engItemMasterDistributionByItem?.items;

  const gridRef = React.useRef<TypeComputedProps | null>(null);
  const theme = useTheme();
  const appearanceContext = React.useContext(AppearanceContext);
  const scrollStyle = {
    scrollThumbStyle: { backgroundColor: theme.palette.primary.main },
  };

  const currentTheme =
    appearanceContext.themeVariant === 'dark'
      ? 'default-dark'
      : 'default-light';

  const dateRenderer = React.useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (d: any) => {
      if (!d) {
        return null;
      }

      return dayjs(d).format(settingsContext.settings.dateFormat);
    },
    [settingsContext.settings.dateFormat]
  );

  const checkboxRenderer = React.useCallback(
    (d: boolean) => <ETOCheckBox name="" value={d} disabled />,
    []
  );

  const columns: TypeColumn[] = React.useMemo(
    () => [
      {
        name: 'parentItemCompanyId',
        header: 'Parent Assy',
        sortable: false,
        groupBy: false,
        showColumnMenuTool: false,
        hideable: false,
      },
      {
        name: 'qty',
        header: 'Quantity',
        sortable: false,
        groupBy: false,
        showColumnMenuTool: false,
        hideable: false,
      },
      {
        name: 'itemNumber',
        header: 'Item #',
        sortable: false,
        groupBy: false,
        showColumnMenuTool: false,
        hideable: false,
      },
      {
        name: 'itemCutLength',
        header: 'Cut Length',
        sortable: false,
        groupBy: false,
        showColumnMenuTool: false,
        hideable: false,
      },
      {
        name: 'requiredDate',
        header: 'Req. Date',
        sortable: false,
        groupBy: false,
        showColumnMenuTool: false,
        hideable: false,
        render: ({ data: { requiredDate } }) => dateRenderer(requiredDate),
      },
      {
        name: 'notes',
        header: 'Note',
        sortable: false,
        groupBy: false,
        showColumnMenuTool: false,
        hideable: false,
      },
      {
        name: 'partOrderDetailCustom7',
        header: 'Or Equals',
        sortable: false,
        groupBy: false,
        showColumnMenuTool: false,
        hideable: false,
        type: 'boolean',
        textAlign: 'center',
        render: ({ data: { partOrderDetailCustom7 } }) =>
          checkboxRenderer(partOrderDetailCustom7),
      },
      {
        name: 'partOrderDetailCustom8',
        header: 'On Hold',
        sortable: false,
        groupBy: false,
        showColumnMenuTool: false,
        hideable: false,
        type: 'boolean',
        textAlign: 'center',
        render: ({ data: { partOrderDetailCustom8 } }) =>
          checkboxRenderer(partOrderDetailCustom8),
      },
      {
        name: 'partNumberHierarchy',
        header: 'Hierarchy',
        sortable: false,
        groupBy: false,
        showColumnMenuTool: false,
        hideable: false,
      },
    ],
    [checkboxRenderer, dateRenderer]
  );

  return (
    <Box sx={[container, useStyles]}>
      <Typography variant="subtitle1">BOM Distribution</Typography>
      {!isEmpty(data) && Array.isArray(data) && (
        <ReactDataGrid
          licenseKey={reactDataGridLicenseKey}
          columns={columns}
          dataSource={data}
          scrollProps={scrollStyle}
          handle={(r) => {
            gridRef.current = r ? r.current : null;
          }}
          theme={currentTheme}
          enableColumnAutosize
          style={{ minHeight: data.length > 1 ? 400 : '' }}
        />
      )}
    </Box>
  );
};

export default BOMDistribution;
