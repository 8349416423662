import Box from '@mui/material/Box';
import { SxProps, Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { SettingsContext } from '@teto/react-component-library';
import dayjs, { Dayjs } from 'dayjs';
import React, { useContext } from 'react';
import PunchInIcon from './PunchInIcon';

const rootCellSx: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  columnGap: (theme) => theme.spacing(1),
};

export interface PunchInAndDateCellProps {
  data: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PunchInAndDateCell = (props: PunchInAndDateCellProps) => {
  const { data } = props;
  const {
    settings: { dateFormat },
  } = useContext(SettingsContext);
  return (
    <Box sx={rootCellSx}>
      <PunchInIcon id={data?.timecardPunchInID || data.startTime} />
      <Typography>{dayjs(data.date as Dayjs).format(dateFormat)}</Typography>
    </Box>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PunchInAndDateHeader = ({ title }: any) => (
  <Box sx={{ marginLeft: (theme) => theme.spacing(5) }}>{title}</Box>
);

export { PunchInAndDateCell, PunchInAndDateHeader };
