import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';
import {
  Box,
  Button,
  CircularProgress,
  ClickAwayListener,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Popover,
  Popper,
  SxProps,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  AuthContext,
  ConfirmDialogs,
  ETOButton,
  ETOTextField,
  MessageContext,
} from '@teto/react-component-library';
import { debounce } from 'lodash';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Permission } from 'teto-client-api';
import { getGraphQLClient } from '../../../../helpers/graphQL/graphQLClient';
import purchaseOrderHeaderQuery from '../../queries/purchaseOrderHeaderQuery';
import searchForPOByFilterQuery from '../../queries/searchForPOByFilterQuery.ts';
import searchPurchaseOrderHeadersByPONumberQuery from '../../queries/searchPurchaseOrderHeadersByPONumberQuery';
import PONumber from '../PONumber';
import { PurchaseOrder, PurchaseOrderDetail } from '../PurchaseOrder';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface POSearchProps {
  isEditing?: boolean;
  customInputSx?: SxProps<Theme>;
  purchaseOrder?: PurchaseOrder;
  setPurchaseOrder: React.Dispatch<
    React.SetStateAction<PurchaseOrder | undefined>
  >;
  lastActivePO?: PurchaseOrder;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setReceivingValues: React.Dispatch<any>;
}

const inputRegex = /^[0-9\b]+$/;

const gridSx = (theme: Theme) => ({
  padding: theme.spacing(1),
  width: theme.spacing(40),
});

const searchContainer = (theme: Theme) => ({
  display: 'flex',
  alignItems: 'center',
  '& .MuiFormControl-fullWidth': {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  [theme.breakpoints.down('lg')]: {
    '& .MuiFormControl-fullWidth': {
      marginRight: theme.spacing(0),
    },
  },
});

const listBtnSx = (theme: Theme) => ({
  borderRadius: `${theme.shape.borderRadius}px`,
  padding: theme.spacing(1),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  '& .MuiListItemButton-root': {
    '&:hover': {
      background: 'none',
    },
  },
});

const listItemAltSx = (theme: Theme) => ({
  backgroundColor: theme.palette.background.default,
});

const okButtonSx = (theme: Theme) => ({
  minWidth: theme.spacing(6),
  padding: theme.spacing(0.5),
});

const POSearch = (props: POSearchProps) => {
  const { t } = useTranslation();
  const {
    customInputSx,
    isEditing,
    purchaseOrder,
    setPurchaseOrder,
    lastActivePO,
    setReceivingValues,
  } = props;

  const inputRef = useRef();
  const searchRef = useRef<HTMLInputElement>(null);

  const [searchByPO, setSearchByPO] = useState('');
  const [searchBySupplier, setSearchBySupplier] = useState('');
  const [searchByPartNumber, setSearchByPartNumber] = useState('');

  const [anchorEl, setAnchorEl] =
    React.useState<HTMLButtonElement | null>(null);
  const [inputAnchorEl, setInputAnchorEl] =
    useState<HTMLButtonElement | null>(null);

  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const [selectedPO, setSelectedPO] = useState<PurchaseOrder | undefined>();

  const [pOs, setPOs] = useState<PurchaseOrder[] | undefined>();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isSearchActive, setIsSearchActive] = useState<boolean>(false);

  const [selectTooltip, setSelectTooltip] = useState<boolean>(false);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const theme = useTheme();
  const isReceivingMobile = useMediaQuery(theme.breakpoints.down('lg'));

  const authContext = useContext(AuthContext);
  const messageContext = useContext(MessageContext);
  const canAccess = !authContext.hasPermission(
    Permission.View_Legacy_frmReceiving_Main
  );

  const _handleClear = () => {
    setSearchByPO('');
    setSelectedPO(undefined);
    setSearchBySupplier('');
    setSearchByPartNumber('');
    setSelectedIndex(-1);
    setIsSearchActive(false);
  };

  const _handleSearch = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (e.target.value === '' || inputRegex.test(e.target.value)) {
      setSelectedPO(undefined);
      setSearchByPO(e.target.value);
      setIsSearchActive(true);
      if (e.target.value !== '') debounceDoQuery(e.target.value);
      if (inputRef.current) setInputAnchorEl(inputRef.current);
      setIsLoading(true);
      setSelectTooltip(false);
    }
  };

  const _handleSelectPO = (item: PurchaseOrder) => {
    setSelectedIndex(item.id);
    setSelectedPO(item);
    setSearchByPO(item.id.toString());
    if (searchRef.current) searchRef?.current.focus();
  };

  const _doQuery = async (id: string) => {
    const query = searchPurchaseOrderHeadersByPONumberQuery;
    const variables = {
      searchTerm: id,
    };

    const result = await getGraphQLClient().performQuery(
      query,
      variables,
      (err) => {
        messageContext.setError(err?.messages.join('\n '));
      },
      (err) => {
        messageContext.setError(JSON.stringify(err));
      }
    );
    if (result != null) {
      setPOs(result.searchPurchaseOrderHeadersByPONumber.items);
      setSelectedPO(result.searchPurchaseOrderHeadersByPONumber.items[0]);
    }

    setIsLoading(false);
    return result;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceDoQuery = useCallback(debounce(_doQuery, 1000), []);

  const filterQuery = useQuery(
    [],
    () =>
      getGraphQLClient()
        .performQuery(
          searchForPOByFilterQuery,
          {
            supplierName: searchBySupplier,
            itemCompanyId: searchByPartNumber,
          },
          (err) => {
            messageContext.setError(err.messages.join('\n '));
          },
          (err) => {
            messageContext.setError(JSON.stringify(err));
          }
        )
        .then((d) => {
          setSearchByPartNumber('');
          setSearchBySupplier('');
          // ES6 filters out duplicate purchaseOrders
          const uniqueList = [
            ...new Map(
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              d.purchaseOrderDetails.items.map((item: any) => [
                item.purchaseOrder.id,
                item,
              ])
            ).values(),
          ];
          setPOs(
            uniqueList.map(
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (item: any) => item.purchaseOrder
            )
          );
        }),
    {
      enabled: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
    }
  );

  const _handleSearchByFilter = () => {
    filterQuery.refetch();
    setAnchorEl(null);
    setIsSearchActive(true);
    if (inputRef.current) setInputAnchorEl(inputRef.current);
  };

  const purchaseOrderQuery = useQuery(
    [],
    () =>
      getGraphQLClient()
        .performQuery(
          purchaseOrderHeaderQuery,
          {
            id: selectedPO?.id,
          },
          (err) => {
            messageContext.setError(err.messages[0]);
          },
          () => {
            // @TODO Handle Validation
          }
        )
        .then((res) => {
          const { purchaseOrderHeader } = res;
          setPurchaseOrder(purchaseOrderHeader);
        }),
    {
      enabled: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
    }
  );

  const _onOkClick = useCallback(() => {
    if (selectedPO) {
      purchaseOrderQuery.refetch();
    }

    setSearchByPO('');
    setIsSearchActive(false);
    setSearchBySupplier('');
    setSelectedPO(undefined);
    setPOs(undefined);
  }, [selectedPO, purchaseOrderQuery]);

  useEffect(() => {
    if (!purchaseOrder && lastActivePO) {
      _doQuery(lastActivePO.id.toString()).then((d) => {
        setSelectedPO(d.searchPurchaseOrderHeadersByPONumber.items[0]);
      });
      setSearchByPO(lastActivePO.id.toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastActivePO, purchaseOrder]);

  useEffect(() => {
    if (searchByPO === '') {
      setIsSearchActive(false);
    }
  }, [searchByPO]);

  return (
    <>
      <Box sx={searchContainer} ref={inputRef}>
        <ETOTextField
          name="searchByPO"
          label={t('Entities.Receiving.searchPONumber')}
          value={searchByPO}
          size="small"
          handleChange={(e) => _handleSearch(e)}
          disabled={canAccess}
          inputProps={{
            maxLength: 6,
            sx: [
              ...(Array.isArray(customInputSx)
                ? customInputSx
                : [customInputSx]),
            ],
          }}
          sxProps={{
            marginTop: theme.spacing(1),
          }}
          onFocus={() => {
            if (isEditing) {
              searchRef?.current?.blur();
              setIsDialogOpen(true);
            }
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && pOs?.length !== 0) {
              if (pOs && selectedPO) {
                _onOkClick();
                _handleClear();
                // Reason why: https://github.com/Microsoft/TypeScript/issues/5901
                (e.target as HTMLElement).blur();
              } else if (pOs && pOs.length >= 1) {
                setSelectedPO(pOs[0]);
              } else {
                setSelectTooltip(true);
              }
            }
            if (e.key === 'Backspace') {
              setSelectedPO(undefined);
              setSelectTooltip(false);
            }
          }}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          InputProps={{
            inputRef: searchRef,
            autoComplete: 'off',
            defaultValue: selectedPO?.pONumber,
            onTouchStart: () => {
              if (isEditing) {
                searchRef?.current?.blur();
                setIsDialogOpen(true);
              }
            },

            sx: {
              paddingRight: theme.spacing(1),
              paddingLeft: 0,
            },
            startAdornment: (
              <IconButton
                color="primary"
                size="large"
                onClick={(e) => {
                  setAnchorEl(e.currentTarget);
                }}
                disabled={
                  !authContext.hasPermission(
                    Permission.View_Legacy_frmReceiving_Main
                  )
                }
              >
                <FilterAltRoundedIcon color={anchorEl ? 'action' : 'primary'} />
              </IconButton>
            ),
            endAdornment: (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <IconButton
                  sx={{ visibility: searchByPO ? 'visible' : 'hidden' }}
                  onClick={() => _handleClear()}
                >
                  <ClearRoundedIcon />
                </IconButton>
                <ClickAwayListener onClickAway={() => setSelectTooltip(false)}>
                  <Box>
                    <Tooltip
                      PopperProps={{
                        disablePortal: true,
                      }}
                      title="Select a purchase order from the list below"
                      arrow
                      open={selectTooltip}
                      onClose={() => setSelectTooltip(false)}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                    >
                      <Button
                        data-testid="POSearchOk"
                        size="medium"
                        color="primary"
                        onClick={() => _onOkClick()}
                        onTouchStart={() => _onOkClick()}
                        disabled={!selectedPO}
                        variant="contained"
                        sx={okButtonSx}
                      >
                        {t('generic.ok')}
                      </Button>
                    </Tooltip>
                  </Box>
                </ClickAwayListener>
              </Box>
            ),
          }}
        />

        {purchaseOrder && !isReceivingMobile && (
          <PONumber
            id={purchaseOrder?.id}
            setPurchaseOrder={setPurchaseOrder}
            setReceivingValues={setReceivingValues}
          />
        )}
      </Box>

      {(searchByPO || isSearchActive) && (
        <ClickAwayListener
          onClickAway={() => {
            // questionable, revisit
            if (purchaseOrder) setSearchByPO('');
            if (lastActivePO && !purchaseOrder)
              setSearchByPO(lastActivePO?.id.toString());
            if (!purchaseOrder) setSelectedPO(lastActivePO);
            if (selectedPO) setSelectedPO(undefined);
            setIsSearchActive(false);
          }}
        >
          <Popper
            open={isSearchActive}
            anchorEl={inputAnchorEl}
            sx={{
              maxWidth: theme.spacing(60),
              width: 'calc(100% - 32px)',
              zIndex: 10,
              [theme.breakpoints.up('sm')]: {
                width: '100%',
              },
              boxShadow: theme.shadows[10],
              backgroundColor: theme.palette.background.paper,
              borderRadius: `${theme.shape.borderRadius}px`,
            }}
            placement="bottom-start"
            modifiers={[
              {
                name: 'offset',
                options: {
                  offset: [0, 8],
                },
              },
            ]}
            onResize={undefined}
            onResizeCapture={undefined}
          >
            <Box
              sx={{
                overflowY: 'auto',
              }}
              data-testid="input-popover"
            >
              {isLoading && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    padding: theme.spacing(1),
                  }}
                >
                  <CircularProgress />
                </Box>
              )}
              <List
                sx={{
                  maxHeight: theme.spacing(44),
                }}
                disablePadding
              >
                {!isLoading && pOs?.length === 0 ? (
                  <ListItem>
                    <Typography variant="h6">
                      {t('generic.message.noRecordsFound')}
                    </Typography>
                  </ListItem>
                ) : (
                  !isLoading &&
                  pOs?.map((item, ind) => (
                    <ListItem
                      disablePadding
                      sx={[ind % 2 === 0 && listItemAltSx]}
                      key={item?.id}
                    >
                      <ListItemButton
                        sx={listBtnSx}
                        selected={selectedIndex === item.id}
                        onClick={() => _handleSelectPO(item)}
                      >
                        <Typography variant="subtitle2">
                          {`${t('Entities.Receiving.pONumberAbrev')}: ${
                            item.id
                          }`}
                        </Typography>
                        <Typography variant="subtitle2">
                          {`${t('Entities.Receiving.supplier')}: ${
                            item.purchaseSupplier?.name
                          }`}
                        </Typography>
                        {item.purchaseOrderDetails && (
                          <List
                            sx={{
                              width: '100%',
                              overflowY: 'auto',
                              maxHeight: theme.spacing(25),
                            }}
                          >
                            {item.purchaseOrderDetails?.map(
                              (pOitem: PurchaseOrderDetail) => (
                                <ListItem
                                  disablePadding
                                  key={pOitem?.purchaseSupplierItem}
                                >
                                  <Typography variant="body2">
                                    {`${t(
                                      'Entities.Receiving.pOPartNumber'
                                    )}: ${pOitem.purchaseSupplierItem}`}
                                  </Typography>
                                </ListItem>
                              )
                            )}
                          </List>
                        )}
                      </ListItemButton>
                    </ListItem>
                  ))
                )}
              </List>
            </Box>
          </Popper>
        </ClickAwayListener>
      )}

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Grid container rowSpacing={2} sx={gridSx}>
          <Grid item xs={12}>
            <ETOTextField
              name="searchBySupplier"
              label={t('Entities.Receiving.supplier')}
              value={searchBySupplier}
              handleChange={(e) => {
                if (searchByPO) setSearchByPO('');
                if (selectedPO) setSelectedPO(undefined);
                setSearchBySupplier(e.target.value);
              }}
              InputProps={{
                autoComplete: 'off',
                sx: {
                  paddingRight: theme.spacing(1),
                  paddingLeft: 0,
                },
                endAdornment: (
                  <IconButton
                    sx={{ visibility: searchBySupplier ? 'visible' : 'hidden' }}
                    onClick={() => _handleClear()}
                  >
                    <ClearRoundedIcon />
                  </IconButton>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <ETOTextField
              name="searchByPartNumber"
              label={t('Entities.Receiving.poPartNumber')}
              value={searchByPartNumber}
              handleChange={(e) => {
                if (searchByPO) setSearchByPO('');
                if (selectedPO) setSelectedPO(undefined);
                setSearchByPartNumber(e.target.value);
              }}
              InputProps={{
                autoComplete: 'off',
                sx: {
                  paddingRight: theme.spacing(1),
                  paddingLeft: 0,
                },
                endAdornment: (
                  <IconButton
                    sx={{
                      visibility: searchByPartNumber ? 'visible' : 'hidden',
                    }}
                    onClick={() => _handleClear()}
                  >
                    <ClearRoundedIcon />
                  </IconButton>
                ),
              }}
            />
          </Grid>
        </Grid>
        <Box
          sx={{
            width: '100%',
            padding: theme.spacing(1),
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <ETOButton
            size="medium"
            color="primary"
            disabled={!searchBySupplier && !searchByPartNumber}
            onClick={() => _handleSearchByFilter()}
          >
            Filter Search
          </ETOButton>
        </Box>
      </Popover>
      {isDialogOpen && (
        <ConfirmDialogs
          open={isDialogOpen}
          title={t('dialogs.cancelActivePO.title')}
          content={t('dialogs.cancelActivePO.content')}
          leftButton={{
            label: 'No',
            onClick: () => setIsDialogOpen(false),
          }}
          rightButton={{
            label: 'Yes',
            onClick: () => {
              setReceivingValues(undefined);
              setPurchaseOrder(undefined);
              setIsDialogOpen(false);
            },
          }}
        />
      )}
    </>
  );
};

export default POSearch;
