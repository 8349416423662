import CancelRounded from '@mui/icons-material/CancelRounded';
import HourglassEmptyRoundedIcon from '@mui/icons-material/HourglassEmptyRounded';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import StopRoundedIcon from '@mui/icons-material/StopRounded';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import { Box, Divider, Theme, Typography, useTheme } from '@mui/material';
import {
  AuthContext,
  ConfirmDialogs,
  MessageContext,
  ModalNavBarItem,
  SettingsContext,
} from '@teto/react-component-library';
import dayjs from 'dayjs';
import React, { useCallback, useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Permission } from 'teto-client-api';
import ClockInTimerContext from './ClockInContext';
import PunchInTimerContext from './PunchInTimerContext';
import TimerDrawer, { ITimerDetailItem } from './TimerDrawer';

const activeClockInSx = (theme: Theme) => ({
  backgroundColor: theme.palette.success.light,
  color: theme.palette.common.white,
  width: 'fit-content',
  '& .MuiTypography-root': {
    color: 'inherit',
  },
  [theme.breakpoints.down('sm')]: {
    backgroundColor: 'inherit',
    color: theme.palette.success.main,
  },
});
const activeSx = (theme: Theme) => ({
  backgroundColor: theme.palette.success.main,
  color: theme.palette.common.white,
  width: 'fit-content',
  '& .MuiTypography-root': {
    color: 'inherit',
  },
  [theme.breakpoints.down('sm')]: {
    backgroundColor: 'inherit',
    color: theme.palette.success.main,
  },
});

const inactiveSx = (theme: Theme) => ({
  backgroundColor: theme.palette.grey[400],
  color: theme.palette.common.white,
  width: 'fit-content',
  '& .MuiTypography-root': {
    color: 'inherit',
  },
  [theme.breakpoints.down('sm')]: {
    backgroundColor: 'inherit',
    color: 'inherit',
  },
});

const timerButtonSx = {
  display: 'flex',
  alignItems: 'center',
};

const timerIconSx = (theme: Theme) => ({
  paddingRight: theme.spacing(0.5),
});
interface IconProps {
  icon: React.ReactNode;
  time?: string;
}

const IconWrapper = (props: IconProps) => {
  const { icon, time } = props;
  return (
    <Box sx={timerButtonSx}>
      <Box sx={timerIconSx}>{icon}</Box>
      <Typography variant="subtitle2" data-testid="navTimer">
        {time}
      </Typography>
    </Box>
  );
};

const NavTimer = () => {
  const punchInTimerContext = useContext(PunchInTimerContext);
  const clockInTimerContext = useContext(ClockInTimerContext);
  const authContext = useContext(AuthContext);
  const messageContext = useContext(MessageContext);
  const settingsContext = useContext(SettingsContext);
  const theme = useTheme();
  const [cancelTimer, setCancelTimer] =
    useState<'punchIn' | 'clockIn' | undefined>(undefined);
  const [cancelDialog, setCancelDialog] = useState<boolean>(false);
  const timerEl = useRef(null);
  const { status, durationFormatted } = punchInTimerContext;

  const { t } = useTranslation();

  const _renderTimerIcon = useCallback(() => {
    switch (status) {
      case 'inactive':
        if (clockInTimerContext.status === 'active') {
          return (
            <IconWrapper
              icon={<TimerOutlinedIcon />}
              time={clockInTimerContext.durationFormatted}
            />
          );
        }
        return (
          <IconWrapper icon={<TimerOutlinedIcon />} time={durationFormatted} />
        );

      case 'active':
        return (
          <IconWrapper
            icon={<HourglassEmptyRoundedIcon />}
            time={durationFormatted}
          />
        );
      default:
        return (
          <IconWrapper icon={<TimerOutlinedIcon />} time={durationFormatted} />
        );
    }
  }, [
    clockInTimerContext.durationFormatted,
    clockInTimerContext.status,
    durationFormatted,
    status,
  ]);

  const _setButtonColor = () => {
    switch (status) {
      case 'inactive':
        if (clockInTimerContext.status === 'active') {
          return activeClockInSx(theme);
        }
        return inactiveSx(theme);
      case 'active':
        return activeSx(theme);
      default:
        return inactiveSx(theme);
    }
  };
  const punchInDetails: ITimerDetailItem[] = [
    {
      title: t('Entities.Project.Project'),
      description: punchInTimerContext?.details?.projectName ?? '',
    },
    {
      title: t('Entities.Machine.Job'),
      description: punchInTimerContext?.details?.specName ?? '',
    },
    {
      title: t('Entities.HourType.HourType'),
      description: punchInTimerContext?.details?.hourTypeName ?? '',
    },
  ];

  const shouldRenderPunchInTimer = () => {
    const PunchInTimer = [
      { onlyCustomComponent: true, customComponent: <Divider /> },
      punchInTimerContext.status === 'active'
        ? {
            onlyCustomComponent: true,
            customComponent: (
              <TimerDrawer
                buttons={[
                  {
                    icon: <CancelRounded color="error" />,
                    disabled:
                      !authContext.hasEnterpriseLicense() ||
                      !authContext.hasAllPermissions([
                        Permission.Add_MyTimeTracker_Punchcards,
                        Permission.Modify_MyTimeTracker_Punchcards,
                      ]),
                    title: `${t('generic.abort')} ${t(
                      'Entities.PunchIn.PunchIn'
                    )}`,
                    onclick: () => {
                      setCancelTimer('punchIn');
                      setCancelDialog(true);
                    },
                  },
                  {
                    icon: <StopRoundedIcon color="error" />,
                    title: `${t('generic.stop')} ${t(
                      'Entities.PunchIn.PunchIn'
                    )}`,
                    onclick: () => {
                      punchInTimerContext.setInspector('stopTimer');
                    },
                  },
                ]}
                title={`${t('Entities.PunchIn.PunchIn')}`}
                startTime={dayjs(
                  punchInTimerContext?.details?.startTime
                ).format('H:mma')}
                duration={punchInTimerContext.durationFormatted as string}
                timerDetailItems={punchInDetails}
              />
            ),
          }
        : {
            onlyCustomComponent: true,
            customComponent: (
              <TimerDrawer
                buttons={[
                  {
                    icon: (
                      <PlayArrowRoundedIcon
                        sx={{ color: theme.palette.success.main }}
                      />
                    ),
                    title: `${t('generic.start')} ${t(
                      'Entities.PunchIn.PunchIn'
                    )}`,
                    disabled:
                      !authContext.hasEnterpriseLicense() ||
                      !authContext.hasPermission(
                        Permission.Add_MyTimeTracker_Punchcards
                      ),
                    onclick: () => {
                      punchInTimerContext.setInspector('startTimer');
                    },
                  },
                ]}
                title={`${t('Entities.PunchIn.PunchIn')}`}
                startTime="N/A"
                duration={punchInTimerContext.durationFormatted as string}
              />
            ),
          },
    ];
    if (
      authContext.hasEnterpriseLicense() &&
      authContext.hasPermission(Permission.Add_MyTimeTracker_Punchcards) &&
      authContext.hasPermission(Permission.Modify_MyTimeTracker_Punchcards)
    ) {
      return PunchInTimer;
    }
    return [];
  };

  const shouldRenderClockinTimer = () => [
    authContext.hasEnterpriseLicense() &&
      !authContext.disableClockIn &&
      (clockInTimerContext.status === 'active'
        ? {
            onlyCustomComponent: true,
            customComponent: (
              <TimerDrawer
                buttons={[
                  {
                    disabled:
                      punchInTimerContext.status !== 'inactive' ||
                      authContext.disableClockIn,
                    icon: (
                      <StopRoundedIcon
                        color={
                          punchInTimerContext.status === 'inactive'
                            ? 'error'
                            : 'disabled'
                        }
                      />
                    ),
                    title: `${t('Entities.ClockIns.clockOut')}`,
                    onclick: () => {
                      clockInTimerContext.stopMyClockIn();
                    },
                  },
                ]}
                title={`${t('Entities.ClockIns.clockIn')}`}
                startTime={dayjs(clockInTimerContext.startTime).format(
                  settingsContext.settings.timeFormat
                )}
                duration={clockInTimerContext.durationFormatted as string}
                timerDetailItems={[
                  {
                    title: 'Start Time',
                    description: dayjs(clockInTimerContext.startTime)
                      .format(settingsContext.settings.timeFormat)
                      .toString(),
                  },
                ]}
              />
            ),
          }
        : {
            onlyCustomComponent: true,
            customComponent: (
              <TimerDrawer
                buttons={[
                  {
                    icon: (
                      <PlayArrowRoundedIcon
                        sx={{ color: theme.palette.success.main }}
                      />
                    ),
                    title: `${t('generic.start')} ${t(
                      'Entities.ClockIns.clockIn'
                    )}`,
                    disabled:
                      authContext.disableClockIn ||
                      !authContext.hasEnterpriseLicense() ||
                      !authContext.hasAnyPermission([
                        Permission.Add_MyTimeTracker_Punchcards,
                        Permission.Add_MyTimeTracker_Timecards,
                      ]),
                    onclick: () => clockInTimerContext.startMyClockIn(),
                  },
                ]}
                title={`${t('Entities.ClockIns.clockIn')}`}
                startTime="N/A"
                duration={clockInTimerContext.durationFormatted as string}
              />
            ),
          }),
  ];

  const _setModalItems = () =>
    authContext.disableClockIn
      ? shouldRenderPunchInTimer()
      : [...shouldRenderClockinTimer(), ...shouldRenderPunchInTimer()];

  const _onCancelPunchInTimer = (id: number) => {
    punchInTimerContext
      .cancelMyPunchIn(id)
      .then(() => {
        punchInTimerContext.refreshTimer();
        punchInTimerContext.setInspector('closed');
        messageContext.setSuccess(t('Entities.PunchIn.abortTimerSuccess'));
        setCancelDialog(false);
      })
      .catch(() => {
        messageContext.setError(t('Entities.PunchIn.abortTimerError'));
      });
  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {authContext.hasEnterpriseLicense() && (
        <>
          <Box ref={timerEl}>
            <ModalNavBarItem
              buttonStyle={_setButtonColor()}
              componentName="Timer"
              modalMenuItems={_setModalItems()}
              icon={_renderTimerIcon()}
            />
          </Box>

          {cancelDialog && (
            <ConfirmDialogs
              open={cancelDialog}
              title={t('dialogs.abortTimer.title')}
              content={t('dialogs.abortTimer.content')}
              leftButton={{
                onClick: () => setCancelDialog(false),
                label: t('generic.cancel'),
              }}
              rightButton={{
                // eslint-disable-next-line consistent-return
                onClick: () => {
                  switch (cancelTimer) {
                    case 'punchIn':
                      _onCancelPunchInTimer(
                        punchInTimerContext.activeTimerId as number
                      );
                      break;
                    default:
                      return setCancelDialog(false);
                  }
                },
                label: t('generic.confirm'),
              }}
            />
          )}
        </>
      )}
    </>
  );
};

export default NavTimer;
