/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/prefer-default-export */
import { formatHoursAsHourMin } from '@teto/react-component-library';
import dayjs from 'dayjs';
import { TFunction } from 'react-i18next';
import TableColumnDefinition from '../TableColumnDefinition';

interface DateTimeFormats {
  dateFormat: string;
  timeFormat: string;
  dateTimeFormat: string;
}

const _boolGroupNameFormatter = (
  t: TFunction<'translation', undefined>,
  v: any
) => {
  if (v === true) {
    return t('generic.on');
  }
  if (v === false) {
    return t('generic.off');
  }
  return t('generic.notSet');
};

export const groupNameFormatter = (
  dateFormats: DateTimeFormats,
  t: TFunction<'translation', undefined>,
  col: TableColumnDefinition,
  value: any
) => {
  // Too specific, stop gap for release, revisit
  if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
    if (col.name === 'spec') {
      return `Job: ${value.displayName}`;
    }
  }

  switch (col.type) {
    case 'boolean':
      return `${col.title}: ${_boolGroupNameFormatter(t, value)}`;
    case 'date':
      return dayjs(value)?.format(dateFormats.dateFormat);
    case 'datetime':
      return dayjs(value)?.format(dateFormats.dateTimeFormat);
    case 'time':
      return dayjs(value)?.format(dateFormats.timeFormat);
    case 'hours':
      return formatHoursAsHourMin(value);
    case 'number':
      return `${col.title}: ${value}`;
    default:
      if (!value) {
        return '(not set)';
      }
      return value;
  }
};
