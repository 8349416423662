import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Theme } from '@mui/material';

interface TimesheetViewBySelectorProps {
  setViewBy?: React.Dispatch<React.SetStateAction<string>>;
  viewBy?: string;
}

const selectSx = (theme: Theme) => ({
  minWidth: theme.spacing(24),
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(1),
});

const TimesheetViewBySelector = (props: TimesheetViewBySelectorProps) => {
  const { viewBy, setViewBy } = props;

  const selectValues = {
    label: 'View Timesheet',
    items: [
      { key: 'week', label: 'By Week' },
      { key: 'month', label: 'By Month' },
    ],
  };

  const { items, label } = selectValues;

  const _handleChange = (event: SelectChangeEvent) => {
    if (setViewBy) {
      setViewBy(event.target.value as string);
    }
  };

  return (
    <Box>
      <FormControl sx={selectSx} size="small" fullWidth>
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select
          labelId="viewByLabelId"
          id="viewBySelect"
          value={viewBy}
          label={label}
          onChange={_handleChange}
        >
          {items.map((item) => (
            <MenuItem key={item.key} value={item.key}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default React.memo(TimesheetViewBySelector);
