import { contains, equalsNumber } from 'teto-client-api';
import MapFilters from './MapFilters';

const getMapStringFilterValue = (filterValue: string) => {
  if (!filterValue) {
    return null;
  }

  return contains(filterValue);
};

const getMapNumberFilterValue = (filterValue: number) => {
  if (!filterValue) {
    return null;
  }

  return equalsNumber(filterValue);
};

const getMapBooleanFilterValue = (filterValue: boolean) => {
  if (!filterValue) {
    return null;
  }

  return filterValue;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getMapFilterValue = (filterValue: any) => {
  switch (typeof filterValue) {
    case 'string':
      return getMapStringFilterValue(filterValue);
    case 'number':
      return getMapNumberFilterValue(filterValue);
    case 'boolean':
      return getMapBooleanFilterValue(filterValue);
    default:
      return getMapStringFilterValue(filterValue);
  }
};

// eslint-disable-next-line import/prefer-default-export
export const buildMapFilterQuery = (filterValue: MapFilters | undefined) => {
  if (!filterValue) return undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const output: { [key: string]: any } = {};

  Object.entries(filterValue).forEach((f) => {
    output[f[0]] = getMapFilterValue(f[1]);
  });

  return output;
};
