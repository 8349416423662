import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import {
  ETOCheckBox,
  ETOTextField,
  MessageContext,
  SettingsContext,
} from '@teto/react-component-library';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import * as React from 'react';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import { getGraphQLClient } from '../../../../helpers/graphQL/graphQLClient';

interface PartDetailsProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

const container = {
  overflow: 'auto',
  maxHeight: '90%',
  px: 1,
  '& .MuiTypography-subtitle1': {
    mb: 2,
  },
  '& .MuiTextField-root': {
    width: '100%',
    mb: 2,
  },
  '& .MuiDivider-root': {
    mb: 2,
  },
  '& .MuiGrid-container': {
    mb: 2,
  },
  '& .MuiTableContainer-root': {
    mb: 2,
  },
};

const PartDetails = (props: PartDetailsProps) => {
  const { detail } = props;
  const messageContext = React.useContext(MessageContext);
  const {
    settings: { dateFormat },
  } = useContext(SettingsContext);
  const inventoryId = isEmpty(detail.inventoryDetails)
    ? 0
    : detail.inventoryDetails[0].inventory.inventoryLocationId;
  const variables = {
    projectId: detail?.projectId,
    specId: detail?.specId,
    itemId: detail?.itemId,
    inventoryLocationId: inventoryId,
    destinationLocationId: detail?.destInventoryLocId,
    processScheduleId: detail?.processScheduleId,
  };

  const _doQuery = async () => {
    const query = `query purchasingItemDetailsSummaryByItem(
      $projectId: Int!
      $specId: Float!
      $itemId: Int!
      $inventoryLocationId: Int!
      $destinationLocationId: Int
      $processScheduleId: Int
    ) {
      purchasingItemDetailsSummaryByItem(
        projectId: $projectId
        specId: $specId
        itemId: $itemId
        inventoryLocationId: $inventoryLocationId
        destinationLocationId: $destinationLocationId
        processScheduleId: $processScheduleId
        skip: 0
        take: 10
        order: { itemId: DESC }
      ) {
        items {
          projectId
          specId
          itemId
          itemCompanyId
          itemDescription
          itemUOM
          itemCategory
          itemRevNumber
          itemWeight
          itemLastCost
          itemListCost
          preferredSupplierCompanyId
          lastSupplierCompanyId
          supplierPartNumber
          manufacturer
          manufacturerPartNumber
          estimatedLeadTime
          hasDrawing
          drawing
          multipleDrawing
          multipleDrawingAddress
          rawMaterialItemID
          rawMaterialCutLength
          itemRepairable
          itemRelub
          itemMaintenance
          itemCertifiedPrints
          itemReserved
          obsolete
          partCustom1
          partCustom2
          partCustom3
          partCustom4
          partCustom5
          partCustom6
          partCustom7
          partCustom8
          processId
          countryOfOrigin
          tariffCode
          processScheduleTemplateId
          itemCategoryDescription
          uOMDescription
          locationName
          destInventoryLoc
          onHand
          rFQs
          bOM
          nonBOM
          procured
          inventory
          processScheduled
          completedPSQty
          openWorkOrders
          orderToInventory
          qtyRequiredForAllProjects
          pulledQty
          reservedQty
          receivedQty
          unreceivedQty
          openQty
          availableQty
          outstandingQty
          required
          processSchedule
          preferredSupplier
          mfgBegin
        }
      }
    }
    `;

    const response = await getGraphQLClient().performQuery(
      query,
      variables,
      (err) => messageContext.setError(err.messages.join('\n ')),
      (err) => {
        // eslint-disable-next-line no-console
        console.log(JSON.stringify(err));
        // messageContext.setError(err.messages.join('\n '))
      }
    );

    return response;
  };

  const partDetailsQuery = useQuery('partDetails', async () => _doQuery(), {
    enabled: true,
  });
  const data =
    partDetailsQuery?.data?.purchasingItemDetailsSummaryByItem?.items?.[0];

  return (
    <Box sx={container} component="form">
      <Typography variant="subtitle1">
        Part Details: <strong>{detail.purchaseSupplierItem}</strong>
      </Typography>
      <ETOTextField
        name="part-description"
        label="PO Part Description"
        multiline
        maxRows={4}
        minRows={4}
        value={data?.itemDescription ?? ''}
        disabled
        inputLabelProps={{ shrink: true }}
      />
      <ETOTextField
        name="uom"
        label="UOM"
        value={data?.uOMDescription ?? ''}
        disabled
        inputLabelProps={{ shrink: true }}
      />
      <ETOTextField
        name="category"
        label="Category"
        value={data?.itemCategoryDescription ?? ''}
        disabled
        inputLabelProps={{ shrink: true }}
      />
      <ETOTextField
        name="last-cost"
        label="Last Cost"
        value={data?.itemLastCost ?? ''}
        disabled
        inputLabelProps={{ shrink: true }}
      />
      <ETOTextField
        name="list-price"
        label="List Price"
        value={data?.itemListCost ?? ''}
        disabled
        inputLabelProps={{ shrink: true }}
      />
      <ETOTextField
        name="preferred-supplier"
        label="Preferred Supplier"
        value={data?.preferredSupplier ?? ''}
        disabled
        inputLabelProps={{ shrink: true }}
      />
      <ETOTextField
        name="mfg-begin-date"
        label="Mfg. Begin Date"
        value={data?.mfgBegin ? dayjs(data?.mfgBegin).format(dateFormat) : ''}
        disabled
        inputLabelProps={{ shrink: true }}
      />
      <ETOTextField
        name="supplier-part-number"
        label="Supplier Part Number"
        value={data?.supplierPartNumber ?? ''}
        disabled
        inputLabelProps={{ shrink: true }}
      />
      <ETOTextField
        name="manufacturer"
        label="Manufacturer"
        value={data?.manufacturer ?? ''}
        disabled
        inputLabelProps={{ shrink: true }}
      />
      <ETOTextField
        name="manufacturer-part-number"
        label="Manufacturer Part Number"
        value={data?.manufacturerPartNumber ?? ''}
        disabled
        inputLabelProps={{ shrink: true }}
      />
      <ETOTextField
        name="weight"
        label="Weight"
        value={data?.itemWeight ?? ''}
        disabled
        inputLabelProps={{ shrink: true }}
      />
      <ETOTextField
        name="estimated-lead-time"
        label="Estimated Lead TIme"
        value={data?.estimatedLeadTime ?? ''}
        disabled
        inputLabelProps={{ shrink: true }}
      />
      <ETOTextField
        name="country-of-origin"
        label="Country of Origin"
        value={data?.countryOfOrigin ?? ''}
        disabled
        inputLabelProps={{ shrink: true }}
      />
      <ETOTextField
        name="tariff-code"
        label="Tariff Code"
        value={data?.tariffCode ?? ''}
        disabled
        inputLabelProps={{ shrink: true }}
      />
      <ETOTextField
        name="raw-material"
        label="Raw Material"
        value={data?.rawMaterialItemID ?? ''}
        disabled
        inputLabelProps={{ shrink: true }}
      />
      <ETOTextField
        name="raw-mat-amount"
        label="Raw Mat. Amount"
        value={data?.rawMaterialCutLength ?? ''}
        disabled
        inputLabelProps={{ shrink: true }}
      />
      <ETOTextField
        name="drawing"
        label="Drawing"
        value={data?.drawing ?? ''}
        disabled
        inputLabelProps={{ shrink: true }}
      />
      {/* 
        To be included in bug fix https://dev.azure.com/TotalETO/Total%20ETO%20Web/_queries/edit/73248/?triage=true
        <ETOTextField
        name="rfq"
        label="RFQ"
        value={!data?.rFQs ? '' : data?.rFQs}
        disabled
        inputLabelProps={{ shrink: true }}
      /> */}
      <Box>
        <ETOCheckBox
          name="maintenance"
          label="Maintenance"
          value={data?.itemMaintenance ?? false}
          disabled
        />
      </Box>
      <Box>
        <ETOCheckBox
          name="certified-print"
          label="Certified Print"
          value={data?.itemCertifiedPrints ?? false}
          disabled
        />
      </Box>
      <Box>
        <ETOCheckBox
          name="relubricatable"
          label="Relubricatable"
          value={data?.itemRelub ?? false}
          disabled
        />
      </Box>
      <Box>
        <ETOCheckBox
          name="obsolete"
          label="Obsolete"
          value={data?.obsolete ?? false}
          disabled
        />
      </Box>
      <Box>
        <ETOCheckBox
          name="locked"
          label="Locked"
          value={data?.itemReserved ?? false}
          disabled
        />
      </Box>
      <Box>
        <ETOCheckBox
          name="default-spare"
          label="Default Spare"
          value={data?.itemRepairable ?? false}
          disabled
        />
      </Box>
    </Box>
  );
};

export default PartDetails;
