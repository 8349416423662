/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Autocomplete } from '@mui/material';
import List from '@mui/material/List';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import {
  AppearanceContext,
  ETOButton,
  ETOCheckBox,
  MessageContext,
  SettingsContext,
} from '@teto/react-component-library';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UpdateEmployeeSettings } from 'teto-client-api';
import useLocalStorage from 'use-local-storage';
import { getChosenEmployeeSettings } from '../../../helpers/settingsHelpers';
import useCommonStyles from './commonStyles';
import {
  dateFormats,
  timeFormats,
  timePreciseFormats,
} from './datetimeFormats';
import TabItem from './TabItem';

const defaultDate = dayjs('2022-07-01 5:04:16 PM');
const timeFormatPreciseStrings = ['hh:mm:ss A'];
const settingsText =
  'Changing settings here will override default system settings.';

const timeFormatPreciseValues = timeFormatPreciseStrings.map((i) => ({
  value: i,
  display: `${defaultDate.format(i)} (${i})`,
}));

const UserTab = () => {
  const settingsContext = useContext(SettingsContext);
  const appearanceContext = useContext(AppearanceContext);
  const messageContext = useContext(MessageContext);
  const { t } = useTranslation();
  const theme = useTheme();
  const commonClasses = useCommonStyles(theme);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isDarkTheme, setIsDarkTheme] = useLocalStorage('dark', false);
  const [inheritSetting, setInheritSetting] = useLocalStorage(
    'inheritSetting',
    true
  );

  const [dateSetting, setDateSetting] = useState<string | null>(null);
  const [timeSetting, setTimeSetting] = useState<string | null>(null);
  const [timeFormatPreciseSetting, setTimeFormatPreciseSetting] =
    useState<string | null>(null);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const _changeTheme = (dark: boolean) => {
    setIsDarkTheme(dark);
    appearanceContext.setThemeVariant(dark ? 'dark' : 'light');
  };

  const userSetting = inheritSetting
    ? {
        webTimeFormatPrecise: null,
        webTimeFormat: null,
        webDateFormat: null,
      }
    : {
        webTimeFormatPrecise: timeFormatPreciseSetting,
        webTimeFormat: timeSetting,
        webDateFormat: dateSetting,
      };

  const _handleSettingsSave = () => {
    settingsContext
      .updateEmployeeSettings(userSetting as UpdateEmployeeSettings)
      .then(() => {
        messageContext.setSuccess(t('generic.message.settingsUpdate'));
      })
      .catch((e) => {
        messageContext.setError(e.message);
      });
  };

  useEffect(() => {
    getChosenEmployeeSettings(inheritSetting)
      .then((settings) => {
        setDateSetting(settings.webDateFormat);
        setTimeSetting(settings.webTimeFormat);
        setTimeFormatPreciseSetting(settings.webTimeFormatPrecise);
      })
      .catch((e) => {
        messageContext.setError(e.message);
      });
  }, [
    inheritSetting,
    messageContext,
    settingsContext.settings.dateFormat,
    settingsContext.settings.timeFormat,
    settingsContext.settings.timeFormatPrecise,
  ]);

  return (
    <>
      <Typography sx={commonClasses.sectionTitle} variant="h6">
        {t('generic.format')}
      </Typography>
      <Typography variant="subtitle1">{settingsText}</Typography>
      <List>
        <TabItem
          settingsName={t('generic.inheritSystemSettings')}
          secondary={
            <ETOCheckBox
              name={t('generic.inheritSystemSettingName')}
              label=""
              value={inheritSetting}
              handleChange={() => setInheritSetting((d) => !d)}
              size="medium"
            />
          }
        />
        <TabItem
          settingsName={t('generic.dateFormat')}
          secondary={
            <Autocomplete
              id="date-format-selection"
              options={dateFormats}
              disabled={inheritSetting}
              style={{ width: 300 }}
              disableClearable
              onChange={(_e, val) => setDateSetting(val)}
              value={dateSetting as string}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    onKeyDown(event) {
                      event.preventDefault();
                    },
                    onChange(event) {
                      setDateSetting((event.target as any).value);
                      return (params.inputProps as any).onChange(event);
                    },
                  }}
                />
              )}
            />
          }
        />
        <TabItem
          settingsName={t('generic.timeFormat')}
          secondary={
            <Autocomplete
              id="time-format-selection"
              options={timeFormats}
              disabled={inheritSetting}
              style={{ width: 300 }}
              disableClearable
              onChange={(_e, val) => setTimeSetting(val)}
              value={timeSetting as string}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    onKeyDown(event) {
                      event.preventDefault();
                    },
                    onChange(event) {
                      setTimeSetting((event.target as any).value);
                      return (params.inputProps as any).onChange(event);
                    },
                  }}
                />
              )}
            />
          }
        />

        <TabItem
          settingsName={t('generic.preciseTimeFormat')}
          secondary={
            <Autocomplete
              id="timeprecise-format-selection"
              options={timePreciseFormats}
              disabled={inheritSetting}
              style={{ width: 300 }}
              disableClearable
              onChange={(_e, val) => setTimeFormatPreciseSetting(val)}
              value={timeFormatPreciseSetting as string}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    onKeyDown(event) {
                      event.preventDefault();
                    },
                    onChange(event) {
                      setTimeFormatPreciseSetting((event.target as any).value);
                      return (params.inputProps as any).onChange(event);
                    },
                  }}
                />
              )}
            />
          }
        />
      </List>

      <ETOButton
        color="primary"
        size="medium"
        onClick={() => _handleSettingsSave()}
        customSx={commonClasses.saveBtn}
      >
        {t('generic.save')}
      </ETOButton>
    </>
  );
};

export default UserTab;
