import {
  TypeFilterValue,
  TypeGroupBy,
  TypeSingleSortInfo,
  TypeSortInfo,
} from '@inovua/reactdatagrid-community/types';
import GridOnRoundedIcon from '@mui/icons-material/GridOnRounded';
import ViewColumnRoundedIcon from '@mui/icons-material/ViewColumnRounded';
import { Tab, Tabs, Theme, useMediaQuery, useTheme } from '@mui/material';
import { ConfirmDialogs } from '@teto/react-component-library';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Inspector from '../../TimeSheets/TimeCardInspector/Inspector';
import GridSettings from '../GridSettings';
import TableColumnDefinition from '../TableColumnDefinition';
import ConfigureColumnsPanel from './ConfigureColumnsPanel';
import { ConfigureTab } from './ConfigureTab';
import GridOptionsPanel from './GridOptionsPanel/GridOptionsPanel';
import { GridOptionType } from './GridOptionType';
import TabPanel from './TabPanel';

const configInspectorSx = (theme: Theme) => ({
  marginRight: theme.spacing(-2),
});

const tabsSx = (theme: Theme) => ({
  marginBottom: theme.spacing(2),
  minHeight: theme.spacing(8),
  // flexGrow: 1,
});

const tabSx = (theme: Theme) => ({
  '& .MuiSvgIcon-root': {
    marginBottom: `${theme.spacing(0)} !important`,
    flexGrow: 0,
  },
});

const panelSx = {
  flexGrow: 1,
  maxHeight: '100%',
  display: 'flex',
  flexDirection: 'column',
  // paddingTop: (theme: Theme) => `${theme.spacing(1)}`,
};

/* const buttonStripContainer = {
  display: 'block',
  width: '100%',
  position: 'relative',
}; */

interface ConfigureInspectorProps {
  columns: TableColumnDefinition[];
  defaultTab?: { tab: string; option?: string };
  filters?: TypeFilterValue;
  grouping?: TypeGroupBy;
  initialColumnOrder: string[];
  mobileGridOptions?: boolean;
  open: boolean;
  sorting?: TypeSingleSortInfo[];
  tabs?: ConfigureTab[];
  handleClose: () => void;
  // eslint-disable-next-line no-unused-vars
  onColumnSettingsSave: (data: GridSettings) => void;
  onReset: () => void;
  setConfigureInspector: React.Dispatch<React.SetStateAction<boolean>>;
  // eslint-disable-next-line no-unused-vars
  setFilters?: (filter: TypeFilterValue) => void;
  // eslint-disable-next-line no-unused-vars
  setGrouping?: (grouping: TypeGroupBy) => void;
  // eslint-disable-next-line no-unused-vars
  setSorting?: (sorting: TypeSortInfo) => void;
}

const ConfigureInspector = (props: ConfigureInspectorProps) => {
  const {
    columns,
    defaultTab,
    filters,
    grouping,
    initialColumnOrder,
    mobileGridOptions,
    open,
    sorting,
    tabs,
    handleClose,
    onColumnSettingsSave,
    onReset,
    setConfigureInspector,
    setFilters,
    setGrouping,
    setSorting,
  } = props;

  const { t, ready } = useTranslation(['dialog', 'generic']);

  const [value, setValue] = useState<number>(0);
  const [resetDialog, setResetDialog] = useState<boolean>(false);
  const [defaultOptionSelected, setDefaultOptionSelected] =
    useState<GridOptionType | undefined>();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  const activeTabs: ConfigureTab[] = useMemo(
    () =>
      [
        {
          tabIcon: <ViewColumnRoundedIcon />,
          tabLabel: t('generic.columnSettings'),
          tabIndex: 0,
          tabPanel: (
            <ConfigureColumnsPanel
              {...{
                columns,
                initialColumnOrder,
                value,
                setConfigureInspector,
                setResetDialog,
                onSave: (data: GridSettings) => onColumnSettingsSave(data),
              }}
            />
          ),
        },
        ...(mobileGridOptions
          ? [
              {
                tabIcon: <GridOnRoundedIcon />,
                tabLabel: t('generic.gridOptions'),
                tabIndex: 1,
                tabPanel: (
                  <GridOptionsPanel
                    {...{
                      columns,
                      filters,
                      grouping,
                      sorting,
                      value,
                      handleClose,
                      setFilters,
                      setGrouping,
                      setSorting,
                      defaultSelect: defaultOptionSelected,
                      tabIndex: 1,
                    }}
                  />
                ),
              },
            ]
          : []),
        ...(tabs || []),
      ].map((e, n) => ({ ...e, tabIndex: n, setConfigureInspector })),
    [
      columns,
      defaultOptionSelected,
      filters,
      grouping,
      handleClose,
      initialColumnOrder,
      mobileGridOptions,
      onColumnSettingsSave,
      setConfigureInspector,
      setFilters,
      setGrouping,
      setSorting,
      sorting,
      t,
      tabs,
      value,
    ]
  );

  useEffect(() => {
    if (defaultTab) {
      const index = activeTabs.filter(
        (e) => e.tabLabel.indexOf(defaultTab.tab) >= 0
      )[0].tabIndex;
      setValue(index);
      if (
        defaultTab?.option &&
        ['filter', 'sort', 'group'].indexOf(defaultTab?.option) >= 0
      )
        setDefaultOptionSelected(defaultTab?.option as GridOptionType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultTab]);

  useEffect(() => {
    if (value > activeTabs.length) setValue(activeTabs.length);
  }, [activeTabs.length, value, isMobile]);

  const handleChange = (e: React.ChangeEvent<unknown>, newValue: number) => {
    setValue(newValue);
  };

  const _handleReset = () => {
    onReset();
    handleClose();
  };

  return (
    <>
      {ready && (
        <Inspector
          title={t('generic.configureTable')}
          open={open}
          onClose={handleClose}
          customSx={configInspectorSx}
        >
          <Tabs
            sx={tabsSx}
            indicatorColor="primary"
            textColor="primary"
            value={value}
            onChange={handleChange}
            variant="scrollable"
          >
            {activeTabs?.map((tb: ConfigureTab) => (
              <Tab
                sx={tabSx}
                icon={tb.tabIcon}
                label={tb.tabLabel}
                key={tb.tabIndex}
              />
            ))}
          </Tabs>

          {activeTabs?.map((tb: ConfigureTab) => (
            <TabPanel
              customSx={panelSx}
              value={value}
              index={tb.tabIndex}
              key={tb.tabLabel}
            >
              {tb.tabPanel}
              {/* {React.cloneElement(tb.tabPanel, { setConfigureInspector })} */}
            </TabPanel>
          ))}
        </Inspector>
      )}

      {resetDialog && (
        <ConfirmDialogs
          open={resetDialog}
          title={t('dialogs.resetGrid.title')}
          content={t('dialogs.resetGrid.content')}
          leftButton={{
            onClick: () => setResetDialog(false),
            label: t('generic.cancel'),
          }}
          rightButton={{
            onClick: () => {
              _handleReset();
              setResetDialog(false);
            },
            label: t('generic.confirm'),
          }}
        />
      )}
    </>
  );
};

export default ConfigureInspector;
