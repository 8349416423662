import React from 'react';
import { useTranslation } from 'react-i18next';
import { AuthenticatedUser, getMyPunchIns } from 'teto-client-api';
import TimerForm from './TimerForm';
import TimerFormProps from './TimerFormProps';

interface StopTimerFormProps
  extends Omit<TimerFormProps, 'editMode' | 'onCancel'> {
  id: number;
  employee: AuthenticatedUser;
  // eslint-disable-next-line no-unused-vars
  onError: (err: string | Error) => void;
  // eslint-disable-next-line no-unused-vars
  onCancel: () => void;
  // eslint-disable-next-line no-unused-vars
  setFormDirty?: (val: boolean) => void;
}

const StopTimerForm = (props: StopTimerFormProps) => {
  const {
    allowEmployeeSelection,
    getHourTypes,
    getJobs,
    getNonConformances,
    getProcessSchedules,
    id,
    fieldCaptions,
    fieldsDisableEditable,
    getProjects,
    initialValues,
    onCancel,
    onSubmit,
    qrEnabled,
    employee,
    onError,
    canUseTimer,
    setFormDirty,
  } = props;
  const { t } = useTranslation();

  return (
    <TimerForm
      setFormDirty={setFormDirty}
      canUseTimer={canUseTimer}
      onError={onError}
      qrEnabled={qrEnabled}
      employee={employee}
      allowEmployeeSelection={allowEmployeeSelection}
      buttons={{
        right: {
          title: 'Finish Timer',
        },
        left: {
          title: 'Abort Timer',
          confirm: {
            content: t('dialogs.abortTimer.content'),
            title: t('dialogs.abortTimer.title'),
          },
        },
      }}
      durationPollFunction={() =>
        getMyPunchIns({ filter: { active: true }, disablePaging: true }).then(
          (p) => p.records.find((a) => a.id === id)?.elapsedMinutes ?? 0
        )
      }
      getHourTypes={getHourTypes}
      getJobs={getJobs}
      getNonConformances={getNonConformances}
      getProcessSchedules={getProcessSchedules}
      fieldCaptions={fieldCaptions}
      fieldsDisableEditable={fieldsDisableEditable}
      getProjects={getProjects}
      initialValues={{
        ...initialValues,
      }}
      onSubmit={onSubmit}
      onCancel={onCancel}
    />
  );
};

export default StopTimerForm;
