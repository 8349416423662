import IndeterminateCheckBoxRounded from '@mui/icons-material/IndeterminateCheckBoxRounded';
import { Box, Checkbox } from '@mui/material';
import { useFormikContext } from 'formik';
import React from 'react';
import { Errors } from '../InlineEditing/editorTypes';

import FormatterErrorWrapper from './FormatterErrorWrapper';
import FormatterProps from './FormatterProps';

const boolSx = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  width: '100%',
};

const BoolFormatter = (props: FormatterProps) => {
  const formik = useFormikContext();
  const {
    value,
    column: { name },
    data,
    cellProps,
  } = props;
  const { editorProps } = cellProps;
  const { errors } = formik;
  const error = errors[name as keyof Errors];
  const rowErrorIndex =
    (Object.hasOwn(data, '__editing') && data.__editing && name) === name;

  const element = (
    <Checkbox
      size="small"
      checked={value ?? false}
      disabled
      indeterminate={value === undefined}
      indeterminateIcon={<IndeterminateCheckBoxRounded />}
    />
  );
  if (editorProps) {
    switch (editorProps.inlineEditMode) {
      case 'default':
        if (editorProps.inlineEditingCondition) {
          const result = editorProps.inlineEditingCondition(
            value,
            cellProps.data
          );

          if (result?.errorIs !== false) {
            return (
              <FormatterErrorWrapper
                errorIs={result.errorIs}
                errorText={result?.errorText}
              >
                {element}
              </FormatterErrorWrapper>
            );
          }
        }
        break;

      default:
        if (error && rowErrorIndex) {
          return (
            <FormatterErrorWrapper errorIs errorText={error}>
              {element}
            </FormatterErrorWrapper>
          );
        }
        break;
    }
  }

  return <Box sx={boolSx}>{element}</Box>;
};

export default BoolFormatter;
