/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import DeleteIcon from '@mui/icons-material/Delete';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  arrayMoveImmutable,
  ButtonStrip,
  ConfirmDialogs,
  ETOIconButton,
  ETOSelectField,
} from '@teto/react-component-library';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Draggable } from 'react-smooth-dnd';
import TableColumnDefinition from '../../TableColumnDefinition';

const addRowSx = (theme: Theme) => ({
  alignItems: 'center',
  display: 'flex',
  flexGrow: 0,
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  '& button': {
    marginLeft: theme.spacing(1),
  },
});

const contentRowSx = (theme: Theme) => ({
  display: 'flex',
  flexGrow: 1,
  '& .drag-handle': {
    cursor: 'pointer',
  },
  [theme.breakpoints.down('md')]: {
    maxHeight: '90%',
    overflow: 'auto',
  },
});

const assignedListSx = (theme: Theme) => ({
  width: '100%',
  '& .smooth-dnd-draggable-wrapper': {
    borderRadius: `${theme.shape.borderRadius}px`,
  },
  '& .smooth-dnd-draggable-wrapper:nth-of-type(odd)': {
    backgroundColor: theme.palette.background.default,
  },
});

const assignedListItemSx = (theme: Theme) => ({
  borderRadius: `${theme.shape.borderRadius}px`,
  paddingLeft: theme.spacing(1),
  paddingRight: 0,
  textTransform: 'capitalize',
});
type AtLeast<T, K extends keyof T> = Partial<T> & Pick<T, K>;
export type GroupableColumns = AtLeast<TableColumnDefinition, 'name' | 'title'>;

interface GroupByInspectorProps {
  groupableColumns: GroupableColumns[];
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-explicit-any
  initialGroupings: string[];
  // eslint-disable-next-line no-unused-vars
  onSave: (groupingColumns: string[]) => Promise<void>;
}

const GroupByPanel = (props: GroupByInspectorProps) => {
  const { onSave, initialGroupings, groupableColumns } = props;

  const { t } = useTranslation(['TimeCard', 'generic']);

  const label = 'Options';
  const [groupings, setGroupings] = useState<string[]>([]);
  const [newGrouping] = useState<string>('');
  const [resetDialog, setResetDialog] = useState<boolean>(false);
  const [availableGroups, setAvailableGroups] = useState(
    groupableColumns.map((col) => col.title)
  );

  useEffect(() => {
    setGroupings(initialGroupings);
  }, [initialGroupings]);

  const _addGroupingItem = (newG: string) => {
    const removeNewGroup = availableGroups.filter((group) => group !== newG);
    setAvailableGroups(() => removeNewGroup);
    setGroupings((grps) => [...grps, newG]);
  };

  const _onDrop = ({
    removedIndex,
    addedIndex,
  }: {
    removedIndex: number | null;
    addedIndex: number | null;
  }) => {
    if (removedIndex !== null && addedIndex !== null) {
      setGroupings((items) =>
        arrayMoveImmutable(items, removedIndex, addedIndex)
      );
    }
  };

  const _deleteItem = (item: string) => {
    setGroupings((items) => items.filter((i) => i !== item));
    setAvailableGroups((items) => [...items, item]);
  };

  const _handleReset = () => {
    onSave([]);
  };

  const _handleSave = () => {
    const matchingGroupings: string[] = [];

    groupableColumns.forEach((col) =>
      groupings.find((group) => {
        if (col.title === group) {
          return matchingGroupings.push(col.name);
        }
      })
    );

    onSave(matchingGroupings);
  };

  return (
    <>
      <Box sx={addRowSx}>
        <ETOSelectField
          id="groupingInput"
          label={label}
          size="medium"
          name="groupBy-select"
          value={newGrouping}
          items={availableGroups}
          defaultValue=" "
          handleChange={(e) => {
            _addGroupingItem(e.target.value);
          }}
          itemNameSelector={(i) => i}
          itemValueSelector={(i) => i}
        />
      </Box>
      <Box sx={contentRowSx}>
        <List sx={assignedListSx}>
          <Container
            dragHandleSelector=".drag-handle"
            lockAxis="y"
            onDrop={(e) => _onDrop(e)}
          >
            {groupings.map((item) => (
              <Draggable key={item}>
                <ListItem sx={assignedListItemSx}>
                  <Tooltip title={t('generic.changeOrder')}>
                    <ListItemIcon className="drag-handle">
                      <DragHandleIcon />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText>
                    <Typography color="textPrimary" variant="subtitle2">
                      {item}
                    </Typography>
                  </ListItemText>
                  <ListItemSecondaryAction style={{ right: 0 }}>
                    <ETOIconButton
                      color="primary"
                      size="medium"
                      // disabled={groupings.length <= 1}
                      onClick={() => _deleteItem(item)}
                      tooltipProps={{
                        title: t('generic.remove'),
                      }}
                    >
                      <DeleteIcon />
                    </ETOIconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              </Draggable>
            ))}
          </Container>
        </List>
      </Box>
      <ButtonStrip
        size="medium"
        leftButton={{
          text: t('generic.resetGridSettings'),
          onClick: () => setResetDialog(true),
          color: 'warning',
        }}
        rightButton={{
          text: t('generic.save'),
          onClick: () => _handleSave(),
          color: 'primary',
        }}
      />
      {resetDialog && (
        <ConfirmDialogs
          open={resetDialog}
          title={t('dialogs.resetFilters.title')}
          content={t('dialogs.resetFilters.content')}
          leftButton={{
            onClick: () => setResetDialog(false),
            label: t('generic.cancel'),
          }}
          rightButton={{
            onClick: () => {
              _handleReset();
              setResetDialog(false);
            },
            label: t('generic.confirm'),
          }}
        />
      )}
    </>
  );
};

export default GroupByPanel;
