import {
  TypeFilterValue,
  TypeGroupBy,
  TypeSingleSortInfo,
  TypeSortInfo,
} from '@inovua/reactdatagrid-community/types';
import { Box, Divider, MenuItem, TextField, useTheme } from '@mui/material';
import React, { useState } from 'react';
import TableColumnDefinition from '../../TableColumnDefinition';
import { GridOptionType } from '../GridOptionType';
import GridOptions from './GridOptions';

interface GridOptionsPanelProps {
  columns: TableColumnDefinition[];
  defaultSelect?: GridOptionType;
  handleClose: () => void;
  grouping: TypeGroupBy;
  // eslint-disable-next-line no-unused-vars
  setGrouping?: (grouping: TypeGroupBy) => void;
  sorting?: TypeSingleSortInfo[];
  // eslint-disable-next-line no-unused-vars
  setSorting?: (sorting: TypeSortInfo) => void;
  filters?: TypeFilterValue;
  // eslint-disable-next-line no-unused-vars
  setFilters?: (filter: TypeFilterValue) => void;
}

const GridOptionsPanel = (props: GridOptionsPanelProps) => {
  const {
    columns,
    defaultSelect,
    handleClose,
    grouping,
    setGrouping,
    sorting,
    setSorting,
    filters,
    setFilters,
  } = props;

  const theme = useTheme();

  const [selected, setSelected] = useState<string>(
    () => defaultSelect ?? 'filter'
  );

  const gridOptions = {
    label: 'Select Option',
    items: [
      { key: 'filter', label: 'Filter' },
      { key: 'groupBy', label: 'Group By' },
      { key: 'sortBy', label: 'Sort By' },
    ],
  };

  const { label, items } = gridOptions;

  return (
    <Box
      sx={{
        maxHeight: 'calc(100% - 70px)',
        flexGrow: 1,
        overflow: 'hidden',
        paddingTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <TextField
        label={label}
        select
        name="selectOption"
        value={selected}
        onChange={(e) => setSelected(e.target.value)}
        fullWidth
        size="small"
        sx={{
          marginBottom: theme.spacing(2),
          flexGrow: 0,
        }}
      >
        {items.map((item) => (
          <MenuItem key={item.key} value={item.key}>
            {item.label}
          </MenuItem>
        ))}
      </TextField>

      <Divider sx={{ marginBottom: theme.spacing(2) }} />

      <GridOptions
        selected={selected}
        columns={columns}
        grouping={grouping}
        setGrouping={setGrouping}
        sorting={sorting}
        setSorting={setSorting}
        filters={filters}
        setFilters={setFilters}
        handleClose={handleClose}
      />
    </Box>
  );
};

export default GridOptionsPanel;
