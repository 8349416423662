import Close from '@mui/icons-material/Close';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  SxProps,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { ETOIconButton } from '@teto/react-component-library';
import React from 'react';
import { useTranslation } from 'react-i18next';

const sizeSx: SxProps<Theme> = {
  height: (theme) => theme.spacing(4.5),
  textTransform: 'capitalize',
};
const primarySx: SxProps<Theme> = {
  backgroundColor: 'primary.main',
  color: 'common.white',
  '&:hover': {
    backgroundColor: 'primary.light',
  },
};
const secondarySx: SxProps<Theme> = {
  backgroundColor: 'secondary.main',
  color: 'common.white',
  '&:hover': {
    backgroundColor: 'secondary.light',
  },
};
const closeSx: SxProps<Theme> = {
  position: 'absolute',
  right: (theme) => theme.spacing(1),
  top: (theme) => theme.spacing(1),
};

const titleSx: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'space-between',
};

interface ConfirmDialogButton {
  label: string;
  onClick: (
    // eslint-disable-next-line no-unused-vars
    e: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined,
    // eslint-disable-next-line no-unused-vars
    data: object | undefined
  ) => void;
}

export interface TetoGridDialogProps {
  open: boolean;
  leftButton?: ConfirmDialogButton;
  rightButton?: ConfirmDialogButton;
  title: string;
  content: string | React.ReactNode;
  data?: object;
  // eslint-disable-next-line no-unused-vars
  handleClose: () => void;
}

const TetoGridDialog = (props: TetoGridDialogProps) => {
  const { open, leftButton, rightButton, data, content, title, handleClose } =
    props;
  const { t } = useTranslation();

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      PaperProps={{
        sx: {
          minWidth: (theme) => theme.spacing(40),
        },
      }}
    >
      <DialogTitle sx={titleSx}>
        {title}
        <ETOIconButton
          aria-label="close"
          onClick={handleClose}
          color="primary"
          customSx={closeSx}
          size="medium"
          tooltipProps={{ title: t('generic.close') }}
        >
          <Close />
        </ETOIconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {leftButton && (
          <Button
            color="secondary"
            size="medium"
            onClick={(e) => leftButton.onClick(e, data)}
            sx={[sizeSx, secondarySx]}
            variant="contained"
          >
            {leftButton.label}
          </Button>
        )}
        {rightButton && (
          <Button
            autoFocus
            color="primary"
            size="medium"
            onClick={(e) => rightButton.onClick(e, data)}
            sx={[sizeSx, primarySx]}
            variant="contained"
          >
            {rightButton.label}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default TetoGridDialog;
