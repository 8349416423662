import { Box, SxProps, TextField } from '@mui/material';
import {
  formatHoursAsHourMin,
  formatInputAshours,
} from '@teto/react-component-library';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { getDisabledValue } from '../editingHelpers';
import { Errors } from '../editorTypes';
import editorStyles from '../InlineEditingCommonStyles';
import InlineEditorProps from '../InlineEditingProps';

const cellSx: SxProps = {
  padding: 0,
  margin: 0,
};

const inputSx = {
  height: '100%',
  width: '100%',
  margin: 0,
  padding: 'auto',
};

const localSx: SxProps = {
  '& .MuiInputBase-root, & .MuiFormControl-root': {
    height: '100%',
  },
  '& .MuiFormHelperText-root.Mui-error': {
    position: 'absolute',
    zIndex: 1500,
    bottom: '-22px',
  },
};

const inputRegex = /^\d+(\d*|:[0-5]?[0-9]?|\.\d*|h|m)$/i;

const ETOHourEditor: React.FC<InlineEditorProps> = (props) => {
  const {
    autoFocus,
    cellProps,
    editorProps,
    onCancel,
    onComplete,
    onTabNavigation,
  } = props;

  const formik = useFormikContext();
  const columnName = cellProps?.name as string;
  const { errors } = formik;
  const isDisabled =
    !editorProps?.hasEditPermission ||
    getDisabledValue(cellProps?.data, editorProps?.disabled) ||
    editorProps?.lockColumn?.(columnName, cellProps?.data);
  const error = errors[columnName as keyof Errors];
  const [input, setInput] = useState<string>('0');

  const formatStartValue = (val: string | number) =>
    formatHoursAsHourMin(typeof val === 'string' ? parseFloat(val) : val);

  useEffect(() => {
    setInput(() => formatStartValue(cellProps?.value ?? 0));
  }, [cellProps?.value]);

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case 'Escape':
        onComplete?.();
        onCancel?.(e);
        break;

      case 'Enter':
        onComplete?.();
        if (formik.dirty) formik.submitForm();
        else formik.resetForm();
        break;

      case 'Tab':
        e.preventDefault();
        onTabNavigation?.(true, e.shiftKey ? -1 : 1);
        break;
      default:
        break;
    }
  };

  const _handleChange = (text: string) => {
    if (inputRegex.test(text)) {
      formik.setFieldValue(columnName, formatInputAshours(text));
      setInput(text);
    } else if (text === '') {
      setInput('');
      formik.setFieldValue(columnName, undefined);
    }
  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {columnName && !isDisabled && (
        <Box sx={[editorStyles.root, cellSx, localSx]} data-testid="container">
          <TextField
            autoFocus={autoFocus}
            autoComplete="off"
            data-testid="ETOHourEditor"
            disabled={isDisabled}
            error={Boolean(error)}
            fullWidth
            helperText={error}
            name={columnName}
            onChange={(e) => _handleChange(e.target.value)}
            onFocus={(
              e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => e.target.select()}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
              onKeyDown?.(e)
            }
            size="small"
            tabIndex={0}
            value={input}
            variant="outlined"
            sx={{
              height: '100%',
            }}
            inputProps={{
              sx: inputSx,
            }}
          />
        </Box>
      )}
    </>
  );
};

export default ETOHourEditor;
