import {
  Box,
  Paper,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { ETOTextField, MessageContext } from '@teto/react-component-library';
import { isEmpty } from 'lodash';
import * as React from 'react';
import { useQuery } from 'react-query';
import { getGraphQLClient } from '../../../../helpers/graphQL/graphQLClient';

interface SummaryProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  detail: { [key: string]: any };
}

const container: SxProps<Theme> = (theme: Theme) => ({
  overflow: 'auto',
  maxHeight: '90%',
  px: theme.spacing(1),
  '& .MuiTypography-subtitle1': {
    marginBottom: theme.spacing(2),
  },
  '& .MuiTextField-root': {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  '& .MuiDivider-root': {
    marginBottom: theme.spacing(2),
  },
  '& .MuiTableContainer-root': {
    marginBottom: theme.spacing(2),
  },
});

const Summary = (props: SummaryProps) => {
  const { detail } = props;
  const inventoryId = isEmpty(detail.inventoryDetails)
    ? 0
    : detail.inventoryDetails[0].inventory.inventoryLocationId;
  const variables = {
    projectId: detail?.projectId,
    specId: detail?.specId,
    itemId: detail?.itemId,
    inventoryLocationId: inventoryId,
    destinationLocationId: detail?.destInventoryLocId,
    processScheduleId: detail?.processScheduleId,
  };

  const messageContext = React.useContext(MessageContext);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const _doQuery = async () => {
    const query = `query purchasingItemDetailsSummaryByItem(
      $projectId: Int!
      $specId: Float!
      $itemId: Int!
      $inventoryLocationId: Int!
      $destinationLocationId: Int
      $processScheduleId: Int
    ) {
      purchasingItemDetailsSummaryByItem(
        projectId: $projectId
        specId: $specId
        itemId: $itemId
        inventoryLocationId: $inventoryLocationId
        destinationLocationId: $destinationLocationId
        processScheduleId: $processScheduleId
        skip: 0
        take: 10
        order: { itemId: DESC }
      ) {
        items {
          projectId
          specId
          itemId
          itemCompanyId
          itemDescription
          itemUOM
          itemCategory
          itemRevNumber
          itemWeight
          itemLastCost
          itemListCost
          preferredSupplierCompanyId
          lastSupplierCompanyId
          supplierPartNumber
          manufacturer
          manufacturerPartNumber
          estimatedLeadTime
          hasDrawing
          drawing
          multipleDrawing
          multipleDrawingAddress
          rawMaterialItemID
          rawMaterialCutLength
          itemRepairable
          itemRelub
          itemMaintenance
          itemCertifiedPrints
          itemReserved
          obsolete
          partCustom1
          partCustom2
          partCustom3
          partCustom4
          partCustom5
          partCustom6
          partCustom7
          partCustom8
          processId
          countryOfOrigin
          tariffCode
          processScheduleTemplateId
          itemCategoryDescription
          uOMDescription
          locationName
          destInventoryLoc
          onHand
          rFQs
          bOM
          nonBOM
          procured
          inventory
          processScheduled
          completedPSQty
          openWorkOrders
          orderToInventory
          qtyRequiredForAllProjects
          pulledQty
          reservedQty
          receivedQty
          unreceivedQty
          openQty
          availableQty
          outstandingQty
          required
          processSchedule
        }
      }
    }
    `;

    const response = await getGraphQLClient().performQuery(
      query,
      variables,
      (err) => messageContext.setError(err.messages.join('\n ')),
      (err) => {
        // eslint-disable-next-line no-console
        console.log(JSON.stringify(err));
        // messageContext.setError(err.messages.join('\n '))
      }
    );

    return response;
  };

  const summaryQuery = useQuery('summary', async () => _doQuery(), {
    enabled: true,
  });
  const data =
    summaryQuery?.data?.purchasingItemDetailsSummaryByItem?.items?.[0];
  const reqTotal = isEmpty(data) ? 0 : data.bOM + data.nonBOM;
  const procTotal = isEmpty(data)
    ? 0
    : data.procured + data.inventory + data.processScheduled;
  const toBeProcuredTotal = isEmpty(data) ? 0 : reqTotal - procTotal;

  return (
    <Box sx={container}>
      <Typography variant="subtitle1">Summary</Typography>
      <ETOTextField
        name="part-description"
        label="PO Part Description"
        multiline
        maxRows={4}
        minRows={4}
        value={data?.itemDescription ?? ''}
        disabled
        inputLabelProps={{ shrink: true }}
      />
      <ETOTextField
        name="uom"
        label="UOM"
        value={data?.uOMDescription ?? ''}
        disabled
        inputLabelProps={{ shrink: true }}
      />
      <ETOTextField
        name="category"
        label="Category"
        value={data?.itemCategoryDescription ?? ''}
        disabled
        inputLabelProps={{ shrink: true }}
      />

      <TableContainer component={Paper} elevation={0}>
        <Table
          aria-label="reference information table"
          padding={mobile ? 'none' : 'normal'}
          size="small"
        >
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}>Reference Information</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>On Order to Inventory</TableCell>
              <TableCell align="right">{data?.orderToInventory ?? 0}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>On Open Work Orders</TableCell>
              <TableCell align="right">{data?.openWorkOrders ?? 0}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <TableContainer component={Paper} elevation={0}>
        <Table
          aria-label="requested table"
          padding={mobile ? 'none' : 'normal'}
          size="small"
        >
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}>Requested</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>BOM/Parts Order</TableCell>
              <TableCell align="right">{data?.bOM ?? 0}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Non-BOM (Including PS)</TableCell>
              <TableCell align="right">{data?.nonBOM ?? 0}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ fontWeight: 700 }}>Total</TableCell>
              <TableCell align="right" sx={{ fontWeight: 700 }}>
                {reqTotal}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <TableContainer component={Paper} elevation={0}>
        <Table
          aria-label="procured table"
          padding={mobile ? 'none' : 'normal'}
          size="small"
        >
          <TableHead>
            <TableRow>
              <TableCell colSpan={1}>Procured</TableCell>
              <TableCell align="right">Total</TableCell>
              <TableCell align="right">Open</TableCell>
              <TableCell align="right">Available</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Purchased</TableCell>
              <TableCell align="right">{data?.procured ?? 0}</TableCell>
              <TableCell align="right">{data?.unreceivedQty ?? 0}</TableCell>
              <TableCell align="right">{data?.receivedQty ?? 0}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Reserved/Pulled Inventory</TableCell>
              <TableCell align="right">{data?.inventory ?? 0}</TableCell>
              <TableCell align="right">{data?.reservedQty ?? 0}</TableCell>
              <TableCell align="right">{data?.pulledQty ?? 0}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Issued Process Schedules</TableCell>
              <TableCell align="right">{data?.processScheduled ?? 0}</TableCell>
              <TableCell align="right">0</TableCell>
              <TableCell align="right">{data?.completedPSQty ?? 0}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ fontWeight: '700' }}>Total</TableCell>
              <TableCell align="right" sx={{ fontWeight: '700' }}>
                {procTotal}
              </TableCell>
              <TableCell align="right" sx={{ fontWeight: '700' }}>
                {data?.openQty ?? 0}
              </TableCell>
              <TableCell align="right" sx={{ fontWeight: '700' }}>
                {data?.availableQty ?? 0}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <TableContainer component={Paper} elevation={0}>
        <Table
          aria-label="to be procured table"
          padding={mobile ? 'none' : 'normal'}
          size="small"
        >
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}>To Be Procured</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Requested Total</TableCell>
              <TableCell align="right">{reqTotal}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Procured Total</TableCell>
              <TableCell align="right">{procTotal}</TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell sx={{ fontWeight: '700' }}>Total</TableCell>
              <TableCell align="right" sx={{ fontWeight: '700' }}>
                {toBeProcuredTotal}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default Summary;
