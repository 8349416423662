/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import FormatterProps from './FormatterProps';

const CustomFormatter = (props: FormatterProps) => {
  const { data, column, disableInGroup } = props;

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {(!disableInGroup || !data?.__group === true) &&
        column.renderFunction &&
        column.renderFunction(data, props)}
    </>
  );
};

export default React.memo(CustomFormatter);
