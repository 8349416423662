import DeleteIcon from '@mui/icons-material/Delete';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  arrayMoveImmutable,
  ButtonStrip,
  ConfirmDialogs,
  ETOButton,
  ETOIconButton,
  ETOSelectField,
} from '@teto/react-component-library';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Draggable } from 'react-smooth-dnd';
import {
  checkValidCustomColumns,
  groupableColumns,
} from '../../../../helpers/GroupableColumns';

const addRowSx = (theme: Theme) => ({
  alignItems: 'center',
  display: 'flex',
  flexGrow: 0,
  marginTop: theme.spacing(1.5),
  marginBottom: theme.spacing(1.5),
  '& button': {
    marginLeft: theme.spacing(1.5),
  },
});

const contentRowSx = (theme: Theme) => ({
  display: 'flex',
  flexGrow: 1,
  '& .drag-handle': {
    cursor: 'pointer',
  },
  [theme.breakpoints.down('md')]: {
    maxHeight: theme.spacing(19),
    overflowY: 'scroll',
  },
});

const panelSx = {
  flexGrow: 1,
  maxHeight: '100%',
  marginRight: 1.5,
};

const assignedListSx = (theme: Theme) => ({
  width: '100%',
  '& .smooth-dnd-draggable-wrapper': {
    borderRadius: `${theme.shape.borderRadius}px`,
  },
  '& .smooth-dnd-draggable-wrapper:nth-of-type(odd)': {
    backgroundColor: theme.palette.background.default,
  },
});

const assignedListItemSx = (theme: Theme) => ({
  height: theme.spacing(7.75),
  borderRadius: `${theme.shape.borderRadius}px`,
  paddingLeft: theme.spacing(1),
  paddingRight: 0,
  textTransform: 'capitalize',
});

interface GroupByTabProps {
  initialGroupings: string[];
  onReset: () => void;
  // eslint-disable-next-line no-unused-vars
  onSave: (groupingColumns: string[]) => Promise<void>;
}

const GroupByTab = (props: GroupByTabProps) => {
  const { initialGroupings, onReset, onSave } = props;

  const { t } = useTranslation(['TimeCard', 'generic']);

  const label = 'Grouping Options';
  const [groupings, setGroupings] = useState<string[]>([]);
  const [newGrouping, setNewGrouping] = useState<string>('');
  const [resetDialog, setResetDialog] = useState<boolean>(false);

  const availableGroups = checkValidCustomColumns(
    Object.entries(groupableColumns)
      .filter((gc) => groupings.indexOf(gc[0]) < 0)
      .map((a) => a[0]),
    t
  );

  useEffect(() => {
    setGroupings(initialGroupings);
  }, [initialGroupings]);

  const _addGroupingItem = () => {
    if (newGrouping) {
      setGroupings((grps) => [...grps, newGrouping]);
      setNewGrouping('');
    }
  };

  const _onDrop = ({
    removedIndex,
    addedIndex,
  }: {
    removedIndex: number | null;
    addedIndex: number | null;
  }) => {
    if (removedIndex !== null && addedIndex !== null) {
      setGroupings((items) =>
        arrayMoveImmutable(items, removedIndex, addedIndex)
      );
    }
  };

  const _deleteItem = (item: string) => {
    setGroupings((items) => items.filter((i) => i !== item));
  };

  const _handleSave = () => {
    onSave(groupings);
  };

  return (
    <Box sx={panelSx}>
      <Box sx={addRowSx}>
        <ETOSelectField
          label={label}
          size="medium"
          name="groupings-select"
          value={newGrouping}
          items={availableGroups}
          defaultValue=" "
          handleChange={(e) => setNewGrouping(e.target.value)}
          itemNameSelector={(i) => t(groupableColumns[i])}
          itemValueSelector={(i) => i}
        />
        <ETOButton
          size="medium"
          color="primary"
          onClick={() => _addGroupingItem()}
        >
          {t('generic.add')}
        </ETOButton>
      </Box>
      <Box sx={contentRowSx}>
        <List sx={assignedListSx}>
          <Container
            dragHandleSelector=".drag-handle"
            lockAxis="y"
            onDrop={(e) => _onDrop(e)}
          >
            {groupings.map((item) => (
              <Draggable key={item}>
                <ListItem sx={assignedListItemSx}>
                  <Tooltip title={t('generic.changeOrder')}>
                    <ListItemIcon className="drag-handle">
                      <DragHandleIcon />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText>
                    <Typography color="textPrimary" variant="body1">
                      {t(groupableColumns[item])}
                    </Typography>
                  </ListItemText>
                  <ListItemSecondaryAction style={{ right: 0 }}>
                    <ETOIconButton
                      color="primary"
                      size="large"
                      disabled={groupings.length <= 1}
                      onClick={() => _deleteItem(item)}
                      tooltipProps={{
                        title: t('generic.remove'),
                      }}
                    >
                      <DeleteIcon />
                    </ETOIconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              </Draggable>
            ))}
          </Container>
        </List>
      </Box>
      <ButtonStrip
        size="medium"
        rightButton={{
          text: t('generic.save'),
          onClick: () => _handleSave(),
          color: 'primary',
        }}
        leftButton={{
          text: t('generic.resetGridSettings'),
          onClick: () => setResetDialog(true),
          color: 'warning',
        }}
      />
      {resetDialog && (
        <ConfirmDialogs
          open={resetDialog}
          title={t('dialogs.resetGroup.title')}
          content={t('dialogs.resetGroup.content')}
          leftButton={{
            onClick: () => setResetDialog(false),
            label: t('generic.cancel'),
          }}
          rightButton={{
            onClick: () => {
              onReset();
              setResetDialog(false);
            },
            label: t('generic.confirm'),
          }}
        />
      )}
    </Box>
  );
};

export default GroupByTab;
