import { CellRenderObject } from '@inovua/reactdatagrid-community/Layout/ColumnLayout/Cell/CellProps';
import { Box } from '@mui/material';
// import { isObject } from 'lodash';
import React from 'react';
import IMobileData from '../IMobileData';
import MobileEditor from '../InlineEditing/Mobile/MobileEditor';

interface Props {
  data: IMobileData[];
  rdgProps: CellRenderObject;
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-explicit-any
  onEditComplete: (editContext: any) => void;
}

const mobileCellStyling = {
  height: '50px',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  paddingLeft: '8px',
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const RenderMobileCell = (props: Props) => {
  const { data } = props;
  return data.map((d) => {
    if (d.editable === true) {
      return <MobileEditor {...props} data={d} />;
    }
    /* if (isObject(d.value)) {
      // switch (d.value) {
      if (Object.hasOwn(d.value, 'displayName') as unknown as object) {
        return (
          // @ts-expect-error : checked above
          <Box sx={mobileCellStyling}>{String(d.value?.displayName)}</Box>
        );
      }
      if (Object.hasOwn(d.value, 'quantityReceived') as unknown as object) {
        return (
          // @ts-expect-error : checked above
          <Box sx={mobileCellStyling}>{String(d.value?.quantityReceived)}</Box>
        );
      }
      if (Object.hasOwn(d.value, 'name') as unknown as object) {
        return (
          // @ts-expect-error : checked above
          <Box sx={mobileCellStyling}>{String(d.value?.name)}</Box>
        );
      }
      if (Object.hasOwn(d.value, 'id') as unknown as object) {
        return (
          // @ts-expect-error : checked above
          <Box sx={mobileCellStyling}>{String(d.value?.id)}</Box>
        );
      }
      // eslint-disable-next-line no-console
      console.error(
        'RenderMobileCell does not recognize any of the keys on the object, considering updating it in RenderMobileCell',
        d.value
      );
      return <Box sx={mobileCellStyling}>Error displaying value</Box>;
    }
     if (d.renderFunction) {
      return (
        <Box sx={mobileCellStyling}>
          {d.value === null ? '-' : d.renderFunction(d, props.rdgProps)}
        </Box>
      );
    }

    if (d.render) {
      return (
        <Box sx={mobileCellStyling}>
          {d.value === null ? '-' : d.render(d, props.rdgProps)}
        </Box>
      ) 
    } */ return (
      <Box sx={mobileCellStyling}>
        {d.value === null ? '-' : String(d.value)}
      </Box>
    );
  });
};

export default RenderMobileCell;
