/* eslint-disable import/prefer-default-export */
import { TypeSortInfo } from '@inovua/reactdatagrid-enterprise/types';

export const buildSortQuery = (sort: TypeSortInfo) => {
  const output: { [key: string]: boolean } = {};

  if (!sort) {
    return output;
  }

  if (Array.isArray(sort)) {
    sort.forEach((s) => {
      if (s.dir !== 0) {
        output[s.name] = s.dir === 1;
      }
    });
  } else {
    if (sort.dir === 0) {
      return output;
    }

    output[sort.name] = sort.dir === 1;
  }

  return output;
};
