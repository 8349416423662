import Avatar from '@mui/material/Avatar';
import { Theme } from '@mui/material/styles';
import React, { useMemo } from 'react';

const avatarSx = (theme: Theme) => ({
  width: theme.spacing(3),
  height: theme.spacing(3),
  backgroundColor: theme.palette.primary.main,
  marginRight: theme.spacing(0.5),
  fontSize: '0.95em',
  color: theme.palette.getContrastText(theme.palette.primary.main),
  '&:hover': {
    backgroundColor: `${theme.palette.primary.main}!important`,
    color: `${theme.palette.getContrastText(
      theme.palette.primary.main
    )}!important`,
  },
});

interface ApproverIconProps {
  name: string;
}

const ApproverIcon = ({ name }: ApproverIconProps) => {
  const initials = useMemo(() => {
    const split = name.split(' ', 2);
    return `${split[0].substring(0, 1)}${split[1].substring(
      0,
      1
    )}`.toUpperCase();
  }, [name]);

  return (
    <Avatar variant="rounded" title={name} sx={avatarSx}>
      {initials}
    </Avatar>
  );
};

export default ApproverIcon;
