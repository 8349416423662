import dayjs, { isDayjs } from 'dayjs';
// eslint-disable-next-line no-unused-vars
import isBetween from 'dayjs/plugin/isBetween';
// eslint-disable-next-line no-unused-vars
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
// eslint-disable-next-line no-unused-vars
import weekday from 'dayjs/plugin/weekday';
import React from 'react';
import { TimeCard } from 'teto-client-api';
import HoursTimeCardForm, { HoursTimeCardFormProps } from './HoursTimeCardForm';

dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);
dayjs.extend(weekday);

interface EditTimeCardFormProps
  extends Omit<
    Omit<Omit<HoursTimeCardFormProps, 'editMode'>, 'initialValues'>,
    'canAffect'
  > {
  // eslint-disable-next-line no-unused-vars
  timecards: TimeCard[];
  weekStart: number;
  qrEnabled: boolean;
  disableOverlay?: boolean;
  batchId: string;
  // eslint-disable-next-line no-unused-vars
  deleteFunction: (batchId: string) => Promise<void>;
  canEdit: boolean;
  canDelete: boolean;
  // eslint-disable-next-line no-unused-vars
  setFormDirty?: (val: boolean) => void;
  // eslint-disable-next-line no-unused-vars
  handleNewTimecardCreated?: (val: boolean) => void;
}

function _createTimeMap(startDate: Date, timecards: TimeCard[]) {
  const data = {
    day1: {
      hours: '0',
      day: dayjs(),
    },
    day2: {
      hours: '0',
      day: dayjs(),
    },
    day3: {
      hours: '0',
      day: dayjs(),
    },
    day4: {
      hours: '0',
      day: dayjs(),
    },
    day5: {
      hours: '0',
      day: dayjs(),
    },
    day6: {
      hours: '0',
      day: dayjs(),
    },
    day7: {
      hours: '0',
      day: dayjs(),
    },
  };

  for (let i = 0; i < 7; i += 1) {
    const d = dayjs(startDate).add(i, 'day');
    const entry = timecards.find((a) =>
      (isDayjs(a.date) ? a.date : dayjs(a.date)).isSame(d)
    );
    const dayLabel = `day${i + 1}`;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (data as any)[dayLabel] = {
      hours: entry?.actualHours ?? 0,
      day: d,
    };
  }

  return data;
}

const _calculateStartDate = (timecards: TimeCard[], weekStart: number) => {
  if (!timecards || timecards.length === 0) {
    return dayjs().toDate();
  }

  const selected = dayjs(timecards[0].date);
  const chosenStartDate = selected.startOf('w').weekday(weekStart);

  return chosenStartDate.toDate();
};

const EditTimeCardForm = (props: EditTimeCardFormProps) => {
  const {
    timecards,
    requireFields,
    batchId,
    deleteFunction,
    weekStart,
    canEdit,
    fieldsDisableEditable,
    handleNewTimecardCreated,
    disableOverlay,
  } = props;

  const startDate = _calculateStartDate(timecards, weekStart);

  const hoursMap = _createTimeMap(startDate, timecards);
  const fieldValues = {
    employeeId: timecards[0].employeeId,
    projectId: timecards[0].projectId,
    comments: timecards[0].comments,
    custom1: timecards[0].custom1,
    custom2: timecards[0].custom2,
    custom3: timecards[0].custom3,
    custom4: timecards[0].custom4,
    custom5: timecards[0].custom5,
    custom6: timecards[0].custom6,
    custom7: timecards[0].custom7,
    custom8: timecards[0].custom8,
    hourTypeId: timecards[0].hourTypeId,
    hourRate: timecards[0].hourRate,
    hourFactor: timecards[0].hourFactor,
    hours: hoursMap,
    specId: timecards[0].specId,
    nonConformanceId: timecards[0].nonConformanceId,
    batchId: timecards[0].batchId,
    processScheduleDetailId: timecards[0].processScheduleDetailId,
    quantity: timecards[0].quantity,
    requireComments: requireFields?.comments,
    requireCustom1: requireFields?.custom1,
    requireCustom2: requireFields?.custom2,
    requireCustom3: requireFields?.custom3,
    requireCustom4: requireFields?.custom4,
    requireCustom5: requireFields?.custom5,
    requireCustom6: requireFields?.custom6,
    requireCustom7: requireFields?.custom7,
    requireCustom8: requireFields?.custom8,
    requireQuantity: requireFields?.quantity,
    requireNonConformance: requireFields?.nonConformanceId,
    requireProcessSchedule: requireFields?.processScheduleDetailId,
    pSCCustom1: timecards[0].pSCCustom1,
    pSCCustom2: timecards[0].pSCCustom2,
    pSCCustom3: timecards[0].pSCCustom3,
    pSCCustom4: timecards[0].pSCCustom4,
    pSCCustom5: timecards[0].pSCCustom5,
    pSCCustom6: timecards[0].pSCCustom6,
    pSCCustom7: timecards[0].pSCCustom7,
    pSCCustom8: timecards[0].pSCCustom8,
    requirePSCCustom1: requireFields?.pSCCustom1,
    requirePSCCustom2: requireFields?.pSCCustom2,
    requirePSCCustom3: requireFields?.pSCCustom3,
    requirePSCCustom4: requireFields?.pSCCustom4,
    requirePSCCustom5: requireFields?.pSCCustom5,
    requirePSCCustom6: requireFields?.pSCCustom6,
    requirePSCCustom7: requireFields?.pSCCustom7,
    requirePSCCustom8: requireFields?.pSCCustom8,
  };
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {timecards.length > 0 && (
        <HoursTimeCardForm
          canAffect={canEdit && !timecards[0].locked}
          editMode={{
            batchId,
            deleteFunction,
          }}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          fieldsDisableEditable={!!timecards[0].locked || fieldsDisableEditable}
          initialValues={fieldValues}
          handleNewTimecardCreated={handleNewTimecardCreated}
          disableOverlay={disableOverlay}
        />
      )}
    </>
  );
};

export default EditTimeCardForm;
