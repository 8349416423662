const getEmployeesQuery = `query employees {
  employees (
      skip: 0
      order: { firstName: ASC }
      where: { active: {eq: true } }
    ) {
      items {
        name
        id 
      }
    }
  }
`;

export default getEmployeesQuery;
