/* eslint-disable react/no-unused-prop-types */
import {
  Box,
  SxProps,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { LatLngExpression, Map } from 'leaflet';
import React from 'react';
import { MapContainer, Marker, TileLayer, Tooltip } from 'react-leaflet';
import { TimeCard } from 'teto-client-api';

export type LocationT = {
  timecardCoords?: string | undefined | null;
  punchInCoords?: string | undefined | null;
} & Pick<
  TimeCard,
  'employeeFullName' | 'projectName' | 'specName' | 'hourTypeName' | 'id'
>;

interface MapProps {
  data: LocationT[] | undefined;
  map: Map | undefined;
  setMap: React.Dispatch<React.SetStateAction<Map | undefined>>;
  setSelectedPunchIn?: React.Dispatch<React.SetStateAction<number>>;
  coords?: LatLngExpression | undefined;
  zoom?: number;
}

const leafletContainer: SxProps<Theme> = (theme: Theme) => ({
  height: '100%',
  width: '100%',
  '& .leaflet-container': {
    borderRadius: `${theme.shape.borderRadius}px`,
    height: '100%',
    width: '100%',
    '& .leaflet-top': {
      zIndex: 500,
    },
    '& .leaflet-bottom': {
      zIndex: 700,
    },
  },
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any, consistent-return
const getCoordsType = (data: LocationT[] | undefined) => {
  if (data && data.length > 0) {
    if (data.some((d) => Object.keys(d).includes('timeSheetId'))) {
      return 'timecardCoords';
    }
    return 'punchInCoords';
  }
  return undefined;
};

// eslint-disable-next-line react/function-component-definition
function MapWrapper(props: MapProps) {
  const { data, zoom, coords, map, setMap, setSelectedPunchIn } = props;

  const theme = useTheme();
  const mobileSize = useMediaQuery(theme.breakpoints.down('md'));
  const coordsType = getCoordsType(data);

  return (
    <Box sx={leafletContainer}>
      <MapContainer
        // Questionable
        whenReady={setMap as () => void}
        center={coords || [0, 0]}
        zoom={zoom || 2}
        scrollWheelZoom={!mobileSize}
        placeholder={<Typography variant="h6">No Map Available</Typography>}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />
        {data &&
          coordsType &&
          data
            .filter(
              (record) =>
                record[coordsType] &&
                record[coordsType] !== ' ' &&
                record[coordsType] !== 'null, null'
            )
            .map(
              (r) =>
                r[coordsType] &&
                coordsType && (
                  <Marker
                    key={r.id}
                    eventHandlers={{
                      click: (e) => {
                        const { lat, lng } = e.latlng;
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        const { target } = map as any;
                        target.setView([lat, lng], 12);
                        if (setSelectedPunchIn) setSelectedPunchIn(r.id);
                      },
                    }}
                    position={
                      // Converts string to array of numbers
                      // @ts-expect-error: handled above
                      r[coordsType]
                        .split(', ')
                        .map((d: string) => parseFloat(d)) as LatLngExpression
                    }
                  >
                    <Tooltip
                      offset={mobileSize ? [-12, 32] : [10, 15]}
                      direction={mobileSize ? 'bottom' : 'auto'}
                    >
                      <Box>
                        <Typography variant="body1" display="block">
                          {r.employeeFullName}
                        </Typography>
                        <Typography variant="caption" display="block">
                          {r.projectName}
                        </Typography>
                        <Typography variant="caption" display="block">
                          {r.specName}
                        </Typography>
                        <Typography variant="caption" display="block">
                          {r.hourTypeName}
                        </Typography>
                      </Box>
                    </Tooltip>
                  </Marker>
                )
            )}
      </MapContainer>
    </Box>
  );
}

export default MapWrapper;
