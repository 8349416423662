import * as Yup from 'yup';
import CommonFormValidation from '../TimecardFormValidation';

const REQUIRED_MESSAGE = 'Required';

const YupSchema = Yup.object()
  .shape({
    // eslint-disable-next-line react/forbid-prop-types
    hour: Yup.number().required(REQUIRED_MESSAGE).min(0),
    date: Yup.object().required(REQUIRED_MESSAGE),
  })
  .concat(CommonFormValidation);

export default YupSchema;
