/* eslint-disable import/prefer-default-export */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const downloadBlob = (blob: any, fileName: string) => {
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);

  link.setAttribute('href', url);
  link.setAttribute('download', fileName);
  link.style.position = 'absolute';
  link.style.visibility = 'hidden';

  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);
};
