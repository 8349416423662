import { Box, MenuItem, TextField, Theme, Typography } from '@mui/material';
import {
  ETOCheckBox,
  ETOSelectField,
  MessageContext,
} from '@teto/react-component-library';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useLocalStorage from 'use-local-storage';
import { getGraphQLClient } from '../../../../helpers/graphQL/graphQLClient';
import { Setter } from '../../../../helpers/setterGeneric';

interface MoreOptionsPanelProps {
  hideFullyReceivedSetting: boolean;
  setHideFullyReceived: Setter<boolean>;
  defaultLabelQty: '1' | 'None' | 'Received Quantities';
  setDefaultLabelQty: Setter<'1' | 'None' | 'Received Quantities'>;
}

const wrapperSx = (theme: Theme) => ({
  width: '100%',
  overflowY: 'auto',
  maxHeight: 'calc(100% - 160px)',
  paddingRight: theme.spacing(2),
});

const itemSx = (theme: Theme) => ({
  marginBottom: theme.spacing(3),
});

const defaultLabelOptions = [
  {
    value: 0,
    key: '1',
  },
  {
    value: 1,
    key: 'None',
  },
  {
    value: 2,
    key: 'Received Quantities',
  },
];

const MoreOptionsPanel = (props: MoreOptionsPanelProps) => {
  const { t } = useTranslation();
  const messageContext = useContext(MessageContext);

  const {
    hideFullyReceivedSetting,
    setHideFullyReceived,
    defaultLabelQty,
    setDefaultLabelQty,
  } = props;

  const receivingLabelsQuery = `query {
    pOReceivingLabelReports {
      reportDisplayName,
      alternateId,
      isAlternate
    }
  }`;

  const [selectedAltReportId, setSelectedAltReportId] = useLocalStorage<number>(
    'ReceivingSelectedLabelReport',
    0
  );

  const [reports, setReports] = useState<{ name: string; altId: number }[]>([]);

  useEffect(() => {
    getGraphQLClient()
      .performQuery(
        receivingLabelsQuery,
        {},
        (err) => messageContext.setError(err.messages[0]),
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {}
      )
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((data: any) =>
        setReports(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          data.pOReceivingLabelReports.map((i: any) => ({
            name: i.reportDisplayName,
            altId: i.alternateId,
          }))
        )
      );
  }, []);

  const _handleToggle = () => {
    setHideFullyReceived((d) => !d);
  };

  /* const _handleSave = () => {
    onSave({ defaultLabelQty, report });
    if (setConfigureInspector) {
      setConfigureInspector(false);
    }
  }; */

  return (
    <Box sx={wrapperSx}>
      <Box
        sx={[
          {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          },
          itemSx,
        ]}
      >
        <Typography variant="subtitle2">
          {t('settings.receivePOSettings.hideFullyReceived')}
        </Typography>
        <ETOCheckBox
          name="hideFullyReceived"
          value={hideFullyReceivedSetting}
          handleChange={() => _handleToggle()}
        />
      </Box>

      <Box sx={itemSx}>
        <TextField
          name="defaultLabelQty"
          select
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onChange={(e) => setDefaultLabelQty(e.target.value as unknown as any)}
          value={defaultLabelQty}
          label={t('settings.receivePOSettings.defaultLabelQty')}
          fullWidth
        >
          {defaultLabelOptions.map((item) => (
            <MenuItem key={item.value} value={item.key}>
              {item.key}
            </MenuItem>
          ))}
        </TextField>
      </Box>
      <Box sx={itemSx}>
        <ETOSelectField
          name="report"
          items={reports}
          disableClearable
          itemNameSelector={(i) => i.name}
          itemValueSelector={(i) => i.altId}
          handleChange={(e) =>
            setSelectedAltReportId(parseInt(e.target.value, 10))
          }
          value={selectedAltReportId}
          label={t('settings.receivePOSettings.report')}
        />
      </Box>
    </Box>
  );
};

export default MoreOptionsPanel;
