/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import { Box, SxProps, Theme, Typography } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import React, { useContext } from 'react';
import { TimeCard } from 'teto-client-api';
import {
  formatHoursAsHourMin,
  SettingsContext,
} from '@teto/react-component-library';
import TableColumnDefinition from '../TetoGrid/TableColumnDefinition';

const detailListDisplaySx = (theme: Theme) => ({
  alignItems: 'flex-start',
  columnGap: theme.spacing(1),
  display: 'flex',
  padding: `${theme.spacing(1.5)} ${theme.spacing(1.5)}`,
  width: '100%',
});

const contentSx = (theme: Theme) => ({
  alignItems: 'flex-start',
  color: theme.palette.text.primary,
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  justifyContent: 'flex-start',
  textOverflow: 'ellipsis',
  width: '100%',
});

const rowSx = (theme: Theme) => ({
  paddingLeft: theme.spacing(1),
  columnGap: theme.spacing(2),
  display: 'flex',
  justifyContent: 'flex-start',
  width: '100%',
});

const titleSx = {
  flexGrow: 0,
  fontWeight: 500,
  whiteSpace: 'nowrap',
};
interface DetailedListDisplayProps {
  customSx?: SxProps;
  timeCard: TimeCard;
  displayList: string[];
  leftChildren?: React.ReactNode;
  rightChildren?: React.ReactNode;
  columns: TableColumnDefinition[];
}

const DetailedListDisplay = (props: DetailedListDisplayProps) => {
  const {
    customSx,
    timeCard,
    displayList,
    leftChildren,
    rightChildren,
    columns,
  } = props;
  const { settings } = useContext(SettingsContext);

  const newLocal = displayList.map((i) => {
    const colName = columns.filter((cName) => {
      if (cName.name === i) {
        return cName.title;
      }
    });
    /* Checking if columns passed down, have a title and if the object has the property. */
    if (
      colName.length > 0 &&
      Object.prototype.hasOwnProperty.call(timeCard, i)
    ) {
      let detail;
      let temp;
      switch (colName[0].type) {
        case 'time':
          temp = dayjs(timeCard[i as keyof TimeCard] as Dayjs);
          detail = temp.isValid() ? temp.format(settings.timeFormat) : 'null';
          break;
        case 'date':
          temp = dayjs(timeCard[i as keyof TimeCard] as Dayjs);
          detail = temp.isValid() ? temp.format(settings.dateFormat) : 'null';
          break;
        case 'hours':
          detail = formatHoursAsHourMin(
            timeCard[i as keyof TimeCard] as number
          );
          break;
        default:
          detail = String(timeCard[i as keyof TimeCard]);
          break;
      }

      return detail !== 'null' ? (
        <Box sx={rowSx} key={i}>
          <Typography
            data-testid={colName[0].title}
            sx={titleSx}
            variant="body1"
            align="left"
            component="span"
          >
            {`${colName[0].title}:`}
          </Typography>
          <Typography
            data-testid={colName[0].name}
            variant="body1"
            align="left"
            component="span"
            sx={{ flexGrow: 1, whiteSpace: 'initial', wordBreak: 'break-word' }}
          >
            {detail}
          </Typography>
        </Box>
      ) : undefined;
    }
  });

  return (
    <Box
      sx={[
        detailListDisplaySx,
        ...(Array.isArray(customSx) ? customSx : [customSx]),
      ]}
    >
      {leftChildren && leftChildren}

      <Box sx={contentSx} data-testid="list">
        {columns && newLocal}
      </Box>

      {rightChildren && rightChildren}
    </Box>
  );
};

export default DetailedListDisplay;
