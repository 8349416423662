import {
  TypeGroupBy,
  TypeSortInfo,
} from '@inovua/reactdatagrid-enterprise/types';
import TableColumnDefinition from '../TableColumnDefinition';
import { GridPersistence } from './GridPersistenceOptions';

const calculateFilterDetails = (col: TableColumnDefinition) => {
  if (col.type === 'string' || col.type === 'foreignKey') {
    return {
      operator: 'contains',
      type: 'string',
    };
  }
  if (col.type === 'number' || col.type === 'hours') {
    return {
      operator: 'equal',
      type: 'number',
    };
  }
  if (col.type === 'bool' || col.type === 'boolean') {
    return {
      operator: 'equal',
      type: 'boolean',
    };
  }
  if (col.type === 'custom') {
    return {
      operator: 'contains',
      type: 'string',
    };
  }

  return {
    operator: 'containers',
    type: 'string',
  };
};

interface DefaultCol {
  value: string | null;
  emptyValue: string;
  operator: string;
  type: string;
  name: string;
}

const defaultFilterValues = (columns: TableColumnDefinition[]) => {
  const common: DefaultCol[] = columns
    .filter(
      (a) =>
        a.filterOptions &&
        [
          'string',
          'foreignKey',
          'number',
          'hours',
          'currency',
          'custom',
        ].indexOf(a.type) >= 0
    )
    .map((c) => ({
      name: c.name,
      ...calculateFilterDetails(c),
      value: '',
      emptyValue: '',
    }));

  const bools: DefaultCol[] = columns
    .filter((a) => a.filterOptions && ['bool', 'boolean'].indexOf(a.type) >= 0)
    .map((c) => ({
      name: c.name,
      ...calculateFilterDetails(c),
      value: '',
      emptyValue: '',
    }));

  return common.concat(bools);
};

// eslint-disable-next-line import/prefer-default-export
export const getDefault = (
  columns: TableColumnDefinition[],
  defaultGrouping?: TypeGroupBy,
  defaultSort?: TypeSortInfo
): GridPersistence => ({
  columnOrder: columns
    .sort((a, b) => (a.order > b.order ? 1 : -1))
    .map((a) => a.name),
  columnSizes: columns.map((a) => ({
    column: { name: a.name },
    width: a.width,
    flex: a.flex,
    minWidth: a.minWidth,
  })),
  filters: defaultFilterValues(columns),
  grouping: defaultGrouping ?? [],
  leftLockedColumns: columns
    .filter((a) => a.fixed === 'left')
    .map((a) => a.name),
  rightLockedColumns: columns
    .filter((a) => a.fixed === 'right')
    .map((a) => a.name),
  hidden: columns.filter((a) => a.hidden).map((a) => a.name),
  sort: defaultSort ?? [],
});
