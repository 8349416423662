import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Box,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  getTimeCardMigrationStatus,
  performTimecardMigration,
} from 'teto-client-api';
import {
  ETOButton,
  ETOSelectField,
  MessageContext,
  SettingsContext,
} from '@teto/react-component-library';

const dayOfWeekFormats = () => {
  const newArr = [];

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < 7; i++) {
    newArr.push({
      value: i,
      display: `${dayjs().day(i).format('dddd')}`,
    });
  }
  return newArr;
};

const TimecardMigrationDialog = () => {
  const [showTimecardMigrationModal, setShowTimecardMigrationModal] =
    useState(false);
  const [numOfRecords, setNumOfRecords] = useState(0);
  const settingsContext = useContext(SettingsContext);
  const messageContext = useContext(MessageContext);
  const [dayofWeek, setDayofWeek] = useState<number>(
    settingsContext.settings.startDayOfWeek
  );
  const { t } = useTranslation(['generic']);

  const _handleDayOfWeekChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setDayofWeek(parseInt(e.target.value, 10));
  };

  const _handleClose = () => {
    setShowTimecardMigrationModal(false);
  };

  const _startMigration = () =>
    settingsContext
      .updateSystemSettings({
        timeSheetStartDayOfWeek: dayofWeek as 0 | 1 | 2 | 3 | 4 | 5 | 6,
      })
      .then(() =>
        performTimecardMigration()
          .then(() => {
            messageContext.setSuccess(
              t('generic.message.startTimecardMigration')
            );
            setShowTimecardMigrationModal(false);
          })
          .catch((e) => {
            messageContext.setError(
              `${t('generic.message.failMigration')}. ${e.message}`
            );
          })
      )
      .catch((e) => {
        messageContext.setError(
          `${t('generic.message.failSystemSettings')}. ${e.message}`
        );
      });

  useEffect(() => {
    getTimeCardMigrationStatus()
      .then((d) => {
        if (d.migrationRequired && d.migrationRequired) {
          setShowTimecardMigrationModal(true);
        }
        setNumOfRecords(d.timecardsToMigrate);
      })
      .catch(() => {
        // do nothing, this isn't critical
      });
  }, []);

  return (
    <Dialog
      open={showTimecardMigrationModal}
      onClose={() => setShowTimecardMigrationModal(false)}
    >
      <DialogTitle>Migrate Timecards</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Box m={4} mt={1}>
            <Typography variant="subtitle1" align="center" color="primary">
              {numOfRecords} Timecards to migrate
            </Typography>
          </Box>
          Welcome to Total ETO Web. As part of our improvements to the Timecard
          module we need to upgrade your existing data to be compatible. This
          process is fully automated but can take some time to finish. Until
          this process is complete you will not be able to see any previous
          Timecards in your system.
          <br />
          <br />
          Before we perform this upgrade we strongly advise you to configure the
          settings below to ensure accurate data.
          <Box m={4}>
            <ETOSelectField
              name="startdayofweek"
              label={t('generic.startDayOfWeek')}
              value={dayofWeek}
              items={dayOfWeekFormats()}
              defaultValue={settingsContext.settings.startDayOfWeek}
              handleChange={(e) => _handleDayOfWeekChange(e)}
              itemNameSelector={(i) => i.display}
              itemValueSelector={(i) => i.value}
            />
          </Box>
          If you choose to migrate now this window will close and migration will
          continue in the background
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <ETOButton onClick={_handleClose} color="secondary" size="medium">
          Postpone
        </ETOButton>
        <ETOButton onClick={_startMigration} color="primary" size="medium">
          Start Migration
        </ETOButton>
      </DialogActions>
    </Dialog>
  );
};

export default TimecardMigrationDialog;
