import DragHandleRoundedIcon from '@mui/icons-material/DragHandleRounded';
import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Theme,
  Typography,
} from '@mui/material';
import { arrayMoveImmutable, ButtonStrip } from '@teto/react-component-library';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Draggable } from 'react-smooth-dnd';
import GridSettings from '../GridSettings';
import TableColumnDefinition from '../TableColumnDefinition';
import ColumnToggleCheckbox from './ColumnToggleCheckbox';

const assignedListSx = (theme: Theme) => ({
  overflowY: 'auto',
  maxHeight: 'calc(100% - 160px)',
  '& .smooth-dnd-draggable-wrapper': {
    borderRadius: `${theme.shape.borderRadius}px`,
  },
  paddingRight: 0.5,
});

const assignedListItemSx = (theme: Theme) => ({
  borderRadius: `${theme.shape.borderRadius}px`,
  paddingLeft: theme.spacing(1),
  paddingRight: 0,
});

const assignedListItemAltSx = (theme: Theme) => ({
  borderRadius: `${theme.shape.borderRadius}px`,
  paddingLeft: theme.spacing(1),
  paddingRight: 0,
  backgroundColor: theme.palette.background.default,
  color: theme.palette.getContrastText(theme.palette.background.default),
});

const dragHandleSx = (theme: Theme) => ({
  paddingRight: theme.spacing(1),
  cursor: 'pointer',
});

interface ConfigureColumnsPanelProps {
  columns: TableColumnDefinition[];
  initialColumnOrder: string[];
  // eslint-disable-next-line no-unused-vars
  onSave: (data: GridSettings) => void;
  setConfigureInspector: React.Dispatch<React.SetStateAction<boolean>>;
  setResetDialog: React.Dispatch<React.SetStateAction<boolean>>;
}

export const _getInitialOrder = (
  cols: TableColumnDefinition[],
  colsOrdered: string[]
) =>
  cols
    .filter((col) => col.name !== 'action')
    .sort((a, b) => {
      const aIndex = colsOrdered.indexOf(a.name); // 3
      const bIndex = colsOrdered.indexOf(b.name); // 2

      if (aIndex === -1 && bIndex === -1) return 0;
      if (aIndex === -1) return -1;
      if (bIndex === -1) return 1;

      return aIndex - bIndex;
    })
    .map((a) => a.name);

const ConfigureColumnsPanel = (props: ConfigureColumnsPanelProps) => {
  const { t } = useTranslation();
  const {
    columns,
    initialColumnOrder,
    onSave,
    setConfigureInspector,
    setResetDialog,
  } = props;

  const [columnOrder, setColumnOrder] = useState(initialColumnOrder);

  const [rightTooltip, setRightTooltip] = useState('');

  const [cols, setCols] = useState<TableColumnDefinition[]>(
    columns.filter(
      (col) =>
        col.name !== 'action' &&
        col.name !== 'mobileCustomGrouping' &&
        col.name !== 'mobileCustomData'
    )
  );

  const _handleColumnToggle = (cName: string) => {
    setCols((items) =>
      items.map((e) => (e.name === cName ? { ...e, hidden: !e.hidden } : e))
    );
  };

  const _handleColumnDrag = ({
    removedIndex,
    addedIndex,
  }: {
    removedIndex: number | null;
    addedIndex: number | null;
  }) => {
    if (removedIndex !== null && addedIndex !== null) {
      setColumnOrder((items) =>
        arrayMoveImmutable(items, removedIndex, addedIndex)
      );
      setCols((item) => arrayMoveImmutable(item, removedIndex, addedIndex));
    }
  };

  const _handleSave = () => {
    const hidden: string[] = [];
    // columnOrder.push('action');
    cols.forEach((e: TableColumnDefinition) => e.hidden && hidden.push(e.name));
    if (cols.every((col) => col.hidden)) {
      setRightTooltip(
        'Cannot save configuration with less than one column selected.'
      );
      return;
    }
    setRightTooltip('');
    setConfigureInspector(false);
    const uniqCols = [...new Set(columnOrder)];
    onSave({
      columnOrder: uniqCols,
      hidden,
    });
  };

  return (
    <>
      {/* <TabPanel customSx={panelSx} value={value} index={0}> */}
      <Typography variant="subtitle2">{t('generic.showColumns')}</Typography>
      <List sx={assignedListSx} disablePadding>
        <Container
          dragHandleSelector=".drag-handle"
          lockAxis="y"
          onDrop={(e) => _handleColumnDrag(e)}
          data-testid="configureInspectorListItemContainer"
        >
          {cols.map((col, ind) => (
            <Draggable key={col.name}>
              <ListItem
                data-testid="configureInspectorListItem"
                sx={[
                  assignedListItemSx,
                  ind % 2 === 0 && assignedListItemAltSx,
                ]}
              >
                <ListItemText>
                  <ColumnToggleCheckbox
                    column={col}
                    onChange={() => _handleColumnToggle(col.name)}
                    disabled={col.disableHideable}
                  />
                </ListItemText>
                {col.name !== 'action' && (
                  <ListItemSecondaryAction
                    sx={dragHandleSx}
                    className="drag-handle"
                  >
                    <IconButton disabled={col.disableHideable} size="large">
                      <DragHandleRoundedIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                )}
              </ListItem>
            </Draggable>
          ))}
        </Container>
      </List>
      <ButtonStrip
        size="medium"
        leftButton={{
          text: t('generic.resetGridSettings'),
          onClick: () => setResetDialog(true),
          color: 'warning',
        }}
        rightButton={{
          text: t('generic.save'),
          onClick: () => _handleSave(),
          color: 'primary',
          tooltip: rightTooltip,
        }}
      />
      {/* </TabPanel> */}
    </>
  );
};

export default ConfigureColumnsPanel;
