/* eslint-disable @typescript-eslint/no-explicit-any */
import ReactDataGrid from '@inovua/reactdatagrid-enterprise';
import {
  TypeColumn,
  TypeComputedProps,
  TypeExpandedNodes,
} from '@inovua/reactdatagrid-enterprise/types';
import ViewListRoundedIcon from '@mui/icons-material/ViewListRounded';
import { Tooltip, useMediaQuery, useTheme } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import {
  AppearanceContext,
  AuthContext,
  MessageContext,
} from '@teto/react-component-library';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import {
  addEmployeeApprover,
  deleteEmployeeApprover,
  getEmployees,
  Licenses,
} from 'teto-client-api';
import FormatterProps from '../../../components/TetoGrid/Formatters/FormatterProps';
import { reactDataGridLicenseKey } from '../../../components/TetoGrid/Licensing';
import ApproverFormatter from '../ApproverFormatter';
import AssignEmployeesInspector from '../AssignEmployeesInspector';

const defaultFilterValue = () => [
  {
    name: 'employeeNumber',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'name',
    operator: 'contains',
    type: 'string',
    value: '',
  },
];

const EmployeeApprovers = () => {
  const { t } = useTranslation();

  const messageContext = useContext(MessageContext);
  const appearanceContext = useContext(AppearanceContext);
  const authContext = useContext(AuthContext);

  const gridRef = useRef<TypeComputedProps | null>(null);

  const [editingRecordId, setEditingRecordId] =
    useState<string | number | undefined>();
  const [expandedNodes, setExpandedNodes] = useState<TypeExpandedNodes>({});
  const [filterValue, setFilterValue] = useState(defaultFilterValue());

  const query = useQuery(
    ['employee-list'],
    () =>
      getEmployees({
        disablePaging: true,
        filter: { includeApprovers: true, active: true },
        orderBy: { employeeNumber: false },
      }),
    {
      refetchOnWindowFocus: false,
    }
  );

  const theme = useTheme();
  const mobileSize = useMediaQuery(theme.breakpoints.down('md'));

  const scrollStyle = {
    scrollThumbStyle: { backgroundColor: theme.palette.primary.main },
  };

  const currentTheme =
    appearanceContext.themeVariant === 'dark'
      ? 'default-dark'
      : 'default-light';

  const onExpandedNodesChange = useCallback((d) => {
    setExpandedNodes(d.expandedNodes);
  }, []);

  useEffect(() => {
    const res: { [key: string | number]: boolean } = {};

    query.data?.records.forEach((a) => {
      res[a.name] = true;
    });

    setExpandedNodes(res);
  }, [query.data]);

  // eslint-disable-next-line arrow-body-style
  const formattedData = useMemo(() => {
    // const results: Employee[] = [];
    const nameFilter = filterValue.find((a) => a.name === 'name');
    const empNumberFilter = filterValue.find(
      (a) => a.name === 'employeeNumber'
    );

    return (
      query.data?.records.filter(
        (e) =>
          (!nameFilter?.value ||
            e.name.toLowerCase().includes(nameFilter.value.toLowerCase())) &&
          (!empNumberFilter?.value ||
            e.employeeNumber
              .toLowerCase()
              .includes(empNumberFilter.value.toLowerCase()))
      ) ?? []
    );
  }, [query.data, filterValue]);

  const columns: TypeColumn[] = useMemo(
    () => [
      {
        name: 'employeeNumber',
        header: t('Entities.Employee.empNumber'),
        sortable: false,
        minWidth: mobileSize ? 80 : 160,
      },
      {
        name: 'name',
        header: t('Entities.Employee.fullName'),
        sortable: false,
        defaultFlex: mobileSize ? null : 1,
        defaultWidth: 180,
      },
      {
        name: 'approvers',
        header: t('Entities.TimeSheet.approvers'),
        render: (d: FormatterProps) => <ApproverFormatter {...d} />,
        sortable: false,
        defaultWidth: 160,
      },
      {
        name: 'view',
        header: '',
        width: 50,
        resizable: false,
        sortable: false,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        render: ({ data }) => (
          <Tooltip title={t('generic.editApprovers')} placement="bottom-end">
            <IconButton
              size="small"
              color="primary"
              disabled={!authContext.hasLicense(Licenses.TotalETOEnterprise)}
              onClick={() => setEditingRecordId(data.id)}
            >
              <ViewListRoundedIcon />
            </IconButton>
          </Tooltip>
        ),
        showInContextMenu: false,
        showColumnMenuTool: false,
      },
    ],
    [mobileSize, t, authContext]
  );

  useEffect(() => {
    gridRef.current?.reload();
  }, [formattedData]);

  const editingRecord = useMemo(
    // eslint-disable-next-line eqeqeq
    () => formattedData.find((a) => a.id == editingRecordId),
    [editingRecordId, formattedData]
  );
  const _onAssign = useCallback(
    (employeeId: number, record: any) => {
      const promise = addEmployeeApprover(record.id, employeeId);

      promise
        .then(() => {
          query.refetch();
        })
        .catch((e) => {
          messageContext.setError(e.message);
        });

      return promise;
    },
    [query, messageContext]
  );

  const _onUnAssign = useCallback(
    (employeeId: number, record: any) => {
      const promise = deleteEmployeeApprover(record.id, employeeId);

      promise
        .then(() => {
          query.refetch();
        })
        .catch((e) => {
          messageContext.setError(e.message);
        });

      return promise;
    },
    [query, messageContext]
  );

  const inspectorLabel = useMemo(() => {
    if (!editingRecord) return '';
    return `Approvers For ${editingRecord?.firstName} ${editingRecord?.lastName}`;
  }, [editingRecord]);

  const onFilterValueChange = useCallback((value) => {
    setFilterValue(value);
  }, []);

  const remoteDS = useCallback(
    () => Promise.resolve(formattedData),
    [formattedData]
  );

  return (
    <>
      {query.isSuccess && (
        <ReactDataGrid
          treeColumn="name"
          licenseKey={reactDataGridLicenseKey}
          expandedNodes={expandedNodes}
          onExpandedNodesChange={onExpandedNodesChange}
          columns={columns}
          dataSource={remoteDS}
          defaultFilterValue={filterValue}
          onFilterValueChange={onFilterValueChange}
          showColumnMenuFilterOptions={false}
          showFilteringMenuItems={false}
          scrollProps={scrollStyle}
          handle={(r) => {
            gridRef.current = r ? r.current : null;
          }}
          theme={currentTheme}
        />
      )}
      {editingRecordId && (
        <AssignEmployeesInspector
          onAssign={_onAssign}
          onClose={() => setEditingRecordId(undefined)}
          onUnassign={_onUnAssign}
          selectedEmployees={editingRecord?.approvers?.map((i) => i.id) ?? []}
          inferredSelectedEmployees={[]}
          title={inspectorLabel}
          record={editingRecord}
        />
      )}
    </>
  );
};

export default EmployeeApprovers;
