/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  IconButton,
  Typography,
  Box,
  SxProps,
  Theme,
  ListItemButton,
} from '@mui/material';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import HowToVoteOutlinedIcon from '@mui/icons-material/HowToVoteOutlined';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import StopRoundedIcon from '@mui/icons-material/StopRounded';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ConfirmDialogs,
  formatHoursAsHourMin,
  MessageContext,
  SettingsContext,
} from '@teto/react-component-library';
import TableColumnDefinition from '../../../components/TetoGrid/TableColumnDefinition';
import isNotEmptyOrNullLocation from '../helpers/isNotEmptyOrNullLocation';

type Setter<T> = React.Dispatch<React.SetStateAction<T | undefined>>;
interface PunchInListItemProps<C> {
  data: any;
  entryNum: number;
  isSelected: boolean;
  isActive: boolean;
  fields: TableColumnDefinition[] | undefined;
  // eslint-disable-next-line no-unused-vars
  onStop: (id: number) => void;
  // eslint-disable-next-line no-unused-vars
  onCancel: (id: number) => void;
  handleClick: (
    // eslint-disable-next-line no-unused-vars
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    // eslint-disable-next-line no-unused-vars
    coords: number[] | undefined,
    // eslint-disable-next-line no-unused-vars
    record: C
  ) => void;
  useApiFallback: boolean;
  setUseApiFallback: Setter<boolean>;
}

const leftContentSx: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '50%',
};

const listItemSx: SxProps<Theme> = (theme: Theme) => ({
  display: 'flex',
  justifyContent: 'space-between',
  borderBottom: `1px solid ${theme.palette.divider}`,
  '& .MuiTypography-root': {
    color: theme.palette.text.primary,
  },
  '&.Mui-selected': {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.getContrastText(theme.palette.primary.dark),
    '& .MuiTypography-root': {
      color: theme.palette.getContrastText(theme.palette.primary.dark),
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.getContrastText(theme.palette.primary.dark),
    },
  },
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.getContrastText(theme.palette.primary.dark),
    '& .MuiTypography-root': {
      color: theme.palette.getContrastText(theme.palette.primary.dark),
    },
  },
});

const rightContentSx: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  alignSelf: 'flex-end',
  position: 'relative',
};

const greenDotSx: SxProps<Theme> = (theme: Theme) => ({
  color: theme.palette.success.main,
});

const punchInActionsSx: SxProps<Theme> = (theme: Theme) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  '& button': {
    color: theme.palette.primary.contrastText,
    '& :hover': {
      color: theme.palette.error.main,
    },
  },
});

const boldSx: SxProps = {
  fontWeight: 500,
};

const arcGISGeocodeURL =
  'https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/reverseGeocode?f=pjson&langCode=EN&token=AAPK5485e993be9748cca512eb51fea43c18FxsGbX-LCwG5TLaGeHEaLtE-Z7E1NjSdMLP9_dZdmdzBM6TN-etMVK04HgYoFFyI&location=';
const photonURL = 'https://photon.komoot.io/reverse?';

// Possible backup url to the backup?
// const nominatimURL = 'https://nominatim.openstreetmap.org/reverse?geojson&';

const getCoordsType = (data: any) => {
  if (data) {
    if (Object.keys(data).includes('timeSheetId')) {
      return 'timecardCoords';
    }
    return 'punchInCoords';
  }
  return 'timecardsCoords';
};

// eslint-disable-next-line react/function-component-definition
function PunchInListItem<C>(props: PunchInListItemProps<C>) {
  const messageContext = useContext(MessageContext);
  const settingsContext = useContext(SettingsContext);
  const {
    data,
    entryNum,
    fields,
    isActive,
    useApiFallback,
    setUseApiFallback,
    handleClick,
    onCancel,
    onStop,
    isSelected,
  } = props;
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [reverseGeocodeResults, setReverseGeocodeResults] =
    useState<any | undefined>();
  const [cancelDialog, setCancelDialog] = useState<boolean>(false);

  const coordsType = getCoordsType(data);
  const dataCoords = data[coordsType];
  const convertedCoords = dataCoords
    ?.split(', ')
    .map((d: string) => parseFloat(d));

  useEffect(() => {
    if (
      isSelected &&
      convertedCoords &&
      !reverseGeocodeResults &&
      !useApiFallback
    ) {
      fetch(`${arcGISGeocodeURL}${convertedCoords[1]},${convertedCoords[0]}`)
        .then((response) => response.json())
        .then((d) => {
          if (d.error) {
            setUseApiFallback(true);
          } else {
            setReverseGeocodeResults(
              d.address
                ? `${d.address.City}, ${d.address.CntryName}`
                : 'Unknown'
            );
          }
        })
        .catch((e) => {
          if (e.code !== '200') {
            setUseApiFallback(true);
          }
          messageContext.setError(e.statusText);
        });
    }
    if (
      useApiFallback &&
      isSelected &&
      convertedCoords &&
      !reverseGeocodeResults
    ) {
      fetch(`${photonURL}lon=${convertedCoords[1]}&lat=${convertedCoords[0]}`)
        .then((response) => response.json())
        .then((d) => {
          setReverseGeocodeResults(
            d.features[0]
              ? `${d.features[0].properties.city}, ${d.features[0].properties.country}`
              : 'Unknown'
          );
        })
        .catch((e) => {
          messageContext.setError(e.statusText);
        });
    }
  }, [
    setUseApiFallback,
    convertedCoords,
    isSelected,
    messageContext,
    reverseGeocodeResults,
    useApiFallback,
  ]);

  const fieldsToRender = useMemo(
    () => fields?.filter((fv) => !fv.hidden),
    [fields]
  );

  const _handleFieldRender = (
    f: string | number | dayjs.Dayjs | Date | null | undefined
  ) => {
    if (f === undefined) return '-';
    if (dayjs.isDayjs(f)) {
      return dayjs(f).format(settingsContext.settings.dateFormat);
    }
    return f;
  };

  const isPunchIn =
    data.timecardPunchInID !== undefined || coordsType === 'punchInCoords';
  return (
    <>
      <ListItemButton
        sx={listItemSx}
        id={entryNum.toString()}
        onClick={(e) => handleClick(e, convertedCoords, data)}
        selected={isSelected}
      >
        <Box sx={leftContentSx}>
          {!isActive && (
            <Typography variant="body2">
              {isPunchIn
                ? dayjs(data.startTime).format(
                    settingsContext.settings.dateFormat
                  )
                : dayjs(data.date).format(settingsContext.settings.dateFormat)}
            </Typography>
          )}
          <Typography variant="body2">{data.employeeFullName}</Typography>
          <Box
            sx={{
              display: 'flex',
            }}
          >
            {data[coordsType] && isNotEmptyOrNullLocation(data[coordsType]) && (
              <LocationOnIcon />
            )}
            {isPunchIn &&
              data[coordsType] &&
              isNotEmptyOrNullLocation(data[coordsType]) && (
                <HowToVoteOutlinedIcon />
              )}
          </Box>
          {isSelected && reverseGeocodeResults && (
            <Typography variant="caption">{reverseGeocodeResults}</Typography>
          )}
          {isSelected &&
            fieldsToRender?.map((field: TableColumnDefinition) => (
              <>
                <Typography variant="body2" sx={boldSx}>
                  {field.title}:
                </Typography>
                <Typography key={`field-${field.name}`} variant="caption">
                  {_handleFieldRender((data as any)[field.name])}
                </Typography>
              </>
            ))}
        </Box>

        <Box sx={rightContentSx}>
          <Box>
            {isActive && (
              <Box component="span" sx={greenDotSx}>
                ● &nbsp;
              </Box>
            )}
            <Typography variant="caption">
              {isPunchIn
                ? `${t('forms.fields.startTime')}: ${dayjs(
                    data.startTime
                  ).format(settingsContext.settings.timeFormat)}`
                : `${t('Entities.Timecard.hourTime')}: ${formatHoursAsHourMin(
                    data.hourTime
                  )}`}
            </Typography>
          </Box>
          {!isActive && isPunchIn && (
            <Typography variant="caption">
              {t('forms.fields.endTime')}:{' '}
              {dayjs(data.endTime).format(settingsContext.settings.timeFormat)}
            </Typography>
          )}
          {data.elapsedMinutes && (
            <Typography variant="caption">
              {t('generic.duration')}:{' '}
              {data.elapsedMinutes
                ? formatHoursAsHourMin(data.elapsedMinutes / 60)
                : '0:00'}
            </Typography>
          )}
        </Box>

        {isSelected && isActive && (
          <Box sx={punchInActionsSx}>
            <IconButton onClick={() => onStop(data.id)} size="large">
              <StopRoundedIcon />
            </IconButton>
            <IconButton onClick={() => setCancelDialog(true)} size="large">
              <CancelRoundedIcon />
            </IconButton>
          </Box>
        )}
      </ListItemButton>

      {cancelDialog && (
        <ConfirmDialogs
          open={cancelDialog}
          title={t('dialogs.abortTimer.title')}
          content={t('dialogs.abortTimer.content')}
          leftButton={{
            onClick: () => setCancelDialog(false),
            label: t('generic.cancel'),
          }}
          rightButton={{
            onClick: () => {
              onCancel(data.id);
              setCancelDialog(false);
            },
            label: t('generic.confirm'),
          }}
        />
      )}
    </>
  );
}

export default PunchInListItem;
