import { formatHoursAsHourMin } from '@teto/react-component-library';
import { useFormikContext } from 'formik';
import React from 'react';
import { Errors } from '../InlineEditing/editorTypes';
import EmptyDisplay from './EmptyDisplay';
import FormatterErrorWrapper from './FormatterErrorWrapper';
import FormatterProps from './FormatterProps';

type NumberFormats = 'number' | 'currency' | 'hours';

const NumberFormatter = (
  props: FormatterProps & { numberFormat: NumberFormats }
) => {
  const {
    value,
    column: { name, emptyDisplay },
    data,
    numberFormat,
    cellProps,
  } = props;
  const formik = useFormikContext();
  const { errors } = formik;
  const { editorProps } = cellProps;
  const error = errors[name as keyof Errors];
  const rowErrorIndex =
    (Object.hasOwn(data, '__editing') && data.__editing && name) === name;
  let element;

  if (value == null || value === undefined) {
    element = <EmptyDisplay placeHolder={emptyDisplay} />;
  } else if (numberFormat === 'currency') {
    element = <span data-testid="numbers">${value.toFixed(2)}</span>;
  } else if (numberFormat === 'hours') {
    element = <span>{formatHoursAsHourMin(value)}</span>;
  } else {
    element = <span>{value}</span>;
  }

  if (editorProps) {
    switch (editorProps.inlineEditMode) {
      case 'default':
        if (editorProps.inlineEditingCondition) {
          const result = editorProps.inlineEditingCondition(
            value,
            cellProps.data
          );

          if (result?.errorIs !== false) {
            return (
              <FormatterErrorWrapper
                errorIs={result.errorIs}
                errorText={result?.errorText}
              >
                {element}
              </FormatterErrorWrapper>
            );
          }
        }
        break;

      default:
        if (error && rowErrorIndex) {
          return (
            <FormatterErrorWrapper errorIs errorText={error}>
              {element}
            </FormatterErrorWrapper>
          );
        }
        break;
    }
  }
  return element;
};

export default NumberFormatter;
