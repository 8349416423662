import { MessageContext } from '@teto/react-component-library';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { MutationKey, useMutation, useQueryClient } from 'react-query';
import { approveTimesheet } from 'teto-client-api';
import TIMESHEET_QUERY_ID from '../../../api/reactQuery/TimesheetsQueryId';
import RDGSelectedType from '../../TetoGrid/RDGSelectedType';
// https://react-query.tanstack.com/reference/useMutation

const useApproveMultipleTimesheets = () => {
  const queryClient = useQueryClient();
  const messageContext = useContext(MessageContext);
  const { t } = useTranslation();

  const _handleMultipleApprove = async (props: RDGSelectedType | undefined) => {
    if (!props) return;

    const timesheets = Object.values(props);

    if (!timesheets) return;
    const approveTs = timesheets.map(
      async (timesheet) =>
        // eslint-disable-next-line no-return-await
        await approveTimesheet(timesheet, '')
    );
    const queryIdentifier = TIMESHEET_QUERY_ID;
    Promise.all(approveTs)
      .then(() => {
        queryClient.invalidateQueries(queryIdentifier, {
          refetchActive: true,
          refetchInactive: true,
        });
        messageContext.setSuccess(t('generic.message.timesheetApprove'));
      })
      .catch((e) => {
        queryClient.invalidateQueries(queryIdentifier, {
          refetchActive: true,
          refetchInactive: true,
        });
        messageContext.setError(`${e.message}: ${t('auth.permission')}`);
      });
  };

  const mutation = useMutation<
    unknown,
    typeof messageContext.setError,
    RDGSelectedType | undefined
  >(_handleMultipleApprove as unknown as MutationKey);

  return mutation;
};

export default useApproveMultipleTimesheets;
