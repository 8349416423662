import LocationOnIcon from '@mui/icons-material/LocationOn';
import StopRoundedIcon from '@mui/icons-material/StopRounded';
import {
  Box,
  IconButton,
  SxProps,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  AuthContext,
  MessageContext,
  SettingsContext,
} from '@teto/react-component-library';
import { LatLngExpression, Map } from 'leaflet';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { QueryFunctionContext, QueryKey } from 'react-query';
import {
  cancelPunchIn,
  getModelMetadata,
  getPunchIns,
  Licenses,
  ModelMetadata,
  Permission,
  PunchIn,
} from 'teto-client-api';
import ExportButton from '../../components/Buttons/ExportButton/ExportButton';
import MapPopover from '../../components/MapWrapper/MapPopover/MapPopover';
import FormatterProps from '../../components/TetoGrid/Formatters/FormatterProps';
import { createColumnsFromMetaData } from '../../components/TetoGrid/ModelMetaDataProcessor/ModelMetaDataProcessor';
import RDGSelectedType from '../../components/TetoGrid/RDGSelectedType';
import TetoContainer from '../../components/TetoGrid/TetoContainer';
import TetoGrid from '../../components/TetoGrid/TetoGrid';
import TetoGridRefType from '../../components/TetoGrid/TetoGridRefType';
import TimerContext from '../../components/TimeSheets/NavTimer/PunchInTimerContext';
import StopTimeInspector from '../../components/TimeSheets/TimerInspectors/StopTimeInspector/StopTimeInspector';
import isNotEmptyOrNullLocation from '../GeolocationPage/helpers/isNotEmptyOrNullLocation';
import ActionButton from '../Timesheets/ApprovalsPage/components/ActionButton/ActionButton';

const fabContainerSx = (theme: Theme) => ({
  bottom: theme.spacing(6.5),
  position: 'fixed',
  right: theme.spacing(2),
  zIndex: 10,
});

const ActiveDisplay = (props: FormatterProps) => {
  const { t } = useTranslation();
  const { data } = props;
  if (!data.__group) {
    return (
      <Typography
        variant="body2"
        color={data.active ? 'primary' : 'textPrimary'}
      >
        {data.active ? t('generic.active') : t('generic.completed')}
      </Typography>
    );
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
};

const PunchInsPage = () => {
  const { t, ready } = useTranslation();

  const authContext = useContext(AuthContext);
  const messageContext = useContext(MessageContext);
  const timerContext = useContext(TimerContext);
  const settingsContext = useContext(SettingsContext);

  const theme = useTheme();
  const mobileSize = useMediaQuery(theme.breakpoints.down('md'));
  const mapWidth = mobileSize ? 300 : 400;
  const mapHeight = mobileSize ? 300 : 400;

  const [refreshToken, setRefreshToken] = useState<Date | undefined>();
  const [editingPunchIn, setEditingPunchIn] = useState<number | undefined>();
  const [metaData, setMetaData] = useState<ModelMetadata | undefined>();
  const [selectedRows, setSelectedRows] = useState<RDGSelectedType>();
  const [mapModalEl, setMapModalEl] = useState<HTMLButtonElement | null>(null);
  const [map, setMap] = useState<Map>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [dataArr, setDataArr] = useState<any[]>([]);
  const [mapCoordinates, setMapCoordinates] = useState<LatLngExpression>();
  const [dataSource, setDataSource] = useState([]);
  const gridRef = React.useRef<TetoGridRefType | undefined>();

  const canAccessPunchin =
    authContext.hasEnterpriseLicense() &&
    (authContext.hasAnyPermission([
      Permission.Modify_Admin_Timecards,
      Permission.View_Admin_Timecards,
    ]) ||
      (settingsContext.settings.approverCanManageTime &&
        authContext.user?.isApprover));

  const canModify =
    authContext.hasLicense(Licenses.TotalETOEnterprise) &&
    (authContext.hasPermission(Permission.Modify_Admin_Timecards) ||
      (settingsContext.settings.approverCanManageTime &&
        authContext.user?.isApprover));

  useEffect(() => {
    if (authContext.hasEnterpriseLicense()) {
      getModelMetadata('punchin')
        .then((e) => setMetaData(e))
        .catch((e) => {
          messageContext.setError(
            `${t('generic.message.failMeta')}.  ${e.message}`
          );
        });
    }
  }, [authContext, messageContext, t]);

  const handleMapClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any
  ) => {
    setDataArr([data]);
    setMapCoordinates(
      data.punchInCoords &&
        isNotEmptyOrNullLocation(data.punchInCoords) &&
        (data.punchInCoords
          .split(', ')
          .map((d: string) => parseFloat(d)) as LatLngExpression)
    );
    setMapModalEl(event.currentTarget);
  };

  const cols = useMemo(() => {
    if (metaData && ready) {
      return createColumnsFromMetaData<PunchIn>(metaData)
        .updateDefinition('active', {
          align: 'center',
        })
        .updateDefinition('startTime', { disableGrouping: true })
        .updateDefinition('endTime', { disableGrouping: true })
        .updateDefinition('projectName', { width: 100 })
        .updateDefinition('specName', { width: 100 })
        .updateDefinition('hourTypeName', { width: 100 })
        .updateDefinition('employeeFullName', { width: 200 })
        .updateDefinition('custom7', { width: 120 })
        .updateDefinition('custom8', { width: 120 })
        .updateDefinition('duration', {
          filterOptions: undefined,
        })
        .updateDefinition('completedReason', {
          filterOptions: undefined,
        })
        .updateDefinition('empNumber', {
          filterOptions: undefined,
        })
        .updateDefinition('id', {
          filterOptions: undefined,
        })
        .updateDefinition('departmentName', {
          filterOptions: undefined,
        })
        .updateDefinition('subDepartmentName', {
          filterOptions: undefined,
        })
        .updateDefinition('processScheduleDetailId', {
          filterOptions: undefined,
        })
        .addCustomFormatterForColumn('active', (props) => (
          <ActiveDisplay {...props} />
        ))
        .removeProperty(
          'custom1',
          () => t('Entities.Timecard.TimecardCustom1') === 'Timecard Custom 1'
        )
        .removeProperty(
          'custom2',
          () => t('Entities.Timecard.TimecardCustom2') === 'Timecard Custom 2'
        )
        .removeProperty(
          'custom3',
          () => t('Entities.Timecard.TimecardCustom3') === 'Timecard Custom 3'
        )
        .removeProperty(
          'custom4',
          () => t('Entities.Timecard.TimecardCustom4') === 'Timecard Custom 4'
        )
        .removeProperty(
          'custom5',
          () => t('Entities.Timecard.TimecardCustom5') === 'Timecard Custom 5'
        )
        .removeProperty(
          'custom6',
          () => t('Entities.Timecard.TimecardCustom6') === 'Timecard Custom 6'
        )
        .removeProperty(
          'custom7',
          () => t('Entities.Timecard.TimecardCustom7') === 'Timecard Custom 7'
        )
        .removeProperty(
          'custom8',
          () => t('Entities.Timecard.TimecardCustom8') === 'Timecard Custom 8'
        )
        .addColumn({
          name: 'timecardGeolocation',
          title: t('menu.geolocation'),
          type: 'button',
          align: 'center',
          sortable: false,
          editable: false,
          filterType: 'none',
          fixed: 'none',
          disableGrouping: true,
          disableHideable: false,
          disableColumnMenuTool: true,
          minWidth: mobileSize ? 60 : 100,
          width: mobileSize ? 60 : 100,
          headerProps: {
            style: {
              textAlign: 'center',
            },
          },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          renderFunction: (data: any) =>
            data.punchInCoords &&
            isNotEmptyOrNullLocation(data.punchInCoords) ? (
              <Box>
                <IconButton
                  onClick={(e) => handleMapClick(e, data)}
                  color="primary"
                >
                  <LocationOnIcon />
                </IconButton>
              </Box>
            ) : (
              '-'
            ),
        })
        .addColumn({
          name: 'action',
          title: t('generic.action'),
          type: 'button',
          align: mobileSize ? 'center' : 'end',
          sortable: false,
          filterType: 'none',
          fixed: 'right',
          disableGrouping: true,
          disableHideable: true,
          disableColumnMenuTool: true,
          width: 60,
          minWidth: 60,
          renderFunction: (data: PunchIn) => (
            <ActionButton
              data={data}
              actionItems={[
                ...(data.active
                  ? [
                      {
                        componentName: t('forms.stopTimer'),
                        icon: <StopRoundedIcon />,
                        title: t('forms.stopTimer'),
                        handleClick: () => setEditingPunchIn(data.id),
                        disabled: !canModify,
                        color: canModify
                          ? 'error'
                          : ('grey' as 'error' | 'grey'),
                      },
                    ]
                  : []),
              ]}
            />
          ),
        })
        .finalize(t);
    }
    return [];
  }, [canModify, metaData, mobileSize, ready, t]);

  const _doQuery = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    context: QueryFunctionContext<QueryKey, any>,
    pageSize: number,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    queryData: { filters: any; orderBy: any }
  ) =>
    getPunchIns({
      pageIndex: context.pageParam,
      pageSize,
      disablePaging: false,
      orderBy: queryData.orderBy,
      filter: {
        ...queryData.filters,
      },
    }).then((d) => ({
      ...d,
      records: d.records,
    }));

  const _onCancelTimer = (id: number) => {
    cancelPunchIn(id)
      .then(() => {
        setEditingPunchIn(undefined);
        setRefreshToken(new Date());
        messageContext.setSuccess(t('Entities.PunchIn.abortTimerSuccess'));
      })
      .catch(() => {
        messageContext.setError(t('Entities.PunchIn.abortTimerError'));
      });
  };
  const ExportBtn = ExportButton({ gridRef });

  return (
    <TetoContainer withBottomNav>
      {ready && cols && metaData && (
        <>
          <TetoGrid
            dataSource={dataSource}
            setDataSource={setDataSource}
            disableMobileCols
            serverSideFiltering
            ref={gridRef}
            pageSize={50000}
            defaultSort={[{ name: 'active', dir: 1 }]}
            // eslint-disable-next-line react/jsx-no-bind
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            doQuery={(a, b, c) => _doQuery(a, b, c as any)}
            columns={cols}
            refreshToken={refreshToken}
            externalQueryProps={{ refreshToken: timerContext.refreshToken }}
            tableIdentifier={t('Entities.PunchIn.tableIdentifier')}
            header={{
              rightChildren: [ExportBtn],
              FABPosition: fabContainerSx as SxProps,
            }}
            setSelectedRows={setSelectedRows}
            selectedRows={selectedRows}
            withBottomNav
          />
          {editingPunchIn && canAccessPunchin && (
            <StopTimeInspector
              id={editingPunchIn}
              onClose={() => setEditingPunchIn(undefined)}
              onUpdate={() => {
                setEditingPunchIn(undefined);
                setRefreshToken(new Date());
                messageContext.setSuccess(
                  t('Entities.PunchIn.stopTimerSuccess')
                );
              }}
              canUseTimer={canAccessPunchin}
              onCancel={() => _onCancelTimer(editingPunchIn)}
            />
          )}
          {Boolean(mapModalEl) && (
            <MapPopover
              dataArr={dataArr}
              map={map}
              setMap={setMap}
              mapCoordinates={mapCoordinates}
              mapModalEl={mapModalEl}
              setMapModalEl={setMapModalEl}
              mapWidth={mapWidth}
              mapHeight={mapHeight}
            />
          )}
        </>
      )}
    </TetoContainer>
  );
};
export default PunchInsPage;
