import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import EditIcon from '@mui/icons-material/Edit';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import LockRoundedIcon from '@mui/icons-material/LockRounded';
import React, { useEffect, useState } from 'react';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, Tooltip } from '@mui/material';
import { ETOIconButton } from '@teto/react-component-library';
import { Form, useFormikContext } from 'formik';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

const iconBtnSx = {
  padding: 0,
};

interface EditButtonProps<T> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  // eslint-disable-next-line no-unused-vars
  onClick: (data: T) => void;
}

type Errors = { [key: string]: string | undefined } | undefined;

// eslint-disable-next-line react/function-component-definition
function EditButton<T>(props: EditButtonProps<T>) {
  const formik = useFormikContext();
  const { t } = useTranslation();
  const errors: Errors = formik?.errors;
  const isValidationError = !isEmpty(errors);
  const { data, onClick } = props;
  const [dirty, setDirty] = useState<boolean>(false);
  const errorsTitle = errors?.title;

  useEffect(() => {
    if (formik.dirty) {
      setDirty(true);
    }
  }, [formik.dirty]);

  if (
    Object.hasOwn(data, '__editing') &&
    data.__editing &&
    data.__editing &&
    !isValidationError
  ) {
    return (
      <Form>
        <ETOIconButton
          disabled={!dirty}
          color="success"
          size="medium"
          type="submit"
          onClick={() => formik.submitForm()}
          buttonProps={{
            tabIndex: 0,
          }}
          tooltipProps={{
            title: t('generic.saveChange'),
            placement: 'bottom-end',
          }}
        >
          <CheckRoundedIcon />
        </ETOIconButton>
        <ETOIconButton
          color="error"
          size="medium"
          type="reset"
          onClick={() => formik.resetForm()}
          buttonProps={{
            tabIndex: 0,
          }}
          tooltipProps={{
            title: t('generic.discardChange'),
            placement: 'bottom-end',
          }}
        >
          <CloseRoundedIcon />
        </ETOIconButton>
      </Form>
    );
  }

  if (
    Object.hasOwn(data, '__editing') &&
    data.__editing &&
    data.__editing &&
    isValidationError
  ) {
    return (
      <>
        <Tooltip
          title={errorsTitle as string}
          placement="bottom-end"
          leaveTouchDelay={0}
          arrow
        >
          <IconButton aria-label="Error" sx={iconBtnSx} size="large">
            <ErrorOutlineIcon color="error" />
          </IconButton>
        </Tooltip>

        {/* <ETOIconButton
        color="primary"
        size="medium"
        onClick={() => onClick(data)}
        buttonProps={{
          tabIndex: 0,
        }}
      >
        <MoreVertIcon />
      </ETOIconButton> */}
      </>
    );
  }

  if (data.locked) {
    return (
      <ETOIconButton
        color="grey"
        size="medium"
        onClick={() => onClick(data)}
        buttonProps={{
          tabIndex: 0,
        }}
        tooltipProps={{
          title: t('forms.lockedTimecards'),
          placement: 'bottom-end',
        }}
      >
        <LockRoundedIcon />
      </ETOIconButton>
    );
  }

  return (
    <ETOIconButton
      color="primary"
      size="medium"
      onClick={() => onClick(data)}
      buttonProps={{
        tabIndex: 0,
      }}
      tooltipProps={{
        title: t('forms.editTimecardButton'),
        placement: 'bottom-end',
      }}
    >
      <EditIcon />
    </ETOIconButton>
  );
}

export default EditButton;
