import React from 'react';
import dayjs from 'dayjs';
// eslint-disable-next-line no-unused-vars
import isBetween from 'dayjs/plugin/isBetween';
// eslint-disable-next-line no-unused-vars
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
// eslint-disable-next-line no-unused-vars
import weekday from 'dayjs/plugin/weekday';
import { TimeCard } from 'teto-client-api';
import TimeBasedTimeCardForm, {
  TimeBasedTimeCardFormProps,
} from './TimeBasedTimeCardForm';

dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);
dayjs.extend(weekday);

interface EditTimeCardFormProps
  extends Omit<
    Omit<
      Omit<Omit<TimeBasedTimeCardFormProps, 'editMode'>, 'initialValues'>,
      'batchId'
    >,
    'canAffect'
  > {
  // eslint-disable-next-line no-unused-vars
  timecards: TimeCard[];
  weekStart: number;
  qrEnabled: boolean;
  // eslint-disable-next-line no-unused-vars
  deleteFunction: (id: number) => Promise<void>;
  canEdit: boolean;
  canDelete: boolean;
  // eslint-disable-next-line no-unused-vars
  setFormDirty?: (val: boolean) => void;
}

const EditTimeCardForm = (props: EditTimeCardFormProps) => {
  const {
    timecards,
    requireFields,
    deleteFunction,
    canEdit,
    canDelete,
    fieldsDisableEditable,
  } = props;

  const fieldValues = {
    employeeId: timecards[0].employeeId,
    startTime: timecards[0].startTime ?? dayjs(),
    endTime: timecards[0].endTime ?? dayjs(),
    projectId: timecards[0].projectId,
    comments: timecards[0].comments,
    custom1: timecards[0].custom1,
    custom2: timecards[0].custom2,
    custom3: timecards[0].custom3,
    custom4: timecards[0].custom4,
    custom5: timecards[0].custom5,
    custom6: timecards[0].custom6,
    custom7: timecards[0].custom7,
    custom8: timecards[0].custom8,
    hourTypeId: timecards[0].hourTypeId,
    specId: timecards[0].specId,
    nonConformanceId: timecards[0].nonConformanceId,
    processScheduleDetailId: timecards[0].processScheduleDetailId,
    quantity: timecards[0].quantity,
    requireComments: requireFields?.comments,
    requireCustom1: requireFields?.custom1,
    requireCustom2: requireFields?.custom2,
    requireCustom3: requireFields?.custom3,
    requireCustom4: requireFields?.custom4,
    requireCustom5: requireFields?.custom5,
    requireCustom6: requireFields?.custom6,
    requireCustom7: requireFields?.custom7,
    requireCustom8: requireFields?.custom8,
    requireQuantity: requireFields?.quantity,
    requireNonConformance: requireFields?.nonConformanceId,
    requireProcessSchedule: requireFields?.processScheduleDetailId,
    pSCCustom1: timecards[0].pSCCustom1,
    pSCCustom2: timecards[0].pSCCustom2,
    pSCCustom3: timecards[0].pSCCustom3,
    pSCCustom4: timecards[0].pSCCustom4,
    pSCCustom5: timecards[0].pSCCustom5,
    pSCCustom6: timecards[0].pSCCustom6,
    pSCCustom7: timecards[0].pSCCustom7,
    pSCCustom8: timecards[0].pSCCustom8,
    requirePSCCustom1: requireFields?.pSCCustom1,
    requirePSCCustom2: requireFields?.pSCCustom2,
    requirePSCCustom3: requireFields?.pSCCustom3,
    requirePSCCustom4: requireFields?.pSCCustom4,
    requirePSCCustom5: requireFields?.pSCCustom5,
    requirePSCCustom6: requireFields?.pSCCustom6,
    requirePSCCustom7: requireFields?.pSCCustom7,
    requirePSCCustom8: requireFields?.pSCCustom8,
    hourRate: timecards[0].hourRate,
    hourFactor: timecards[0].hourFactor,
  };
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {timecards.length > 0 && (
        <TimeBasedTimeCardForm
          editMode={{
            id: timecards?.length > 0 ? timecards[0].id : 0,
            deleteFunction,
          }}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          fieldsDisableEditable={!!timecards[0].locked || fieldsDisableEditable}
          canAffect={timecards[0].locked ? false : canEdit}
          canDelete={timecards[0].locked ? false : canDelete}
          initialValues={fieldValues}
        />
      )}
    </>
  );
};

export default EditTimeCardForm;
