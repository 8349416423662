const timeCardTernaryBooleans = [''];
const dependents = {
  projectName: {
    required: ['specName'],
    optional: ['processScheduleDetailName', 'nonConformanceName'],
  },
  specName: {
    required: [],
    optional: ['processScheduleDetailName', 'nonConformanceName'],
  },
};

export { timeCardTernaryBooleans, dependents };
