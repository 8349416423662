/* eslint-disable no-unused-vars */
import { ListItemText } from '@mui/material';
import { ETOTextField } from '@teto/react-component-library';
import { uniqueId } from 'lodash';
import React from 'react';
import ColumnToggleCheckbox from '../ConfigureInspector/ColumnToggleCheckbox';
import TableColumnDefinition from '../TableColumnDefinition';
import FilterCheckbox from './FilterCheckbox';
import FilterIcon from './FilterIcon';
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface RenderFilterFieldTypeProps {
  col: TableColumnDefinition;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultValue: any;
  handleClickListItem: () => void;
  handleChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    curCol: TableColumnDefinition
  ) => void | undefined | null;
}

const RenderFilterFieldType = (props: RenderFilterFieldTypeProps) => {
  const { col, handleChange, handleClickListItem, defaultValue } = props;

  switch (col.filterType) {
    case 'custom':
      return (
        <>
          <ETOTextField
            type={col.type === 'number' ? 'number' : undefined}
            sxProps={{ mr: 1 }}
            key={uniqueId(col.id)}
            id={col.id}
            name={col.name}
            label={col.title}
            handleChange={(e) => {
              handleChange(e, col);
            }}
            defaultValue={defaultValue}
          />
          {FilterIcon('Filter', handleClickListItem)}
        </>
      );
    case 'string':
      return (
        <>
          <ETOTextField
            type={col.type === 'number' ? 'number' : undefined}
            sxProps={{ mr: 1 }}
            key={uniqueId(col.id)}
            id={col.id}
            name={col.name}
            label={col.title}
            handleChange={(e) => {
              handleChange(e, col);
            }}
            defaultValue={defaultValue}
          />
          {FilterIcon('Filter', handleClickListItem)}
        </>
      );
    case 'number':
    case 'hours':
    case 'currency':
      return (
        <>
          <ETOTextField
            type={col.type === 'number' ? 'number' : undefined}
            sxProps={{ mr: 1 }}
            key={uniqueId(col.id)}
            id={col.id}
            name={col.name}
            label={col.title}
            handleChange={(e) => {
              handleChange(e, col);
            }}
            defaultValue={defaultValue}
          />
          {FilterIcon('Filter', handleClickListItem)}
        </>
      );
    case 'bool':
    case 'boolean':
    case 'bitwise':
      return (
        <FilterCheckbox
          column={col}
          handleChange={handleChange}
          defaultValue={defaultValue}
        />
      );

    default:
      return (
        <>
          <ETOTextField
            type={col.type === 'number' ? 'number' : undefined}
            sxProps={{ mr: 1 }}
            key={uniqueId(col.id)}
            id={col.id}
            name={col.name}
            label={col.title}
            handleChange={(e) => {
              handleChange(e, col);
            }}
            defaultValue={defaultValue}
          />
          {FilterIcon('Filter', handleClickListItem)}
        </>
      );
  }
};

export default RenderFilterFieldType;
