import {
  Box,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LockRoundedIcon from '@mui/icons-material/LockRounded';
import {
  AuthContext,
  MessageContext,
  SettingsContext,
  YesNoConfirmDialog,
} from '@teto/react-component-library';
import { LatLngExpression, Map } from 'leaflet';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { QueryFunctionContext, QueryKey, useQuery } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  deleteTimeCard,
  getModelMetadata,
  getTimecards,
  getTimeSheet,
  getTimeSheets,
  Licenses,
  ModelMetadata,
  Permission,
  TimeCard,
  TimeCardLockedReason,
  TimeSheet,
  updateTimeCardByHours,
  updateTimeCardByTime,
} from 'teto-client-api';
import TIMESHEET_QUERY_ID from '../../../api/reactQuery/TimesheetsQueryId';
import { AdminAddHoursButton } from '../../../components/Buttons/AddHoursButton/AddHoursButton';
import ExportButton from '../../../components/Buttons/ExportButton/ExportButton';
import useTimesheetSubmitButton from '../../../components/Buttons/TimesheetSubmitButton/TimesheetSubmitButton';
import TimesheetUnsubmitButton from '../../../components/Buttons/TimesheetUnsubmitButton/TimesheetUnsubmitButton';
import MapPopover from '../../../components/MapWrapper/MapPopover/MapPopover';
import { createColumnsFromMetaData } from '../../../components/TetoGrid/ModelMetaDataProcessor/ModelMetaDataProcessor';
import RDGSelectedType from '../../../components/TetoGrid/RDGSelectedType';
import TableColumnDefinition from '../../../components/TetoGrid/TableColumnDefinition';
import TetoContainer from '../../../components/TetoGrid/TetoContainer';
import TetoGrid from '../../../components/TetoGrid/TetoGrid';
import TetoGridRefType from '../../../components/TetoGrid/TetoGridRefType';
import AddHoursInspector from '../../../components/TimeSheets/AddHoursInspector/AddHoursInspector';
import EditInspector from '../../../components/TimeSheets/EditInspector/EditInspector';
import TimecardFormValidation from '../../../components/TimeSheets/TimeSheetForms/TimecardFormValidation';
import TimesheetInspectorWrapper from '../../../components/TimeSheets/TimesheetInspector/TimesheetInspector';
import { DateRange } from '../../../helpers/dateTypes';
import {
  hourbasedRequestFormatOutput,
  timebasedRequestFormatOutput,
} from '../../../helpers/timecardRequestFormats';
import isNotEmptyOrNullLocation from '../../GeolocationPage/helpers/isNotEmptyOrNullLocation';
import WeeklyDateSelectorWithBackArrow from '../../MyTimeTrackerPage/TimeSheetsTab/WeeklyGridTab/WeeklyDateSelectorWithBackArrow';
import ActionButton, {
  OkCancelConfirmDialog,
} from '../ApprovalsPage/components/ActionButton/ActionButton';
import AdminTimesheetActionBarButtons from './AdminTimesheetActionBarButtons';
import getLockedColumns from './InlineEditing/disabledColumns';
import {
  dependents,
  timeCardTernaryBooleans,
} from './InlineEditing/editingConstants';
import {
  processTimecardForeignKey,
  processTimecardForeignKeyChange,
} from './InlineEditing/foreignKeyPartnerLookup';
import getTimecardSelectorQuery from './InlineEditing/selectorQueries';
import TimesheetSelection from './TimesheetsDetailPageCommonTypes';
import TimesheetsDetailsSummary from './TimesheetsDetailsSummary';

interface TimesheetsDetailsPageType {
  TABLE_ID: string;
  timeSheet: TimeSheet;
  setTimeSheet: React.Dispatch<React.SetStateAction<TimeSheet | undefined>>;
  currentWeek: DateRange;
  // eslint-disable-next-line no-unused-vars
  setCurrentWeek: (nw: DateRange) => void;
  refreshToken: Date | undefined;
  setRefreshToken: React.Dispatch<React.SetStateAction<Date | undefined>>;
  queryIdentifier: typeof TIMESHEET_QUERY_ID;
}

function createDeferred<PT>(): Promise<
  PT & {
    // eslint-disable-next-line no-unused-vars
    resolve: ((value: PT | PromiseLike<PT>) => void) | null;
    // eslint-disable-next-line no-unused-vars
    reject: ((value: PT | PromiseLike<PT>) => void) | null;
  }
> {
  // eslint-disable-line
  // eslint-disable-next-line no-unused-vars
  let resolve: ((value: PT | PromiseLike<PT>) => void) | null = null;
  // eslint-disable-next-line no-unused-vars
  let reject: ((value: PT | PromiseLike<PT>) => void) | null = null;
  const p = new Promise<PT>((_resolve, _reject) => {
    resolve = _resolve;
    reject = _reject;
  });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return Object.assign(p, { resolve, reject }) as any;
}

const TimesheetDetailsPageWrapper = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const queryIdentifier = TIMESHEET_QUERY_ID;
  const [timeSheet, setTimeSheet] = useState<TimeSheet | undefined>();
  const [error, setError] = useState<string | undefined>();
  const [loaded, setLoaded] = useState(false);
  const [refreshToken, setRefreshToken] = useState<Date | undefined>();
  // const settingsContext = useContext(SettingsContext);
  const [currentWeek, setCurrentWeek] = useState<DateRange>();

  // const [currentWeek, setCurrentWeek] = useState<DateRange | undefined>({
  //   start: dayjs(),
  //   end: dayjs(),
  // });

  const TABLE_ID = t('Entities.TimeSheet.detailTableIdentifier');

  useEffect(() => {
    if (!id || Number.isNaN(parseInt(id, 10))) {
      setError('Invalid or no Timesheet specified');
    }
  }, [id, refreshToken]);

  useQuery(
    [queryIdentifier, TABLE_ID, id, refreshToken],
    () =>
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      getTimeSheet(parseInt(id!, 10)).catch((e) => {
        setError(`Failed to load timesheet.  ${e.message}`);
        setLoaded(false);
      }),
    {
      onSuccess: (d: TimeSheet) => {
        setError(undefined);
        setTimeSheet(d);
        setCurrentWeek({ start: d.startDate, end: d.endDate });
        setLoaded(true);
      },
      refetchOnWindowFocus: false,
    }
  );
  if (error) {
    return <Typography color="error">{error}</Typography>;
  }
  if (loaded && timeSheet && currentWeek) {
    return (
      <TimesheetsDetailsPage
        setTimeSheet={setTimeSheet}
        key={id}
        TABLE_ID={TABLE_ID}
        timeSheet={timeSheet}
        currentWeek={currentWeek}
        setCurrentWeek={setCurrentWeek}
        refreshToken={refreshToken}
        setRefreshToken={setRefreshToken}
        queryIdentifier={queryIdentifier}
      />
    );
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
};

const TimesheetsDetailsPage = (props: TimesheetsDetailsPageType) => {
  const {
    TABLE_ID,
    setTimeSheet,
    timeSheet,
    currentWeek,
    setCurrentWeek,
    refreshToken,
    setRefreshToken,
    queryIdentifier,
  } = props;

  const { t, ready } = useTranslation();

  const theme = useTheme();
  const mobileSize = useMediaQuery(theme.breakpoints.down('md'));
  const mapWidth = mobileSize ? 300 : 400;
  const mapHeight = mobileSize ? 300 : 400;

  const settingsContext = useContext(SettingsContext);
  const authContext = useContext(AuthContext);
  const messageContext = useContext(MessageContext);

  const navigate = useNavigate();
  const location = useLocation();

  const [dataSource, setDataSource] = useState([]);
  const [editingTimecard, setEditingTimecard] =
    useState<TimeCard | undefined>();
  const [newEntryInspector, setNewEntryInspector] = useState<boolean>(false);
  const [selectedTimesheet, setSelectedTimesheet] =
    useState<TimesheetSelection | undefined>();
  const [metaData, setMetaData] = useState<ModelMetadata | undefined>();
  const [selectedRows, setSelectedRows] = useState<RDGSelectedType>();
  const [isDiscardConfirmDialog, setIsDiscardConfirmDialog] =
    useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  // eslint-disable-next-line no-spaced-func
  const [isBatchSeparationConfirmation, setIsBatchSeparationConfirmation] =
    useState<
      | {
          // eslint-disable-next-line func-call-spacing
          resolve: (() => void) | null;
          reject: (() => void) | null;
        }
      | undefined
    >();
  const [mapModalEl, setMapModalEl] = useState<HTMLButtonElement | null>(null);
  const [map, setMap] = useState<Map>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [dataArr, setDataArr] = useState<any[]>([]);
  const [mapCoordinates, setMapCoordinates] = useState<LatLngExpression>();

  const canAdd =
    authContext.hasAnyLicense() &&
    (authContext.hasPermission(Permission.Add_Admin_Timecards) ||
      (settingsContext.settings.approverCanManageTime &&
        authContext.canApprove(timeSheet.employeeId)));

  const gridRef = React.useRef<TetoGridRefType | undefined>();

  useEffect(() => {
    // Temporary solution, should generalize this.
    if (
      !(
        authContext.hasAnyPermission([
          Permission.View_Admin_Timecards,
          Permission.Modify_Admin_Timecards,
        ]) ||
        (settingsContext.settings.approverCanManageTime &&
          authContext.canApprove(timeSheet.employeeId))
      )
    )
      navigate('/access-denied', { replace: true });
    if (authContext.hasProfessionalLicense()) {
      getModelMetadata('timecard')
        .then((e) => setMetaData(e))
        .catch((e) => {
          messageContext.setError(
            `${t('generic.message.failMeta')}.  ${e.message}`
          );
        });
    }
  }, [
    authContext,
    messageContext,
    navigate,
    settingsContext.settings.approverCanManageTime,
    t,
    timeSheet.employeeId,
  ]);

  const handleMapClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any
  ) => {
    setDataArr([data]);
    setMapCoordinates(
      data.timecardCoords &&
        isNotEmptyOrNullLocation(data.timecardCoords) &&
        (data.timecardCoords
          .split(', ')
          .map((d: string) => parseFloat(d)) as LatLngExpression)
    );
    setMapModalEl(event.currentTarget);
  };

  const canModify = useCallback(
    (employeeId) =>
      authContext.hasLicense(Licenses.TotalETOProfessional) &&
      (authContext.hasPermission(Permission.Modify_Admin_Timecards) ||
        (settingsContext.settings.approverCanManageTime &&
          authContext.canApprove(employeeId))),
    [authContext, settingsContext.settings]
  );
  const canDelete = useCallback(
    (employeeId) =>
      authContext.hasLicense(Licenses.TotalETOProfessional) &&
      (authContext.hasPermission(Permission.Delete_Admin_Timecards) ||
        (settingsContext.settings.approverCanManageTime &&
          authContext.canApprove(employeeId))),
    [authContext, settingsContext.settings]
  );
  const canInlineEdit = useMemo(
    () =>
      authContext.hasLicense(Licenses.TotalETOProfessional) &&
      authContext.hasPermission(Permission.Modify_Admin_Timecards),
    [authContext]
  );

  const _handleTimecardDelete = useCallback(
    (data: TimeCard) => {
      deleteTimeCard(data.id)
        .then(() => {
          setRefreshToken(new Date());
          messageContext.setSuccess(t('generic.message.recordDeleted'));
        })
        .catch(() => {
          messageContext.setError(t('generic.message.failDeleteTimeCard'));
        });
    },
    [messageContext, setRefreshToken, t]
  );

  const cols = useMemo((): TableColumnDefinition[] => {
    if (metaData && ready) {
      return createColumnsFromMetaData<TimeCard>(metaData)
        .updateDefinition('startTime', { disableGrouping: true, minWidth: 120 })
        .updateDefinition('endTime', { disableGrouping: true, minWidth: 120 })
        .updateDefinition('projectName', { minWidth: 200 })
        .updateDefinition('specName', { minWidth: 200 })
        .updateDefinition('hourTypeName', { minWidth: 200 })
        .updateDefinition('custom7', { width: 120, minWidth: 120 })
        .updateDefinition('custom8', { width: 120, minWidth: 120 })
        .updateDefinition('hourTime', { disableGrouping: true })
        .updateDefinition('actualHours', { disableGrouping: true })
        .updateDefinition('roundedHours', { disableGrouping: true })
        .updateDefinition('locked', {
          bitwiseOptions: TimeCardLockedReason,
          filterOptions: 'simple',
          type: 'bitwise',
          align: 'center',
        })
        .removeProperty('employeeFullName')
        .removeProperty('employeeFirstName')
        .removeProperty('employeeLastName')
        .removeProperty(
          'custom1',
          () => t('Entities.Timecard.TimecardCustom1') === 'Timecard Custom 1'
        )
        .removeProperty(
          'custom2',
          () => t('Entities.Timecard.TimecardCustom2') === 'Timecard Custom 2'
        )
        .removeProperty(
          'custom3',
          () => t('Entities.Timecard.TimecardCustom3') === 'Timecard Custom 3'
        )
        .removeProperty(
          'custom4',
          () => t('Entities.Timecard.TimecardCustom4') === 'Timecard Custom 4'
        )
        .removeProperty(
          'custom5',
          () => t('Entities.Timecard.TimecardCustom5') === 'Timecard Custom 5'
        )
        .removeProperty(
          'custom6',
          () => t('Entities.Timecard.TimecardCustom6') === 'Timecard Custom 6'
        )
        .removeProperty(
          'custom7',
          () => t('Entities.Timecard.TimecardCustom7') === 'Timecard Custom 7'
        )
        .removeProperty(
          'custom8',
          () => t('Entities.Timecard.TimecardCustom8') === 'Timecard Custom 8'
        )
        .removeProperty(
          'pSCCustom1',
          () =>
            t(
              'Entities.Process Schedule In House Transaction.CompletedLogCustom1'
            ) === 'Process Schedule In House Transaction Custom 1'
        )
        .removeProperty(
          'pSCCustom2',
          () =>
            t(
              'Entities.Process Schedule In House Transaction.CompletedLogCustom2'
            ) === 'Process Schedule In House Transaction Custom 2'
        )
        .removeProperty(
          'pSCCustom3',
          () =>
            t(
              'Entities.Process Schedule In House Transaction.CompletedLogCustom3'
            ) === 'Process Schedule In House Transaction Custom 3'
        )
        .removeProperty(
          'pSCCustom4',
          () =>
            t(
              'Entities.Process Schedule In House Transaction.CompletedLogCustom4'
            ) === 'Process Schedule In House Transaction Custom 4'
        )
        .removeProperty(
          'pSCCustom5',
          () =>
            t(
              'Entities.Process Schedule In House Transaction.CompletedLogCustom5'
            ) === 'Process Schedule In House Transaction Custom 5'
        )
        .removeProperty(
          'pSCCustom6',
          () =>
            t(
              'Entities.Process Schedule In House Transaction.CompletedLogCustom6'
            ) === 'Process Schedule In House Transaction Custom 6'
        )
        .removeProperty(
          'pSCCustom7',
          () =>
            t(
              'Entities.Process Schedule In House Transaction.CompletedLogCustom7'
            ) === 'Process Schedule In House Transaction Custom 7'
        )
        .removeProperty(
          'pSCCustom8',
          () =>
            t(
              'Entities.Process Schedule In House Transaction.CompletedLogCustom8'
            ) === 'Process Schedule In House Transaction Custom 8'
        )
        .removeProperty(
          'hourFactor',
          () =>
            !authContext.hasAnyPermission([
              Permission.View_MyTimeTracker_Display_HourInformationDetails,
              Permission.View_Admin_Data_HourInformationDetails,
              Permission.Modify_Admin_Data_HourInformationDetails,
            ])
        )
        .removeProperty(
          'hourRate',
          () =>
            !authContext.hasAnyPermission([
              Permission.View_MyTimeTracker_Display_HourInformationDetails,
              Permission.View_Admin_Data_HourInformationDetails,
              Permission.Modify_Admin_Data_HourInformationDetails,
            ])
        )
        .removeProperty('employeeFirstName')
        .removeProperty('employeeLastName')
        .addColumn({
          name: 'timecardGeolocation',
          title: t('menu.geolocation'),
          type: 'button',
          align: 'center',
          sortable: false,
          editable: false,
          filterType: 'none',
          fixed: 'none',
          disableGrouping: true,
          disableHideable: false,
          disableColumnMenuTool: true,
          width: mobileSize ? 60 : 100,
          headerProps: {
            style: {
              textAlign: 'center',
            },
          },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          renderFunction: (data: any) =>
            data.timecardCoords &&
            isNotEmptyOrNullLocation(data.timecardCoords) ? (
              <Box>
                <IconButton
                  disabled={isEditing} // && !data?.__editing}
                  onClick={(e) => handleMapClick(e, data)}
                  color={isEditing ? 'secondary' : 'primary'}
                >
                  <LocationOnIcon />
                </IconButton>
              </Box>
            ) : (
              '-'
            ),
        })
        .addColumn({
          name: 'action',
          title: t('generic.action'),
          type: 'button',
          align: mobileSize ? 'center' : 'end',
          sortable: false,
          editable: false,
          filterType: 'none',
          fixed: 'right',
          disableGrouping: true,
          disableHideable: true,
          disableColumnMenuTool: true,
          width: mobileSize ? 60 : 140,
          headerProps: {
            style: {
              textAlign: 'center',
            },
          },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          renderFunction: (data: any) => (
            <ActionButton
              isGridEditing={isEditing}
              actionItems={[
                ...(data.locked
                  ? [
                      {
                        componentName: t('generic.info'),
                        icon: <LockRoundedIcon color="action" />,
                        title: t('forms.lockedTimecards'),
                        disabled: !authContext.hasLicense(
                          Licenses.TotalETOProfessional
                        ),
                        handleClick: () => setEditingTimecard(data),
                      },
                    ]
                  : []),
                ...(!data.locked && !data.__editing
                  ? [
                      {
                        componentName: t('generic.edit'),
                        title: t('forms.editTimecardButton'),
                        icon: (
                          <EditIcon
                            color={
                              isEditing || !canModify(data.employeeId)
                                ? 'disabled'
                                : 'primary'
                            }
                          />
                        ),
                        handleClick: () => setEditingTimecard(data),
                        disabled:
                          !authContext.hasLicense(
                            Licenses.TotalETOProfessional
                          ) ||
                          isEditing ||
                          !canModify(data.employeeId),
                      },
                      ...(canDelete(data.employeeId)
                        ? [
                            {
                              componentName: t('generic.delete'),
                              icon: (
                                <DeleteIcon
                                  color={
                                    isEditing || !canDelete(data.employeeId)
                                      ? 'disabled'
                                      : 'error'
                                  }
                                />
                              ),
                              title: t('generic.deleteRecord'),
                              handleClick: () => _handleTimecardDelete(data),
                              confirm: {
                                type: 'okCancel',
                                title: t('dialogs.deleteRecord.title'),
                                content: t('dialogs.deleteRecord.content'),
                                data,
                              } as OkCancelConfirmDialog,
                            },
                          ]
                        : []),
                    ]
                  : []),
              ]}
              data={data}
              setIsDiscardConfirmDialog={setIsDiscardConfirmDialog}
            />
          ),
        })
        .finalize(t);
    }
    return [];
  }, [
    _handleTimecardDelete,
    authContext,
    canDelete,
    canModify,
    isEditing,
    metaData,
    mobileSize,
    ready,
    t,
  ]);
  // eslint-disable-next-line no-unused-expressions
  useQuery(
    [currentWeek, timeSheet, refreshToken],
    () =>
      getTimeSheets({
        disablePaging: true,
        filter: {
          employeeId: timeSheet.employeeId,
          startDate: currentWeek.start,
          endDate: currentWeek.end,
        },
      })
        .then((d) => {
          if (d.records.length > 0) {
            if (
              location.pathname !==
              `/timeTracking/timesheets/${d.records[0].id}`
            ) {
              navigate(`/timeTracking/timesheets/${d.records[0].id}`);
            }
          } else {
            messageContext.setError(
              `No ${t('Entities.TimeSheet.Timesheet')} available for ${
                timeSheet.employeeFullName
              } for ${currentWeek.start} to ${currentWeek.end}`
            );
          }
        })
        .catch((e) => {
          messageContext.setError(
            `Failed to get ${t('Entities.TimeSheet.Timesheet')} for ${
              timeSheet.employeeFullName
            } for ${currentWeek.start} to ${currentWeek.end}. ${
              e.message ?? ''
            }`
          );
        }),
    {
      retry: 1,
      refetchOnWindowFocus: false,
    }
  );

  function _doQuery(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    context: QueryFunctionContext<QueryKey, any>,
    pageSize: number,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    queryData: { filters: any; orderBy: any }
  ) {
    return getTimecards({
      pageIndex: context.pageParam,
      pageSize,
      disablePaging: false,
      orderBy: queryData.orderBy,
      filter: {
        ...queryData.filters,
        timeSheetId: timeSheet.id,
      },
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const _onSubmitting = (v: any, i: any) => {
    if (v?.hourTime !== i?.hourTime) {
      return Promise.resolve({ ...v, hourChanged: true });
    }
    return Promise.resolve({ ...v, hourChanged: false });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const _onSubmit = async (values: any) => {
    if (Object.hasOwn(values, 'startTime')) {
      return updateTimeCardByTime(
        values.id,
        timebasedRequestFormatOutput(values)
      );
    }
    if (Object.hasOwn(values, 'batchId')) {
      if (values?.hourChanged) {
        return updateTimeCardByHours(values.id, {
          ...values,
          hours: values.hourTime,
        });
      }
      const promise = createDeferred();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setIsBatchSeparationConfirmation(promise as any);
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      await promise;
      return updateTimeCardByHours(values.id, {
        ...values,
        hours: values.hourTime,
        batchId: null,
        batched: false,
      });
    }
    return updateTimeCardByHours(values.id, {
      ...hourbasedRequestFormatOutput(values),
      hours: values.hourTime,
    });
  };

  const _handleRecordUpdate = (e: 'Add' | undefined) => {
    setRefreshToken(new Date());
    messageContext.setSuccess(t('generic.message.recordUpdated'));
    if (e === 'Add') {
      setNewEntryInspector(() => false);
    }
  };

  const _handleClose = () => {
    setRefreshToken(new Date());
    setSelectedTimesheet(undefined);
  };

  const AddHoursBtn = AdminAddHoursButton({
    employeeId: timeSheet.employeeId,
    onClick: () => setNewEntryInspector(() => true),
    disabled:
      !authContext.hasLicense(Licenses.TotalETOProfessional) ||
      (timeSheet.status !== 'In Progress' && timeSheet.status !== 'Rejected'),
  });

  const ExportBtn = ExportButton({ gridRef });

  const ApproverButtons = authContext.user?.isApprover
    ? AdminTimesheetActionBarButtons(timeSheet, setSelectedTimesheet)
    : false;

  const SubmitBtn = useTimesheetSubmitButton({
    timesheet: timeSheet,
    disabled: !timeSheet || Boolean(messageContext.message),
  });

  const UnsubmitBtn = TimesheetUnsubmitButton({
    status: timeSheet.status,
    timesheetId: timeSheet.id,
    disabled: !timeSheet || Boolean(messageContext.message),
  });

  return (
    <>
      {ready && cols && metaData && timeSheet?.id && (
        <TetoContainer>
          <TetoGrid
            dataSource={dataSource}
            setDataSource={setDataSource}
            serverSideFiltering
            ref={gridRef}
            // eslint-disable-next-line react/jsx-no-bind
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            doQuery={(a, b, c) => _doQuery(a, b, c as any)}
            columns={cols}
            pageSize={50000}
            refreshToken={refreshToken}
            externalQueryProps={{ timeSheet }}
            summary={<TimesheetsDetailsSummary {...timeSheet} />}
            editable={{
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              rowDisabled: (row: any) => row.locked,
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onSubmitting: (v: any, i: any) => _onSubmitting(v, i),
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onSubmit: (values: any) => _onSubmit(values),
              // eslint-disable-next-line no-unused-vars
              onSubmitSuccess: () => {
                setRefreshToken(new Date());
                return Promise.resolve();
              },
              hasEditPermission: canInlineEdit,
              allowUnset: (value: string) =>
                timeCardTernaryBooleans.includes(value),
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              selector: (c: string, d: any) => getTimecardSelectorQuery(c, d),
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              lockColumn: (c: string, d: any) => getLockedColumns(c, d),
              validationSchema: TimecardFormValidation,
              dependantColumns: dependents,
              processForeignKey: processTimecardForeignKey,
              handleForeignKeyChange: processTimecardForeignKeyChange,
              formatEditableRow: (values) => ({
                ...values,
                requireComments: false,
                requireCustom1: false,
                requireCustom2: false,
                requireCustom3: false,
                requireCustom4: false,
                requireCustom5: false,
                requireCustom6: false,
                requireCustom7: false,
                requireCustom8: false,
                requireNonConformance: false,
                requirePSCCustom1: false,
                requirePSCCustom2: false,
                requirePSCCustom3: false,
                requirePSCCustom4: false,
                requirePSCCustom5: false,
                requirePSCCustom6: false,
                requirePSCCustom7: false,
                requirePSCCustom8: false,
                requireProcessSchedule: false,
                requireQuantity: false,
              }),
            }}
            header={{
              leftChildren: (
                <WeeklyDateSelectorWithBackArrow
                  showBackNavigation
                  startOfTheWeek={currentWeek}
                  onChange={(e) => {
                    setCurrentWeek({ start: e.start, end: e.end });
                  }}
                  handleBackClick={() => {
                    setTimeSheet(undefined);
                    navigate(-1);
                  }}
                />
              ),
              rightChildren: [
                AddHoursBtn,
                ExportBtn,
                ...((authContext.hasPermission(
                  Permission.Modify_Admin_Timecards
                ) &&
                  authContext.hasLicense(Licenses.TotalETOEnterprise)) ||
                (settingsContext.settings.approverCanManageTime &&
                  authContext.canApprove(timeSheet.employeeId) &&
                  authContext.hasLicense(Licenses.TotalETOEnterprise))
                  ? [SubmitBtn, ApproverButtons, UnsubmitBtn]
                  : []),
              ],
            }}
            tableIdentifier={TABLE_ID}
            setSelectedRows={setSelectedRows}
            selectedRows={selectedRows}
            queryIdentifier={queryIdentifier}
            setIsEditing={setIsEditing}
            setIsDiscardConfirmDialog={setIsDiscardConfirmDialog}
            isDiscardConfirmDialog={isDiscardConfirmDialog}
          >
            {newEntryInspector && canAdd && (
              <AddHoursInspector
                allowEmployeeSelection
                initialValues={{
                  employeeId: timeSheet.employeeId,
                }}
                startDate={timeSheet.startDate.toDate()}
                canAdd={canAdd}
                open={newEntryInspector}
                onClose={() => setNewEntryInspector(() => false)}
                onUpdate={() => _handleRecordUpdate(t('generic.add'))}
              />
            )}
          </TetoGrid>
          {Boolean(editingTimecard) && canModify(editingTimecard?.employeeId) && (
            <EditInspector
              canEdit={canModify(editingTimecard?.employeeId)}
              canDelete={canDelete(editingTimecard?.employeeId)}
              allowEmployeeSelection
              disableEmployee
              timecard={editingTimecard}
              onClose={() => setEditingTimecard(undefined)}
              onDelete={() => {
                setEditingTimecard(undefined);
                setRefreshToken(new Date());
                messageContext.setSuccess(t('generic.message.recordDeleted'));
              }}
              onUpdate={() => {
                setEditingTimecard(undefined);
                setRefreshToken(new Date());
                messageContext.setSuccess(t('generic.message.recordUpdated'));
              }}
            />
          )}
          {Boolean(mapModalEl) && (
            <MapPopover
              dataArr={dataArr}
              map={map}
              setMap={setMap}
              mapCoordinates={mapCoordinates}
              mapModalEl={mapModalEl}
              setMapModalEl={setMapModalEl}
              mapWidth={mapWidth}
              mapHeight={mapHeight}
            />
          )}
          {isBatchSeparationConfirmation && (
            <YesNoConfirmDialog
              open={Boolean(isBatchSeparationConfirmation)}
              title={t('dialogs.separateBatchedRecord.title')}
              content={t('dialogs.separateBatchedRecord.content')}
              onYes={() => {
                if (isBatchSeparationConfirmation?.resolve) {
                  isBatchSeparationConfirmation?.resolve();
                }
                setIsBatchSeparationConfirmation(undefined);
              }}
              onNo={() => {
                if (isBatchSeparationConfirmation?.reject)
                  isBatchSeparationConfirmation?.reject();
                setIsBatchSeparationConfirmation(undefined);
              }}
            />
          )}
        </TetoContainer>
      )}
      {selectedTimesheet && (
        <TimesheetInspectorWrapper
          timesheet={selectedTimesheet.timesheet}
          open={Boolean(selectedTimesheet)}
          onClose={_handleClose}
          tableIdentifier={TABLE_ID}
          action={selectedTimesheet.action}
        />
      )}
    </>
  );
};

export default TimesheetDetailsPageWrapper;
