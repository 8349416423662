import ReactDataGrid from '@inovua/reactdatagrid-enterprise';
import {
  TypeColumn,
  TypeComputedProps,
} from '@inovua/reactdatagrid-enterprise/types';
import { Box, Typography, useTheme } from '@mui/material';
import {
  AppearanceContext,
  MessageContext,
  SettingsContext,
} from '@teto/react-component-library';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import * as React from 'react';
import { useQuery } from 'react-query';
import useStyles from '../../../../components/TetoGrid/GridStyles';
import { reactDataGridLicenseKey } from '../../../../components/TetoGrid/Licensing';
import { getGraphQLClient } from '../../../../helpers/graphQL/graphQLClient';

interface PurchasedProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

const container = {
  overflow: 'auto',
  maxHeight: '90%',
  px: 1,
  '& .MuiTypography-subtitle1': {
    mb: 2,
  },
};

const Purchased = (props: PurchasedProps) => {
  const { detail } = props;
  const messageContext = React.useContext(MessageContext);
  const settingsContext = React.useContext(SettingsContext);
  const inventoryId = isEmpty(detail.inventoryDetails)
    ? 0
    : detail.inventoryDetails[0].inventory.inventoryLocationId;
  const variables = {
    projectId: detail?.projectId,
    specId: detail?.specId,
    itemId: detail?.itemId,
    inventoryLocationId: inventoryId,
    processScheduleId: detail?.processScheduleId,
  };

  const _doQuery = async () => {
    const query = `query purchasingItemDetailsPurchased(
      $projectId: Int!
      $specId: Float!
      $itemId: Int!
      $inventoryLocationId: Int!
      $processScheduleId: Int
    ) {
      purchasingItemDetailsPurchased(
        projectId: $projectId
        specId: $specId
        itemId: $itemId
        inventoryLocationId: $inventoryLocationId
        processScheduleId: $processScheduleId
        skip: 0
        take: 10
        order: { purchaseOrderId: DESC }
      ) {
        items {
          purchaseQty
          dateRequired
          dateRevised
          purchaseOrderId
          received
          nonConformanceId
          status
          processNumber
          processScheduleId
          externalQty
          externalUOM
          externalUOMType
          buyer
        }
      }
    }
    `;

    const response = await getGraphQLClient().performQuery(
      query,
      variables,
      (err) => messageContext.setError(err.messages.join('\n ')),
      (err) => {
        // eslint-disable-next-line no-console
        console.log(JSON.stringify(err));
        // messageContext.setError(err.messages.join('\n '))
      }
    );

    return response;
  };

  const purchasingQuery = useQuery('purchasing', async () => _doQuery(), {
    enabled: true,
  });
  const data = purchasingQuery?.data?.purchasingItemDetailsPurchased?.items;

  const gridRef = React.useRef<TypeComputedProps | null>(null);
  const theme = useTheme();
  const appearanceContext = React.useContext(AppearanceContext);
  const scrollStyle = {
    scrollThumbStyle: { backgroundColor: theme.palette.primary.main },
  };

  const currentTheme =
    appearanceContext.themeVariant === 'dark'
      ? 'default-dark'
      : 'default-light';

  const dateRenderer = React.useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (d: any) => {
      if (!d) {
        return null;
      }

      return dayjs(d).format(settingsContext.settings.dateFormat);
    },
    [settingsContext.settings.dateFormat]
  );

  const columns: TypeColumn[] = React.useMemo(
    () => [
      {
        name: 'purchaseQty',
        header: 'Quantity',
        sortable: true,
        groupBy: false,
        showColumnMenuTool: false,
        hideable: false,
      },
      {
        name: 'dateRequired',
        header: 'Required Date',
        sortable: true,
        groupBy: false,
        showColumnMenuTool: false,
        hideable: false,
        render: ({ data: { dateRequired } }) => dateRenderer(dateRequired),
      },
      {
        name: 'purchaseOrderId',
        header: 'Purchase Order',
        sortable: true,
        groupBy: false,
        showColumnMenuTool: false,
        hideable: false,
      },
      {
        name: 'nonConformanceId',
        header: 'Non Conformance',
        sortable: true,
        groupBy: false,
        showColumnMenuTool: false,
        hideable: false,
      },
      {
        name: 'dateRevised',
        header: 'Revised',
        sortable: true,
        groupBy: false,
        showColumnMenuTool: false,
        hideable: false,
        render: ({ data: { dateRevised } }) => dateRenderer(dateRevised),
      },
      {
        name: 'received',
        header: 'Received',
        sortable: true,
        groupBy: false,
        showColumnMenuTool: false,
        hideable: false,
      },
      {
        name: 'status',
        header: 'Status',
        sortable: true,
        groupBy: false,
        showColumnMenuTool: false,
        hideable: false,
      },
      {
        name: 'processNumber',
        header: 'Process Schedule No.',
        sortable: true,
        groupBy: false,
        showColumnMenuTool: false,
        hideable: false,
      },
      {
        name: 'buyer',
        header: 'Buyer',
        sortable: true,
        groupBy: false,
        showColumnMenuTool: false,
        hideable: false,
      },
    ],
    [dateRenderer]
  );

  return (
    <Box sx={[container, useStyles]}>
      <Typography variant="subtitle1">Purchased</Typography>
      {!isEmpty(data) && Array.isArray(data) && (
        <ReactDataGrid
          licenseKey={reactDataGridLicenseKey}
          columns={columns}
          dataSource={data}
          scrollProps={scrollStyle}
          handle={(r) => {
            gridRef.current = r ? r.current : null;
          }}
          theme={currentTheme}
          enableColumnAutosize
          style={{ minHeight: data.length > 1 ? 400 : '' }}
        />
      )}
    </Box>
  );
};

export default Purchased;
