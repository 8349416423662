import { AuthContext } from '@teto/react-component-library';
import { useContext } from 'react';
import { Permission } from 'teto-client-api';

const viewAllPermission = [
  Permission.View_MyTimeTracker_Display_HourInformationDetails,
  Permission.View_MyTimeTracker_Punchcards,
  Permission.View_MyTimeTracker_Timecards,
  Permission.View_Admin_Timecards,
  Permission.View_Admin_Data_HourInformationDetails,
  Permission.View_Legacy_fdlgEditPayPeriod,
];

const useAccessApprovals = () => {
  const { hasAllPermissions, hasProfessionalLicense, user } =
    useContext(AuthContext);

  if (
    user?.isAdmin ||
    hasProfessionalLicense() ||
    hasAllPermissions(viewAllPermission)
  )
    return true;
  return false;
};

export default useAccessApprovals;
