import { darken } from '@mui/material';
import { Theme } from '@mui/material/styles';

const useStyles = (theme: Theme) => ({
  width: '100%',
  borderRadius: `${theme.shape.borderRadius}px`,
  borderColor: `${theme.palette.divider}`,
  '.InovuaReactDataGrid': {
    borderRadius: `${theme.shape.borderRadius}px`,
  },
  '& .MuiInputBase-root': {
    color: theme.palette.text.primary,
  },
  '&.InovuaReactDataGrid--theme-default-light': {
    backgroundColor: theme.palette.background.default,
  },
  '& .InovuaReactDataGrid__body': {
    borderRadius: `${theme.shape.borderRadius}px`,
    borderColor: theme.palette.divider,
  },
  '& .InovuaReactDataGrid__group-toolbar': {
    backgroundColor: darken(theme.palette.background.paper, 0.1),
    color: theme.palette.getContrastText(
      darken(theme.palette.background.paper, 0.1)
    ),
    borderColor: theme.palette.divider,
  },
  '& .InovuaReactDataGrid__group-toolbar-item': {
    borderRadius: `${theme.shape.borderRadius}px`,
  },
  '& .InovuaReactDataGrid__header': {
    borderBottom: `1px solid ${theme.palette.divider} !important`,
    background: 'inherit !important',
  },
  '& .InovuaReactDataGrid__column-header': {
    backgroundColor: darken(theme.palette.primary.main, 0.2),
    color: theme.palette.getContrastText(
      darken(theme.palette.primary.main, 0.2)
    ),
    ...theme.typography.body2,
  },
  '& .InovuaReactDataGrid__column-header__content': {
    fontWeight: 'normal !important',
  },
  '& .InovuaReactDataGrid__column-header__filter-wrapper': {
    backgroundColor: `${theme.palette.background.paper} !important`,
    color: theme.palette.getContrastText(theme.palette.background.paper),
    borderTop: `1px solid ${theme.palette.divider} !important`,

    '& .inovua-react-toolkit-text-input, .inovua-react-toolkit-numeric-input': {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.getContrastText(theme.palette.background.paper),
      borderTop: 0,
      borderLeft: 0,
      borderRight: 0,
    },
  },

  '& .InovuaReactDataGrid__column-header__resize-wrapper--show-border-left': {
    borderLeft: `1px solid ${theme.palette.divider} !important`,
  },
  '& .InovuaReactDataGrid__column-header__resize-wrapper--first-in-section:after ':
    {
      // removes fixed header left border
      left: '-1.5px !important',
      width: '2px !important',
      bottom: '-2px !important',
      borderLeft: 'unset !important',
      borderRight: 'unset !important',
      background: `${theme.palette.background.default} !important`,
    },

  '& .InovuaReactDataGrid__cell--locked-end:first-of-type:after ': {
    // adjusts fixed/locked right cells border
    width: '2px !important',
    borderLeft: 'unset !important',
    borderRight: 'unset !important',
    background: `${theme.palette.background.default} !important`,
  },

  '& .InovuaReactDataGrid__row--odd': {
    backgroundColor: `${darken(
      theme.palette.background.paper,
      0.07
    )} !important`,
    color: theme.palette.getContrastText(
      darken(theme.palette.background.paper, 0.07)
    ),
    '& .InovuaReactDataGrid__cell': {
      borderColor: theme.palette.divider,
    },
  },

  '& .InovuaReactDataGrid__row--even': {
    '& .InovuaReactDataGrid__cell': {
      borderColor: theme.palette.divider,
    },
  },

  '& .InovuaReactDataGrid__virtual-list': {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.getContrastText(theme.palette.background.paper),
  },
  // '& .InovuaReactDataGrid__row-hover-target': {
  //   '& :hover': {
  //     background: theme.palette.background.paper,
  //     color: theme.palette.getContrastText(theme.palette.background.paper),
  //   },
  // },
  '& .InovuaReactDataGrid__empty-wrapper': {
    backgroundColor: theme.palette.background.paper,
    zIndex: 10101,
  },
  '& .InovuaReactDataGrid__cell--last-in-section': {
    paddingLeft: theme.spacing(1),
  },
  '& .InovuaReactDataGrid__column-header__filter-settings-icon': {
    fill: `${theme.palette.primary.main} !important`,
  },
  '& .InovuaReactDataGrid__column-header__filter-settings-icon:hover': {
    fill: `${theme.palette.primary.light} !important`,
  },
  // Checkbox Styles
  '& .inovua-react-toolkit-checkbox--theme-default-dark': {
    fill: theme.palette.primary.dark,
  },
  '& .inovua-react-toolkit-checkbox--theme-default-light': {
    fill: theme.palette.primary.dark,
  },
  '& .inovua-react-toolkit-checkbox__icon-wrapper': {
    fill: 'inherit !important',
    borderRadius: `${theme.shape.borderRadius}px`,
    overflow: 'hidden',
  },
  '& .inovua-react-toolkit-checkbox.inovua-react-toolkit-checkbox--theme-default-dark.inovua-react-toolkit-checkbox--indeterminate .inovua-react-toolkit-checkbox__icon-wrapper svg':
    {
      background: theme.palette.grey[500],
    },
  '& .inovua-react-toolkit-checkbox.inovua-react-toolkit-checkbox--theme-default-light.inovua-react-toolkit-checkbox--indeterminate .inovua-react-toolkit-checkbox__icon-wrapper svg':
    {
      background: theme.palette.grey[500],
    },
  '& .active-row-border': {
    borderColor: theme.palette.primary.main,
  },
  '& .active-row-border--error': {
    borderColor: `${theme.palette.error.main} !important`,
  },
  '& .InovuaReactDataGrid__row--editing': {
    '& .InovuaReactDataGrid__row-hover-target': {
      border: `1px solid ${theme.palette.primary.main}`,
    },
    '& .InovuaReactDataGrid__row-hover-target:hover': {
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
  '& .InovuaReactDataGrid__cell .InovuaReactDataGrid__cell__content, .InovuaReactDataGrid__nested-cell .InovuaReactDataGrid__nested-group-cell__content, .InovuaReactDataGrid__nested-group-cell .InovuaReactDataGrid__nested-group-cell__content':
    {
      '& span.MuiButtonBase-root.MuiCheckbox-root.Mui-disabled.PrivateSwitchBase-root.MuiCheckbox-root.Mui-disabled.css-9rmmt3-MuiButtonBase-root-MuiCheckbox-root':
        {
          color: theme.palette.text.secondary,
        },
    },
  '& .InovuaReactDataGrid__cell--in-edit': {
    '& .MuiBox-root': {
      background: theme.palette.background.paper,
      color: theme.palette.getContrastText(theme.palette.background.paper),
      '& :hover': {
        background: theme.palette.background.paper,
        color: theme.palette.getContrastText(theme.palette.background.paper),
      },
    },
  },
  '.gridColHeader__sort': {
    paddingLeft: '1.625em',
  },
  ' .InovuaReactDataGrid__header-group__title': {
    backgroundColor: darken(theme.palette.primary.main, 0.2),
  },
  /* '.InovuaReactDataGrid__row .InovuaReactDataGrid__row-details.InovuaReactDataGrid__row-details--details-grid':
    {
      top: '50px',
    }, */
});

export default useStyles;
