import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import LockRoundedIcon from '@mui/icons-material/LockRounded';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import {
  ActionButton,
  AuthContext,
  MessageContext,
} from '@teto/react-component-library';
import dayjs, { Dayjs } from 'dayjs';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import {
  deleteTimeCard,
  getModelMetadata,
  Permission,
  TimeCard,
  TimeCardLockedReason,
} from 'teto-client-api';
import TIMESHEET_QUERY_ID from '../../../../api/reactQuery/TimesheetsQueryId';
import GridPersistenceProvider from '../../../../components/TetoGrid/GridPersistence/GridPersistenceProvider';
import { createColumnsFromMetaData } from '../../../../components/TetoGrid/ModelMetaDataProcessor/ModelMetaDataProcessor';
import EditInspector from '../../../../components/TimeSheets/EditInspector/EditInspector';
import {
  PunchInAndDateCell,
  PunchInAndDateCellProps,
  PunchInAndDateHeader,
} from '../../components/CustomCells/PunchInAndDateCell';
import WeeklyGridTab from './WeeklyGridTab';

interface WeeklyGridTabWrapperProps {
  setViewBy?: React.Dispatch<React.SetStateAction<string>>;
  viewBy?: string;
}

const WeeklyGridTabWrapper = ({
  setViewBy,
  viewBy,
}: WeeklyGridTabWrapperProps) => {
  const authContext = useContext(AuthContext);
  const messageContext = useContext(MessageContext);
  const { t, ready } = useTranslation();
  const TABLE_ID = t('pages.timeTracker.tableIdentifier');
  const queryIdentifier = TIMESHEET_QUERY_ID;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [editableTimecard, setEditableTimecard] =
    useState<TimeCard | undefined>();
  const [refreshToken, setRefreshToken] =
    useState<Dayjs | undefined>(undefined);

  const canDelete =
    authContext.hasAnyLicense() &&
    authContext.hasPermission(Permission.Delete_MyTimeTracker_Timecards);

  const metaData = useQuery(
    [queryIdentifier, 'metaMyTimeTracker'],
    () => getModelMetadata('timecard'),
    {
      onError: (e) => {
        messageContext.setError(`${t('generic.message.failMeta')}.  ${e}`);
      },
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
    }
  );

  const _handleTimecardDelete = useCallback(
    (data: TimeCard) => {
      deleteTimeCard(data.id)
        .then(() => {
          setRefreshToken(dayjs());
          messageContext.setSuccess(t('generic.message.recordDeleted'));
        })
        .catch(() => {
          messageContext.setError(t('generic.message.failDeleteTimeCard'));
        });
    },
    [messageContext, t]
  );

  const _onRecordsDeleted = () => {
    messageContext.setSuccess(t('generic.message.recordDeleted'));
    setRefreshToken(dayjs());
    setEditableTimecard(undefined);
  };

  const _handleTimecardEditSave = () => {
    setRefreshToken(dayjs());
    setEditableTimecard(undefined);
    messageContext.setSuccess(t('generic.message.recordUpdated'));
  };

  const subTableColumns = useMemo(() => {
    if (metaData.data && ready) {
      return createColumnsFromMetaData<TimeCard>(metaData?.data)
        .updateDefinition('startTime', { disableGrouping: true })
        .updateDefinition('endTime', { disableGrouping: true })
        .updateDefinition('projectName', { minWidth: 200 })
        .updateDefinition('specName', { minWidth: 200 })
        .updateDefinition('hourTypeName', { minWidth: 200 })
        .updateDefinition('custom7', { width: 120, minWidth: 120 })
        .updateDefinition('custom8', { width: 120, minWidth: 120 })
        .updateDefinition('id', { filterOptions: undefined })
        .updateDefinition('locked', {
          bitwiseOptions: TimeCardLockedReason,
          filterOptions: 'simple',
          type: 'bitwise',
          align: 'center',
        })
        .updateDefinition('date', {
          minWidth: 160,
          align: 'start',
          // eslint-disable-next-line react/no-unstable-nested-components, react/no-unused-prop-types
          header: ({ title }: { title: string }) => (
            <PunchInAndDateHeader title={title as string} />
          ),
          render: (data: { data: unknown }) => (
            <PunchInAndDateCell
              data={data.data as unknown as PunchInAndDateCellProps}
            />
          ),
        })
        .updateDefinition('timecardCoords', {
          editable: false,
        })
        .removeProperty('employeeFullName')
        .removeProperty('empNumber')
        .removeProperty('employeeFirstName')
        .removeProperty('employeeLastName')
        .removeProperty('employeeId')
        .removeProperty(
          'custom1',
          () => t('Entities.Timecard.TimecardCustom1') === 'Timecard Custom 1'
        )
        .removeProperty(
          'custom2',
          () => t('Entities.Timecard.TimecardCustom2') === 'Timecard Custom 2'
        )
        .removeProperty(
          'custom3',
          () => t('Entities.Timecard.TimecardCustom3') === 'Timecard Custom 3'
        )
        .removeProperty(
          'custom4',
          () => t('Entities.Timecard.TimecardCustom4') === 'Timecard Custom 4'
        )
        .removeProperty(
          'custom5',
          () => t('Entities.Timecard.TimecardCustom5') === 'Timecard Custom 5'
        )
        .removeProperty(
          'custom6',
          () => t('Entities.Timecard.TimecardCustom6') === 'Timecard Custom 6'
        )
        .removeProperty(
          'custom7',
          () => t('Entities.Timecard.TimecardCustom7') === 'Timecard Custom 7'
        )
        .removeProperty(
          'custom8',
          () => t('Entities.Timecard.TimecardCustom8') === 'Timecard Custom 8'
        )
        .removeProperty(
          'pSCCustom1',
          () =>
            t(
              'Entities.Process Schedule In House Transaction.CompletedLogCustom1'
            ) === 'Process Schedule In House Transaction Custom 1'
        )
        .removeProperty(
          'pSCCustom2',
          () =>
            t(
              'Entities.Process Schedule In House Transaction.CompletedLogCustom2'
            ) === 'Process Schedule In House Transaction Custom 2'
        )
        .removeProperty(
          'pSCCustom3',
          () =>
            t(
              'Entities.Process Schedule In House Transaction.CompletedLogCustom3'
            ) === 'Process Schedule In House Transaction Custom 3'
        )
        .removeProperty(
          'pSCCustom4',
          () =>
            t(
              'Entities.Process Schedule In House Transaction.CompletedLogCustom4'
            ) === 'Process Schedule In House Transaction Custom 4'
        )
        .removeProperty(
          'pSCCustom5',
          () =>
            t(
              'Entities.Process Schedule In House Transaction.CompletedLogCustom5'
            ) === 'Process Schedule In House Transaction Custom 5'
        )
        .removeProperty(
          'pSCCustom6',
          () =>
            t(
              'Entities.Process Schedule In House Transaction.CompletedLogCustom6'
            ) === 'Process Schedule In House Transaction Custom 6'
        )
        .removeProperty(
          'pSCCustom7',
          () =>
            t(
              'Entities.Process Schedule In House Transaction.CompletedLogCustom7'
            ) === 'Process Schedule In House Transaction Custom 7'
        )
        .removeProperty(
          'pSCCustom8',
          () =>
            t(
              'Entities.Process Schedule In House Transaction.CompletedLogCustom8'
            ) === 'Process Schedule In House Transaction Custom 8'
        )
        .removeProperty(
          'hourFactor',
          () =>
            !authContext.hasAnyPermission([
              Permission.Modify_Admin_Data_HourInformationDetails,
              Permission.View_Admin_Data_HourInformationDetails,
              Permission.View_MyTimeTracker_Display_HourInformationDetails,
            ])
        )
        .removeProperty(
          'hourRate',
          () =>
            !authContext.hasAnyPermission([
              Permission.Modify_Admin_Data_HourInformationDetails,
              Permission.View_Admin_Data_HourInformationDetails,
              Permission.View_MyTimeTracker_Display_HourInformationDetails,
            ])
        )
        .addColumn({
          name: 'action',
          title: t('generic.action'),
          type: 'button',
          align: 'center',
          sortable: false,
          editable: false,
          filterType: 'none',
          hidden: false,
          fixed: 'right',
          locked: 'start',
          disableGrouping: true,
          disableHideable: true,
          disableColumnMenuTool: true,
          minWidth: isMobile ? 60 : 144,
          width: isMobile ? 60 : 144,
          headerProps: {
            style: {
              textAlign: 'center',
            },
          },
          renderFunction: (data: TimeCard) => (
            <ActionButton
              actionItems={[
                ...(data.locked !== 0
                  ? [
                      {
                        componentName: t('generic.info'),
                        icon: <LockRoundedIcon color="action" />,
                        handleClick: () => setEditableTimecard(data),
                        title: t('forms.lockedTimecards'),
                      },
                    ]
                  : []),
                ...(data.locked === 0
                  ? [
                      {
                        componentName: t('generic.edit'),
                        title: t('forms.editTimecardButton'),
                        icon: <EditIcon color="primary" />,
                        handleClick: () => setEditableTimecard(data),
                      },
                      ...(canDelete
                        ? [
                            {
                              componentName: t('generic.delete'),
                              title: t('generic.deleteRecord'),
                              icon: <DeleteIcon color="error" />,
                              handleClick: () => _handleTimecardDelete(data),
                              confirm: {
                                type: 'okCancel',
                                title: t('dialogs.deleteRecord.title'),
                                content: t('dialogs.deleteRecord.content'),
                                data,
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                              } as any,
                            },
                          ]
                        : []),
                    ]
                  : []),
              ]}
            />
          ),
        })
        .finalize(t);
    }
    return [];
  }, [
    _handleTimecardDelete,
    authContext,
    canDelete,
    isMobile,
    metaData.data,
    ready,
    t,
  ]);

  if (
    authContext.user &&
    (authContext.user?.id === -1 || authContext.user?.id >= 1)
  ) {
    return (
      <GridPersistenceProvider
        tableIdentifier={TABLE_ID}
        columns={subTableColumns}
        persistenceType={
          authContext.hasEnterpriseLicense() ? 'db' : 'sessionStorage'
        }
      >
        <WeeklyGridTab
          columns={subTableColumns}
          tableId={TABLE_ID}
          setViewBy={setViewBy}
          viewBy={viewBy}
          refreshGrid={refreshToken}
          setRefreshGrid={() => setRefreshToken(undefined)}
          setEditableTimecard={setEditableTimecard}
        />
        {editableTimecard && ready && (
          <EditInspector
            canEdit={
              authContext.hasAnyLicense() &&
              authContext.hasPermission(
                Permission.Modify_MyTimeTracker_Timecards
              )
            }
            canDelete={canDelete}
            allowEmployeeSelection={false}
            timecard={editableTimecard}
            editMyTimecards
            onUpdate={() => _handleTimecardEditSave()}
            onDelete={() => _onRecordsDeleted()}
            onClose={() => setEditableTimecard(undefined)}
          />
        )}
      </GridPersistenceProvider>
    );
  }

  return <Box>&nbsp;</Box>;
};

export default WeeklyGridTabWrapper;
