import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import {
  ApplicationInsights,
  ITelemetryPlugin,
} from '@microsoft/applicationinsights-web';

import process from 'process';

const development: boolean =
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: development
      ? ''
      : 'e2b17dfe-89c8-48e9-958b-116ce7cc617e',
    enableAutoRouteTracking: true,
    // Workaround due to upgrading node version, leave until they fix package
    extensions: [reactPlugin as unknown as ITelemetryPlugin],
  },
});

appInsights.loadAppInsights();

export { reactPlugin, appInsights };
