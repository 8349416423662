/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import {
  Box,
  Collapse,
  Grid,
  List,
  ListItem,
  ListItemText,
  TextField,
  Theme,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  AppearanceContext,
  AuthContext,
  ETODateField,
  ETOIconButton,
  ETOTextField,
  SettingsContext,
} from '@teto/react-component-library';
import dayjs from 'dayjs';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Typography from '@mui/material/Typography';
import { debounce } from 'lodash';
import { Permission } from 'teto-client-api';
import POSearch from '../POSearch/POSearch';
import { PurchaseOrder } from '../PurchaseOrder';

const gridSx = (theme: Theme) => ({
  paddingTop: theme.spacing(2),
});

const detailsSx = {
  width: '100%',
};

const collapseSx = (theme: Theme) => ({
  display: 'flex',
  alignItems: 'center',
  '& .MuiListItemText-primary': {
    cursor: 'pointer',
    fontSize: theme.typography.fontSize,
    fontWeight: 500,
  },
});

const disabledColorSx = (
  theme: Theme,
  currentTheme: 'default-dark' | 'default-light'
) => ({
  color: `${
    theme.palette.grey[currentTheme === 'default-dark' ? 400 : 800]
  }!important`,
  '& .Mui-disabled': {
    WebkitTextFillColor: `${
      theme.palette.grey[currentTheme === 'default-dark' ? 400 : 800]
    }!important`,
  },
});

interface POSummaryProps {
  // isEditing?: boolean;
  purchaseOrder?: PurchaseOrder | any;
  setPurchaseOrder: React.Dispatch<
    React.SetStateAction<PurchaseOrder | undefined | any>
  >;
  setReceivingValues: React.Dispatch<any>;
  receivingValues: any;
  lastActivePO?: PurchaseOrder;
  hasUpdatedDataSource: boolean;
}

const POSummary = (props: POSummaryProps) => {
  const { t } = useTranslation();
  const {
    // isEditing,
    lastActivePO,
    purchaseOrder,
    setPurchaseOrder,
    setReceivingValues,
    receivingValues,
    hasUpdatedDataSource,
  } = props;

  const authContext = useContext(AuthContext);
  const settingsContext = useContext(SettingsContext);
  const appearanceContext = useContext(AppearanceContext);
  const currentTheme =
    appearanceContext.themeVariant === 'dark'
      ? 'default-dark'
      : 'default-light';

  const theme = useTheme();
  const isReceivingMobile = useMediaQuery(theme.breakpoints.down('lg'));

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const handlePackingSlipChange = (e: React.BaseSyntheticEvent) =>
    setReceivingValues((prev: any) => ({
      ...prev,
      input: {
        ...prev.input,
        packingSlipNumber: e.target.value,
      },
    }));
  const debouncedHandlePackingSlipChange = debounce(
    handlePackingSlipChange,
    200
  );

  const pONotes =
    purchaseOrder?.purchaseNotes ||
    purchaseOrder?.purchaseNotes2 ||
    purchaseOrder?.purchaseNotes3
      ? `${purchaseOrder?.purchaseNotes} - ${purchaseOrder?.purchaseNotes2} - \n${purchaseOrder?.purchaseNotes3}`
      : '';

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {!purchaseOrder && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: theme.spacing(1),
          }}
        >
          {isReceivingMobile ? (
            <POSearch
              purchaseOrder={purchaseOrder}
              setPurchaseOrder={setPurchaseOrder}
              lastActivePO={lastActivePO}
              setReceivingValues={setReceivingValues}
              isEditing={hasUpdatedDataSource}
            />
          ) : (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                position: 'absolute',
                bottom: '50%',
              }}
            >
              <Typography
                variant="h6"
                sx={{ color: theme.palette.text.disabled }}
              >
                {t('Entities.Receiving.noActivePurchaseOrder')}
              </Typography>
            </Box>
          )}
        </Box>
      )}
      {purchaseOrder && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {isReceivingMobile && (
            <POSearch
              purchaseOrder={purchaseOrder}
              setPurchaseOrder={setPurchaseOrder}
              lastActivePO={lastActivePO}
              setReceivingValues={setReceivingValues}
              isEditing={hasUpdatedDataSource}
            />
          )}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '100%',
            }}
          >
            <Box
              sx={{
                width: '100%',
                marginBottom: theme.spacing(isOpen ? 2 : 0),
              }}
            >
              <Grid sx={gridSx} container spacing={2}>
                <Grid item xs={7} md={6}>
                  <ETOTextField
                    // disabled={isEditing}
                    size="small"
                    name="packingSlipNumber"
                    handleChange={debouncedHandlePackingSlipChange}
                    label="Packing Slip Number"
                    defaultValue={
                      receivingValues?.input?.packingSlipNumber
                        ? receivingValues.input?.packingSlipNumber
                        : purchaseOrder.packingSlipNumber
                        ? purchaseOrder.packingSlipNumber
                        : ''
                    }
                  />
                </Grid>
                <Grid item xs={5} md={6}>
                  <ETODateField
                    inputFormat={settingsContext.settings.dateFormat}
                    size="small"
                    name="pOReceiveDate"
                    label="Receive Date"
                    disabled={
                      !authContext.hasPermission(
                        Permission.Modify_Legacy_frmReceiving_Main
                      )
                    }
                    handleChange={(e) =>
                      setReceivingValues((prev: any) => ({
                        input: { ...prev.input, receiveDate: e },
                      }))
                    }
                    value={
                      receivingValues?.input?.receiveDate
                        ? receivingValues.input?.receiveDate
                        : purchaseOrder.pOReceiveDate
                        ? purchaseOrder.pOReceiveDate
                        : dayjs()
                    }
                  />
                </Grid>
              </Grid>
            </Box>
            <Collapse
              in={isOpen}
              sx={[detailsSx]}
              collapsedSize={0}
              timeout={0}
              unmountOnExit
            >
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    size="small"
                    disabled
                    name="supplier"
                    label="Supplier"
                    value={purchaseOrder.purchaseSupplier?.name}
                    variant="standard"
                    fullWidth
                    sx={disabledColorSx(theme, currentTheme)}
                    InputLabelProps={{
                      sx: disabledColorSx(theme, currentTheme),
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    size="small"
                    disabled
                    name="buyer"
                    label="Buyer"
                    value={purchaseOrder.buyer?.name}
                    variant="standard"
                    fullWidth
                    sx={disabledColorSx(theme, currentTheme)}
                    InputLabelProps={{
                      sx: disabledColorSx(theme, currentTheme),
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    multiline
                    size="small"
                    disabled
                    name="pONotes"
                    label="PO Notes"
                    value={pONotes}
                    variant="standard"
                    fullWidth
                    sx={disabledColorSx(theme, currentTheme)}
                    InputLabelProps={{
                      sx: disabledColorSx(theme, currentTheme),
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>
            </Collapse>

            <Box sx={collapseSx}>
              <List disablePadding>
                <ListItem
                  disableGutters
                  secondaryAction={
                    <ETOIconButton
                      size="medium"
                      color="primary"
                      onClick={() => setIsOpen((d) => !d)}
                      tooltipProps={{
                        title: t(
                          `generic.${
                            isOpen
                              ? 'hideAdditionalDetails'
                              : 'viewAdditionalDetails'
                          }`
                        ),
                        leaveTouchDelay: 0,
                      }}
                    >
                      {isOpen ? (
                        <ExpandLessRoundedIcon />
                      ) : (
                        <ExpandMoreRoundedIcon />
                      )}
                    </ETOIconButton>
                  }
                >
                  <ListItemText
                    primary={t(
                      `generic.${
                        isOpen
                          ? 'hideAdditionalDetails'
                          : 'viewAdditionalDetails'
                      }`
                    )}
                    onClick={() => setIsOpen((d) => !d)}
                  />
                </ListItem>
              </List>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default POSummary;
