/* eslint-disable no-unused-vars */
import ChevronLeftRounded from '@mui/icons-material/ChevronLeftRounded';
import { Box, SxProps, Theme } from '@mui/material';
import {
  ETOIconButton,
  SelectedWeek,
  SettingsContext,
  WeeklyDateSelector,
} from '@teto/react-component-library';
import dayjs from 'dayjs';
import React, { memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { DateRange } from '../../../../helpers/dateTypes';

interface WeeklyDateSelectorWithBackArrowProps {
  showBackNavigation?: boolean;
  startOfTheWeek: DateRange;
  onChange: (e: SelectedWeek) => void;
  handleBackClick: () => void;
}

const weekSelectorSx = (theme: Theme) => ({
  minHeight: '32px',
  width: 'fit-content',
  [theme.breakpoints.down('sm')]: {
    minWidth: theme.spacing(26.5),
  },
  // '& .MuiTextField-root': {
  //   width: theme.spacing(40),
  // },
});

const toolbarTileSx = {
  '& h4.MuiTypography-root.MuiTypography-h4.MuiTypography-alignCenter.MuiDatePickerToolbar-title.css-3jvy96-MuiTypography-root-MuiDatePickerToolbar-title':
    {
      fontSize: '1.25rem',
    },
};

const rootSx = (theme: Theme) => ({
  display: 'flex',
  '& .MuiSvgIcon-root': {
    fontSize: theme.spacing(3.5),
  },
  color: 'red !important',
});
const WeeklyDateSelectorWithBackArrow = (
  props: WeeklyDateSelectorWithBackArrowProps
) => {
  const { showBackNavigation, startOfTheWeek, onChange, handleBackClick } =
    props;
  const { settings } = useContext(SettingsContext);
  const { t } = useTranslation();

  return (
    <Box component="div" sx={rootSx}>
      {showBackNavigation && (
        <ETOIconButton
          color="primary"
          size="small"
          onClick={handleBackClick}
          tooltipProps={{
            title: t('generic.back'),
          }}
        >
          <ChevronLeftRounded />
        </ETOIconButton>
      )}
      <WeeklyDateSelector
        weekStart={settings.startDayOfWeek}
        customSx={weekSelectorSx as SxProps}
        value={startOfTheWeek.start.toDate()}
        onChange={onChange}
        inputFormat={settings.dateFormat}
        datePickerProps={{
          DialogProps: {
            sx: toolbarTileSx,
          },
        }}
      />
    </Box>
  );
};

export default memo(WeeklyDateSelectorWithBackArrow);
