import GridOnRoundedIcon from '@mui/icons-material/GridOnRounded';
import MapRoundedIcon from '@mui/icons-material/MapRounded';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { PageTabs } from '@teto/react-component-library';
import { SxProps, Theme } from '@mui/material/styles';
import TetoContainer from '../../components/TetoGrid/TetoContainer';
import DepartmentApprovers from './DepartmentTab/DepartmentApprovers';
import EmployeeApprovers from './EmployeeTab/EmployeeApprovers';
import useAccessApprovals from './useAccessApprovals';

const tabPanelStyleSx = (theme: Theme) => ({
  overflow: 'hidden',
  marginTop: theme.spacing(1),
});

const ManageApproversPage = () => {
  const { t, ready } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const canAccessApprovals = useAccessApprovals();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const approverTabs = {
    department: {
      title: t('Entities.Department.Department'),
      icon: <MapRoundedIcon />,
      component: <DepartmentApprovers />,
      priority: 1,
    },
    employee: {
      title: t('Entities.Employee.Employee'),
      icon: <GridOnRoundedIcon />,
      component: <EmployeeApprovers />,
      priority: 2,
    },
  };

  const approversTabKeys = useMemo(
    () => Object.keys(approverTabs),
    [approverTabs]
  );
  const availableTabs = approversTabKeys.filter((tab) =>
    location.pathname.includes(tab)
  );

  useEffect(() => {
    // Temporary solution, should generalize this.
    if (!canAccessApprovals) navigate('/access-denied', { replace: true });
    if (availableTabs.length === 0)
      navigate('/timeTracking/timeSheetApprovers/department', {
        replace: true,
      });
  }, [availableTabs, canAccessApprovals, navigate]);

  const selectedTab = availableTabs.length > 0 ? availableTabs[0] : undefined;

  return (
    <TetoContainer withBottomNav>
      {ready && selectedTab && (
        <PageTabs
          customTabPanelSx={tabPanelStyleSx as SxProps}
          tabs={approverTabs}
          selectedTab={selectedTab}
          handleTabChange={(e) =>
            navigate(`/timeTracking/timeSheetApprovers/${e}`)
          }
        />
      )}
    </TetoContainer>
  );
};

export default ManageApproversPage;
