const getClockInsByDateQuery = `query clockInsByDay($startDate: DateTime, $endDate: DateTime) {
  clockInsByDay(
    skip: 0
    order: { date: DESC }
    where: { date: { gte: $startDate }, and: { date: { lte: $endDate } } }
  ) {
    items {
      clockIns {
        id
        startTime
        endTime
        date
        totalDuration
      }
      date
      startTime
      endTime
      employee {
        name
        id
      }
      totalDuration
      workingDuration
    }
  }
}
`;

export default getClockInsByDateQuery;
