import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import {
  Box,
  IconButton,
  TextField,
  Theme,
  Tooltip,
  useTheme,
} from '@mui/material';
import { ConfirmDialogs } from '@teto/react-component-library';
import { t } from 'i18next';
import React, { useState } from 'react';
import { Setter } from '../../../helpers/setterGeneric';

interface PONumberProps {
  id: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setPurchaseOrder: Setter<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setReceivingValues: React.Dispatch<any>;
}

const textFieldSx = (theme: Theme) => ({
  width: theme.spacing(17),
  marginTop: theme.spacing(1),
  [theme.breakpoints.down('md')]: {
    width: theme.spacing(14),
    marginTop: theme.spacing(0),
  },
  '& .MuiInput-input.Mui-disabled': {
    fontWeight: 'bold',
  },
});

const PONumber = (props: PONumberProps) => {
  const { id, setPurchaseOrder, setReceivingValues } = props;
  const theme = useTheme();

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          maxWidth: theme.spacing(18),
        }}
      >
        <TextField
          name="purchaseOrderId"
          variant="standard"
          value={`PO: #${id}`}
          sx={textFieldSx}
          disabled
        />
        <Tooltip title="Close Purchase Order">
          <IconButton
            onClick={() => setIsDialogOpen(true)}
            sx={{
              [theme.breakpoints.up('sm')]: {
                marginTop: theme.spacing(1),
              },
            }}
          >
            <CloseRoundedIcon />
          </IconButton>
        </Tooltip>
      </Box>

      <ConfirmDialogs
        open={isDialogOpen}
        title={t('dialogs.cancelActivePO.title')}
        content={t('dialogs.cancelActivePO.content')}
        leftButton={{
          label: 'No',
          onClick: () => setIsDialogOpen(false),
        }}
        rightButton={{
          label: 'Yes',
          onClick: () => {
            setReceivingValues(undefined);
            setPurchaseOrder(undefined);
          },
        }}
      />
    </>
  );
};

export default PONumber;
