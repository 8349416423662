import { TypeSummaryReducer } from '@inovua/reactdatagrid-enterprise/types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isNumber = (value: any) =>
  typeof value === 'number' && Number.isFinite(value);

export const averageGroupSummary: TypeSummaryReducer = {
  initialValue: 0,
  reducer: (acc, newVal) => acc + (isNumber(newVal) ? newVal : 0),
  complete: (value, arr) => (arr.length ? value / arr.length : 0),
};

export const minGroupSummary: TypeSummaryReducer = {
  initialValue: 0,
  reducer: (acc, newVal) => acc + (isNumber(newVal) ? newVal : 0),
  complete: (_value, arr) => arr.sort()[0],
};

export const maxGroupSummary: TypeSummaryReducer = {
  initialValue: 0,
  reducer: (acc, newVal) => acc + (isNumber(newVal) ? newVal : 0),
  complete: (_value, arr) => arr.sort().reverse()[0],
};

export const sumGroupSummary: TypeSummaryReducer = {
  initialValue: 0,
  reducer: (acc, newVal) => acc + (isNumber(newVal) ? newVal : 0),
};
