/* eslint-disable no-unused-vars */
import { CellProps } from '@inovua/reactdatagrid-enterprise/types';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import {
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import {
  ETOIconButton,
  ETOSelectField,
  ETOTextField,
  Inspector,
  MessageContext,
} from '@teto/react-component-library';
import { t } from 'i18next';
import { debounce, isEmpty, isUndefined } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { EditInfo } from '../../../components/TetoGrid/InlineEditing/IEditInfo';
import TInlineEditingError from '../../../components/TetoGrid/InlineEditing/TInlineEditingError';
import { PurchaseOrder, PurchaseOrderDetail } from './PurchaseOrder';

const paginationSx: SxProps<Theme> = (theme: Theme) => ({
  position: 'absolute',
  bottom: '0',
  marginBottom: '16px',
  width: 'calc(100% - 48px)',
  '& .MuiTypography-root': {
    pt: '8px',
  },
  '& .MuiGrid-item:last-child': {
    textAlign: 'right',
  },
  [theme.breakpoints.down('md')]: {
    width: 'calc(100% - 36px)',
  },
});

const listContainerSx: SxProps<Theme> = (theme: Theme) => ({
  overflow: 'auto',
  maxHeight: '90%',
  [theme.breakpoints.down('md')]: {
    maxHeight: '80%',
  },
});

const selectContainerSx: SxProps = {
  margin: 2,
  '& .MuiFormControl-root': {
    width: '100%',
    '& .MuiAutocomplete-endAdornment': {
      top: '8px',
    },
  },
  '& .MuiIconButton-root': {
    marginTop: 1,
  },
};

const listSx = (theme: Theme) => ({
  [theme.breakpoints.up('sm')]: {
    marginBottom: theme.spacing(2),
  },
});
const warningSx = (theme: Theme) => ({
  '& .MuiFormLabel-root ': {
    color: theme.palette.warning.main,
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.warning.main,
  },
});

interface ViewPartsInspectorProps {
  open: boolean;
  title: string;
  onClose: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dataSource: any[];
  selectedPOPart?: PurchaseOrderDetail;
  setSelectedPOPart: React.Dispatch<
    React.SetStateAction<PurchaseOrderDetail | undefined>
  >;
  onEditComplete: (props: EditInfo) => void;
  calcLabelQuantities: (receivedVal: number) => number;
  checkInlineEditingCondition: (
    value: string,
    data: {
      purchaseQty: number;
      status: {
        quantityReceived: number;
      };
    }
  ) => TInlineEditingError;
}

const PartDetailsInspector = (props: ViewPartsInspectorProps) => {
  const {
    title,
    open,
    onClose,
    dataSource,
    selectedPOPart,
    setSelectedPOPart,
    onEditComplete,
    calcLabelQuantities,
    checkInlineEditingCondition,
  } = props;

  const [toReceiveValue, setToReceiveValue] =
    useState<number | string | undefined>(0);
  const [numberOfLabelsValue, setNumberOfLabelsValue] =
    useState<number | undefined>(0);
  const [selectedPartId, setSelectedPartId] = useState<number | undefined>(
    selectedPOPart?.id
  );
  const currentPOIndex = dataSource.findIndex((i) => i?.id === selectedPartId);
  const count = Object.entries(dataSource ?? {}).length;
  const purchaseQty = selectedPOPart?.purchaseQty ?? 0;
  const quantityReceived = selectedPOPart?.status?.quantityReceived ?? 0;
  const purchaseQtyExceeds = purchaseQty - quantityReceived;
  const isBiggerThanZero =
    selectedPOPart?.toReceive && selectedPOPart?.toReceive > 0;
  const exceedsReceivedValue =
    selectedPOPart?.toReceive && selectedPOPart?.toReceive > purchaseQtyExceeds;
  const messageContext = React.useContext(MessageContext);
  const [errorState, setErrorState] = useState<TInlineEditingError>({
    errorIs: false,
    errorText: '',
  });

  // const [errorState, setErrorState] = useState<TInlineEditingError>(
  //   selectedPOPart
  //     ? checkInlineEditingCondition(
  //         selectedPOPart?.toReceive?.toString() ?? '0',
  //         {
  //           purchaseQty: selectedPOPart?.purchaseQty ?? 0,
  //           // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  //           status: selectedPOPart.status!,
  //         }
  //       )
  //     : {
  //         errorIs: false,
  //         errorText: '',
  //       }
  // );

  const _handleNext = useCallback(() => {
    const nextItem = dataSource[currentPOIndex + 1];
    if (currentPOIndex >= 0 && currentPOIndex < dataSource.length - 1) {
      setSelectedPOPart(nextItem);
      setSelectedPartId(nextItem?.id);
    }

    if (nextItem?.toReceive || nextItem?.toReceive === 0) {
      setToReceiveValue(nextItem?.toReceive);
    }

    if (isUndefined(selectedPOPart?.toReceive)) {
      setToReceiveValue(0);
    }

    if (nextItem?.numberOfLabels || nextItem?.numberOfLabels === 0) {
      setNumberOfLabelsValue(nextItem?.numberOfLabels);
    }

    if (isUndefined(selectedPOPart?.numberOfLabels)) {
      setNumberOfLabelsValue(0);
    }
  }, [
    currentPOIndex,
    dataSource,
    selectedPOPart?.numberOfLabels,
    selectedPOPart?.toReceive,
    setSelectedPOPart,
  ]);

  const _handlePrev = useCallback(() => {
    const prevItem = dataSource[currentPOIndex - 1];
    if (currentPOIndex >= 0 && currentPOIndex < dataSource.length) {
      setSelectedPOPart(prevItem);
      setSelectedPartId(prevItem.id);
    }
    if (prevItem?.toReceive || prevItem?.toReceive === 0) {
      setToReceiveValue(prevItem?.toReceive);
    }

    if (isUndefined(selectedPOPart?.toReceive)) {
      setToReceiveValue(0);
    }

    if (prevItem?.numberOfLabels || prevItem?.numberOfLabels === 0) {
      setNumberOfLabelsValue(prevItem?.numberOfLabels);
    }

    if (isUndefined(selectedPOPart?.numberOfLabels)) {
      setNumberOfLabelsValue(0);
    }
  }, [
    currentPOIndex,
    dataSource,
    selectedPOPart?.numberOfLabels,
    selectedPOPart?.toReceive,
    setSelectedPOPart,
  ]);

  const _handleSelect = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setSelectedPartId(parseInt(e.target.value, 10));
    },
    []
  );

  useEffect(() => {
    const poPart = dataSource.find((r) => r.id === selectedPartId);
    setSelectedPOPart(poPart);
  }, [dataSource, selectedPartId, setSelectedPOPart]);

  const _handleToReceive = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setToReceiveValue(parseInt(e.target.value, 10));
      setNumberOfLabelsValue(calcLabelQuantities(parseInt(e.target.value, 10)));
      if (
        selectedPOPart &&
        selectedPOPart.purchaseQty &&
        selectedPOPart.status?.quantityReceived
      ) {
        const checkIfError = checkInlineEditingCondition(e.target.value, {
          purchaseQty: selectedPOPart.purchaseQty,
          status: selectedPOPart.status,
        });
        setErrorState(checkIfError);
        if (checkIfError.errorIs === true) {
          return messageContext.setError(checkIfError.errorText);
        }
        if (checkIfError.errorIs === 'warning') {
          messageContext.setWarning(checkIfError.errorText);
        }
      }

      return onEditComplete({
        value: parseInt(e.target.value, 10),
        columnId: 'toReceive',
        cellProps: { data: { id: selectedPartId } } as unknown as CellProps,
        rowIndex: 0,
        columnIndex: 0,
        rowId: '',
        data: {},
      });
    },
    [
      calcLabelQuantities,
      checkInlineEditingCondition,
      messageContext,
      onEditComplete,
      selectedPOPart,
      selectedPartId,
    ]
  );

  const _handleNumberOfLabels = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setNumberOfLabelsValue(parseInt(e.target.value, 10));
      return onEditComplete({
        value: parseInt(e.target.value, 10),
        columnId: 'numberOfLabels',
        cellProps: { data: { id: selectedPartId } } as unknown as CellProps,
        rowIndex: 0,
        columnIndex: 0,
        rowId: '',
        data: {},
      });
    },
    [onEditComplete, selectedPartId]
  );
  useEffect(() => {
    if (selectedPOPart) {
      const errorResult = checkInlineEditingCondition(
        toReceiveValue?.toString() ?? '0',
        {
          purchaseQty: selectedPOPart?.purchaseQty ?? 0,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          status: selectedPOPart.status!,
        }
      );
      setErrorState(errorResult);
    }
  }, [checkInlineEditingCondition, selectedPOPart, toReceiveValue]);

  useEffect(() => {
    setToReceiveValue(selectedPOPart?.toReceive ?? 0);
    setNumberOfLabelsValue(selectedPOPart?.numberOfLabels ?? 0);
  }, [
    selectedPOPart,
    selectedPOPart?.numberOfLabels,
    selectedPOPart?.toReceive,
  ]);

  return (
    <Inspector open={open} title={title} onClose={onClose}>
      {selectedPOPart && (
        <>
          <Box sx={selectContainerSx}>
            <ETOSelectField
              label={t('Entities.Receiving.pOPartNumber')}
              name="pOPartNumber"
              value={selectedPartId}
              items={dataSource}
              handleChange={(e) => _handleSelect(e)}
              itemNameSelector={(i) => i.purchaseSupplierItem}
              itemValueSelector={(i) => i.id}
            />
          </Box>
          <Divider />
          <Box sx={listContainerSx} component="form" autoComplete="off">
            <List sx={listSx}>
              <ListItem>
                <ETOTextField
                  name="part-description"
                  label="PO Part Description"
                  multiline
                  maxRows={4}
                  minRows={4}
                  value={selectedPOPart?.purchaseSupplierDescription ?? ''}
                  disabled
                  inputLabelProps={{ shrink: true }}
                />
              </ListItem>
              <ListItem>
                <ETOTextField
                  label={t(
                    'Entities.Receiving.purchaseOrderDetail.spec.displayName'
                  )}
                  name="job"
                  value={selectedPOPart?.spec?.displayName ?? ''}
                  disabled
                  inputLabelProps={{ shrink: true }}
                />
              </ListItem>
              <ListItem>
                <ETOTextField
                  label="Project Id"
                  name="job"
                  value={selectedPOPart?.projectId ?? ''}
                  disabled
                  inputLabelProps={{ shrink: true }}
                />
              </ListItem>
              <ListItem>
                <ETOTextField
                  label="Job Id"
                  name="job-id"
                  value={selectedPOPart?.specId ?? ''}
                  disabled
                  inputLabelProps={{ shrink: true }}
                />
              </ListItem>
              <ListItem>
                <ETOTextField
                  label="Inventory Destination"
                  name="inventory-destination"
                  value={
                    isEmpty(selectedPOPart?.inventoryDetails)
                      ? '(none)'
                      : selectedPOPart?.inventoryDetails?.[0]
                  }
                  disabled
                  inputLabelProps={{ shrink: true }}
                />
              </ListItem>
              <ListItem>
                <ETOTextField
                  label="EXT. UOM"
                  name="ext-uom"
                  value={selectedPOPart?.purchaseUom ?? ''}
                  disabled
                  inputLabelProps={{ shrink: true }}
                />
              </ListItem>
              <ListItem>
                <ETOTextField
                  label="PO Quantity"
                  name="quantity"
                  value={selectedPOPart?.purchaseQty ?? ''}
                  disabled
                  inputLabelProps={{ shrink: true }}
                />
              </ListItem>
              <ListItem>
                <ETOTextField
                  label="Previously Received"
                  name="previouslyReceived"
                  value={selectedPOPart?.status?.quantityReceived ?? 0}
                  disabled
                  inputLabelProps={{ shrink: true }}
                />
              </ListItem>
              <ListItem>
                <ETOTextField
                  type="number"
                  label="To Receive"
                  name="toReceive"
                  value={toReceiveValue}
                  handleChange={(e) => setToReceiveValue(e.target.value)}
                  onBlur={_handleToReceive}
                  inputLabelProps={{ shrink: true }}
                  // error
                  error={
                    errorState.errorIs === true
                      ? errorState.errorText
                      : undefined
                  }
                  // @ts-expect-error : this is fine
                  sxProps={
                    errorState.errorIs === 'warning' ? warningSx : undefined
                  }

                  // error={
                  //   isBiggerThanZero || exceedsReceivedValue
                  //     ? t('Entities.Receiving.overReceived')
                  //     : ''
                  // }
                />
              </ListItem>
              <ListItem>
                <ETOTextField
                  type="number"
                  label="# of Labels"
                  name="numOfLabels"
                  value={numberOfLabelsValue}
                  handleChange={(e) =>
                    setNumberOfLabelsValue(parseInt(e.target.value, 10))
                  }
                  onBlur={_handleNumberOfLabels}
                  inputLabelProps={{ shrink: true }}
                />
              </ListItem>
              <ListItem>
                <ETOTextField
                  label="Process Schedule"
                  name="process-schedule"
                  value={selectedPOPart?.processSchedule?.number ?? ''}
                  disabled
                  inputLabelProps={{ shrink: true }}
                />
              </ListItem>
            </List>
          </Box>
          {dataSource && (
            <Box sx={paginationSx}>
              <Grid container>
                <Grid item xs={6}>
                  <Typography variant="body2">{`${
                    currentPOIndex + 1
                  } of ${count}`}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <ETOIconButton
                    disabled={currentPOIndex === dataSource.length - 1}
                    color="primary"
                    size="large"
                    onClick={_handleNext}
                    tooltipProps={{ title: 'Next' }}
                  >
                    <ArrowDownwardIcon />
                  </ETOIconButton>
                  <ETOIconButton
                    disabled={currentPOIndex === 0}
                    color="primary"
                    size="large"
                    onClick={_handlePrev}
                    tooltipProps={{ title: 'Prev' }}
                  >
                    <ArrowUpwardIcon />
                  </ETOIconButton>
                </Grid>
              </Grid>
            </Box>
          )}
        </>
      )}
    </Inspector>
  );
};

export default PartDetailsInspector;
