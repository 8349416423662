import { Chip, SxProps, Theme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const chipSx: SxProps<Theme> = (theme: Theme) => ({
  minWidth: theme.spacing(12),
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const TimesheetStatusButton = (props: any) => {
  const { missing, status, __group } = props;
  const { t } = useTranslation();

  return (
    <>
      {__group && <span>-</span>}
      {status === 'Approved' && (
        <Chip
          data-testid="approved"
          size="small"
          color="success"
          label={t('Entities.TimeSheet.approved')}
          sx={chipSx}
        />
      )}

      {status === 'Rejected' && (
        <Chip
          data-testid="rejected"
          size="small"
          color="error"
          label={t('Entities.TimeSheet.rejected')}
          sx={chipSx}
        />
      )}

      {status === 'Submitted' && (
        <Chip
          data-testid="submitted"
          size="small"
          color="primary"
          label={t('Entities.TimeSheet.submitted')}
          sx={chipSx}
        />
      )}
      {missing && (
        <Chip
          data-testid="incomplete"
          size="small"
          color="warning"
          label={t('Entities.OverdueTimesheets.incomplete')}
          sx={chipSx}
        />
      )}
      {(status === 'In Progress' || (!status && !__group && !missing)) && (
        <Chip
          data-testid="inProgress"
          size="small"
          color="info"
          label={t('Entities.TimeSheet.inProgress')}
          sx={chipSx}
        />
      )}
    </>
  );
};

export default TimesheetStatusButton;
