import Paper from '@mui/material/Paper';
import { SxProps, Theme } from '@mui/material/styles';
import React from 'react';

import useGridStyles from './GridStyles';

interface TetoContainerProps {
  withBottomNav?: boolean;
  children: React.ReactNode;
  receiveBtn?: React.ReactNode;
  customSx?: SxProps<Theme>;
}

const tetoContainerSx = (theme: Theme) => ({
  position: 'relative',
  padding: theme.spacing(2),
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: `${theme.shape.borderRadius}px`,
  backgroundColor: theme.palette.background.paper,
  '& .InovuaReactDataGrid': {
    height: '100%',
  },
});

const tetoContainerWithBottomNavSx = (theme: Theme) => ({
  [theme.breakpoints.down('md')]: {
    height: 'calc(100% - 5em)',
    padding: theme.spacing(1),
  },
});

const tetoContainerNavSx = (theme: Theme) => ({
  [theme.breakpoints.down('md')]: {
    height: 'calc(100% - 3em)',
    padding: theme.spacing(1),
  },
});

const TetoContainer = (props: TetoContainerProps) => {
  const { children, customSx, withBottomNav, receiveBtn } = props;

  const containerSx = withBottomNav
    ? tetoContainerWithBottomNavSx
    : tetoContainerNavSx;

  return (
    <Paper
      sx={[
        ...(Array.isArray(customSx) ? customSx : [customSx]),
        useGridStyles,
        tetoContainerSx,
        containerSx,
      ]}
    >
      {receiveBtn}
      {children}
    </Paper>
  );
};

export default TetoContainer;
