import { useFormikContext } from 'formik';
import React from 'react';
import { Errors } from '../InlineEditing/editorTypes';

import EmptyDisplay from './EmptyDisplay';
import FormatterErrorWrapper from './FormatterErrorWrapper';
import FormatterProps from './FormatterProps';

const StringFormatter = (props: FormatterProps) => {
  const formik = useFormikContext();
  const {
    value,
    column: { name, emptyDisplay },
    data,
    cellProps,
  } = props;
  const { errors } = formik;
  const error = errors[name as keyof Errors];
  const rowErrorIndex =
    (Object.hasOwn(data, '__editing') && data.__editing && name) === name;

  const element = !value ? (
    <EmptyDisplay placeHolder={emptyDisplay} />
  ) : (
    <span>{value}</span>
  );
  const { editorProps } = cellProps;
  if (editorProps) {
    switch (editorProps.inlineEditMode) {
      case 'default':
        if (editorProps.inlineEditingCondition) {
          const result = editorProps.inlineEditingCondition(
            value,
            cellProps.data
          );

          if (result?.errorIs !== false) {
            return (
              <FormatterErrorWrapper
                errorIs={result.errorIs}
                errorText={result?.errorText}
              >
                {element}
              </FormatterErrorWrapper>
            );
          }
        }
        break;

      default:
        if (error && rowErrorIndex) {
          return (
            <FormatterErrorWrapper errorIs errorText={error}>
              {element}
            </FormatterErrorWrapper>
          );
        }
        break;
    }
  }
  return element;
};

export default StringFormatter;
