import {
  Box,
  List,
  SxProps,
  Theme,
  TooltipProps,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  ETOIconButton,
  formatHoursAsHourMin,
} from '@teto/react-component-library';
import React, { useState } from 'react';
import NoRecords from '../NoRecords/NoRecords';

const summaryContainerSx = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  height: 'auto',
  justifyContent: 'flex-start',
  rowGap: theme.spacing(1),
  width: '100%',
});

const headerSx = (theme: Theme) => ({
  alignItems: 'center',
  color: theme.palette.text.primary,
  columnGap: theme.spacing(1),
  display: 'flex',
  flexGrow: 0,
  height: theme.spacing(6),
  paddingBottom: theme.spacing(2),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(1),
  paddingTop: theme.spacing(2),
  width: '100%',

  [theme.breakpoints.down('lg')]: {
    borderTopLeftRadius: `${theme.shape.borderRadius}px`,
    borderTopRightRadius: `${theme.shape.borderRadius}px`,
  },
});

const noIconSx = (theme: Theme) => ({
  paddingRight: theme.spacing(2),
});

const leftHeaderSx = {
  flexGrow: 1,
  fontWeight: 500,
};

const rightHeaderSx = {
  alignItems: 'center',
  display: 'flex',
  flexGrow: 0,
  justifyContent: 'flex-end',
  '& > p': {
    fontWeight: 500,
  },
};

const contentSx = (theme: Theme) => ({
  flexDirection: 'column',
  minHeight: '80%',
  overflowY: 'auto',
  width: '100%',
  [theme.breakpoints.up('md')]: {},
  '& >:nth-of-type(2n)': {
    backgroundColor: theme.palette.background.default,
  },
  '& >:nth-of-type(2n + 1)': {
    backgroundColor: theme.palette.background.paper,
  },
});

const recordsSx = (theme: Theme) => ({
  justifyContent: 'flex-start',
  '&> :last-child': {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
});

const noRecordsSx = {
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};

const invisibleSx = {
  display: 'none',
  visibility: 'collapse',
};

const visibleSx = {
  display: 'flex',
  flexGrow: 1,
  minHeight: '40%',
};

const collapsedSx = {
  flexGrow: 0,
  minHeight: '12% !important',
};

const growSx = {
  flexGrow: 1,
};

export type ToolTipPlacement =
  | 'bottom-end'
  | 'bottom-start'
  | 'bottom'
  | 'left-end'
  | 'left-start'
  | 'left'
  | 'right-end'
  | 'right-start'
  | 'right'
  | 'top-end'
  | 'top-start'
  | 'top';

interface SummaryWrapperProps {
  customSx?: SxProps<Theme>;
  icon?: React.ReactNode;
  title: string;
  total: number;
  onClick?: () => void;
  children: React.ReactNode;
  tooltipProps?: Omit<TooltipProps, 'children'>;
}

const SummaryWrapper = (props: SummaryWrapperProps) => {
  const { children, customSx, icon, onClick, title, total, tooltipProps } =
    props;
  const [isCollapsed, setIsCollapsed] = useState(false);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));
  const totalHours = `Total: ${formatHoursAsHourMin(total ?? 0)}`;

  const _handleClick = () => {
    if (onClick) onClick();
    setIsCollapsed((d) => !d);
  };

  return (
    <List
      sx={[
        summaryContainerSx,
        ...(Array.isArray(customSx) ? customSx : [customSx]),
        { ...(!isSmall && isCollapsed ? collapsedSx : growSx) },
      ]}
      component="div"
    >
      <Box sx={headerSx}>
        <Typography sx={leftHeaderSx} align="left" variant="body1">
          {title}
        </Typography>
        <Box sx={[{ ...(!icon ? noIconSx : null) }, rightHeaderSx]}>
          <Typography variant="body1">{totalHours}</Typography>
          {icon && (
            <ETOIconButton
              color="primary"
              size={isSmall ? 'large' : 'medium'}
              onClick={() => _handleClick()}
              tooltipProps={tooltipProps}
            >
              {icon}
            </ETOIconButton>
          )}
        </Box>
      </Box>
      <Box
        sx={[
          contentSx,
          { ...(children ? recordsSx : noRecordsSx) },
          { ...(!isSmall && isCollapsed ? invisibleSx : visibleSx) },
        ]}
      >
        {children ?? <NoRecords customSx={noRecordsSx} />}
      </Box>
    </List>
  );
};

export default SummaryWrapper;
