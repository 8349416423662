import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
import PunchClockOutlinedIcon from '@mui/icons-material/PunchClockOutlined';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  AuthContext,
  PageTabs,
  SettingsContext,
} from '@teto/react-component-library';
import TetoContainer from '../../components/TetoGrid/TetoContainer';
import ClockInsTab from './ClockInsTab/ClockInsTab';
import TimeSheetsTab from './TimeSheetsTab/TimeSheetsTab';
import useSessionStorage from '../../helpers/useSessionStorage';

const MyTimeTrackerPage = () => {
  const { t, ready } = useTranslation();

  const navigate = useNavigate();

  const settingsContext = useContext(SettingsContext);
  const { disableClockIn, hasEnterpriseLicense } = useContext(AuthContext);
  const CLOCKIN_URL = '/timeTracking/myTimeTracker/clockIns/';

  const defaultTabs = {
    timesheet: {
      title: t('generic.timeSheet'),
      icon: <EventNoteOutlinedIcon />,
      component: <TimeSheetsTab />,
    },
  };

  const enterpriseTabs = !disableClockIn && {
    clockins: {
      title: t('generic.clockIns'),
      icon: <PunchClockOutlinedIcon />,
      component: <ClockInsTab />,
    },
  };

  const tabs = hasEnterpriseLicense()
    ? { ...defaultTabs, ...enterpriseTabs }
    : defaultTabs;

  const [selected, setSelected] = useSessionStorage(
    'selected-tab',
    'timesheet'
  );

  const _handleChange = (e: string) => {
    setSelected(e);
  };

  useEffect(() => {
    if (selected === 'clockIns') {
      navigate(CLOCKIN_URL, { replace: true });
    }
  }, [navigate, selected, settingsContext.settings.startDayOfWeek]);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {ready && selected && (
        <TetoContainer withBottomNav>
          <PageTabs
            tabs={tabs}
            selectedTab={selected}
            handleTabChange={(e) => _handleChange(e)}
            customTabPanelSx={{ overflow: 'hidden' }}
          />
        </TetoContainer>
      )}
    </>
  );
};

export default React.memo(MyTimeTrackerPage);
