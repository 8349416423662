import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import DeleteIcon from '@mui/icons-material/Delete';
import StopRoundedIcon from '@mui/icons-material/StopRounded';
import { TooltipProps } from '@mui/material';
import { ETOIconButton } from '@teto/react-component-library';
import React from 'react';
import { TFunction } from 'react-i18next';
import { ModelMetadata } from 'teto-client-api';
import ColumnTypes from '../ColumnTypes';
import EditButton from '../components/EditButton';
import FormatterProps from '../Formatters/FormatterProps';
import {
  averageGroupSummary,
  maxGroupSummary,
  minGroupSummary,
  sumGroupSummary,
} from '../Grouping/GroupAggregateFunctions';
import TypeSummaryReducer from '../Grouping/TypeSummaryReducer';
import TableColumnDefinition from '../TableColumnDefinition';

function getEmptyDisplay<T>(
  processor: ModelMetaDataProcessor<T>,
  columnType: string
): string | undefined {
  if (Object.prototype.hasOwnProperty.call(processor.emptyDisplay, columnType))
    return processor.emptyDisplay[columnType];
  return processor.emptyDisplay['*'];
}

const calculateAlign = (type: ColumnTypes) => {
  if (type === 'number' || type === 'time' || type === 'date') return 'end';
  return 'start';
};

const getGroupSummaryReducer = (
  val?: 'sum' | 'min' | 'max' | 'avg'
): TypeSummaryReducer | undefined => {
  switch (val) {
    case 'avg':
      return averageGroupSummary;
    case 'min':
      return minGroupSummary;
    case 'max':
      return maxGroupSummary;
    case 'sum':
      return sumGroupSummary;
    default:
      return undefined;
  }
};

const getFilterType = (type: ColumnTypes): string => {
  switch (type) {
    case 'boolean':
    case 'number':
    case 'string':
      return type;
    case 'hours':
      return 'number';
    case 'foreignKey':
      return 'string';
    default:
      return 'string';
  }
};

const getFilterOptions = (
  type: string,
  filterOptions: 'simple' | 'standard' | string[] | undefined
): string[] | undefined => {
  if (Array.isArray(filterOptions)) {
    return filterOptions;
  }
  if (filterOptions === 'simple') {
    switch (type) {
      case 'boolean':
      case 'number':
        return ['equal'];
      case 'string':
      case 'foreignKey':
        return ['contains'];
      default:
        return undefined;
    }
  } else if (filterOptions === 'standard') {
    switch (type) {
      case 'boolean':
        return ['equal'];
      case 'number':
        return ['equal', 'noEqual', 'greaterThan', 'lessThan'];
      case 'string':
      case 'foreignKey':
        return ['startsWith', 'endsWidth', 'contains', 'equal', 'notEqual'];
      default:
        return undefined;
    }
  } else {
    switch (type) {
      case 'boolean':
        return ['equal'];
      case 'number':
        return ['equal', 'noEqual', 'greaterThan', 'lessThan'];
      case 'string':
      case 'foreignKey':
        return ['startsWith', 'endsWidth', 'contains', 'equal', 'notEqual'];
      default:
        return undefined;
    }
  }
};

// eslint-disable-next-line no-unused-vars
interface AddButtonDefinition<T> {
  // eslint-disable-next-line no-unused-vars
  render: (item: T) => string | React.ReactElement;
  width?: number;
  name: string;
  title?: string;
  lockedTo?: 'left' | 'right';
}

export type AddColumn = Omit<Omit<TableColumnDefinition, 'id'>, 'order'>;

type AddPosition = { column: string; position: 'before' | 'after' } | undefined;

class ModelMetaDataProcessor<T> {
  metaData?: ModelMetadata;

  removeProperties: string[] = [];

  emptyDisplay: { [key: string]: string } = { '*': ' - ' };

  additionalColumns: {
    position: AddPosition;
    colDef: TableColumnDefinition;
  }[] = [];

  customFormatters: {
    // eslint-disable-next-line no-unused-vars
    [key: string]: (item: FormatterProps) => string | React.ReactElement;
  } = {};

  updates: { [key: string]: Partial<TableColumnDefinition> } = {};

  constructor(metaData?: ModelMetadata) {
    this.metaData = metaData;
  }

  setEmptyDisplay(
    columnName: string,
    placeHolder: string
  ): ModelMetaDataProcessor<T> {
    this.emptyDisplay[columnName] = placeHolder;
    return this;
  }

  removeProperty(
    name: string,
    conditionalRenderFunc?: () => boolean
  ): ModelMetaDataProcessor<T> {
    if (conditionalRenderFunc && !conditionalRenderFunc()) {
      return this;
    }

    this.removeProperties.push(name);
    return this;
  }

  addCustomFormatterForColumn(
    colName: string,
    // eslint-disable-next-line no-unused-vars
    renderer: (item: FormatterProps) => string | React.ReactElement
  ) {
    this.customFormatters[colName] = renderer;

    return this;
  }

  addColumn(col: AddColumn, position?: AddPosition) {
    this.additionalColumns.push({
      colDef: {
        ...col,
        header: col.header ?? col.title,
        id: col.name,
        order: this.additionalColumns.length,
      },
      position,
    });
    return this;
  }

  addEditButton(
    // eslint-disable-next-line no-unused-vars
    onClick: (data: T) => void,
    // eslint-disable-next-line no-unused-vars
    conditionalRenderFunc?: (item: T) => boolean
  ) {
    this.addButton(
      {
        name: 'editButton',
        lockedTo: 'right',
        width: 120,

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        render: (data: any) => (
          <EditButton data={data} onClick={() => onClick(data)} />
        ),
      },
      undefined,
      conditionalRenderFunc
    );

    return this;
  }

  addViewButton(
    // eslint-disable-next-line no-unused-vars
    onClick: (data: T) => void,
    // eslint-disable-next-line no-unused-vars
    conditionalRenderFunc?: (item: T) => boolean
  ) {
    this.addButton(
      {
        name: 'viewButton',
        lockedTo: 'right',
        width: 60,
        render: (data) => (
          <ETOIconButton
            color="primary"
            size="medium"
            onClick={() => onClick(data)}
            buttonProps={{
              tabIndex: 0,
            }}
            tooltipProps={
              {
                title: 'View Details',
                placement: 'bottom-end',
              } as TooltipProps
            }
          >
            <ArrowForwardIosRoundedIcon />
          </ETOIconButton>
        ),
      },
      undefined,
      conditionalRenderFunc
    );

    return this;
  }

  addDeleteButton(
    // eslint-disable-next-line no-unused-vars
    onClick: (data: T) => void,
    // eslint-disable-next-line no-unused-vars
    conditionalRenderFunc?: (item: T) => boolean
  ) {
    this.addButton(
      {
        name: 'deleteButton',
        lockedTo: 'right',
        width: 60,
        render: (data) => (
          <ETOIconButton
            color="error"
            confirm={{
              type: 'okCancel',
              title: 'Are you sure you wish to delete this record?',
              content:
                'Do you wish to delete this record?  This operation is permanent and cannot be undone',
            }}
            size="medium"
            onClick={(e) => {
              e?.preventDefault();
              onClick(data);
            }}
            buttonProps={{
              tabIndex: 0,
            }}
            tooltipProps={
              {
                title: 'Delete Record',
                placement: 'bottom-end',
              } as TooltipProps
            }
          >
            <DeleteIcon />
          </ETOIconButton>
        ),
      },
      undefined,
      conditionalRenderFunc
    );

    return this;
  }

  addStopButton(
    // eslint-disable-next-line no-unused-vars
    onClick: (data: T) => void,
    // eslint-disable-next-line no-unused-vars
    conditionalRenderFunc?: (item: T) => boolean
  ) {
    this.addButton(
      {
        name: 'stopButton',
        lockedTo: 'right',
        width: 60,
        render: (data) => (
          <ETOIconButton
            color="error"
            size="medium"
            onClick={() => onClick(data)}
            buttonProps={{
              tabIndex: 0,
            }}
            tooltipProps={
              {
                title: 'Stop',
                placement: 'bottom-end',
              } as TooltipProps
            }
          >
            <StopRoundedIcon />
          </ETOIconButton>
        ),
      },
      undefined,
      conditionalRenderFunc
    );

    return this;
  }

  addApproveButton(
    // eslint-disable-next-line no-unused-vars
    onClick: (data: T) => void,
    // eslint-disable-next-line no-unused-vars
    conditionalRenderFunc?: (item: T) => boolean
  ) {
    this.addButton(
      {
        name: 'approveButton',
        lockedTo: 'right',
        width: 60,
        render: (data) => (
          <ETOIconButton
            color="success"
            size="medium"
            onClick={() => onClick(data)}
            buttonProps={{
              tabIndex: 0,
            }}
            tooltipProps={
              {
                title: 'Approve',
                placement: 'bottom-end',
              } as TooltipProps
            }
          >
            <CheckRoundedIcon />
          </ETOIconButton>
        ),
      },
      undefined,
      conditionalRenderFunc
    );

    return this;
  }

  addRejectButton(
    // eslint-disable-next-line no-unused-vars
    onClick: (data: T) => void,
    // eslint-disable-next-line no-unused-vars
    conditionalRenderFunc?: (item: T) => boolean
  ) {
    this.addButton(
      {
        name: 'rejectButton',
        lockedTo: 'right',
        width: 60,
        render: (data) => (
          <ETOIconButton
            color="error"
            size="medium"
            onClick={() => onClick(data)}
            buttonProps={{
              tabIndex: 0,
            }}
            tooltipProps={
              {
                title: 'Reject',
                placement: 'bottom-end',
              } as TooltipProps
            }
          >
            <CloseRoundedIcon />
          </ETOIconButton>
        ),
      },
      undefined,
      conditionalRenderFunc
    );

    return this;
  }

  addButton(
    colDef: AddButtonDefinition<T>,
    position?: AddPosition,

    // eslint-disable-next-line no-unused-vars
    conditionalRenderFunc?: (item: T) => boolean
  ): ModelMetaDataProcessor<T> {
    const renderFunc = conditionalRenderFunc
      ? (item: T) => {
          if (conditionalRenderFunc(item)) {
            return colDef.render(item);
          }

          // eslint-disable-next-line react/jsx-no-useless-fragment
          return <></>;
        }
      : colDef.render;

    const colButtonDefinition: TableColumnDefinition = {
      align: 'center',
      // flex: 1, DO NOT ADD - This makes the reset button useless
      filterType: '',
      fixed: colDef.lockedTo ?? 'none',
      header: '',
      hidden: false,
      id: colDef.name,
      name: colDef.name,
      sortable: false,
      title: colDef.title ?? '',
      type: 'button',
      filterOptions: undefined,
      order: 999,
      width: colDef.width,
      renderFunction: renderFunc,
      disableGrouping: true,
      disableColumnFilterContextMenu: true,
      disableColumnMenuTool: true,
      disableHideable: true,
      editable: false,
    };

    this.additionalColumns.push({ position, colDef: colButtonDefinition });
    return this;
  }

  updateDefinition(columnName: string, data: Partial<TableColumnDefinition>) {
    this.updates[columnName] = data;
    return this;
  }

  finalize(t: TFunction<'translation', undefined>): TableColumnDefinition[] {
    const result = Object.entries(this.metaData ?? [])
      .sort((a, b) => b[1].order - a[1].order)
      .filter((a) => this.removeProperties.indexOf(a[0]) < 0)
      .map(
        // This is for adding the columns saved data: ie the persisted previous column settings from the db server
        // If updating here - consider updating the the gridPersistance
        (metaItem): TableColumnDefinition => ({
          id: metaItem[1].name,
          name: metaItem[1].name,
          type: metaItem[1].type ?? (metaItem[1].objectType as ColumnTypes),
          order: metaItem[1].order,
          width: metaItem[1].defaultWidth,
          title: t(metaItem[1].title),
          header: t(metaItem[1].title),
          hidden: !metaItem[1].defaultVisible,
          sortable: metaItem[1].sortable,
          align: calculateAlign(metaItem[1].type as ColumnTypes),
          emptyDisplay: getEmptyDisplay(this, metaItem[1].type),
          columnGrouping: metaItem[1].columnGrouping
            ? t(metaItem[1].columnGrouping)
            : undefined,
          filterType: getFilterType(
            (metaItem[1].type as ColumnTypes) ??
              (metaItem[1].objectType as ColumnTypes)
          ),
          filterOptions: getFilterOptions(
            metaItem[1].type ?? (metaItem[1].objectType as ColumnTypes),
            metaItem[1].filterOptions
          ),
          //   ? undefined
          //   : getFilterOptions(
          //       metaItem[1].type as ColumnTypes,
          //       metaItem[1].filterOptions,
          //   ),
          fixed: 'none',
          groupSummaryReducer: getGroupSummaryReducer(
            metaItem[1].groupingAggregate
          ),
          renderFunction: this.customFormatters[metaItem[1].name],
        })
      );

    Object.entries(this.updates).forEach((item) => {
      const matching = result.find((a) => a.name === item[0]);
      if (matching) {
        const index = result.indexOf(matching);
        const newItem = {
          ...matching,
          ...item[1],
        };
        result[index] = newItem;
      }
    });

    this.additionalColumns.forEach((cc) => {
      if (!cc.position) {
        result.push(cc.colDef);
      } else {
        const matchingColumn = result.find(
          (a) => a.name === cc.position?.column
        );
        if (matchingColumn) {
          if (cc.position?.position === 'after') {
            const matchingIndex = result.indexOf(matchingColumn);
            result.splice(matchingIndex + 1, 0, cc.colDef);
          } else {
            const matchingIndex = result.indexOf(matchingColumn);
            result.splice(
              matchingIndex === 0 ? matchingIndex : matchingIndex - 1,
              0,
              cc.colDef
            );
          }
        }
      }
    });

    const newResult = result.filter(
      (a) => this.removeProperties.indexOf(a.name) < 0
    );
    return newResult;
  }
}

export const createColumnsFromMetaData = function _createColumnsFromMetaData<T>(
  metaData: ModelMetadata
): ModelMetaDataProcessor<T> {
  return new ModelMetaDataProcessor<T>(metaData);
};

export const createColumns = function _createColumns<T>() {
  return new ModelMetaDataProcessor<T>();
};
