/* eslint-disable @typescript-eslint/no-explicit-any */
import dayjs from 'dayjs';
import * as Yup from 'yup';
import CommonFormValidation from '../TimecardFormValidation';

const REQUIRED_MESSAGE = 'Required';

const YupSchema = Yup.object()
  .shape({
    startTime: Yup.object()
      .required(REQUIRED_MESSAGE)
      .nullable()
      .test('date-validation', 'Invalid Date', (val: any) =>
        dayjs.isDayjs(val)
      ),
    endTime: Yup.object()
      .required(REQUIRED_MESSAGE)
      .nullable()
      .test('date-validation', 'Invalid Date', (val: any) =>
        dayjs.isDayjs(val)
      ),
  })
  .concat(CommonFormValidation);

export default YupSchema;
