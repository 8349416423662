import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LockRoundedIcon from '@mui/icons-material/LockRounded';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import {
  AuthContext,
  DateRangeSelector,
  MessageContext,
  SettingsContext,
  YesNoConfirmDialog,
} from '@teto/react-component-library';
import dayjs from 'dayjs';
import { LatLngExpression, Map } from 'leaflet';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { QueryFunctionContext, QueryKey } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  betweenDate,
  deleteTimeCard,
  getModelMetadata,
  getTimecards,
  ModelMetadata,
  Permission,
  TimeCard,
  Licenses,
  TimeCardLockedReason,
  updateTimeCardByHours,
  updateTimeCardByTime,
} from 'teto-client-api';
import { AdminAddHoursButton } from '../../components/Buttons/AddHoursButton/AddHoursButton';
import ExportButton from '../../components/Buttons/ExportButton/ExportButton';
import MapPopover from '../../components/MapWrapper/MapPopover/MapPopover';
import { createColumnsFromMetaData } from '../../components/TetoGrid/ModelMetaDataProcessor/ModelMetaDataProcessor';
import RDGSelectedType from '../../components/TetoGrid/RDGSelectedType';
import TetoContainer from '../../components/TetoGrid/TetoContainer';
import TetoGrid from '../../components/TetoGrid/TetoGrid';
import TetoGridRefType from '../../components/TetoGrid/TetoGridRefType';
import AddHoursInspector from '../../components/TimeSheets/AddHoursInspector/AddHoursInspector';
import EditInspector from '../../components/TimeSheets/EditInspector/EditInspector';
import TimecardFormValidation from '../../components/TimeSheets/TimeSheetForms/TimecardFormValidation';
import { DateRange } from '../../helpers/dateTypes';
import defaultDateRange from '../../helpers/defaultDateRange';
import {
  hourbasedRequestFormatOutput,
  timebasedRequestFormatOutput,
} from '../../helpers/timecardRequestFormats';
import isNotEmptyOrNullLocation from '../GeolocationPage/helpers/isNotEmptyOrNullLocation';
import ActionButton, {
  OkCancelConfirmDialog,
} from '../Timesheets/ApprovalsPage/components/ActionButton/ActionButton';
import getLockedColumns from './InlineEditing/disabledColumns';
import {
  dependents,
  timeCardTernaryBooleans,
} from './InlineEditing/editingConstants';
import {
  processTimecardForeignKey,
  processTimecardForeignKeyChange,
} from './InlineEditing/foreignKeyPartnerLookup';
import getTimecardSelectorQuery from './InlineEditing/selectorQueries';

function createDeferred<PT>(): Promise<
  PT & {
    // eslint-disable-next-line no-unused-vars
    resolve: ((value: PT | PromiseLike<PT>) => void) | null;
    // eslint-disable-next-line no-unused-vars
    reject: ((value: PT | PromiseLike<PT>) => void) | null;
  }
> {
  // eslint-disable-line
  // eslint-disable-next-line no-unused-vars
  let resolve: ((value: PT | PromiseLike<PT>) => void) | null = null;
  // eslint-disable-next-line no-unused-vars
  let reject: ((value: PT | PromiseLike<PT>) => void) | null = null;
  const p = new Promise<PT>((_resolve, _reject) => {
    resolve = _resolve;
    reject = _reject;
  });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return Object.assign(p, { resolve, reject }) as any;
}

const TimeCardsPage = () => {
  const { t, ready } = useTranslation();
  const TABLE_ID = t('Entities.Timecard.tableIdentifier');

  const theme = useTheme();
  const mobileSize = useMediaQuery(theme.breakpoints.down('md'));
  const mapWidth = mobileSize ? 300 : 400;
  const mapHeight = mobileSize ? 300 : 400;

  const authContext = useContext(AuthContext);
  const settingsContext = useContext(SettingsContext);
  const messageContext = useContext(MessageContext);
  const [dataSource, setDataSource] = useState([]);
  const canAccessTimesheets =
    authContext.hasAnyLicense() &&
    (authContext.hasAnyPermission([
      Permission.View_Admin_Timecards,
      Permission.Modify_Admin_Timecards,
    ]) ||
      (settingsContext.settings.approverCanManageTime &&
        authContext.user?.isApprover));

  // const { mutate: deleteTimecard } = useDeleteTimecard(TABLE_ID);
  const { startDate, endDate } = useParams();
  const browserLocation = useLocation();
  const navigate = useNavigate();

  const [metaData, setMetaData] = useState<ModelMetadata | undefined>();
  const [newEntryInspector, setNewEntryInspector] = useState<boolean>(false);
  const [refreshToken, setRefreshToken] = useState<Date | undefined>();
  const [editingTimecard, setEditingTimecard] =
    useState<TimeCard | undefined>();
  const [selectedRows, setSelectedRows] = useState<RDGSelectedType>();
  const [isDiscardConfirmDialog, setIsDiscardConfirmDialog] =
    useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  // eslint-disable-next-line no-spaced-func
  const [isBatchSeparationConfirmation, setIsBatchSeparationConfirmation] =
    useState<
      | {
          // eslint-disable-next-line func-call-spacing
          resolve: (() => void) | null;
          reject: (() => void) | null;
        }
      | undefined
    >();
  const [mapModalEl, setMapModalEl] = useState<HTMLButtonElement | null>(null);
  const [map, setMap] = useState<Map>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [dataArr, setDataArr] = useState<any[]>([]);
  const [mapCoordinates, setMapCoordinates] = useState<LatLngExpression>();

  const gridRef = React.useRef<TetoGridRefType | undefined>();

  const [queryDateRange, setQueryDateRange] = useState<DateRange>({
    start: startDate
      ? dayjs(startDate)
      : dayjs(defaultDateRange(settingsContext.settings.startDayOfWeek).start),
    end: endDate
      ? dayjs(endDate)
      : dayjs(defaultDateRange(settingsContext.settings.startDayOfWeek).end),
  });

  useEffect(() => {
    // Temporary solution, should generalize this.
    if (!canAccessTimesheets) navigate('/access-denied', { replace: true });
    if (!startDate || !endDate) {
      const defaultRange = defaultDateRange(
        settingsContext.settings.startDayOfWeek
      );
      navigate(
        `/timeTracking/timecards/${defaultRange.start.format(
          'YYYY-MM-DD'
        )}/${defaultRange.end.format('YYYY-MM-DD')}`,
        { replace: true }
      );
    }
  }, [
    authContext,
    browserLocation.key,
    canAccessTimesheets,
    endDate,
    navigate,
    settingsContext.settings.startDayOfWeek,
    startDate,
  ]);

  const _handleDateChange = (e: DateRange) => {
    setQueryDateRange(e);
    navigate(
      `/timeTracking/timecards/${e.start.format('YYYY-MM-DD')}/${e.end.format(
        'YYYY-MM-DD'
      )}`
    );
  };

  useEffect(() => {
    if (authContext.hasProfessionalLicense()) {
      getModelMetadata('timecard')
        .then((e) => setMetaData(e))
        .catch((e) => {
          messageContext.setError(
            `${t('generic.message.failMeta')}.  ${e.message}`
          );
        });
    }
  }, [authContext, messageContext, t]);

  const _handleTimecardDelete = useCallback(
    (data: TimeCard) => {
      // deleteTimecard({ timecard: data });
      deleteTimeCard(data.id)
        .then(() => {
          setRefreshToken(new Date());
          messageContext.setSuccess(t('generic.message.recordDeleted'));
        })
        .catch(() => {
          messageContext.setError(t('generic.message.failDeleteTimeCard'));
        });
    },
    [messageContext, t]
  );

  const handleMapClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any
  ) => {
    setDataArr([data]);
    setMapCoordinates(
      data.timecardCoords &&
        isNotEmptyOrNullLocation(data.timecardCoords) &&
        (data.timecardCoords
          .split(', ')
          .map((d: string) => parseFloat(d)) as LatLngExpression)
    );
    setMapModalEl(event.currentTarget);
  };

  const canModify = useCallback(
    (employeeId) =>
      authContext.hasLicense(Licenses.TotalETOProfessional) &&
      (authContext.hasPermission(Permission.Modify_Admin_Timecards) ||
        (settingsContext.settings.approverCanManageTime &&
          authContext.canApprove(employeeId))),
    [authContext, settingsContext.settings]
  );
  const canDelete = useCallback(
    (employeeId) =>
      authContext.hasLicense(Licenses.TotalETOProfessional) &&
      (authContext.hasPermission(Permission.Delete_Admin_Timecards) ||
        (settingsContext.settings.approverCanManageTime &&
          authContext.canApprove(employeeId))),
    [authContext, settingsContext.settings]
  );
  const canInlineEdit = useMemo(
    () =>
      authContext.hasLicense(Licenses.TotalETOProfessional) &&
      (authContext.hasPermission(Permission.Modify_Admin_Timecards) ||
        settingsContext.settings.approverCanManageTime),
    [authContext, settingsContext.settings]
  );

  const cols = useMemo(() => {
    if (metaData && ready) {
      return createColumnsFromMetaData<TimeCard>(metaData)
        .updateDefinition('startTime', { disableGrouping: true })
        .updateDefinition('endTime', { disableGrouping: true })
        .updateDefinition('projectName', { minWidth: 200 })
        .updateDefinition('specName', { minWidth: 200 })
        .updateDefinition('hourTypeName', { minWidth: 200 })
        .updateDefinition('employeeFullName', { minWidth: 130 })
        .updateDefinition('custom7', { width: 120, minWidth: 120 })
        .updateDefinition('custom8', { width: 120, minWidth: 120 })
        .updateDefinition('id', { filterOptions: undefined })
        .updateDefinition('hourTime', { disableGrouping: true })
        .updateDefinition('actualHours', { disableGrouping: true })
        .updateDefinition('roundedHours', { disableGrouping: true })
        .updateDefinition('locked', {
          bitwiseOptions: TimeCardLockedReason,
          filterOptions: 'simple',
          type: 'bitwise',
          align: 'center',
        })
        .updateDefinition('timecardCoords', {
          editable: false,
        })
        .updateDefinition('exportErrorMsg', {
          editable: false,
        })
        .updateDefinition('billable', { editable: true })
        .removeProperty(
          'custom1',
          () => t('Entities.Timecard.TimecardCustom1') === 'Timecard Custom 1'
        )
        .removeProperty(
          'custom2',
          () => t('Entities.Timecard.TimecardCustom2') === 'Timecard Custom 2'
        )
        .removeProperty(
          'custom3',
          () => t('Entities.Timecard.TimecardCustom3') === 'Timecard Custom 3'
        )
        .removeProperty(
          'custom4',
          () => t('Entities.Timecard.TimecardCustom4') === 'Timecard Custom 4'
        )
        .removeProperty(
          'custom5',
          () => t('Entities.Timecard.TimecardCustom5') === 'Timecard Custom 5'
        )
        .removeProperty(
          'custom6',
          () => t('Entities.Timecard.TimecardCustom6') === 'Timecard Custom 6'
        )
        .removeProperty(
          'custom7',
          () => t('Entities.Timecard.TimecardCustom7') === 'Timecard Custom 7'
        )
        .removeProperty(
          'custom8',
          () => t('Entities.Timecard.TimecardCustom8') === 'Timecard Custom 8'
        )
        .removeProperty(
          'pSCCustom1',
          () =>
            t(
              'Entities.Process Schedule In House Transaction.CompletedLogCustom1'
            ) === 'Process Schedule In House Transaction Custom 1'
        )
        .removeProperty(
          'pSCCustom2',
          () =>
            t(
              'Entities.Process Schedule In House Transaction.CompletedLogCustom2'
            ) === 'Process Schedule In House Transaction Custom 2'
        )
        .removeProperty(
          'pSCCustom3',
          () =>
            t(
              'Entities.Process Schedule In House Transaction.CompletedLogCustom3'
            ) === 'Process Schedule In House Transaction Custom 3'
        )
        .removeProperty(
          'pSCCustom4',
          () =>
            t(
              'Entities.Process Schedule In House Transaction.CompletedLogCustom4'
            ) === 'Process Schedule In House Transaction Custom 4'
        )
        .removeProperty(
          'pSCCustom5',
          () =>
            t(
              'Entities.Process Schedule In House Transaction.CompletedLogCustom5'
            ) === 'Process Schedule In House Transaction Custom 5'
        )
        .removeProperty(
          'pSCCustom6',
          () =>
            t(
              'Entities.Process Schedule In House Transaction.CompletedLogCustom6'
            ) === 'Process Schedule In House Transaction Custom 6'
        )
        .removeProperty(
          'pSCCustom7',
          () =>
            t(
              'Entities.Process Schedule In House Transaction.CompletedLogCustom7'
            ) === 'Process Schedule In House Transaction Custom 7'
        )
        .removeProperty(
          'pSCCustom8',
          () =>
            t(
              'Entities.Process Schedule In House Transaction.CompletedLogCustom8'
            ) === 'Process Schedule In House Transaction Custom 8'
        )
        .addColumn({
          name: 'timecardGeolocation',
          title: t('menu.geolocation'),
          type: 'button',
          align: 'center',
          sortable: false,
          editable: false,
          filterType: 'none',
          fixed: 'none',
          disableGrouping: true,
          disableHideable: false,
          disableColumnMenuTool: true,
          minWidth: mobileSize ? 60 : 100,
          width: mobileSize ? 60 : 100,
          headerProps: {
            style: {
              textAlign: 'center',
            },
          },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          renderFunction: (data: any) =>
            data.timecardCoords &&
            isNotEmptyOrNullLocation(data.timecardCoords) ? (
              <Box>
                <IconButton
                  disabled={isEditing}
                  onClick={(e) => handleMapClick(e, data)}
                  color={isEditing ? 'secondary' : 'primary'}
                >
                  <LocationOnIcon />
                </IconButton>
              </Box>
            ) : (
              '-'
            ),
        })
        .addColumn({
          name: 'action',
          title: t('generic.action'),
          type: 'button',
          align: mobileSize ? 'center' : 'end',
          sortable: false,
          editable: false,
          filterType: 'none',
          fixed: 'right',
          disableGrouping: true,
          disableHideable: true,
          disableColumnMenuTool: true,
          minWidth: mobileSize ? 60 : 144,
          width: mobileSize ? 60 : 144,
          headerProps: {
            style: {
              textAlign: 'center',
            },
          },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          renderFunction: (data: any) => (
            <ActionButton
              isGridEditing={isEditing}
              actionItems={[
                ...(data.locked !== 0
                  ? [
                      {
                        componentName: t('generic.info'),
                        icon: <LockRoundedIcon color="action" />,
                        handleClick: () => setEditingTimecard(data),
                        title: t('forms.lockedTimecards'),
                      },
                    ]
                  : []),
                ...(data.locked === 0 && !data.__editing
                  ? [
                      {
                        componentName: t('generic.edit'),
                        title: t('forms.editTimecardButton'),
                        icon: (
                          <EditIcon
                            color={
                              isEditing || !canModify(data.employeeId)
                                ? 'disabled'
                                : 'primary'
                            }
                          />
                        ),

                        handleClick: () => setEditingTimecard(data),
                      },
                      ...(canDelete(data.employeeId)
                        ? [
                            {
                              componentName: t('generic.delete'),
                              title: t('generic.deleteRecord'),
                              icon: (
                                <DeleteIcon
                                  color={
                                    isEditing || !canDelete(data.employeeId)
                                      ? 'disabled'
                                      : 'error'
                                  }
                                />
                              ),
                              handleClick: () => _handleTimecardDelete(data),
                              confirm: {
                                type: 'okCancel',
                                title: t('dialogs.deleteRecord.title'),
                                content: t('dialogs.deleteRecord.content'),
                                data,
                              } as OkCancelConfirmDialog,
                            },
                          ]
                        : []),
                    ]
                  : []),
                {
                  componentName: t('Entities.Timecard.viewTimesheet'),
                  title: t('Entities.Timecard.viewTimesheet'),
                  icon: (
                    <ArrowForwardIosRoundedIcon
                      color={isEditing ? 'disabled' : 'primary'}
                    />
                  ),
                  handleClick: () =>
                    navigate(`/timeTracking/timesheets/${data.timeSheetId}`, {
                      state: browserLocation.pathname,
                    }),
                },
              ]}
              data={data}
              setIsDiscardConfirmDialog={setIsDiscardConfirmDialog}
            />
          ),
        })
        .finalize(t);
    }
    return [];
  }, [
    _handleTimecardDelete,
    browserLocation.pathname,
    canDelete,
    canModify,
    isEditing,
    metaData,
    mobileSize,
    navigate,
    ready,
    t,
  ]);

  function _doQuery(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    context: QueryFunctionContext<QueryKey, any>,
    pageSize: number,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    queryData: { filters: any; orderBy: any } & { params: DateRange }
  ) {
    return getTimecards({
      pageIndex: context.pageParam,
      pageSize,
      disablePaging: false,
      orderBy: queryData.orderBy,
      filter: {
        date: betweenDate(queryData.params.start, queryData.params.end),
        ...queryData.filters,
      },
    }).then((d) => ({
      ...d,
      records: d.records,
    }));
  }

  const _handleRecordUpdate = (e: 'Add' | undefined) => {
    setRefreshToken(new Date());
    messageContext.setSuccess(t('generic.message.recordUpdated'));
    if (e === 'Add') {
      setNewEntryInspector(() => false);
    }
  };
  const ExportBtn = ExportButton({ gridRef });
  const AddHoursBtn = AdminAddHoursButton({
    disabled: !authContext.hasLicense(Licenses.TotalETOProfessional),
    onClick: () => setNewEntryInspector(() => true),
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const _onSubmitting = (v: any, i: any) => {
    if (v?.hourTime !== i?.hourTime) {
      return Promise.resolve({ ...v, hourChanged: true });
    }
    return Promise.resolve({ ...v, hourChanged: false });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const _onSubmit = async (values: any) => {
    if (Object.hasOwn(values, 'startTime')) {
      return updateTimeCardByTime(
        values.id,
        timebasedRequestFormatOutput(values)
      );
    }

    if (Object.hasOwn(values, 'batchId')) {
      if (values?.hourChanged) {
        return updateTimeCardByHours(values.id, {
          ...values,
          hours: values.hourTime,
        });
      }
      const promise = createDeferred();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setIsBatchSeparationConfirmation(promise as any);
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      await promise;
      return updateTimeCardByHours(values.id, {
        ...values,
        hours: values.hourTime,
        batchId: null,
        batched: false,
      });
    }

    return updateTimeCardByHours(values.id, {
      ...hourbasedRequestFormatOutput(values),
      hours: values.hourTime,
    });
  };

  const canAdd =
    authContext.hasAnyLicense() &&
    (authContext.hasPermission(Permission.Add_Admin_Timecards) ||
      authContext.user?.isApprover);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {ready && cols && metaData && (
        <TetoContainer>
          <TetoGrid
            dataSource={dataSource}
            setDataSource={setDataSource}
            disableMobileCols
            serverSideFiltering
            ref={gridRef}
            pageSize={50000}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            doQuery={(a, b, c) => _doQuery(a, b, c as any)}
            columns={cols}
            refreshToken={refreshToken}
            externalQueryProps={queryDateRange}
            inlineEditMode="submitRowOnEnter"
            editable={
              !mobileSize
                ? {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    rowDisabled: (row: any) => row.locked,
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    onSubmitting: (v: any, i: any) => _onSubmitting(v, i),
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    onSubmit: (values: any) => _onSubmit(values),
                    // eslint-disable-next-line no-unused-vars
                    onSubmitSuccess: () => {
                      setRefreshToken(new Date());
                      return Promise.resolve();
                    },
                    hasEditPermission: canInlineEdit,
                    allowUnset: (value: string) =>
                      timeCardTernaryBooleans.includes(value),
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    selector: (c: string, d: any) =>
                      getTimecardSelectorQuery(c, d),
                    dependantColumns: dependents,
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    lockColumn: (c: string, d: any) => getLockedColumns(c, d),
                    validationSchema: TimecardFormValidation,
                    processForeignKey: processTimecardForeignKey,
                    handleForeignKeyChange: processTimecardForeignKeyChange,
                    formatEditableRow: (values) => ({
                      ...values,
                      requireComments: false,
                      requireCustom1: false,
                      requireCustom2: false,
                      requireCustom3: false,
                      requireCustom4: false,
                      requireCustom5: false,
                      requireCustom6: false,
                      requireCustom7: false,
                      requireCustom8: false,
                      requireNonConformance: false,
                      requirePSCCustom1: false,
                      requirePSCCustom2: false,
                      requirePSCCustom3: false,
                      requirePSCCustom4: false,
                      requirePSCCustom5: false,
                      requirePSCCustom6: false,
                      requirePSCCustom7: false,
                      requirePSCCustom8: false,
                      requireProcessSchedule: false,
                      requireQuantity: false,
                    }),
                  }
                : undefined
            }
            header={{
              leftChildren: (
                <DateRangeSelector
                  dates={queryDateRange}
                  onDateChange={(e) => _handleDateChange(e)}
                />
              ),
              rightChildren: [AddHoursBtn, ExportBtn],
            }}
            tableIdentifier={TABLE_ID}
            setSelectedRows={setSelectedRows}
            selectedRows={selectedRows}
            setEditingTimecard={setEditingTimecard}
            setIsEditing={setIsEditing}
            setIsDiscardConfirmDialog={setIsDiscardConfirmDialog}
            isDiscardConfirmDialog={isDiscardConfirmDialog}
          >
            {newEntryInspector && canAdd && (
              <AddHoursInspector
                allowEmployeeSelection
                canAdd={canAdd}
                open={newEntryInspector}
                onClose={() => setNewEntryInspector(() => false)}
                onUpdate={() => _handleRecordUpdate(t('generic.add'))}
              />
            )}
          </TetoGrid>
          {Boolean(editingTimecard) && canModify(editingTimecard?.employeeId) && (
            <EditInspector
              canEdit={canModify(editingTimecard?.employeeId)}
              canDelete={canDelete(editingTimecard?.employeeId)}
              allowEmployeeSelection
              timecard={editingTimecard}
              onClose={() => setEditingTimecard(undefined)}
              onDelete={() => {
                setEditingTimecard(undefined);
                setRefreshToken(new Date());
                messageContext.setSuccess(t('generic.message.recordDeleted'));
              }}
              onUpdate={() => {
                setEditingTimecard(undefined);
                setRefreshToken(new Date());
                messageContext.setSuccess(t('generic.message.recordUpdated'));
              }}
            />
          )}
          {Boolean(mapModalEl) && (
            <MapPopover
              dataArr={dataArr}
              map={map}
              setMap={setMap}
              mapCoordinates={mapCoordinates}
              mapModalEl={mapModalEl}
              setMapModalEl={setMapModalEl}
              mapWidth={mapWidth}
              mapHeight={mapHeight}
            />
          )}
          {isBatchSeparationConfirmation && (
            <YesNoConfirmDialog
              open={Boolean(isBatchSeparationConfirmation)}
              title={t('dialogs.separateBatchedRecord.title')}
              content={t('dialogs.separateBatchedRecord.content')}
              onYes={() => {
                if (isBatchSeparationConfirmation?.resolve) {
                  isBatchSeparationConfirmation?.resolve();
                }
                setIsBatchSeparationConfirmation(undefined);
              }}
              onNo={() => {
                if (isBatchSeparationConfirmation?.reject)
                  isBatchSeparationConfirmation?.reject();
                setIsBatchSeparationConfirmation(undefined);
              }}
            />
          )}
        </TetoContainer>
      )}
    </>
  );
};

export default TimeCardsPage;
