import { TypeColumn } from '@inovua/reactdatagrid-community/types';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { AppearanceContext } from '@teto/react-component-library';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactDataGrid from '@inovua/reactdatagrid-enterprise';
import { Formik } from 'formik';
import { createColumns } from '../../../../components/TetoGrid/ModelMetaDataProcessor/ModelMetaDataProcessor';
import { formatHoursAsHourMin } from '../../../../helpers/dateHelpers';
import ClockIn from '../../../ClockInsPage/ClockIn';
import { buildCellFormatter } from '../../../../components/TetoGrid/Formatters/cellFormatterHelper';
import { reactDataGridLicenseKey } from '../../../../components/TetoGrid/Licensing';

interface TimeTrackerRowDetailsProps {
  data: ClockIn[] | undefined;
}

const TimeTrackerRowDetails = (props: TimeTrackerRowDetailsProps) => {
  const { ready, t } = useTranslation();

  const { data } = props;

  const appearanceContext = useContext(AppearanceContext);

  const theme = useTheme();
  const mobileSize = useMediaQuery(theme.breakpoints.down('md'));

  const [mappedData] = useState<ClockIn[] | undefined>([]);

  useEffect(() => {
    data?.forEach((a) => a.clockIns.forEach((d) => mappedData?.push(d)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const scrollStyle = {
    scrollThumbStyle: { backgroundColor: theme.palette.primary.main },
  };

  const currentTheme =
    appearanceContext.themeVariant === 'dark'
      ? 'default-dark'
      : 'default-light';

  const cols = useMemo(() => {
    if (ready) {
      return createColumns<ClockIn>()
        .addColumn({
          name: 'date',
          title: t('generic.date'),
          type: 'date',
          align: 'start',
          editable: false,
          sortable: false,
          filterType: 'string',
          filterOptions: 'simple',
          fixed: 'none',
          minWidth: 180,
          width: 180,
          disableGrouping: true,
          disableHideable: true,
          disableColumnMenuTool: true,
        })
        .addColumn({
          name: 'startTime',
          title: t('Entities.ClockIns.clockIn'),
          type: 'time',
          align: 'start',
          editable: true,
          sortable: false,
          filterType: 'none',
          fixed: 'none',
          minWidth: 180,
          width: 180,
          defaultFlex: 1,
          disableGrouping: true,
          disableHideable: true,
          disableColumnMenuTool: true,
        })
        .addColumn({
          name: 'endTime',
          title: t('Entities.ClockIns.clockOut'),
          type: 'time',
          align: 'start',
          editable: true,
          sortable: false,
          filterType: 'none',
          fixed: 'none',
          minWidth: 180,
          width: 180,
          defaultFlex: 1,
          disableGrouping: true,
          disableHideable: true,
          disableColumnMenuTool: true,
          resizable: false,
        })
        .addColumn({
          name: 'totalDuration',
          title: t('generic.total'),
          type: 'custom',
          align: 'center',
          editable: false,
          sortable: false,
          filterType: 'none',
          fixed: 'none',
          minWidth: 75,
          width: 75,
          disableGrouping: true,
          disableHideable: true,
          disableColumnMenuTool: true,
          resizable: false,
          hidden: false,
          defaultVisible: true,
          renderFunction: (d) => {
            if (d.totalDuration) {
              return <span>{formatHoursAsHourMin(d.totalDuration / 60)}</span>;
            }
            return <span>-</span>;
          },
        })

        .finalize(t);
    }
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobileSize, ready, t]);

  const convertedToRDGColumns = useMemo<TypeColumn[]>(
    () =>
      cols.map((c) => ({
        name: c.name,
        type: c.type,
        header: c.title,
        hideable: !c.disableHideable,
        showInContextMenu: false,
        hidden: c.hidden,
        align: c.align,
        groupByName: c.title,
        isCheckboxColumn: c.type === 'boolean',
        group: c.group,
        draggable: !c.disableGrouping,
        // draggable: c.type !== 'custom' && !c.disableGrouping,
        id: c.name,
        width: c.width,
        defaultWidth: c.defaultWidth,
        minWidth: c.minWidth,
        flex: c.flex,
        defaultFlex: c.defaultFlex,
        textAlign: c.align,
        headerProps: c.headerProps,
        filterDelay: 500,
        sortable: c.sortable,
        visible: !c.hidden,
        enableColumnFilterContextMenu: !c.disableColumnFilterContextMenu,
        showColumnMenuTool: false,
        groupBy: !c.disableGrouping,
        render: buildCellFormatter(c),
        filterType: c.filterType,
        filterable: true,
      })),
    [cols]
  );

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {data && (
        <Box
          sx={{
            height: '100%',
            [theme.breakpoints.down('md')]: {
              position: 'absolute',
              width: 'inherit',
            },
          }}
        >
          <Formik
            initialValues={{}}
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onSubmit={() => {}}
          >
            <ReactDataGrid
              dataSource={mappedData ?? []}
              columns={convertedToRDGColumns}
              licenseKey={reactDataGridLicenseKey}
              filterRowHeight={0}
              scrollProps={scrollStyle}
              theme={currentTheme}
              emptyText={t('generic.message.noRecordsFound')}
            />
          </Formik>
        </Box>
      )}
    </>
  );
};

export default TimeTrackerRowDetails;
