import {
  CheckBoxOutlineBlankRounded,
  CheckBoxRounded,
} from '@mui/icons-material';
import IndeterminateCheckBoxRounded from '@mui/icons-material/IndeterminateCheckBoxRounded';
import {
  EmployeeSettings,
  formatHoursAsHourMin,
  ServerSystemSettings,
} from '@teto/react-component-library';
import dayjs, { Dayjs } from 'dayjs';
import React, { useMemo } from 'react';
import TableColumnDefinition from '../../../../components/TetoGrid/TableColumnDefinition';

interface ColumnValueFormatterProps {
  value?: string | number | boolean | Dayjs | null;
  column: TableColumnDefinition;
  settings: ServerSystemSettings &
    EmployeeSettings & {
      dateTimeFormat: string;
    };
}

const ColumnValueFormatter = (
  props: ColumnValueFormatterProps
  // eslint-disable-next-line no-undef
): JSX.Element => {
  const { column, settings, value } = props;
  const emptyDisplay = '-';
  const element = useMemo(() => {
    if (value == null || value === undefined || value === '')
      return emptyDisplay;
    switch (column.type) {
      case 'currency':
        return `$${value}`;
      case 'hours':
        return formatHoursAsHourMin(value as number);
      case 'time':
        return dayjs(value as string | number | Dayjs).isValid()
          ? dayjs(value as string | number | Dayjs).format(settings.timeFormat)
          : emptyDisplay;
      case 'date':
        return dayjs(value as string | number | Dayjs).isValid()
          ? dayjs(value as string | number | Dayjs).format(settings.dateFormat)
          : emptyDisplay;
      case 'dateTime':
        return dayjs(value as string | number | Dayjs).isValid()
          ? dayjs(value as string | number | Dayjs).format(
              settings.dateTimeFormat
            )
          : emptyDisplay;
      case 'boolean':
        if (value === undefined) {
          return <IndeterminateCheckBoxRounded color="disabled" />;
        }
        if (!value) {
          return <CheckBoxOutlineBlankRounded color="disabled" />;
        }
        return <CheckBoxRounded color="disabled" />;
      case 'foreignKey':
      case 'string':
      case 'number':
      default:
        return value as React.ReactNode;
    }
  }, [
    column,
    settings.dateFormat,
    settings.dateTimeFormat,
    settings.timeFormat,
    value,
  ]);

  // eslint-disable-next-line no-undef
  return element as JSX.Element;
};

export default ColumnValueFormatter;
