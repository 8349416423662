import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import AddCardIcon from '@mui/icons-material/AddCard';
import CalendarViewDayRoundedIcon from '@mui/icons-material/CalendarViewDayRounded';
import ContactsRounded from '@mui/icons-material/ContactsRounded';
import EventAvailableRoundedIcon from '@mui/icons-material/EventAvailableRounded';
import EventNoteRoundedIcon from '@mui/icons-material/EventNoteRounded';
import HowToVoteRounded from '@mui/icons-material/HowToVoteRounded';
import PinDropIcon from '@mui/icons-material/PinDrop';
import RunningWithErrorsIcon from '@mui/icons-material/RunningWithErrors';
import Settings from '@mui/icons-material/Settings';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import { capitalize } from '@mui/material';
import {
  AuthContext,
  LoadingPage,
  PageWrapper,
  RouteMenuItem,
  SettingsContext,
  SidebarMenu,
} from '@teto/react-component-library';

import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  matchPath,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { Licenses, Permission } from 'teto-client-api';

import ClockInModalNotification from './components/ClockInModalNotification/ClockInModalNotification';
import getPageArticleNumbers from './components/HelpLinks/helpers/getPageArticleNumber';
import NavHelpLink from './components/HelpLinks/NavHelpLink/NavHelpLink';
import ClockInTimerContext from './components/TimeSheets/NavTimer/ClockInContext';
import NavTimer from './components/TimeSheets/NavTimer/NavTimer';
import TimerInspectors from './components/TimeSheets/NavTimer/TimerInspectors';
import TimecardMigrationDialog from './components/TimeSheets/TimecardMigrationDialog/TimecardMigrationDialog';
import ClockInsPage from './pages/ClockInsPage/ClockInsPage';
import AdminPermissionErrorPage from './pages/ErrorPages/AdminPermissionErrorPage';
import RoutingErrorPage from './pages/ErrorPages/RoutingErrorPage';
import GeolocationPage from './pages/GeolocationPage/GeolocationPage';
import HomePage from './pages/HomePage/HomePage';
import ManageApproversPage from './pages/ManageApproversPage/ManageApproversPage';
import MyTimeTrackerPage from './pages/MyTimeTrackerPage/MyTimeTrackerPage';
import OverdueTimesheetsPage from './pages/OverdueTimesheetsPage/OverdueTimesheetsPage';
import PayPeriodsPage from './pages/PayPeriodsPage/PayPeriodsPage';
import PunchInsPage from './pages/PunchInsPage/PunchInsPage';
import ReceivingPage from './pages/ReceivingPage/ReceivingPage';
import SettingsPage from './pages/SettingsPage/SettingsPage';
import TimeCardsPage from './pages/TimeCardsPage/TimeCardsPage';
import TimesheetsApprovalsPage from './pages/Timesheets/ApprovalsPage/TimesheetsApprovalsPage';
import TimesheetDetailsPage from './pages/Timesheets/TimesheetsDetailsPage/TimesheetsDetailsPage';
import TimesheetsPage from './pages/Timesheets/TimesheetsPage/TimesheetsPage';

interface RouteDef {
  displayName?: string;
  route?: string;
  index?: boolean;
  component?: React.ReactElement;
  routes?: RouteDef[];
  helpId?: string | number;
}

const URL = '';

const isRouteActive = (route: string, pathname: string) =>
  !!matchPath(`${URL}${route}`, pathname) || pathname.includes(route);

const AuthenticatedRoutes = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation(['generic']);
  const authContext = useContext(AuthContext);
  const settingsContext = useContext(SettingsContext);
  const clockInContext = useContext(ClockInTimerContext);
  const anchorRef = React.useRef<HTMLElement>(null);
  const [isClockInNotificationVisible, setIsClockInNotificationVisible] =
    useState(false);

  const navMenuItems = [
    ...(authContext.hasEnterpriseLicense()
      ? [{ component: <NavTimer />, priority: 2 }]
      : []),
  ];

  useEffect(() => {
    if (clockInContext.status !== 'active') {
      return setIsClockInNotificationVisible(true);
    }
    setIsClockInNotificationVisible(false);
  }, [clockInContext.status]);

  const _selectedRoute = (menuItem: RouteMenuItem) =>
    navigate(`${URL}${menuItem.route}`);

  const routes: RouteDef[] = [
    {
      route: '/',
      displayName: 'Home',
      component: <HomePage />,
      helpId: '5658553066516-Getting-Started-with-Time-Tracker',
    },
    {
      route: '/timeTracking/timesheets',
      routes: [
        {
          index: true,
          displayName: t('pages.timesheets.title'),
          component: <TimesheetsPage />,
          helpId: '5659157371668#view-and-edit-employee-time-0-2',
        },
        {
          route: 'week',
          displayName: t('pages.timesheets.title'),
          component: <TimesheetsPage />,
          helpId: '5659157371668#view-and-edit-employee-time-0-2',
        },
        {
          route: 'week/:startDate/:endDate',
          displayName: t('pages.timesheets.title'),
          component: <TimesheetsPage />,
          helpId: '5659157371668#view-and-edit-employee-time-0-2',
        },
        {
          route: ':id',
          displayName: t('pages.timesheetsDetails.title'),
          component: <TimesheetDetailsPage />,
          helpId: '5659157371668#view-and-edit-employee-time-0-2',
        },
        {
          route: 'month',
          displayName: t('pages.timesheets.title'),
          component: <TimesheetsPage />,
        },
        {
          route: 'month/:startDate/:endDate',
          displayName: t('pages.timesheets.title'),
          component: <TimesheetsPage />,
        },
      ],
    },
    {
      route: '/timeTracking/overduetimesheets',
      routes: [
        {
          index: true,
          displayName: t('pages.overdueTimesheets.title'),
          component: <OverdueTimesheetsPage />,
        },
        {
          route: ':startDate/:endDate',
          displayName: t('pages.overdueTimesheets.title'),
          component: <OverdueTimesheetsPage />,
        },
      ],
    },
    {
      route: '/timeTracking/approvals',
      routes: [
        {
          index: true,
          displayName: t('pages.timesheetsApprovals.title'),
          component: <TimesheetsApprovalsPage />,
        },
        {
          route: 'week',
          displayName: t('pages.timesheetsApprovals.title'),
          component: <TimesheetsApprovalsPage />,
        },
        {
          route: 'week/:startDate/:endDate',
          displayName: t('pages.timesheetsApprovals.title'),
          component: <TimesheetsApprovalsPage />,
        },
        {
          route: ':id',
          displayName: t('pages.timesheetsDetails.title'),
          component: <TimesheetDetailsPage />,
        },
      ],
    },
    {
      route: '/timeTracking/myTimeTracker',
      routes: [
        {
          index: true,
          displayName: t('pages.timeTracker.title'),
          component: <MyTimeTrackerPage />,
          helpId: '5552067995924',
        },
        {
          route: 'timesheet/weekly/:weekStart/:weekEnd',
          displayName: t('pages.timeTracker.title'),
          component: <MyTimeTrackerPage />,
          helpId: '5552067995924',
        },
        {
          route: 'timesheet/weekly',
          displayName: t('pages.timeTracker.title'),
          component: <MyTimeTrackerPage />,
          helpId: '5552067995924',
        },
        {
          route: 'timesheet/monthly',
          displayName: t('pages.timeTracker.title'),
          component: <MyTimeTrackerPage />,
          helpId:
            '5552067995924-Enter-and-Manage-Your-Time#h_01G3VT2NZ48NC6EX0TT5DB1PGC',
        },
        {
          route: 'timesheet/monthly/:month/weekly/:weekStart/:weekEnd',
          displayName: t('pages.timeTracker.title'),
          component: <MyTimeTrackerPage />,
          helpId:
            '5552067995924-Enter-and-Manage-Your-Time#h_01G3VT2NZ48NC6EX0TT5DB1PGC',
        },
        {
          route: 'timesheet/monthly/:month',
          displayName: t('pages.timeTracker.title'),
          component: <MyTimeTrackerPage />,
          helpId:
            '5552067995924-Enter-and-Manage-Your-Time#h_01G3VT2NZ48NC6EX0TT5DB1PGC',
        },
        {
          route: 'clockIns',
          displayName: t('pages.timeTracker.title'),
          component: <MyTimeTrackerPage />,
          helpId: '5552067995924',
        },
        {
          route: 'clockIns/:startDate/:endDate',
          displayName: t('pages.timeTracker.title'),
          component: <MyTimeTrackerPage />,
          helpId: '5552067995924',
        },
      ],
    },
    {
      route: '/timeTracking/timecards',
      routes: [
        {
          index: true,
          displayName: t('pages.timecards.title'),
          component: <TimeCardsPage />,
          helpId:
            '5659157371668-Time-Tracker-Administration#view-and-edit-employee-time-0-2',
        },
        {
          route: ':startDate/:endDate',
          displayName: t('pages.timecards.title'),
          component: <TimeCardsPage />,
          helpId:
            '5659157371668-Time-Tracker-Administration#view-and-edit-employee-time-0-2',
        },
      ],
    },
    {
      route: '/timeTracking/timeSheetApprovers',
      routes: [
        {
          index: true,
          displayName: t('pages.manageApprovers.title'),
          component: <ManageApproversPage />,
          helpId: '10713693950740',
        },
        {
          route: ':approverTab',
          displayName: t('pages.manageApprovers.title'),
          component: <ManageApproversPage />,
          helpId: '10713693950740',
        },
      ],
    },
    {
      route: '/timeTracking/punch-ins',
      routes: [
        {
          index: true,
          displayName: t('pages.punchIns.title'),
          component: <PunchInsPage />,
          helpId:
            '5659157371668-Time-Tracker-Administration#view-and-edit-employee-time-0-2',
        },
      ],
    },
    {
      route: '/timeTracking/clock-ins',
      routes: [
        {
          index: true,
          displayName: t('pages.clockIns.title'),
          component: <ClockInsPage />,
        },
        {
          route: ':startDate/:endDate',
          displayName: t('pages.clockIns.title'),
          component: <ClockInsPage />,
        },
      ],
    },
    {
      route: '/timeTracking/payperiods',
      routes: [
        {
          index: true,
          displayName: t('pages.managePayPeriods.title'),
          component: <PayPeriodsPage />,
          helpId: '10516477473684',
        },
        {
          route: ':startDate/:endDate',
          displayName: t('pages.managePayPeriods.title'),
          component: <PayPeriodsPage />,
          helpId: '10516477473684',
        },
      ],
    },
    {
      route: '/receivePO',
      routes: [
        {
          index: true,
          displayName: t('pages.receivePO.title'),
          component: <ReceivingPage />,
        },
      ],
    },
    {
      route: '/timeTracking/geolocation',
      routes: [
        {
          index: true,
          displayName: t('pages.location.title'),
          component: <GeolocationPage />,
          helpId:
            '5659157371668-Time-Tracker-Administration#view-and-edit-employee-time-0-2',
        },
        {
          route: 'active',
          displayName: t('pages.location.title'),
          component: <GeolocationPage />,
          helpId:
            '5659157371668-Time-Tracker-Administration#view-and-edit-employee-time-0-2',
        },
        {
          route: 'historical',
          displayName: t('pages.location.title'),
          component: <GeolocationPage />,
          helpId:
            '5659157371668-Time-Tracker-Administration#view-and-edit-employee-time-0-2',
        },
        {
          route: 'historical/:startDate/:endDate',
          displayName: t('pages.location.title'),
          component: <GeolocationPage />,
          helpId:
            '5659157371668-Time-Tracker-Administration#view-and-edit-employee-time-0-2',
        },
      ],
    },
    {
      route: '/receiving',
      routes: [
        {
          index: true,
          displayName: t('pages.receiving.title'),
          component: <ReceivingPage />,
          helpId: authContext.isAdmin ? '11059059932052' : '10771850684692',
        },
        {
          route: 'summary',
          displayName: t('pages.receiving.title'),
          component: <ReceivingPage />,
          helpId: authContext.isAdmin ? '11059059932052' : '10771850684692',
        },
        {
          route: 'partDetails',
          displayName: t('pages.receiving.title'),
          component: <ReceivingPage />,
          helpId: authContext.isAdmin ? '11059059932052' : '10771850684692',
        },
        {
          route: 'bomDistribution',
          displayName: t('pages.receiving.title'),
          component: <ReceivingPage />,
          helpId: authContext.isAdmin ? '11059059932052' : '10771850684692',
        },
        {
          route: 'nonBOM',
          displayName: t('pages.receiving.title'),
          component: <ReceivingPage />,
          helpId: authContext.isAdmin ? '11059059932052' : '10771850684692',
        },
        {
          route: 'purchased',
          displayName: t('pages.receiving.title'),
          component: <ReceivingPage />,
          helpId: authContext.isAdmin ? '11059059932052' : '10771850684692',
        },
        {
          route: 'reservedPulledInventoty',
          displayName: t('pages.receiving.title'),
          component: <ReceivingPage />,
          helpId: authContext.isAdmin ? '11059059932052' : '10771850684692',
        },
        {
          route: 'issuedProcessSchedules',
          displayName: t('pages.receiving.title'),
          component: <ReceivingPage />,
          helpId: authContext.isAdmin ? '11059059932052' : '10771850684692',
        },
        {
          route: 'bomPartsOrderReleases',
          displayName: t('pages.receiving.title'),
          component: <ReceivingPage />,
          helpId: authContext.isAdmin ? '11059059932052' : '10771850684692',
        },
      ],
    },
    {
      route: '/settings',
      routes: [
        {
          index: true,
          displayName: t('pages.settings.title'),
          component: <SettingsPage />,
          helpId: getPageArticleNumbers(location.pathname),
        },
        {
          route: 'account',
          displayName: t('pages.settings.title'),
          component: <SettingsPage />,
          helpId: getPageArticleNumbers(location.pathname),
        },
        {
          route: 'user',
          displayName: t('pages.settings.title'),
          component: <SettingsPage />,
          helpId: getPageArticleNumbers(location.pathname),
        },
        {
          route: 'system',
          displayName: t('pages.settings.title'),
          component: <SettingsPage />,
          helpId: getPageArticleNumbers(location.pathname),
        },
      ],
    },
    {
      route: '/access-denied',
      displayName: t('pages.accessDenied.title'),
      component: <AdminPermissionErrorPage />,
    },
    {
      route: '*',
      displayName: '',
      component: <RoutingErrorPage />,
    },
  ];

  const firstMenuGroup = [
    {
      title: t('menu.timeTracker'),
      leftIcon: <AccessTimeRoundedIcon />,
      route: '/timeTracking/myTimeTracker',
      isActive: isRouteActive('/timeTracking/myTimeTracker', location.pathname),
      hidden:
        !authContext.hasAnyPermission([
          Permission.View_MyTimeTracker_Timecards,
          Permission.Add_MyTimeTracker_Timecards,
          Permission.Modify_MyTimeTracker_Timecards,
          Permission.Delete_MyTimeTracker_Timecards,
          Permission.View_MyTimeTracker_Punchcards,
          Permission.Add_MyTimeTracker_Punchcards,
          Permission.Modify_MyTimeTracker_Punchcards,
          Permission.Delete_MyTimeTracker_Punchcards,
        ]) || !authContext.hasAnyLicense(),
    },
    ...[
      {
        title: t('menu.timeAndAdministration'),
        leftIcon: <SupervisorAccountIcon />,
        route: '',
        menuItems: [
          {
            title: t('menu.timesheets'),
            hidden:
              !authContext.hasLicense(Licenses.ReadOnlyProfessional) ||
              !(
                authContext.hasAnyPermission([
                  Permission.View_Admin_Timecards,
                  Permission.Modify_Admin_Timecards,
                ]) || authContext.user?.isApprover
              ),
            leftIcon: <EventNoteRoundedIcon />,
            route: '/timeTracking/timesheets',
            isActive: isRouteActive(
              '/timeTracking/timesheets',
              location.pathname
            ),
          },
          {
            title: t('menu.overdueTimesheets'),
            hidden:
              !authContext.hasLicense(Licenses.ReadOnlyEnterprise) ||
              !(
                authContext.hasAnyPermission([
                  Permission.View_Admin_Timecards,
                  Permission.Modify_Admin_Timecards,
                ]) || authContext.user?.isApprover
              ),
            leftIcon: <RunningWithErrorsIcon />,
            route: '/timeTracking/overduetimesheets',
            isActive: isRouteActive(
              '/timeTracking/overduetimesheets',
              location.pathname
            ),
          },
          {
            title: t('menu.timesheetApprovals'),
            hidden:
              !authContext.hasLicense(Licenses.ReadOnlyEnterprise) ||
              !(
                authContext.hasAnyPermission([
                  Permission.View_Admin_Timecards,
                  Permission.Modify_Admin_Timecards,
                ]) || authContext.user?.isApprover
              ),
            leftIcon: <EventAvailableRoundedIcon />,
            route: '/timeTracking/approvals',
            isActive: isRouteActive(
              '/timeTracking/approvals',
              location.pathname
            ),
          },
          {
            title: t('menu.timecards'),
            hidden:
              !authContext.hasLicense(Licenses.ReadOnlyProfessional) ||
              !(
                authContext.hasAnyPermission([
                  Permission.View_Admin_Timecards,
                  Permission.Modify_Admin_Timecards,
                ]) || authContext.user?.isApprover
              ),
            leftIcon: <ContactsRounded />,
            route: '/timeTracking/timecards',
            isActive: isRouteActive(
              '/timeTracking/timecards',
              location.pathname
            ),
          },
          {
            title: t('menu.punchins'),
            hidden:
              !authContext.hasLicense(Licenses.ReadOnlyEnterprise) ||
              !(
                authContext.hasAnyPermission([
                  Permission.View_Admin_Timecards,
                  Permission.Modify_Admin_Timecards,
                ]) || authContext.user?.isApprover
              ),
            leftIcon: <HowToVoteRounded />,
            route: '/timeTracking/punch-ins',
            isActive: isRouteActive(
              '/timeTracking/punch-ins',
              location.pathname
            ),
          },
          {
            title: t('menu.clockIns'),
            hidden:
              !authContext.hasLicense(Licenses.ReadOnlyEnterprise) ||
              !(
                authContext.hasAnyPermission([
                  Permission.View_Admin_Timecards,
                  Permission.Modify_Admin_Timecards,
                ]) || authContext.user?.isApprover
              ),
            leftIcon: <AddCardIcon />,
            route: '/timeTracking/clock-ins',
            isActive: isRouteActive(
              '/timeTracking/clock-ins',
              location.pathname
            ),
          },
          {
            title: t('menu.geolocation'),
            hidden:
              !authContext.hasLicense(Licenses.ReadOnlyEnterprise) ||
              !(
                authContext.hasAnyPermission([
                  Permission.View_Admin_Timecards,
                  Permission.Modify_Admin_Timecards,
                ]) || authContext.user?.isApprover
              ),
            leftIcon: <PinDropIcon />,
            route: '/timeTracking/geolocation',
            isActive: isRouteActive(
              '/timeTracking/geolocation',
              location.pathname
            ),
          },
          {
            title: t('menu.manageApprovers'),
            hidden:
              !authContext.hasLicense(Licenses.ReadOnlyEnterprise) ||
              !authContext.hasAnyPermission([
                Permission.View_Admin_TimesheetApprovers,
                Permission.Modify_Admin_TimesheetApprovers,
              ]),
            leftIcon: <SettingsApplicationsIcon />,
            route: '/timeTracking/timesheetApprovers',
            isActive: isRouteActive(
              '/timeTracking/timeSheetApprovers',
              location.pathname
            ),
          },
          {
            title: t('menu.managePayPeriods'),
            hidden:
              !authContext.hasLicense(Licenses.ReadOnlyProfessional) ||
              !authContext.hasAnyPermission([
                Permission.View_Legacy_fdlgEditPayPeriod,
                Permission.Modify_Legacy_fdlgEditPayPeriod,
                Permission.Add_Legacy_fdlgEditPayPeriod,
              ]),
            leftIcon: <CalendarViewDayRoundedIcon />,
            route: '/timeTracking/payperiods',
            isActive: isRouteActive(
              '/timeTracking/payperiods',
              location.pathname
            ),
          },
        ],
      },
    ],
    {
      title: t('menu.receiving'),
      hidden:
        !authContext.hasLicense(Licenses.ReadOnlyProfessional) ||
        !authContext.hasAnyPermission([
          Permission.View_Legacy_frmReceiving_Main,
          Permission.Modify_Legacy_frmReceiving_Main,
        ]),
      leftIcon: <MoveToInboxIcon />,
      route: '/receiving',
      isActive: isRouteActive('/receiving', location.pathname),
    },
  ];

  if (!settingsContext.settingsLoaded) {
    return <LoadingPage />;
  }

  const capitalizeAll = (words: string, delimiter = ' ') =>
    words
      .split(delimiter)
      .map((i) => capitalize(i))
      .join(delimiter);

  document.title =
    location.pathname === '/'
      ? 'TotalETO'
      : `TotalETO - ${capitalizeAll(location.pathname.replaceAll('/', ' '))}`;

  const _renderRoutes = (routesToRender: RouteDef[], root: boolean) =>
    routesToRender.map((r) => {
      const rightMenu = r.helpId
        ? [
            ...navMenuItems,
            {
              component: (
                <NavHelpLink title={r.displayName ?? ''} helpId={r.helpId} />
              ),
              priority: 1,
            },
          ]
        : navMenuItems;
      return (
        <Route
          path={root ? `${URL}${r.route}` : r.route}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          index={r.index as any}
          key={document.location.href}
          element={
            r.component ? (
              <PageWrapper
                pageTitle={r.displayName ?? ''}
                rightMenuItems={rightMenu}
                navBarRef={anchorRef}
              >
                {!authContext.disableClockIn &&
                  authContext.hasEnterpriseLicense() &&
                  authContext.hasAnyPermission([
                    Permission.Add_MyTimeTracker_Punchcards,
                    Permission.Add_MyTimeTracker_Timecards,
                  ]) && (
                    <ClockInModalNotification
                      open={isClockInNotificationVisible}
                      onYes={() => {
                        clockInContext.startMyClockIn();
                        setIsClockInNotificationVisible(false);
                      }}
                      onNo={() => {
                        setIsClockInNotificationVisible(false);
                      }}
                      anchorEl={anchorRef.current}
                    />
                  )}
                {r.component}
              </PageWrapper>
            ) : undefined
          }
        >
          {r.routes && _renderRoutes(r.routes, false)}
        </Route>
      );
    });

  return (
    <>
      <SidebarMenu
        app={t('appName')}
        groupedMenus={[firstMenuGroup]}
        footer={{
          title: t('generic.settings'),
          leftIcon: <Settings />,
          route: '/settings',
          isActive: isRouteActive('/settings', location.pathname),
        }}
        helpCenter
        onBrandingClicked={() => navigate(`${URL}/`)}
        onRouteSelected={_selectedRoute}
      />
      <React.Suspense fallback={<LoadingPage />}>
        <Routes>{_renderRoutes(routes, true)}</Routes>
      </React.Suspense>
      <TimecardMigrationDialog />
      <TimerInspectors />
    </>
  );
};

export default AuthenticatedRoutes;
