/* eslint-disable @typescript-eslint/no-explicit-any */
import Check from '@mui/icons-material/Check';
import Close from '@mui/icons-material/Close';
import { Popover, useTheme } from '@mui/material';
import { ETOIconButton } from '@teto/react-component-library';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dependents,
  ForeignKeyOperation,
  SelectorParameters,
} from '../editorTypes';
import DependantEditor from './DependentEditor';

export interface ETODependencyEditorProps {
  dependents?: Dependents;
  popoverProps: {
    anchorEl?: HTMLInputElement;
    width: number;
  };
  dependentSelector: // eslint-disable-next-line no-unused-vars
  ((name: string, data: any) => void | SelectorParameters) | undefined;
  // eslint-disable-next-line no-unused-vars
  onAccept: () => void;
  onReject: () => void;
  disabled: boolean;
  handleForeignKeyChange?: (
    // eslint-disable-next-line no-unused-vars
    e: { value: string; name: string },
    // eslint-disable-next-line no-unused-vars
    data: { [key: string]: any }
  ) => { [key: string]: any };
  // eslint-disable-next-line no-unused-vars
  processForeignKey?: (cName: string, operation: ForeignKeyOperation) => any;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const rootStyles = (props: { [key: string]: number | string | any }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  width: `${props.style.width}`,
  height: 'fit-content',
  position: 'absolute',
  top: `${props.theme.spacing(6)}px`,
  rowGap: `${props.theme.spacing(2)}px`,
  zIndex: 150,
  paddingLeft: props.theme.spacing(1),
  paddingRight: props.theme.spacing(1),
  paddingTop: props.theme.spacing(2),
  paddingBottom: props.theme.spacing(2),
});

const ETODependencyEditor: React.FC<ETODependencyEditorProps> = (props) => {
  const {
    dependents,
    dependentSelector,
    disabled,
    onAccept,
    onReject,
    handleForeignKeyChange,
    processForeignKey,
    popoverProps,
  } = props;
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const { t } = useTranslation();
  const theme = useTheme();
  const rootSx = rootStyles({
    style: {
      width: `${popoverProps.width}px`,
    },
    theme,
  });

  const fullDependentList = useMemo(
    () =>
      dependents
        ? [
            ...(dependents?.required as string[]),
            ...(dependents?.optional as string[]),
          ]
        : [],
    [dependents]
  );
  const [updatedDependent, setUpdatedDependent] = useState<{
    [key: string]: any;
  }>({});
  const isDisabled =
    updatedDependent &&
    Object.keys(updatedDependent).length <
      (dependents?.required as string[]).length;

  const _handleChange = useCallback(
    (value: { [key: string]: string }) => {
      const dependentList = { ...updatedDependent, [value.name]: value.value };

      setUpdatedDependent((d) => ({
        ...d,
        [value.name]: value.value,
      }));

      if (
        dependentList &&
        Object.keys(dependentList).length === fullDependentList.length &&
        Object.entries(dependentList).filter((e) => e[1] !== 'empty').length ===
          0
      ) {
        setIsOpen(false);
        onAccept();
      }
    },
    [fullDependentList, onAccept, updatedDependent]
  );

  const dependentElements = useMemo(
    () =>
      fullDependentList.map((i) => (
        <DependantEditor
          key={i}
          dependentSelector={dependentSelector}
          handleForeignKeyChange={handleForeignKeyChange}
          name={i}
          disabled={disabled}
          handleChange={_handleChange}
          processForeignKey={processForeignKey}
        />
      )),
    [
      _handleChange,
      dependentSelector,
      disabled,
      fullDependentList,
      handleForeignKeyChange,
      processForeignKey,
    ]
  );

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {!disabled && isOpen && (
        <Popover
          open={isOpen}
          anchorEl={popoverProps.anchorEl}
          onClose={onReject}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          PaperProps={{
            sx: rootSx,
          }}
        >
          {dependentElements}

          <div>
            <ETOIconButton
              color="success"
              size="medium"
              disabled={isDisabled}
              onClick={() => onAccept()}
              tooltipProps={{
                title: t('generic.saveChange'),
                placement: 'bottom',
                leaveTouchDelay: 0,
              }}
              buttonProps={{
                onKeyDown: (e) => {
                  if (e.key === 'Enter') {
                    e.stopPropagation();
                    onAccept();
                  }
                },
              }}
            >
              <Check />
            </ETOIconButton>
            <ETOIconButton
              color="error"
              size="medium"
              onClick={() => onReject()}
              tooltipProps={{
                title: t('generic.discardChange'),
                placement: 'bottom',
                leaveTouchDelay: 0,
              }}
              buttonProps={{
                onKeyDown: (e) => {
                  if (e.key === 'Enter') {
                    e.stopPropagation();
                    onReject();
                  }
                },
              }}
            >
              <Close />
            </ETOIconButton>
          </div>
        </Popover>
      )}
    </>
  );
};

export default ETODependencyEditor;
