import FilterListIcon from '@mui/icons-material/FilterList';
import { Grid, List, Paper, SxProps, Theme, Typography } from '@mui/material';
import { AuthContext, MessageContext } from '@teto/react-component-library';
import { LatLngExpression, Map } from 'leaflet';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { QueryFunctionContext, QueryKey, useQuery } from 'react-query';
import {
  cancelPunchIn,
  getModelMetadata,
  ModelMetadata,
  PagedResponse,
  PunchIn,
} from 'teto-client-api';
import useLocalStorage from 'use-local-storage';
import MapWrapper from '../../../components/MapWrapper/MapWrapper';
import GridConfigurationHeader from '../../../components/TetoGrid/GridConfigurationHeader';
import GridSettings from '../../../components/TetoGrid/GridSettings';
import { createColumnsFromMetaData } from '../../../components/TetoGrid/ModelMetaDataProcessor/ModelMetaDataProcessor';
import TableColumnDefinition from '../../../components/TetoGrid/TableColumnDefinition';
import TimerContext from '../../../components/TimeSheets/NavTimer/PunchInTimerContext';
import StopTimeInspector from '../../../components/TimeSheets/TimerInspectors/StopTimeInspector/StopTimeInspector';
import { Setter } from '../../../helpers/setterGeneric';
import { buildMapFilterQuery } from '../components/Filtering/mapFilteringHelpers';
import MapFilters from '../components/Filtering/MapFilters';
import FilterInspector from '../components/FilterInspector';
import PunchInListItem from '../components/PunchInListItem';

interface ActiveTabProps<T> {
  doQuery: (
    // eslint-disable-next-line no-unused-vars
    context: QueryFunctionContext<QueryKey, unknown>,
    // eslint-disable-next-line no-unused-vars
    pageSize: number,
    // eslint-disable-next-line no-unused-vars
    queryData: { filters: unknown; orderBy: unknown }
  ) => Promise<PagedResponse<T>>;
  useApiFallback: boolean;
  setUseApiFallback: Setter<boolean>;
}

const gridContainer: SxProps<Theme> = (theme: Theme) => ({
  width: '100%',
  height: 'calc(100% - 3.5em)',
  '& .MuiGrid-item': {
    [theme.breakpoints.up('md')]: {
      padding: 0,
    },
  },
  '& .MuiGrid-item:first-of-type': {
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(1),
    },
    [theme.breakpoints.up('md')]: {
      paddingRight: theme.spacing(1),
    },
  },
  '& .MuiGrid-item:last-child': {
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(1),
    },
  },
});

const mapGrid: SxProps<Theme> = (theme: Theme) => ({
  height: '33vh',
  width: '100%',
  overflow: 'hidden',
  [theme.breakpoints.up('md')]: {
    height: '100%',
  },
});

const mapGridPaper: SxProps<Theme> = (theme: Theme) => ({
  width: '100%',
  height: '33vh',
  [theme.breakpoints.up('md')]: {
    height: '100%',
  },
});

const listGrid: SxProps<Theme> = (theme: Theme) => ({
  width: '100%',
  height: 'calc(100% - 33vh)',
  [theme.breakpoints.only('sm')]: {
    height: 'calc(100% - 34vh)',
  },
  [theme.breakpoints.up('md')]: {
    height: '100%',
  },
});

const list: SxProps<Theme> = (theme: Theme) => ({
  overflowY: 'auto',
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: `${theme.shape.borderRadius}px`,
  width: '100%',
  height: '100%',
  padding: 0,
  backgroundColor: theme.palette.background.paper,
  '& .MuiListItem-button:hover': {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.getContrastText(theme.palette.primary.main),
  },
  '& .MuiButtonBase-root-MuiListItem-root.Mui-selected': {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.getContrastText(theme.palette.primary.main),
  },
});

const paper = {
  margin: 'auto',
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 0,
};
const ActiveTab = <T extends PunchIn>(props: ActiveTabProps<T>) => {
  const { t, ready } = useTranslation();

  const { doQuery, useApiFallback, setUseApiFallback } = props;

  const authContext = useContext(AuthContext);
  const messageContext = useContext(MessageContext);
  const timerContext = useContext(TimerContext);

  const [data, setData] = useState<PunchIn[] | undefined>();
  const [filters, setFilters] = useLocalStorage<MapFilters | undefined>(
    'activeTab-filters',
    {}
  );
  const [filterCount, setFilterCount] = useState<number>(0);
  const [editingPunchIn, setEditingPunchIn] = useState<number | undefined>();
  const [selectedPunchIn, setSelectedPunchIn] = useState<number>(-1);
  const [filterInspector, setFilterInspector] = useState(false);
  const [map, setMap] = useState<Map>();
  const [metaData, setMetaData] = useState<ModelMetadata | undefined>();
  const [refreshToken, setRefreshToken] = useState<Date>(new Date());

  const _handleFilterInspectorOpen = () => {
    setFilterInspector(true);
  };

  const _handleFilterInspectorClose = () => {
    setFilterInspector(false);
  };

  const _onCancelTimer = (id: number) => {
    cancelPunchIn(id)
      .then(() => {
        setEditingPunchIn(undefined);
        setRefreshToken(new Date());
        messageContext.setSuccess(t('Entities.PunchIn.abortTimerSuccess'));
      })
      .catch(() => {
        messageContext.setError(t('Entities.PunchIn.abortTimerError'));
      });
  };

  useEffect(() => {
    if (authContext.hasEnterpriseLicense()) {
      getModelMetadata('punchin')
        .then((e) => setMetaData(e))
        .catch((e) => {
          messageContext.setError(
            `${t('generic.message.failMeta')}.  ${e.message}`
          );
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authContext, t]);

  const fields = useMemo(() => {
    if (metaData && ready) {
      return createColumnsFromMetaData<PunchIn>(metaData)
        .updateDefinition('comments', {
          hidden: true,
        })
        .updateDefinition('custom5', {
          hidden: true,
        })
        .updateDefinition('custom6', {
          hidden: true,
        })
        .updateDefinition('custom7', {
          hidden: true,
        })
        .updateDefinition('processScheduleDetailName', {
          hidden: true,
        })
        .updateDefinition('processScheduleDetailId', {
          hidden: true,
        })
        .updateDefinition('nonConformanceName', {
          hidden: true,
        })
        .updateDefinition('completedReason', {
          hidden: true,
        })
        .updateDefinition('employeeFullName', {
          hidden: true,
        })
        .removeProperty('active')
        .removeProperty('id')
        .removeProperty('startTime')
        .removeProperty('endTime')
        .removeProperty('elapsedMinutes')
        .removeProperty(
          'custom1',
          () => t('Entities.Timecard.TimecardCustom1') === 'Timecard Custom 1'
        )
        .removeProperty(
          'custom2',
          () => t('Entities.Timecard.TimecardCustom2') === 'Timecard Custom 2'
        )
        .removeProperty(
          'custom3',
          () => t('Entities.Timecard.TimecardCustom3') === 'Timecard Custom 3'
        )
        .removeProperty(
          'custom4',
          () => t('Entities.Timecard.TimecardCustom4') === 'Timecard Custom 4'
        )
        .removeProperty(
          'custom5',
          () => t('Entities.Timecard.TimecardCustom5') === 'Timecard Custom 5'
        )
        .removeProperty(
          'custom6',
          () => t('Entities.Timecard.TimecardCustom6') === 'Timecard Custom 6'
        )
        .removeProperty(
          'custom7',
          () => t('Entities.Timecard.TimecardCustom7') === 'Timecard Custom 7'
        )
        .removeProperty(
          'custom8',
          () => t('Entities.Timecard.TimecardCustom8') === 'Timecard Custom 8'
        )
        .finalize(t);
    }
    return [];
  }, [metaData, ready, t]);

  const [metaFields, setMetaFields] = useLocalStorage<
    TableColumnDefinition[] | undefined
  >('active-fields', []);

  const _onStop = (id: number) => {
    setEditingPunchIn(id);
  };

  const _setFilters = useCallback(
    (newFilters: MapFilters | undefined) => {
      setFilters(newFilters);
    },
    [setFilters]
  );

  const query = useQuery<PagedResponse<PunchIn>>(
    [filters, refreshToken, timerContext.refreshToken],
    (fc) =>
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      doQuery(fc as any, 100, {
        filters: { active: true, ...buildMapFilterQuery(filters) },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as any).then((qr) => {
        if (qr.maxExceeded) {
          messageContext.setWarning(
            `Query Row Limit Hit: Query returned ${qr.records.length} records of ${qr.totalRecords}`
          );
        }

        return qr;
      }),
    {
      enabled: Boolean(metaData),
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );

  const handleSetView = (
    coords: number[] | undefined,
    zoom: number | undefined
  ) => {
    if (map) {
      // Not good
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const { target } = map as any;
      if (!coords || coords.some((coord) => !coord)) {
        target.setView([0, 0], 2);
        if (selectedPunchIn === -1) {
          messageContext.setError(t('Entities.PunchIn.undefinedPunchInCoords'));
        }
      } else {
        target.setView(coords as LatLngExpression, zoom);
      }
    }
  };

  const _handleEntryClicked = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    coords: number[] | undefined,
    punchIn: PunchIn
  ) => {
    const { id } = punchIn;
    if (selectedPunchIn.toString() === id.toString()) {
      setSelectedPunchIn(-1);
      setMetaFields(fields);
      handleSetView([0, 0], 2);
    } else {
      setSelectedPunchIn(id);
      handleSetView(coords, 13);
    }
  };

  useEffect(() => {
    if (query.data) {
      setData(query.data.records ?? []);
    }
  }, [query]);

  const _onSave = (settings: GridSettings) => {
    setMetaFields((items) =>
      items?.map((item) => {
        const obj = { ...item };
        obj.hidden = settings.hidden.indexOf(item.name) > -1;
        return obj;
      })
    );
    messageContext.setSuccess(t('generic.message.settingsUpdate'));
  };

  useEffect(() => {
    if (filters) {
      setFilterCount(Object.values(filters).filter((filter) => filter).length);
    }
  }, [filters]);

  return (
    <>
      {ready && fields && metaData && metaFields && (
        <>
          <GridConfigurationHeader
            withBottomNav
            disableConfigureButton
            disableResetButton
            columns={metaFields}
            columnOrder={[]}
            rightChildren={[
              {
                title: t('Entities.PunchIn.filter'),
                icon: <FilterListIcon />,
                onclick: () => _handleFilterInspectorOpen(),
                count: filterCount,
              },
            ]}
            onReset={() => setMetaFields(fields)}
            onSettingsUpdate={(e) => _onSave(e)}
          />

          <Grid container sx={gridContainer}>
            <Grid item xs={12} md={7} lg={8} sx={mapGrid}>
              <Paper variant="outlined" sx={mapGridPaper}>
                {query.isSuccess && data && (
                  <MapWrapper
                    setMap={setMap}
                    data={data}
                    map={map}
                    setSelectedPunchIn={setSelectedPunchIn}
                  />
                )}
              </Paper>
            </Grid>

            <Grid item xs={12} md={5} lg={4} sx={listGrid}>
              {data && data?.length < 1 && (
                <Paper variant="outlined" sx={paper}>
                  <Typography variant="overline" display="block" align="center">
                    {t('Entities.PunchIn.noRecords')}
                  </Typography>
                </Paper>
              )}
              <List sx={list}>
                {data &&
                  data?.length >= 1 &&
                  data?.map((r: PunchIn, i: number) => (
                    <PunchInListItem
                      key={r?.id}
                      onStop={_onStop}
                      onCancel={_onCancelTimer}
                      data={r}
                      handleClick={_handleEntryClicked}
                      isSelected={selectedPunchIn === r?.id}
                      isActive
                      entryNum={i}
                      fields={metaFields}
                      useApiFallback={useApiFallback}
                      setUseApiFallback={setUseApiFallback}
                    />
                  ))}
              </List>
            </Grid>
          </Grid>
        </>
      )}

      {filterInspector && (
        <FilterInspector
          filters={filters}
          setFilters={_setFilters}
          open={filterInspector}
          handleClose={_handleFilterInspectorClose}
        />
      )}

      {editingPunchIn && authContext.hasEnterpriseLicense() && (
        <StopTimeInspector
          id={editingPunchIn}
          onClose={() => setEditingPunchIn(undefined)}
          onUpdate={() => {
            setEditingPunchIn(undefined);
            setRefreshToken(new Date());
            messageContext.setSuccess(t('Entities.PunchIn.stopTimerSuccess'));
          }}
          canUseTimer
          onCancel={() => _onCancelTimer(editingPunchIn)}
        />
      )}
    </>
  );
};

export default ActiveTab;
