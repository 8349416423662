import ReactDataGrid from '@inovua/reactdatagrid-enterprise';
import { Box, darken, SxProps, Theme, useTheme } from '@mui/material';
import {
  AppearanceContext,
  AuthContext,
  defaultDateRange,
  formatHoursAsHourMin,
  MessageContext,
  SettingsContext,
  WeeklyDateSelector,
} from '@teto/react-component-library';
import { v4 as uuid } from 'uuid';
import dayjs from 'dayjs';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { Permission } from 'teto-client-api';
import { TypeColumn } from '@inovua/reactdatagrid-enterprise/types';
import ExpandLessRounded from '@mui/icons-material/ExpandLessRounded';
import ExpandMoreRounded from '@mui/icons-material/ExpandMoreRounded';
import { buildCellFormatter } from '../../../components/TetoGrid/Formatters/cellFormatterHelper';
import { reactDataGridLicenseKey } from '../../../components/TetoGrid/Licensing';
import {
  AddColumn,
  createColumns,
} from '../../../components/TetoGrid/ModelMetaDataProcessor/ModelMetaDataProcessor';
import useGridStyles from '../../../components/TetoGrid/GridStyles';
import { getGraphQLClient } from '../../../helpers/graphQL/graphQLClient';
import ClockIn from '../../ClockInsPage/ClockIn';
import {
  getClockInColumnName,
  getClockOutColumnName,
  getTotalColumnName,
} from '../../ClockInsPage/helpers/getClockColProps';
import getMyClockInsByDayQuery from './queries/getMyClockInsByDayQuery';
import TimeTrackerRowDetails from './components/TimeTrackerRowDetails';

const groupHeaderSx = (theme: Theme) => ({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  backgroundColor: darken(theme.palette.primary.main, 0.2),
  color: theme.palette.getContrastText(darken(theme.palette.primary.main, 0.2)),
});

const tableWrapSx = (theme: Theme) => ({
  height: '100%',
  minHeight: theme.spacing(36),
  marginBottom: theme.spacing(2),
  '& .InovuaReactDataGrid__column-header__filter-wrapper': {
    borderTop: 'none',
    padding: `${theme.spacing(0)} !important`,
  },
});

const wrapperSx = (theme: Theme) => ({
  width: 'fit-content',
  '& .MuiSvgIcon-root': {
    fontSize: theme.spacing(3.5),
  },
  '& .MuiTextField-root': {
    width: theme.spacing(24),
  },
});

const toolbarTileSx = {
  '& h4.MuiTypography-root.MuiTypography-h4.MuiTypography-alignCenter.MuiDatePickerToolbar-title.css-3jvy96-MuiTypography-root-MuiDatePickerToolbar-title':
    {
      fontSize: '1.25rem',
    },
};

const pageName = 'clockIns';

const ClockInsTab = () => {
  const { t, ready } = useTranslation();

  const navigate = useNavigate();
  const { startDate, endDate } = useParams();

  const authContext = useContext(AuthContext);
  const messageContext = useContext(MessageContext);
  const settingsContext = useContext(SettingsContext);
  const appearanceContext = useContext(AppearanceContext);

  const theme = useTheme();
  const scrollStyle = {
    scrollThumbStyle: { backgroundColor: theme.palette.primary.main },
  };

  const currentTheme =
    appearanceContext.themeVariant === 'dark'
      ? 'default-dark'
      : 'default-light';

  const [dataSource, setDataSource] = useState([]);
  const [expandedRows, setExpandedRows] = useState<{ [key: number]: boolean }>(
    {}
  );
  const [collapseRows, setCollapsedRows] = useState<{ [key: number]: boolean }>(
    {}
  );

  const canAccessClockIns =
    authContext.hasEnterpriseLicense() &&
    authContext.hasAnyPermission([
      Permission.View_MyTimeTracker_Timecards,
      Permission.Modify_MyTimeTracker_Timecards,
    ]);

  useEffect(() => {
    if (!canAccessClockIns) navigate('/access-denied', { replace: true });
    if (!startDate || !endDate) {
      const defaultRange = defaultDateRange(
        settingsContext.settings.startDayOfWeek
      );
      navigate(
        `/timeTracking/myTimeTracker/${pageName}/${defaultRange.start.format(
          'YYYY-MM-DD'
        )}/${defaultRange.end.format('YYYY-MM-DD')}`,
        { replace: true }
      );
    }
  }, [
    authContext,
    canAccessClockIns,
    endDate,
    navigate,
    settingsContext.settings.startDayOfWeek,
    startDate,
  ]);

  const { startDayOfWeek } = settingsContext.settings;

  const getGroupTitle = useCallback(
    (index: number) =>
      `${t(
        `Entities.TimeSheet.${
          index + startDayOfWeek > 6
            ? startDayOfWeek + index - 7
            : index + startDayOfWeek
        }`
      )} ${dayjs(startDate).add(index, 'day').format('D')}`,
    [startDate, startDayOfWeek, t]
  );

  const daysOfWeekGroups = useMemo(
    () => [
      {
        name: 'sunday',
        header: <Box sx={groupHeaderSx}>{getGroupTitle(0)}</Box>,
      },
      {
        name: 'monday',
        header: <Box sx={groupHeaderSx}>{getGroupTitle(1)}</Box>,
      },
      {
        name: 'tuesday',
        header: <Box sx={groupHeaderSx}>{getGroupTitle(2)}</Box>,
      },
      {
        name: 'wednesday',
        header: <Box sx={groupHeaderSx}>{getGroupTitle(3)}</Box>,
      },
      {
        name: 'thursday',
        header: <Box sx={groupHeaderSx}>{getGroupTitle(4)}</Box>,
      },
      {
        name: 'friday',
        header: <Box sx={groupHeaderSx}>{getGroupTitle(5)}</Box>,
      },
      {
        name: 'saturday',
        header: <Box sx={groupHeaderSx}>{getGroupTitle(6)}</Box>,
      },
    ],
    [getGroupTitle]
  );

  const _buildClockInColumn = useCallback(
    (index: number): AddColumn => ({
      name: getClockInColumnName(index).name,
      title: t('Entities.ClockIns.clockIn'),
      type: 'custom',
      align: 'center',
      sortable: false,
      filterType: '',
      filterOptions: undefined,
      fixed: 'none',
      disableGrouping: true,
      disableColumnFilterContextMenu: true,
      disableColumnMenuTool: true,
      defaultFlex: 1,
      minWidth: 100,
      group: getClockInColumnName(index).group,
      emptyDisplay: '-',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      renderFunction: (data: any) => {
        const { name } = getClockInColumnName(index);
        if (data[index][name]) {
          return (
            <span>
              {dayjs(data[index][name]).format(
                settingsContext.settings.timeFormat
              )}
            </span>
          );
        }
        return <span>-</span>;
      },
    }),
    [settingsContext.settings.timeFormat, t]
  );

  const _buildClockOutColumn = useCallback(
    (index: number): AddColumn => ({
      name: getClockOutColumnName(index).name,
      title: t('Entities.ClockIns.clockOut'),
      type: 'custom',
      align: 'center',
      sortable: false,
      filterType: '',
      filterOptions: undefined,
      fixed: 'none',
      disableGrouping: true,
      disableColumnFilterContextMenu: true,
      disableColumnMenuTool: true,
      defaultFlex: 1,
      minWidth: 100,
      group: getClockOutColumnName(index).group,
      emptyDisplay: '-',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      renderFunction: (data: any) => {
        const { name } = getClockOutColumnName(index);
        if (data[index][name]) {
          return (
            <span>
              {dayjs(data[index][name]).format(
                settingsContext.settings.timeFormat
              )}
            </span>
          );
        }
        return <span>-</span>;
      },
    }),
    [settingsContext.settings.timeFormat, t]
  );

  const _buildTotalHoursColumn = useCallback(
    (index: number): AddColumn => ({
      name: getTotalColumnName(index).name,
      title: t('generic.total'),
      type: 'custom',
      align: 'center',
      sortable: false,
      filterType: '',
      filterOptions: undefined,
      fixed: 'none',
      disableGrouping: true,
      disableColumnFilterContextMenu: true,
      disableColumnMenuTool: true,
      defaultFlex: 1,
      minWidth: 65,
      group: getTotalColumnName(index).group,
      emptyDisplay: '-',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      renderFunction: (data: any) => {
        const { name } = getTotalColumnName(index);
        if (data[index][name]) {
          return <span>{formatHoursAsHourMin(data[index][name] / 60)}</span>;
        }
        return <span>-</span>;
      },
    }),
    [t]
  );

  const cols = useMemo(() => {
    if (ready) {
      return createColumns<ClockIn>()
        .addColumn(_buildClockInColumn(0))
        .addColumn(_buildClockOutColumn(0))
        .addColumn(_buildTotalHoursColumn(0))
        .addColumn(_buildClockInColumn(1))
        .addColumn(_buildClockOutColumn(1))
        .addColumn(_buildTotalHoursColumn(1))
        .addColumn(_buildClockInColumn(2))
        .addColumn(_buildClockOutColumn(2))
        .addColumn(_buildTotalHoursColumn(2))
        .addColumn(_buildClockInColumn(3))
        .addColumn(_buildClockOutColumn(3))
        .addColumn(_buildTotalHoursColumn(3))
        .addColumn(_buildClockInColumn(4))
        .addColumn(_buildClockOutColumn(4))
        .addColumn(_buildTotalHoursColumn(4))
        .addColumn(_buildClockInColumn(5))
        .addColumn(_buildClockOutColumn(5))
        .addColumn(_buildTotalHoursColumn(5))
        .addColumn(_buildClockInColumn(6))
        .addColumn(_buildClockOutColumn(6))
        .addColumn(_buildTotalHoursColumn(6))
        .finalize(t);
    }
    return [];
  }, [
    ready,
    _buildClockInColumn,
    _buildClockOutColumn,
    _buildTotalHoursColumn,
    t,
  ]);

  const convertedToRDGColumns = useMemo<TypeColumn[]>(
    () =>
      cols.map((c) => ({
        name: c.name,
        type: c.type,
        header: c.title,
        hideable: !c.disableHideable,
        showInContextMenu: false,
        hidden: c.hidden,
        align: c.align,
        groupByName: c.title,
        group: c.group,
        draggable: !c.disableGrouping,
        id: c.name,
        width: c.width,
        defaultWidth: c.defaultWidth,
        minWidth: c.minWidth,
        flex: c.flex,
        defaultFlex: c.defaultFlex,
        textAlign: c.align,
        headerProps: c.headerProps,
        filterDelay: 500,
        sortable: c.sortable,
        visible: !c.hidden,
        enableColumnFilterContextMenu: !c.disableColumnFilterContextMenu,
        showColumnMenuTool: false,
        groupBy: !c.disableGrouping,
        render: buildCellFormatter(c),
        filterType: c.filterType,
        filterable: true,
      })),
    [cols]
  );

  const query = useQuery(
    [startDate, endDate],
    () =>
      getGraphQLClient()
        .performQuery(
          getMyClockInsByDayQuery,
          {
            startDate,
            endDate,
          },
          (err) => {
            messageContext.setError(err.messages[0]);
          },
          () => {
            // @TODO Handle Validation
          }
        )
        .then((dr) => {
          const { items } = dr.myClockInsByDay;

          const itemsMapped = Object.values(
            items.reduce(
              (
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                c: { [key: string]: any },
                e: { employee: { name: string | number } }
              ) => {
                // eslint-disable-next-line no-param-reassign
                if (!c[e.employee.name]) c[e.employee.name] = e;
                return c;
              },
              {}
            )
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ).map((h: any) => ({
            employee: h.employee,
            id: uuid(),
            0: {
              date: dayjs(startDate),
              sundayClockIn:
                items.filter(
                  (item: ClockIn) =>
                    dayjs(
                      item.date.substring(0, item.date.lastIndexOf('-'))
                    ).isSame(dayjs(startDate)) &&
                    item.employee.id === h.employee.id
                ).length > 0
                  ? items.filter(
                      (item: ClockIn) =>
                        dayjs(
                          item.date.substring(0, item.date.lastIndexOf('-'))
                        ).isSame(dayjs(startDate)) &&
                        item.employee.id === h.employee.id
                    )[0].startTime
                  : undefined,
              sundayClockOut:
                items.filter(
                  (item: ClockIn) =>
                    dayjs(
                      item.date.substring(0, item.date.lastIndexOf('-'))
                    ).isSame(dayjs(startDate)) &&
                    item.employee.id === h.employee.id
                ).length > 0
                  ? items.filter(
                      (item: ClockIn) =>
                        dayjs(
                          item.date.substring(0, item.date.lastIndexOf('-'))
                        ).isSame(dayjs(startDate)) &&
                        item.employee.id === h.employee.id
                    )[0].endTime
                  : undefined,
              sundayTotal:
                items.filter(
                  (item: ClockIn) =>
                    dayjs(
                      item.date.substring(0, item.date.lastIndexOf('-'))
                    ).isSame(dayjs(startDate)) &&
                    item.employee.id === h.employee.id
                ).length > 0
                  ? items.filter(
                      (item: ClockIn) =>
                        dayjs(
                          item.date.substring(0, item.date.lastIndexOf('-'))
                        ).isSame(dayjs(startDate)) &&
                        item.employee.id === h.employee.id
                    )[0].workingDuration
                  : undefined,
            },
            1: {
              date: dayjs(startDate).add(1, 'day'),
              mondayClockIn:
                items.filter(
                  (item: ClockIn) =>
                    dayjs(
                      item.date.substring(0, item.date.lastIndexOf('-'))
                    ).isSame(dayjs(startDate).add(1, 'day')) &&
                    item.employee.id === h.employee.id
                ).length > 0
                  ? items.filter(
                      (item: ClockIn) =>
                        dayjs(
                          item.date.substring(0, item.date.lastIndexOf('-'))
                        ).isSame(dayjs(startDate).add(1, 'day')) &&
                        item.employee.id === h.employee.id
                    )[0].startTime
                  : undefined,
              mondayClockOut:
                items.filter(
                  (item: ClockIn) =>
                    dayjs(
                      item.date.substring(0, item.date.lastIndexOf('-'))
                    ).isSame(dayjs(startDate).add(1, 'day')) &&
                    item.employee.id === h.employee.id
                ).length > 0
                  ? items.filter(
                      (item: ClockIn) =>
                        dayjs(
                          item.date.substring(0, item.date.lastIndexOf('-'))
                        ).isSame(dayjs(startDate).add(1, 'day')) &&
                        item.employee.id === h.employee.id
                    )[0].endTime
                  : undefined,
              mondayTotal:
                items.filter(
                  (item: ClockIn) =>
                    dayjs(
                      item.date.substring(0, item.date.lastIndexOf('-'))
                    ).isSame(dayjs(startDate).add(1, 'day')) &&
                    item.employee.id === h.employee.id
                ).length > 0
                  ? items.filter(
                      (item: ClockIn) =>
                        dayjs(
                          item.date.substring(0, item.date.lastIndexOf('-'))
                        ).isSame(dayjs(startDate).add(1, 'day')) &&
                        item.employee.id === h.employee.id
                    )[0].workingDuration
                  : undefined,
            },
            2: {
              date: dayjs(startDate).add(2, 'day'),
              tuesdayClockIn:
                items.filter(
                  (item: ClockIn) =>
                    dayjs(
                      item.date.substring(0, item.date.lastIndexOf('-'))
                    ).isSame(dayjs(startDate).add(2, 'day')) &&
                    item.employee.id === h.employee.id
                ).length > 0
                  ? items.filter(
                      (item: ClockIn) =>
                        dayjs(
                          item.date.substring(0, item.date.lastIndexOf('-'))
                        ).isSame(dayjs(startDate).add(2, 'day')) &&
                        item.employee.id === h.employee.id
                    )[0].startTime
                  : undefined,
              tuesdayClockOut:
                items.filter(
                  (item: ClockIn) =>
                    dayjs(
                      item.date.substring(0, item.date.lastIndexOf('-'))
                    ).isSame(dayjs(startDate).add(2, 'day')) &&
                    item.employee.id === h.employee.id
                ).length > 0
                  ? items.filter(
                      (item: ClockIn) =>
                        dayjs(
                          item.date.substring(0, item.date.lastIndexOf('-'))
                        ).isSame(dayjs(startDate).add(2, 'day')) &&
                        item.employee.id === h.employee.id
                    )[0].endTime
                  : undefined,
              tuesdayTotal:
                items.filter(
                  (item: ClockIn) =>
                    dayjs(
                      item.date.substring(0, item.date.lastIndexOf('-'))
                    ).isSame(dayjs(startDate).add(2, 'day')) &&
                    item.employee.id === h.employee.id
                ).length > 0
                  ? items.filter(
                      (item: ClockIn) =>
                        dayjs(
                          item.date.substring(0, item.date.lastIndexOf('-'))
                        ).isSame(dayjs(startDate).add(2, 'day')) &&
                        item.employee.id === h.employee.id
                    )[0].workingDuration
                  : undefined,
            },
            3: {
              date: dayjs(startDate).add(3, 'day'),
              wednesdayClockIn:
                items.filter(
                  (item: ClockIn) =>
                    dayjs(
                      item.date.substring(0, item.date.lastIndexOf('-'))
                    ).isSame(dayjs(startDate).add(3, 'day')) &&
                    item.employee.id === h.employee.id
                ).length > 0
                  ? items.filter(
                      (item: ClockIn) =>
                        dayjs(
                          item.date.substring(0, item.date.lastIndexOf('-'))
                        ).isSame(dayjs(startDate).add(3, 'day')) &&
                        item.employee.id === h.employee.id
                    )[0].startTime
                  : undefined,
              wednesdayClockOut:
                items.filter(
                  (item: ClockIn) =>
                    dayjs(
                      item.date.substring(0, item.date.lastIndexOf('-'))
                    ).isSame(dayjs(startDate).add(3, 'day')) &&
                    item.employee.id === h.employee.id
                ).length > 0
                  ? items.filter(
                      (item: ClockIn) =>
                        dayjs(
                          item.date.substring(0, item.date.lastIndexOf('-'))
                        ).isSame(dayjs(startDate).add(3, 'day')) &&
                        item.employee.id === h.employee.id
                    )[0].endTime
                  : undefined,
              wednesdayTotal:
                items.filter(
                  (item: ClockIn) =>
                    dayjs(
                      item.date.substring(0, item.date.lastIndexOf('-'))
                    ).isSame(dayjs(startDate).add(3, 'day')) &&
                    item.employee.id === h.employee.id
                ).length > 0
                  ? items.filter(
                      (item: ClockIn) =>
                        dayjs(
                          item.date.substring(0, item.date.lastIndexOf('-'))
                        ).isSame(dayjs(startDate).add(3, 'day')) &&
                        item.employee.id === h.employee.id
                    )[0].workingDuration
                  : undefined,
            },
            4: {
              date: dayjs(startDate).add(4, 'day'),
              thursdayClockIn:
                items.filter(
                  (item: ClockIn) =>
                    dayjs(
                      item.date.substring(0, item.date.lastIndexOf('-'))
                    ).isSame(dayjs(startDate).add(4, 'day')) &&
                    item.employee.id === h.employee.id
                ).length > 0
                  ? items.filter(
                      (item: ClockIn) =>
                        dayjs(
                          item.date.substring(0, item.date.lastIndexOf('-'))
                        ).isSame(dayjs(startDate).add(4, 'day')) &&
                        item.employee.id === h.employee.id
                    )[0].startTime
                  : undefined,
              thursdayClockOut:
                items.filter(
                  (item: ClockIn) =>
                    dayjs(
                      item.date.substring(0, item.date.lastIndexOf('-'))
                    ).isSame(dayjs(startDate).add(4, 'day')) &&
                    item.employee.id === h.employee.id
                ).length > 0
                  ? items.filter(
                      (item: ClockIn) =>
                        dayjs(
                          item.date.substring(0, item.date.lastIndexOf('-'))
                        ).isSame(dayjs(startDate).add(4, 'day')) &&
                        item.employee.id === h.employee.id
                    )[0].endTime
                  : undefined,
              thursdayTotal:
                items.filter(
                  (item: ClockIn) =>
                    dayjs(
                      item.date.substring(0, item.date.lastIndexOf('-'))
                    ).isSame(dayjs(startDate).add(4, 'day')) &&
                    item.employee.id === h.employee.id
                ).length > 0
                  ? items.filter(
                      (item: ClockIn) =>
                        dayjs(
                          item.date.substring(0, item.date.lastIndexOf('-'))
                        ).isSame(dayjs(startDate).add(4, 'day')) &&
                        item.employee.id === h.employee.id
                    )[0].workingDuration
                  : undefined,
            },
            5: {
              date: dayjs(startDate).add(5, 'day'),
              fridayClockIn:
                items.filter(
                  (item: ClockIn) =>
                    dayjs(
                      item.date.substring(0, item.date.lastIndexOf('-'))
                    ).isSame(dayjs(startDate).add(5, 'day')) &&
                    item.employee.id === h.employee.id
                ).length > 0
                  ? items.filter(
                      (item: ClockIn) =>
                        dayjs(
                          item.date.substring(0, item.date.lastIndexOf('-'))
                        ).isSame(dayjs(startDate).add(5, 'day')) &&
                        item.employee.id === h.employee.id
                    )[0].startTime
                  : undefined,
              fridayClockOut:
                items.filter(
                  (item: ClockIn) =>
                    dayjs(
                      item.date.substring(0, item.date.lastIndexOf('-'))
                    ).isSame(dayjs(startDate).add(5, 'day')) &&
                    item.employee.id === h.employee.id
                ).length > 0
                  ? items.filter(
                      (item: ClockIn) =>
                        dayjs(
                          item.date.substring(0, item.date.lastIndexOf('-'))
                        ).isSame(dayjs(startDate).add(5, 'day')) &&
                        item.employee.id === h.employee.id
                    )[0].endTime
                  : undefined,
              fridayTotal:
                items.filter(
                  (item: ClockIn) =>
                    dayjs(
                      item.date.substring(0, item.date.lastIndexOf('-'))
                    ).isSame(dayjs(startDate).add(5, 'day')) &&
                    item.employee.id === h.employee.id
                ).length > 0
                  ? items.filter(
                      (item: ClockIn) =>
                        dayjs(
                          item.date.substring(0, item.date.lastIndexOf('-'))
                        ).isSame(dayjs(startDate).add(5, 'day')) &&
                        item.employee.id === h.employee.id
                    )[0].workingDuration
                  : undefined,
            },
            6: {
              date: dayjs(endDate),
              saturdayClockIn:
                items.filter(
                  (item: ClockIn) =>
                    dayjs(
                      item.date.substring(0, item.date.lastIndexOf('-'))
                    ).isSame(dayjs(endDate)) &&
                    item.employee.id === h.employee.id
                ).length > 0
                  ? items.filter(
                      (item: ClockIn) =>
                        dayjs(
                          item.date.substring(0, item.date.lastIndexOf('-'))
                        ).isSame(dayjs(endDate)) &&
                        item.employee.id === h.employee.id
                    )[0].startTime
                  : undefined,
              saturdayClockOut:
                items.filter(
                  (item: ClockIn) =>
                    dayjs(
                      item.date.substring(0, item.date.lastIndexOf('-'))
                    ).isSame(dayjs(endDate)) &&
                    item.employee.id === h.employee.id
                ).length > 0
                  ? items.filter(
                      (item: ClockIn) =>
                        dayjs(
                          item.date.substring(0, item.date.lastIndexOf('-'))
                        ).isSame(dayjs(endDate)) &&
                        item.employee.id === h.employee.id
                    )[0].endTime
                  : undefined,
              saturdayTotal:
                items.filter(
                  (item: ClockIn) =>
                    dayjs(
                      item.date.substring(0, item.date.lastIndexOf('-'))
                    ).isSame(dayjs(endDate)) &&
                    item.employee.id === h.employee.id
                ).length > 0
                  ? items.filter(
                      (item: ClockIn) =>
                        dayjs(
                          item.date.substring(0, item.date.lastIndexOf('-'))
                        ).isSame(dayjs(endDate)) &&
                        item.employee.id === h.employee.id
                    )[0].workingDuration
                  : undefined,
            },
          }));

          return { records: itemsMapped, rowData: items };
        }),
    {
      enabled: Boolean(startDate && endDate),
      onSuccess: (d) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setDataSource(d.records as unknown as any);
      },
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
    }
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const _handleRowExpandChange = useCallback((rowData: any) => {
    setExpandedRows(rowData.expandedRows);
    setCollapsedRows(rowData.collapsedRows);
    return true;
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const renderDetailsGrid = useCallback(
    () => <TimeTrackerRowDetails data={query.data?.rowData} />,
    [query.data?.rowData]
  );

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {startDate && endDate && (
        <Box
          sx={{
            height: '100%',
            display: 'grid',
            gridTemplateRows: `${theme.spacing(7)} 1fr`,
            [theme.breakpoints.down('md')]: {
              gridTemplateRows: `${theme.spacing(5)} 1fr`,
            },
          }}
        >
          <Box
            sx={{
              marginTop: theme.spacing(0.5),
              marginBottom: theme.spacing(1),
              width: '100%',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <WeeklyDateSelector
              data-testid="week-selector"
              onChange={(e) =>
                navigate(
                  `/timeTracking/myTimeTracker/${pageName}/${e.start.format(
                    'YYYY-MM-DD'
                  )}/${e.end.format('YYYY-MM-DD')}`
                )
              }
              weekStart={settingsContext.settings.startDayOfWeek}
              inputFormat={settingsContext.settings.dateFormat}
              value={startDate as unknown as Date}
              containerSx={wrapperSx as SxProps}
              datePickerProps={{
                DialogProps: {
                  sx: toolbarTileSx,
                },
              }}
            />
          </Box>
          <Box sx={[tableWrapSx, useGridStyles]}>
            <ReactDataGrid
              dataSource={dataSource}
              groups={daysOfWeekGroups}
              columns={convertedToRDGColumns}
              licenseKey={reactDataGridLicenseKey}
              filterRowHeight={0}
              loading={query.isFetching}
              scrollProps={scrollStyle}
              theme={currentTheme}
              emptyText={t('generic.message.noRecordsFound')}
              expandedRows={expandedRows}
              collapsedRows={collapseRows}
              onExpandedRowsChange={(e) => _handleRowExpandChange(e)}
              renderDetailsGrid={renderDetailsGrid}
              rowDetailsWidth="viewport-width"
              rowExpandHeight={265}
              renderRowDetailsCollapsedIcon={() => <ExpandMoreRounded />}
              renderRowDetailsExpandIcon={() => <ExpandLessRounded />}
            />
          </Box>
        </Box>
      )}
    </>
  );
};
export default ClockInsTab;
