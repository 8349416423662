import { AuthContext, MessageContext } from '@teto/react-component-library';
import { t } from 'i18next';
import React, { useContext } from 'react';
import { cancelMyPunchIn, Permission } from 'teto-client-api';
import StartTimeInspector from '../TimerInspectors/StartTimeInspector/StartTimeInspector';
import StopTimeInspector from '../TimerInspectors/StopTimeInspector/StopTimeInspector';
import PunchInTimerContext from './PunchInTimerContext';

const TimerInspectors = () => {
  const timerContext = useContext(PunchInTimerContext);
  const authContext = useContext(AuthContext);
  const messageContext = useContext(MessageContext);

  const _onStartTimer = () => {
    timerContext.refreshTimer();
    timerContext.setInspector('closed');
    // messageContext.setSuccess(t('Entities.PunchIn.startTimerSuccess'));
  };

  const _onStopTimer = () => {
    timerContext.refreshTimer();
    timerContext.setInspector('closed');
    // messageContext.setSuccess(t('Entities.PunchIn.stopTimerSuccess'));
  };

  const _onCancelTimer = (id: number) => {
    cancelMyPunchIn(id)
      .then(() => {
        timerContext.setTimerStatus('inactive');
        timerContext.refreshTimer();
        timerContext.setInspector('closed');
        messageContext.setSuccess(t('Entities.PunchIn.abortTimerSuccess'));
      })
      .catch(() => {
        messageContext.setError(t('Entities.PunchIn.abortTimerError'));
      });
  };

  return (
    <>
      {timerContext.inspector === 'startTimer' && (
        <StartTimeInspector
          canUseTimer={
            authContext.hasEnterpriseLicense() &&
            authContext.hasPermission(Permission.Add_MyTimeTracker_Punchcards)
          }
          open={timerContext.inspector === 'startTimer'}
          onClose={() => timerContext.setInspector('closed')}
          onUpdate={() => _onStartTimer()}
        />
      )}
      {timerContext.inspector === 'stopTimer' && (
        <StopTimeInspector
          limitToSelf
          canUseTimer={
            authContext.hasEnterpriseLicense() &&
            authContext.hasPermission(Permission.Add_MyTimeTracker_Punchcards)
          }
          id={timerContext.activeTimerId || 0}
          onClose={() => timerContext.setInspector('closed')}
          onUpdate={() => _onStopTimer()}
          onCancel={() => _onCancelTimer(timerContext.activeTimerId as number)}
        />
      )}
    </>
  );
};

export default TimerInspectors;
