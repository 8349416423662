const purchaseOrderHeaderQuery = `query purchaseOrderHeader($id: Int!) {
  purchaseOrderHeader(id: $id) {
          id
          pONotes {
            note
          }
          purchaseNotes
          purchaseNotes2
          purchaseNotes3
          buyer {
            name
          }
          purchaseSupplier {
            name
          }
          purchaseOrderDetails {
            id
            item {
              id
              category {
                itemCategory,
                categoryDescription
              }
            }
            itemId
            destInventoryLoc {
              name
            }
            inventoryDetails {
              inventory {
                inventoryLocationId,
                inventoryLocation {
                  location,
                  name
                }
              }
            }
      
            processSchedule {
              number
              status {
                processScheduleHeaders
                {
                  number
                }
              }
            }
            processScheduleId
            processScheduleDetailId
            projectId
            specId
            spec {
              id
              displayName,
              project {
                displayName
              }
            }
            purchaseSupplierItem
            purchaseSupplierDescription
            purchaseQty
            purchaseUom
            purchasePrice
            archived
            dateRequired
            dateRevised
            externalQty
            externalUOM {
              uomtype
            }
            nonConformanceId
            nonConformance {
              title           
            }
            orderNumber
            status {
              quantityReceived
            },
            processScheduleDetail {
              inHouse
              quantityReceived
              quantityIssued
              quantityOrdered
            }
            custom1
            custom2
            custom3
            custom4
            custom5
            custom6
            custom7
            custom8
          }
        }
    }
`;

export default purchaseOrderHeaderQuery;
