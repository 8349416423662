/* eslint-disable  @typescript-eslint/no-explicit-any, no-unused-vars, no-shadow, class-methods-use-this, no-empty */
// eslint-disable-next-line max-classes-per-file
import { fetch } from 'cross-fetch';
import { ModelMetadata } from 'teto-client-api';
import { getConfig } from './graphQLConfig';
import {
  IGraphQLClient,
  MutationError,
  MutationValidationError,
  QueryError,
  QueryValidationError,
} from './IGraphQLClient';

export default class FetchGraphQLClient implements IGraphQLClient {
  private _performQueryAsColumnMetadata = async <VariableTyping = any>(
    query: string,
    variables: VariableTyping | null,
    onError: (err: QueryError) => void,
    onValidationError: (err: QueryValidationError) => void
  ): Promise<ModelMetadata | undefined> => {
    if (!query.includes('__type')) throw new Error('Must be a type query.');

    const result = await this.performQuery(
      query,
      variables,
      onError,
      onValidationError
    );

    const formattedResult: ModelMetadata = {};

    const __type = result?.__type;
    if (__type == null) throw new Error('__type should not be null.');
    const fields = __type?.fields;
    if (fields == null) throw new Error('fields should not be null');

    fields.forEach((field: any) => {
      if (field.name != null && field.title != null)
        formattedResult[field.name] = field;
    });
    return formattedResult;
  };

  public get performQueryAsColumnMetadata() {
    return this._performQueryAsColumnMetadata;
  }

  public set performQueryAsColumnMetadata(value) {
    this._performQueryAsColumnMetadata = value;
  }

  performQuery = async <ReponseTyping = any, VariableTyping = any>(
    query: string,
    variables: VariableTyping | null,
    onError: (err: QueryError) => void,
    onValidationError: (err: QueryValidationError) => void
  ): Promise<ReponseTyping | null> => {
    const body: any = createQueryBody<VariableTyping>(query, variables);

    const response = await handleFetch<ReponseTyping, VariableTyping>(body);

    const text = await response.text();

    return new Promise((resolve, reject) => {
      try {
        const {
          errorList,
          validationErrorList,
        }: {
          errorList: [string];
          validationErrorList: any;
        } = parseErrors(text);

        if (errorList?.length > 0) {
          onError({ messages: errorList });
        }

        if (validationErrorList) {
          onValidationError(validationErrorList);
        }

        if (errorList.length > 0 || validationErrorList) {
          reject(new GraphQLError(errorList, validationErrorList));
          return;
        }
      } catch (e: any) {
        const errorList: any = [e?.message ?? 'An error occurred.'];
        onError({ messages: errorList });
        reject(e);
        return;
      }

      if (response.ok) {
        if (text.length === 0) {
          resolve({} as any);
          return;
        }

        const result = JSON.parse(text);
        const { data } = result;

        resolve(data as any);
      } else {
        reject(new Error('Request failed'));
      }
    });
  };

  performMutation = async <ReponseTyping = any, VariableTyping = any>(
    mutation: string,
    variables: VariableTyping | null,
    onError: (err: MutationError) => void,
    onValidationError: (err: MutationValidationError) => void
  ): Promise<ReponseTyping | null> => {
    const body: any = createQueryBody<VariableTyping>(mutation, variables);

    const response = await handleFetch<ReponseTyping, VariableTyping>(body);

    const text = await response.text();

    return new Promise((resolve, reject) => {
      try {
        const {
          errorList,
          validationErrorList,
        }: {
          errorList: [string];
          validationErrorList: any;
        } = parseErrors(text);

        if (errorList?.length > 0) {
          onError({ messages: errorList });
        }

        if (validationErrorList) {
          onValidationError(validationErrorList);
        }

        if (errorList.length > 0 || validationErrorList) {
          reject(new GraphQLError(errorList, validationErrorList));
          return;
        }
      } catch (e: any) {
        const errorList: any = [e?.message ?? 'An error occurred.'];
        onError({ messages: errorList });
        reject(e);
        return;
      }

      if (response.ok) {
        if (text.length === 0) {
          resolve({} as any);
          return;
        }

        const result = JSON.parse(text);
        const { data } = result;

        resolve(data as any);
      } else {
        reject(new Error('Request failed'));
      }
    });
  };
}

const handleFetch = <ReponseTyping = any, VariableTyping = any>(body: any) =>
  fetch(`${getConfig().baseUrl}${getConfig().apiEndPoint}`, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  });

const createQueryBody = <VariableTyping = any>(
  query: string,
  variables: VariableTyping | null
) => {
  const body: any = {};
  body.query = query;
  if (variables != null) {
    body.variables = variables;
  }
  return body;
};
function parseErrors(text: string) {
  const errorList: any = [];
  const validationErrorList: any = {};

  const jsonObject = JSON.parse(text);

  let hasValidationError = false;

  try {
    jsonObject.errors?.forEach((error: any) => {
      if (error.extensions.code === 'VALIDATION_ERROR') {
        hasValidationError = true;
        const fullFieldName = `${error.path}.${error.extensions.field}`;
        if (error.path) {
          validationErrorList[error.path] = {};
          validationErrorList[error.path][error.extensions.field] =
            error.message;
        } else {
          validationErrorList[error.extensions.field] = error.message;
        }
      } else {
        errorList.push(error.message);
      }
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
  if (!hasValidationError) {
    return { errorList, validationErrorList: undefined };
  }
  return { errorList, validationErrorList };
}

export class GraphQLError extends Error {
  errors: string[] = [];

  validationErrors: QueryValidationError = {};

  constructor(
    errors: string[] = [],
    validationErrors: QueryValidationError = {}
  ) {
    super();
    this.errors = errors;
    this.validationErrors = validationErrors;
  }
}
