import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Button, Theme, Tooltip, useTheme } from '@mui/material';
import React from 'react';
import { getArticleHeaderLink } from '../../../helpers/zendeskHelpers';

const buttonSx = (theme: Theme) => ({
  minWidth: theme.spacing(3),
});

interface InlineHelpLinkProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  helpId?: string | number;
  subSectionId?: string | number;
  title?: string;
}

const InlineHelpLink = (props: InlineHelpLinkProps) => {
  const { helpId, subSectionId, title } = props;
  const theme = useTheme();

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {helpId && (
        <Tooltip title={`${title as string} Help`}>
          <Button
            component="a"
            href={getArticleHeaderLink(helpId, subSectionId)}
            target="_blank"
            rel="noopener"
            sx={buttonSx}
          >
            <InfoOutlinedIcon sx={{ color: theme.palette.grey[600] }} />
          </Button>
        </Tooltip>
      )}
    </>
  );
};

export default InlineHelpLink;
