import React, { useEffect, useMemo, useState } from 'react';
import { TimeCard } from 'teto-client-api';
import { v4 as uuid } from 'uuid';
import TableColumnDefinition from '../../../../components/TetoGrid/TableColumnDefinition';
import GroupColumnsRow from './GroupColumnsRow';

type RowCount = { [key: string]: number };

interface MobileSubTableProps {
  data: TimeCard[];
  dataId: string;
  columns: TableColumnDefinition[];
  // eslint-disable-next-line no-unused-vars
  onDeleteClick: (data: TimeCard) => void;
  // eslint-disable-next-line no-unused-vars
  onEditClick: (data: TimeCard) => void;
  // eslint-disable-next-line no-unused-vars
  setSubRowCount: (c: RowCount) => void;
}

const MobileSubTable = (props: MobileSubTableProps) => {
  const { columns, data, dataId, onDeleteClick, onEditClick, setSubRowCount } =
    props;
  const [rowItemCount, setRowItemCount] = useState<number>(0);

  const handleOpeItems = (d: number) => {
    setRowItemCount((e) => e + d);
  };

  useEffect(() => {
    setSubRowCount({
      [dataId]: rowItemCount,
    });
    return () => setSubRowCount({ [dataId]: 0 });
  }, [dataId, rowItemCount, setSubRowCount]);

  const dayGroups = useMemo(
    () =>
      data.map((e) => (
        <GroupColumnsRow
          key={uuid()}
          columns={columns}
          data={e}
          onDeleteClick={onDeleteClick}
          onEditClick={onEditClick}
          setSubOpen={handleOpeItems}
        />
      )),
    [columns, data, onDeleteClick, onEditClick]
  );

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{dayGroups}</>
  );
};

export default MobileSubTable;
