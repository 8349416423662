import { SxProps, Theme, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { QueryFunctionContext, QueryKey } from 'react-query';
import { PagedResponse, TimeCard } from 'teto-client-api';
import TableColumnDefinition from '../../../../components/TetoGrid/TableColumnDefinition';
import { MainTetoGrid } from '../../../../components/TetoGrid/TetoGrid';
import TetoGridRefType from '../../../../components/TetoGrid/TetoGridRefType';
import {
  DESKTOP_SUB_TABLE_FIXED_HEIGHT,
  DESKTOP_SUB_TABLE_ROW_HEIGHT,
} from '../../TimeSheetsTab/WeeklyGridTab/WeeklyGridTabConstants';

interface SubTableProps {
  columns: TableColumnDefinition[];
  data?: TimeCard[];
  rowId: number;
  groupingColumns?: string[];
  ref?: React.MutableRefObject<TetoGridRefType | undefined>;
}

const SubTable = (props: SubTableProps) => {
  const { columns, data, groupingColumns, ref, rowId } = props;
  const { ready } = useTranslation();
  // const TABLE_ID = t('pages.timeTracker.subTableIdentifier');
  const [dataSource, setDataSource] = useState<TimeCard[]>([]);
  const theme = useTheme();

  const mobileSize = useMediaQuery(theme.breakpoints.down('md'));

  async function _doQuery(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-unused-vars
    context: QueryFunctionContext<QueryKey, any>,
    // eslint-disable-next-line no-unused-vars
    pageSize: number,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-unused-vars
    queryData: { filters: any; orderBy: any }
  ) {
    return Promise.resolve().then(() => ({
      records: data,
    }));
  }
  const subCols = mobileSize
    ? columns.filter(
        (e) => (groupingColumns as string[])?.indexOf?.(e.name) >= 0
      )
    : columns;

  const tableHeightSx: SxProps<Theme> = useMemo(
    () => ({
      '& .InovuaReactDataGrid': {
        minHeight: `${
          DESKTOP_SUB_TABLE_ROW_HEIGHT + DESKTOP_SUB_TABLE_FIXED_HEIGHT
        }px !important`,
      },
      '& .InovuaReactDataGrid__body, InovuaReactDataGrid__column-layout': {
        height:
          (data as TimeCard[]).length * DESKTOP_SUB_TABLE_ROW_HEIGHT +
          DESKTOP_SUB_TABLE_FIXED_HEIGHT,
      },
    }),
    [data]
  );

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {ready && data && data.length > 0 && columns && (
        <MainTetoGrid
          clientSideFiltering
          columns={subCols}
          customGridWrapSx={tableHeightSx}
          dataSource={dataSource}
          disableConfigureButton
          disableGroupByToolbar
          disableMobileCols
          disableResetButton
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          doQuery={(a, b, c) =>
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            _doQuery(a, b, c as any) as unknown as Promise<
              PagedResponse<Record<string, unknown>>
            >
          }
          enableFiltering={false}
          enableGroupColumn={false}
          enableRowExpand={false}
          externalQueryProps={{}}
          filterRowHeight={0}
          idProperty="id"
          multiRowExpand={false}
          noHeader
          pageSize={50000}
          ref={ref}
          setDataSource={setDataSource}
          showCellBorders={false}
          tableIdentifier={rowId.toString()}
          virtualized={false}
        />
      )}
    </>
  );
};

export default SubTable;
