import { Box, SxProps } from '@mui/material';
import React from 'react';

interface TabPanelProps {
  children: React.ReactNode;
  index: number;
  value: number;
  customSx: SxProps;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, customSx, value, index, ...other } = props;
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {value === index && (
        <Box
          sx={[...(Array.isArray(customSx) ? customSx : [customSx])]}
          role="tabpanel"
          hidden={value !== index}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...other}
        >
          {children}
        </Box>
      )}
    </>
  );
};

export default TabPanel;
