import React from 'react';
import GridPersistenceOptions from './GridPersistenceOptions';

export interface GridPersistenceState extends GridPersistenceOptions {
  loaded: boolean;
  error?: string;
  resetSettings: () => Promise<void>;
  saveSettings: () => Promise<void>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const context = React.createContext<GridPersistenceState>(null as any);

export default context;
