const whiteSpaceRegex = /^\s/;
const nullRegex = /null, null/;
const isNotEmptyOrNullLocation = (location: string) => {
  if (location.match(whiteSpaceRegex) || location.match(nullRegex)) {
    return false;
  }
  return true;
};

export default isNotEmptyOrNullLocation;
