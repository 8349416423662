const postUpdateClockInMutation = `mutation updateClockIn($id: Int!, $startTime: DateTime!, $endTime: DateTime!) {
  updateClockIn(id: $id, input: { startTime: $startTime, endTime: $endTime }) {
    id,
    startTime,
    endTime,
    date
    totalDuration
  }
}
`;

export default postUpdateClockInMutation;
