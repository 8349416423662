import { ForeignKeyOperation } from '../../../../components/TetoGrid/InlineEditing/editorTypes';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const connectedNames: { [key: string]: { [key: string]: any } } = {
  projectName: {
    key: 'projectId',
    reset: ['projectId', 'projectName'],
    title: 'Entities.Project.Project',
  },
  specName: {
    key: 'specId',
    reset: ['specId', 'specName'],
    title: 'Entities.Machine.Job',
  },
  employeeFullName: {
    key: 'employeeId',
    reset: [
      'employeeId',
      'employeeLastName',
      'employeeFullName',
      'employeeFirstName',
      'empNumber',
    ],
    title: 'Entities.Employee.Employee.fullName',
  },
  hourTypeName: {
    key: 'hourTypeId',
    reset: ['hourTypeName', 'hourTypeId'],
    title: 'Entities.HourType.HourType',
  },
  nonConformanceName: {
    key: 'nonConformanceId',
    reset: ['nonConformanceId', 'nonConformanceName'],
    title: 'Entities.NonConformance.NonConformance',
  },
  processScheduleDetailName: {
    key: 'processScheduleDetailId',
    reset: ['processScheduleDetailId', 'processScheduleDetailName'],
    title: 'Entities.ProcessScheduleDetail.ProcessScheduleDetail',
  },
};

const processTimecardForeignKey = (
  cName: string,
  operation: ForeignKeyOperation
) => {
  switch (operation) {
    case 'key':
      return connectedNames[cName].key;
    case 'title':
      return connectedNames[cName].title;
    default:
      return connectedNames[cName].reset;
  }
};

const processTimecardForeignKeyChange = (
  e: { value: string; name: string },
  data: { [key: string]: unknown }
) => {
  let result = {};

  switch (e.name) {
    case 'employeeFullName':
      result = {
        empNumber: data.employeeNumber,
        employeeFirstName: data.firstName,
        employeeFullName: data.name,
        employeeLastName: data.lastName,
        employeeId: e.value,
      };
      break;

    case 'nonConformanceName':
      result = {
        nonConformanceName: data.title,
        nonConformanceId: e.value,
      };
      break;
    case 'specName':
      result = {
        [processTimecardForeignKey(e.name, 'key')]: e.value,
        [e.name]: data.displayName,
      };
      break;

    default:
      result = {
        [processTimecardForeignKey(e.name, 'key')]: e.value,
        [e.name]: data.displayName,
      };
      break;
  }
  return result;
};

export { processTimecardForeignKey, processTimecardForeignKeyChange };
