import ETOMultilineStringEditor from '../Editors/ETOMultilineStringEditor';
import DefaultBooleanEditor from './DefaultBooleanEditor';
import DefaultDateEditor from './DefaultDateEditor';
import DefaultForeignKeyEditor from './DefaultForeignKeyEditor';
import DefaultHoursEditor from './DefaultHoursEditor';
import DefaultNumberEditor from './DefaultNumberEditor';
import DefaultStringEditor from './DefaultStringEditor';
import DefaultTimeEditor from './DefaultTimeEditor';

const getDefaultEditor = (type: string) => {
  switch (type) {
    case 'time':
      return DefaultTimeEditor;
    case 'foreignKey':
      return DefaultForeignKeyEditor;
    case 'number':
      return DefaultNumberEditor;
    case 'date':
      return DefaultDateEditor;
    case 'hours':
      return DefaultHoursEditor;
    case 'boolean':
      return DefaultBooleanEditor;
    case 'multilineString':
      return ETOMultilineStringEditor;
    default:
      return DefaultStringEditor;
  }
};

export default getDefaultEditor;
