import React, { useContext } from 'react';
import { ETODateField, SettingsContext } from '@teto/react-component-library';
import { SxProps } from '@mui/material/styles';
import PickerEditorWrapper from '../EditorWrappers/PickerEditorWrapper';
import InlineEditorProps from '../InlineEditingProps';

const rootSx: SxProps = {
  '& .MuiOutlinedInput-adornedEnd': {
    padding: '0px',
  },
  '& .MuiInputAdornment-positionEnd ': {
    margin: '0px',
  },
  '& .InovuaReactDataGrid__cell__editor InovuaReactDataGrid__cell__editor--date':
    {},
};

// eslint-disable-next-line react/function-component-definition
const ETODateEditor: React.FC<InlineEditorProps> = (props) => {
  const settings = useContext(SettingsContext);
  const { cellProps } = props;
  return (
    <PickerEditorWrapper
      {...props}
      child={
        <ETODateField
          name={cellProps?.name as string}
          inputFormat={settings.settings.dateFormat}
        />
      }
      customCellSx={rootSx}
    />
  );
};

export default ETODateEditor;
