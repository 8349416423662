import { Box, Theme } from '@mui/material';
import {
  ButtonStrip,
  ETODateField,
  Inspector,
  MessageContext,
  SettingsContext,
} from '@teto/react-component-library';
import dayjs, { Dayjs } from 'dayjs';
import { FormikHelpers, useFormik } from 'formik';
import { t } from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import { addPayPeriod, getNextAvailablePayPeriod } from 'teto-client-api';
import * as Yup from 'yup';
import { parseServerResponse } from '../../../helpers/validationHelperREST';

const contentSx = {
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
};

const buttonRowSx = (theme: Theme) => ({
  flex: `0 0 ${theme.spacing(7.5)}`,
  flexGrow: 0,
});

type PayPeriodValues = {
  startDate: Dayjs | null | undefined;
  endDate: Dayjs | null | undefined;
};

interface CreatePayPeriodInspectorProps {
  title: string;
  open: boolean;
  onClose: () => void;
  setRefreshToken: React.Dispatch<React.SetStateAction<Date | undefined>>;
}

const REQUIRED_MESSAGE = 'Required';

const YupSchema = Yup.object().shape({
  startDate: Yup.object()
    .required(REQUIRED_MESSAGE)
    .nullable()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .test('date-validation', 'Invalid Date', (val: any) => dayjs.isDayjs(val)),
  endDate: Yup.object()
    .required(REQUIRED_MESSAGE)
    .nullable()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .test('date-validation', 'Invalid Date', (val: any) => dayjs.isDayjs(val)),
});

const CreatePayPeriodInspector = (props: CreatePayPeriodInspectorProps) => {
  const { title, open, onClose, setRefreshToken } = props;

  const settingsContext = useContext(SettingsContext);
  const messageContext = useContext(MessageContext);

  const [startDate, setStartDate] =
    useState<Dayjs | undefined | null>(undefined);
  const [endDate, setEndDate] = useState<Dayjs | undefined | null>(undefined);

  useEffect(() => {
    getNextAvailablePayPeriod()
      .then((d) => {
        setStartDate(() => d.startDate);
        setEndDate(() => d.endDate);
      })
      .catch((e) => {
        messageContext.setError(e.message);
      });
  }, [setStartDate, setEndDate, messageContext]);

  const formik = useFormik<PayPeriodValues>({
    enableReinitialize: true,
    validationSchema: YupSchema,
    initialValues: {
      startDate,
      endDate,
    },
    onSubmit: (values, actions) => _onSubmit(values, actions),
  });

  const _onSubmit = (
    values: PayPeriodValues,
    actions: FormikHelpers<PayPeriodValues>
  ) => {
    if (startDate && endDate) {
      addPayPeriod({
        startDate: startDate.utc(true),
        endDate: endDate.utc(true),
      })
        .then(() => {
          actions.setSubmitting(false);
          setRefreshToken(new Date());
          onClose();
          messageContext.setSuccess(t('generic.message.payPeriodCreated'));
        })
        .catch((e) => {
          actions.setSubmitting(false);
          parseServerResponse(
            e,
            (errors) => actions.setErrors(errors),
            (error) => {
              messageContext.setError(error);
            }
          );
        });
    }
  };

  return (
    <Inspector open={open} title={title} onClose={onClose}>
      <Box sx={contentSx} component="form" onSubmit={formik.handleSubmit}>
        <Box mt={3}>
          <ETODateField
            name="payPeriodStartDate"
            value={startDate}
            handleChange={(v) => setStartDate(v)}
            label={t('generic.startDate')}
            inputFormat={settingsContext.settings.dateFormat}
            clearable
            error={formik.errors.startDate as string}
          />
        </Box>

        <Box mt={3}>
          <ETODateField
            name="payPeriodEndDate"
            value={endDate}
            handleChange={(v) => setEndDate(v)}
            label={t('generic.endDate')}
            inputFormat={settingsContext.settings.dateFormat}
            clearable
            error={formik.errors.endDate as string}
          />
        </Box>
      </Box>
      <Box sx={buttonRowSx}>
        <ButtonStrip
          size="medium"
          rightButton={{
            text: 'Save',
            color: 'primary',
            onClick: () => formik.handleSubmit(),
          }}
        />
      </Box>
    </Inspector>
  );
};

export default CreatePayPeriodInspector;
