import { TypeColumn } from '@inovua/reactdatagrid-community/types';
import ReactDataGrid from '@inovua/reactdatagrid-enterprise';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  SxProps,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import {
  AppearanceContext,
  AuthContext,
  ETOCheckBox,
  MessageContext,
} from '@teto/react-component-library';
import { t } from 'i18next';
import React, { useContext, useMemo } from 'react';
import { Permission } from 'teto-client-api';
import useLocalStorage from 'use-local-storage';
import useGridStyles from '../../../../components/TetoGrid/GridStyles';
import { reactDataGridLicenseKey } from '../../../../components/TetoGrid/Licensing';
import { PurchaseOrderDetail } from '../PurchaseOrder';

const sizeSx: SxProps<Theme> = {
  height: (theme) => theme.spacing(4.5),
  textTransform: 'capitalize',
};
const primarySx: SxProps<Theme> = {
  backgroundColor: 'primary.main',
  color: 'common.white',
  '&:hover': {
    backgroundColor: 'primary.light',
  },
};
const secondarySx: SxProps<Theme> = {
  backgroundColor: 'secondary.main',
  color: 'common.white',
  '&:hover': {
    backgroundColor: 'secondary.light',
  },
};

interface Props {
  purchaseOrderDetails: PurchaseOrderDetail[];
  // eslint-disable-next-line no-unused-vars
  onReceive: (generateReport: boolean) => Promise<void>;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const ReceivingDialog = (props: Props) => {
  const { purchaseOrderDetails, onReceive, open, setOpen } = props;

  const authContext = useContext(AuthContext);
  const appearanceContext = useContext(AppearanceContext);
  const messageContext = useContext(MessageContext);

  const [generateReport, setGenerateReport] = useLocalStorage<boolean>(
    'generate-report',
    false
  );
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const changedRows = useMemo(
    () =>
      purchaseOrderDetails?.filter(
        (detail: PurchaseOrderDetail) => detail.toReceive
      ),
    [purchaseOrderDetails]
  );

  const currentTheme =
    appearanceContext.themeVariant === 'dark'
      ? 'default-dark'
      : 'default-light';
  const columns: TypeColumn[] = useMemo(
    () => [
      {
        name: 'purchaseSupplierItem',
        header: 'PO Item',
        sortable: false,
        defaultFlex: 1,
        minWidth: 160,
        showColumnMenuTool: false,
        showInContextMenu: false,
      },
      {
        name: 'toReceive',
        header: 'To Receive',
        sortable: false,
        defaultWidth: isMobile ? 90 : 120,
        showInContextMenu: false,
        showColumnMenuTool: false,
      },
    ],
    [isMobile]
  );
  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          minWidth: theme.spacing(40),
        },
      }}
    >
      <DialogTitle>{t('Entities.Receiving.receive')}</DialogTitle>
      <DialogContent>
        <>
          {purchaseOrderDetails && (
            <Box
              sx={[
                {
                  marginBottom: theme.spacing(2),
                },
                useGridStyles,
              ]}
            >
              <ReactDataGrid
                dataSource={changedRows}
                columns={columns}
                licenseKey={reactDataGridLicenseKey}
                theme={currentTheme}
              />
            </Box>
          )}
          {authContext.hasPermission(
            Permission.View_Legacy_Reports_Receiving_Report
          ) && (
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography variant="subtitle1">
                {t('generic.generateReport')}
              </Typography>
              <ETOCheckBox
                name="generateReport"
                value={generateReport}
                handleChange={() => setGenerateReport((d) => !d)}
              />
            </Box>
          )}
          <Typography variant="subtitle1">
            {t('dialogs.confirmPurchaseOrder.content')}
          </Typography>
        </>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          size="medium"
          onClick={() => setOpen(false)}
          sx={[sizeSx, secondarySx]}
          variant="contained"
        >
          {t('generic.cancel')}
        </Button>
        <Button
          color="primary"
          size="medium"
          onClick={async () => {
            if (
              purchaseOrderDetails.length !== 0 &&
              purchaseOrderDetails.some(
                (detail: PurchaseOrderDetail) => detail.toReceive
              )
            ) {
              await onReceive(generateReport);
              setOpen(false);
            } else {
              messageContext.setError(t('generic.message.noQuantitiesReceive'));
            }
          }}
          sx={[sizeSx, primarySx]}
          variant="contained"
        >
          {t('generic.ok')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReceivingDialog;
