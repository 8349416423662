import * as Yup from 'yup';
import CommonFormValidation from '../TimecardFormValidation';

const REQUIRED_MESSAGE = 'Required';

const DaySchema = Yup.object({
  hours: Yup.number().required(REQUIRED_MESSAGE).min(0),
});

const YupSchema = Yup.object()
  .shape({
    // eslint-disable-next-line react/forbid-prop-types
    hours: Yup.object({
      day1: DaySchema,
      day2: DaySchema,
      day3: DaySchema,
      day4: DaySchema,
      day5: DaySchema,
      day6: DaySchema,
      day7: DaySchema,
    } as { [key: string]: typeof DaySchema }),
  })
  .concat(CommonFormValidation);

export default YupSchema;
