import {
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  Stack,
  SxProps,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  ButtonStrip,
  ETOTextField,
  Inspector,
  MessageContext,
  SettingsContext,
} from '@teto/react-component-library';
import dayjs, { Dayjs } from 'dayjs';
import { isEmpty } from 'lodash';
import React, { FocusEventHandler, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { notifyOverdueTimesheet } from 'teto-client-api';
import * as Yup from 'yup';
import RDGSelectedType from '../../../../components/TetoGrid/RDGSelectedType';
import { parseServerResponse } from '../../../../helpers/validationHelperREST';

const commentsTitleSx = (theme: Theme) => ({
  marginBottom: theme.spacing(1),
});

const buttonStripSx: SxProps = {
  borderTop: 0,
};

const mainContainer: SxProps = {
  height: '100vh',
};

const detailsContainer: SxProps<Theme> = (theme: Theme) => ({
  height: '56vh',
  [theme.breakpoints.up('lg')]: {
    maxHeight: '55em',
    minHeight: '25em',
  },
  [theme.breakpoints.only('lg')]: {
    maxHeight: '25em',
    minHeight: '25em',
  },
  [theme.breakpoints.only('md')]: {
    maxHeight: '12em',
    minHeight: '12em',
  },
  [theme.breakpoints.only('xs')]: {
    minHeight: '17em',
    maxHeight: '17em',
  },
  [theme.breakpoints.down('md')]: {
    height: '50vh',
  },
});

const listContainer: SxProps<Theme> = {
  width: '100%',
  height: '94%',
  overflow: 'auto',
};

const commentsContainer: SxProps<Theme> = (theme: Theme) => ({
  position: 'relative',
  height: '30vh',
  [theme.breakpoints.down('md')]: {
    height: '34vh',
  },
});

const commentsComponent: SxProps = {
  position: 'absolute',
  bottom: 0,
  width: '100%',
};

const dividerSx: SxProps<Theme> = (theme: Theme) => ({
  borderTop: `1px solid ${theme.palette.error.main}`,
  paddingTop: theme.spacing(0.5),
});

interface EmailInspectorPropsI {
  open: boolean;
  handleClose: () => void;
  selectedRows: RDGSelectedType | undefined;
  setSelectedRows: React.Dispatch<
    React.SetStateAction<RDGSelectedType | undefined>
  >;
  setSelectAllRowsData: React.Dispatch<
    React.SetStateAction<RDGSelectedType | undefined>
  >;
  selectAllRowsData: RDGSelectedType | undefined;
  setRefreshToken: React.Dispatch<React.SetStateAction<Date | undefined>>;
}

interface NotificationError {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

interface OverdueSheets {
  employeeId: number;
  timesheetId?: number;
  startDate: Dayjs;
  endDate: Dayjs;
  hasSent?: boolean;
}

const EmailInspector = (props: EmailInspectorPropsI) => {
  const {
    open,
    handleClose,
    setRefreshToken,
    selectedRows,
    setSelectedRows,
    selectAllRowsData,
    setSelectAllRowsData,
  } = props;

  const theme = useTheme();
  const mobileDevices = useMediaQuery(theme.breakpoints.down('md'));

  const { t } = useTranslation();

  const { settings } = useContext(SettingsContext);
  const messageContext = useContext(MessageContext);

  const [commentsError, setCommentsError] = useState<string>('');
  const [comments, setComments] = useState<string>('');
  const [notificationErrors, setNotificationErrors] =
    useState<NotificationError>({});
  const [loading, setLoading] = useState(false);
  const [sendButtonText, setSendButtonText] = useState(
    t('generic.send') as string
  );
  const [hasSent, setHasSent] = useState<boolean>(false);

  const gridSelectedRows = () => {
    if (!isEmpty(selectAllRowsData) && !isEmpty(selectedRows)) {
      const rows = Object.keys(selectedRows);
      return Array.isArray(selectAllRowsData)
        ? selectAllRowsData.filter((item: RDGSelectedType) =>
            rows.includes(item?.id as unknown as string)
          )
        : [];
    }
    if (Array.isArray(selectAllRowsData) && !isEmpty(selectAllRowsData)) {
      return selectAllRowsData.map((data) => data);
    }

    if (
      !Array.isArray(selectedRows) &&
      !isEmpty(selectedRows) &&
      !selectAllRowsData
    ) {
      return Object.values(selectedRows);
    }

    if (!isEmpty(selectedRows)) {
      return Object.values(selectedRows);
    }

    return [];
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [rowData, setRowData] = useState<any[]>(gridSelectedRows());

  const timesheets = !isEmpty(gridSelectedRows())
    ? gridSelectedRows()
        .map((row) => ({
          employeeId: row.employeeId,
          startDate: row.startDate,
          endDate: row.endDate,
          timesheetId: row.timesheetId ?? undefined,
        }))
        .map((item) => ({
          ...item,
          hasSent: false,
        }))
    : [];

  const [timesheetsToSend, setTimesheetsToSend] =
    useState<OverdueSheets[] | undefined>(timesheets);

  const YupSchema = Yup.string()
    .nullable()
    .max(2048, 'Comments exceed max character length');

  // If form level error, show in MessageContext otherwise show at individual input level
  const _handleErrors = (errors: { [key: string]: string }) => {
    const keys = Object.keys(errors);
    keys.forEach((k) => {
      if (k === '*') {
        messageContext.setError(errors[k]);
      }
    });
  };

  const _handleSave = () => {
    setLoading(true);
    // eslint-disable-next-line no-alert
    if (timesheetsToSend) {
      notifyOverdueTimesheet(
        {
          messageComments: comments,
        },
        timesheetsToSend
      )
        .then(() => {
          setLoading(false);
          messageContext.setSuccess(t('generic.message.notificationSent'));
          setSelectedRows({});
          setSelectAllRowsData([]);
          handleClose();
          setRefreshToken(new Date());
        })
        .catch((e) => {
          if (e.errors.errors) {
            // Refactor code block, more effeicient way to do this
            const failedTimesheetsIndex = Object.keys(e.errors.errors).map(
              (d) => parseInt(d, 10)
            );
            setTimesheetsToSend((prev) =>
              prev?.filter((d, index) => {
                if (failedTimesheetsIndex.includes(index)) return true;
                return false;
              })
            );
            const timesheetIDs = timesheetsToSend.map((d) => d.timesheetId);
            setRowData((prev) =>
              prev.filter((d) => {
                if (timesheetIDs.indexOf(d.timesheetId) > -1) return true;
                return false;
              })
            );
          }

          setSendButtonText(t('generic.resend'));
          setLoading(false);
          setHasSent(true);
          setNotificationErrors(e);
          parseServerResponse(
            e,
            (errors) => _handleErrors(errors),
            (error) => messageContext.setError(error)
          );
        });
    }
  };

  const _handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setComments(e.target.value);
  };

  const _handleBlur = () => {
    YupSchema.validate(comments)
      .then(() => setCommentsError(''))
      .catch((err) => setCommentsError(err.message));
  };

  const createErrorTimesheets = () => {
    if (isEmpty(notificationErrors?.errors?.errors)) {
      return [];
    }

    const errorsIndex = Object.keys(notificationErrors?.errors?.errors);
    const errorMessages = Object.values(notificationErrors?.errors?.errors);
    const errorTimesheetList = errorsIndex?.map(
      (index) => rowData?.[index as unknown as number]
    );

    return errorTimesheetList.map((data, i) => ({
      ...data,
      error: errorMessages[i],
    }));
  };

  return (
    <Inspector
      open={open}
      onClose={() => {
        setCommentsError('');
        setSelectedRows({});
        setSelectAllRowsData([]);
        handleClose();
        if (hasSent) setRefreshToken(new Date());
      }}
      title={t('generic.notify')}
    >
      <Stack sx={mainContainer} spacing={1} data-testid="emailInspector">
        <Box sx={detailsContainer}>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="subtitle2">
                {t('Entities.Employee.Employee')}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2">
                {t('Entities.TimeSheet.week')}
              </Typography>
            </Grid>
          </Grid>
          <Divider />
          <List sx={listContainer}>
            {isEmpty(notificationErrors)
              ? rowData?.map((data: RDGSelectedType) => (
                  <ListItem key={data?.id} disableGutters>
                    <Grid container>
                      <Grid item xs={6}>
                        <Typography
                          variant="body2"
                          data-testid="emailListNames"
                        >
                          {`${data?.firstName} ${data?.lastName}`}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body2">
                          {dayjs(data?.startDate)
                            .format(settings.dateFormat)
                            .toString()}{' '}
                          -{' '}
                          {dayjs(data?.endDate)
                            .format(settings.dateFormat)
                            .toString()}
                        </Typography>
                      </Grid>
                    </Grid>
                  </ListItem>
                ))
              : createErrorTimesheets()?.map((data: RDGSelectedType) => (
                  <ListItem key={data?.id} disableGutters>
                    <Grid container>
                      <Grid item xs={6}>
                        <Typography variant="body2">
                          {`${data?.firstName} ${data?.lastName}`}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body2">
                          {dayjs(data?.startDate)
                            .format(settings.dateFormat)
                            .toString()}{' '}
                          -{' '}
                          {dayjs(data?.endDate)
                            .format(settings.dateFormat)
                            .toString()}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sx={dividerSx}>
                        <Typography
                          variant="caption"
                          display="block"
                          color="error"
                        >
                          {data?.error}
                        </Typography>
                      </Grid>
                    </Grid>
                  </ListItem>
                ))}
          </List>
        </Box>
        <Box sx={commentsContainer}>
          <Box sx={commentsComponent}>
            <Typography variant="h6" sx={commentsTitleSx}>
              {t('forms.fields.comments')}
            </Typography>
            <ETOTextField
              name="comments"
              handleChange={(e) => _handleChange(e)}
              label={t('forms.fields.comments')}
              multiline
              error={commentsError}
              inputProps={{
                onBlur:
                  _handleBlur() as unknown as FocusEventHandler<HTMLTextAreaElement>,
              }}
              minRows={mobileDevices ? 4 : 6}
              maxRows={mobileDevices ? 4 : 6}
            />
            <ButtonStrip
              rightButton={{
                text: sendButtonText,
                onClick: _handleSave,
                color: 'primary',
                loading,
              }}
              className={buttonStripSx}
              size="medium"
            />
          </Box>
        </Box>
      </Stack>
    </Inspector>
  );
};
export default EmailInspector;
