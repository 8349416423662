import { GroupableColumns } from './GroupByModal';

const setupInitialGrouping = (
  initialGroupings: string[],
  colsToBeGrouped: GroupableColumns[]
) => {
  const newGroups: string[] = [];
  initialGroupings.forEach((group) => {
    const foundCol = colsToBeGrouped.find((col) => col.name === group);
    if (foundCol) {
      newGroups.push(foundCol?.title);
    }
  });
  return newGroups;
};
export default setupInitialGrouping;
