import { Box, SxProps, Theme } from '@mui/material';
import { ETODateField, SettingsContext } from '@teto/react-component-library';
import React, { useContext } from 'react';
import editorStyles from '../InlineEditingCommonStyles';

const localSx: SxProps<Theme> = {
  '& .MuiInputBase-root, & .MuiFormControl-root': {
    height: '100%',
    width: '100%',
  },
  '& .MuiFormHelperText-root': {
    fontSize: '0.9rem',
    position: 'absolute',
    zIndex: 1500,
    bottom: '-30px',
    backgroundColor: 'rgba(97, 97, 97, 0.92)',
    marginLeft: 0,
    marginRight: 0,
    color: (theme) => `${theme.palette.common.white} !important`,
    padding: (theme) => `${theme.spacing(0)} ${theme.spacing(1.75)}`,
    borderRadius: (theme) => theme.spacing(0.5),
    '& .Mui-error': {
      color: (theme) => `${theme.palette.common.white} !important`,
      '&:hover': {
        color: 'common.white',
      },
    },
  },
  '& .MuiInputBase-input': {
    minWidth: '0px !important',
  },
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DefaultDateTimeEditor = (props: any) => {
  const {
    cellProps,
    onCancel,
    onTabNavigation,
    onComplete,
    onChange,
    onEnterNavigation,
    value,
  } = props;

  const settings = useContext(SettingsContext);

  return (
    <Box sx={[editorStyles.root, localSx]}>
      <ETODateField
        autoFocus={cellProps.inEdit}
        name={cellProps.name}
        handleChange={onChange}
        onBlur={cellProps.onBlur}
        onFocus={cellProps.startEdit}
        onKeyDown={(e) => {
          if (e.key === 'Escape') {
            onCancel(e);
          }
          if (e.key === 'Enter') {
            onComplete(e);
            onEnterNavigation(true, e.shiftKey ? -1 : 1);
          }
          if (e.key === 'Tab') {
            e.preventDefault();
            onTabNavigation(true, e.shiftKey ? -1 : 1);
          }
        }}
        inputFormat={settings.settings.dateTimeFormat}
        value={value}
      />
    </Box>
  );
};

export default DefaultDateTimeEditor;
