import ETOBooleanEditor from './Editors/ETOBooleanEditor';
import ETODateEditor from './Editors/ETODateEditor';
import ETODateTimeEditor from './Editors/ETODateTimeEditor';
import ETOHourEditor from './Editors/ETOHourEditor';
import ETOMultilineStringEditor from './Editors/ETOMultilineStringEditor';
import ETONumberEditor from './Editors/ETONumberEditor';
import ETOSelectionEditor from './Editors/ETOSelectionEditor';
import ETOStringEditor from './Editors/ETOStringEditor';
// import ETOTimeEditor from './Editors/ETOTimeEditor';

const getEditor = (type: string) => {
  switch (type) {
    case 'boolean':
      return ETOBooleanEditor;
    case 'date':
      return ETODateEditor;
    case 'time':
      return ETODateTimeEditor;
    case 'hours':
      return ETOHourEditor;
    case 'number':
      return ETONumberEditor;
    // case 'time': return ETOTimeEditor;
    case 'foreignKey':
      return ETOSelectionEditor;
    case 'multilineString':
      return ETOMultilineStringEditor;
    case 'string':
    default:
      return ETOStringEditor;
  }
};

export default getEditor;
