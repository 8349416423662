import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import React, { useState } from 'react';
import TableColumnDefinition from '../TableColumnDefinition';

interface ColumnToggleCheckboxProps {
  column: TableColumnDefinition;
  // eslint-disable-next-line no-unused-vars
  onChange: (colName: string) => void;
  disabled?: boolean;
}

const ColumnToggleCheckbox = (props: ColumnToggleCheckboxProps) => {
  const { column, onChange, disabled } = props;
  const [checked, setChecked] = useState(
    column.hidden == null || !column.hidden
  );
  const handleChange = (colName: string) => {
    setChecked(Boolean(column.hidden));
    onChange(colName);
  };
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            data-testid="configureInspectorListItemCheckBox"
            key={column.name}
            name={column.name}
            checked={checked}
            onChange={() => handleChange(column.name)}
            color="primary"
            inputProps={{
              'aria-label': 'primary checkbox',
              'aria-checked': `${checked}`,
            }}
            size="small"
            value={checked}
            disabled={disabled}
          />
        }
        // eslint-disable-next-line jsx-a11y/aria-proptypes
        aria-checked={`${checked}`}
        data-testid="configureInspectorListItemCheckBoxLabel"
        label={column.title}
      />
    </FormGroup>
  );
};

export default ColumnToggleCheckbox;
