const exportReceivingReportQuery = `query exportReceivingReport(
  $purchaseOrderId: Int!
  $batchId: String!
) {
  exportReceivingReport (
    input: {
      purchaseOrderId: $purchaseOrderId
      batchId: $batchId
    }
  ) {
    path
    reportId
  }
}`;

export default exportReceivingReportQuery;
