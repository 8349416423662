const searchForPOByFilterQuery = `query searchForPOByFilter($itemCompanyId: String!, $supplierName: String!) {
  purchaseOrderDetails(where: { and: [
    {item: { itemCompanyId: { startsWith: $itemCompanyId }}},
    { purchaseOrder: { purchaseSupplier: { name: { startsWith: $supplierName } }}}
    ]
    }
    ) {
    items {
      id,
      purchaseOrder {
        id,
        pONotes {
            note
          }
          buyer {
            name
          }
          purchaseSupplier {
            name
          }
          buyerId
          purchaseOrderDetails {
            id
            projectId
            specId
            spec {
              id
              displayName
            }
            purchaseSupplierItem
            purchaseSupplierDescription
            purchaseQty
            dateRequired
            dateRevised
            status {
              quantityReceived
            }
          }
      },
      item {
        id,
        itemCompanyId
      }
    }
  }
}`;

export default searchForPOByFilterQuery;
