const stopClockInMutation = `mutation stopCloclIn ($employeeId: Int!) {
  stopClockIn (employeeId: $employeeId){
    success
    startTime
    endTime
  }
}
`;

export default stopClockInMutation;
