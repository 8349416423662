import { Grid } from '@mui/material';
import React from 'react';

interface Props {
  mobileRowHeight: number;
  children: React.ReactNode;
}

const MobileCellWrapper = (props: Props) => {
  const { children, mobileRowHeight } = props;
  return (
    <Grid
      sx={{
        height: mobileRowHeight,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        pt: 2,
      }}
    >
      {children}
    </Grid>
  );
};

export default MobileCellWrapper;
