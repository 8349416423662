import IndeterminateCheckBoxRounded from '@mui/icons-material/IndeterminateCheckBoxRounded';
import {
  Box,
  Checkbox,
  Popper,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import { FormikValues, useFormikContext } from 'formik';
import React, { useRef } from 'react';
import { getDisabledValue, onFocus, onKeyDown } from '../editingHelpers';
import { Errors, Values } from '../editorTypes';
import editorStyles from '../InlineEditingCommonStyles';
import InlineEditorProps from '../InlineEditingProps';

const cellSx = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& .MuiCheckbox-root': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

const errorSx = (theme: Theme) => ({
  border: `2px solid ${theme.palette.error.main}`,
  color: theme.palette.error.main,
  borderRadius: 1,
});

const activeEditSx = (theme: Theme) => ({
  border: `2px solid ${theme.palette.primary.main}`,
  borderRadius: '3px',
});

const popperSx: SxProps = {
  bgColor: 'none',
};

const ETOBooleanEditor: React.FC<InlineEditorProps> = (props) => {
  const {
    autoFocus,
    cellProps,
    editorProps,
    onCancel,
    onComplete,
    onTabNavigation,
  } = props;
  const columnName = cellProps?.name as string;
  const formik = useFormikContext();
  const { errors, values } = formik;
  const boxRef = useRef();

  const error = errors[columnName as keyof Errors];
  const value = (values as FormikValues)[columnName as keyof Values];

  const checked = !editorProps?.allowUnset
    ? value ?? false
    : value ?? undefined;

  const isDisabled =
    !editorProps?.hasEditPermission ||
    getDisabledValue(cellProps?.data, editorProps?.disabled) ||
    editorProps?.lockColumn?.(columnName, cellProps?.data);

  const editSx = error ? errorSx : activeEditSx;

  const editHandlers = {
    onCancel,
    onComplete,
    onTabNavigation,
  };

  // eslint-disable-next-line no-unused-vars
  const _handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (editorProps?.allowUnset) {
      if (checked === true) formik.setFieldValue(columnName, undefined);
      else if (checked === false) formik.setFieldValue(columnName, true);
      else formik.setFieldValue(columnName, false);
    } else {
      formik.setFieldValue(columnName, !value);
    }
  };

  const checkBox = (
    <Box ref={boxRef} sx={[cellSx, editorStyles.root, editSx]}>
      <Checkbox
        autoFocus={autoFocus ?? false}
        color="primary"
        checked={checked ?? false}
        indeterminate={!editorProps?.allowUnset ? false : checked === undefined}
        indeterminateIcon={<IndeterminateCheckBoxRounded />}
        inputProps={{
          onFocus,
          onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) =>
            onKeyDown(e, editHandlers),
          style: {
            minWidth: Math.max(0, (cellProps?.computedWidth as number) - 30),
          },
          tabIndex: 0,
        }}
        onChange={_handleChange}
        disabled={isDisabled}
        name={columnName}
        size="small"
      />
    </Box>
  );

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {columnName && !isDisabled && (
        <>
          {checkBox}

          {error && (
            <Popper
              open
              sx={popperSx}
              placement="bottom-start"
              anchorEl={boxRef?.current}
              onResize={undefined}
              onResizeCapture={undefined}
            >
              <Typography variant="caption" color="error">
                {error}
              </Typography>
            </Popper>
          )}
        </>
      )}
    </>
  );
};

export default ETOBooleanEditor;
