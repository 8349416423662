/* eslint-disable @typescript-eslint/no-explicit-any */
const lockedCols = [
  'id',
  'employeeId',
  'employeeFirstName',
  'employeeLastName',
  'empNumber',
  'departmentName',
  'subDepartmentName',
  'actualHours',
  'roundedHours',
  'hourClass',
  'timePeriodId',
  'timePeriodStartDate',
  'timePeriodEndDate',
  'pscCustom1',
  'pscCustom2',
  'pscCustom7',
  'pscCustom8',
  'timeSheetId',
  'exported',
  'doNotExport',
  'validated',
  'batched',
  'locked',
  'lastModDateForTsheets',
  'timePeriodExported',
  'closingRelease',
  'timesheetStatus',
  'projectId',
  'specId',
  'nonConformanceId',
  'hourTypeId',
];

// eslint-disable-next-line no-unused-vars
const getLockedColumns = (c: string, d: any) => {
  const isNoEndTime = !d?.endTime && c === 'endTime';
  const isNoStartTime = !d?.startTime && c === 'startTime';
  const isPunchIn = d?.timecardPunchInID && c === 'hourTime';
  const isPayPeriodLocked = d?.timePeriodLocked && c === 'hourTime';
  const isNoProcessScheduleDetailName =
    !d?.processScheduleDetailName && c === 'quantity';

  if (lockedCols.includes(c)) return true;
  if (isNoEndTime) return true;
  if (isNoStartTime) return true;
  if (isNoProcessScheduleDetailName) return true;
  if (isPunchIn) return true;
  if (isPayPeriodLocked) return true;

  return false;
};
export default getLockedColumns;
