import { SettingsContext } from '@teto/react-component-library';
// eslint-disable-next-line no-unused-vars
import React, { useContext, useEffect, useState } from 'react';
import GeolocationInfo from './IGeolocationInfo';

const useLocationFormatted = () => {
  const {
    settings: { geotaggingMode },
  } = useContext(SettingsContext);
  const [geoInfo, setGeoInfo] = useState<GeolocationInfo>({
    geoError: false,
    location: null,
  });

  useEffect(() => {
    if (
      geotaggingMode === 'Require on All' ||
      geotaggingMode === 'Require on Punch Ins'
    ) {
      navigator?.geolocation?.getCurrentPosition(
        (position) =>
          setGeoInfo({
            geoError: false,
            location: `${position.coords.latitude}, ${position.coords.longitude}`,
          }),
        () => setGeoInfo({ geoError: true, location: '' })
      );
    }
    if (geotaggingMode === 'Optional') {
      navigator?.geolocation?.getCurrentPosition((position) =>
        setGeoInfo({
          geoError: false,
          location: `${position.coords.latitude}, ${position.coords.longitude}`,
        })
      );
    }
  }, [geotaggingMode]);

  return [geoInfo, setGeoInfo] as const;
};

export default useLocationFormatted;
