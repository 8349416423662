import { Box, SxProps, Theme, Tooltip } from '@mui/material';

import React from 'react';
import TInlineEditingError from '../InlineEditing/TInlineEditingError';

interface FormatterErrorWrapperProps extends TInlineEditingError {
  children: React.ReactNode;
}

const errorSx: SxProps<Theme> = (theme: Theme) => ({
  border: `2px solid ${theme.palette.error.main}`,
  color: theme.palette.error.main,
  padding: '6px',
  boxSizing: 'border-box',
  position: 'absolute',
  top: 0,
  left: 0,
  margin: 0,
  width: '100%',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  height: '100%',
});
const warningSx: SxProps<Theme> = (theme: Theme) => ({
  border: `2px solid ${theme.palette.warning.main}`,
  color: theme.palette.warning.main,
  padding: '6px',
  boxSizing: 'border-box',
  position: 'absolute',
  top: 0,
  left: 0,
  margin: 0,
  width: '100%',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  borderRadius: '2px',
  height: '100%',
});

const tooltipSx: SxProps<Theme> = {
  '& .MuiTooltip-tooltip': {
    fontSize: '0.9rem',
  },
};

const FormatterErrorWrapper = (props: FormatterErrorWrapperProps) => {
  const { errorIs, children, errorText } = props;

  return (
    <Tooltip title={errorText} arrow PopperProps={{ sx: tooltipSx }}>
      <Box sx={errorIs === true ? errorSx : warningSx}>{children}</Box>
    </Tooltip>
  );
};

export default FormatterErrorWrapper;
