import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { SxProps, Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { SettingsContext } from '@teto/react-component-library';
import dayjs, { Dayjs } from 'dayjs';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface WeekDateDisplayProps {
  startDate: Dayjs;
  selectedDate?: Dayjs;
  // eslint-disable-next-line no-unused-vars
  handleSelectedDate: (d: Dayjs) => void;
}

const tsListDaysSx: SxProps<Theme> = {
  display: 'flex',
  padding: (theme) => theme.spacing(0),
  paddingBottom: (theme) => theme.spacing(0.5),
};

const tsDaySx: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  paddingTop: 0,
};

const dateNumSx: SxProps<Theme> = {
  marginTop: (theme) => theme.spacing(1),
  minHeight: (theme) => theme.spacing(3),
  minWidth: (theme) => theme.spacing(3),
  textAlign: 'center',
};

const daySelectedSx: SxProps<Theme> = {
  backgroundColor: (theme) => theme.palette.primary.dark,
  borderRadius: (theme) => `${theme.shape.borderRadius}px`,
  color: (theme) => theme.palette.getContrastText(theme.palette.primary.dark),
  padding: (theme) => `${theme.spacing(0.5)} ${theme.spacing(1)}`,
  position: 'absolute',
  bottom: (theme) => theme.spacing(1),
};

const WeekDateDisplay = (props: WeekDateDisplayProps) => {
  const { selectedDate, startDate, handleSelectedDate } = props;
  const {
    settings: { startDayOfWeek },
  } = useContext(SettingsContext);
  const { t } = useTranslation();

  const createDates = useMemo(() => {
    const dates = [];
    for (let i = 0; i < 7; i += 1) {
      dates.push({
        day: t(
          `Entities.TimeSheet.${
            i + startDayOfWeek > 6 ? startDayOfWeek + i - 7 : i + startDayOfWeek
          }`
        ),
        date: dayjs(startDate).add(i, 'day'),
      });
    }
    return dates;
  }, [startDate, startDayOfWeek, t]);

  const _handleDayClicked = (date: Dayjs) => {
    handleSelectedDate(date);
  };

  return (
    <List sx={tsListDaysSx}>
      {createDates?.map((d) => (
        <ListItem
          key={`${d.date}-calendarItem`}
          sx={tsDaySx}
          button
          onClick={() => _handleDayClicked(d.date)}
          disableGutters
        >
          <Typography variant="subtitle2" color="textSecondary">
            {d.day}
          </Typography>
          <Typography
            color="textPrimary"
            variant="subtitle2"
            sx={[
              dateNumSx,
              ...(dayjs(selectedDate)?.isSame(dayjs(d.date))
                ? [daySelectedSx]
                : []),
            ]}
          >
            {d.date.format('D')}
          </Typography>
        </ListItem>
      ))}
    </List>
  );
};

export default WeekDateDisplay;
