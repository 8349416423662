import { Box, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useLocation } from 'react-router-dom';
import getPageArticleNumbers from '../../../components/HelpLinks/helpers/getPageArticleNumber';
import InlineHelpLink from '../../../components/HelpLinks/InlineHelpLink/InlineHelpLink';
import useCommonStyles from './commonStyles';

type TProps = { title: string; subSectionId: string; inlineTitle: string };

const SectionHeader = (props: TProps) => {
  const { title, inlineTitle, subSectionId } = props;
  const theme = useTheme();
  const commonStyles = useCommonStyles(theme);
  const location = useLocation();
  const pageNum = getPageArticleNumbers(location.pathname);
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Typography sx={commonStyles.subSectionTitle} variant="h6">
        {title}
      </Typography>
      <InlineHelpLink
        helpId={pageNum}
        subSectionId={subSectionId}
        title={inlineTitle}
      />
    </Box>
  );
};

export default SectionHeader;
