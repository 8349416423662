import dayjs from 'dayjs';
import { TimeCard } from 'teto-client-api';
import { groupRecords } from './groupHelper';

const dateGrouper = (
  groups: string[],
  startDate: Date,
  timeCards: TimeCard[]
) => {
  const results = [];
  const start = dayjs(startDate);
  // todo - no forloop
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < 7; i++) {
    const day = start.add(i, 'day');

    const records = timeCards.filter((r) => dayjs(r.date).isSame(day.toDate()));
    if (records.length > 0) {
      results.push({
        date: day.toDate(),
        groups: groupRecords(groups, records),
        // eslint-disable-next-line no-return-assign
        totalHours: records.reduce((cum, rec) => cum + rec.hourTime, 0), // calculate totals, use reduce statement
      });
    } else {
      // insert blank date to results
      results.push({
        date: day.toDate(),
        groups: [],
        totalHours: 0,
      });
    }
  }

  return results;
};

export default dateGrouper;
