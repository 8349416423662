/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Autocomplete,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  List,
  TextField,
  Typography,
} from '@mui/material';
import { SxProps, useTheme } from '@mui/material/styles';
import {
  DayOfWeekFormat,
  ETOButton,
  ETOCheckBox,
  ETOSelectField,
  GeotaggingMode,
  geotaggingOptions,
  MessageContext,
  SettingsContext,
} from '@teto/react-component-library';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { getSystemSettings, SystemSettings } from 'teto-client-api';
import getPageArticleNumbers from '../../../components/HelpLinks/helpers/getPageArticleNumber';
import InlineHelpLink from '../../../components/HelpLinks/InlineHelpLink/InlineHelpLink';
import useCommonStyles from './commonStyles';
import {
  dateFormats,
  timeFormats,
  timePreciseFormats,
} from './datetimeFormats';
import SectionHeader from './SectionHeader';
import TabItem from './TabItem';

const selectFieldSx: SxProps = { minWidth: 300 };

const dayOfWeekFormats = () => {
  const newArr = [];

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < 7; i++) {
    newArr.push({
      value: i,
      display: `${dayjs().day(i).format('dddd')}`,
    });
  }
  return newArr;
};

type ValidationErrors = {
  // eslint-disable-next-line no-unused-vars
  [key in keyof SystemSettings | string]: string;
};

const geotaggingModeValues = geotaggingOptions.map((i) => ({
  value: i,
  display: i,
}));

const DialogText = () => (
  <>
    Changing start day of week could have significant impact on <b>Timesheet</b>{' '}
    data. Are you sure you want to change start day of week?
  </>
);

const punchInModes = ['Nearest', 'RoundUp', 'RoundDown', 'None'];
const punchInModeValues = punchInModes.map((i) => ({
  value: i,
  display: i,
}));

const SystemTab = () => {
  const { t } = useTranslation();
  const settingsContext = useContext(SettingsContext);
  const messageContext = useContext(MessageContext);
  const theme = useTheme();
  const commonStyles = useCommonStyles(theme);

  const location = useLocation();
  const pageNum = getPageArticleNumbers(location.pathname);
  const [errors, setErrors] = useState<Partial<ValidationErrors>>({});

  const [startDayAlert, setStartDayAlert] = useState<boolean>(false);
  const punchInValues = ['0', '1', '3', '5', '6', '10', '15', '30'];
  const [dayofWeek, setDayofWeek] = useState<DayOfWeekFormat>(0);
  const [weekDay, setWeekDay] = useState<string>('0');
  const [punchInUseRounded, setPunchInUseRounded] = useState<boolean>(false);
  const [punchInStartRounding, setPunchInStartRounding] = useState<number>(0);
  const [punchInEndRounding, setPunchInEndRounding] = useState<number>(0);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [punchInStartMode, setPunchInStartMode] = useState<any>('Nearest');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [punchInEndMode, setPunchInEndMode] = useState<any>('Nearest');
  const [dateSetting, setDateSetting] = useState<string>(
    settingsContext.settings.dateFormat
  );
  const [timeSetting, setTimeSetting] = useState<string>(
    settingsContext.settings.timeFormat
  );
  const [timeFormatPreciseSetting, setTimeFormatPreciseSetting] =
    useState<string>(settingsContext.settings.timeFormatPrecise);
  const [autoCreatePayPeriodSetting, setAutoCreatePayPeriodSetting] =
    useState<boolean>(settingsContext.settings.payPeriodsCreateAutomatically);
  const [geotaggingModeSettings, setGeotaggingModeSettings] = useState<
    GeotaggingMode | undefined
  >((settingsContext.settings.geotaggingMode as GeotaggingMode) ?? 'Optional');
  const [teHideHourFactorFieldSetting, setTEHideHourFactorField] = useState(
    settingsContext.settings.enableHourFactorInMyTimecards
  );
  const [approversCanManageTimeSetting, setApproversCanManageTimeSetting] =
    useState<boolean>(false);

  useEffect(() => {
    getSystemSettings()
      .then((settings) => {
        setDayofWeek(settings.timeSheetStartDayOfWeek as DayOfWeekFormat);
        setPunchInUseRounded(settings.punchInUseRoundedHours);
        setPunchInStartRounding(settings.punchInStartRoundingInterval);
        setPunchInEndRounding(settings.punchInEndRoundingInterval);
        setPunchInStartMode(settings.punchInStartRoundingMode);
        setPunchInEndMode(settings.punchInEndRoundingMode);
        setDateSetting(settings.webDateFormat);
        setTimeSetting(settings.webTimeFormat);
        setTimeFormatPreciseSetting(settings.webTimeFormatPrecise);
        setAutoCreatePayPeriodSetting(settings.payPeriodsCreateAutomatically);
        setGeotaggingModeSettings(settings.geotaggingMode as GeotaggingMode);
        setTEHideHourFactorField(settings.teHideHourFactorField);
        setApproversCanManageTimeSetting(settings.approversCanManageTime);
      })
      .catch((e) => {
        messageContext.setError(e.message);
      });
  }, [messageContext]);

  const _handleDayOfWeekChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setStartDayAlert((d) => !d);
    setWeekDay(e.target.value);
  };

  const _handleSettingsChanged = () => {
    setStartDayAlert((d) => !d);
    setDayofWeek(parseInt(weekDay, 10) as DayOfWeekFormat);
  };

  const _handleSettingsSave = () => {
    setErrors({});
    settingsContext
      .updateSystemSettings({
        timeSheetStartDayOfWeek: dayofWeek,
        punchInStartRoundingInterval: punchInStartRounding,
        punchInEndRoundingInterval: punchInEndRounding,
        punchInStartRoundingMode: punchInStartMode,
        punchInEndRoundingMode: punchInEndMode,
        punchInUseRoundedHours: punchInUseRounded,
        webDateFormat: dateSetting,
        webTimeFormat: timeSetting,
        webTimeFormatPrecise: timeFormatPreciseSetting,
        teHideHourFactorField: teHideHourFactorFieldSetting,
        payPeriodsCreateAutomatically: autoCreatePayPeriodSetting,
        geotaggingMode: geotaggingModeSettings,
        approversCanManageTime: approversCanManageTimeSetting,
      })
      .then(() => {
        messageContext.setSuccess(t('generic.message.settingsUpdate'));
      })
      .catch((e) => {
        if (e.errors?.errors) {
          const output: Partial<ValidationErrors> = {};
          Object.keys(e.errors.errors).forEach((a: any) => {
            // eslint-disable-next-line prefer-destructuring
            output[a] = e.errors.errors[a][0];
          });
          setErrors(output);
        } else {
          messageContext.setError(e.message);
        }
      });
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'auto',
        }}
      >
        <Typography sx={commonStyles.sectionTitle} variant="h6">
          {t('settings.systemSettings')}
        </Typography>
        <Divider
          sx={{ marginRight: theme.spacing(2), marginTop: theme.spacing(0.5) }}
        />

        <List>
          <SectionHeader
            title={t('settings.timecardSettings')}
            subSectionId="timesheet-start-of-the-week-0-3"
            inlineTitle={t('settings.timecardSettings')}
          />
          <TabItem
            settingsName={t('generic.geotaggingMode')}
            inlineHelp={{
              title: t('generic.geotaggingMode'),
              helpId: pageNum,
              subSectionId: 'geotagging-mode-0-2',
            }}
            secondary={
              <ETOSelectField
                customSx={selectFieldSx}
                name="geotaggingMode"
                error={errors.geotaggingMode}
                disabled={false}
                value={geotaggingModeSettings}
                items={geotaggingModeValues}
                defaultValue={0}
                handleChange={(e) =>
                  setGeotaggingModeSettings(e.target.value as GeotaggingMode)
                }
                itemNameSelector={(i) => i.display}
                itemValueSelector={(i) => i.value}
                disableClearable
                onKeyDown={(e) => e.preventDefault()}
              />
            }
          />

          <TabItem
            settingsName={`Timesheet ${t('generic.startDayOfWeek')}`}
            inlineHelp={{
              title: `Timesheet ${t('generic.startDayOfWeek')}`,
              helpId: pageNum,
              subSectionId: 'timesheet-start-of-the-week-0-3',
            }}
            secondary={
              <ETOSelectField
                customSx={selectFieldSx}
                name="startOfWeek"
                error={errors.startOfWeek}
                value={dayofWeek}
                items={dayOfWeekFormats()}
                defaultValue={0}
                handleChange={(e) => _handleDayOfWeekChange(e)}
                itemNameSelector={(i) => i.display}
                itemValueSelector={(i) => i.value}
                disableClearable
                onKeyDown={(e) => e.preventDefault()}
              />
            }
          />
          <TabItem
            inlineHelp={{
              helpId: pageNum,
              subSectionId: 'auto-create-pay-periods-0-5',
              title: t('generic.payPeriod'),
            }}
            settingsName={t('generic.payPeriodConfig')}
            secondary={
              <ETOCheckBox
                name={t('generic.payPeriod')}
                label=""
                value={autoCreatePayPeriodSetting}
                handleChange={() => setAutoCreatePayPeriodSetting((d) => !d)}
                size="medium"
              />
            }
          />
        </List>
        <List>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography sx={commonStyles.subSectionTitle} variant="h6">
              {t('settings.roundingSettings')}
            </Typography>
            <InlineHelpLink
              helpId={getPageArticleNumbers(location.pathname)}
              subSectionId="punch-in-rounding-settings-0-4"
              title={t('Entities.Timecard.roundedHours')}
            />
          </Box>
          <TabItem
            settingsName={t('Entities.PunchIn.useRoundedHours')}
            secondary={
              <ETOCheckBox
                name={t('Entities.PunchIn.useNameRoundedHours')}
                label=""
                value={punchInUseRounded}
                handleChange={() => setPunchInUseRounded((d) => !d)}
                size="medium"
              />
            }
          />

          <TabItem
            settingsName={t('Entities.PunchIn.startRoundedInterval')}
            secondary={
              <ETOSelectField
                customSx={selectFieldSx}
                name="startRoundedInterval"
                error={errors.startRoundedInterval}
                disabled={!punchInUseRounded}
                value={punchInStartRounding}
                items={punchInValues}
                defaultValue={0}
                handleChange={(e) =>
                  setPunchInStartRounding(parseInt(e.target.value, 10))
                }
                itemNameSelector={(i) => i}
                itemValueSelector={(i) => parseInt(i, 10)}
                disableClearable
                onKeyDown={(e) => e.preventDefault()}
              />
            }
          />
          <TabItem
            settingsName={t('Entities.PunchIn.endRoundedInterval')}
            secondary={
              <ETOSelectField
                customSx={selectFieldSx}
                name="endRoundedInterval"
                error={errors.endRoundedInterval}
                disabled={!punchInUseRounded}
                value={punchInEndRounding}
                items={punchInValues}
                defaultValue={0}
                handleChange={(e) =>
                  setPunchInEndRounding(parseInt(e.target.value, 10))
                }
                itemNameSelector={(i) => i}
                itemValueSelector={(i) => parseInt(i, 10)}
                disableClearable
                onKeyDown={(e) => e.preventDefault()}
              />
            }
          />
          <TabItem
            settingsName={t('Entities.PunchIn.startRoundedMode')}
            secondary={
              <ETOSelectField
                customSx={selectFieldSx}
                name="startRoundedMode"
                error={errors.punchInStartRoundingMode}
                disabled={!punchInUseRounded}
                value={punchInStartMode}
                items={punchInModeValues}
                defaultValue={0}
                handleChange={(e) => setPunchInStartMode(e.target.value)}
                itemNameSelector={(i) => i.display}
                itemValueSelector={(i) => i.value}
                disableClearable
                onKeyDown={(e) => e.preventDefault()}
              />
            }
          />
          <TabItem
            settingsName={t('Entities.PunchIn.endRoundedMode')}
            secondary={
              <ETOSelectField
                customSx={selectFieldSx}
                name="endRoundedMode"
                error={errors.punchInEndRoundingMode}
                disabled={!punchInUseRounded}
                value={punchInEndMode}
                items={punchInModeValues}
                defaultValue={0}
                handleChange={(e) => setPunchInEndMode(e.target.value)}
                itemNameSelector={(i) => i.display}
                itemValueSelector={(i) => i.value}
                disableClearable
                onKeyDown={(e) => e.preventDefault()}
              />
            }
          />
        </List>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography sx={commonStyles.subSectionTitle} variant="h6">
            {t('settings.webFormatSettings')}
          </Typography>
          <InlineHelpLink
            helpId={pageNum}
            subSectionId="date-and-time-settings-0-6"
            title={t('generic.webFormat')}
          />
        </Box>
        <List>
          <TabItem
            settingsName={t('generic.dateFormat')}
            secondary={
              <Autocomplete
                id="date-format-selection"
                options={dateFormats}
                style={{ width: 300 }}
                disableClearable
                onChange={(_e, val) => setDateSetting(val)}
                value={dateSetting}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                      onKeyDown(event) {
                        event.preventDefault();
                      },
                      onChange(event) {
                        setDateSetting((event.target as any).value);
                        return (params.inputProps as any).onChange(event);
                      },
                    }}
                  />
                )}
              />
            }
          />
          <TabItem
            settingsName={t('generic.timeFormat')}
            secondary={
              <Autocomplete
                id="time-format-selection"
                options={timeFormats}
                style={{ width: 300 }}
                disableClearable
                onChange={(_e, val) => setTimeSetting(val)}
                value={timeSetting}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                      onKeyDown(event) {
                        event.preventDefault();
                      },
                      onChange(event) {
                        setTimeSetting((event.target as any).value);
                        return (params.inputProps as any).onChange(event);
                      },
                    }}
                  />
                )}
              />
            }
          />
          <TabItem
            settingsName={t('generic.preciseTimeFormat')}
            secondary={
              <Autocomplete
                id="timeprecise-format-selection"
                options={timePreciseFormats}
                style={{ width: 300 }}
                disableClearable
                onChange={(_e, val) => setTimeFormatPreciseSetting(val)}
                value={timeFormatPreciseSetting}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                      onKeyDown(event) {
                        event.preventDefault();
                      },
                      onChange(event) {
                        setTimeFormatPreciseSetting(
                          (event.target as any).value
                        );
                        return (params.inputProps as any).onChange(event);
                      },
                    }}
                  />
                )}
              />
            }
          />
        </List>

        <SectionHeader
          title={t('settings.permissionSettings')}
          subSectionId="approvers-timecard-management-0-7"
          inlineTitle={t('settings.permissionSettings')}
        />

        <List>
          <TabItem
            inlineHelp={{
              helpId: pageNum,
              subSectionId: 'approvers-timecard-management-0-7',
              title: t('generic.approversCanManageTime'),
            }}
            settingsName={t('generic.approversCanManageTime')}
            secondary={
              <ETOCheckBox
                name={t('generic.approversCanManageTime')}
                label=""
                value={approversCanManageTimeSetting}
                handleChange={() => setApproversCanManageTimeSetting((d) => !d)}
                size="medium"
              />
            }
          />
          <TabItem
            inlineHelp={{
              helpId: pageNum,
              subSectionId: 'hide-hour-factor-0-8',
              title: t('settings.hideHourFactor'),
            }}
            settingsName={t('settings.hideHourFactor')}
            secondary={
              <ETOCheckBox
                // disabled
                name={t('settings.hideHourFactor')}
                label=""
                value={teHideHourFactorFieldSetting}
                handleChange={() => setTEHideHourFactorField((d) => !d)}
                size="medium"
              />
            }
          />
        </List>
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          flexGrow: 0,
          flex: '0 0 60px',
          paddingTop: theme.spacing(2),
        }}
      >
        <ETOButton
          color="primary"
          size="medium"
          onClick={() => _handleSettingsSave()}
          customSx={commonStyles.saveBtn}
        >
          {t('generic.save')}
        </ETOButton>
      </Box>

      {startDayAlert && (
        <Dialog
          open={startDayAlert}
          onClose={() => setStartDayAlert((d) => !d)}
        >
          <DialogTitle>{t('generic.startDayOfWeekTitle')}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <DialogText />
            </DialogContentText>
            <DialogActions>
              <ETOButton
                onClick={() => {
                  setStartDayAlert(false);
                }}
                color="secondary"
                size="medium"
              >
                {t('generic.cancel')}
              </ETOButton>
              <ETOButton
                onClick={() => {
                  _handleSettingsChanged();
                }}
                color="primary"
                size="medium"
              >
                {t('generic.yes')}
              </ETOButton>
            </DialogActions>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default SystemTab;
