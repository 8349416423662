import {
  Box,
  Card,
  CardContent,
  Divider,
  Paper,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import AccessTimeRounded from '@mui/icons-material/AccessTimeRounded';
import SupervisorAccountRounded from '@mui/icons-material/SupervisorAccountRounded';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface HomeCardProps {
  header?: React.ReactNode;
  icon: React.ReactNode;
  children?: React.ReactNode;
}

const homePageSx = (theme: Theme) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(4),
  justifyContent: 'space-between',
  padding: theme.spacing(2),
  paddingBottom: theme.spacing(3),
  overflowY: 'auto',
  [theme.breakpoints.up('sm')]: {
    justifyContent: 'space-evenly',
  },
});

const cardGroupSx = (theme: Theme) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  gap: theme.spacing(4),
});

const cardSx = (theme: Theme) => ({
  backgroundImage: 'none',
  width: '80%',
  boxShadow: theme.shadows[0],
  [theme.breakpoints.up('sm')]: {
    maxWidth: theme.spacing(43),
  },
});

const cardHeaderSx = (theme: Theme) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',

  '& .MuiSvgIcon-root': {
    width: theme.spacing(4),
    height: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(6),
      height: theme.spacing(6),
    },
  },
});

const homeIconSx = (theme: Theme) => ({
  color: theme.palette.grey[500],
});

const HomeCard = (props: HomeCardProps) => {
  const { children, header, icon } = props;
  return (
    <Card sx={cardSx}>
      <CardContent sx={cardHeaderSx}>
        {icon}
        <Divider variant="middle" />
        <Typography variant="h6" color="textSecondary">
          {header}
        </Typography>
      </CardContent>
      <CardContent>{children}</CardContent>
    </Card>
  );
};

const HomePage = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const largeDevice = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Paper sx={homePageSx} elevation={0}>
      <Typography
        variant={largeDevice ? 'h4' : 'h3'}
        align="center"
        color="primary"
      >
        Welcome to Total ETO
      </Typography>

      <Typography variant="h6" component="p" align="center">
        {t('homepage.topQuote')}
        <br />
        {t('homepage.bottomQuote')}
      </Typography>

      <Box sx={cardGroupSx}>
        <HomeCard
          icon={<AccessTimeRounded sx={homeIconSx} />}
          header={t('homepage.card1.header')}
        >
          <Typography align="center">{t('homepage.card1.content')}</Typography>
        </HomeCard>

        <HomeCard
          icon={<SupervisorAccountRounded sx={homeIconSx} />}
          header={t('homepage.card2.header')}
        >
          <Typography align="center">{t('homepage.card2.content')}</Typography>
        </HomeCard>
      </Box>
    </Paper>
  );
};

export default HomePage;
