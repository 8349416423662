/* eslint-disable @typescript-eslint/no-explicit-any */
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { Alert, Snackbar } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {
  AppearanceProvider,
  AuthContext,
  AuthProvider,
  LoadingPage,
  LoginPage,
  MessageContext,
  MessageProvider,
  NavProvider,
  SettingsProvider,
} from '@teto/react-component-library';
import React, { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import {
  AuthorizationError,
  LicenseError,
  login,
  Permission,
} from 'teto-client-api';
import AuthenticatedRoutes from './AuthenticatedRoutes';
import SessionTimeout from './components/SessionTimeout/SessionTimeout';
import { ClockInTimerProvider } from './components/TimeSheets/NavTimer/ClockInContext';
import { PunchInTimerProvider } from './components/TimeSheets/NavTimer/PunchInTimerContext';
import ChangeExpiredPasswordPage from './pages/ChangeExpiredPasswordPage/ChangeExpiredPasswordPage';
import AdminPermissionErrorPage from './pages/ErrorPages/AdminPermissionErrorPage';
import { reactPlugin } from './TelemetryService';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const adminPermissions = Object.keys(Permission)
  .filter((a) => a.indexOf('_MyTimeTracker_') < 0 && a.indexOf('_Client_') < 0)
  .map((a) => (Permission as any)[a]);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const clientPermissions = Object.keys(Permission)
  .filter(
    (a) => a.indexOf('_MyTimeTracker_') >= 0 || a.indexOf('_Client_') >= 0
  )
  .map((a) => (Permission as any)[a]);

const queryClient = new QueryClient();

const AppWrapper = () => (
  <BrowserRouter>
    <AppInsightsContext.Provider value={reactPlugin}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <AppearanceProvider>
          <AuthProvider>
            <MessageProvider>
              {/* <ClockInTimerProvider>
                <PunchInTimerProvider> */}
              <NavProvider>
                <QueryClientProvider client={queryClient}>
                  <App />
                </QueryClientProvider>
              </NavProvider>
              {/* </PunchInTimerProvider>
              </ClockInTimerProvider> */}
            </MessageProvider>
          </AuthProvider>
        </AppearanceProvider>
      </LocalizationProvider>
    </AppInsightsContext.Provider>
  </BrowserRouter>
);

const App = () => {
  const authContext = useContext(AuthContext);
  const messageContext = useContext(MessageContext);
  const { t } = useTranslation('main');
  const [passwordChangeRequired, setPasswordChangeRequired] = useState(false);
  const [lastUserName, setLastUserName] = useState('');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const _doLogin = (userName: string, password: string) =>
    login({ password, userName })
      .then(() => {
        window.location.reload();
      })
      .catch((e: AuthorizationError | LicenseError | any) => {
        if (
          e.message === 'Authorization Error' ||
          e.message === 'License Error'
        ) {
          if (e.reason === 1) {
            setLastUserName(userName);
            setPasswordChangeRequired(true);
          } else {
            messageContext.setError(
              t(`auth.authError${(e as AuthorizationError).reason}`)
            );
          }
        } else {
          messageContext.setError(e.message);
        }
      });

  const renderView = useCallback(() => {
    if (!authContext.isLoaded) {
      return <LoadingPage />;
    }

    if (!authContext.authenticated) {
      if (passwordChangeRequired) {
        return (
          <ChangeExpiredPasswordPage
            onPasswordChanged={() => setPasswordChangeRequired(false)}
            userName={lastUserName}
          />
        );
      }

      return <LoginPage appTitle="" links={[]} onLogin={_doLogin} />;
    }

    if (
      !(
        authContext.hasAnyPermission(adminPermissions) ||
        authContext.hasAnyPermission(clientPermissions)
      )
    ) {
      return <AdminPermissionErrorPage />;
    }

    return (
      <SettingsProvider>
        <ClockInTimerProvider>
          <PunchInTimerProvider>
            <AuthenticatedRoutes />
          </PunchInTimerProvider>
        </ClockInTimerProvider>
      </SettingsProvider>
    );
  }, [authContext, passwordChangeRequired, _doLogin, lastUserName]);

  return (
    <>
      {renderView()}
      <Snackbar
        open={Boolean(messageContext.message)}
        autoHideDuration={3500}
        onClose={() => messageContext.clearMessage()}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        {messageContext.message ? (
          <Alert
            onClose={() => messageContext.clearMessage()}
            severity={messageContext.messageType}
            data-testid="message-display"
            variant="filled"
            sx={{ fontFamily: 'inherit' }}
          >
            {messageContext.message}
          </Alert>
        ) : undefined}
      </Snackbar>
      <SessionTimeout open={authContext.sessionTimeout} />
    </>
  );
};

export default AppWrapper;
