/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * If the fieldsDisableEditable is a boolean, return it. Otherwise, return the " value " of the " fieldName " - in the fieldsDisableEditable object
 * @param fieldName - The name of the field that we want to check if it's disabled.
 * @param {FieldsDisableEditableType} fieldsDisableEditable - FieldsDisableEditableType
 * @returns A boolean value.
 */

const isDisabled = (
  canAffect: boolean,
  // eslint-disable-next-line no-unused-vars
  fieldName: keyof { [key in any]: boolean },
  // eslint-disable-next-line no-unused-vars
  fieldsDisableEditable: boolean | { [key in any]: boolean }
) => {
  if (!canAffect) {
    return true;
  }

  if (typeof fieldsDisableEditable === 'boolean') {
    return fieldsDisableEditable;
  }

  return fieldsDisableEditable[fieldName];
};

export default isDisabled;
