/* eslint-disable @typescript-eslint/no-explicit-any */
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import FormatColorTextRoundedIcon from '@mui/icons-material/FormatColorTextRounded';
import LanIcon from '@mui/icons-material/Lan';
import MoveToInboxRoundedIcon from '@mui/icons-material/MoveToInboxRounded';
import PrintRoundedIcon from '@mui/icons-material/PrintRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import TuneRoundedIcon from '@mui/icons-material/TuneRounded';
import {
  Box,
  Dialog,
  LinearProgress,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  ActionBar,
  ActionButton,
  AuthContext,
  ETOButton,
  MessageContext,
} from '@teto/react-component-library';
import dayjs from 'dayjs';
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { QueryFunctionContext, QueryKey, useQuery } from 'react-query';
import {
  Licenses,
  ModelMetadata,
  PagedResponse,
  Permission,
} from 'teto-client-api';
import useLocalStorage from 'use-local-storage';
import ColumnAlignments from '../../components/TetoGrid/ColumnAlignment/ColumnAlignments';

import DefaultDateEditor from '../../components/TetoGrid/InlineEditing/DefaultEditors/DefaultDateEditor';
import DefaultBooleanEditor from '../../components/TetoGrid/InlineEditing/DefaultEditors/DefaultForeignKeyEditor';
import DefaultNumberEditor from '../../components/TetoGrid/InlineEditing/DefaultEditors/DefaultNumberEditor';
import DefaultStringEditor from '../../components/TetoGrid/InlineEditing/DefaultEditors/DefaultStringEditor';
import { EditInfo } from '../../components/TetoGrid/InlineEditing/IEditInfo';
import TInlineEditingError from '../../components/TetoGrid/InlineEditing/TInlineEditingError';
import {
  AddColumn,
  createColumnsFromMetaData,
} from '../../components/TetoGrid/ModelMetaDataProcessor/ModelMetaDataProcessor';
import ReactDataGridRefType from '../../components/TetoGrid/ReactDataGridRefType';
import TetoContainer from '../../components/TetoGrid/TetoContainer';
import TetoGrid from '../../components/TetoGrid/TetoGrid';
import TetoGridRefType from '../../components/TetoGrid/TetoGridRefType';
import { getGraphQLClient } from '../../helpers/graphQL/graphQLClient';
import useSessionStorage from '../../helpers/useSessionStorage';
import { processTimecardForeignKey } from '../TimeCardsPage/InlineEditing/foreignKeyPartnerLookup';
import DistributionInspector from './components/DistributionInspector';
import MoreOptionsPanel from './components/MoreOptionsPanel/MoreOptionsPanel';
import PartDetailsInspector from './components/PartDetailsInspector';
import PONumber from './components/PONumber';
import POSearch from './components/POSearch/POSearch';
import POSummary from './components/POSummary/POSummary';
import POValidation from './components/POValidation';
import { PurchaseOrder, PurchaseOrderDetail } from './components/PurchaseOrder';
import { ReceivePOSettings } from './components/ReceivePOSettings';
import ReceivingDialog from './components/ReceivingDialog/ReceivingDialog';
import exportReceivingReportQuery from './queries/exportReceivingReportQuery';

const tableWrapSx = (theme: Theme) => ({
  '& .InovuaReactDataGrid__column-header__filter-wrapper': {
    borderTop: 'none',
    padding: `${theme.spacing(0)} !important`,
  },
});

const receiveBtnSx = (theme: Theme) => ({
  display: 'flex',
  flexGrow: 1,
  justifyContent: 'flex-end',
  position: 'absolute',
  right: theme.spacing(2),
  top: theme.spacing(4),
  [theme.breakpoints.down('md')]: {
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
});

interface ReceivePurchaseOrderDetailInput {
  custom1?: string;
  custom2?: string;
  custom3?: number;
  custom4?: number;
  custom5?: Date;
  custom6?: Date;
  custom7?: boolean;
  custom8?: boolean;
  itemId: number;
  numberOfLabels: number;
  toReceive: number;
}

const ReceivingPage = () => {
  const { t, ready } = useTranslation();
  const TABLE_ID = t('Entities.Receiving.tableIdentifier');
  const theme = useTheme();
  const isReceivingMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const showSecondaryReceiveButton = useMediaQuery(
    theme.breakpoints.down('md')
  );

  const authContext = React.useContext(AuthContext);
  const messageContext = React.useContext(MessageContext);

  const [configureInspector, setConfigureInspector] = useState<boolean>(false);
  const [refreshToken, setRefreshToken] = useState<Date | undefined>();
  const [metaData, setMetaData] = useState<ModelMetadata | undefined>();
  const [reactDataGridRef, setReactDataGridRef] =
    useState<ReactDataGridRefType>();
  const [distributionInspector, setDistributionInspector] =
    useState<boolean>(false);
  const [selectedPOPart, setSelectedPOPart] =
    useState<PurchaseOrderDetail | undefined>();
  const [distribution, setDistribution] =
    useState<PurchaseOrderDetail | undefined>();

  const gridRef = React.useRef<TetoGridRefType | undefined>();
  const [hideFullyReceived, setHideFullyReceived] = useLocalStorage<boolean>(
    'hide-fully-received',
    false
  );
  const [defaultLabelQty, setDefaultLabelQty] = useLocalStorage<
    '1' | 'None' | 'Received Quantities'
  >('default-label-qty', '1');
  const [purchaseOrder, setPurchaseOrder] = useSessionStorage(
    'purchase-order',
    undefined
  );
  const [dataSource, setDataSource] = useState(
    Array.isArray(purchaseOrder) ? purchaseOrder : []
  );

  const [receivingValues, setReceivingValues] = useState<any>();

  const [lastActivePO, setLastActivePO] = useLocalStorage(
    'active-purchase-order',
    undefined
  );
  const [isReceivingDialogOpen, setIsReceivingDialogOpen] = useState(false);
  const [isReportLoading, setIsReportLoading] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  useEffect(() => {
    if (purchaseOrder) setLastActivePO(purchaseOrder);
  }, [purchaseOrder, setLastActivePO]);

  const _handleDistribution = (data: PurchaseOrderDetail) => {
    setDistributionInspector(true);
    setDistribution(data);
  };

  const query = `query getMetadata($typeName: String!) {
    __type(name: $typeName) {
      fields {
        name
        order
        objectType
        title
        isPrimaryKey
        sortable
        filterType
        filterOptions
        defaultVisible
        defaultWidth
        columnGrouping
        groupingAggregate
      }
    }
  }`;

  const variables = useMemo(
    () => ({
      typeName: 'PurchaseOrderDetail',
    }),
    []
  );

  useEffect(() => {
    getGraphQLClient()
      .performQueryAsColumnMetadata(
        query,
        variables,
        // eslint-disable-next-line no-unused-vars
        (_err) => ({}),
        // eslint-disable-next-line no-unused-vars
        (_err) => ({})
      )
      .then((result) => {
        setMetaData(result);
      });
  }, [query, variables]);

  // Hacky, highly questionable temporary solution
  useEffect(() => {
    setHideFullyReceived(false);
  }, [purchaseOrder, setHideFullyReceived]);

  const checkInlineEditingCondition = useCallback(
    (
      value: string,
      data: {
        purchaseQty: number | undefined;
        status: { quantityReceived: number | undefined };
      }
    ): TInlineEditingError => {
      const notAEmptyString = value !== '';
      const isBiggerThanOrZero = parseInt(value, 10) >= 0;
      const purchaseQty = data?.purchaseQty ?? 0;
      const quantityReceived = data?.status?.quantityReceived ?? 0;
      const calculatedValue = purchaseQty - quantityReceived;
      const purchaseQtyExceeds = parseInt(value, 10) > calculatedValue;
      if (
        parseInt(value, 10) !== 0 &&
        notAEmptyString &&
        isBiggerThanOrZero &&
        purchaseQtyExceeds
      ) {
        return {
          errorIs: 'warning',
          errorText: t('Entities.Receiving.overReceived'),
        };
      }
      return { errorIs: false, errorText: '' };
    },
    [t]
  );

  const cols = React.useMemo(() => {
    const createCustomField = (
      name: string,
      title: string,
      type: string,
      align: ColumnAlignments,
      filterType: string,
      order: number,
      editor: any
    ) =>
      ({
        name,
        type,
        defaultVisible: false,
        hidden: true,
        sortable: false,
        title,
        align,
        filterType,
        fixed: 'none',
        editable: authContext.hasLicense(Licenses.TotalETOProfessional),
        width: 200,
        order,
        emptyDisplay: '',
        editor,
        renderFunction: (data) => <span>{data[name]}</span>,
      } as AddColumn);

    if (metaData && ready) {
      return createColumnsFromMetaData<PurchaseOrderDetail>(metaData)
        .removeProperty('externalUOMId')
        .removeProperty('id')
        .removeProperty('itemId')
        .removeProperty('destInventoryLocId')
        .removeProperty('nonConformanceId')
        .removeProperty('processScheduleId')
        .removeProperty('processScheduleDetailId')
        .removeProperty('purchaseOrderId')
        .updateDefinition('purchaseQty', {
          editable: false,
          order: 2,
          width: 130,
          filterOptions: undefined,
          renderGroupTitle: (value) => <span>{value}</span>,
        })
        .updateDefinition('purchasePrice', {
          editable: false,
          renderGroupTitle: (value) => <span>{value}</span>,
          render: (data) => (
            <span data-testid="currency">${data.value.toFixed(2)}</span>
          ),
          renderFunction: (data) => (
            <span data-testid="currency">${data.value.toFixed(2)}</span>
          ),
        })
        .updateDefinition('purchaseSupplierItem', {
          editable: false,
          width: 300,
          header: t('Entities.Receiving.poPartNumber'),
          title: t('Entities.Receiving.poPartNumber'),
        })
        .updateDefinition('purchaseSupplierDescription', {
          editable: false,
          width: 1000,
          header: t('Entities.Receiving.poPartDescription'),
          title: t('Entities.Receiving.poPartDescription'),
        })
        .updateDefinition('specId', {
          editable: false,
          defaultVisible: false,

          renderGroupTitle: (value) => <span>{value}</span>,
          header: `${t('Entities.Machine.Job')} Number`,
          title: `${t('Entities.Machine.Job')} Number`,
        })
        .updateDefinition('projectId', {
          editable: false,
          defaultVisible: false,

          renderGroupTitle: (value) => <span>{value}</span>,
          header: `${t('Entities.Project.Project')} Number`,
          title: `${t('Entities.Project.Project')} Number`,
          // title: t('Entities.Receiving.poPartDescription'),
        })
        .updateDefinition('dateRequired', {
          editable: false,
          renderGroupTitle: (value) => <span>{value}</span>,
        })
        .updateDefinition('dateRevised', {
          editable: false,
          renderGroupTitle: (value) => <span>{value}</span>,
        })
        .updateDefinition('orderNumber', {
          editable: false,
          renderGroupTitle: (value) => <span>{value}</span>,
        })
        .updateDefinition('archived', {
          editable: false,
          renderGroupTitle: (value) => <span>{value}</span>,
        })

        .updateDefinition('externalQty', {
          editable: false,
          renderGroupTitle: (value) => <span>{value}</span>,
        })
        .updateDefinition('custom1', {
          editable: false,
        })
        .updateDefinition('custom2', {
          editable: false,
        })
        .updateDefinition('custom3', {
          editable: false,
        })
        .updateDefinition('custom4', {
          editable: false,
        })
        .updateDefinition('custom5', {
          editable: false,
        })
        .updateDefinition('custom6', {
          editable: false,
        })
        .updateDefinition('custom7', {
          defaultWidth: 160,
          editable: false,
        })
        .updateDefinition('custom8', {
          defaultWidth: 160,
          editable: false,
        })
        .removeProperty(
          'custom1',
          () =>
            t('Entities.PurchaseOrderDetail.PurchaseOrderDetailCustom1') ===
            'Purchase Order Detail Custom 1'
        )
        .removeProperty(
          'custom2',
          () =>
            t('Entities.PurchaseOrderDetail.PurchaseOrderDetailCustom2') ===
            'Purchase Order Detail Custom 2'
        )
        .removeProperty(
          'custom3',
          () =>
            t('Entities.PurchaseOrderDetail.PurchaseOrderDetailCustom3') ===
            'Purchase Order Detail Custom 3'
        )
        .removeProperty(
          'custom4',
          () =>
            t('Entities.PurchaseOrderDetail.PurchaseOrderDetailCustom4') ===
            'Purchase Order Detail Custom 4'
        )
        .removeProperty(
          'custom5',
          () =>
            t('Entities.PurchaseOrderDetail.PurchaseOrderDetailCustom5') ===
            'Purchase Order Detail Custom 5'
        )
        .removeProperty(
          'custom6',
          () =>
            t('Entities.PurchaseOrderDetail.PurchaseOrderDetailCustom6') ===
            'Purchase Order Detail Custom 6'
        )
        .removeProperty(
          'custom7',
          () =>
            t('Entities.PurchaseOrderDetail.PurchaseOrderDetailCustom7') ===
            'Purchase Order Detail Custom 7'
        )
        .removeProperty(
          'custom8',
          () =>
            t('Entities.PurchaseOrderDetail.PurchaseOrderDetailCustom8') ===
            'Purchase Order Detail Custom 8'
        )
        .addColumn({
          name: 'spec.project.displayName',
          type: 'custom',
          defaultVisible: true,
          hidden: false,
          sortable: true,
          title: t('Entities.Project.Project'),
          align: 'start',
          filterType: 'none',
          fixed: 'none',
          editable: false,
          width: 200,
          renderGroupTitle: (value, { data }) => (
            <span>{data.array[0]?.spec?.project?.displayName}</span>
          ),
          renderFunction: (data) => (
            <span>{data.spec?.project?.displayName}</span>
          ),
        })
        .addColumn({
          name: 'spec.displayName',
          type: 'custom',
          defaultVisible: true,
          hidden: false,
          sortable: true,
          title: t('Entities.Machine.Job'),
          align: 'start',
          filterType: 'none',
          fixed: 'none',
          editable: false,
          width: 200,
          renderGroupTitle: (value, { data }) => (
            <span>{data.array[0]?.spec?.displayName}</span>
          ),
          renderFunction: (data) => <span>{data.spec?.displayName}</span>,
        })
        .updateDefinition('spec.projectId', {
          defaultVisible: false,
        })

        .addColumn({
          name: 'item.category.categoryDescription',
          type: 'custom',
          defaultVisible: false,

          sortable: true,
          title: t('Entities.ItemCategory.ItemCategory'),
          align: 'start',
          filterType: 'none',
          fixed: 'none',
          editable: false,
          width: 200,
          renderGroupTitle: (value) => <span>{value}</span>,
          renderFunction: (data) => (
            <span>{data.item?.category?.categoryDescription}</span>
          ),
        })
        .addColumn({
          name: 'processSchedule.number',
          type: 'custom',
          defaultVisible: false,

          sortable: true,
          title: `${t(
            'Entities.ProcessScheduleHeader.ProcessScheduleHeader'
          )} Number`,
          align: 'start',
          filterType: 'none',
          fixed: 'none',
          editable: false,
          width: 200,
          renderGroupTitle: (value) => <span>{value}</span>,
          renderFunction: (data) => (
            <span>{data?.processSchedule?.number}</span>
          ),
        })
        .addColumn({
          name: 'nonConformance.title',
          type: 'custom',
          defaultVisible: false,

          sortable: true,
          title: t('Entities.NonConformance.NonConformance'),
          align: 'start',
          filterType: 'none',
          fixed: 'none',
          editable: false,
          width: 200,
          renderGroupTitle: (value) => <span>{value}</span>,
          renderFunction: (data) => <span>{data.nonConformanceId}</span>,
        })
        .addColumn({
          name: 'destInventoryLoc.name',
          type: 'custom',
          defaultVisible: false,

          sortable: true,
          title: t('Entities.Receiving.inventoryDestination'),
          align: 'start',
          filterType: 'none',
          fixed: 'none',
          editable: false,
          width: 200,
          renderGroupTitle: (value) => <span>{value}</span>,
          renderFunction: (data) => <span>{data.destInventoryLoc?.name}</span>,
        })
        .addColumn({
          name: 'inventoryDetails.inventory.inventoryLocation.name',
          type: 'custom',
          defaultVisible: false,

          sortable: true,
          title: t('Entities.InventoryLocation.InventoryLocation'),
          align: 'start',
          filterType: 'none',
          fixed: 'none',
          editable: false,
          width: 200,
          renderGroupTitle: (value) => <span>{value}</span>,
          renderFunction: (data) => (
            <span>
              {data.inventoryDetails?.inventory?.inventoryLocation?.name}
            </span>
          ),
        })
        .updateDefinition('purchaseUom', {
          type: 'custom',
          align: 'start',
          filterType: 'none',
          fixed: 'none',
          editable: false,
          width: 200,
          renderGroupTitle: (value) => <span>{value}</span>,
          renderFunction: (data) => <span>{data?.purchaseUom}</span>,
        })
        .addColumn({
          name: 'status.quantityReceived',
          type: 'custom',
          defaultVisible: true,
          hidden: false,
          sortable: true,
          title: 'Previously Received',
          align: 'end',
          filterType: 'number',
          fixed: 'none',
          editable: false,
          width: 195,
          renderGroupTitle: (value) => <span>{value}</span>,
          renderFunction: (data) => (
            <span>
              {data?.status === null ? 0 : data?.status?.quantityReceived}
            </span>
          ),
          headerProps: {
            style: {
              textAlign: 'center',
            },
          },
        })
        .updateDefinition('status.quantityReceived', { order: 51 })
        .addColumn({
          name: 'toReceive',
          type: 'number',
          defaultVisible: true,
          hidden: false,
          sortable: true,
          title: 'To Receive',
          align: 'end',
          filterType: 'number',
          fixed: 'none',
          editable: authContext.hasLicense(Licenses.TotalETOProfessional),
          width: 130,
          filterOptions: undefined,
          renderGroupTitle: (value) => <span>{value}</span>,
          inlineEditingCondition: (value, data) => {
            const notAEmptyString = value !== '';
            const isBiggerThanOrZero = parseInt(value, 10) >= 0;
            const purchaseQty = data?.purchaseQty ?? 0;
            const quantityReceived = data?.status?.quantityReceived ?? 0;
            const calculatedValue = purchaseQty - quantityReceived;
            const purchaseQtyExceeds = parseInt(value, 10) > calculatedValue;
            if (
              parseInt(value, 10) !== 0 &&
              notAEmptyString &&
              isBiggerThanOrZero
            ) {
              if (data?.processScheduleDetail) {
                const qtyReceived =
                  data.processScheduleDetail.quantityReceived +
                  parseInt(value, 10);

                const valid =
                  qtyReceived <= data.processScheduleDetail.quantityIssued &&
                  (data.processScheduleDetail.inHouse ||
                    qtyReceived <= data.processScheduleDetail.quantityOrdered);

                if (!valid) {
                  return {
                    errorIs: true,
                    errorText: t('pages.receiving.overReceivedPSD'),
                  };
                }
              } else if (purchaseQtyExceeds) {
                return {
                  errorIs: 'warning',
                  errorText: t('pages.receiving.overReceived'),
                };
              }
            }
            return { errorIs: false, errorText: '' };
          },
        })

        .updateDefinition('toReceive', {
          order: 50,
        })
        .addColumn({
          name: 'numberOfLabels',
          title: '# of Labels',
          type: 'number',
          editable: authContext.hasLicense(Licenses.TotalETOProfessional),
          defaultVisible: true,
          sortable: true,
          align: 'end',
          filterType: 'number',
          fixed: 'none',
          width: 130,
          renderGroupTitle: (value) => <span>{value}</span>,
        })
        .updateDefinition('numberOfLabels', { order: 52 })
        .addColumn({
          name: 'action',
          title: t('generic.action'),
          type: 'button',
          align: 'center',
          sortable: false,
          filterType: 'none',
          fixed: 'right',
          disableGrouping: true,
          disableHideable: true,
          disableColumnMenuTool: true,
          width: isMobile ? 60 : 144,
          editable: false,
          renderFunction: (data: PurchaseOrderDetail) => (
            <ActionButton
              modalProps={{ placement: 'left-end' }}
              actionItems={[
                {
                  componentName: t('Entities.Receiving.distribution'),
                  icon: <LanIcon />,
                  title: t('Entities.Receiving.distribution'),
                  handleClick: () => _handleDistribution(data),
                  color: 'primary',
                },
                {
                  componentName: t('Entities.Receiving.partDetails'),
                  icon: <ArrowForwardIosIcon />,
                  title: t('Entities.Receiving.partDetails'),
                  handleClick: () => setSelectedPOPart(data),
                  color: 'primary',
                },
              ]}
            />
          ),
        })
        .addColumn(
          createCustomField(
            'receivingLogCustom1',
            t('Entities.ReceivingTransaction.ReceiverLogCustom1'),
            'string',
            'start',
            'string',
            400,
            DefaultStringEditor
          )
        )
        .addColumn(
          createCustomField(
            'receivingLogCustom2',
            t('Entities.ReceivingTransaction.ReceiverLogCustom2'),
            'string',
            'start',
            'string',
            401,
            DefaultStringEditor
          )
        )
        .addColumn(
          createCustomField(
            'receivingLogCustom3',
            t('Entities.ReceivingTransaction.ReceiverLogCustom3'),
            'number',
            'start',
            'number',
            402,
            DefaultNumberEditor
          )
        )
        .addColumn(
          createCustomField(
            'receivingLogCustom4',
            t('Entities.ReceivingTransaction.ReceiverLogCustom4'),
            'number',
            'start',
            'number',
            403,
            DefaultNumberEditor
          )
        )
        .addColumn(
          createCustomField(
            'receivingLogCustom5',
            t('Entities.ReceivingTransaction.ReceiverLogCustom5'),
            'date',
            'start',
            'date',
            404,
            DefaultDateEditor
          )
        )
        .addColumn(
          createCustomField(
            'receivingLogCustom6',
            t('Entities.ReceivingTransaction.ReceiverLogCustom6'),
            'date',
            'start',
            'date',
            405,
            DefaultDateEditor
          )
        )
        .addColumn(
          createCustomField(
            'receivingLogCustom7',
            t('Entities.ReceivingTransaction.ReceiverLogCustom7'),
            'boolean',
            'start',
            'boolean',
            406,
            DefaultBooleanEditor
          )
        )
        .addColumn(
          createCustomField(
            'receivingLogCustom8',
            t('Entities.ReceivingTransaction.ReceiverLogCustom8'),
            'boolean',
            'start',
            'boolean',
            407,
            DefaultBooleanEditor
          )
        )
        .removeProperty(
          'receivingLogCustom1',
          () =>
            t('Entities.ReceivingTransaction.ReceiverLogCustom1') ===
            'Receiving Transaction Custom 1'
        )
        .removeProperty(
          'receivingLogCustom2',
          () =>
            t('Entities.ReceivingTransaction.ReceiverLogCustom2') ===
            'Receiving Transaction Custom 2'
        )
        .removeProperty(
          'receivingLogCustom3',
          () =>
            t('Entities.ReceivingTransaction.ReceiverLogCustom3') ===
            'Receiving Transaction Custom 3'
        )
        .removeProperty(
          'receivingLogCustom4',
          () =>
            t('Entities.ReceivingTransaction.ReceiverLogCustom4') ===
            'Receiving Transaction Custom 4'
        )
        .removeProperty(
          'receivingLogCustom5',
          () =>
            t('Entities.ReceivingTransaction.ReceiverLogCustom5') ===
            'Receiving Transaction Custom 5'
        )
        .removeProperty(
          'receivingLogCustom6',
          () =>
            t('Entities.ReceivingTransaction.ReceiverLogCustom6') ===
            'Receiving Transaction Custom 6'
        )
        .removeProperty(
          'receivingLogCustom7',
          () =>
            t('Entities.ReceivingTransaction.ReceiverLogCustom7') ===
            'Receiving Transaction Custom 7'
        )
        .removeProperty(
          'receivingLogCustom8',
          () =>
            t('Entities.ReceivingTransaction.ReceiverLogCustom8') ===
            'Receiving Transaction Custom 8'
        )
        .updateDefinition('action', { order: 1000 })

        .finalize(t);
    }

    return [];
  }, [authContext, isMobile, metaData, ready, t]);

  async function _doQuery(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-unused-vars
    _context: QueryFunctionContext<QueryKey, any>,
    // eslint-disable-next-line no-unused-vars
    _pageSize: number,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-unused-vars
    _queryData: { filters: any; orderBy: any }
  ) {
    return Promise.resolve().then(() => ({
      records: purchaseOrder?.purchaseOrderDetails,
    }));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-unused-vars
  const _onSubmit = (_values: any) =>
    // eslint-disable-next-line no-unused-vars
    Promise.resolve().then((_d) => {
      // console.log(values)
    });

  const calcLabelQuantities = useCallback(
    (receivedVal: number) => {
      switch (defaultLabelQty) {
        case '1':
          return 1;
        case 'None':
          return 0;
        case 'Received Quantities':
          return receivedVal;

        default:
          return 1;
      }
    },
    [defaultLabelQty]
  );

  const _onEditComplete = useCallback(
    (props: EditInfo) => {
      const { value, columnId, cellProps } = props;
      if (purchaseOrder) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const newDataSource: PurchaseOrder[] = dataSource?.map((item: any) => {
          if (item.id === cellProps?.data.id) {
            if (
              columnId === 'toReceive' &&
              (item.numberOfLabels <= 0 || !item.numberOfLabels)
            ) {
              return {
                ...item,
                [columnId]: value,
                numberOfLabels: calcLabelQuantities(value),
              };
            }
            return { ...item, [columnId]: value };
          }
          return item;
        });
        setDataSource(newDataSource);
      }
    },
    [calcLabelQuantities, dataSource, purchaseOrder]
  );

  // eslint-disable-next-line no-unused-vars
  const _onMoreOptionsSave = (_settings: ReceivePOSettings) => {
    // Sub this out when graphql is ready
    /* updateReceivePOSettings(settings.defaultLabelQty, settings.report)
      .then(() => messageContext.setSuccess(t('message.settingsUpdate')))
      .catch((e) => messageContext.setError(e.message)); */
    // console.log(settings);
  };

  const autoFillValue = (receive: number) => {
    switch (defaultLabelQty) {
      case 'None':
        return 0;
      case 'Received Quantities':
        return receive;
      case '1':
      default:
        return 1;
    }
  };

  const _onAutofill = () => {
    let items = dataSource ?? [];
    if (items) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      items = items.map((item: any) => {
        const previouslyReceived =
          item.status === null ? 0 : item.status.quantityReceived;
        const quantityToReceive =
          item.purchaseQty - previouslyReceived < 0
            ? 0
            : item.purchaseQty - previouslyReceived;
        return {
          ...item,
          toReceive: quantityToReceive,
          numberOfLabels: autoFillValue(quantityToReceive),
        };
      });
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setPurchaseOrder((prev: any) => ({
      ...prev,
      purchaseOrderDetails: items,
    }));

    if (reactDataGridRef) {
      reactDataGridRef.current?.reload();
    }
    setRefreshToken(new Date());
  };

  const moreOptionTab = {
    tabIcon: <TuneRoundedIcon />,
    tabLabel: 'More Options',
    tabIndex: 1,
    tabPanel: (
      <MoreOptionsPanel
        hideFullyReceivedSetting={hideFullyReceived}
        setHideFullyReceived={setHideFullyReceived}
        defaultLabelQty={defaultLabelQty}
        setDefaultLabelQty={setDefaultLabelQty}
      />
    ),
    onTabSave: () => {
      // eslint no console
      // console.log('save')
    },
  };

  useEffect(() => {
    if (hideFullyReceived && dataSource) {
      if (purchaseOrder) {
        setDataSource((prev) =>
          prev.filter((det) => {
            const previouslyReceived =
              det.status === null ? 0 : det.status.quantityReceived;
            if (det.purchaseQty - previouslyReceived <= 0) {
              return false;
            }
            return true;
          })
        );
      }
    } else if (purchaseOrder) {
      setDataSource(purchaseOrder.purchaseOrderDetails ?? []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hideFullyReceived, purchaseOrder]);

  const labelQuery = `query exportReceivingLabelsReport(
    $reportId: Int!,
    $alternateId: Int,
    $purchaseOrderId: Int!,
    $packingSlipNumber: String,
    $receiveDate: DateTime!,
    $itemsToReceive: [ReceivePurchaseOrderDetailInput!]!) {
      exportReceivingLabelsReport(reportId: $reportId, alternateReportId: $alternateId, input: {
        purchaseOrderId: $purchaseOrderId
        packingSlipNumber: $packingSlipNumber,
        receiveDate: $receiveDate,
        itemsToReceive: $itemsToReceive
      }) {
        reportId,
        path
      }
    }`;

  const labelVariables = useMemo(
    () =>
      purchaseOrder && dataSource
        ? {
            reportId: 201,
            purchaseOrderId: purchaseOrder.id,
            receiveDate: purchaseOrder.pOReceiveDate ?? dayjs(),
            packingSlipNumber: purchaseOrder.packingSlipNumber,
            // itemsToReceive: receivingValues.input.itemsToReceive
            itemsToReceive: dataSource
              ?.map((item: PurchaseOrderDetail) => ({
                itemId: item.id,
                toReceive: item.toReceive ?? 0,
                numberOfLabels: item.numberOfLabels ?? 0,
              }))
              .filter(
                (i) =>
                  i.numberOfLabels !== 0 && (i.numberOfLabels as any) !== ''
              ),
          }
        : [],
    [purchaseOrder, dataSource]
  );
  const labelQueries = useQuery(
    [labelVariables],
    () => {
      const labelAltId = localStorage.getItem('ReceivingSelectedLabelReport')
        ? parseInt(
            localStorage.getItem('ReceivingSelectedLabelReport') as string,
            10
          )
        : 0;
      return getGraphQLClient()
        .performQuery(
          labelQuery,
          { ...labelVariables, alternateId: labelAltId },
          (err) => {
            messageContext.setError(err.messages[0]);
          },
          (err) => {
            // @ts-expect-error: this is correct
            messageContext.setError(err?.input?.id);
          }
        )
        .then((data) => {
          if (data != null) {
            const url = data.exportReceivingLabelsReport.path;
            window.open(url, '_blank');
          }
        });
      /* .catch((e) => {
          console.log(e, 'e');
          messageContext.setError(e.errors[0]);
        }); */
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  const _onPrintLabels = () => {
    if (
      dataSource.every(
        (detail) =>
          detail.numberOfLabels === undefined || detail.numberOfLabels === 0
      )
    ) {
      messageContext.setError(t('generic.message.noLabels'));
    } else {
      labelQueries.refetch();
    }
  };

  const formatReceivingValues = (data: any[]) => {
    const formattedValues = data
      .map((e) => {
        let values = {};
        const keys = Object.keys(e);
        keys.forEach((b) => {
          if (/Custom/i.test(b)) {
            values = {
              ...values,
              [`c${b.slice(-6)}`]: e[b],
            };
          } else if (b === 'itemId') {
            values = {
              ...values,
              [b]: e.id,
            };
          } else if (b === 'toReceive' || b === 'numberOfLabels') {
            values = {
              ...values,
              [b]: e[b] ?? 0,
            };
          }
        });
        return values as ReceivePurchaseOrderDetailInput;
      })
      .filter((i) => i?.toReceive && i?.toReceive !== 0);
    return formattedValues;
  };

  useEffect(() => {
    if (purchaseOrder && dataSource) {
      setReceivingValues({
        input: {
          // eslint-disable-next-line no-nested-ternary
          receiveDate: receivingValues?.input?.receiveDate
            ? receivingValues.input?.receiveDate
            : purchaseOrder.pOReceiveDate
            ? purchaseOrder.pOReceiveDate
            : dayjs(),
          itemsToReceive: formatReceivingValues(dataSource),
          purchaseOrderId: purchaseOrder.id,
          // eslint-disable-next-line no-nested-ternary
          packingSlipNumber: receivingValues?.input?.packingSlipNumber
            ? receivingValues.input?.packingSlipNumber
            : purchaseOrder.packingSlipNumber
            ? purchaseOrder.packingSlipNumber
            : '',
        },
      });
    }
    // don't add to the dependencies the receivingValues?.input?.receiveDate and receivingValues?.input?.packingSlipNumber or else it loops
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource, purchaseOrder]);

  const _onReceive = async (generateReport: boolean) => {
    const receivePurchaseOrderMutation = `mutation receivePurchaseOrder($input: ReceivePurchaseOrderRequestInput!) {
        receivePurchaseOrder(input: $input) {
          batchId
          success
        }
      }`;

    // eslint-disable-next-line no-unused-vars
    const result = await getGraphQLClient()
      .performMutation(
        receivePurchaseOrderMutation,
        receivingValues,
        (err) => {
          messageContext.setError(err.messages[0]);
        },
        () => {
          // @TODO Handle Validation
        }
      )
      .then((d) => {
        if (d?.receivePurchaseOrder?.success === true && generateReport) {
          setIsReportLoading(true);
          // eslint-disable-next-line no-unused-vars
          const report = getGraphQLClient()
            .performQuery(
              exportReceivingReportQuery,
              {
                purchaseOrderId: purchaseOrder.id,
                batchId: d.receivePurchaseOrder.batchId,
              },
              (err) => {
                setIsReportLoading(false);
                messageContext.setError(err.messages[0]);
              },
              () => {
                setIsReportLoading(false);
                // @TODO Handle Validation
              }
            )
            .then((r) => {
              if (r != null) {
                const url = r.exportReceivingReport.path;
                window.open(url, '_blank');
                setIsReportLoading(false);
              }
            });
        }
        setIsEditing(false);
        setReceivingValues(undefined);
        setPurchaseOrder(undefined);
        messageContext.setSuccess(t('generic.message.receivePO'));
      });
  };
  const AutoFillButton = {
    title: t('Entities.Receiving.autoFill'),
    icon: <FormatColorTextRoundedIcon />,
    onclick: () => _onAutofill(),
    disabled:
      !purchaseOrder ||
      !authContext.hasPermission(Permission.Modify_Legacy_frmReceiving_Main),
  };

  const PrintLabelsButton = {
    title: t('Entities.Receiving.printLabels'),
    icon: <PrintRoundedIcon />,
    onclick: () => _onPrintLabels(),
    customComponent: (
      <ETOButton
        onClick={_onPrintLabels}
        loading={labelQueries.isFetching}
        color="primary"
        icon={<PrintRoundedIcon />}
        size="medium"
        disabled={
          !authContext.hasLicense(Licenses.TotalETOProfessional) ||
          !dataSource ||
          !dataSource.some(
            (val: PurchaseOrderDetail) => val.toReceive || val.numberOfLabels
          ) ||
          !authContext.hasPermission(Permission.Modify_Legacy_frmReceiving_Main)
        }
      >
        {t('Entities.Receiving.printLabels')}
      </ETOButton>
    ),
  };

  const ReceiveBtn = useMemo(
    () => ({
      title: t('Entities.Receiving.receive'),
      icon: <MoveToInboxRoundedIcon />,
      onclick: () => setIsReceivingDialogOpen(true),
      disabled:
        !authContext.hasLicense(Licenses.TotalETOProfessional) ||
        !dataSource ||
        !authContext.hasPermission(Permission.Modify_Legacy_frmReceiving_Main),
    }),
    [authContext, dataSource, t]
  );

  useEffect(() => {
    if (
      dataSource &&
      dataSource.length > 0 &&
      dataSource.findIndex((val) => {
        const keys = Object.keys(val);
        return keys.includes('toReceive') && keys.includes('numberOfLabels');
      }) >= 0
    ) {
      setIsEditing(true);
    }
  }, [dataSource]);

  /* const hasUpdatedDataSource = useMemo(
    () =>
      dataSource &&
      dataSource.length > 0 &&
      dataSource.findIndex((val) => {
        const keys = Object.keys(val);
        return keys.includes('toReceive') && keys.includes('numberOfLabels');
      }) >= 0,
    [dataSource]
  ); */

  return (
    <>
      {ready && cols && metaData && (
        <TetoContainer
          receiveBtn={
            purchaseOrder &&
            showSecondaryReceiveButton && (
              <Box sx={receiveBtnSx}>
                <ETOButton
                  color="primary"
                  size="medium"
                  icon={ReceiveBtn.icon}
                  disabled={ReceiveBtn.disabled}
                  onClick={ReceiveBtn.onclick}
                >
                  {ReceiveBtn.title}
                </ETOButton>
              </Box>
            )
          }
        >
          <ActionBar
            leftChildren={
              // eslint-disable-next-line no-nested-ternary
              isReceivingMobile ? (
                purchaseOrder ? (
                  <PONumber
                    id={purchaseOrder?.id}
                    setPurchaseOrder={setPurchaseOrder}
                    setReceivingValues={setReceivingValues}
                  />
                ) : (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: 'calc(100% - 32px)',
                      position: 'absolute',
                      bottom: '50%',
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{ color: theme.palette.text.disabled }}
                    >
                      {t('Entities.Receiving.noActivePurchaseOrder')}
                    </Typography>
                  </Box>
                )
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <POSearch
                    setReceivingValues={setReceivingValues}
                    purchaseOrder={purchaseOrder}
                    setPurchaseOrder={setPurchaseOrder}
                    lastActivePO={lastActivePO}
                    isEditing={isEditing}
                  />
                </Box>
              )
            }
            rightChildren={
              purchaseOrder
                ? [
                    ReceiveBtn,
                    PrintLabelsButton,
                    AutoFillButton,
                    {
                      title: t('generic.configure'),
                      icon: <SettingsRoundedIcon />,
                      onclick: () => setConfigureInspector(true),
                    },
                  ]
                : []
            }
            // rightChildren={rightMenuActions}
            // bottomChildren={bottomChildren}
            withBottomNav
          />

          <TetoGrid
            noHeader
            configureInspector={configureInspector}
            setConfigureInspector={setConfigureInspector}
            // disableAllButtons={isEditing}
            // setIsEditing={setIsEditing}
            dataSource={dataSource ?? []}
            setDataSource={setDataSource}
            clientSideFiltering
            ref={gridRef}
            pageSize={50_000}
            externalQueryProps={{}}
            inlineEditMode={
              authContext.hasPermission(
                Permission.Modify_Legacy_frmReceiving_Main
              )
                ? 'default'
                : undefined
            }
            hideGrid={!purchaseOrder}
            unControlledSort
            disableConfigureButton={!purchaseOrder}
            disableResetButton={!purchaseOrder}
            editable={{
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onSubmit: (values: any) => _onSubmit(values),
              validationSchema: POValidation,
              onSubmitSuccess: () => {
                setPurchaseOrder(undefined);
                return Promise.resolve();
              },
              onEditComplete: _onEditComplete,
              hasEditPermission: true,
              processForeignKey: processTimecardForeignKey,
              formatEditableRow: (values) => ({
                ...values,
              }),
            }}
            summary={
              <POSummary
                receivingValues={receivingValues}
                setReceivingValues={setReceivingValues}
                purchaseOrder={purchaseOrder}
                setPurchaseOrder={setPurchaseOrder}
                lastActivePO={lastActivePO}
                hasUpdatedDataSource={isEditing}
              />
            }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            doQuery={(a, b, c) =>
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              _doQuery(a, b, c as any) as unknown as Promise<
                PagedResponse<Record<string, unknown>>
              >
            }
            columns={cols}
            setReactDataGridRef={setReactDataGridRef}
            refreshToken={refreshToken}
            header={{
              // eslint-disable-next-line no-nested-ternary
              leftChildren: isReceivingMobile ? (
                purchaseOrder ? (
                  <PONumber
                    id={purchaseOrder?.id}
                    setPurchaseOrder={setPurchaseOrder}
                    setReceivingValues={setReceivingValues}
                  />
                ) : (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: 'calc(100% - 32px)',
                      position: 'absolute',
                      bottom: '50%',
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{ color: theme.palette.text.disabled }}
                    >
                      {t('Entities.Receiving.noActivePurchaseOrder')}
                    </Typography>
                  </Box>
                )
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <POSearch
                    setReceivingValues={setReceivingValues}
                    purchaseOrder={purchaseOrder}
                    setPurchaseOrder={setPurchaseOrder}
                    lastActivePO={lastActivePO}
                    isEditing={isEditing}
                  />
                </Box>
              ),
              rightChildren: purchaseOrder
                ? [ReceiveBtn, PrintLabelsButton, AutoFillButton]
                : [],
            }}
            tableIdentifier={TABLE_ID}
            isDiscardConfirmDialog={false}
            configTabs={[moreOptionTab]}
            filterRowHeight={isReceivingMobile ? 0 : undefined}
            customGridWrapSx={isReceivingMobile ? tableWrapSx : undefined}
            mobileGridOptions
          />
        </TetoContainer>
      )}
      {isReceivingDialogOpen && (
        <ReceivingDialog
          purchaseOrderDetails={dataSource}
          onReceive={_onReceive}
          open={isReceivingDialogOpen}
          setOpen={setIsReceivingDialogOpen}
        />
      )}
      {selectedPOPart && (
        <PartDetailsInspector
          calcLabelQuantities={calcLabelQuantities}
          onEditComplete={_onEditComplete}
          title={t('Entities.Receiving.partDetails')}
          open={Boolean(selectedPOPart)}
          onClose={() => setSelectedPOPart(undefined)}
          dataSource={dataSource}
          selectedPOPart={selectedPOPart}
          setSelectedPOPart={setSelectedPOPart}
          checkInlineEditingCondition={checkInlineEditingCondition}
        />
      )}

      {distributionInspector && (
        <DistributionInspector
          title={t('Entities.Receiving.distribution')}
          open={distributionInspector}
          onClose={() => setDistributionInspector(false)}
          detail={distribution as PurchaseOrderDetail}
        />
      )}

      <Dialog
        open={isReportLoading}
        onClose={(reason) => {
          if (reason !== 'backdropClick') {
            setIsReportLoading(false);
          }
        }}
        PaperProps={{
          sx: {
            minWidth: theme.spacing(20),
            textAlign: 'center',
            margin: 'auto',
            padding: theme.spacing(2),
            [theme.breakpoints.up('sm')]: {
              width: '100%',
            },
          },
        }}
      >
        <Typography sx={{ marginBottom: theme.spacing(1) }} variant="h6">
          {t('generic.generatingReport')}
        </Typography>
        <LinearProgress />
      </Dialog>
    </>
  );
};

export default React.memo(ReceivingPage);
