const searchPurchaseOrderHeadersByPONumberQuery = `query searchPurchaseOrderHeadersByPONumber($searchTerm: String!) {
  searchPurchaseOrderHeadersByPONumber(searchTerm: $searchTerm) {
        items {
          id
          purchaseSupplier {
            name
          }
          purchaseOrderDetails {
            purchaseSupplierItem
          }
        }
      }
    }
`;

export default searchPurchaseOrderHeadersByPONumberQuery;
