import { Box, Theme } from '@mui/material';
import {
  ButtonStrip,
  ETODateTimeField,
  ETOSelectField,
  Inspector,
  MessageContext,
  SettingsContext,
} from '@teto/react-component-library';
import dayjs, { Dayjs } from 'dayjs';
import { useFormik } from 'formik';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useQuery } from 'react-query';
import { Employee } from 'teto-client-api';
import { getGraphQLClient } from '../../../helpers/graphQL/graphQLClient';
import { REQUIRED_MESSAGE } from '../../ReceivingPage/components/POValidation';
import addClockInMutation from '../queries/addClockInMutation';
import getEmployeesQuery from '../queries/getEmployeesQuery';

const contentSx = (theme: Theme) => ({
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  marginBottom: theme.spacing(4),
});

const buttonRowSx = (theme: Theme) => ({
  flex: `0 0 ${theme.spacing(7.5)}`,
  flexGrow: 0,
});

const YupSchema = Yup.object().shape({
  employeeId: Yup.number().required(REQUIRED_MESSAGE).min(-1, REQUIRED_MESSAGE),
  startTime: Yup.date().required(REQUIRED_MESSAGE),
  endTime: Yup.date().required(REQUIRED_MESSAGE),
});

type ClockInValues = {
  employeeId: number;
  startTime: Dayjs;
  endTime: Dayjs;
};

interface AddClockInInspectorProps {
  open: boolean;
  setAddClockInInspector: React.Dispatch<React.SetStateAction<boolean>>;
  setRefreshToken: React.Dispatch<React.SetStateAction<Date | undefined>>;
}

const AddClockInInspector = (props: AddClockInInspectorProps) => {
  const { open, setAddClockInInspector, setRefreshToken } = props;

  const { t } = useTranslation();

  const messageContext = useContext(MessageContext);
  const settingsContext = useContext(SettingsContext);

  const _handleClose = () => {
    setAddClockInInspector(false);
  };

  const employees = useQuery(
    ['clockin-employees'],
    () =>
      getGraphQLClient()
        .performQuery(
          getEmployeesQuery,
          {},
          (err) => messageContext.setError(err.messages[0]),
          () => {
            /* @TODO Handle Validation */
          }
        )
        .then((d) => {
          const { items } = d.employees;
          return items;
        }),
    {
      enabled: true,
      refetchOnWindowFocus: false,
    }
  );

  const _onSubmit = async (values: ClockInValues) =>
    getGraphQLClient().performMutation(
      addClockInMutation,
      {
        employeeId: values.employeeId,
        startTime: values.startTime,
        endTime: values.endTime,
      },
      (err) => {
        messageContext.setError(err.messages[0]);
      },
      () => {
        // @TODO Handle Validation
      }
    );
  const formik = useFormik<ClockInValues>({
    validationSchema: YupSchema,
    initialValues: {
      employeeId: -2,
      startTime: dayjs(),
      endTime: dayjs(),
    },
    onSubmit: (values, actions) =>
      _onSubmit(values).then((d) => {
        const { id } = d.data.addClockIn;
        if (id) {
          messageContext.setSuccess(t('generic.message.addClockIn'));
          _handleClose();
          setRefreshToken(new Date());
        }
        actions.setSubmitting(false);
      }),
  });

  return (
    <Inspector open={open} title="Add Clock In" onClose={_handleClose}>
      <Box component="form" onSubmit={formik.handleSubmit} autoComplete="off">
        <Box sx={contentSx}>
          <Box mt={2}>
            <ETOSelectField
              name="employeeId"
              value={formik.values.employeeId}
              items={employees.data ?? []}
              itemNameSelector={(item: Employee) => item.name}
              itemValueSelector={(item: Employee) => item.id}
              handleChange={formik.handleChange}
              label={t('Entities.Employee.Employee')}
              error={formik.errors.employeeId}
            />
          </Box>
          <Box mt={2}>
            <ETODateTimeField
              name="clockInTme"
              value={formik.values.startTime}
              handleChange={(v) => formik.setFieldValue('startTime', v)}
              label={t('Entities.ClockIns.clockIn')}
              inputFormat={settingsContext.settings.dateTimeFormat}
              error={formik.errors.startTime as string}
            />
          </Box>
          <Box mt={2}>
            <ETODateTimeField
              name="clockOutTime"
              value={formik.values.endTime}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              handleChange={(v) => formik.setFieldValue('endTime', v)}
              label={t('Entities.ClockIns.clockOut')}
              inputFormat={settingsContext.settings.dateTimeFormat}
              error={formik.errors.endTime as string}
            />
          </Box>
        </Box>

        <Box sx={buttonRowSx}>
          <ButtonStrip
            size="medium"
            rightButton={{
              text: t('generic.save'),
              color: 'primary',
              type: 'submit',
              disabled: formik.values.employeeId === -2,
            }}
          />
        </Box>
      </Box>
    </Inspector>
  );
};

export default AddClockInInspector;
