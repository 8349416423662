/* eslint-disable @typescript-eslint/no-explicit-any */
import ExpandLessOutlined from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlined from '@mui/icons-material/ExpandMoreOutlined';
import { Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import { SxProps, Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { SettingsContext } from '@teto/react-component-library';
import { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TimeCard } from 'teto-client-api';
import TableColumnDefinition from '../../../../components/TetoGrid/TableColumnDefinition';
import ColumnValueFormatter from './ColumnValueFormatter';
import SubGroupingDetails from './SubGroupingDetails';

interface GroupColumnsRowProps {
  data: any;
  columns: TableColumnDefinition[];
  // eslint-disable-next-line no-unused-vars
  onDeleteClick: (data: TimeCard) => void;
  // eslint-disable-next-line no-unused-vars
  onEditClick: (data: TimeCard) => void;
  // eslint-disable-next-line no-unused-vars
  setSubOpen: (data: number) => void;
}
interface EvalConstant {
  timeCard: TimeCard;
  subColumn: TableColumnDefinition[];
  subGroup: string[];
  hoursColumn: TableColumnDefinition;
}

const boldSx = {
  fontWeight: 500,
};

const buttonBaseSx: SxProps<Theme> = {
  display: 'flex',
  borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
  width: '100%',
  flexGrow: 1,
};

const expandIconSx: SxProps<Theme> = {
  alignItems: 'center',
  color: (theme) => theme.palette.primary.main,
  flexGrow: 0,
  flexShrink: 0,
  justifyContent: 'center',
  rowGap: (theme) => theme.spacing(0.5),
  width: (theme) => theme.spacing(6.25),
  '& > p': {
    fontWeight: (theme) => theme.typography.fontWeightMedium,
  },
};

const groupingSx: SxProps<Theme> = {
  flexGrow: 1,
  paddingLeft: (theme) => theme.spacing(1),
  width: '57%',
};

const timeSx: SxProps<Theme> = {
  alignItems: 'center',
  flexGrow: 1,
  flexShrink: 0,
  fontWeight: 500,
  justifyContent: 'center',
  maxWidth: 250,
  minWidth: 100,
  width: '27%',
};

const commonSx: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
};

const columnContainerSx: SxProps<Theme> = {
  alignItems: 'center',
  display: 'flex',
  height: (theme) => theme.spacing(3.125),
  justifyContent: 'flex-start',
  columnGap: (theme) => theme.spacing(0.5),
  padding: '8px 0px !important',
  width: '100%',
};

const columnSx: SxProps<Theme> = {
  flexGrow: 1,
  flexShrink: 0,
  overflowX: 'hidden',
  textAlign: 'left',
  maxWidth: '33%',
  minWidth: '20%',
  width: 'fit-content',
};

const valueSx: SxProps<Theme> = {
  flexGrow: 2,
  flexShrink: 0,
  overflowX: 'hidden',
  textAlign: 'left',
  maxWidth: '66%',
};

const excludedColumns = [
  'action',
  'mobileCustomGrouping',
  'mobileCustomData',
  'date',
  'actualHours',
];

const GroupColumnsRow = (props: GroupColumnsRowProps) => {
  const { columns, data, onDeleteClick, onEditClick, setSubOpen } = props;
  const [open, setOpen] = useState<boolean>(false);
  const { settings } = useContext(SettingsContext);
  const { t } = useTranslation();

  const { timeCard, subColumn, subGroup, hoursColumn } = useMemo(() => {
    let subColumns = columns.filter(
      (e) =>
        data.groupColumns.indexOf(e.name) < 0 &&
        !e.hidden &&
        excludedColumns.indexOf(e.name) < 0
    );

    if (subColumns.length === 0)
      subColumns = [columns[data.groupColumns[data.groupColumns.length - 1]]];

    return {
      timeCard: data.items[0],
      subColumn: subColumns,
      subGroup: subColumns.map((e) => e.name),
      hoursColumn: columns.filter((e) => e.name === 'hourTime')[0],
    };
  }, [columns, data.groupColumns, data.items]) as EvalConstant;

  const buttonHeight: SxProps<Theme> = useMemo(
    () => ({
      height: (theme) => theme.spacing(2 + data.groupColumns.length * 3.25),
    }),
    [data.groupColumns.length]
  );

  const handleOpen = () => {
    setOpen((d) => !d);
    if (!open) setSubOpen(data.items.length);
    else setSubOpen(-data.items.length);
  };

  const formattedGrouping = useMemo(
    () =>
      data.groupColumns.map((e: string) => {
        const columnDef = columns.filter((g) => g.name === e)[0];
        return (
          <Box sx={columnContainerSx} key={`${data.groupString}-${e}`}>
            <Typography
              align="left"
              component="span"
              noWrap
              sx={[columnSx, boldSx]}
              variant="body2"
            >
              {`${columnDef.title}:`}
            </Typography>
            <Typography
              align="left"
              component="span"
              noWrap
              sx={[
                valueSx,
                ...(columnDef.title === 'Comments'
                  ? [{ textTransform: 'none' }]
                  : []),
              ]}
              variant="body2"
            >
              <ColumnValueFormatter
                value={timeCard[e as keyof TimeCard]}
                column={columnDef}
                settings={settings}
              />
            </Typography>
          </Box>
        );
      }),
    [columns, data.groupColumns, data.groupString, settings, timeCard]
  );

  const records = data.items.map((e: TimeCard) => (
    <SubGroupingDetails
      key={`items ${e.id}`}
      columnlist={subGroup}
      columns={subColumn}
      data={e}
      onDeleteClick={onDeleteClick}
      onEditClick={onEditClick}
    />
  ));

  return (
    <>
      <ButtonBase
        sx={[buttonBaseSx, buttonHeight]}
        onClick={() => handleOpen()}
      >
        <Box sx={[expandIconSx, commonSx]}>
          <Typography>{records.length}</Typography>
          <Tooltip
            title={t(`Entities.TimeSheet.${open ? 'hide' : 'view'}Details`)}
            leaveTouchDelay={200}
          >
            {open ? <ExpandLessOutlined /> : <ExpandMoreOutlined />}
          </Tooltip>
        </Box>
        <Box sx={[groupingSx]}>{formattedGrouping}</Box>
        <Box sx={[timeSx, commonSx, boldSx]}>
          <ColumnValueFormatter
            value={data.totalHours}
            column={hoursColumn}
            settings={settings}
          />
        </Box>
      </ButtonBase>
      {open && (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>{records}</>
      )}
    </>
  );
};

export default GroupColumnsRow;
