import {
  Box,
  Divider,
  MenuItem,
  SxProps,
  TextField,
  Theme,
} from '@mui/material';
import { Inspector } from '@teto/react-component-library';
import * as React from 'react';
import useLocalStorage from 'use-local-storage';
import Distribution from './distribution/Distribution';

const selectContainerSx: SxProps<Theme> = (theme: Theme) => ({
  margin: 2,
  marginLeft: 1,
  '& .MuiFormControl-root': {
    width: '100%',
    '& .MuiAutocomplete-endAdornment': {
      top: theme.spacing(1),
    },
  },
  '& .MuiIconButton-root': {
    marginTop: theme.spacing(1),
  },
});

interface DistributionInspectorProps {
  open: boolean;
  title: string;
  onClose: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  detail: { [key: string]: any };
}

const DistributionInspector = (props: DistributionInspectorProps) => {
  const { title, open, onClose, detail } = props;

  const [selected, setSelected] = React.useState<string>('');
  const [prevSelectedTab, setPrevSelectedTab] = useLocalStorage<string>(
    'prevSelectedTab',
    ''
  );

  const selectValues = {
    label: 'Select Distribution',
    items: [
      { key: 'summary', label: 'Summary' },
      { key: 'partDetails', label: 'Part Details' },
      { key: 'bomDistribution', label: 'BOM Distribution' },
      { key: 'nonBOM', label: 'Non-BOM' },
      { key: 'purchased', label: 'Purchased' },
      { key: 'reservedPulledInventory', label: 'Reserved/Pulled Inventory' },
      { key: 'issuedProcessSchedules', label: 'Issued Process Schedules' },
      { key: 'bomPartsOrderRelease', label: 'BOM\\Parts Order Releases' },
    ],
  };

  const { items, label } = selectValues;

  const _handleSelect = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSelected(e.target.value);
    setPrevSelectedTab(e.target.value);
  };

  return (
    <Inspector open={open} title={title} onClose={onClose}>
      <Box sx={selectContainerSx}>
        <TextField
          label={label}
          select
          name="selectDistribution"
          value={selected}
          onChange={(e) => _handleSelect(e)}
        >
          {items.map((item) => (
            <MenuItem key={item.key} value={item.key}>
              {item.label}
            </MenuItem>
          ))}
        </TextField>
      </Box>
      <Divider sx={{ mb: 2 }} />
      <Distribution
        selected={selected}
        prevSelectedTab={prevSelectedTab}
        detail={detail}
      />
    </Inspector>
  );
};

export default DistributionInspector;
