import {
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Popover,
  useTheme,
  useMediaQuery,
  Typography,
  ClickAwayListener,
  Tooltip,
  Button,
  Theme,
  Box,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Container, Draggable } from 'react-smooth-dnd';
import DragHandleRoundedIcon from '@mui/icons-material/DragHandleRounded';
import { useTranslation } from 'react-i18next';
import { arrayMoveImmutable } from '@teto/react-component-library';
import ColumnToggleCheckbox from '../../../TetoGrid/ConfigureInspector/ColumnToggleCheckbox';
import TableColumnDefinition from '../../../TetoGrid/TableColumnDefinition';

const modalSx = (theme: Theme) => ({
  display: 'flex',
  padding: theme.spacing(2),
  alignItems: 'center',
  justifyContent: 'center',
  '& .MuiPaper-root': {
    [theme.breakpoints.down('md')]: {
      top: 0,
    },
  },
});

const paperSx = (theme: Theme) => ({
  maxHeight: theme.spacing(40),
  minWidth: theme.spacing(45),
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('md')]: {
    minWidth: theme.spacing(40),
  },
});

const assignedListSx = (theme: Theme) => ({
  width: '100%',
  overflowY: 'auto',
  maxHeight: 'calc(100% - 160px)',
  '& .smooth-dnd-draggable-wrapper': {
    borderRadius: `${theme.shape.borderRadius}px`,
  },
  paddingRight: theme.spacing(2),
});

const assignedListItemSx = (theme: Theme) => ({
  borderRadius: `${theme.shape.borderRadius}px`,
  paddingLeft: theme.spacing(1),
  paddingRight: 0,
});

const assignedListItemAltSx = (theme: Theme) => ({
  borderRadius: `${theme.shape.borderRadius}px`,
  paddingLeft: theme.spacing(1),
  paddingRight: 0,
  backgroundColor: theme.palette.background.default,
  color: theme.palette.getContrastText(theme.palette.background.default),
});

const dragHandleSx = (theme: Theme) => ({
  paddingRight: theme.spacing(1),
  cursor: 'pointer',
});

const saveBtnWrapSx = (theme: Theme) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: theme.spacing(1),
});

const tooltipSx = {
  zIndex: 100,
};

interface GroupingModalProps {
  anchorEl: Element | null;
  open: boolean;
  columns: TableColumnDefinition[];
  initialColumnOrder: string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setRefreshToken: React.Dispatch<any>;
  // eslint-disable-next-line no-unused-vars
  setHidden: (hidden: string[]) => void;
  // eslint-disable-next-line no-unused-vars
  setColumnOrder: (order: string[]) => void;
  onCancel: () => void;
}

const _getInitialOrder = (
  cols: TableColumnDefinition[],
  colsOrdered: string[]
) =>
  cols
    .sort((a, b) => {
      const aIndex = colsOrdered.indexOf(a.name); // 3
      const bIndex = colsOrdered.indexOf(b.name); // 2

      if (aIndex === -1 && bIndex === -1) return 0;
      if (aIndex === -1) return -1;
      if (bIndex === -1) return 1;

      return aIndex - bIndex;
    })
    .map((a) => a.name);

const GroupingModal = (props: GroupingModalProps) => {
  const {
    anchorEl,
    columns,
    initialColumnOrder,
    open,
    setRefreshToken,
    setColumnOrder,
    setHidden,
    onCancel,
  } = props;

  const { t } = useTranslation();

  const theme = useTheme();
  const mobileSize = useMediaQuery(theme.breakpoints.down('md'));

  const [cols, setCols] = useState<TableColumnDefinition[]>();
  const [columnOrders, setColumnOrders] = useState(
    _getInitialOrder(columns, initialColumnOrder)
  );

  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);

  const _handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  useEffect(() => {
    setColumnOrders(_getInitialOrder(columns, initialColumnOrder));
    setCols(columns);
  }, [columns, initialColumnOrder]);

  const _handleColumnToggle = (cName: string) => {
    if (cols) {
      setCols((items) =>
        items?.map((e) => (e.name === cName ? { ...e, hidden: !e.hidden } : e))
      );
    }
  };

  const _handleColumnDrag = ({
    removedIndex,
    addedIndex,
  }: {
    removedIndex: number | null;
    addedIndex: number | null;
  }) => {
    if (removedIndex !== null && addedIndex !== null) {
      setColumnOrders((items) =>
        arrayMoveImmutable(items, removedIndex, addedIndex)
      );
      setCols((item) =>
        arrayMoveImmutable(item ?? [], removedIndex, addedIndex)
      );
    }
  };

  const _onSave = () => {
    const visibility: string[] = [];
    columnOrders.push('action');
    if (cols) {
      cols.forEach(
        (e: TableColumnDefinition) => e.hidden && visibility.push(e.name)
      );
      if (cols.every((col) => col.hidden)) {
        setTooltipOpen(true);
      } else {
        setTooltipOpen(false);
        setRefreshToken(new Date());
        onCancel();
        setColumnOrder(columnOrders);
        setHidden(visibility);
      }
    }
  };

  return (
    <Popover
      sx={modalSx}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: mobileSize ? 0 : -8,
        horizontal: mobileSize ? 'left' : 'right',
      }}
      onClose={() => onCancel()}
    >
      <Paper sx={paperSx}>
        <Typography variant="h6">{t('generic.showColumns')}</Typography>
        <List sx={assignedListSx}>
          <Container
            dragHandleSelector=".drag-handle"
            lockAxis="y"
            onDrop={(e) => _handleColumnDrag(e)}
            data-testid="configureInspectorListItemContainer"
          >
            {cols &&
              cols.map((col, ind) => (
                <Draggable key={col.name}>
                  <ListItem
                    data-testid="configureInspectorListItem"
                    sx={[
                      assignedListItemSx,
                      { ...(ind % 2 === 0 ? assignedListItemAltSx : null) },
                    ]}
                  >
                    <ListItemText>
                      <ColumnToggleCheckbox
                        column={col}
                        onChange={() => _handleColumnToggle(col.name)}
                      />
                    </ListItemText>
                    <ListItemSecondaryAction
                      sx={dragHandleSx}
                      className="drag-handle"
                    >
                      <DragHandleRoundedIcon />
                    </ListItemSecondaryAction>
                  </ListItem>
                </Draggable>
              ))}
          </Container>
        </List>
        <ClickAwayListener onClickAway={_handleTooltipClose}>
          <Box sx={saveBtnWrapSx}>
            <Tooltip
              PopperProps={{
                disablePortal: true,
              }}
              open={tooltipOpen}
              onClose={_handleTooltipClose}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title="Cannot save grouping with less than one column selected"
              arrow
              placement="top"
              sx={tooltipSx}
            >
              <Button
                size="medium"
                onClick={() => _onSave()}
                variant="contained"
                color="primary"
              >
                {t('generic.save')}
              </Button>
            </Tooltip>
          </Box>
        </ClickAwayListener>

        {/* <ETOButton
          size="medium"
          color="primary"
          onClick={() => _onSave()}
        >
          {t('generic.save')}
        </ETOButton> */}
      </Paper>
    </Popover>
  );
};

export default GroupingModal;
