/* eslint-disable @typescript-eslint/no-explicit-any */
import ReactDataGrid from '@inovua/reactdatagrid-enterprise';
import {
  TypeColumn,
  TypeComputedProps,
  TypeExpandedNodes,
} from '@inovua/reactdatagrid-enterprise/types';
import ViewListRoundedIcon from '@mui/icons-material/ViewListRounded';
import { Tooltip, useTheme } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import {
  AppearanceContext,
  AuthContext,
  MessageContext,
} from '@teto/react-component-library';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import {
  addDepartmentApprover,
  addSubDepartmentApprover,
  deleteDepartmentApprover,
  deleteSubDepartmentApprover,
  getDepartments,
  Licenses,
} from 'teto-client-api';
import FormatterProps from '../../../components/TetoGrid/Formatters/FormatterProps';
import { reactDataGridLicenseKey } from '../../../components/TetoGrid/Licensing';
import ApproverFormatter from '../ApproverFormatter';
import AssignEmployeesInspector from '../AssignEmployeesInspector';
import ITreeData from './ITreeData';

const defaultFilterValue = () => [
  {
    name: 'name',
    operator: 'contains',
    type: 'string',
    value: '',
  },
];

const DepartmentApprovers = () => {
  const [editingRecordId, setEditingRecordId] =
    useState<string | number | undefined>();
  const query = useQuery(
    ['departments'],
    () => getDepartments({ disablePaging: true }),
    {
      refetchOnWindowFocus: false,
    }
  );

  const [expandedNodes, setExpandedNodes] = useState<TypeExpandedNodes>({});
  const [filterValue, setFilterValue] = useState(defaultFilterValue());

  const appearanceContext = useContext(AppearanceContext);
  const messageContext = useContext(MessageContext);
  const authContext = useContext(AuthContext);

  const currentTheme =
    appearanceContext.themeVariant === 'dark'
      ? 'default-dark'
      : 'default-light';

  const { t } = useTranslation();

  const theme = useTheme();

  const scrollStyle = {
    scrollThumbStyle: { backgroundColor: theme.palette.primary.main },
  };

  const gridRef = useRef<TypeComputedProps | null>(null);

  const onExpandedNodesChange = useCallback((d) => {
    setExpandedNodes(d.expandedNodes);
  }, []);

  useEffect(() => {
    const res: { [key: string | number]: boolean } = {};

    query.data?.records.forEach((a) => {
      res[a.name] = true;
    });

    setExpandedNodes(res);
  }, [query.data]);

  const formattedData = useMemo(() => {
    const results: ITreeData[] = [];
    const filter =
      filterValue && filterValue.length > 0 ? filterValue[0] : undefined;

    query.data?.records.forEach((d) => {
      const subDepts: ITreeData[] = [];

      const department = {
        id: d.name,
        privId: d.name,
        name: d.name,
        nodes: subDepts,
        approvers: d.approvers,
      };

      let shouldAdd = !filter?.value;

      d.subDepartments.forEach((sd) => {
        if (filter?.value) {
          shouldAdd = sd.name
            .toLowerCase()
            .includes(filter.value.toLowerCase());
        }

        if (shouldAdd) {
          subDepts.push({
            id: sd.id,
            privId: sd.id,
            name: sd.name,
            approvers: sd.approvers,
            inferredApprovers: d.approvers,
            parentName: d.name,
          });
        }
      });

      if (
        !filter?.value ||
        department.nodes.length > 0 ||
        department.name.toLowerCase().includes(filter.value.toLowerCase())
      ) {
        results.push(department);
      }
    });
    return results;
  }, [query.data, filterValue]);

  const columns: TypeColumn[] = useMemo(
    () => [
      {
        name: 'name',
        header: t('Entities.Department.Department'),
        defaultFlex: 1,
      },
      {
        name: 'approvers',
        header: t('Entities.TimeSheet.approvers'),
        defaultWidth: 160,
        render: (d: FormatterProps) => <ApproverFormatter {...d} />,
        sortable: false,
      },
      {
        name: 'view',
        header: '',
        width: 50,
        resizable: false,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        render: ({ data }) => (
          <Tooltip title={t('generic.editApprovers')} placement="bottom-end">
            <IconButton
              size="small"
              disabled={!authContext.hasLicense(Licenses.TotalETOEnterprise)}
              color="primary"
              onClick={() => setEditingRecordId(data.privId)}
            >
              <ViewListRoundedIcon />
            </IconButton>
          </Tooltip>
        ),
        showInContextMenu: false,
        showColumnMenuTool: false,
      },
    ],
    [t, authContext]
  );

  useEffect(() => {
    gridRef.current?.reload();
  }, [formattedData]);

  const editingRecord = useMemo(() => {
    if (typeof editingRecordId === 'number') {
      for (let i = 0; i < formattedData.length; i += 1) {
        const d = formattedData[i];
        if (d.nodes) {
          for (let a = 0; a < (d.nodes?.length ?? 0); a += 1) {
            const sd = d.nodes[a];
            if (sd.privId === editingRecordId) {
              return sd;
            }
          }
        }
      }
    } else {
      for (let i = 0; i < formattedData.length; i += 1) {
        const d = formattedData[i];
        if (d.privId === editingRecordId) {
          return d;
        }
      }
    }

    return undefined;
  }, [editingRecordId, formattedData]);

  const _onAssign = useCallback(
    (employeeId: number, record: ITreeData) => {
      const promise =
        typeof record.privId === 'number'
          ? addSubDepartmentApprover(record.privId as number, employeeId)
          : addDepartmentApprover(record.privId as string, employeeId);

      promise
        .then(() => {
          query.refetch();
        })
        .catch((e) => {
          messageContext.setError(e.message);
        });

      return promise;
    },
    [query, messageContext]
  );

  const _onUnAssign = useCallback(
    (employeeId: number, record: ITreeData) => {
      const promise =
        typeof record.privId === 'number'
          ? deleteSubDepartmentApprover(record.privId as number, employeeId)
          : deleteDepartmentApprover(record.privId as string, employeeId);

      promise
        .then(() => {
          query.refetch();
        })
        .catch((e) => {
          messageContext.setError(e.message);
        });

      return promise;
    },
    [query, messageContext]
  );

  const inspectorLabel = useMemo(() => {
    if (!editingRecord) return '';
    if (typeof editingRecord.privId === 'number')
      return `Approvers For ${editingRecord?.parentName} / ${editingRecord?.name}`;
    return `Approvers For ${editingRecord?.name}`;
  }, [editingRecord]);

  const onFilterValueChange = useCallback((value) => {
    setFilterValue(value);
  }, []);

  const remoteDS = useCallback(
    () => Promise.resolve(formattedData),
    [formattedData]
  );

  return (
    <>
      {query.isSuccess && (
        <ReactDataGrid
          treeColumn="name"
          licenseKey={reactDataGridLicenseKey}
          expandedNodes={expandedNodes}
          onExpandedNodesChange={onExpandedNodesChange}
          columns={columns}
          dataSource={remoteDS}
          defaultFilterValue={filterValue}
          onFilterValueChange={onFilterValueChange}
          showColumnMenuFilterOptions={false}
          showFilteringMenuItems={false}
          scrollProps={scrollStyle}
          handle={(r) => {
            gridRef.current = r ? r.current : null;
          }}
          theme={currentTheme}
        />
      )}
      {editingRecordId && (
        <AssignEmployeesInspector
          onAssign={_onAssign}
          onClose={() => setEditingRecordId(undefined)}
          onUnassign={_onUnAssign}
          selectedEmployees={editingRecord?.approvers?.map((i) => i.id) ?? []}
          inferredSelectedEmployees={
            editingRecord?.inferredApprovers?.map((i) => i.id) ?? []
          }
          title={inspectorLabel}
          record={editingRecord}
        />
      )}
    </>
  );
};

export default DepartmentApprovers;
