import { Box, SxProps, Tab, Tabs, Theme, useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { AuthContext } from '@teto/react-component-library';
import React, { useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Permission } from 'teto-client-api';
import AccountTab from './Tabs/AccountTab';
import SystemTab from './Tabs/SystemTab';
import UserTab from './Tabs/UserTab';

interface SettingsTabs {
  [key: string]: TabItem;
}

interface TabItem {
  tabTitle: string;
  content: React.ReactElement;
}

interface TabPanelProps {
  children: React.ReactElement;
  currentTab: string;
  value: string;
  ariaLabel: string;
}

const rootSx: SxProps<Theme> = (theme: Theme) => ({
  display: 'flex',
  backgroundColor: theme.palette.background.paper,
  borderRadius: `${theme.shape.borderRadius}px`,
  flexGrow: 1,
  height: '100%',
  width: '100%',
  overflowY: 'auto',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
  padding: theme.spacing(2),
});

const tabSx: SxProps<Theme> = (theme: Theme) => ({
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  textTransform: 'capitalize',
});

const tabPanelSx: SxProps<Theme> = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(2),
  paddingTop: theme.spacing(1),
  paddingRight: theme.spacing(1),
  flexGrow: 1,
  justifyContent: 'space-between',
  height: '100%',
  overflowY: 'auto',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1),
  },
});

const tabPanelMainSx = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  color: theme.palette.text.primary,
  position: 'relative',
  overflowY: 'auto',
});

const TabPanel = (props: TabPanelProps) => {
  const { children, currentTab, value, ariaLabel } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== currentTab}
      aria-labelledby={`tab-${ariaLabel}`}
      sx={tabPanelSx}
    >
      {/* Allows you to pass props to children */}
      <Box sx={tabPanelMainSx}>
        {React.Children.map(children, (child) => React.cloneElement(child))}
      </Box>
    </Box>
  );
};

const URL = '/settings';

const SettingsPage = () => {
  const authContext = useContext(AuthContext);
  const theme = useTheme();
  const mobileSize = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation(['generic']);
  const navigate = useNavigate();
  const location = useLocation();

  const systemPermissions = authContext.hasPermission(
    Permission.Modify_Admin_SystemSettings
  );

  const tabsItems: SettingsTabs = useMemo(
    () => ({
      account: {
        tabTitle: t('generic.account'),
        content: <AccountTab />,
      },
      user: {
        tabTitle: t('generic.user'),
        content: <UserTab />,
      },
    }),
    [t]
  );

  const settings = useMemo(
    () => ({
      system: {
        tabTitle: t('generic.system'),
        content: <SystemTab />,
      },
    }),
    [t]
  );

  const systemTabs: SettingsTabs = useMemo(
    () => (systemPermissions ? { ...tabsItems, ...settings } : tabsItems),
    [settings, systemPermissions, tabsItems]
  );
  const settingsTabs = useMemo(() => Object.entries(systemTabs), [systemTabs]);
  const availableTabs = useMemo(
    () =>
      Object.keys(systemTabs).filter((tab) => location.pathname.includes(tab)),
    [location.pathname, systemTabs]
  );

  useEffect(() => {
    if (availableTabs.length === 0) {
      navigate(`${URL}/account`, { replace: true });
    }
  }, [availableTabs, navigate]);

  const currentTab = availableTabs.length > 0 ? availableTabs[0] : undefined;

  return (
    <Box sx={rootSx}>
      {currentTab && (
        <>
          <Tabs
            value={currentTab}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onChange={(event: React.ChangeEvent<unknown>, value: any) =>
              navigate(`${URL}/${value}`)
            }
            orientation={mobileSize ? 'horizontal' : 'vertical'}
            aria-label={t('generic.settingsTab')}
            indicatorColor="primary"
            textColor="primary"
          >
            {settingsTabs.map(
              (tabItem) =>
                tabItem && (
                  <Tab
                    key={`tab-${tabItem[1].tabTitle}`}
                    label={tabItem[1].tabTitle}
                    sx={tabSx}
                    value={tabItem[0]}
                  />
                )
            )}
          </Tabs>
          {settingsTabs &&
            settingsTabs.map((tabItem) =>
              tabItem && tabItem[0] === currentTab
                ? tabItem[0] && (
                    <TabPanel
                      key={`tabPanel=${tabItem[1].tabTitle}`}
                      ariaLabel={tabItem[1].tabTitle}
                      value={tabItem[0]}
                      currentTab={currentTab}
                    >
                      {tabItem[1].content}
                    </TabPanel>
                  )
                : null
            )}
        </>
      )}
    </Box>
  );
};

export default SettingsPage;
