import dayjs from 'dayjs';
import { firstDayOfWeek } from '@teto/react-component-library';
import { DateRange } from './dateTypes';

const defaultDateRange = (
  startOfWeek:
    | number
    | 'sunday'
    | 'monday'
    | 'tuesday'
    | 'wednesday'
    | 'thursday'
    | 'friday'
    | 'saturday'
) => {
  const startDate = dayjs()
    .set('hour', 0)
    .set('minute', 0)
    .set('second', 0)
    .set('millisecond', 0);

  const startDow = dayjs(firstDayOfWeek(startDate.toDate(), startOfWeek));
  const endDow = startDow.add(6, 'day');

  const dateRange: DateRange = { start: startDow, end: endDow };

  return dateRange;
};

export default defaultDateRange;
