import Edit from '@mui/icons-material/Edit';
import HowToVoteOutlined from '@mui/icons-material/HowToVoteOutlined';
import {
  Box,
  Theme,
  Tooltip,
  TooltipProps,
  Typography,
  useTheme,
} from '@mui/material';
import {
  ETOIconButton,
  formatHoursAsHourMin,
} from '@teto/react-component-library';
import React from 'react';
import { TimeCard } from 'teto-client-api';
import LockIcon from '@mui/icons-material/Lock';
import { useTranslation } from 'react-i18next';
import DetailedListDisplay from '../../../../../components/DetailListDisplay/DetailListDisplay';
import TableColumnDefinition from '../../../../../components/TetoGrid/TableColumnDefinition';

interface DailySummaryItemProps {
  timeCard: TimeCard;
  displayList: string[];
  onClick: () => void;
  columns: TableColumnDefinition[];
}

const useStyles = (theme: Theme) => ({
  punchin: {
    alignItems: 'center',
    color: theme.palette.primary.main,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 0,
    height: '100%',
    justifyContent: 'center',
    minWidth: theme.spacing(3.5),
    paddingTop: theme.spacing(5),
    width: theme.spacing(3.5),
  },
  action: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    width: theme.spacing(7),
  },
  rightTop: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 0,
    height: theme.spacing(5),
  },
  rightBottom: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    position: 'sticky',
    top: 0,
  },
  rightTypography: {
    fontWeight: 500,
  },
});

const DailySummaryItem = (props: DailySummaryItemProps) => {
  const { timeCard, displayList, onClick, columns } = props;
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();

  const button = !(timeCard.locked > 0)
    ? {
        icon: <Edit />,
        title: t('forms.editTimecardButton'),
      }
    : {
        icon: <LockIcon color="action" />,
        title: t('forms.lockedTimecards'),
      };

  const punchinDisplay = timeCard.timecardPunchInID ? (
    <Tooltip title={t('Entities.PunchIn.punchInRecord')}>
      <HowToVoteOutlined color="action" />
    </Tooltip>
  ) : (
    ''
  );

  return (
    <DetailedListDisplay
      columns={columns}
      timeCard={timeCard}
      displayList={displayList}
      leftChildren={<Box sx={classes.punchin}>{punchinDisplay}</Box>}
      rightChildren={
        <Box component="div" sx={classes.action}>
          <Box component="div" sx={classes.rightTop}>
            <Typography sx={classes.rightTypography} variant="subtitle2">
              {formatHoursAsHourMin(timeCard.hourTime)}
            </Typography>
          </Box>
          <Box component="div" sx={classes.rightBottom}>
            <ETOIconButton
              data-testid="edit"
              color="primary"
              size="large"
              onClick={onClick}
              tooltipProps={
                {
                  title: button.title,
                } as TooltipProps
              }
            >
              {button.icon}
            </ETOIconButton>
          </Box>
        </Box>
      }
    />
  );
};

export default DailySummaryItem;
