import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { useMediaQuery, useTheme } from '@mui/material';
import {
  AuthContext,
  DateRangeSelector,
  MessageContext,
  SettingsContext,
} from '@teto/react-component-library';
import dayjs from 'dayjs';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { QueryFunctionContext, QueryKey } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import {
  betweenDate,
  deletePayPeriod,
  getPayPeriods,
  Licenses,
  lockPayPeriod,
  PayPeriod,
  unlockPayPeriod,
} from 'teto-client-api';
import { createColumns } from '../../components/TetoGrid/ModelMetaDataProcessor/ModelMetaDataProcessor';
import TetoContainer from '../../components/TetoGrid/TetoContainer';
import TetoGrid from '../../components/TetoGrid/TetoGrid';
import TetoGridRefType from '../../components/TetoGrid/TetoGridRefType';
import { DateRange } from '../../helpers/dateTypes';
import defaultDateRange from '../../helpers/defaultDateRange';
import { parseServerResponse } from '../../helpers/validationHelperREST';
import ActionButton, { OkCancelConfirmDialog } from './components/ActionButton';
import CreatePayPeriodInspector from './components/CreatePayPeriodInspector';
import EditPayPeriodInspector from './components/EditPayPeriodInspector';

const PayPeriodsPage = () => {
  const { t, ready } = useTranslation();
  const TABLE_ID = t('Entities.PayPeriods.tableIdentifier');
  const theme = useTheme();
  const mobileSize = useMediaQuery(theme.breakpoints.down('md'));
  const settingsContext = useContext(SettingsContext);
  const messageContext = useContext(MessageContext);
  const authContext = useContext(AuthContext);

  const navigate = useNavigate();
  const [dataSource, setDataSource] = useState([]);
  const [createPayPeriodInspector, setCreatePayPeriodInspector] =
    useState<boolean>(false);
  const [editPayPeriodInspector, setEditPayPeriodInspector] =
    useState<boolean>(false);
  const [refreshToken, setRefreshToken] = useState<Date | undefined>();
  const [payPeriod, setPayPeriod] = useState<PayPeriod>();

  const { startDate, endDate } = useParams();
  const [queryDateRange, setQueryDateRange] = useState<DateRange>({
    start: startDate
      ? dayjs(startDate)
      : dayjs(
          defaultDateRange(
            settingsContext.settings.startDayOfWeek
          ).start.subtract(1, 'y')
        ),
    end: endDate
      ? dayjs(endDate)
      : dayjs(defaultDateRange(settingsContext.settings.startDayOfWeek).end),
  });

  const gridRef = React.useRef<TetoGridRefType | undefined>();

  useEffect(() => {
    if (!startDate || !endDate) {
      navigate(
        `/timeTracking/payperiods/${queryDateRange.start.format(
          'YYYY-MM-DD'
        )}/${queryDateRange.end.format('YYYY-MM-DD')}`,
        { replace: true }
      );
    }
  }, [endDate, navigate, queryDateRange.end, queryDateRange.start, startDate]);

  const _handleDateChange = (e: DateRange) => {
    setQueryDateRange(e);
    navigate(
      `/timeTracking/payperiods/${e.start.format('YYYY-MM-DD')}/${e.end.format(
        'YYYY-MM-DD'
      )}`
    );
  };

  const _handlePayPeriodDelete = useCallback(
    (data: PayPeriod) => {
      if (data.id) {
        deletePayPeriod(data.id)
          .then(() => {
            setRefreshToken(new Date());
            messageContext.setSuccess(t('generic.message.payPeriodDeleted'));
          })
          .catch((e) => {
            parseServerResponse(
              e,
              (errors) => messageContext.setError(Object.values(errors)[0]),
              (error) => messageContext.setError(error)
            );
          });
      }
    },
    [messageContext, t]
  );

  const _handlePayPeriodLock = useCallback(
    (data: PayPeriod) => {
      if (data.id) {
        lockPayPeriod(data.id)
          .then(() => {
            setRefreshToken(new Date());
            messageContext.setSuccess(t('generic.message.payPeriodLocked'));
          })
          .catch((e) => {
            parseServerResponse(
              e,
              (errors) => messageContext.setError(Object.values(errors)[0]),
              (error) => messageContext.setError(error)
            );
          });
      }
    },
    [messageContext, t]
  );

  const _handlePayPeriodUnlock = useCallback(
    (data: PayPeriod) => {
      if (data.id) {
        unlockPayPeriod(data.id)
          .then(() => {
            setRefreshToken(new Date());
            messageContext.setSuccess(t('generic.message.payPeriodUnlocked'));
          })
          .catch((e) => {
            parseServerResponse(
              e,
              (errors) => messageContext.setError(Object.values(errors)[0]),
              (error) => messageContext.setError(error)
            );
          });
      }
    },
    [messageContext, t]
  );

  const _handlePayPeriodEdit = useCallback((data: PayPeriod) => {
    setEditPayPeriodInspector(true);
    setPayPeriod(data);
  }, []);

  const cols = useMemo(() => {
    if (ready) {
      return createColumns<PayPeriod>()
        .addColumn({
          name: 'startDate',
          title: t('Entities.PayPeriods.startDate'),
          type: 'date',
          align: 'end',
          sortable: true,
          filterType: '',
          filterOptions: undefined,
          fixed: 'none',
          disableGrouping: true,
          disableColumnFilterContextMenu: true,
          disableColumnMenuTool: true,
          flex: 1,
          minWidth: 100,
        })
        .addColumn({
          name: 'endDate',
          title: t('Entities.PayPeriods.endDate'),
          type: 'date',
          align: 'end',
          sortable: true,
          filterType: '',
          filterOptions: undefined,
          fixed: 'none',
          disableGrouping: true,
          disableColumnFilterContextMenu: true,
          disableColumnMenuTool: true,
          flex: 1,
          minWidth: 120,
        })
        .addColumn({
          name: 'hasTimecards',
          title: t('Entities.PayPeriods.hasTimecards'),
          headerAlign: 'center',
          type: 'boolean',
          align: 'center',
          sortable: false,
          filterType: 'boolean',
          filterOptions: 'simple',
          fixed: 'none',
          disableGrouping: true,
          disableColumnFilterContextMenu: true,
          disableColumnMenuTool: true,
          flex: 1,
          minWidth: 80,
        })
        .addColumn({
          name: 'locked',
          title: t('Entities.PayPeriods.locked'),
          type: 'boolean',
          align: 'center',
          sortable: false,
          filterType: 'boolean',
          filterOptions: 'simple',
          fixed: 'none',
          disableGrouping: true,
          disableColumnFilterContextMenu: true,
          disableColumnMenuTool: true,
          flex: 1,
          minWidth: 80,
        })
        .addColumn({
          name: 'exported',
          title: t('Entities.PayPeriods.exported'),
          type: 'boolean',
          align: 'center',
          sortable: false,
          filterType: 'boolean',
          filterOptions: 'simple',
          fixed: 'none',
          disableGrouping: true,
          disableColumnFilterContextMenu: true,
          disableColumnMenuTool: true,
          flex: 1,
          minWidth: 80,
        })
        .addColumn({
          name: 'action',
          title: t('generic.action'),
          type: 'button',
          align: mobileSize ? 'center' : 'end',
          sortable: false,
          editable: false,
          filterType: 'none',
          fixed: 'right',
          disableGrouping: true,
          disableHideable: true,
          disableColumnMenuTool: true,
          flex: mobileSize ? 1 : 0,
          minWidth: mobileSize ? 60 : 144,
          headerProps: {
            style: {
              textAlign: 'center',
            },
          },
          renderFunction: (data: PayPeriod) => (
            <ActionButton
              actionItems={[
                data.locked
                  ? {
                      componentName: t('generic.unlockPayPeriod'),
                      icon: <LockIcon color="primary" />,
                      title: t('generic.unlockPayPeriod'),
                      disabled: !authContext.hasLicense(
                        Licenses.TotalETOProfessional
                      ),
                      handleClick: () => _handlePayPeriodUnlock(data),
                    }
                  : {
                      componentName: t('generic.lockPayPeriod'),
                      icon: <LockOpenIcon color="primary" />,
                      title: t('generic.lockPayPeriod'),
                      disabled: !authContext.hasLicense(
                        Licenses.TotalETOProfessional
                      ),
                      handleClick: () => _handlePayPeriodLock(data),
                    },
                {
                  componentName: t('generic.editPayPeriod'),
                  icon: <EditIcon color="primary" />,
                  disabled: !authContext.hasLicense(
                    Licenses.TotalETOProfessional
                  ),
                  title: t('generic.editPayPeriod'),
                  handleClick: () => _handlePayPeriodEdit(data),
                },
                {
                  componentName: t('generic.deletePayPeriod'),
                  icon: <DeleteIcon color="error" />,
                  disabled: !authContext.hasLicense(
                    Licenses.TotalETOProfessional
                  ),
                  title: t('generic.deletePayPeriod'),
                  handleClick: () => _handlePayPeriodDelete(data),
                  confirm: {
                    type: 'okCancel',
                    title: t('dialogs.deletePayPeriod.title'),
                    content: t('dialogs.deletePayPeriod.content'),
                  } as OkCancelConfirmDialog,
                },
              ]}
            />
          ),
        })
        .finalize(t);
    }
    return [];
  }, [
    _handlePayPeriodDelete,
    _handlePayPeriodEdit,
    _handlePayPeriodLock,
    _handlePayPeriodUnlock,
    mobileSize,
    ready,
    t,
    authContext,
  ]);

  function _doQuery(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    context: QueryFunctionContext<QueryKey, any>,
    pageSize: number,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    queryData: { filters: any; orderBy: any } & { params: DateRange }
  ) {
    return getPayPeriods({
      pageIndex: context.pageParam,
      pageSize,
      disablePaging: false,
      orderBy: queryData.orderBy,
      filter: {
        startDate: betweenDate(queryData.params.start, queryData.params.end),
        ...queryData.filters,
      },
    }).then((d) => ({
      ...d,
      records: d.records,
    }));
  }

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {ready && cols && (
        <TetoContainer>
          <TetoGrid
            dataSource={dataSource}
            setDataSource={setDataSource}
            disableMobileCols
            serverSideFiltering
            ref={gridRef}
            pageSize={50_000}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            doQuery={(a, b, c) => _doQuery(a, b, c as any)}
            columns={cols}
            refreshToken={refreshToken}
            externalQueryProps={queryDateRange}
            header={{
              leftChildren: (
                <DateRangeSelector
                  dates={queryDateRange}
                  onDateChange={(e) => _handleDateChange(e)}
                />
              ),
              rightChildren: [
                {
                  title: t('generic.createPayPeriod'),
                  icon: <AddBoxIcon />,
                  disabled: !authContext.hasLicense(
                    Licenses.TotalETOProfessional
                  ),
                  onclick: () => setCreatePayPeriodInspector(true),
                },
              ],
            }}
            tableIdentifier={TABLE_ID}
            disableGroupByToolbar
            disableConfigureButton
          />
        </TetoContainer>
      )}

      {createPayPeriodInspector && (
        <CreatePayPeriodInspector
          title={t('generic.createPayPeriod')}
          open={createPayPeriodInspector}
          onClose={() => setCreatePayPeriodInspector(false)}
          setRefreshToken={setRefreshToken}
        />
      )}

      {editPayPeriodInspector && (
        <EditPayPeriodInspector
          title={t('generic.editPayPeriod')}
          open={editPayPeriodInspector}
          onClose={() => setEditPayPeriodInspector(false)}
          data={payPeriod as PayPeriod}
          setRefreshToken={setRefreshToken}
        />
      )}
    </>
  );
};

export default PayPeriodsPage;
