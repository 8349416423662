import { ETOTextFieldProps } from '@teto/react-component-library';
import React from 'react';
import InlineEditorProps from '../InlineEditingProps';
import ETOStringEditor from './ETOStringEditor';

type ETOMultilineStringEditorProps = InlineEditorProps &
  Partial<Omit<ETOTextFieldProps, 'value'>>;

const ETOMultilineStringEditor = (props: ETOMultilineStringEditorProps) => (
  <ETOStringEditor {...{ ...props, multiline: true, maxRows: 1 }} />
);

export default ETOMultilineStringEditor;
