import { CellRenderObject } from '@inovua/reactdatagrid-community/Layout/ColumnLayout/Cell/CellProps';
import { SxProps, TextField, useTheme } from '@mui/material';
import { AppearanceContext } from '@teto/react-component-library';
import { uniqueId } from 'lodash';
import React, { useContext } from 'react';
import TableColumnDefinition from '../../TableColumnDefinition';

interface Props {
  col: TableColumnDefinition;
  defaultValue: number;
  // eslint-disable-next-line no-unused-vars
  handleChange: (e: React.BaseSyntheticEvent) => void;
  // eslint-disable-next-line no-unused-vars
  onBlur: (e: React.BaseSyntheticEvent) => void;
  rdgProps: CellRenderObject;
  error: boolean;
  sxProps: SxProps | undefined;
}

const MobileNumberEditor = (props: Props) => {
  const { col, handleChange, defaultValue, onBlur, rdgProps, error, sxProps } =
    props;
  const { cellProps, rowIndex } = rdgProps;
  const { rowIndexInGroup } = cellProps;
  const theme = useContext(AppearanceContext);
  const contrast = useTheme();
  return (
    <TextField
      error={error}
      variant="outlined"
      type="number"
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      sx={{
        height: '50px',
        mr: 1,
        p: 0,
        display: 'block',
        position: 'relative',
        top: '-8px',
        '& .MuiInputBase-input ': {
          padding: 0,
          fontSize: 14,
          backgroundColor:
            // if grouping is less than one it renders the row grey but the textfield not white
            // || rowIndexInGroup === 0
            (rowIndexInGroup || rowIndex) % 2 === 0 || rowIndexInGroup === 0
              ? '#fff !important'
              : 'rgb(237,237,237) !important',
          paddingLeft: '8px',
          height: '35px',
          color:
            theme.themeVariant === 'dark'
              ? contrast.palette.getContrastText(contrast.palette.text.primary)
              : 'text.primary',
          ...sxProps,
        },
      }}
      key={uniqueId(col.id)}
      name={col.name}
      onChange={handleChange}
      defaultValue={defaultValue}
      onBlur={onBlur}
    />
  );
};

export default MobileNumberEditor;
