/* eslint-disable comma-dangle */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable radix */
/* eslint-disable react/jsx-props-no-spreading */
import { Alert, Box, Grid, Snackbar, Theme, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
  AuthContext,
  BarcodeReader,
  ButtonStrip,
  ButtonStripProps,
  ETOCheckBox,
  ETODateField,
  ETOSelectField,
  ETOTextField,
  SettingsContext,
  useBarcodeScannerListener,
} from '@teto/react-component-library';
import { FormikErrors } from 'formik';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import {
  AuthenticatedUser,
  Employee,
  getJob,
  getNonConformance,
  getProcessScheduleDetail,
  getSystemSettings,
  HourType,
  Job,
  NonConformance,
  Permission,
  ProcessScheduleDetail,
  Project,
} from 'teto-client-api';
import InlineHelpLink from '../../HelpLinks/InlineHelpLink/InlineHelpLink';
import CommonFormValues from './CommonFormValues';

import ETOHourField from './ETOHourField';
import { CommonFields, CustomFields, OptionalFields } from './Fields';
import FieldsDisableEditableType from './FieldsDisableEditableType';
import isDisabled from './isDisabled';

const wrapperSx = {
  overflow: 'hidden',
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
};

const contentSx = {
  overflowY: 'auto',
  display: 'flex',
};

const buttonRowSx = (theme: Theme) => ({
  flex: `0 0 ${theme.spacing(7.5)}`,
  flexGrow: 0,
});

const buttonStripSx = {
  borderTop: 0,
};

const quantityWrapSx = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
};

const qrRowSx = (theme: Theme) => ({
  flex: 1,
  display: 'flex',
  overflow: 'hidden',
  borderTop: '2px solid rgba(0, 0, 0, 0.12)',
  paddingTop: theme.spacing(1.5),
  marginRight: '1px',
  minHeight: theme.spacing(41),
});

export type CustomFieldCaptions = Record<CustomFields, string>;

export const DefaultCustomFieldCaptions: CustomFieldCaptions = {
  custom1: 'Timecard Custom 1',
  custom2: 'Timecard Custom 2',
  custom3: 'Timecard Custom 3',
  custom4: 'Timecard Custom 4',
  custom5: 'Timecard Custom 5',
  custom6: 'Timecard Custom 6',
  custom7: 'Timecard Custom 7',
  custom8: 'Timecard Custom 8',
  pSCCustom1: 'Process Schedule In House Transaction Custom 1',
  pSCCustom2: 'Process Schedule In House Transaction Custom 2',
  pSCCustom3: 'Process Schedule In House Transaction Custom 3',
  pSCCustom4: 'Process Schedule In House Transaction Custom 4',
  pSCCustom5: 'Process Schedule In House Transaction Custom 5',
  pSCCustom6: 'Process Schedule In House Transaction Custom 6',
  pSCCustom7: 'Process Schedule In House Transaction Custom 7',
  pSCCustom8: 'Process Schedule In House Transaction Custom 8',
};

export const isCustomCaption = (field: CustomFields, caption: string) =>
  DefaultCustomFieldCaptions[field] !== caption;

interface TimeCardFormProps {
  disableAutoFocus: boolean;
  isEditMode: boolean;
  qrEnabled: boolean;
  disableOverlay?: boolean;
  isSubmitting: boolean;
  canAffect: boolean;
  values: CommonFormValues & { hour?: number };
  origValues: CommonFormValues & { hour?: number };
  errors: FormikErrors<CommonFormValues>;
  submitForm?: () => void;

  fieldCaptions: {
    // eslint-disable-next-line no-unused-vars
    [key in CommonFields | OptionalFields | CustomFields]: string;
  };
  fieldsDisableEditable: boolean | Partial<FieldsDisableEditableType>;
  getEmployees: (() => Promise<Employee[]>) | AuthenticatedUser;
  totalHours?: (() => number) | (() => string);
  getProjects: () => Promise<Project[]>;
  // eslint-disable-next-line no-unused-vars
  getJobs: (projectId: number) => Promise<Job[]>;
  getNonConformances: (
    // eslint-disable-next-line no-unused-vars
    projectId: number,
    // eslint-disable-next-line no-unused-vars
    specId: number
  ) => Promise<NonConformance[]>;
  getProcessSchedules: (
    // eslint-disable-next-line no-unused-vars
    projectId: number,
    // eslint-disable-next-line no-unused-vars
    specId: number
  ) => Promise<ProcessScheduleDetail[]>;
  getHourTypes: (
    // eslint-disable-next-line no-unused-vars
    projectId?: number,
    // eslint-disable-next-line no-unused-vars
    specId?: number,
    // eslint-disable-next-line no-unused-vars
    processScheduleDetailId?: number,
    // eslint-disable-next-line no-unused-vars
    nonConformanceId?: number,
    // eslint-disable-next-line no-unused-vars
    employeeId?: number
  ) => Promise<HourType[]>;
  setFieldValue: (
    // eslint-disable-next-line no-unused-vars
    field: string,
    // eslint-disable-next-line no-unused-vars
    value: any,
    // eslint-disable-next-line no-unused-vars
    shouldValidate?: boolean | undefined
  ) => Promise<FormikErrors<CommonFormValues>> | Promise<void>;
  handleChange: {
    // eslint-disable-next-line no-unused-vars
    (e: React.ChangeEvent<any>): void;
    // eslint-disable-next-line no-unused-vars
    <T_1 = string | React.ChangeEvent<any>>(
      // eslint-disable-next-line no-unused-vars
      field: T_1
    ): T_1 extends React.ChangeEvent<any>
      ? void
      : // eslint-disable-next-line no-unused-vars
        (e: string | React.ChangeEvent<any>) => void;
  };
  buttonStrip?: ButtonStripProps;
  children?: React.ReactNode;
}

const TimeCardForm = (props: TimeCardFormProps) => {
  const {
    totalHours,
    handleChange,
    origValues,
    values,
    getProjects,
    getJobs,
    getNonConformances,
    getHourTypes,
    getProcessSchedules,
    fieldsDisableEditable,
    fieldCaptions,
    errors,
    buttonStrip,
    setFieldValue,
    submitForm,
    isSubmitting,
    canAffect,
    getEmployees,
    qrEnabled,
    disableOverlay,
    children,
    disableAutoFocus,
    // eslint-disable-next-line no-unused-vars
    isEditMode,
  } = props;

  const {
    specId,
    projectId,
    nonConformanceId,
    employeeId,
    processScheduleDetailId,
  } = values;
  const [error, setError] = useState('');
  const [cameraPermission, setCameraPermission] = useState(true);
  const projects = useQuery('projects', getProjects);
  const hourTypes = useQuery(
    [
      'hourTypes',
      projectId,
      specId,
      nonConformanceId,
      employeeId,
      processScheduleDetailId,
    ],
    () =>
      getHourTypes(
        values.projectId,
        values.specId,
        values.processScheduleDetailId,
        values.nonConformanceId,
        values.employeeId
      )
  );
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const settingsContext = useContext(SettingsContext);
  const [disableHourFactorEdit, setDisableHourFactorEdit] = useState(
    settingsContext.settings.enableHourFactorInMyTimecards
  );

  useEffect(() => {
    getSystemSettings()
      .then((d) => {
        setDisableHourFactorEdit((d as any).teHideHourFactorField);
      })
      // eslint-disable-next-line no-console
      .catch((e) => console.error(e));
  }, []);
  useEffect(() => {
    async function checkCameraPermission() {
      await navigator.permissions
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .query({ name: 'camera' })
        .then((res) => {
          if (res.state !== 'granted') {
            return setCameraPermission(false);
          }
          return setCameraPermission(true);
        })
        // eslint-disable-next-line no-console
        .catch((e) => console.warn(e));
    }
    checkCameraPermission();
  }, []);
  const jobs = useQuery(
    ['jobs', projectId],
    () => getJobs(parseInt(projectId as unknown as string)),
    {
      enabled: parseInt(projectId as unknown as string) > 0,
    }
  );

  const _handleErrorClose = () => {
    setError('');
  };

  const nonConformances = useQuery(
    ['nonConformances', projectId, specId],
    () =>
      getNonConformances(
        parseInt(projectId as unknown as string),
        parseFloat(specId as unknown as string)
      ),
    {
      enabled:
        parseInt(projectId as unknown as string) > 0 &&
        parseFloat(specId as unknown as string) > 0,
    }
  );

  const processScheduleDetails = useQuery(
    ['processScheduleDetails', projectId, specId],
    () =>
      getProcessSchedules(
        parseInt(projectId as unknown as string),
        parseFloat(specId as unknown as string)
      ),
    {
      enabled:
        parseInt(projectId as unknown as string) > 0 &&
        parseFloat(specId as unknown as string) > 0,
    }
  );

  const employees = useQuery(
    ['timecard-employees'],
    () =>
      typeof getEmployees === 'function' ? getEmployees() : Promise.resolve([]),
    {
      enabled: typeof getEmployees === 'function',
    }
  );

  const _showError = (e: Error) => {
    setError(e.message);
  };

  const _getAndSetJob = (autoId: number) =>
    getJob(autoId)
      .then((j) => {
        setFieldValue('projectId', j.projectId);
        setFieldValue('specId', j.id.toFixed(10));
      })
      .catch(() => {
        _showError(new Error('Failed to find scanned record'));
        setFieldValue('specId', '');
      });

  const _getAndSetProcessSchedule = (id: number) =>
    getProcessScheduleDetail(id)
      .then((psd) => {
        setFieldValue('projectId', psd.projectId);
        setFieldValue('specId', psd.specId);
        setFieldValue('processScheduleDetailId', psd.id);
      })
      .catch(() => {
        _showError(new Error('Failed to find scanned record'));
        setFieldValue('processScheduleDetailId', '');
      });

  const _getAndSetNonConformance = (id: number) =>
    getNonConformance(id)
      .then((nc) => {
        setFieldValue('projectId', nc.projectId);
        setFieldValue('specId', nc.specId);
        setFieldValue('nonConformanceId', nc.id);
      })
      .catch(() => {
        _showError(new Error('Failed to find scanned record'));
        setFieldValue('nonConformanceId', '');
      });

  useEffect(() => {
    if (values.projectId !== origValues.projectId) {
      setFieldValue('specId', '');
      setFieldValue('nonConformanceId', '');
      setFieldValue('processScheduleDetailId', '');
    }
  }, [values.projectId, setFieldValue, origValues.projectId]);

  useEffect(() => {
    if (values.specId !== origValues.specId) {
      setFieldValue('nonConformanceId', '');
      setFieldValue('processScheduleDetailId', '');
    }
  }, [values.specId, setFieldValue, origValues.specId]);

  useEffect(() => {
    if (values.processScheduleDetailId !== origValues.processScheduleDetailId) {
      setFieldValue('quantity', '0');
    }
  }, [
    values.processScheduleDetailId,
    setFieldValue,
    origValues.processScheduleDetailId,
  ]);

  const prefixes = ['EMP', 'PSD', 'HRT', 'NCO', 'PRJ', 'JOB', 'COM', 'CLO'];
  // eslint-disable-next-line no-unused-vars
  const [_qrCode, setScan] = useBarcodeScannerListener(
    prefixes,
    async (prefix, val) => {
      const prefixUpperCase = prefix.toUpperCase();
      switch (prefixUpperCase) {
        case 'PRJ':
          setFieldValue('projectId', parseInt(val, 10));
          break;
        case 'HRT':
          setFieldValue('hourTypeId', parseInt(val, 10));
          break;
        case 'NCO':
          await _getAndSetNonConformance(parseInt(val, 10));
          break;
        case 'JOB':
          await _getAndSetJob(parseInt(val, 10));
          break;
        case 'PSD':
          await _getAndSetProcessSchedule(parseInt(val, 10));
          break;
        case 'EMP':
          setFieldValue('employeeId', parseInt(val, 10));
          break;
        case 'COM':
        case 'CLO':
          if (submitForm) {
            submitForm();
          }
          break;
        default:
          break;
      }
    }
  );

  const processSchedule = useMemo(() => {
    if (!values.processScheduleDetailId || !processScheduleDetails)
      return undefined;
    return (processScheduleDetails.data ?? []).find(
      // eslint-disable-next-line eqeqeq
      (a) => a.id == values.processScheduleDetailId
    );
  }, [values.processScheduleDetailId, processScheduleDetails]);

  useEffect(() => {
    if (processSchedule && processSchedule.processDefaultHourType) {
      setFieldValue('hourTypeId', processSchedule.processDefaultHourType);
    }
  }, [processSchedule, setFieldValue]);

  const hasEmployeeSelection = typeof getEmployees === 'function';
  const hasProcessSchedule = Boolean(processSchedule);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={wrapperSx}>
        <Grid container spacing={0} sx={contentSx}>
          {children}
          {hasEmployeeSelection && (
            <Grid item xs={12}>
              <Box m={2} mb={0}>
                <ETOSelectField
                  disabled={isDisabled(
                    canAffect,
                    'employeeId',
                    fieldsDisableEditable
                  )}
                  autoFocus={!disableAutoFocus}
                  name="employeeId"
                  handleChange={handleChange}
                  error={errors.employeeId}
                  items={employees.data ?? []}
                  itemNameSelector={(item) =>
                    `${!item.active ? '(inactive) ' : ''}${
                      item.employeeNumber
                    } - ${item.firstName} ${item.lastName}`
                  }
                  itemValueSelector={(item) => item.id}
                  itemDisabledSelector={(item) => !item.active}
                  value={values.employeeId}
                  defaultValue={-2}
                  label={fieldCaptions.employeeId}
                />
              </Box>
            </Grid>
          )}
          <Grid item xs={12}>
            <Box m={2} mb={0}>
              <ETOSelectField
                name="projectId"
                autoFocus={!disableAutoFocus}
                disabled={isDisabled(
                  canAffect,
                  'projectId',
                  fieldsDisableEditable
                )}
                handleChange={handleChange}
                error={errors.projectId}
                items={projects.data ?? []}
                itemNameSelector={(item) =>
                  item.displayName ?? item.id.toString()
                }
                itemValueSelector={(item) => item.id}
                value={values.projectId}
                defaultValue={-1}
                label={fieldCaptions.projectId}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box m={2} mb={0}>
              <ETOSelectField
                name="specId"
                disabled={isDisabled(
                  canAffect,
                  'specId',
                  fieldsDisableEditable
                )}
                handleChange={handleChange}
                error={errors.specId}
                items={jobs.data ?? []}
                itemNameSelector={(item) => item.displayName ?? ''}
                itemValueSelector={(item) => item.id.toFixed(10)}
                value={parseFloat(values.specId?.toString()).toFixed(10)}
                defaultValue={-1}
                label={fieldCaptions.specId}
              />
            </Box>
          </Grid>
          <Grid item xs={8} sm={8}>
            <Box m={2} mb={1}>
              <ETOSelectField
                name="hourTypeId"
                disabled={isDisabled(
                  canAffect,
                  'hourTypeId',
                  fieldsDisableEditable
                )}
                handleChange={handleChange}
                error={errors.hourTypeId}
                items={hourTypes.data ?? []}
                itemNameSelector={(item) =>
                  item.displayName ?? item.id.toString()
                }
                itemValueSelector={(item) => item.id}
                value={values.hourTypeId}
                defaultValue={-1}
                label={fieldCaptions.hourTypeId}
              />
            </Box>
          </Grid>
          <Grid item xs={4} sm={4}>
            <Box m={2} mb={0}>
              <ETOHourField
                value={totalHours ? totalHours() : values.hour}
                handleChange={handleChange}
                label={fieldCaptions.hour}
                name="hour"
                disabled={
                  Boolean(totalHours) ||
                  isDisabled(canAffect, 'hour', fieldsDisableEditable)
                }
              />
            </Box>
          </Grid>
          {authContext.authenticated &&
            ((hasEmployeeSelection &&
              authContext.hasAnyPermission([
                Permission.Modify_Admin_Data_HourInformationDetails,
                Permission.View_Admin_Data_HourInformationDetails,
              ])) ||
              (!hasEmployeeSelection && !disableHourFactorEdit)) && (
              <>
                <Grid item xs={isEditMode ? 6 : 12}>
                  <Box m={2} mb={0}>
                    <ETOTextField
                      name="hourFactor"
                      type="number"
                      handleChange={handleChange}
                      inputLabelProps={{ shrink: true }} // https://mui.com/material-ui/react-text-field/#shrink fixes overlapping label
                      inputProps={{ min: 0, step: '0.01' }}
                      disabled={
                        isDisabled(
                          canAffect,
                          'hourFactor',
                          fieldsDisableEditable
                        ) ||
                        !authContext.hasPermission(
                          Permission.Modify_Admin_Data_HourInformationDetails
                        )
                      }
                      error={errors.hourFactor}
                      label={fieldCaptions.hourFactor}
                      value={values.hourFactor}
                    />
                  </Box>
                </Grid>
                {isEditMode &&
                  hasEmployeeSelection &&
                  authContext.hasAnyPermission([
                    Permission.Modify_Admin_Data_HourInformationDetails,
                    Permission.View_Admin_Data_HourInformationDetails,
                  ]) && (
                    <Grid item xs={6}>
                      <Box m={2} mb={0}>
                        <ETOTextField
                          name="hourRate"
                          type="number"
                          handleChange={handleChange}
                          inputLabelProps={{ shrink: true }}
                          inputProps={{ min: 0, step: '0.01' }}
                          disabled={
                            isDisabled(
                              canAffect,
                              'hourRate',
                              fieldsDisableEditable
                            ) ||
                            !authContext.hasPermission(
                              Permission.Modify_Admin_Data_HourInformationDetails
                            )
                          }
                          error={errors.hourRate}
                          label={fieldCaptions.hourRate}
                          value={values.hourRate}
                        />
                      </Box>
                    </Grid>
                  )}
              </>
            )}
          <Grid item xs={12}>
            <Box m={2} mb={0}>
              <ETOTextField
                name="comments"
                handleChange={handleChange}
                value={values.comments}
                disabled={isDisabled(
                  canAffect,
                  'comments',
                  fieldsDisableEditable
                )}
                error={errors.comments}
                label={fieldCaptions.comments}
                multiline
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box m={2} mb={0}>
              <ETOSelectField
                name="nonConformanceId"
                disabled={isDisabled(
                  canAffect,
                  'nonConformanceId',
                  fieldsDisableEditable
                )}
                handleChange={handleChange}
                error={errors.nonConformanceId}
                items={nonConformances.data ?? []}
                itemNameSelector={(item) => `${item.id} - ${item.title}`}
                itemValueSelector={(item) => item.id}
                value={values.nonConformanceId}
                defaultValue={-1}
                label={fieldCaptions.nonConformanceId}
              />
            </Box>
          </Grid>
          {isCustomCaption('custom1', fieldCaptions.custom1) && (
            <Grid item xs={12}>
              <Box m={2} mb={0}>
                <ETOTextField
                  name="custom1"
                  handleChange={handleChange}
                  disabled={isDisabled(
                    canAffect,
                    'custom1',
                    fieldsDisableEditable
                  )}
                  error={errors.custom1}
                  label={fieldCaptions.custom1}
                  value={values.custom1}
                />
              </Box>
            </Grid>
          )}
          {isCustomCaption('custom2', fieldCaptions.custom2) && (
            <Grid item xs={12}>
              <Box m={2} mb={0}>
                <ETOTextField
                  name="custom2"
                  handleChange={handleChange}
                  disabled={isDisabled(
                    canAffect,
                    'custom2',
                    fieldsDisableEditable
                  )}
                  error={errors.custom2}
                  label={fieldCaptions.custom2}
                  value={values.custom2}
                />
              </Box>
            </Grid>
          )}
          {isCustomCaption('custom3', fieldCaptions.custom3) && (
            <Grid item xs={12}>
              <Box m={2} mb={0}>
                <ETOTextField
                  name="custom3"
                  type="number"
                  handleChange={handleChange}
                  disabled={isDisabled(
                    canAffect,
                    'custom3',
                    fieldsDisableEditable
                  )}
                  error={errors.custom3}
                  label={fieldCaptions.custom3}
                  value={values.custom3}
                />
              </Box>
            </Grid>
          )}
          {isCustomCaption('custom4', fieldCaptions.custom4) && (
            <Grid item xs={12}>
              <Box m={2} mb={0}>
                <ETOTextField
                  name="custom4"
                  type="number"
                  handleChange={handleChange}
                  disabled={isDisabled(
                    canAffect,
                    'custom4',
                    fieldsDisableEditable
                  )}
                  error={errors.custom4}
                  label={fieldCaptions.custom4}
                  value={values.custom4}
                />
              </Box>
            </Grid>
          )}
          {isCustomCaption('custom5', fieldCaptions.custom5) && (
            <Grid item xs={12}>
              <Box m={2} mb={0}>
                <ETODateField
                  name="custom5"
                  inputFormat={settingsContext.settings.dateFormat}
                  value={values.custom5}
                  handleChange={(v) => setFieldValue('custom5', v)}
                  error={errors.custom5}
                  label={fieldCaptions.custom5}
                  disabled={isDisabled(
                    canAffect,
                    'custom5',
                    fieldsDisableEditable
                  )}
                  clearable
                  data-testid="custom5"
                />
              </Box>
            </Grid>
          )}
          {isCustomCaption('custom6', fieldCaptions.custom6) && (
            <Grid item xs={12}>
              <Box m={2} mb={0}>
                <ETODateField
                  name="custom6"
                  inputFormat={settingsContext.settings.dateFormat}
                  value={values.custom6}
                  handleChange={(v) => setFieldValue('custom6', v)}
                  error={errors.custom6}
                  label={fieldCaptions.custom6}
                  disabled={isDisabled(
                    canAffect,
                    'custom6',
                    fieldsDisableEditable
                  )}
                  clearable
                  data-testid="custom6"
                />
              </Box>
            </Grid>
          )}
          {isCustomCaption('custom7', fieldCaptions.custom7) && (
            <Grid item xs={12}>
              <Box m={2} mb={0}>
                <ETOCheckBox
                  name="custom7"
                  handleChange={handleChange}
                  label={fieldCaptions.custom7}
                  value={values.custom7}
                  disabled={isDisabled(
                    canAffect,
                    'custom7',
                    fieldsDisableEditable
                  )}
                />
              </Box>
            </Grid>
          )}
          {isCustomCaption('custom8', fieldCaptions.custom8) && (
            <Grid item xs={12}>
              <Box m={2} mb={0}>
                <ETOCheckBox
                  name="custom8"
                  handleChange={handleChange}
                  label={fieldCaptions.custom8}
                  value={values.custom8}
                  disabled={isDisabled(
                    canAffect,
                    'custom8',
                    fieldsDisableEditable
                  )}
                />
              </Box>
            </Grid>
          )}
          <Grid item md={8} xs={12}>
            <Box m={2} mb={0}>
              <ETOSelectField
                name="processScheduleDetailId"
                disabled={isDisabled(
                  canAffect,
                  'processScheduleDetailId',
                  fieldsDisableEditable
                )}
                handleChange={handleChange}
                error={errors.processScheduleDetailId}
                items={processScheduleDetails.data ?? []}
                itemNameSelector={(item) => `${item.displayName}`}
                itemValueSelector={(item) => item.id}
                value={values.processScheduleDetailId}
                defaultValue={-1}
                label={fieldCaptions.processScheduleDetailId}
              />
            </Box>
          </Grid>
          <Grid item md={4} xs={12}>
            <Box m={2} mb={0}>
              <ETOTextField
                name="quantity"
                type="number"
                handleChange={handleChange}
                inputProps={{ min: 0, step: '0.01' }}
                disabled={
                  isDisabled(canAffect, 'quantity', fieldsDisableEditable) ||
                  !hasProcessSchedule
                }
                error={errors.quantity}
                label={fieldCaptions.quantity}
                value={values.quantity}
              />
            </Box>
          </Grid>
          {hasProcessSchedule && (
            <Grid item md={12} xs={12}>
              <Box m={2} mb={0} sx={quantityWrapSx}>
                <Typography variant="subtitle1">
                  {t(
                    'Entities.Process Schedule In House Transaction.quantityIssued'
                  )}
                  : {processSchedule?.quantityIssued}
                </Typography>
                <Typography variant="subtitle1">
                  {t(
                    'Entities.Process Schedule In House Transaction.quantityOutstanding'
                  )}
                  : {processSchedule?.quantityOutstanding}
                </Typography>
              </Box>
            </Grid>
          )}
          {hasProcessSchedule &&
            isCustomCaption('pSCCustom1', fieldCaptions.pSCCustom1) && (
              <Grid item xs={12}>
                <Box m={2} mb={0}>
                  <ETOTextField
                    name="pSCCustom1"
                    handleChange={handleChange}
                    disabled={isDisabled(
                      canAffect,
                      'pSCCustom1',
                      fieldsDisableEditable
                    )}
                    error={errors.pSCCustom1}
                    label={fieldCaptions.pSCCustom1}
                    value={values.pSCCustom1}
                  />
                </Box>
              </Grid>
            )}
          {hasProcessSchedule &&
            isCustomCaption('pSCCustom2', fieldCaptions.pSCCustom2) && (
              <Grid item xs={12}>
                <Box m={2} mb={0}>
                  <ETOTextField
                    name="pSCCustom2"
                    handleChange={handleChange}
                    disabled={isDisabled(
                      canAffect,
                      'pSCCustom2',
                      fieldsDisableEditable
                    )}
                    error={errors.pSCCustom2}
                    label={fieldCaptions.pSCCustom2}
                    value={values.pSCCustom2}
                  />
                </Box>
              </Grid>
            )}
          {hasProcessSchedule &&
            isCustomCaption('pSCCustom3', fieldCaptions.pSCCustom3) && (
              <Grid item xs={12}>
                <Box m={2} mb={0}>
                  <ETOTextField
                    name="pSCCustom3"
                    type="number"
                    handleChange={handleChange}
                    disabled={isDisabled(
                      canAffect,
                      'pSCCustom3',
                      fieldsDisableEditable
                    )}
                    error={errors.pSCCustom3}
                    label={fieldCaptions.pSCCustom3}
                    value={values.pSCCustom3}
                  />
                </Box>
              </Grid>
            )}
          {hasProcessSchedule &&
            isCustomCaption('pSCCustom4', fieldCaptions.pSCCustom4) && (
              <Grid item xs={12}>
                <Box m={2} mb={0}>
                  <ETOTextField
                    name="pSCCustom4"
                    type="number"
                    handleChange={handleChange}
                    disabled={isDisabled(
                      canAffect,
                      'pSCCustom4',
                      fieldsDisableEditable
                    )}
                    error={errors.pSCCustom4}
                    label={fieldCaptions.pSCCustom4}
                    value={values.pSCCustom4}
                  />
                </Box>
              </Grid>
            )}
          {hasProcessSchedule &&
            isCustomCaption('pSCCustom5', fieldCaptions.pSCCustom5) && (
              <Grid item xs={12}>
                <Box m={2} mb={0}>
                  <ETODateField
                    name="pSCCustom5"
                    inputFormat={settingsContext.settings.dateTimeFormat}
                    value={values.pSCCustom5}
                    handleChange={(v) => setFieldValue('pSCCustom5', v)}
                    error={errors.pSCCustom5}
                    label={fieldCaptions.pSCCustom5}
                    disabled={isDisabled(
                      canAffect,
                      'pSCCustom5',
                      fieldsDisableEditable
                    )}
                  />
                </Box>
              </Grid>
            )}
          {hasProcessSchedule &&
            isCustomCaption('pSCCustom6', fieldCaptions.pSCCustom6) && (
              <Grid item xs={12}>
                <Box m={2} mb={0}>
                  <ETODateField
                    name="pSCCustom6"
                    inputFormat={settingsContext.settings.dateTimeFormat}
                    value={values.custom6}
                    handleChange={(v) => setFieldValue('pSCCustom6', v)}
                    error={errors.pSCCustom6}
                    label={fieldCaptions.pSCCustom6}
                    disabled={isDisabled(
                      canAffect,
                      'pSCCustom6',
                      fieldsDisableEditable
                    )}
                  />
                </Box>
              </Grid>
            )}
          {hasProcessSchedule &&
            isCustomCaption('pSCCustom7', fieldCaptions.pSCCustom7) && (
              <Grid item xs={12}>
                <Box m={2} mb={0}>
                  <ETOCheckBox
                    name="pSCCustom7"
                    handleChange={handleChange}
                    label={fieldCaptions.pSCCustom7}
                    value={values.pSCCustom7}
                    disabled={isDisabled(
                      canAffect,
                      'pSCCustom7',
                      fieldsDisableEditable
                    )}
                  />
                </Box>
              </Grid>
            )}
          {hasProcessSchedule &&
            isCustomCaption('pSCCustom8', fieldCaptions.pSCCustom8) && (
              <Grid item xs={12}>
                <Box m={2} mb={0}>
                  <ETOCheckBox
                    name="pSCCustom8"
                    handleChange={handleChange}
                    label={fieldCaptions.pSCCustom8}
                    value={values.pSCCustom8}
                    disabled={isDisabled(
                      canAffect,
                      'pSCCustom8',
                      fieldsDisableEditable
                    )}
                  />
                </Box>
              </Grid>
            )}
        </Grid>
        {buttonStrip && (
          <Box sx={buttonRowSx}>
            <ButtonStrip
              className={buttonStripSx}
              size={buttonStrip.size}
              leftButton={
                buttonStrip.leftButton
                  ? {
                      ...buttonStrip.leftButton,
                      disabled:
                        buttonStrip.leftButton.disabled ||
                        isSubmitting ||
                        !canAffect,
                    }
                  : undefined
              }
              rightButton={{
                ...buttonStrip.rightButton,
                disabled:
                  buttonStrip.rightButton.disabled ||
                  isSubmitting ||
                  !canAffect,
              }}
            />
          </Box>
        )}
        {qrEnabled && (
          <>
            <Box sx={qrRowSx}>
              <BarcodeReader
                enabled={Boolean(qrEnabled)}
                onRead={(e) => setScan(e)}
                disableOverlay={!cameraPermission || disableOverlay}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              {!cameraPermission && (
                <>
                  <Typography color="error">{`${t(
                    'generic.browserPermissions.noCamera'
                  )}`}</Typography>
                  <InlineHelpLink
                    helpId="5743329873940"
                    subSectionId="Allow-Browser-to-Access-Your-Camera#allow-camera-access-upon-first-visit-0-1"
                    title={t('generic.browserPermissions.cameraAccess')}
                  />
                </>
              )}
            </Box>
          </>
        )}
      </Box>
      <Snackbar
        open={Boolean(error)}
        autoHideDuration={6000}
        onClose={() => _handleErrorClose()}
      >
        <Alert
          onClose={() => _handleErrorClose()}
          severity="error"
          data-testid="error-display"
        >
          {error}
        </Alert>
      </Snackbar>
    </LocalizationProvider>
  );
};

export default TimeCardForm;
