import React from 'react';
import { AuthenticatedUser } from 'teto-client-api';
import TimerForm from './TimerForm';
import TimerFormProps from './TimerFormProps';

interface StartTimerFormProps
  extends Omit<Omit<TimerFormProps, 'editMode'>, 'getEmployees'> {
  employee: AuthenticatedUser;
  // eslint-disable-next-line no-unused-vars
  onError: (err: string | Error) => void;
  // eslint-disable-next-line no-unused-vars
  setFormDirty?: (val: boolean) => void;
}

const StartTimerForm = (props: StartTimerFormProps) => {
  const {
    allowEmployeeSelection,
    getHourTypes,
    getJobs,
    getNonConformances,
    getProcessSchedules,
    employee,
    fieldCaptions,
    fieldsDisableEditable,
    getProjects,
    initialValues,
    onSubmit,
    qrEnabled,
    onError,
    canUseTimer,
    setFormDirty,
  } = props;

  return (
    <TimerForm
      setFormDirty={setFormDirty}
      canUseTimer={canUseTimer}
      onError={onError}
      buttons={{
        right: {
          title: 'Start Timer',
        },
      }}
      employee={employee}
      qrEnabled={qrEnabled}
      getHourTypes={getHourTypes}
      getJobs={getJobs}
      getNonConformances={getNonConformances}
      getProcessSchedules={getProcessSchedules}
      fieldCaptions={fieldCaptions}
      fieldsDisableEditable={fieldsDisableEditable}
      getProjects={getProjects}
      initialValues={initialValues}
      onSubmit={onSubmit}
      allowEmployeeSelection={allowEmployeeSelection}
    />
  );
};

export default StartTimerForm;
