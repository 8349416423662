import Box from '@mui/material/Box';
import { SxProps, Theme } from '@mui/material/styles';
import { AuthContext, ETOTextFieldProps } from '@teto/react-component-library';
import { useFormikContext } from 'formik';
import React, { useContext, useMemo } from 'react';
import { Permission } from 'teto-client-api';
import { getDisabledValue, onFocus, onKeyDown } from '../editingHelpers';
import { Errors, Values } from '../editorTypes';
import editorStyles from '../InlineEditingCommonStyles';
import InlineEditorProps from '../InlineEditingProps';

interface StandardEditorWrapperProps extends InlineEditorProps {
  child: React.ReactElement;
  customCellSx?: SxProps;
}
interface StandardEditorChildProps extends ETOTextFieldProps {
  customSx?: SxProps;
}

const localSx: SxProps<Theme> = {
  '& .MuiInputBase-root, & .MuiFormControl-root': {
    height: '100%',
  },
  '& .MuiFormHelperText-root': {
    fontSize: '0.9rem',
    position: 'absolute',
    zIndex: 1500,
    bottom: '-30px',
    backgroundColor: 'rgba(97, 97, 97, 0.92)',
    marginLeft: 0,
    marginRight: 0,
    color: (theme) => `${theme.palette.common.white} !important`,
    padding: (theme) => `${theme.spacing(0)} ${theme.spacing(1.75)}`,
    borderRadius: (theme) => theme.spacing(0.5),
    '& .Mui-error': {
      color: (theme) => `${theme.palette.common.white} !important`,
      '&:hover': {
        color: 'common.white',
      },
    },
  },
};

const StandardEditorWrapper: React.FC<StandardEditorWrapperProps> = (props) => {
  const authContext = useContext(AuthContext);
  const {
    autoFocus,
    cellProps,
    editorProps,
    onCancel,
    onComplete,
    onTabNavigation,
    child,
    customCellSx,
  } = props;
  const formik = useFormikContext();
  const columnName = cellProps?.name as string;
  const { errors, values } = formik;

  const isDisabled = useMemo(() => {
    if (!editorProps?.hasEditPermission) return true;
    if (getDisabledValue(cellProps?.data, editorProps?.disabled)) return true;
    if (editorProps?.lockColumn?.(columnName, cellProps?.data)) return true;
    if (
      columnName === 'hourRate' &&
      !authContext?.hasPermission(
        Permission.Modify_Admin_Data_HourInformationDetails
      )
    )
      return true;
    if (
      columnName === 'hourFactor' &&
      !authContext.hasPermission(
        Permission.Modify_Admin_Data_HourInformationDetails
      )
    )
      return true;

    return false;
  }, [authContext, cellProps?.data, columnName, editorProps]);

  const editHandlers = {
    onCancel,
    onComplete,
    onTabNavigation,
  };

  const childProps: StandardEditorChildProps = {
    autoFocus: autoFocus ?? true,
    customSx: editorStyles.input,
    disabled: isDisabled,
    error: errors[columnName as keyof Errors],
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => {
      formik.handleChange(e);
    },
    name: columnName,
    inputProps: {
      onFocus,
      onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) =>
        onKeyDown(e, editHandlers),
      style: {
        minWidth: Math.max(0, (cellProps?.computedWidth as number) - 30),
      },
      tabIndex: 0,
    },
    size: 'small',
    value: (values as Values)?.[columnName] ?? '',
  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {columnName && !isDisabled && (
        <Box
          sx={[
            customCellSx as { [key: string]: unknown },
            editorStyles.root,
            localSx,
          ]}
          data-testid="standard-container"
        >
          {React.cloneElement(child, { ...childProps })}
        </Box>
      )}
    </>
  );
};

export default StandardEditorWrapper;
