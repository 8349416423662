export const URL = '/timeTracking/myTimeTracker/timesheet/weekly/';
export const weekParmRegex =
  /(19|2[0-9])[0-9]{2}-(0[1-9]|1[0-2])-([0-2][0-9]|3[0-1])/;
export const defaultGrouping = ['projectName', 'specName', 'hourTypeName'];
export const excludedColumns = [
  'action',
  'mobileCustomGrouping',
  'mobileCustomData',
  'date',
  'actualHours',
];
export const DESKTOP_SUB_TABLE_ROW_HEIGHT = 45;
export const DESKTOP_SUB_TABLE_FIXED_HEIGHT = 40;
export const MOBILE_ROW_HEIGHT = 56;
export const MOBILE_HEADER_HEIGHT = 45;
