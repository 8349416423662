import ReactDataGrid from '@inovua/reactdatagrid-enterprise';
import {
  TypeColumn,
  TypeComputedProps,
} from '@inovua/reactdatagrid-enterprise/types';
import { Box, Typography, useTheme } from '@mui/material';
import {
  AppearanceContext,
  MessageContext,
} from '@teto/react-component-library';
import { isEmpty } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import useStyles from '../../../../components/TetoGrid/GridStyles';
import { reactDataGridLicenseKey } from '../../../../components/TetoGrid/Licensing';
import { getGraphQLClient } from '../../../../helpers/graphQL/graphQLClient';

interface IssuedProcessSchedulesProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

const container = {
  overflow: 'auto',
  maxHeight: '90%',
  px: 1,
  '& .MuiTypography-subtitle1': {
    mb: 2,
  },
};

const IssuedProcessSchedules = (props: IssuedProcessSchedulesProps) => {
  const { detail } = props;
  const messageContext = React.useContext(MessageContext);
  const inventoryId = isEmpty(detail.inventoryDetails)
    ? 0
    : detail.inventoryDetails[0].inventory.inventoryLocationId;
  const variables = {
    projectId: detail?.projectId,
    specId: detail?.specId,
    itemId: detail?.itemId,
    inventoryLocationId: inventoryId,
    processScheduleId: detail?.processScheduleId,
  };
  const { t } = useTranslation();
  const _doQuery = async () => {
    const query = `query purchasingItemDetailsProcessSchedules(
      $projectId: Int!
      $specId: Float!
      $itemId: Int!
      $inventoryLocationId: Int!
      $processScheduleId: Int
    ) {
      purchasingItemDetailsProcessSchedules(
        projectId: $projectId
        specId: $specId
        itemId: $itemId
        inventoryLocationId: $inventoryLocationId
        processScheduleId: $processScheduleId
      ) {
        number,
        quantity,
        createdDate,
        startDate,
        completionDate,
        materialFromFirstSupplier,
        materialItemCompanyID,
        firstLastNameNumber,
        processScheduleTotal,
        materialAmount,
        materialTotalEstimate,
        quantityOrdered,
        quantityReceived,
        lastReceivedDate,
        finalRequiredDate,
        pSHeaderCustom1,
        pSHeaderCustom2,
        pSHeaderCustom3,
        pSHeaderCustom4,
        pSHeaderCustom5,
        pSHeaderCustom6,
        pSHeaderCustom7,
        pSHeaderCustom8,
        costPerUnit
      }
    }
    `;

    const response = await getGraphQLClient().performQuery(
      query,
      variables,
      (err) => messageContext.setError(err.messages.join('\n ')),
      (err) => {
        // eslint-disable-next-line no-console
        console.log(JSON.stringify(err));
        // messageContext.setError(err.messages.join('\n '))
      }
    );

    return response;
  };

  const processScheduleQuery = useQuery(
    'processSchedule',
    async () => _doQuery(),
    { enabled: true }
  );
  const data =
    processScheduleQuery?.data?.purchasingItemDetailsProcessSchedules;

  const gridRef = React.useRef<TypeComputedProps | null>(null);
  const theme = useTheme();
  const appearanceContext = React.useContext(AppearanceContext);
  const scrollStyle = {
    scrollThumbStyle: { backgroundColor: theme.palette.primary.main },
  };

  const currentTheme =
    appearanceContext.themeVariant === 'dark'
      ? 'default-dark'
      : 'default-light';

  const columns: TypeColumn[] = React.useMemo(
    () => [
      {
        name: 'number',
        header: t('Entities.ProcessScheduleHeader.number'),
        sortable: true,
        groupBy: false,
        showColumnMenuTool: false,
        hideable: false,
      },
      {
        name: 'quantity',
        header: t('Entities.ProcessScheduleHeader.quantity'),
        sortable: true,
        groupBy: false,
        showColumnMenuTool: false,
        hideable: false,
      },
      {
        name: 'createdDate',
        header: t('Entities.ProcessScheduleHeader.createdDate'),
        sortable: true,
        groupBy: false,
        showColumnMenuTool: false,
        hideable: false,
      },
      {
        name: 'startDate',
        header: t('Entities.ProcessScheduleHeader.startDate'),
        sortable: true,
        groupBy: false,
        showColumnMenuTool: false,
        hideable: false,
      },
      {
        name: 'completionDate',
        header: t('Entities.ProcessScheduleHeader.completionDate'),
        sortable: true,
        groupBy: false,
        showColumnMenuTool: false,
        hideable: false,
      },
      {
        name: 'materialItemCompanyID',
        header: t(
          'Entities.EmployeeRetrieveDisplayNames.materialItemCompanyID'
        ),
        sortable: true,
        groupBy: false,
        showColumnMenuTool: false,
        hideable: false,
      },
      {
        name: 'firstLastNameNumber',
        header: t('Entities.EmployeeRetrieveDisplayNames.firstLastNameNumber'),
        sortable: true,
        groupBy: false,
        showColumnMenuTool: false,
        hideable: false,
      },
      {
        name: 'costPerUnit',
        header: t('Entities.EmployeeRetrieveDisplayNames.costPerUnit'),
        sortable: true,
        groupBy: false,
        showColumnMenuTool: false,
        hideable: false,
      },
      {
        name: 'processScheduleTotal',
        header: `${t(
          'Entities.ProcessScheduleHeader.ProcessScheduleHeader'
        )} Total`,
        width: 200,
        sortable: true,
        groupBy: false,
        showColumnMenuTool: false,
        hideable: false,
      },
    ],
    [t]
  );

  return (
    <Box sx={[container, useStyles]}>
      <Typography variant="subtitle1">Issued Process Schedules</Typography>
      {!isEmpty(data) && Array.isArray(data) && (
        <ReactDataGrid
          licenseKey={reactDataGridLicenseKey}
          columns={columns}
          dataSource={data}
          scrollProps={scrollStyle}
          handle={(r) => {
            gridRef.current = r ? r.current : null;
          }}
          theme={currentTheme}
          enableColumnAutosize
          style={{ minHeight: data.length > 1 ? 400 : '' }}
        />
      )}
    </Box>
  );
};

export default IssuedProcessSchedules;
