/* eslint-disable @typescript-eslint/no-explicit-any */
import { isEmpty } from 'lodash';

function getPropertyValue<T>(propName: string, item: T) {
  if (!Object.prototype.hasOwnProperty.call(item, propName)) {
    return 'None';
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (item as any)[propName];
}
export const getNameForItem = (
  apiData: { [key: string]: any },
  id: number
): string | undefined => {
  if (Object.prototype.hasOwnProperty.call(apiData, id)) {
    return apiData[id];
  }
  return '-';
};

const getTimeCardsValues = (
  apiData: { [key: string]: any },
  key: string,
  id: number
  // eslint-disable-next-line consistent-return
) => {
  if (apiData[key]) {
    switch (key) {
      case 'projectId':
        return getNameForItem(apiData[key], id);
      case 'specId':
        return getNameForItem(apiData[key], id);
      case 'hourType':
        return getNameForItem(apiData[key], id);
      case 'nonConformanceId':
        if (apiData[key]) {
          return getNameForItem(apiData[key], id);
        }
        return '-';
      case 'processScheduleDetailId':
        if (apiData[key]) {
          return getNameForItem(apiData, id);
        }
        return '-';

      default:
        return '-';
    }
  }
};

export const updateTimeCardValues = <T extends Record<string, unknown>>(
  apiData: { [key: string]: any },
  item: T,
  keysToMap: string[]
): T => {
  let clonedRecord = {};

  // eslint-disable-next-line array-callback-return
  keysToMap.map((cKey) => {
    const id: number = getPropertyValue(cKey, item);
    const val = getTimeCardsValues(apiData, cKey, id);

    if (isEmpty(clonedRecord)) {
      clonedRecord = { ...item };
      (clonedRecord as any)[cKey] = val;
    } else if (!isEmpty(clonedRecord)) {
      clonedRecord = { ...(clonedRecord as Record<string, unknown>) };
      (clonedRecord as any)[cKey] = val;
    }
  });
  return clonedRecord as T;
};

export interface Grouping<T> {
  groupString: string;
  groupColumns?: string[];
  groupedParts: any[];
  items: T[];
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
// eslint-disable-next-line no-unused-vars
type ResolverFunction<T> = (item: T) => any;

function formatGroupName<T>(
  propNames: (string | ResolverFunction<T>)[],
  item: T
): string {
  const propVals = getGroupPropertyValues(propNames, item).results;
  return propVals.join('---');
}

function getGroupPropertyValue<T>(propName: string, item: T) {
  if (!Object.prototype.hasOwnProperty.call(item, propName)) {
    return 'None';
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (item as any)[propName];
}

function getGroupPropertyResolvedValue<T>(
  propResolver: ResolverFunction<T>,
  item: T
) {
  const result = propResolver(item);
  return result;
}

function getGroupPropertyValues<T>(
  propNames: (string | ResolverFunction<T>)[],
  item: T
) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const results: any[] = [];
  const columnNames: any[] = [];
  propNames.forEach((propName) => {
    if (propName instanceof Object) {
      results.push(getGroupPropertyResolvedValue(propName, item));
    } else {
      results.push(getGroupPropertyValue(propName, item));
      columnNames.push(propName);
    }
  });

  return {
    results,
    columnNames,
  };
}

export const groupTimeCardRecords = <T extends Record<string, unknown>>(
  groups: (string | ResolverFunction<T>)[],
  records: T[],
  apiData?: { [key: string]: any },
  keysToMap?: string[]
): Grouping<T>[] => {
  const results: Grouping<T>[] = [];
  const groupingLookup: { [key: string]: Grouping<T> } = {};

  records.forEach((item) => {
    if (apiData && keysToMap) {
      const recordResult = updateTimeCardValues(apiData, item, keysToMap);
      const groupName = formatGroupName(groups, recordResult as T);

      if (!Object.prototype.hasOwnProperty.call(groupingLookup, groupName)) {
        const val: Grouping<T> = {
          groupString: groupName,
          groupedParts: getGroupPropertyValues(groups, recordResult as T)
            .results,
          items: [],
        };

        groupingLookup[groupName] = val;
        results.push(val);
      }

      groupingLookup[groupName].items.push(recordResult as T);
    } else {
      const groupName = formatGroupName(groups, item);

      if (!Object.prototype.hasOwnProperty.call(groupingLookup, groupName)) {
        const val: Grouping<T> = {
          groupString: groupName,
          groupedParts: getGroupPropertyValues(groups, item).results,
          items: [],
        };

        groupingLookup[groupName] = val;
        results.push(val);
      }

      groupingLookup[groupName].items.push(item);
    }
  });

  return results;
};
export const groupRecords = <T>(
  groups: (string | ResolverFunction<T>)[],
  records: T[]
): Grouping<T>[] => {
  const results: Grouping<T>[] = [];
  const groupingLookup: { [key: string]: Grouping<T> } = {};

  records.forEach((item) => {
    const groupName = formatGroupName(groups, item);

    if (!Object.prototype.hasOwnProperty.call(groupingLookup, groupName)) {
      const val: Grouping<T> = {
        groupString: groupName,
        groupColumns: getGroupPropertyValues(groups, item).columnNames,
        groupedParts: getGroupPropertyValues(groups, item).results,
        items: [],
      };

      groupingLookup[groupName] = val;
      results.push(val);
    }

    groupingLookup[groupName].items.push(item);
  });

  return results;
};
