import {
  TypeFilterValue,
  TypeGroupBy,
  TypeSingleSortInfo,
  TypeSortInfo,
} from '@inovua/reactdatagrid-enterprise/types';
import React from 'react';
import setupInitialGrouping from '../../../GroupByModal/setupInitialGrouping';
import GroupByPanel from './GroupByPanel';
import TableColumnDefinition from '../../TableColumnDefinition';
import FilterPanel from './FilterPanel';
import SortByPanel from './SortByPanel';

interface DistributionProps {
  selected: string;
  columns: TableColumnDefinition[];
  grouping: TypeGroupBy;
  handleClose: () => void;
  // eslint-disable-next-line no-unused-vars
  setGrouping?: (grouping: TypeGroupBy) => void;
  sorting?: TypeSingleSortInfo[];
  // eslint-disable-next-line no-unused-vars
  setSorting?: (sorting: TypeSortInfo) => void;
  filters?: TypeFilterValue;
  // eslint-disable-next-line no-unused-vars
  setFilters?: (filter: TypeFilterValue) => void;
}

const GridOptions = (props: DistributionProps) => {
  const {
    selected,
    columns,
    handleClose,
    grouping,
    sorting,
    filters,
    setFilters,
    setGrouping,
    setSorting,
  } = props;

  const sortableColumns = columns.filter((col) => col.sortable);
  const filterableColumns = columns.filter(
    (col) =>
      col.filterType !== '' &&
      col.name !== 'action' &&
      col.filterType !== 'none'
  );
  const groupableColumns = columns.filter((col) => !col.disableGrouping);
  const initialGroupings = setupInitialGrouping(
    (grouping ?? []) as string[],
    groupableColumns
  );

  switch (selected) {
    case 'groupBy':
      return (
        <GroupByPanel
          groupableColumns={groupableColumns}
          initialGroupings={initialGroupings ?? ['']}
          onSave={async (e) => {
            if (setGrouping) setGrouping(e);
            handleClose();
          }}
        />
      );
    case 'sortBy':
      return (
        <SortByPanel
          sortableColumns={sortableColumns}
          setSorting={setSorting}
          sorting={sorting}
          handleClose={handleClose}
        />
      );
    case 'filter':
    default:
      return (
        <FilterPanel
          filterableColumns={filterableColumns}
          filters={filters}
          setFilters={setFilters}
          handleClose={handleClose}
        />
      );
  }
};

export default GridOptions;
