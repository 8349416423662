import { TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  formatHoursAsHourMin,
  formatInputAshours,
} from '@teto/react-component-library';

interface ETOHourFieldProps {
  disabled: boolean;
  label: string;
  name: string;
  value?: number | string;
  handleChange: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  >;
}

const inputRegex = /^\d+(\d*|:[0-5]?[0-9]?|\.\d*|h|m)$/i;
const outputRegex = /^\d+(\d*|:\d+|\.\d+|h|m)$/i;

const ETOHourField = (props: ETOHourFieldProps) => {
  const { disabled, label, name, value, handleChange } = props;

  const [input, setInput] = useState<string>('0');

  useEffect(() => {
    setInput(() =>
      formatHoursAsHourMin(
        typeof value === 'string'
          ? (parseFloat(value) as number)
          : (value as number)
      )
    );
  }, [value]);

  const _handleChange = (text: string) => {
    if (inputRegex.test(text)) setInput(text);
  };

  const _handleFocus = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    e: any
  ) => e.target.select();

  const _handleBlur = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (outputRegex.test(e.target.value)) {
      e.target.value = formatInputAshours(e.target.value).toString();
      handleChange(e);
      setInput(() => formatHoursAsHourMin(parseFloat(e.target.value)));
    }
  };

  return (
    <TextField
      autoComplete="off"
      data-testid="ETOHourField"
      disabled={disabled}
      label={label}
      onChange={(e) => _handleChange(e.target.value)}
      name={name}
      onFocus={(e) => _handleFocus(e)}
      onBlur={(e) => _handleBlur(e)}
      value={input}
      variant="outlined"
      id={label}
    />
  );
};

export default ETOHourField;
