/* eslint-disable import/no-unresolved */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */

import AppsRoundedIcon from '@mui/icons-material/AppsRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Box, SxProps, Theme, useMediaQuery, useTheme } from '@mui/material';
import {
  ETOButton,
  FloatingActionButton,
  OkCancelConfirmDialogProps,
  YesNoConfirmDialogProps,
} from '@teto/react-component-library';
import {
  ActionItemType,
  CustomConfirmDialogProps,
} from '@teto/react-component-library/dist/components/ActionBar/ActionBar';
import { uniqueId } from 'lodash';
import React from 'react';

// export interface ActionItemType {
//   disabled?: boolean;
//   title: string;
//   icon: React.ReactChild;
//   count?: number;
//   confirm?:
//     | CustomConfirmDialogProps
//     | YesNoConfirmDialogProps
//     | OkCancelConfirmDialogProps;
//   // eslint-disable-next-line no-unused-vars
//   onclick: (e: SyntheticEvent | undefined) => void | Promise<void>;
//   color?: 'grey' | 'primary' | 'secondary' | 'warning' | 'error' | 'success';
// }
export interface ActionBarActionItemType extends ActionItemType {
  color?: 'grey' | 'primary' | 'secondary' | 'warning' | 'error' | 'success';
  count?: number;
}

export interface CustomRightActionType extends ActionBarActionItemType {
  customComponent?: React.ReactNode;
  cssStyles?: string;
}

// type is possibly false because if the user doesnt have the correct license then the button returns false.
export type RightMenuActionItems = (boolean | CustomRightActionType)[];

export interface ActionBarProps {
  withBottomNav?: boolean;
  headerStyles?: SxProps;
  leftChildren?: React.ReactNode;
  rightChildren?: RightMenuActionItems;
  bottomChildren?: React.ReactNode;
  FABPosition?: SxProps<Theme>;
  anchorRef?: React.RefObject<HTMLElement>;
}

const actionBarSx = (theme: Theme) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(1),
  marginTop: theme.spacing(0.5),
});

const leftChildrenSx = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  columnGap: theme.spacing(2),
  [theme.breakpoints.down('lg')]: {
    columnGap: theme.spacing(1),
    flexGrow: 1,
    display: 'grid',
    gridAutoFlow: 'column',
    justifyContent: 'space-between',
  },
});

const defaultFABPositionSx = (theme: Theme) => ({
  bottom: theme.spacing(2),
  position: 'fixed',
  right: theme.spacing(2),
  zIndex: 999,
});

const withBottomNavFabPositionSx = (theme: Theme) => ({
  bottom: theme.spacing(6.5),
  position: 'fixed',
  right: theme.spacing(2),
  zIndex: 999,
});

const actionButtonsSx = (theme: Theme) => ({
  float: 'right',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  columnGap: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    columnGap: theme.spacing(1),
  },
  '& .MuiButton-root': {
    minWidth: theme.spacing(2),
  },
  '& button': {
    [theme.breakpoints.down('md')]: {
      '& .MuiButton-startIcon': {
        marginLeft: 0,
        marginRight: 0,
      },
    },
  },
});

const speedDialOpenSx = (theme: Theme) => ({
  backgroundColor: theme.palette.primary.main,
});

const ActionBar = (props: ActionBarProps) => {
  const {
    withBottomNav,
    headerStyles,
    leftChildren,
    rightChildren,
    bottomChildren,
    FABPosition,
    anchorRef,
  } = props;

  const theme = useTheme();

  const rightButtons = [] as CustomRightActionType[];

  rightChildren?.forEach((obj) => {
    if (Array.isArray(obj)) {
      obj.forEach((item) => {
        if (item) {
          rightButtons.push(item);
        }
      });
    }
    if (obj && !Array.isArray(obj)) {
      rightButtons.push(obj as CustomRightActionType);
    }
  });

  const filterComponentsForFab = rightButtons
    ? rightButtons
        .filter((item) => !item.disabled)
        .map((comp) => {
          const { customComponent, ...rest } = comp;
          if (comp.confirm) {
            return {
              ...rest,
              confirm: {
                type: comp.confirm?.type
                  ? comp.confirm?.type
                  : ('okCancel' as const),
                title: comp.confirm.title,
                content: comp.confirm.content,
              },
            };
          }
          return { ...rest };
        })
    : [];

  const FabActions = [...filterComponentsForFab];

  const isResponsive = useMediaQuery(
    theme.breakpoints.down(
      rightChildren && rightChildren.length > 3 ? 'lg' : 'md'
    )
  );

  return (
    <Box
      sx={[
        actionBarSx,
        ...(Array.isArray(headerStyles) ? headerStyles : [headerStyles]),
      ]}
      ref={anchorRef}
    >
      <Box sx={leftChildrenSx}>{leftChildren}</Box>

      {!isResponsive && (
        <Box sx={actionButtonsSx}>
          {rightButtons?.map((child) =>
            child.customComponent ? (
              <span className={child.cssStyles} key={uniqueId(child.title)}>
                {child.customComponent}
              </span>
            ) : (
              <ETOButton
                key={uniqueId(child.title)}
                color={child.color ?? 'primary'}
                size="medium"
                icon={child.icon}
                onClick={child.onclick}
                // @ts-expect-error: type does exist?
                confirm={
                  child.confirm
                    ? ({
                        ...child.confirm,
                        type: child.confirm?.type ?? ('okCancel' as const),
                        title: child.confirm.title,
                        content: child.confirm.content,
                      } as unknown as
                        | CustomConfirmDialogProps
                        | YesNoConfirmDialogProps
                        | OkCancelConfirmDialogProps
                        | undefined)
                    : undefined
                }
                count={child.count ?? undefined}
                disabled={child.disabled}
              >
                {child.title}
              </ETOButton>
            )
          )}
        </Box>
      )}

      {bottomChildren && <Box>{bottomChildren}</Box>}

      {isResponsive && (
        <Box
          sx={[
            ...(Array.isArray(FABPosition) ? FABPosition : [FABPosition]),
            !FABPosition && defaultFABPositionSx,
            withBottomNav && withBottomNavFabPositionSx,
            !withBottomNav && defaultFABPositionSx,
          ]}
        >
          {FabActions.length > 0 && (
            <FloatingActionButton
              closeIcon={<CloseRoundedIcon />}
              openIcon={<AppsRoundedIcon />}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              actionList={FabActions as any}
              customSpeedDialOpenSx={speedDialOpenSx as SxProps}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

export default React.memo(ActionBar);
