import LayersClearRoundedIcon from '@mui/icons-material/LayersClearRounded';
import MapRoundedIcon from '@mui/icons-material/MapRounded';
import { useTheme } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface MapViewInspectorIconProps {
  mapEnabled: boolean;
}

const MapViewInspectorIcon = (props: MapViewInspectorIconProps) => {
  const { t } = useTranslation();
  const { mapEnabled } = props;
  const theme = useTheme();

  return (
    <Tooltip
      title={
        mapEnabled ? t('forms.mapView.disable') : t('forms.mapView.enable')
      }
    >
      {mapEnabled ? (
        <LayersClearRoundedIcon fill={theme.palette.primary.dark} />
      ) : (
        <MapRoundedIcon fill={theme.palette.grey[500]} />
      )}
    </Tooltip>
  );
};

export default MapViewInspectorIcon;
