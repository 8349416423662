import CheckBox from '@inovua/reactdatagrid-community/packages/CheckBox';
import { IColumn } from '@inovua/reactdatagrid-enterprise/types';
import React from 'react';

const OverdueTimesheetsGridCheckbox = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  renderCheckbox: (checkboxProps, cellProps) => {
    const {
      checked,
      isIndeterminate,
      onChange,
      onClick,
      className,
      theme,
      supportIndeterminate,
    } = checkboxProps;
    const { data } = cellProps;
    const string = isIndeterminate ? String(isIndeterminate) : 'false';
    return (
      data &&
      data?.status !== 'Submitted' &&
      !data.__group && (
        <CheckBox
          role="checkbox"
          data-testid="GridCheckbox"
          aria-checked={checked}
          isindeterminate={string}
          onChange={onChange}
          onClick={onClick}
          checked={checked}
          className={className}
          theme={theme}
          supportIndeterminate={supportIndeterminate}
        />
      )
    );
  },
} as IColumn;

export default OverdueTimesheetsGridCheckbox;
