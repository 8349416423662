/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable react/jsx-props-no-spreading */
import { CellRenderObject } from '@inovua/reactdatagrid-community/Layout/ColumnLayout/Cell/CellProps';
import ReactDataGrid from '@inovua/reactdatagrid-enterprise';
import '@inovua/reactdatagrid-enterprise/base.css';
import '@inovua/reactdatagrid-enterprise/theme/default-dark.css';
import '@inovua/reactdatagrid-enterprise/theme/default-light.css';
import {
  TypeColumn,
  TypeComputedProps,
  TypeEditInfo,
  TypeFilterValue,
  TypeSingleSortInfo,
  TypeSortInfo,
} from '@inovua/reactdatagrid-enterprise/types';
import ExpandLessRounded from '@mui/icons-material/ExpandLessRounded';
import ExpandMoreRounded from '@mui/icons-material/ExpandMoreRounded';

import { ClickAwayListener, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import { Theme, useTheme } from '@mui/material/styles';
import {
  AppearanceContext,
  MessageContext,
  SettingsContext,
} from '@teto/react-component-library';
import { Formik, FormikErrors, FormikProps, FormikValues } from 'formik';
import { isEmpty } from 'lodash';
import React, {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { PagedResponse } from 'teto-client-api';
import { GraphQLError } from '../../helpers/graphQL/fetchGraphQLClient';
import { parseServerResponse } from '../../helpers/validationHelperREST';
import MobileCellWrapper from './components/MobileCellWrapper';
import RenderMobileCell from './components/RenderMobileCell';
import TetoGridDialog from './components/TetoGridDialog';
import ConfigureInspector from './ConfigureInspector/ConfigureInspector';
import setSelectedRowsError from './ErrorHandling/setSelectedRowsError';
import { exportCSV } from './Exporting/csvExporter';
import FilterIcon from './Filtering/FilterIcon';
import {
  buildFilterQuery,
  calculateFilterEditor,
} from './Filtering/filteringHelpers';
import filterTypes from './Filtering/filterTypes';
import { buildCellFormatter } from './Formatters/cellFormatterHelper';
import GridCheckbox from './GridCheckbox/GridCheckbox';
import GridConfigurationHeader from './GridConfigurationHeader';
import GridPersistenceContext from './GridPersistence/GridPersistenceContext';
import PersistenceWrapper from './GridPersistence/PersistenceWrapper';
import { groupNameFormatter } from './Grouping/groupingHelpers';
import parseTreeItem from './helpers/parseTreeItem';
import IMobileData from './IMobileData';
import getDefaultEditor from './InlineEditing/DefaultEditors/getDefaultEditor';
import { EditInfo } from './InlineEditing/IEditInfo';
import getEditor from './InlineEditing/InlineEditingProcessor';
import { reactDataGridLicenseKey } from './Licensing';
import RDGSelectedType from './RDGSelectedType';
import { buildSortQuery } from './Sorting/SortHelpers';
import TableColumnDefinition from './TableColumnDefinition';
import TetoGridCommonProps from './TetoGridCommonProps';
import TetoGridRefType from './TetoGridRefType';
import TSelectAll from './TSelectAll';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
ReactDataGrid.defaultProps.filterTypes = filterTypes as any;

const gridContainerSx = (theme: Theme) => ({
  height: '100%',
  display: 'grid',
  gridTemplateRows: `${theme.spacing(7)} 1fr`,
  [theme.breakpoints.down('md')]: {
    gridTemplateRows: `${theme.spacing(5)} 1fr`,
  },
});
const gridContainerWithSummarySx = (theme: Theme) => ({
  height: '100%',
  display: 'grid',
  gridTemplateRows: `${theme.spacing(7)} 1fr 10fr`,
  [theme.breakpoints.down('md')]: {
    gridTemplateRows: `${theme.spacing(5)} 1fr 10fr`,
  },
});

const gridContainerWithNoHeader = () => ({
  height: '100%',
  display: 'grid',
  gridTemplateRows: '1fr',
});
const gridContainerWithNoHeaderWithSummary = () => ({
  height: '100%',
  display: 'grid',
  gridTemplateRows: '1fr 6.5fr',
});

interface EditStatus {
  isEditMode: boolean;
  values?: EditInfo;
}

export const MainTetoGrid = forwardRef<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any,
  TetoGridCommonProps<{}, {}, {}> & { children?: React.ReactNode }
>(
  <T extends {}, C = {}, VT = {}>(
    props: TetoGridCommonProps<T, C, VT>,
    ref: React.Ref<{ exportData: () => Promise<void> }> | undefined
  ) => {
    const gridContext = useContext(GridPersistenceContext);

    const {
      children,
      checkboxColumn,
      clientSideFiltering,
      configTabs,
      columns,
      CustomCheckbox,
      customGridWrapSx,
      CustomOnSelectionChange,
      defaultConfigTab,
      disableConfigureButton,
      disableGroupByToolbar,
      disableMobileCols,
      disableResetButton,
      doQuery,
      editable,
      enableFiltering,
      enableGroupColumn,
      externalQueryProps,
      filterDebounceTime,
      filterRowHeight,
      groups,
      header,
      headerHeight,
      headerStyles,
      hideGrid,
      idProperty,
      inlineEditMode,
      isDiscardConfirmDialog,
      multiRowExpand,
      noHeader,
      pageSize,
      queryIdentifier,
      refreshToken,
      renderDetailsGrid,
      rowExpandHeight,
      selectedRows,
      serverSideFiltering,
      dataSource,
      setDataSource,
      setIsDiscardConfirmDialog,
      setIsEditing,
      setReactDataGridRef,
      setSelectedRows,
      setSelectAllRowsData,
      showCellBorders,
      showEmptyRows,
      stickyGroupRows,
      summary,
      tableIdentifier,
      unControlledSort,
      viewportSize,
      virtualized,
      withBottomNav,
      onExpandedRowsChange,
      renderRowDetails,
      expandedRows,
      collapsedRows,
      onRowExpand,
      onRowCollapse,
      rowDetailsWidth,
      mobileGridOptions,
      disableAllButtons,
      configureInspector,
      setConfigureInspector,
    } = props;

    const {
      columnOrder,
      columnSizes,
      filters,
      grouping,
      hidden,
      leftLockedColumns,
      rightLockedColumns,
      resetSettings,
      setColumnOrder,
      setColumnSizes,
      setFilters,
      setGrouping,
      setHidden,
      setLeftLockedColumns,
      setRightLockedColumns,
      setSort,
      sort,
    } = gridContext;

    const appearanceContext = useContext(AppearanceContext);
    const settingsContext = useContext(SettingsContext);
    const messageContext = useContext(MessageContext);

    // const [dataSource, setDataSource] = useState([]);
    const gridRef = useRef<TypeComputedProps | null>(null);
    const { t } = useTranslation();
    const theme = useTheme();
    const largeDevice = useMediaQuery(theme.breakpoints.up('md'));
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isReceivingMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const location = useLocation();

    const scrollStyle = useMemo(
      () => ({
        scrollThumbStyle: { backgroundColor: theme.palette.primary.main },
      }),
      [theme]
    );
    const [rowEditingInfo, setRowEditingInfo] = useState<EditStatus>({
      isEditMode: false,
      values: undefined,
    });
    const [openAuthorizedDialog, setOpenAuthorizedDialog] =
      useState<boolean>(false);
    const [isSaveConfirmDialog, setIsSaveConfirmDialog] = useState<{
      isOpen: boolean;
      data: EditInfo | undefined;
    }>({ isOpen: false, data: undefined });

    const formikRef = useRef<FormikProps<FormikValues> | null>(null);
    const formikErrors: FormikErrors<FormikValues> | undefined =
      formikRef.current?.errors;
    const [activeRowStyle, setActiveRowStyle] =
      useState<string>('active-row-border');
    const [mobileRowHeight, setMobileRowHeight] = useState(40);
    useEffect(() => {
      // Make this a switch or two separate if blocks
      if (rowEditingInfo?.isEditMode && setIsEditing) {
        setIsEditing(true);
      }
      if (!rowEditingInfo?.isEditMode && setIsEditing) {
        setIsEditing(false);
      }
    }, [rowEditingInfo, setIsEditing]);

    useEffect(() => {
      if (!isEmpty(formikErrors)) {
        setActiveRowStyle('active-row-border--error');
      } else setActiveRowStyle('active-row-border');

      return () => {
        setActiveRowStyle('active-row-border');
      };
    }, [formikErrors]);

    const currentTheme =
      appearanceContext.themeVariant === 'dark'
        ? 'default-dark'
        : 'default-light';

    const query = useQuery<PagedResponse<T>>(
      [
        queryIdentifier,
        tableIdentifier,
        externalQueryProps,
        filters,
        sort,
        refreshToken,
      ],
      (fc) =>
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        doQuery(fc as any, pageSize, {
          params: externalQueryProps,
          filters: filters ? buildFilterQuery(filters) : undefined,
          orderBy: sort ? buildSortQuery(sort) : undefined,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } as any).then((qr) => {
          if (qr.maxExceeded) {
            messageContext.setWarning(
              `Table Row Limit Hit: Query Returned ${qr.records.length} records of ${qr.totalRecords}`
            );
          }
          return qr;
        }),
      {
        onSuccess: (d) => setDataSource(d.records),
        refetchOnWindowFocus: false,
      }
    );

    const _calculateLocked = useCallback(
      (col: TableColumnDefinition) => {
        if (rightLockedColumns.indexOf(col.name) >= 0) {
          return 'end';
        }
        if (leftLockedColumns.indexOf(col.name) >= 0) {
          return 'start';
        }

        return false;
      },
      [leftLockedColumns, rightLockedColumns]
    );

    const _calculateColumnWidth = useCallback(
      (col: TableColumnDefinition) => {
        const columnSize = columnSizes.find((a) => a.column.name === col.name);
        if (columnSize) {
          return { width: columnSize.width };
        }

        return { width: col.width };
      },
      [columnSizes]
    );

    const onSelectionChange = useCallback(
      (selectionProps) => {
        const { selected, data } = selectionProps;

        if (setSelectedRows) {
          setSelectedRows(() => selected);
        } else {
          setSelectedRowsError();
        }

        if (Array.isArray(data) && data.length > 0 && setSelectAllRowsData) {
          setSelectAllRowsData(() => data);
        }
      },
      [setSelectAllRowsData, setSelectedRows]
    );

    const setItemPropertyForId = useCallback(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (data: any, value: boolean) =>
        gridRef.current?.setItemPropertyForId(
          data[idProperty ?? 'id'] as string,
          '__editing',
          value
        ),
      [idProperty]
    );

    useImperativeHandle(ref, () => ({
      exportData: () => {
        if (gridRef.current) {
          exportCSV(gridRef.current, tableIdentifier);
        }
        return Promise.resolve();
      },
      resetGridSettings: () => {
        resetSettings();
      },
      columnOrder,
      handleSelectAllButtonChange: (obj: TSelectAll) => {
        // References the current records of the grid
        if (!gridRef.current) {
          return messageContext.setError(
            t('generic.message.failNoRecordsFound')
          );
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const dataSourceRef = gridRef.current.dataSource as any[];

        const allSelected = {} as RDGSelectedType;
        if (!isEmpty(selectedRows)) {
          return gridRef?.current?.deselectAll();
        }
        if (obj && dataSourceRef) {
          // eslint-disable-next-line array-callback-return
          dataSourceRef.forEach((item) => {
            /* Iterating over each row and checking if the object has the key and if the value is equal
         to the value of the key that is passed in from the the select all function */
            Object.entries(item).forEach(([key, value]) => {
              // If it does, set the selectedRow and copy if there are other available options
              if (
                Object.hasOwn(obj, key).valueOf() &&
                obj[key] === value &&
                !obj?.excludes?.includes(value)
              ) {
                const selectedRow = {
                  [idProperty ? `${item[idProperty]}` : `${item.id}`]: {
                    ...item,
                  },
                };
                Object.assign(allSelected, selectedRow);
              }
              // allows you to pass in a array of keys to set as selected
              if (
                Object.hasOwn(obj, key).valueOf() &&
                Array.isArray(obj[key])
              ) {
                const listOfValuesToBeSelected = obj[key];
                if (
                  Array.isArray(listOfValuesToBeSelected) &&
                  listOfValuesToBeSelected.includes(value) &&
                  !obj?.excludes?.includes(value)
                ) {
                  const selectedRow = {
                    [idProperty ? `${item[idProperty]}` : `${item.id}`]: {
                      ...item,
                    },
                  };
                  Object.assign(allSelected, selectedRow);
                }
              }
            });
          });
        }
        if (!isEmpty(allSelected)) {
          return setSelectedRows
            ? setSelectedRows(allSelected)
            : setSelectedRowsError();
        }
        return messageContext.setWarning(t('generic.message.noRecordsFound'));
      },
    })) as unknown as TetoGridRefType;

    useEffect(() => {
      gridRef.current?.reload();
    }, [refreshToken]);

    const _onColumnLockedChange = useCallback(
      (cl: { column: TypeColumn; locked: false | 'end' | 'start' }) => {
        if (!cl.column.name) {
          return;
        }

        const isLockedLeft = leftLockedColumns.indexOf(cl.column.name) >= 0;
        const isLockedRight = rightLockedColumns.indexOf(cl.column.name) >= 0;

        if (cl.locked === 'start') {
          if (isLockedRight) {
            setRightLockedColumns(
              rightLockedColumns.filter((a) => a !== cl.column.name)
            );
          }
          if (!isLockedLeft) {
            setLeftLockedColumns(
              leftLockedColumns.concat(cl.column.name as string)
            );
          }
        } else if (cl.locked === 'end') {
          if (isLockedLeft) {
            setLeftLockedColumns(
              leftLockedColumns.filter((a) => a !== cl.column.name)
            );
          }
          if (!isLockedRight) {
            setRightLockedColumns(
              rightLockedColumns.concat(cl.column.name as string)
            );
          }
        } else if (cl.locked) {
          if (!isLockedLeft) {
            setLeftLockedColumns(
              leftLockedColumns.filter((a) => a !== cl.column.name)
            );
          }
          if (!isLockedRight) {
            setRightLockedColumns(
              rightLockedColumns.filter((a) => a !== cl.column.name)
            );
          }
        } else {
          if (isLockedLeft) {
            setLeftLockedColumns(
              leftLockedColumns.filter((a) => a !== cl.column.name)
            );
          }
          if (isLockedRight) {
            setRightLockedColumns(
              rightLockedColumns.filter((a) => a !== cl.column.name)
            );
          }
        }
      },
      [
        leftLockedColumns,
        rightLockedColumns,
        setLeftLockedColumns,
        setRightLockedColumns,
      ]
    );

    const _onColumnVisibleChange = useCallback(
      (cv: { column: TypeColumn; visible: boolean }) => {
        const isHidden = cv.column.name
          ? hidden.indexOf(cv.column.name) >= 0
          : false;
        if (cv.visible && isHidden && cv.column.name) {
          setHidden(hidden.filter((a) => a !== cv.column.name));
        }

        if (!cv.visible && !isHidden && cv.column.name) {
          setHidden(hidden.concat([cv.column.name as string]));
        }
      },
      [hidden, setHidden]
    );

    const _onColumnResize = useCallback(
      (cs: {
        column: TypeColumn;
        width?: number | undefined;
        flex?: number | undefined;
      }) => {
        const col = columnSizes.find((a) => a.column.name === cs.column.name);
        if (col) {
          const result = columnSizes
            .filter((a) => a.column.name !== cs.column.name)
            .concat([
              {
                column: cs.column,
                // @ts-expect-error: missing type from rdg
                width: cs.size ?? cs.width,
                flex: cs.flex,
              },
            ]);
          return setColumnSizes(result);
        }
        return setColumnSizes(columnSizes);
      },
      [columnSizes, setColumnSizes]
    );

    const _onColumnOrderChanged = useCallback(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (co: any) => setColumnOrder(co),
      [setColumnOrder]
    );
    const _onGroupByChanged = useCallback(
      (g: string[]) => setGrouping(g),
      [setGrouping]
    );
    const _onFilterValueChange = useCallback(
      (fv: TypeFilterValue) => setFilters(fv),
      [setFilters]
    );
    const _onSortInfoChange = useCallback(
      (si: TypeSortInfo) => {
        setSort(si);
      },
      [setSort]
    );
    const _isGroupBarDisabled = useMemo(
      () => Boolean(!columns.find((a) => !a.disableGrouping)),
      [columns]
    );

    const _handleComplete = useCallback(
      (editContext: EditInfo) => {
        if (editable?.onEditComplete) {
          editable.onEditComplete(editContext);
          if (inlineEditMode === 'default') {
            return setRowEditingInfo({
              isEditMode: false,
              values: undefined,
            });
          }
        } else if (
          rowEditingInfo.isEditMode &&
          rowEditingInfo?.values?.rowIndex === editContext.rowIndex
        ) {
          gridRef?.current?.setItemAt(editContext.rowIndex, {
            ...formikRef?.current?.values,
          });
        }
      },
      [
        editable,
        inlineEditMode,
        rowEditingInfo.isEditMode,
        rowEditingInfo?.values?.rowIndex,
      ]
    );
    const mobileCols = useMemo(
      () => [
        ...columns,
        // If you change anything below, make sure to update in the grid persistance provider - bug #72605
        {
          order: columns.length + 2,
          name: 'mobileCustomGrouping',
          title: 'Visible Headers',
          type: 'custom',
          align: 'start' as const,
          sortable: false,
          filterType: 'none',
          fixed: 'none' as const,
          disableGrouping: true,
          disableHideable: true,
          disableColumnMenuTool: false,
          minWidth: 144,
          editable: false,
          renderFunction: () => {
            // eslint-disable-next-line react/jsx-no-useless-fragment
            if (!isReceivingMobile) return <></>;
            return (
              <MobileCellWrapper mobileRowHeight={mobileRowHeight}>
                {columns
                  .sort((a, b) => {
                    const aIndex = columnOrder.indexOf(a.name);
                    const bIndex = columnOrder.indexOf(b.name);
                    if (aIndex === -1) {
                      return 1;
                    }
                    if (bIndex === -1) {
                      return -1;
                    }
                    if (aIndex < bIndex) return 1;
                    if (aIndex > bIndex) return -1;
                    return 0;
                  })
                  .reverse()
                  // eslint-disable-next-line array-callback-return
                  .map((col) => {
                    if (col.name === 'action') return;
                    if (col.name === 'mobileCustomGrouping') return;
                    if (col.name === 'mobileCustomData') return;
                    if (
                      !(hidden.indexOf(col.name) >= 0) &&
                      !grouping?.includes(col.name)
                    ) {
                      // eslint-disable-next-line consistent-return
                      return (
                        <Box
                          sx={{
                            height: '50px',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                          }}
                        >
                          {col.title}
                        </Box>
                      );
                    }
                  })}
              </MobileCellWrapper>
            );
          },
          header: 'Visible Headers',
          id: 'mobileCustomGrouping',
          hidden: false,
          flex: 1,
        },
        {
          order: columns.length + 1,
          name: 'mobileCustomData',
          title: 'Data',
          type: 'custom',
          align: 'start' as const,
          sortable: false,
          filterType: 'none',
          fixed: 'none' as const,
          disableGrouping: true,
          disableHideable: true,
          disableColumnMenuTool: false,
          minWidth: 144,
          editable: false,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          renderFunction: (data: any, rdgProps: CellRenderObject) => {
            if (!isReceivingMobile) {
              // eslint-disable-next-line react/jsx-no-useless-fragment
              return <></>;
            }
            const valuesNotMissing: [string, unknown][] = [];
            const colsNotHidden: TableColumnDefinition[] = columns.filter(
              (col) =>
                !(
                  hidden.indexOf(col.name) >= 0 || grouping?.includes(col.name)
                ) &&
                col.name !== 'action' &&
                col.name !== 'mobileCustomGrouping' &&
                col.name !== 'mobileCustomData'
            );

            columns.forEach((col) => {
              if (!(hidden.indexOf(col.name) >= 0)) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                Object.entries(parseTreeItem(data, 3) as any).forEach((key) => {
                  /* Checking if the key is equal to the column name from the grid configuration header. If it is, it returns the value
              of the key. */
                  if (key[0] === col.name && !grouping?.includes(col.name)) {
                    valuesNotMissing.push(key);
                  }
                });
              }
            });
            // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-explicit-any
            const dataWithMissingDataAdded: IMobileData[] = colsNotHidden
              .sort((a, b) => {
                const aIndex = columnOrder.indexOf(a.name);
                const bIndex = columnOrder.indexOf(b.name);
                if (aIndex === -1) {
                  return 1;
                }
                if (bIndex === -1) {
                  return -1;
                }
                if (aIndex < bIndex) return 1;
                if (aIndex > bIndex) return -1;
                return 0;
              })
              .reverse()
              .map((col) => {
                const found = valuesNotMissing.find(
                  (value) => col.name === value[0]
                );
                if (!found) {
                  return { value: '-', ...col };
                }
                return { value: found[1], ...col };
              });
            const mobileProps = {
              data: dataWithMissingDataAdded,
              rdgProps,
              setDataSource,
              dataSource,
              idProperty: idProperty ?? 'id',
              onEditComplete: _handleComplete,
            };
            return (
              <MobileCellWrapper mobileRowHeight={mobileRowHeight}>
                {RenderMobileCell(mobileProps)}
              </MobileCellWrapper>
            );
          },
          header: 'Data',
          id: 'mobileCustomData',
          hidden: false,
          flex: 1,
        },
      ],
      [
        _handleComplete,
        columnOrder,
        columns,
        dataSource,
        grouping,
        hidden,
        idProperty,
        isReceivingMobile,
        mobileRowHeight,
        setDataSource,
      ]
    );

    const mobileOrDesktopCols = useMemo<TableColumnDefinition[]>(() => {
      if (isReceivingMobile && !disableMobileCols) {
        return mobileCols
          .sort((a, b) => {
            const aIndex = columnOrder.indexOf(a.name);
            const bIndex = columnOrder.indexOf(b.name);
            if (aIndex === -1) {
              return 1;
            }
            if (bIndex === -1) {
              return -1;
            }
            if (aIndex < bIndex) return 1;
            if (aIndex > bIndex) return -1;
            return 0;
          })
          .reverse()
          .map((item) => {
            if (
              item.name === 'mobileCustomData' ||
              item.name === 'mobileCustomGrouping' ||
              item.name === 'action'
            ) {
              return {
                ...item,
                hidden: false,
                width: _calculateColumnWidth(item).width,
                // flex: _calculateColumnWidth(item).flex
              };
            }

            return {
              ...item,
              hidden: true,
              width: _calculateColumnWidth(item).width,
              // flex: _calculateColumnWidth(item).flex
            };
          });
      }

      return mobileCols
        .sort((a, b) => {
          const aIndex = columnOrder.indexOf(a.name);
          const bIndex = columnOrder.indexOf(b.name);
          if (aIndex === -1) {
            return 1;
          }
          if (bIndex === -1) {
            return -1;
          }
          if (aIndex < bIndex) return 1;
          if (aIndex > bIndex) return -1;
          return 0;
        })
        .reverse()
        .map((item) => {
          if (
            item.name === 'mobileCustomData' ||
            item.name === 'mobileCustomGrouping'
          ) {
            return {
              ...item,
              hidden: true,
              width: _calculateColumnWidth(item).width,
              // flex: _calculateColumnWidth(item).flex
            };
          }
          return {
            ...item,
            hidden: item.disableHideable
              ? false
              : hidden.indexOf(item.name) >= 0,
            width: _calculateColumnWidth(item).width,
            // flex: _calculateColumnWidth(item).flex
          };
        });
      // }
    }, [
      isReceivingMobile,
      disableMobileCols,
      mobileCols,
      columnOrder,
      _calculateColumnWidth,
      hidden,
    ]);

    const inspectorCols = useMemo<TableColumnDefinition[]>(
      () =>
        mobileOrDesktopCols
          .sort((a, b) => {
            const aIndex = columnOrder.indexOf(a.name);
            const bIndex = columnOrder.indexOf(b.name);
            if (aIndex === -1) {
              return 1;
            }
            if (bIndex === -1) {
              return -1;
            }
            if (aIndex < bIndex) return 1;
            if (aIndex > bIndex) return -1;
            return 0;
          })
          .reverse()
          .map((item) => ({
            ...item,
            hidden: item.disableHideable
              ? false
              : hidden.indexOf(item.name) >= 0,
            width: _calculateColumnWidth(item).width,
            // flex: _calculateColumnWidth(item).flex
          })),
      [mobileOrDesktopCols, columnOrder, hidden, _calculateColumnWidth]
    );
    // This is our columns being mapped to be able to be used by RDG columns
    // // add new col def here

    const convertedToRDGColumns = useMemo<TableColumnDefinition[]>(
      () =>
        mobileOrDesktopCols.map((c) => ({
          ...c,
          name: c.name,
          type: c.type,
          header: c.header ?? c.title,
          hideable: !c.disableHideable,
          showInContextMenu: false,
          hidden: c.hidden ?? false,
          align: c.align,
          groupByName: c.title,
          isCheckboxColumn: c.type === 'boolean',
          draggable: !c.disableGrouping,
          id: c.name,
          width: c.width,
          defaultWidth: c.defaultWidth,
          minWidth: c.minWidth,
          flex: c.flex,
          defaultFlex: c.defaultFlex,
          textAlign: c.align,
          headerProps: c.headerProps,
          groupSummaryReducer: c.groupSummaryReducer,
          groupToString: (v) =>
            groupNameFormatter(
              {
                dateFormat: settingsContext.settings.dateFormat,
                dateTimeFormat: settingsContext.settings.dateTimeFormat,
                timeFormat: settingsContext.settings.timeFormat,
              },
              t,
              c,
              v
            ),
          sortable: c.sortable,
          visible: !c.hidden,
          enableColumnFilterContextMenu: !c.disableColumnFilterContextMenu,
          showColumnMenuTool: !c.disableColumnMenuTool,
          groupBy: !c.disableGrouping,
          locked: _calculateLocked(c),
          filterEditor: calculateFilterEditor(c),
          render: c.render ?? buildCellFormatter(c),
          filterDelay: filterDebounceTime ?? 500,
          filterType: c.filterType,
          filterEditorProps: {
            renderSettings: () => FilterIcon('Filter Settings'),
          },
          filterable: c.filterable ?? true,
          editor:
            inlineEditMode === 'default'
              ? getDefaultEditor(c.type)
              : getEditor(c.name === 'comments' ? 'multilineString' : c.type),
          editable:
            c.editable &&
            c.type !== 'button' &&
            c.type !== 'custom' &&
            largeDevice,
          editorProps: {
            disabled: editable?.rowDisabled,
            allowUnset: editable?.allowUnset?.(c.name),
            selector: editable?.selector,
            lockColumn: editable?.lockColumn,
            dependantColumns: editable?.dependantColumns,
            processForeignKey: editable?.processForeignKey,
            handleForeignKeyChange: editable?.handleForeignKeyChange,
            hasEditPermission: editable?.hasEditPermission,
            inlineEditingCondition: c.inlineEditingCondition,
            isInEditMode: gridRef.current?.isInEdit,
            inlineEditMode,
          },
        })),
      [
        mobileOrDesktopCols,
        filterDebounceTime,
        _calculateLocked,
        inlineEditMode,
        largeDevice,
        editable,
        settingsContext.settings.dateFormat,
        settingsContext.settings.dateTimeFormat,
        settingsContext.settings.timeFormat,
        t,
      ]
    );

    // eslint-disable-next-line consistent-return
    useLayoutEffect(() => {
      if (isReceivingMobile && !disableMobileCols) {
        const amountOfCols = mobileOrDesktopCols.reduce((acc, curCol) => {
          if (!(hidden.indexOf(curCol.name) >= 0)) {
            return acc + 30;
          }
          return acc;
        }, 0);
        return setMobileRowHeight(amountOfCols);
      }
      setMobileRowHeight(40);
    }, [
      mobileOrDesktopCols,
      isMobile,
      hidden,
      disableMobileCols,
      isReceivingMobile,
    ]);

    const _formatEditableRowData = (data: C) =>
      editable?.formatEditableRow ? editable?.formatEditableRow(data) : data;
    const _closeOpenEditor = useCallback(() => {
      if (inlineEditMode === 'default') return;

      if (gridRef.current?.isInEdit.current && gridRef?.current?.completeEdit) {
        const gridInfo = gridRef?.current;
        const editInfo = gridInfo.getCurrentEditInfo?.();
        if (editInfo) {
          gridRef?.current?.completeEdit?.({ ...editInfo, dir: 1, value: 1 });
        }
      }
    }, [inlineEditMode]);

    const _handleStart = (editContext: EditInfo) => {
      if (inlineEditMode === 'default') {
        return setRowEditingInfo({
          isEditMode: true,
          values: {
            ...editContext,
            data: _formatEditableRowData(editContext.data as unknown as C),
          },
        });
      }
      if (!rowEditingInfo.isEditMode) {
        if (
          (editable?.rowDisabled &&
            !editable?.rowDisabled(editContext.data as unknown as C)) ||
          !editable?.rowDisabled
        ) {
          setItemPropertyForId(editContext.data, true);
          setRowEditingInfo({
            isEditMode: true,
            values: {
              ...editContext,
              data: _formatEditableRowData(editContext.data as unknown as C),
            },
          });
        }
      } else if (
        rowEditingInfo.isEditMode &&
        rowEditingInfo.values?.rowIndex !== editContext.rowIndex
      ) {
        if (
          formikRef?.current?.dirty &&
          inlineEditMode === 'submitRowOnEnter'
        ) {
          _closeOpenEditor();
          setIsSaveConfirmDialog({ isOpen: true, data: editContext });
        } else if (
          (editable?.rowDisabled &&
            !editable?.rowDisabled(editContext.data as unknown as C)) ||
          !editable?.rowDisabled
        ) {
          setItemPropertyForId(rowEditingInfo?.values?.data, false);
          setItemPropertyForId(editContext.data, true);
          setRowEditingInfo({
            isEditMode: true,
            values: {
              ...editContext,
              data: _formatEditableRowData(editContext.data as unknown as C),
            },
          });
        } else if (
          editable?.rowDisabled &&
          editable?.rowDisabled(editContext.data as unknown as C)
        ) {
          setItemPropertyForId(rowEditingInfo?.values?.data, false);
          setRowEditingInfo({
            isEditMode: false,
            values: undefined,
          });
        }
      } else if (
        rowEditingInfo.isEditMode &&
        rowEditingInfo?.values?.columnId !== editContext.columnId &&
        rowEditingInfo.values?.rowIndex === editContext.rowIndex
      ) {
        gridRef?.current?.setItemAt?.(
          rowEditingInfo?.values?.rowIndex as number,
          formikRef?.current?.values
        );
      }
    };

    const _handleCancel = useCallback(
      // eslint-disable-next-line no-unused-vars
      (editContext: EditInfo) => {
        if (inlineEditMode === 'default') {
          return setRowEditingInfo({
            isEditMode: false,
            values: undefined,
          });
        }

        if (formikRef?.current?.dirty) {
          _closeOpenEditor();
          setIsDiscardConfirmDialog?.(true);
        } else {
          _closeOpenEditor();
          setItemPropertyForId(rowEditingInfo?.values, false);
          gridRef.current?.setItemAt(
            rowEditingInfo?.values?.rowIndex as number,
            {
              ...formikRef.current?.initialValues,
            },
            { replace: true }
          );
          setRowEditingInfo({
            isEditMode: false,
            values: undefined,
          });
        }
      },
      [
        _closeOpenEditor,
        inlineEditMode,
        rowEditingInfo?.values,
        setIsDiscardConfirmDialog,
        setItemPropertyForId,
      ]
    );

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const _onSubmit = (values: C, actions: any) => {
      if (inlineEditMode === 'default') return;

      _closeOpenEditor();
      setOpenAuthorizedDialog(true);
      const initValues = formikRef?.current?.initialValues as unknown as C;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (
        (editable?.onSubmitting &&
          editable?.onSubmitting(values, initValues)) ??
        Promise.resolve(values)
      ).then((modifiedValue: C) =>
        editable
          ?.onSubmit(modifiedValue)
          .then(() => {
            actions.setSubmitting(false);
            setItemPropertyForId(formikRef?.current?.initialValues as C, false);
            setRowEditingInfo({
              isEditMode: false,
              values: undefined,
            });
            // setIsEditing?.(false);
            editable?.onSubmitSuccess(
              rowEditingInfo.values?.rowIndex as number
            );
            messageContext.setSuccess(t('generic.message.recordUpdated'));
            setOpenAuthorizedDialog(false);
          })
          .catch((e) => {
            let message = '';
            actions.setSubmitting(false);
            setOpenAuthorizedDialog(false);
            setActiveRowStyle('active-row-border--error');
            if (e instanceof GraphQLError) {
              const errorList = {};

              Object.entries(e).forEach((err) => {
                if (err[0] === 'validationErrors') {
                  const keys = Object.keys(err[1].input);
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  const results: { [key: string]: any[] } = {};
                  keys.forEach((k) => {
                    if (k !== '*') {
                      // eslint-disable-next-line prefer-destructuring
                      results[k] = [err[1].input[k]];
                    }
                  });
                  Object.assign(errorList, results);
                }
              });
              const graphQLError = {
                errors: errorList,
              };
              parseServerResponse(
                graphQLError,
                (errors) => {
                  const vColumns = gridRef?.current?.visibleColumns;
                  const visibleColumns =
                    vColumns && vColumns?.length > 0
                      ? vColumns.map((col) => col.name)
                      : [''];
                  let newErrors = {};
                  Object.keys(errors).forEach((key: string) => {
                    if (visibleColumns?.indexOf(key) < 0) {
                      message += `${key}: ${errors[key]} \n`;
                    } else {
                      newErrors = {
                        ...newErrors,
                        [key]: errors[key],
                      };
                    }
                  });
                  formikRef?.current?.setErrors(newErrors);
                },
                (error) => {
                  message += `${error}\n`;
                }
              );
            } else {
              parseServerResponse(
                e,
                (errors) => {
                  const vColumns = gridRef?.current?.visibleColumns;
                  const visibleColumns =
                    vColumns && vColumns?.length > 0
                      ? vColumns.map((col) => col.name)
                      : [''];
                  let newErrors = {};
                  Object.keys(errors).forEach((key: string) => {
                    if (visibleColumns?.indexOf(key) < 0) {
                      message += `${key}: ${errors[key]} \n`;
                    } else {
                      newErrors = {
                        ...newErrors,
                        [key]: errors[key],
                      };
                    }
                  });
                  formikRef?.current?.setErrors(newErrors);
                },
                (error) => {
                  message += `${error}\n`;
                }
              );
            }
            // parseServerResponse(
            //   e,
            //   (errors) => {
            //     const vColumns = gridRef?.current?.visibleColumns;
            //     const visibleColumns =
            //       vColumns && vColumns?.length > 0
            //         ? vColumns.map((col) => col.name)
            //         : [''];
            //     let newErrors = {};
            //     Object.keys(errors).forEach((key: string) => {
            //       if (visibleColumns?.indexOf(key) < 0) {
            //         message += `${key}: ${errors[key]} \n`;
            //       } else {
            //         newErrors = {
            //           ...newErrors,
            //           [key]: errors[key],
            //         };
            //       }
            //     });
            //     formikRef?.current?.setErrors(newErrors);
            //   },
            //   (error) => {
            //     message += `${error}\n`;
            //   }
            // );
            if (message.length >= 1) messageContext.setError(message);
          })
      );
    };

    const discardRowChanges = useCallback(() => {
      _closeOpenEditor();
      setItemPropertyForId(formikRef?.current?.initialValues as C, false);
      gridRef.current?.setItemAt(
        rowEditingInfo?.values?.rowIndex as number,
        rowEditingInfo?.values?.data,
        { replace: true }
      );
      setRowEditingInfo({
        isEditMode: false,
        values: undefined,
      });
      if (setIsDiscardConfirmDialog) {
        setIsDiscardConfirmDialog(false);
      }
    }, [
      _closeOpenEditor,
      rowEditingInfo?.values?.data,
      rowEditingInfo?.values?.rowIndex,
      setIsDiscardConfirmDialog,
      setItemPropertyForId,
    ]);

    useEffect(() => {
      if (isDiscardConfirmDialog && !formikRef?.current?.dirty) {
        discardRowChanges();
      }
    }, [discardRowChanges, isDiscardConfirmDialog]);

    const _handleKeypress = useCallback(
      (e: KeyboardEvent) => {
        if (rowEditingInfo.isEditMode) {
          switch (e.key) {
            case 'Enter': {
              if (formikRef?.current?.dirty) {
                formikRef?.current?.submitForm();
              } else {
                discardRowChanges();
              }
              break;
            }
            case 'Escape': {
              _handleCancel(
                gridRef?.current?.getCurrentEditInfo?.() as EditInfo
              );
              break;
            }
            default:
              break;
          }
        }
      },
      [_handleCancel, discardRowChanges, rowEditingInfo.isEditMode]
    );

    const _handleClickAway = () => {
      if (rowEditingInfo.isEditMode && !openAuthorizedDialog) {
        if (inlineEditMode === 'default') return;
        if (
          formikRef?.current?.dirty &&
          !(isSaveConfirmDialog.isOpen || isDiscardConfirmDialog)
        ) {
          _closeOpenEditor();
          setIsSaveConfirmDialog({ isOpen: true, data: undefined });
        } else if (!formikRef?.current?.dirty) {
          _closeOpenEditor();
          setItemPropertyForId(rowEditingInfo?.values, false);
          gridRef.current?.setItemAt(
            rowEditingInfo?.values?.rowIndex as number,
            {
              ...formikRef.current?.initialValues,
            },
            { replace: true }
          );
          setRowEditingInfo({
            isEditMode: false,
            values: undefined,
          });
        }
      }
    };
    const RenderCheckbox = useMemo(() => {
      if (CustomCheckbox) {
        return CustomCheckbox;
      }
      return GridCheckbox;
    }, [CustomCheckbox]);

    const disableActiveIndicator = () => {
      const isReceivingPage =
        location.pathname === '/receiving' && isReceivingMobile;

      if (!isReceivingPage) {
        return true;
      }

      return false;
    };

    const computedRowHeight = useCallback(() => {
      if (isReceivingMobile) {
        return null;
      }

      return 45;
    }, [isReceivingMobile]);

    const _handleSettingsUpdate = useCallback(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (data: any) => {
        const order = [
          ...data.columnOrder.filter((e: string) => e !== 'action'),
          'action',
        ];
        // Set ensures a value can only occur once https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Set
        const uniqCols = [...new Set(order)];
        setColumnOrder(uniqCols);
        setHidden(data.hidden);
      },
      [setColumnOrder, setHidden]
    );

    /* Do not add a Formik onReset function, it always creates bugs that break inline editing!!!!! */
    // eslint-disable-next-line no-return-assign

    return (
      <Box
        sx={[
          // eslint-disable-next-line no-nested-ternary
          summary && noHeader
            ? gridContainerWithNoHeaderWithSummary
            : // eslint-disable-next-line no-nested-ternary
            noHeader
            ? gridContainerWithNoHeader
            : summary
            ? gridContainerWithSummarySx
            : gridContainerSx,
          ...(Array.isArray(customGridWrapSx)
            ? customGridWrapSx
            : [customGridWrapSx]),
        ]}
      >
        {!noHeader && (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            <GridConfigurationHeader
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...header}
              withBottomNav={withBottomNav}
              headerStyles={headerStyles}
              onReset={resetSettings}
              disableConfigureButton={disableConfigureButton}
              disableResetButton={disableResetButton}
              columnOrder={columnOrder}
              columns={inspectorCols}
              grouping={grouping}
              setGrouping={setGrouping}
              sorting={sort as unknown as TypeSingleSortInfo[]}
              setSorting={setSort}
              filters={filters}
              setFilters={setFilters}
              onSettingsUpdate={(e) => _handleSettingsUpdate(e)}
              configTabs={configTabs}
              mobileGridOptions={mobileGridOptions}
              disableAllButtons={disableAllButtons}
              defaultConfigTab={defaultConfigTab}
            />
          </>
        )}
        {configureInspector && setConfigureInspector && noHeader && (
          <ConfigureInspector
            open={configureInspector}
            columns={inspectorCols}
            initialColumnOrder={columnOrder}
            setConfigureInspector={setConfigureInspector}
            handleClose={() => setConfigureInspector(() => false)}
            onReset={resetSettings}
            onColumnSettingsSave={_handleSettingsUpdate}
            tabs={configTabs}
            grouping={grouping}
            setGrouping={setGrouping}
            sorting={sort as unknown as TypeSingleSortInfo[]}
            setSorting={setSort}
            filters={filters}
            setFilters={setFilters}
            mobileGridOptions={mobileGridOptions}
            defaultTab={defaultConfigTab}
          />
        )}
        {summary}

        <Formik
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          initialValues={{ ...(rowEditingInfo?.values?.data as unknown as C) }}
          enableReinitialize
          validationSchema={editable?.validationSchema}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onSubmit={_onSubmit}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          innerRef={formikRef as unknown as any}
        >
          <ClickAwayListener
            onClickAway={_handleClickAway}
            mouseEvent="onMouseDown"
            touchEvent="onTouchEnd"
          >
            <Box data-testid="dataGrid" sx={{ height: '100% !important' }}>
              {!hideGrid && (
                <ReactDataGrid
                  autoFocusOnEditComplete={false} // Bug #73213
                  groups={groups ?? undefined}
                  onReady={setReactDataGridRef}
                  idProperty={idProperty || 'id'}
                  licenseKey={reactDataGridLicenseKey}
                  columns={convertedToRDGColumns}
                  handle={(r) => {
                    gridRef.current = r ? r.current : null;
                  }}
                  dataSource={dataSource}
                  activateRowOnFocus={false}
                  columnOrder={columnOrder}
                  editable={
                    inlineEditMode === 'default'
                      ? !isMobile
                      : Boolean(editable) && editable?.hasEditPermission
                  } // edit
                  onEditComplete={(e) => _handleComplete({ ...e } as EditInfo)}
                  onEditStart={(e) => _handleStart({ ...e } as EditInfo)} // edit
                  onEditCancel={(e) => _handleCancel({ ...e } as EditInfo)}
                  checkboxOnlyRowSelect
                  enableColumnAutosize
                  enableFiltering={enableFiltering ?? true}
                  remoteSort
                  loading={query.isFetching}
                  remotePagination={false}
                  groupBy={grouping ?? []}
                  filterValue={serverSideFiltering ? filters : undefined}
                  defaultFilterValue={clientSideFiltering ? filters : undefined}
                  sortInfo={unControlledSort ? undefined : sort}
                  onSortInfoChange={_onSortInfoChange}
                  onColumnLockedChange={_onColumnLockedChange}
                  onFilterValueChange={_onFilterValueChange}
                  onColumnVisibleChange={_onColumnVisibleChange}
                  onColumnResize={_onColumnResize}
                  onColumnOrderChange={_onColumnOrderChanged}
                  disableGroupByToolbar={
                    disableGroupByToolbar ||
                    (isReceivingMobile && !disableMobileCols)
                      ? true
                      : _isGroupBarDisabled
                  }
                  expandGroupTitle
                  stickyGroupRows={stickyGroupRows}
                  onGroupByChange={_onGroupByChanged}
                  groupColumn={
                    !(!enableGroupColumn || (isMobile && !disableMobileCols))
                  }
                  showEmptyRows={!isMobile && !showEmptyRows}
                  emptyText={t('generic.message.noRecordsFound')}
                  scrollProps={scrollStyle}
                  theme={currentTheme}
                  showActiveRowIndicator={disableActiveIndicator()}
                  activeRowIndicatorClassName={activeRowStyle}
                  editStartEvent="click"
                  selected={selectedRows}
                  onSelectionChange={
                    CustomOnSelectionChange || onSelectionChange
                  }
                  checkboxColumn={checkboxColumn ? RenderCheckbox : undefined}
                  virtualized={virtualized !== true}
                  onKeyDown={
                    inlineEditMode === 'submitRowOnEnter'
                      ? _handleKeypress
                      : undefined
                  }
                  viewportSize={viewportSize}
                  // @ts-expect-error: This does exist
                  rowHeight={computedRowHeight()} // Bug #72690
                  // enables dynamic row height - has to be null not undefined, row height should be 45 or else performance issues come into play,
                  renderDetailsGrid={renderDetailsGrid}
                  rowExpandHeight={
                    rowExpandHeight ?? (null as unknown as number)
                  }
                  renderRowDetailsCollapsedIcon={() => <ExpandMoreRounded />}
                  renderRowDetailsExpandIcon={() => <ExpandLessRounded />}
                  headerHeight={headerHeight}
                  filterRowHeight={filterRowHeight}
                  multiRowExpand={multiRowExpand}
                  expandedRows={expandedRows}
                  collapsedRows={collapsedRows}
                  onExpandedRowsChange={onExpandedRowsChange}
                  renderRowDetails={renderRowDetails}
                  onRowExpand={onRowExpand}
                  onRowCollapse={onRowCollapse}
                  rowDetailsWidth={rowDetailsWidth}
                  showCellBorders={showCellBorders}
                />
              )}
            </Box>
          </ClickAwayListener>
        </Formik>
        {children}
        {isSaveConfirmDialog.isOpen && (
          <TetoGridDialog
            open={isSaveConfirmDialog.isOpen}
            title={t('dialogs.saveDiscardDialog.title')}
            content={t('dialogs.saveDiscardDialog.content')}
            handleClose={() => {
              gridRef?.current?.setActiveIndex(
                rowEditingInfo.values?.rowIndex as number
              );
              setIsSaveConfirmDialog({ isOpen: false, data: undefined });
              _closeOpenEditor();
            }}
            rightButton={{
              label: t('generic.save'),
              onClick: () => {
                formikRef?.current?.submitForm();
                setIsSaveConfirmDialog({ isOpen: false, data: undefined });
              },
            }}
            leftButton={{
              label: t('generic.discard'),
              onClick: () => {
                discardRowChanges();
                if (isSaveConfirmDialog.data) {
                  gridRef?.current?.startEdit?.({
                    ...isSaveConfirmDialog.data,
                  } as unknown as TypeEditInfo);
                }
                setIsSaveConfirmDialog({ isOpen: false, data: undefined });
              },
            }}
          />
        )}
        {isDiscardConfirmDialog && formikRef?.current?.dirty && (
          <TetoGridDialog
            open={isDiscardConfirmDialog}
            title={t('dialogs.closeUnsavedForm.title')}
            content={t('dialogs.closeUnsavedForm.content')}
            handleClose={() => setIsDiscardConfirmDialog?.(false)}
            rightButton={{
              label: t('generic.yes'),
              onClick: () => {
                discardRowChanges();
              },
            }}
            leftButton={{
              label: t('generic.no'),
              onClick: () => {
                if (setIsDiscardConfirmDialog) {
                  setIsDiscardConfirmDialog(false);
                }
              },
            }}
          />
        )}
      </Box>
    );
  }
);

const TetoGrid = forwardRef<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any,
  // eslint-disable-next-line @typescript-eslint/ban-types
  TetoGridCommonProps<{}, {}, {}> & {
    persistenceTypeOverride?: 'db' | 'localStorage' | 'sessionStorage' | 'none';
  }
>((props, ref) => (
  <PersistenceWrapper
    {...props}
    persistenceTypeOverride={props.persistenceTypeOverride}
  >
    <MainTetoGrid {...props} ref={ref}>
      {props.children}
    </MainTetoGrid>
  </PersistenceWrapper>
));

export default TetoGrid;
