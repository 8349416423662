/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-expressions */
import StopRoundedIcon from '@mui/icons-material/StopRounded';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import { AuthContext } from '@teto/react-component-library';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Permission, TimesheetStatus } from 'teto-client-api';
import TimerContext from '../../TimeSheets/NavTimer/PunchInTimerContext';

interface ButtonObjectProps {
  // eslint-disable-next-line no-unused-vars
  onStartTimerOnClick?: () => void;
  onStopTimerOnClick?: () => void;
  setStopTimerModal: (value: React.SetStateAction<boolean>) => void;
  setStartTimerModal: (value: React.SetStateAction<boolean>) => void;
  status: TimesheetStatus | undefined;
}

const TimerButton = (props: ButtonObjectProps) => {
  const { t } = useTranslation();
  const {
    status,
    onStopTimerOnClick,
    onStartTimerOnClick,
    setStopTimerModal,
    setStartTimerModal,
  } = props;
  const authContext = useContext(AuthContext);
  const timerContext = useContext(TimerContext);

  const canUseStartTimer =
    authContext.hasEnterpriseLicense() &&
    authContext.hasPermission(Permission.Add_MyTimeTracker_Punchcards);
  const canuseStopTimer =
    authContext.hasEnterpriseLicense() &&
    authContext.hasPermission(Permission.Modify_MyTimeTracker_Punchcards);

  const stopTimerButton = {
    title: t('forms.stopTimer'),
    icon: <StopRoundedIcon />,
    onclick: () => {
      onStopTimerOnClick ? onStopTimerOnClick() : null;
      setStopTimerModal(true);
    },
  };

  const startTimerButton = {
    title: t('forms.startTimer'),
    icon: <TimerOutlinedIcon />,
    disabled:
      !canUseStartTimer ||
      !canuseStopTimer ||
      timerContext.status === 'active' ||
      status === 'Approved' ||
      status === 'Submitted',
    onclick: () => {
      onStartTimerOnClick ? onStartTimerOnClick() : null;
      setStartTimerModal(true);
    },
  };

  return timerContext.status !== 'active'
    ? canUseStartTimer && startTimerButton
    : canuseStopTimer && stopTimerButton;
};

export default TimerButton;
