/* eslint-disable @typescript-eslint/no-explicit-any */
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import { SxProps, Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { SettingsContext } from '@teto/react-component-library';
import dayjs from 'dayjs';
import React, { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { groupableColumns } from '../../../../helpers/GroupableColumns';

interface TableGroupCellProps {
  grouping: { [key: string]: any }[];
}

const activeGroupByTextSx: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  '& span': {
    textOverflow: 'ellipsis',
    lineHeight: 1.9,
  },
};
const columnSx: SxProps<Theme> = {
  fontWeight: '500',
  textAlign: 'left',
  lineHeight: 'normal',
};
const rootSx: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  height: '100%',
  paddingLeft: (theme) => theme.spacing(1),
  paddingRight: (theme) => theme.spacing(1),
  '& span': {
    lineHeight: 'normal',
  },
};

const valueSx: SxProps<Theme> = {
  textAlign: 'left',
  width: '100%',
  lineHeight: 'normal',
};

const inlineSx: SxProps<Theme> = {
  flexDirection: 'row',
  columnGap: '16px',
};

const TableGroupCell = (props: TableGroupCellProps) => {
  const { grouping } = props;
  const { t } = useTranslation();
  const {
    settings: { dateFormat },
  } = useContext(SettingsContext);

  const renderItem = useCallback(
    (item: any) => {
      if (item === 'None') return '-';
      switch (typeof item) {
        case 'object':
          if (dayjs.isDayjs(item)) {
            return dayjs(item).format(dateFormat);
          }
          return item;
        case 'boolean':
          return (
            <Checkbox
              style={{ padding: 0 }}
              size="small"
              checked={item}
              disabled
            />
          );
        default:
          return item;
      }
    },
    [dateFormat]
  );

  const cellElements = useMemo(() => {
    if (grouping) {
      return grouping.map((e) => (
        <Grid
          sx={[rootSx, ...(typeof e[1] === 'boolean' ? [inlineSx] : [])]}
          key={e[0]}
        >
          <Typography sx={columnSx} variant="body2" component="span">
            {t(groupableColumns[e[0]])}:
          </Typography>
          <Typography sx={valueSx} noWrap variant="body2" component="span">
            {renderItem(e[1])}
          </Typography>
        </Grid>
      ));
    }
    return undefined;
  }, [grouping, renderItem, t]);

  return <Box sx={activeGroupByTextSx}>{cellElements}</Box>;
};

export default TableGroupCell;
