import { Box } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
  AuthContext,
  formatHoursAsHourMin,
  MessageContext,
} from '@teto/react-component-library';
import { useFormik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { getEmployees } from 'teto-client-api';
import { parseServerResponse } from '../../../../helpers/validationHelperREST';
import { CustomFields } from '../Fields';
// eslint-disable-next-line import/no-named-as-default
import TimeCardForm from '../TimeCardForm';
import TimeCardFormValidation from '../TimecardFormValidation';
import TimeCardRequestFormat from '../TimeCardRequestFormat';
import TimerFormProps from './TimerFormProps';
import TimerFormValues from './TimerFormValues';

const timerFormSx = {
  flex: 1,
  flexGrow: 1,
  overflowY: 'hidden',
};

const formSx = {
  height: '100%',
  width: '100%',
};

export type CustomFieldCaptions = Record<CustomFields, string>;

export const DefaultCustomFieldCaptions: CustomFieldCaptions = {
  custom1: 'Timecard Custom 1',
  custom2: 'Timecard Custom 2',
  custom3: 'Timecard Custom 3',
  custom4: 'Timecard Custom 4',
  custom5: 'Timecard Custom 5',
  custom6: 'Timecard Custom 6',
  custom7: 'Timecard Custom 7',
  custom8: 'Timecard Custom 8',
  pSCCustom1: 'Process Schedule In House Transaction Custom 1',
  pSCCustom2: 'Process Schedule In House Transaction Custom 2',
  pSCCustom3: 'Process Schedule In House Transaction Custom 3',
  pSCCustom4: 'Process Schedule In House Transaction Custom 4',
  pSCCustom5: 'Process Schedule In House Transaction Custom 5',
  pSCCustom6: 'Process Schedule In House Transaction Custom 6',
  pSCCustom7: 'Process Schedule In House Transaction Custom 7',
  pSCCustom8: 'Process Schedule In House Transaction Custom 8',
};

export const isCustomCaption = (field: CustomFields, caption: string) =>
  DefaultCustomFieldCaptions[field] !== caption;

function _formatOutput(formValues: TimerFormValues): TimeCardRequestFormat {
  // remove all undefined fields the easy way
  const out = JSON.parse(
    JSON.stringify({
      employeeId: parseInt(formValues.projectId as unknown as string, 10),
      projectId: parseInt(formValues.projectId as unknown as string, 10),
      specId: parseInt(formValues.specId as unknown as string, 10),
      hourTypeId: parseInt(formValues.hourTypeId as unknown as string, 10),
      hourFactor: parseFloat(formValues.hourFactor as unknown as string),
      hourRate: parseFloat(formValues.hourRate as unknown as string),
      processScheduleDetailId:
        formValues.processScheduleDetailId &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (formValues.processScheduleDetailId as any) !== '-1'
          ? parseInt(
              formValues.processScheduleDetailId as unknown as string,
              10
            )
          : undefined,
      nonConformanceId:
        formValues.nonConformanceId &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (formValues.nonConformanceId as any) !== '-1'
          ? parseInt(formValues.nonConformanceId as unknown as string, 10)
          : undefined,
      quantity: formValues.quantity,
      comments: formValues.comments,
      custom1: formValues.custom1,
      custom2: formValues.custom2,
      custom3: formValues.custom3,
      custom4: formValues.custom4,
      custom5: formValues.custom5 ?? undefined,
      custom6: formValues.custom6 ?? undefined,
      custom7: formValues.custom7,
      custom8: formValues.custom8,
    })
  );

  if (out.processScheduleDetailId <= 0) {
    delete out.processScheduleDetailId;
  }

  if (out.nonConformanceId <= 0) {
    delete out.nonConformanceId;
  }

  return out;
}

const TimerForm = (props: TimerFormProps) => {
  const authContext = useContext(AuthContext);
  const [totalDuration, setTotalDuration] = useState<number>(0);
  const messageContext = useContext(MessageContext);

  const {
    initialValues,
    requireFields,
    getJobs,
    getHourTypes,
    getProjects,
    getNonConformances,
    getProcessSchedules,
    fieldCaptions,
    fieldsDisableEditable,
    onSubmit,
    onCancel,
    qrEnabled,
    onError,
    canUseTimer,
    durationPollFunction,
    buttons,
    setFormDirty,
    allowEmployeeSelection,
  } = props;

  const _handleLocationError = (errors: { [key: string]: string }) => {
    const keys = Object.keys(errors);
    keys.forEach((k) => {
      if (k === 'location') {
        messageContext.setError(errors[k]);
      }
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const _updateDuration = () =>
    durationPollFunction &&
    durationPollFunction()
      .then((n) => setTotalDuration(n / 60))
      // eslint-disable-next-line no-console
      .catch((e) => console.warn(e));

  useEffect(() => {
    _updateDuration();
    const interval = setInterval(() => {
      _updateDuration();
    }, 60000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formik = useFormik<TimerFormValues>({
    enableReinitialize: true,
    validationSchema: TimeCardFormValidation,
    initialValues: {
      employeeId: initialValues?.employeeId ?? -1,
      projectId: initialValues?.projectId ?? -1,
      specId: initialValues?.specId ?? -1,
      hourTypeId: initialValues?.hourTypeId ?? -1,
      quantity: initialValues?.quantity ?? 0,
      custom1: initialValues?.custom1 ?? '',
      custom2: initialValues?.custom2 ?? '',
      custom3: initialValues?.custom3 ?? undefined,
      custom4: initialValues?.custom4 ?? undefined,
      custom5: initialValues?.custom5 ?? null,
      custom6: initialValues?.custom6 ?? null,
      custom7: initialValues?.custom7 ?? false,
      custom8: initialValues?.custom8 ?? false,
      pSCCustom1: initialValues?.pSCCustom1 ?? '',
      pSCCustom2: initialValues?.pSCCustom2 ?? '',
      pSCCustom3: initialValues?.pSCCustom3,
      pSCCustom4: initialValues?.pSCCustom4,
      pSCCustom5: initialValues?.pSCCustom5 ?? null,
      pSCCustom6: initialValues?.pSCCustom6 ?? null,
      pSCCustom7: initialValues?.pSCCustom7 ?? false,
      pSCCustom8: initialValues?.pSCCustom8 ?? false,
      comments: initialValues?.comments ?? '',
      processScheduleDetailId: initialValues?.processScheduleDetailId ?? -1,
      nonConformanceId: initialValues?.nonConformanceId ?? -1,
      requireComments: requireFields?.comments ?? false,
      requireCustom1:
        (requireFields?.custom1 &&
          isCustomCaption('custom1', fieldCaptions.custom1)) ??
        false,
      requireCustom2:
        (requireFields?.custom2 &&
          isCustomCaption('custom2', fieldCaptions.custom2)) ??
        false,
      requireCustom3:
        (requireFields?.custom3 &&
          isCustomCaption('custom3', fieldCaptions.custom3)) ??
        false,
      requireCustom4:
        (requireFields?.custom4 &&
          isCustomCaption('custom4', fieldCaptions.custom4)) ??
        false,
      requireCustom5:
        (requireFields?.custom5 &&
          isCustomCaption('custom5', fieldCaptions.custom5)) ??
        false,
      requireCustom6:
        (requireFields?.custom6 &&
          isCustomCaption('custom6', fieldCaptions.custom6)) ??
        false,
      requireCustom7:
        (requireFields?.custom7 &&
          isCustomCaption('custom7', fieldCaptions.custom7)) ??
        false,
      requireCustom8:
        (requireFields?.custom8 &&
          isCustomCaption('custom8', fieldCaptions.custom8)) ??
        false,
      requirePSCCustom1:
        (requireFields?.pSCCustom1 &&
          isCustomCaption('pSCCustom1', fieldCaptions.pSCCustom1)) ??
        false,
      requirePSCCustom2:
        (requireFields?.pSCCustom2 &&
          isCustomCaption('pSCCustom2', fieldCaptions.pSCCustom2)) ??
        false,
      requirePSCCustom3:
        (requireFields?.pSCCustom3 &&
          isCustomCaption('pSCCustom3', fieldCaptions.pSCCustom3)) ??
        false,
      requirePSCCustom4:
        (requireFields?.pSCCustom4 &&
          isCustomCaption('pSCCustom4', fieldCaptions.pSCCustom4)) ??
        false,
      requirePSCCustom5:
        (requireFields?.pSCCustom5 &&
          isCustomCaption('pSCCustom5', fieldCaptions.pSCCustom5)) ??
        false,
      requirePSCCustom6:
        (requireFields?.pSCCustom6 &&
          isCustomCaption('pSCCustom6', fieldCaptions.pSCCustom6)) ??
        false,
      requirePSCCustom7:
        (requireFields?.pSCCustom7 &&
          isCustomCaption('pSCCustom7', fieldCaptions.pSCCustom7)) ??
        false,
      requirePSCCustom8:
        (requireFields?.pSCCustom8 &&
          isCustomCaption('pSCCustom8', fieldCaptions.pSCCustom8)) ??
        false,
      requireNonConformance: requireFields?.nonConformanceId ?? false,
      requireProcessSchedule: requireFields?.processScheduleDetailId ?? false,
      requireQuantity: requireFields?.quantity ?? false,
      hourFactor: initialValues?.hourFactor,
      hourRate: initialValues?.hourRate,
    },
    onSubmit: (values, actions) =>
      onSubmit(_formatOutput(values), actions)
        .then(() => {
          actions.setSubmitting(false);
        })
        .catch((e) => {
          actions.setSubmitting(false);
          parseServerResponse(
            e,
            (errors) => actions.setErrors(errors),
            (error) => onError(error)
          );
          _handleLocationError(e?.errors?.errors);
        }),
  });

  useEffect(() => {
    if (setFormDirty) setFormDirty(formik.dirty);
  }, [formik.dirty, setFormDirty]);

  const _handleCancel = () => {
    if (onCancel) onCancel();
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={timerFormSx} data-testid="hours-form">
        {authContext.user && (
          <Box
            component="form"
            onSubmit={formik.handleSubmit}
            sx={formSx}
            autoComplete="off"
          >
            <TimeCardForm
              isEditMode={false}
              canAffect
              getEmployees={
                allowEmployeeSelection
                  ? () =>
                      getEmployees({
                        disablePaging: true,
                        orderBy: { active: true, employeeNumber: false },
                      }).then((e) => e.records)
                  : authContext.user
              }
              disableAutoFocus={false}
              qrEnabled={qrEnabled}
              buttonStrip={{
                size: 'medium',
                rightButton: {
                  disabled: !canUseTimer || formik.isSubmitting,
                  color: 'primary',
                  text: buttons.right.title,
                  type: 'submit',
                },
                leftButton: buttons.left
                  ? {
                      color: 'error',
                      text: buttons.left.title,
                      confirm: buttons.left.confirm
                        ? {
                            type: 'okCancel',
                            ...buttons.left.confirm,
                          }
                        : undefined,
                      onClick: () => _handleCancel(),
                    }
                  : undefined,
              }}
              errors={formik.errors}
              fieldCaptions={fieldCaptions}
              fieldsDisableEditable={fieldsDisableEditable}
              getHourTypes={getHourTypes}
              getJobs={getJobs}
              getNonConformances={getNonConformances}
              getProcessSchedules={getProcessSchedules}
              getProjects={getProjects}
              handleChange={formik.handleChange}
              isSubmitting={formik.isSubmitting}
              setFieldValue={formik.setFieldValue}
              totalHours={() => formatHoursAsHourMin(totalDuration)}
              values={formik.values}
              origValues={formik.initialValues}
              submitForm={formik.handleSubmit}
            />
          </Box>
        )}
      </Box>
    </LocalizationProvider>
  );
};

export default TimerForm;
