/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/prefer-default-export */
import {
  TypeColumn,
  TypeComputedProps,
} from '@inovua/reactdatagrid-enterprise/types';
import { downloadBlob } from './downloader';

const _formatCSVValue = (val: any) => {
  if (val === null || val === undefined) {
    return '';
  }
  return `"${val}"`;
};

const buildCSVExport = (grid: TypeComputedProps) => {
  const seperator = ',';
  const { visibleColumns } = grid;
  const finalColumns: TypeColumn[] = [];

  const groupedColumns = grid.groupBy ?? [];
  const groupHeaders: string[] = [];
  groupedColumns.forEach((gc) => {
    const col = grid.allColumns.find((a) => a.name === gc);
    if (!col) {
      throw new Error(`CSV Export failed finding grouped column ${gc}`);
    }
    groupHeaders.push(col.groupByName ?? gc);
    finalColumns.push(col);
  });

  visibleColumns.forEach((vc) => {
    if (!vc.groupColumn && vc.type !== 'button' && !vc.checkboxColumn) {
      finalColumns.push(vc);
    }
  });

  const exportHeader = finalColumns
    .map((c: any) => _formatCSVValue(c.header))
    .join(seperator);
  const rows = (grid.dataSource as []).map((data: any) =>
    finalColumns.map((c: any) => _formatCSVValue(data[c.id])).join(seperator)
  );
  const contents = [exportHeader].concat(rows).join('\n');
  return contents;
};

export const exportCSV = (
  gridRef: TypeComputedProps,
  tableIdentifier: string
) => {
  const contents = buildCSVExport(gridRef);
  const blob = new Blob([contents], { type: 'text/csv;charset=utf-8;' });
  downloadBlob(blob, `grid-${tableIdentifier}-data.csv`);
};
