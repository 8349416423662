/* eslint-disable  @typescript-eslint/no-explicit-any, no-unused-vars, no-shadow */

import { useState } from 'react';
import FetchGraphQLClient from './fetchGraphQLClient';
import { IGraphQLClient } from './IGraphQLClient';

const fetchGraphQLClient: IGraphQLClient = new FetchGraphQLClient();

export const getGraphQLClient = (
  clienType: GraphQLClienType = GraphQLClienType.CROSS_FETCH
) => fetchGraphQLClient;

export enum GraphQLClienType {
  CROSS_FETCH,
}
