import AvatarGroup from '@mui/material/AvatarGroup';
import React from 'react';
import { Approver } from 'teto-client-api';
import { Theme } from '@mui/material/styles';
import { Box } from '@mui/material';
import FormatterProps from '../../components/TetoGrid/Formatters/FormatterProps';
import ApproverIcon from './ApproverIcon';

const CUT_OFF = 5;

const avatarGroupSx = (theme: Theme) => ({
  '& > .MuiAvatarGroup-avatar': {
    fontSize: '1rem',
    marginLeft: 0,
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    color: theme.palette.primary.light,
    width: theme.spacing(4.5),
    height: theme.spacing(3),
  },
  '& .MuiAvatar-root': {
    marginLeft: theme.spacing(-0.25),
    border: 'none',
    boxSizing: 'border-box',
  },
});

const NoApprovers = () => (
  <Box sx={{ width: '100%', height: '100%', textAlign: 'center' }}>-</Box>
);

const ApproverFormatter = (props: FormatterProps) => {
  const { value } = props;
  const formattedValue: Approver[] = value;

  return (
    <>
      {(!value || formattedValue.length <= 0) && <NoApprovers />}
      {value && formattedValue.length > 0 && (
        <AvatarGroup max={CUT_OFF} spacing={0} sx={avatarGroupSx}>
          {formattedValue.map((a) => (
            <ApproverIcon key={a.id} name={a.approverName} />
          ))}
        </AvatarGroup>
      )}
    </>
  );
};

export default ApproverFormatter;
