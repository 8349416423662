import { Box } from '@mui/material';
import React from 'react';
import { ETOSelectField } from '@teto/react-component-library';
import editorStyles from '../InlineEditingCommonStyles';

const cellSx = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& .MuiCheckbox-root': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DefaultBooleanEditor = (props: any) => {
  const {
    cellProps,
    onCancel,
    onTabNavigation,
    onComplete,
    onChange,
    onEnterNavigation,
    value,
  } = props;

  return (
    <Box sx={[editorStyles.root, cellSx]}>
      <ETOSelectField
        autoFocus={cellProps.inEdit}
        name={cellProps.name}
        handleChange={onChange}
        onBlur={onComplete}
        onFocus={cellProps.startEdit}
        items={[]}
        itemNameSelector={(i) => i}
        itemValueSelector={(i) => i}
        onKeyDown={(e) => {
          if (e.key === 'Escape') {
            onCancel(e);
          }
          if (e.key === 'Enter') {
            onEnterNavigation(true, e.shiftKey ? -1 : 1);
          }
          if (e.key === 'Tab') {
            e.preventDefault();
            onTabNavigation(true, e.shiftKey ? -1 : 1);
          }
        }}
        value={value}
      />
    </Box>
  );
};

export default DefaultBooleanEditor;
