import React from 'react';
import { ETOTextField } from '@teto/react-component-library';
import { SxProps } from '@mui/material/styles';
import StandardEditorWrapper from '../EditorWrappers/StandardEditorWrapper';
import InlineEditorProps from '../InlineEditingProps';

const ETONumberEditor: React.FC<InlineEditorProps> = (props) => {
  const cellSx: SxProps = {
    '& .InovuaReactDataGrid__cell__editor InovuaReactDataGrid__cell__editor--number':
      {},
  };
  const { cellProps } = props;

  return (
    <StandardEditorWrapper
      {...props}
      child={<ETOTextField name={cellProps?.name as string} type="number" />}
      customCellSx={cellSx}
    />
  );
};

export default ETONumberEditor;
