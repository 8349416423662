import { Box, SxProps, Theme, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TimeSheet } from 'teto-client-api';

const timesheetStatusTextSx = (theme: Theme) => ({
  display: 'flex',
  alignItems: 'center',
  flexGrow: 1,
  width: '100%',
  gap: theme.spacing(0.5),
});

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface TimesheetStatusTextProps {
  timesheet: TimeSheet | undefined;
  stylesSx?: SxProps<Theme>;
}

const TimesheetStatusText = (props: TimesheetStatusTextProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { timesheet, stylesSx } = props;
  // const text = timesheet && timesheet.status ? t(`Entities.TimeSheet.${timesheet.status}`) : t('Entities.TimeSheet.UnSubmitted');
  // const statusText = ` ${text}`;
  const status = timesheet?.status;
  return (
    <Box
      sx={[
        timesheetStatusTextSx,
        // stylesSx,
        ...(Array.isArray(stylesSx) ? stylesSx : [stylesSx]),
      ]}
    >
      <Typography color="textPrimary" variant="subtitle2">
        {t('Entities.TimeSheet.status')}:
      </Typography>

      {status === 'Approved' && (
        <Typography
          variant="body2"
          data-testid="approved"
          sx={{
            // !important is used here so color stays appropriate color even when you hover
            color: `${theme.palette.success.main} !important`,
          }}
        >
          {t('Entities.TimeSheet.approved')}
        </Typography>
      )}

      {status === 'Rejected' && (
        <Typography
          variant="body2"
          data-testid="rejected"
          sx={{
            color: `${theme.palette.error.main} !important`,
          }}
        >
          {t('Entities.TimeSheet.rejected')}
        </Typography>
      )}

      {status === 'Submitted' && (
        <Typography
          variant="body2"
          data-testid="submitted"
          sx={{
            color: `${theme.palette.warning.main} !important`,
          }}
        >
          {t('Entities.TimeSheet.submitted')}
        </Typography>
      )}

      {(status === 'In Progress' || !status) && (
        <Typography
          variant="body2"
          data-testid="unsubmitted"
          sx={{
            color: `${theme.palette.grey[500]} !important`,
          }}
        >
          {t('Entities.TimeSheet.inProgress')}
        </Typography>
      )}
    </Box>
  );
};

export default TimesheetStatusText;
