/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import { AuthContext, MessageContext } from '@teto/react-component-library';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Permission } from 'teto-client-api';
import { getGraphQLClient } from '../../../helpers/graphQL/graphQLClient';
import cancelMyClockIn from './queries/clockIn/cancelMyClockIn';
import getMyCurrentClockInStatus from './queries/clockIn/getMyCurrentClockInStatus';
import startMyClockIn from './queries/clockIn/startMyClockIn';
import stopMyClockIn from './queries/clockIn/stopMyClockIn';

export type TimerStatus = 'active' | 'inactive' | 'error' | 'paused';

export interface ClockInTimerContextState {
  status: TimerStatus;
  refreshToken: Date;
  activeTimerId?: number | null | undefined;
  errorMessage?: string;
  duration?: number;
  durationFormatted?: string;
  startTime?: string | null;
  refreshTimer: () => void;
  // eslint-disable-next-line no-unused-vars
  startMyClockIn: () => Promise<void>;
  // // eslint-disable-next-line no-unused-vars
  stopMyClockIn: () => Promise<void>;
}

const ClockInTimerContext = React.createContext<ClockInTimerContextState>(
  null as never
);

const POLL_INTERVAL = 30_000;

interface ClockInTimerContextProviderProps {
  children: React.ReactNode;
}

interface IMyCurrentClockInStatus {
  myCurrentClockInStatus: {
    employee: {
      id: number | null;
    };
    clockedIn: boolean;
    startTime: string | null;
    endTime: string | null;
    timeElapsedInMinutes: number | null;
  };
}

const ClockInTimerContextProvider = (
  props: ClockInTimerContextProviderProps
) => {
  const [timerStatus, setTimerStatus] = useState<TimerStatus>('inactive');
  const [activeTimerId, setActiveTimerId] =
    useState<number | undefined | null>();
  const [errorMessage, setErrorMessage] =
    useState<string | undefined>(undefined);
  const authContext = useContext(AuthContext);
  const messageContext = useContext(MessageContext);
  const [elapsedMinutes, setElapsedMinutes] = useState<number | undefined>(0);
  const [refreshToken, setRefreshToken] = useState<Date>(new Date());
  const [startTime, setStartTime] = useState<string | null>();

  const { t } = useTranslation();

  const { children } = props;

  const _updateClockInStatus = useCallback(() => {
    setErrorMessage(undefined);

    getGraphQLClient()
      .performQuery(
        getMyCurrentClockInStatus,
        {},
        (err: { messages: string[] }) => {
          messageContext.setError(err.messages[0]);
        },
        (err) => {
          messageContext.setError(err.myCurrentClockInStatus);
        }
      )
      .then((data: IMyCurrentClockInStatus) => {
        if (data && data?.myCurrentClockInStatus?.clockedIn) {
          setTimerStatus('active');
          setActiveTimerId(data.myCurrentClockInStatus.employee.id);
          setStartTime(data.myCurrentClockInStatus.startTime);
          return setElapsedMinutes(
            data.myCurrentClockInStatus.timeElapsedInMinutes ?? 0
          );
        }
        setTimerStatus('inactive');
        setElapsedMinutes(0);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      authContext.hasEnterpriseLicense() &&
      authContext.authenticated &&
      authContext.hasPermission(Permission.View_MyTimeTracker_Timecards)
    ) {
      _updateClockInStatus();
      const pollTimer = setInterval(() => {
        _updateClockInStatus();
      }, POLL_INTERVAL);
      return () => clearInterval(pollTimer);
    }
  }, [_updateClockInStatus, authContext]);

  const hours = Math.floor((elapsedMinutes ?? 0) / 60);
  const minutes = (elapsedMinutes ?? 0) % 60;
  const time = minutes < 10 ? `${hours}:0${minutes}` : `${hours}:${minutes}`;

  const formattedDuration = time === undefined ? '0:00' : String(time);

  const _startMyClockIn = useCallback(
    () =>
      getGraphQLClient()
        .performQuery(
          startMyClockIn,
          {},
          (err) => {
            messageContext.setError(err.messages[0]);
          },
          (err) => {
            messageContext.setError(err.startMyClockIn);
          }
        )
        .then((data) => {
          if (data.startMyClockIn) {
            setRefreshToken(new Date());
            setTimerStatus('active');
            messageContext.setSuccess(
              `${t('Entities.ClockIns.clockIn')} ${t('generic.success')}`
            );
            return data;
          }
        }),
    [messageContext, t]
  );

  const _stopMyClockIn = useCallback(
    () =>
      getGraphQLClient()
        .performQuery(
          stopMyClockIn,
          {},
          (err) => {
            messageContext.setError(err.messages[0]);
          },
          (err) => {
            messageContext.setError(err.stopMyClockIn);
          }
        )
        .then((data) => {
          if (data.stopMyClockIn) {
            setRefreshToken(new Date());
            setTimerStatus('inactive');
            setElapsedMinutes(0);
            messageContext.setSuccess(
              `${t('Entities.ClockIns.clockOut')} ${t('generic.success')}`
            );
            return data;
          }
        }),
    [messageContext, t]
  );

  const timerValues = useMemo(
    () => ({
      status: timerStatus,
      duration: elapsedMinutes,
      durationFormatted: formattedDuration,
      errorMessage,
      refreshTimer: _updateClockInStatus,
      activeTimerId,
      refreshToken,
      startMyClockIn: _startMyClockIn,
      stopMyClockIn: _stopMyClockIn,
      startTime,
    }),
    [
      _startMyClockIn,
      _stopMyClockIn,
      _updateClockInStatus,
      activeTimerId,
      elapsedMinutes,
      errorMessage,
      formattedDuration,
      refreshToken,
      startTime,
      timerStatus,
    ]
  );

  return (
    <ClockInTimerContext.Provider value={timerValues}>
      {children}
    </ClockInTimerContext.Provider>
  );
};

export const ClockInTimerProvider = ClockInTimerContextProvider;
export const ClockInTimerConsumer = ClockInTimerContext.Consumer;
export default ClockInTimerContext;
