interface GraphQLConfig {
  baseUrl: string;
  apiEndPoint: string;
}

const DEFAULT_CONFIG: GraphQLConfig = {
  baseUrl: '',
  apiEndPoint: '/graphql',
};

let globalConfig = { ...DEFAULT_CONFIG };

export const getConfig = () => globalConfig;

export const setConfig = (config: GraphQLConfig) => {
  GuardForNull(config);
  GuardForInvalidEndpoint(config);

  globalConfig = config;
};

function GuardForInvalidEndpoint(config: GraphQLConfig) {
  if (!config.apiEndPoint.startsWith('/')) {
    throw new Error('Endpoint must start with /');
  }
}

function GuardForNull(config: GraphQLConfig) {
  if (
    config === null ||
    config.baseUrl === null ||
    config.apiEndPoint === null
  ) {
    throw new Error('Cannot set a null config');
  }
}
