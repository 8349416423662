import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ButtonStrip,
  ConfirmDialogs,
  ETOCheckBox,
  ETOTextField,
  Inspector,
} from '@teto/react-component-library';
import useLocalStorage from 'use-local-storage';
import { Box, SxProps, Theme } from '@mui/material';
import MapFilters from './Filtering/MapFilters';

const rootSx: SxProps<Theme> = (theme: Theme) => ({
  marginRight: theme.spacing(-2),
});

const buttonStripContainer = {
  display: 'block',
  width: '100%',
  position: 'relative',
};

const filterForm: SxProps<Theme> = (theme: Theme) => ({
  flexGrow: 1,
  marginBottom: theme.spacing(7),
  overflowY: 'auto',
  paddingRight: theme.spacing(2),
  '& .MuiTextField-root': {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
});

interface FilterDrawerProps {
  open: boolean;
  handleClose: () => void;
  filters: MapFilters | undefined;
  // eslint-disable-next-line no-unused-vars
  setFilters: (filters: MapFilters | undefined) => void;
}

const FilterDrawer = (props: FilterDrawerProps) => {
  const { t, ready } = useTranslation();
  const { open, handleClose, setFilters, filters } = props;

  const [tempFilters, setTempFilters] = useLocalStorage<MapFilters | undefined>(
    'tempFilters-local',
    filters
  );
  const [resetDialog, setResetDialog] = useState<boolean>(false);

  const _onSave = () => {
    setFilters(tempFilters);
    handleClose();
  };

  const _handleReset = () => {
    setTempFilters({
      employeeFirstName: '',
      employeeLastName: '',
      employeeFullName: '',
      employeeId: undefined,
      empNumber: undefined,
      projectName: '',
      projectId: undefined,
      comments: '',
      specName: '',
      specId: undefined,
      departmentName: '',
      subDepartmentName: '',
      processScheduleDetailId: undefined,
      processScheduleDtailName: '',
      nonConformanceId: undefined,
      nonConformanceName: '',
      id: undefined,
      hourTypeId: undefined,
      hourTypeName: '',
      hourRate: undefined,
      custom7: false,
    });
  };

  return (
    <>
      {ready && (
        <Inspector
          title={t('Entities.PunchIn.filter')}
          open={open}
          onClose={handleClose}
          customSx={rootSx as SxProps}
          customButtonStripSx={buttonStripContainer}
          buttonStrip={
            <ButtonStrip
              size="medium"
              leftButton={{
                text: t('generic.resetGridSettings'),
                onClick: () => setResetDialog(true),
                color: 'warning',
              }}
              rightButton={{
                text: t('generic.save'),
                onClick: () => _onSave(),
                color: 'primary',
              }}
            />
          }
        >
          <Box component="form" noValidate autoComplete="off" sx={filterForm}>
            <ETOTextField
              id="employeeNameFilter"
              name="employeeNameFilter"
              label={t('Entities.Employee.fullName')}
              handleChange={(e) => {
                setTempFilters((old) => ({
                  ...old,
                  employeeFullName: e.target.value,
                }));
              }}
              value={tempFilters?.employeeFullName}
            />

            <ETOTextField
              id="employeeFirstNameFilter"
              name="employeeFirstNameFilter"
              label={t('Entities.Employee.firstName')}
              handleChange={(e) => {
                setTempFilters((old) => ({
                  ...old,
                  employeeFirstName: e.target.value,
                }));
              }}
              value={tempFilters?.employeeFirstName}
            />

            <ETOTextField
              id="employeeLastNameFilter"
              name="employeeLastNameFilter"
              label={t('Entities.Employee.lastName')}
              handleChange={(e) => {
                setTempFilters((old) => ({
                  ...old,
                  employeeLastName: e.target.value,
                }));
              }}
              value={tempFilters?.employeeLastName}
            />

            <ETOTextField
              type="number"
              id="employeeIdFilter"
              name="employeeIdFilter"
              label={t('Entities.Employee.id')}
              handleChange={(e) => {
                setTempFilters((old) => ({
                  ...old,
                  employeeId: parseInt(e.target.value, 10),
                }));
              }}
              value={tempFilters?.employeeId ?? ''}
            />

            <ETOTextField
              id="projectNameFilter"
              name="projectNameFilter"
              label={t('Entities.Project.Project')}
              handleChange={(e) => {
                setTempFilters((old) => ({
                  ...old,
                  projectName: e.target.value,
                }));
              }}
              value={tempFilters?.projectName}
            />

            <ETOTextField
              type="number"
              id="projectIdFilter"
              name="projectIdFilter"
              label={t('Entities.Project.id')}
              handleChange={(e) => {
                setTempFilters((old) => ({
                  ...old,
                  projectId: parseInt(e.target.value, 10),
                }));
              }}
              value={tempFilters?.projectId ?? ''}
            />

            <ETOTextField
              id="commentsFilter"
              name="commentsFilter"
              label={t('Entities.Timecard.comments')}
              handleChange={(e) => {
                setTempFilters((old) => ({
                  ...old,
                  comments: e.target.value,
                }));
              }}
              value={tempFilters?.comments}
            />

            <ETOTextField
              id="specNameFilter"
              name="specNameFilter"
              label={t('Entities.Machine.Job')}
              handleChange={(e) => {
                setTempFilters((old) => ({
                  ...old,
                  specName: e.target.value,
                }));
              }}
              value={tempFilters?.specName}
            />

            <ETOTextField
              type="number"
              id="specIdFilter"
              name="specIdFilter"
              label={t('Entities.Machine.id')}
              handleChange={(e) => {
                setTempFilters((old) => ({
                  ...old,
                  specId: parseInt(e.target.value, 10),
                }));
              }}
              value={tempFilters?.specId ?? ''}
            />

            <ETOTextField
              id="hourTypeNameFilter"
              name="hourTypeNameFilter"
              label={t('Entities.HourType.HourType')}
              handleChange={(e) => {
                setTempFilters((old) => ({
                  ...old,
                  hourTypeName: e.target.value,
                }));
              }}
              value={tempFilters?.hourTypeName}
            />

            <ETOTextField
              type="number"
              id="hourTypeIdFilter"
              name="hourTypeIdFilter"
              label={t('Entities.HourType.id')}
              handleChange={(e) => {
                setTempFilters((old) => ({
                  ...old,
                  hourTypeId: parseInt(e.target.value, 10),
                }));
              }}
              value={tempFilters?.hourTypeId ?? ''}
            />

            <ETOTextField
              type="number"
              id="hourRateFilter"
              name="hourRateFilter"
              label={t('Entities.Timecard.hourRate')}
              handleChange={(e) => {
                setTempFilters((old) => ({
                  ...old,
                  hourRate: parseInt(e.target.value, 10),
                }));
              }}
              value={tempFilters?.hourRate ?? ''}
            />

            <ETOTextField
              id="departmentNameFilter"
              name="departmentNameFilter"
              label={t('Entities.Employee.deptName')}
              handleChange={(e) => {
                setTempFilters((old) => ({
                  ...old,
                  departmentName: e.target.value,
                }));
              }}
              value={tempFilters?.departmentName}
            />

            <ETOTextField
              id="subDepartmentNameFilter"
              name="subDepartmentNameFilter"
              label={t('Entities.Employee.subDeptName')}
              handleChange={(e) => {
                setTempFilters((old) => ({
                  ...old,
                  subDepartmentName: e.target.value,
                }));
              }}
              value={tempFilters?.subDepartmentName}
            />

            <ETOTextField
              id="processScheduleDetailNameFilter"
              name="processScheduleDetailNameFilter"
              label={t('Entities.ProcessScheduleDetail.ProcessScheduleDetail')}
              handleChange={(e) => {
                setTempFilters((old) => ({
                  ...old,
                  processScheduleDetailName: e.target.value,
                }));
              }}
              value={tempFilters?.processScheduleDtailName}
            />

            <ETOTextField
              type="number"
              id="processScheduleDetailIdFilter"
              name="processScheduleDetailIdFilter"
              label={t('Entities.ProcessScheduleDetail.id')}
              handleChange={(e) => {
                setTempFilters((old) => ({
                  ...old,
                  processScheduleDetailId: parseInt(e.target.value, 10),
                }));
              }}
              value={tempFilters?.processScheduleDetailId ?? ''}
            />

            <ETOTextField
              id="nonConformanceNameFilter"
              name="nonConformanceNameFilter"
              label={t('Entities.NonConformance.NonConformance')}
              handleChange={(e) => {
                setTempFilters((old) => ({
                  ...old,
                  nonConformanceName: e.target.value,
                }));
              }}
              value={tempFilters?.nonConformanceName}
            />

            <ETOTextField
              type="number"
              id="nonConformanceIdFilter"
              name="nonConformanceIdFilter"
              label={t('Entities.NonConformance.id')}
              handleChange={(e) => {
                setTempFilters((old) => ({
                  ...old,
                  nonConformanceId: parseInt(e.target.value, 10),
                }));
              }}
              value={tempFilters?.nonConformanceId ?? ''}
            />

            <ETOCheckBox
              name="custom7Filter"
              label={t('Entities.Timecard.TimecardCustom7')}
              handleChange={() => {
                setTempFilters((old) => ({
                  ...old,
                  custom7: !old?.custom7,
                }));
              }}
              value={tempFilters?.custom7 as boolean}
            />
          </Box>
        </Inspector>
      )}

      {resetDialog && (
        <ConfirmDialogs
          open={resetDialog}
          title={t('dialogs.resetFilters.title')}
          content={t('dialogs.resetFilters.content')}
          leftButton={{
            onClick: () => setResetDialog(false),
            label: t('generic.cancel'),
          }}
          rightButton={{
            onClick: () => {
              _handleReset();
              setResetDialog(false);
            },
            label: t('generic.confirm'),
          }}
        />
      )}
    </>
  );
};

export default FilterDrawer;
