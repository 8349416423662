import { Box } from '@mui/material';
import { SettingsContext } from '@teto/react-component-library';
import { Map } from 'leaflet';
import React, { useCallback, useContext, useState } from 'react';
import useLocalStorage from 'use-local-storage';
import BarcodeScannerStatusIcon from '../../BarcodeScannerStatusIcon/BarcodeScannerStatusIcon';
import MapViewInspectorIcon from '../../MapViewInspectorIcon/MapViewInspectorIcon';
import MapWrapper, { LocationT } from '../../MapWrapper/MapWrapper';
import Inspector, { InspectorButtonOptionsI } from './Inspector';

interface TimeCardInspectorProps {
  title: string;
  barcodeEnabled: boolean;
  toggleBarcode: () => void;
  open: boolean;
  onClose: () => void;
  headerIcon?: React.ReactElement;
  children: React.ReactNode;
  additionalButtons?: InspectorButtonOptionsI;
  data?: LocationT[] | undefined;
  lockedInspector?: boolean;
  disableMap?: boolean;
}

const TimeCardInspector = (props: TimeCardInspectorProps) => {
  const {
    title,
    open,
    onClose,
    children,
    barcodeEnabled,
    toggleBarcode,
    headerIcon,
    additionalButtons,
    data,
    // eslint-disable-next-line no-unused-vars
    lockedInspector,
    disableMap,
  } = props;

  const settingsContext = useContext(SettingsContext);

  const [mapEnabled, setMapEnabled] = useLocalStorage('map-enabled', false);
  // const [coords, setCoords] = useState<LatLngExpression | undefined>(undefined);
  const [map, setMap] = useState<Map>();

  const _toggleMap = useCallback(() => {
    setMapEnabled((bc) => !bc);
  }, [setMapEnabled]);

  const BarcodeButtonIcon: InspectorButtonOptionsI = {
    name: 'barcode',
    icon: (
      <BarcodeScannerStatusIcon
        cameraEnabled={barcodeEnabled && !lockedInspector}
      />
    ),
    onClick: () => {
      toggleBarcode();
    },
    disabled: lockedInspector,
  };
  const MapButtonIcon = {
    name: 'map',
    icon: <MapViewInspectorIcon mapEnabled={mapEnabled && !lockedInspector} />,
    onClick: () => {
      _toggleMap();
    },
    disabled: lockedInspector,
  };
  // D.R.Y approach, this does not work
  // const isMapButtonEnabled = settingsContext.settings.geotaggingMode !== 'Disabled' ? [MapButtonIcon] : []
  /**
   * If disableMap is true, return BarcodeButtonIcon, otherwise return BarcodeButtonIcon, MapButtonIcon
   * @returns The returnButtonOptions function is returning an array of objects.
   */
  const returnButtonOptions = () => {
    if (disableMap) {
      if (additionalButtons) {
        return [BarcodeButtonIcon, additionalButtons];
      }
      return [BarcodeButtonIcon];
    }
    if (additionalButtons) {
      return [
        BarcodeButtonIcon,
        ...(settingsContext.settings.geotaggingMode !== 'Disabled'
          ? [MapButtonIcon]
          : []),
        additionalButtons,
      ];
    }
    return [
      BarcodeButtonIcon,
      ...(settingsContext.settings.geotaggingMode !== 'Disabled'
        ? [MapButtonIcon]
        : []),
    ];
  };
  const buttonOptions = returnButtonOptions();

  // for setting the zoom in
  // const getCoordsType = () => {
  //   if (data && data.length > 0) {
  //     if (data.some((d) => Object.keys(d).includes('timeSheetId'))) {
  //       return 'timecardCoords';
  //     }
  //     return 'punchInCoords';
  //   }
  //   return undefined;
  // };

  // const coordsType = getCoordsType();

  // useEffect(() => {
  //   if (coordsType && data && data.length > 0) {
  //     const coordsValue = data[0][coordsType];
  //     const coordsToNumber = coordsValue?.split(',');
  //     const arrOfNum = coordsToNumber?.map((str) => Number(str));

  //     setCoords(arrOfNum as LatLngTuple);
  //   }
  // }, [coordsType, data]);
  return (
    <Inspector
      onClose={onClose}
      open={open}
      title={title}
      options={buttonOptions}
      headerIcon={headerIcon}
    >
      {children}
      {mapEnabled && !disableMap && !lockedInspector && (
        <Box
          sx={{
            height: '50vh',
            p: 2,
          }}
        >
          <MapWrapper data={data} map={map} setMap={setMap} />
        </Box>
      )}
    </Inspector>
  );
};

export default TimeCardInspector;
