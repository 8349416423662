/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, SxProps, Typography } from '@mui/material';
import { ETOSelectField } from '@teto/react-component-library';
import { useFormikContext } from 'formik';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { QueryFunction, useQuery } from 'react-query';
import {
  Errors,
  ForeignKeyOperation,
  SelectorParameters,
  Values,
} from '../editorTypes';

export interface DependentEditorProps {
  dependentSelector: // eslint-disable-next-line no-unused-vars
  ((name: string, data: any) => void | SelectorParameters) | undefined;
  name: string;
  disabled: boolean;
  // eslint-disable-next-line no-unused-vars
  handleChange: (value: { [key: string]: string }) => void;
  handleForeignKeyChange?: (
    // eslint-disable-next-line no-unused-vars
    e: { value: string; name: string },
    // eslint-disable-next-line no-unused-vars
    data: { [key: string]: any }
  ) => { [key: string]: any };
  // eslint-disable-next-line no-unused-vars
  processForeignKey?: (cName: string, operation: ForeignKeyOperation) => any;
}

const dependentSx: SxProps = {
  position: 'relative',
  zIndex: 250,
};

const DependentEditor: React.FC<DependentEditorProps> = (props) => {
  const { t } = useTranslation();
  const {
    name,
    disabled,
    dependentSelector,
    handleChange,
    handleForeignKeyChange,
    processForeignKey,
  } = props;
  const formik = useFormikContext();
  const { errors, values } = formik;
  const selectorParams = dependentSelector?.(name, values);
  const selectValue = (values as Values)?.[processForeignKey?.(name, 'key')];
  const title = processForeignKey?.(name, 'title');

  const selectorItems = useQuery(
    selectorParams?.selectorQuery?.queryKey as string | unknown[],
    selectorParams?.selectorQuery?.queryFn as QueryFunction<
      string | unknown[] | any
    >,
    {
      ...selectorParams?.selectorQuery?.queryOptions,
      onSuccess(data) {
        if (data.length === 0) {
          handleChange({ name, value: 'empty' });
        }
        return data;
      },
    }
  );

  const _handleChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const selectedForeignKeyValues = selectorItems?.data.filter(
        (f: { id: number | string }) =>
          f.id === e.target.value || f.id.toString() === e.target.value
      )[0];
      const foreignChangedKeyValues = handleForeignKeyChange?.(
        { value: e.target.value, name: e.target.name },
        selectedForeignKeyValues
      );
      formik.setValues({
        ...(values as Values),
        ...foreignChangedKeyValues,
      });
      handleChange({ value: e.target.value, name: e.target.name });
    },
    [formik, handleChange, handleForeignKeyChange, selectorItems?.data, values]
  );

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {selectorItems.data && selectorItems.data.length > 0 && (
        <Box
          sx={dependentSx}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.stopPropagation();
            }
          }}
        >
          <Typography>{t(title)}</Typography>
          <ETOSelectField
            disabled={disabled}
            name={name}
            handleChange={(
              e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => _handleChange(e)}
            error={(errors as Errors)?.[name]}
            items={selectorItems.data ?? []}
            itemNameSelector={(item) =>
              selectorParams?.itemNameSelector?.(item) as string
            }
            itemValueSelector={(item) =>
              selectorParams?.itemValueSelector?.(item)
            }
            itemDisabledSelector={(item) =>
              selectorParams?.itemDisabledSelector?.(item) as boolean
            }
            value={selectValue}
            defaultValue={selectorParams?.defaultValue}
          />
        </Box>
      )}
    </>
  );
};

export default DependentEditor;
