import ReactDataGrid from '@inovua/reactdatagrid-enterprise';
import {
  TypeColumn,
  TypeComputedProps,
} from '@inovua/reactdatagrid-enterprise/types';
import { Box, Typography, useTheme } from '@mui/material';
import {
  AppearanceContext,
  MessageContext,
  SettingsContext,
} from '@teto/react-component-library';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import useStyles from '../../../../components/TetoGrid/GridStyles';
import { reactDataGridLicenseKey } from '../../../../components/TetoGrid/Licensing';
import { getGraphQLClient } from '../../../../helpers/graphQL/graphQLClient';

interface BOMPartsOrderReleaseProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

const container = {
  overflow: 'auto',
  maxHeight: '90%',
  px: 1,
  '& .MuiTypography-subtitle1': {
    mb: 2,
  },
};

const BOMPartsOrderRelease = (props: BOMPartsOrderReleaseProps) => {
  const { detail } = props;
  const messageContext = React.useContext(MessageContext);
  const settingsContext = React.useContext(SettingsContext);
  const { ready, t } = useTranslation();
  const inventoryId = isEmpty(detail.inventoryDetails)
    ? 0
    : detail.inventoryDetails[0].inventory.inventoryLocationId;
  const variables = {
    projectId: detail?.projectId,
    specId: detail?.specId,
    itemId: detail?.itemId,
    inventoryLocationId: inventoryId,
    processScheduleId: detail?.processScheduleId,
  };

  const _doQuery = async () => {
    const query = `query bOMReleaseHistoryByItem(
      $projectId: Int!
      $specId: Float!
      $itemId: Int!
      $inventoryLocationId: Int!
      $processScheduleId: Int
    ) {
      bOMReleaseHistoryByItem(
        itemId: $itemId
        projectId: $projectId
        specId: $specId
        inventoryLocationId: $inventoryLocationId
        processScheduleId: $processScheduleId
      ) {
        items {
          id
          projectId
          specId
          itemId
          quantityChange
          releasedByEmployee {
            id
            firstName
            lastName
          }
          releasedDateTime
          releaseNote
          type
        }
      }
    }

    `;

    const response = await getGraphQLClient().performQuery(
      query,
      variables,
      (err) => messageContext.setError(err.messages.join('\n ')),
      (err) => {
        // eslint-disable-next-line no-console
        console.log(JSON.stringify(err));
        // messageContext.setError(err.messages.join('\n '))
      }
    );

    return response;
  };

  const bOMReleaseHistoryQuery = useQuery(
    'bOMReleaseHistory',
    async () => _doQuery(),
    { enabled: true }
  );
  const data = bOMReleaseHistoryQuery?.data?.bOMReleaseHistoryByItem?.items;

  const gridRef = React.useRef<TypeComputedProps | null>(null);
  const theme = useTheme();
  const appearanceContext = React.useContext(AppearanceContext);
  const scrollStyle = {
    scrollThumbStyle: { backgroundColor: theme.palette.primary.main },
  };

  const currentTheme =
    appearanceContext.themeVariant === 'dark'
      ? 'default-dark'
      : 'default-light';

  const dateTimeRenderer = React.useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (d: any) => {
      if (!d) {
        return null;
      }

      return dayjs(d).format(settingsContext.settings.dateTimeFormat);
    },
    [settingsContext.settings.dateTimeFormat]
  );

  const columns: TypeColumn[] = React.useMemo(
    () => [
      {
        name: 'quantityChange',
        header: 'Quantity',
        sortable: true,
        groupBy: false,
        showColumnMenuTool: false,
        hideable: false,
      },
      {
        name: 'releasedDateTime',
        header: 'Released On',
        sortable: true,
        groupBy: false,
        showColumnMenuTool: false,
        hideable: false,
        render: ({ data: { releasedDateTime } }) =>
          dateTimeRenderer(releasedDateTime),
      },
      {
        name: 'releaseNote',
        header: 'Release Note',
        sortable: true,
        groupBy: false,
        showColumnMenuTool: false,
        hideable: false,
      },
      {
        name: 'type',
        header: 'Type',
        sortable: true,
        groupBy: false,
        showColumnMenuTool: false,
        hideable: false,
      },
      {
        name: 'releasedByEmployee',
        header: 'Released By',
        sortable: true,
        groupBy: false,
        showColumnMenuTool: false,
        hideable: false,
        render: ({ data: { releasedByEmployee } }) =>
          `${releasedByEmployee?.lastName}, ${releasedByEmployee?.firstName}`,
      },
    ],
    [dateTimeRenderer]
  );

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {ready && (
        <Box sx={[container, useStyles]}>
          <Typography variant="subtitle1">
            {t('Entities.PurchasingItemDetailSummary.bomPartsOrderReleases')}
          </Typography>
          {!isEmpty(data) && Array.isArray(data) && (
            <ReactDataGrid
              licenseKey={reactDataGridLicenseKey}
              columns={columns}
              dataSource={data}
              scrollProps={scrollStyle}
              handle={(r) => {
                gridRef.current = r ? r.current : null;
              }}
              theme={currentTheme}
              enableColumnAutosize
              style={{ minHeight: data.length > 1 ? 400 : '' }}
            />
          )}
        </Box>
      )}
    </>
  );
};

export default BOMPartsOrderRelease;
