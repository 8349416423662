/* eslint-disable @typescript-eslint/no-explicit-any */

const parseError = (
  eObj: any,
  // eslint-disable-next-line no-unused-vars
  onValErrors: (errs: { [key: string]: string }) => void,
  // eslint-disable-next-line no-unused-vars
  onError: (err: any) => void
) => {
  if (Object.prototype.hasOwnProperty.call(eObj, '*')) {
    onError(eObj['*'][0]);
  }

  let hasError = false;
  const keys = Object.keys(eObj);
  const results: { [key: string]: string } = {};
  keys.forEach((k) => {
    if (k !== '*') {
      // eslint-disable-next-line prefer-destructuring
      results[k] = eObj[k][0];
      hasError = true;
    }
  });

  if (hasError) onValErrors(results);
};

/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line import/prefer-default-export
export const parseServerResponse = (
  e: any,
  // eslint-disable-next-line no-unused-vars
  onValErrors: (errs: { [key: string]: string }) => void,
  // eslint-disable-next-line no-unused-vars
  onError: (err: any) => void
) => {
  if (Object.prototype.hasOwnProperty.call(e, 'errors')) {
    if (Object.prototype.hasOwnProperty.call(e.errors, 'errors')) {
      parseError(e.errors.errors, onValErrors, onError);
    } else {
      parseError(e.errors, onValErrors, onError);
    }
  } else {
    onError(e);
  }
};
