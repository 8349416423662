import SaveAltIcon from '@mui/icons-material/SaveAlt';
import React, { MutableRefObject, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { MessageContext } from '@teto/react-component-library';
import TetoGridRefType from '../../TetoGrid/TetoGridRefType';

interface ExportButtonProps {
  onClick?: () => void;
  gridRef: MutableRefObject<TetoGridRefType | undefined>;
}

const ExportButton = (props: ExportButtonProps) => {
  const { onClick, gridRef } = props;
  const { t } = useTranslation();
  const messageContext = useContext(MessageContext);
  return {
    title: t('generic.export'),
    icon: <SaveAltIcon />,
    onclick: () => {
      const gr = gridRef.current;
      // eslint-disable-next-line no-unused-expressions
      gr
        ? gr.exportData()
        : messageContext.setError(t('generic.message.failExportGrid'));
      // eslint-disable-next-line no-unused-expressions
      onClick && onClick();
    },
  };
};

export default ExportButton;
