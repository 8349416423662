import HourFormValues from '../components/TimeSheets/TimeSheetForms/Hour/HourFormValues';
import TimeFormValues from '../components/TimeSheets/TimeSheetForms/Time/TimeFormValues';

const timebasedRequestFormatOutput = (formValues: TimeFormValues) => {
  // remove all undefined fields the easy way
  const out = JSON.parse(
    JSON.stringify({
      employeeId: parseInt(formValues.employeeId as unknown as string, 10),
      projectId: parseInt(formValues.projectId as unknown as string, 10),
      specId: parseFloat(formValues.specId as unknown as string),
      hourTypeId: parseInt(formValues.hourTypeId as unknown as string, 10),
      processScheduleDetailId:
        formValues.processScheduleDetailId &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (formValues.processScheduleDetailId as any) !== '-1'
          ? parseInt(
              formValues.processScheduleDetailId as unknown as string,
              10
            )
          : undefined,
      nonConformanceId:
        formValues.nonConformanceId &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (formValues.nonConformanceId as any) !== '-1'
          ? parseInt(formValues.nonConformanceId as unknown as string, 10)
          : undefined,
      quantity: formValues.quantity,
      comments: formValues.comments,
      custom1: formValues.custom1,
      custom2: formValues.custom2,
      custom3: formValues.custom3,
      custom4: formValues.custom4,
      custom5: formValues.custom5?.utc(true) ?? undefined,
      custom6: formValues.custom6?.utc(true) ?? undefined,
      custom7: formValues.custom7,
      custom8: formValues.custom8,
      startTime: formValues.startTime?.utc(true),
      endTime: formValues.endTime?.utc(true),
      pSCCustom1: formValues.pSCCustom1,
      pSCCustom2: formValues.pSCCustom2,
      pSCCustom3: formValues.pSCCustom3,
      pSCCustom4: formValues.pSCCustom4,
      pSCCustom5: formValues.pSCCustom5?.utc(true) ?? undefined,
      pSCCustom6: formValues.pSCCustom6?.utc(true) ?? undefined,
      pSCCustom7: formValues.pSCCustom7,
      pSCCustom8: formValues.pSCCustom8,
      hourRate: formValues.hourRate,
      hourFactor: formValues.hourFactor,
    })
  );

  if (out.hour) {
    delete out.hour;
  }

  if (out.processScheduleDetailId <= 0) {
    delete out.processScheduleDetailId;
  }

  if (out.nonConformanceId <= 0) {
    delete out.nonConformanceId;
  }

  return out;
};

const hourbasedRequestFormatOutput = (formValues: HourFormValues) => {
  // remove all undefined fields the easy way
  const out = JSON.parse(
    JSON.stringify({
      date: formValues.date,
      employeeId: parseInt(formValues.employeeId as unknown as string, 10),
      projectId: parseInt(formValues.projectId as unknown as string, 10),
      specId: parseFloat(formValues.specId as unknown as string),
      hourTypeId: parseInt(formValues.hourTypeId as unknown as string, 10),
      processScheduleDetailId:
        formValues.processScheduleDetailId &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (formValues.processScheduleDetailId as any) !== '-1'
          ? parseInt(
              formValues.processScheduleDetailId as unknown as string,
              10
            )
          : undefined,
      nonConformanceId:
        formValues.nonConformanceId &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (formValues.nonConformanceId as any) !== '-1'
          ? parseInt(formValues.nonConformanceId as unknown as string, 10)
          : undefined,
      quantity: formValues.quantity,
      comments: formValues.comments,
      custom1: formValues.custom1,
      custom2: formValues.custom2,
      custom3: formValues.custom3,
      custom4: formValues.custom4,
      custom5: formValues.custom5 ?? undefined,
      custom6: formValues.custom6 ?? undefined,
      custom7: formValues.custom7,
      custom8: formValues.custom8,
      pSCCustom1: formValues.pSCCustom1,
      pSCCustom2: formValues.pSCCustom2,
      pSCCustom3: formValues.pSCCustom3,
      pSCCustom4: formValues.pSCCustom4,
      pSCCustom5: formValues.pSCCustom5 ?? undefined,
      pSCCustom6: formValues.pSCCustom6 ?? undefined,
      pSCCustom7: formValues.pSCCustom7,
      pSCCustom8: formValues.pSCCustom8,
      hour: formValues.hour,
      hourRate: formValues.hourRate,
      hourFactor: formValues.hourFactor,
      billable: formValues.billable,
    })
  );

  if (out.processScheduleDetailId <= 0) {
    delete out.processScheduleDetailId;
  }

  if (out.nonConformanceId <= 0) {
    delete out.nonConformanceId;
  }

  return out;
};

export { timebasedRequestFormatOutput, hourbasedRequestFormatOutput };
