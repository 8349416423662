import SendRoundedIcon from '@mui/icons-material/SendRounded';
import { MessageContext, SettingsContext } from '@teto/react-component-library';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TimeSheet } from 'teto-client-api';
import defaultDateRange from '../../../helpers/defaultDateRange';
import TimerContext from '../../TimeSheets/NavTimer/PunchInTimerContext';
import useSubmitTimesheet from '../../TimeSheets/TimesheetActions/useSubmitTimesheet';

dayjs.extend(isSameOrAfter);

interface TimesheetSubmitButtonProps {
  onClick?: () => void;
  disabled?: boolean;
  timesheet?: TimeSheet;
}

const useTimesheetSubmitButton = (props: TimesheetSubmitButtonProps) => {
  const { onClick, disabled, timesheet } = props;

  const messageContext = useContext(MessageContext);
  const timerContext = useContext(TimerContext);
  const { t } = useTranslation();
  const { mutate: submitTimesheet } = useSubmitTimesheet();
  const {
    settings: { startDayOfWeek },
  } = useContext(SettingsContext);

  const isDisabled = useMemo(
    () =>
      (timesheet?.startDate.isSameOrAfter(
        defaultDateRange(startDayOfWeek).start
      ) &&
        timerContext.status === 'active') ||
      disabled,
    [disabled, startDayOfWeek, timerContext.status, timesheet?.startDate]
  );

  return (
    timesheet?.status !== 'Submitted' &&
    timesheet?.status !== 'Approved' && {
      title: t('generic.submit'),
      icon: <SendRoundedIcon />,
      disabled: isDisabled,
      onclick: () => {
        if (!timesheet?.id) {
          messageContext.setError(
            `${t(
              'Entities.Timesheet.id'
            )} is not defined. Please contact your Admin.`
          );
          return;
        }
        // eslint-disable-next-line no-unused-expressions
        onClick ? onClick() : null;
        submitTimesheet({ id: timesheet?.id as number });
      },
      confirm: {
        type: 'okCancel' as const,
        title: t('dialogs.submitTimesheet.title'),
        content: t('dialogs.submitTimesheet.content'),
      },
    }
  );
};

export default useTimesheetSubmitButton;
