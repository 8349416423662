import { Button, Theme, Tooltip, useTheme } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import React from 'react';
import { getArticleLink } from '../../../helpers/zendeskHelpers';

interface NavHelpLinkProps {
  helpId?: string | number;
  title?: string;
}

const buttonSx = (theme: Theme) => ({
  minWidth: theme.spacing(3),
});

const NavHelpLink: React.FC<NavHelpLinkProps> = (props) => {
  const { helpId, title } = props;
  const theme = useTheme();
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {helpId && (
        <Tooltip title={`${title as string} Help`}>
          <Button
            component="a"
            href={getArticleLink(helpId)}
            target="_blank"
            rel="noreferrer"
            sx={buttonSx}
            fullWidth
          >
            <HelpOutlineIcon sx={{ color: theme.palette.grey[600] }} />
          </Button>
        </Tooltip>
      )}
    </>
  );
};

export default NavHelpLink;
