const getMyCurrentClockInStatus = `
query myCurrentClockInStatus {
  myCurrentClockInStatus{
    employee {
      id
    },                        
    clockedIn,
    startTime,
    endTime,
    timeElapsedInMinutes
  }
}
`;
export default getMyCurrentClockInStatus;
