import ReactDataGrid from '@inovua/reactdatagrid-enterprise';
import {
  TypeColumn,
  TypeComputedProps,
} from '@inovua/reactdatagrid-enterprise/types';
import SettingsBackupRestoreRoundedIcon from '@mui/icons-material/SettingsBackupRestoreRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import { Box, Button, Grid, Theme, Typography, useTheme } from '@mui/material';
import {
  AppearanceContext,
  AuthContext,
  ButtonStrip,
  ETOButton,
  ETOTextField,
  formatHoursAsHourMin,
  MessageContext,
  SettingsContext,
} from '@teto/react-component-library';
import dayjs from 'dayjs';
import { Formik } from 'formik';
import React, {
  FocusEventHandler,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { QueryFunctionContext, QueryKey, useQuery } from 'react-query';
import {
  getModelMetadata,
  getTimecards,
  ModelMetadata,
  PagedResponse,
  TimeCard,
  TimeCardLockedReason,
  TimeSheet,
  TimesheetStatus,
} from 'teto-client-api';
import * as Yup from 'yup';
import { buildCellFormatter } from '../../TetoGrid/Formatters/cellFormatterHelper';
import GridPersistenceContext from '../../TetoGrid/GridPersistence/GridPersistenceContext';
import GridPersistenceProvider from '../../TetoGrid/GridPersistence/GridPersistenceProvider';
import useGridStyles from '../../TetoGrid/GridStyles';
import { groupNameFormatter } from '../../TetoGrid/Grouping/groupingHelpers';
import { reactDataGridLicenseKey } from '../../TetoGrid/Licensing';
import { createColumnsFromMetaData } from '../../TetoGrid/ModelMetaDataProcessor/ModelMetaDataProcessor';
import ReactDataGridRefType from '../../TetoGrid/ReactDataGridRefType';
import TableColumnDefinition from '../../TetoGrid/TableColumnDefinition';
import Inspector from '../TimeCardInspector/Inspector';
import useApproveTimesheet from '../TimesheetActions/useApproveTimesheet';
import useRejectTimesheet from '../TimesheetActions/useRejectTimesheet';
import useUnapproveTimesheet from '../TimesheetActions/useUnapproveTimesheet';
import useUnrejectTimesheet from '../TimesheetActions/useUnrejectTimesheet';
import GroupingModal from './GroupingModal/GroupingModal';

const wrapperSx = {
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  flexGrow: 1,
  height: '100%',
};

const contentSx = {
  overflowY: 'auto',
};

const hoursStatusWrapSx = (theme: Theme) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    marginBottom: theme.spacing(1),
  },
});

const groupByWrapSx = (theme: Theme) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(2),
});

const groupByRightSx = (theme: Theme) => ({
  '& .MuiButton-root': {
    marginLeft: theme.spacing(1),
  },
});

const tableWrapSx = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  minHeight: theme.spacing(36),
  marginBottom: theme.spacing(2),
  '& .InovuaReactDataGrid__body': {
    height: '100%',
  },
  '& .InovuaReactDataGrid__column-header__filter-wrapper': {
    borderTop: 'none',
    padding: `${theme.spacing(0)} !important`,
  },
});

const statusWrapSx = {
  display: 'flex',
};

const buttonRowSx = (theme: Theme) => ({
  flex: `0 0 ${theme.spacing(7.5)}`,
  flexGrow: 0,
});

const buttonStripSx = {
  borderTop: 0,
};

const commentsTitleSx = (theme: Theme) => ({
  marginBottom: theme.spacing(1),
});

const approvedTextSx = (theme: Theme) => ({
  color: theme.palette.success.main,
});

const rejectedTextSx = (theme: Theme) => ({
  color: theme.palette.error.main,
});

const submittedTextSx = (theme: Theme) => ({
  color: theme.palette.warning.main,
});

const configureButtonSx = (theme: Theme) => ({
  textTransform: 'capitalize',
  height: theme.spacing(4),
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
  },
});

interface TimesheetInspectorProps {
  action: 'approve' | 'reject' | 'unapprove' | 'unreject';
  timesheet: TimeSheet;
  open: boolean;
  onClose: () => void;
  cols: TableColumnDefinition[];
  tableIdentifier: string;
}

const TimesheetInspector = (props: TimesheetInspectorProps) => {
  const { action, cols, timesheet, open, onClose, tableIdentifier } = props;

  const appearanceContext = useContext(AppearanceContext);
  const gridContext = useContext(GridPersistenceContext);
  const messageContext = useContext(MessageContext);
  const settingsContext = useContext(SettingsContext);

  const {
    hidden,
    columnOrder,
    grouping,
    resetSettings,
    setGrouping,
    setHidden,
    setColumnOrder,
  } = gridContext;

  const gridRef = useRef<TypeComputedProps | null>(null);

  const theme = useTheme();
  const scrollStyle = {
    scrollThumbStyle: { backgroundColor: theme.palette.primary.main },
  };
  const currentTheme =
    appearanceContext.themeVariant === 'dark'
      ? 'default-dark'
      : 'default-light';

  const { t } = useTranslation();

  const { mutate: approveTimesheet } = useApproveTimesheet();
  const { mutate: rejectTimesheet } = useRejectTimesheet();
  const { mutate: unapproveTimesheet } = useUnapproveTimesheet();
  const { mutate: unrejectTimesheet } = useUnrejectTimesheet();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [comments, setComments] = useState<string>('');
  const [commentsError, setCommentsError] = useState<string>('');
  // eslint-disable-next-line no-unused-vars
  const [reactDataGridRef, setReactDataGridRef] =
    useState<ReactDataGridRefType>();
  const [refreshToken, setRefreshToken] = useState<Date>(new Date());

  const currentTimesheetInfo = useMemo(
    () => ({ id: timesheet.id, message: comments }),
    [comments, timesheet.id]
  );

  const _handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setComments(e.target.value);
  };

  function _doQuery(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    context: QueryFunctionContext<QueryKey, any>,
    pageSize: number,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    queryData: { filters: any; orderBy: any }
  ) {
    return getTimecards({
      pageIndex: context.pageParam,
      pageSize,
      disablePaging: false,
      orderBy: queryData.orderBy,
      filter: {
        ...queryData.filters,
      },
    }).then((d) => ({
      ...d,
      records: d.records,
    }));
  }
  const query = useQuery<PagedResponse<TimeCard>>(
    [tableIdentifier, timesheet],
    (fc) =>
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      _doQuery(fc as any, 1000, {
        filters: { timeSheetId: timesheet.id },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as any).then((qr) => {
        if (qr.maxExceeded) {
          messageContext.setWarning(
            `Table Row Limit Hit: Query returned ${qr.records.length} records of ${qr.totalRecords}`
          );
        }

        return qr;
      }),
    {
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );

  const _onCancel = () => {
    setAnchorEl(null);
  };

  const _onGroupByClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined
  ) => {
    if (e) {
      setAnchorEl(e.currentTarget);
    }
  };

  const ApproveButton = useMemo(
    () => ({
      text: t('Entities.TimeSheet.approve'),
      onClick: () => {
        approveTimesheet(currentTimesheetInfo);
        onClose();
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      color: 'primary' as any,
      disabled: timesheet.status !== 'Submitted' || commentsError,
    }),
    [
      approveTimesheet,
      commentsError,
      currentTimesheetInfo,
      onClose,
      t,
      timesheet.status,
    ]
  );

  const UnapproveButton = useMemo(
    () => ({
      text: t('Entities.TimeSheet.unapprove'),
      onClick: () => {
        unapproveTimesheet(currentTimesheetInfo);
        onClose();
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      color: 'primary' as any,
    }),
    [currentTimesheetInfo, onClose, t, unapproveTimesheet]
  );

  const RejectButton = useMemo(
    () => ({
      text: t('Entities.TimeSheet.reject'),
      onClick: () => {
        onClose();
        rejectTimesheet(currentTimesheetInfo);
      },
      color: 'error',
      disabled: timesheet.status !== 'Submitted' || commentsError,
    }),
    [
      commentsError,
      currentTimesheetInfo,
      onClose,
      rejectTimesheet,
      t,
      timesheet.status,
    ]
  );

  const UnrejectButton = useMemo(
    () => ({
      text: t('Entities.TimeSheet.unreject'),
      onClick: () => {
        unrejectTimesheet(currentTimesheetInfo);
        onClose();
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      color: 'primary' as any,
    }),
    [currentTimesheetInfo, onClose, t, unrejectTimesheet]
  );

  const _statusColor = (timesheetStatus: TimesheetStatus | undefined) => {
    switch (timesheetStatus) {
      case 'Approved':
        return approvedTextSx;
      case 'Rejected':
        return rejectedTextSx;
      case 'Submitted':
        return submittedTextSx;
      default:
        return undefined;
    }
  };

  const updatedColumns = useMemo<TableColumnDefinition[]>(
    () =>
      cols
        .sort((a, b) => {
          const aIndex = columnOrder.indexOf(a.name);
          const bIndex = columnOrder.indexOf(b.name);
          if (aIndex === -1) {
            return 1;
          }
          if (bIndex === -1) {
            return -1;
          }
          if (aIndex < bIndex) return 1;
          if (aIndex > bIndex) return -1;
          return 0;
        })
        .reverse()
        .map((item) => ({
          ...item,
          hidden: item.disableHideable ? false : hidden.indexOf(item.name) >= 0,
        })),
    [cols, columnOrder, hidden]
  );

  const YupSchema = Yup.object({
    comments: Yup.string().max(2048, 'Comments exceed max character length'),
  });

  const _handleBlur = () => {
    YupSchema.validate({ comments })
      .then(() => setCommentsError(''))
      .catch((err) => setCommentsError(err.message));
  };

  const buttonStripButton = useMemo(() => {
    switch (action) {
      case 'approve':
        return ApproveButton;
      case 'unapprove':
        return UnapproveButton;
      case 'reject':
        return RejectButton;
      case 'unreject':
      default:
        return UnrejectButton;
    }
  }, [ApproveButton, RejectButton, UnapproveButton, UnrejectButton, action]);

  const convertedToRDGColumns = useMemo<TypeColumn[]>(
    () =>
      updatedColumns.map((c) => ({
        name: c.name,
        type: c.type,
        header: c.title,
        hideable: !c.disableHideable,
        showInContextMenu: false,
        hidden: c.hidden,
        align: c.align,
        groupByName: c.title,
        isCheckboxColumn: c.type === 'boolean',
        draggable: !c.disableGrouping,
        id: c.name,
        width: c.width,
        defaultWidth: c.defaultWidth,
        minWidth: c.minWidth,
        flex: c.flex,
        defaultFlex: c.defaultFlex,
        textAlign: c.align,
        headerProps: c.headerProps,
        groupSummaryReducer: c.groupSummaryReducer,
        groupToString: (v) =>
          groupNameFormatter(
            {
              dateFormat: settingsContext.settings.dateFormat,
              dateTimeFormat: settingsContext.settings.dateTimeFormat,
              timeFormat: settingsContext.settings.timeFormat,
            },
            t,
            c,
            v
          ),
        filterDelay: 500,
        sortable: c.sortable,
        visible: !c.hidden,
        enableColumnFilterContextMenu: !c.disableColumnFilterContextMenu,
        showColumnMenuTool: false,
        groupBy: !c.disableGrouping,
        render: buildCellFormatter(c),
        filterType: c.filterType,
        filterable: true,
      })),
    [
      settingsContext.settings.dateFormat,
      settingsContext.settings.dateTimeFormat,
      settingsContext.settings.timeFormat,
      t,
      updatedColumns,
    ]
  );

  const _onColumnVisibleChange = useCallback(
    (cv: { column: TypeColumn; visible: boolean }) => {
      const isHidden = !cv.column.name
        ? false
        : hidden.indexOf(cv.column.name) >= 0;
      if (cv.visible && isHidden && cv.column.name) {
        setHidden(hidden.filter((a) => a !== cv.column.name));
      }

      if (!cv.visible && !isHidden && cv.column.name) {
        setHidden(hidden.concat([cv.column.name as string]));
      }
    },
    [hidden, setHidden]
  );

  useEffect(() => {
    gridRef.current?.reload();
  }, [refreshToken]);

  return (
    <Inspector title={timesheet.employeeFullName} open={open} onClose={onClose}>
      <Box sx={wrapperSx}>
        <Grid container spacing={0} sx={contentSx}>
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              {dayjs(timesheet.startDate).format(
                settingsContext.settings.dateFormat
              )}{' '}
              -{' '}
              {dayjs(timesheet.endDate).format(
                settingsContext.settings.dateFormat
              )}
            </Typography>
          </Grid>

          <Grid item xs={6} sx={hoursStatusWrapSx}>
            <Box display="flex">
              <Typography variant="subtitle1" color="textSecondary">
                {t('Entities.TimeSheet.totalHours')}:
              </Typography>
              <Typography variant="subtitle1">
                &nbsp;{formatHoursAsHourMin(timesheet.totalHours)}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={6} sx={statusWrapSx} justifyContent="flex-end">
            <Box display="flex">
              <Typography variant="subtitle1" color="textSecondary">
                {t('Entities.TimeSheet.status')}:
              </Typography>
              <Typography
                variant="subtitle1"
                sx={_statusColor(timesheet?.status)}
              >
                &nbsp;{timesheet.status ?? 'In Progress'}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box sx={groupByWrapSx}>
              <Typography variant="subtitle1">
                {t('generic.summary')}
              </Typography>

              <Box sx={groupByRightSx}>
                {/* <ETOButton
                  color="primary"
                  icon={<FilterNoneRoundedIcon />}
                  size="small"
                  onClick={(e) => _onGroupByClick(e)}
                >
                  {t('generic.groupBy')}
                </ETOButton> */}

                {/* <ETOButton
                  color="primary"
                  icon={<SettingsRoundedIcon />}
                  size="small"
                  onClick={(e) => _onGroupByClick(e)}
                >
                  {t('generic.configure')}
                </ETOButton> */}
                <Button
                  variant="outlined"
                  startIcon={<SettingsRoundedIcon />}
                  onClick={(e) => _onGroupByClick(e)}
                  color="primary"
                  size="small"
                  sx={configureButtonSx}
                >
                  {t('generic.configure')}
                </Button>
                <ETOButton
                  color="primary"
                  icon={<SettingsBackupRestoreRoundedIcon />}
                  size="small"
                  onClick={() => resetSettings()}
                >
                  {t('generic.resetGridSettings')}
                </ETOButton>
              </Box>
            </Box>
          </Grid>

          {/* <Grid item xs={12}>
            <List sx={groupListSx}>
              {cols && cols.length > 0 && grouping?.map((group) => (
                <ListItem key={`group-${group}`} sx={groupItemSx}>
                  <ListItemText disableTypography>
                    <Typography variant="subtitle2">{updatedColumns.filter((col) => col.name === group)[0].title}</Typography>
                  </ListItemText>
                  <ClickAwayListener onClickAway={() => setTooltipOpen(false)}>
                    <Box>
                      <Tooltip
                        PopperProps={{
                          disablePortal: true,
                        }}
                        open={tooltipOpen}
                        onClose={() => setTooltipOpen(false)}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        arrow
                        placement="top"
                        title="Cannot save grouping with less than one column selected"
                      >
                        <IconButton
                          color="primary"
                          size="small"
                          onClick={() => _onGroupByRemoved(group)}
                          sx={removeGroupBtnSx}
                        >
                          <CloseRoundedIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </ClickAwayListener>
                </ListItem>
              ))}

            </List>
          </Grid> */}

          <Grid item xs={12}>
            <Box sx={[tableWrapSx, useGridStyles]}>
              {/* This is here to enable use of cellFromatter() */}
              <Formik
                initialValues={{}}
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onSubmit={() => {}}
              >
                <ReactDataGrid
                  onReady={setReactDataGridRef}
                  handle={(r) => {
                    gridRef.current = r ? r.current : null;
                  }}
                  licenseKey={reactDataGridLicenseKey}
                  columns={convertedToRDGColumns}
                  columnOrder={columnOrder}
                  dataSource={query.data?.records ?? []}
                  groupBy={grouping ?? []}
                  expandGroupTitle
                  filterRowHeight={0}
                  showColumnMenuTool={false}
                  loading={query.isFetching}
                  onGroupByChange={setGrouping}
                  onColumnVisibleChange={_onColumnVisibleChange}
                  defaultCollapsedGroups
                  scrollProps={scrollStyle}
                  /* groupColumn={{
                    minWidth: 265,
                  }} */
                  theme={currentTheme}
                  emptyText={t('generic.message.noRecordsFound')}
                />
              </Formik>
            </Box>
          </Grid>

          {timesheet.status !== ('In Progress' || 'Approved') && (
            <Grid item xs={12}>
              <Typography variant="h6" sx={commentsTitleSx}>
                {t('forms.fields.comments')}
              </Typography>
              <ETOTextField
                name="comments"
                handleChange={(e) => _handleChange(e)}
                value={comments}
                disabled={timesheet.status === 'Rejected'}
                label={t('forms.fields.comments')}
                multiline
                error={commentsError}
                inputProps={{
                  // Questionable
                  onBlur:
                    _handleBlur() as unknown as FocusEventHandler<HTMLTextAreaElement>,
                }}
                minRows={4}
                maxRows={4}
              />
            </Grid>
          )}
        </Grid>
        <Box sx={buttonRowSx}>
          <ButtonStrip
            rightButton={buttonStripButton}
            className={buttonStripSx}
            size="medium"
          />
        </Box>
      </Box>

      {cols && timesheet && (
        <GroupingModal
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          columns={updatedColumns}
          initialColumnOrder={columnOrder}
          setRefreshToken={setRefreshToken}
          setHidden={setHidden}
          setColumnOrder={setColumnOrder}
          onCancel={_onCancel}
        />
      )}
    </Inspector>
  );
};

type WrapperProps = Omit<TimesheetInspectorProps, 'cols'>;

const TimesheetInspectorWrapper = (props: WrapperProps) => {
  const { timesheet, open, onClose, action } = props;
  const { t, ready } = useTranslation();

  const messageContext = useContext(MessageContext);
  const authContext = useContext(AuthContext);

  const [metaData, setMetaData] = useState<ModelMetadata | undefined>();

  useEffect(() => {
    if (authContext.hasProfessionalLicense()) {
      getModelMetadata('timecard')
        .then((e) => setMetaData(e))
        .catch((e) => {
          messageContext.setError(
            `${t('generic.message.failMeta')}.  ${e.message}`
          );
        });
    }
  }, [authContext, messageContext, t]);

  const cols = useMemo(() => {
    if (metaData && ready) {
      return createColumnsFromMetaData<TimeCard>(metaData)
        .updateDefinition('startTime', { disableGrouping: true })
        .updateDefinition('endTime', { disableGrouping: true })
        .updateDefinition('nonConformanceName', { hidden: true })
        .updateDefinition('processScheduleDetailName', { hidden: true })
        .updateDefinition('custom7', { hidden: true })
        .updateDefinition('custom5', { hidden: true })
        .updateDefinition('custom6', { hidden: true })
        .updateDefinition('comments', { hidden: true })
        .updateDefinition('hourTime', { disableGrouping: true })
        .removeProperty('employeeFullName')
        .updateDefinition('locked', {
          bitwiseOptions: TimeCardLockedReason,
          filterOptions: 'simple',
          type: 'bitwise',
          align: 'center',
        })
        .removeProperty(
          'custom1',
          () => t('Entities.Timecard.TimecardCustom1') === 'Timecard Custom 1'
        )
        .removeProperty(
          'custom2',
          () => t('Entities.Timecard.TimecardCustom2') === 'Timecard Custom 2'
        )
        .removeProperty(
          'custom3',
          () => t('Entities.Timecard.TimecardCustom3') === 'Timecard Custom 3'
        )
        .removeProperty(
          'custom4',
          () => t('Entities.Timecard.TimecardCustom4') === 'Timecard Custom 4'
        )
        .removeProperty(
          'custom5',
          () => t('Entities.Timecard.TimecardCustom5') === 'Timecard Custom 5'
        )
        .removeProperty(
          'custom6',
          () => t('Entities.Timecard.TimecardCustom6') === 'Timecard Custom 6'
        )
        .removeProperty(
          'custom7',
          () => t('Entities.Timecard.TimecardCustom7') === 'Timecard Custom 7'
        )
        .removeProperty(
          'custom8',
          () => t('Entities.Timecard.TimecardCustom8') === 'Timecard Custom 8'
        )
        .removeProperty(
          'pSCCustom1',
          () =>
            t(
              'Entities.Process Schedule In House Transaction.CompletedLogCustom1'
            ) === 'Process Schedule In House Transaction Custom 1'
        )
        .removeProperty(
          'pSCCustom2',
          () =>
            t(
              'Entities.Process Schedule In House Transaction.CompletedLogCustom2'
            ) === 'Process Schedule In House Transaction Custom 2'
        )
        .removeProperty(
          'pSCCustom3',
          () =>
            t(
              'Entities.Process Schedule In House Transaction.CompletedLogCustom3'
            ) === 'Process Schedule In House Transaction Custom 3'
        )
        .removeProperty(
          'pSCCustom4',
          () =>
            t(
              'Entities.Process Schedule In House Transaction.CompletedLogCustom4'
            ) === 'Process Schedule In House Transaction Custom 4'
        )
        .removeProperty(
          'pSCCustom5',
          () =>
            t(
              'Entities.Process Schedule In House Transaction.CompletedLogCustom5'
            ) === 'Process Schedule In House Transaction Custom 5'
        )
        .removeProperty(
          'pSCCustom6',
          () =>
            t(
              'Entities.Process Schedule In House Transaction.CompletedLogCustom6'
            ) === 'Process Schedule In House Transaction Custom 6'
        )
        .removeProperty(
          'pSCCustom7',
          () =>
            t(
              'Entities.Process Schedule In House Transaction.CompletedLogCustom7'
            ) === 'Process Schedule In House Transaction Custom 7'
        )
        .removeProperty(
          'pSCCustom8',
          () =>
            t(
              'Entities.Process Schedule In House Transaction.CompletedLogCustom8'
            ) === 'Process Schedule In House Transaction Custom 8'
        )
        .finalize(t);
    }
    return [];
  }, [metaData, ready, t]);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {ready && cols && (
        <GridPersistenceProvider
          defaultGrouping={['projectName', 'specName', 'hourTypeName']}
          tableIdentifier="timesheetInspector"
          columns={cols}
          persistenceType={
            authContext.hasEnterpriseLicense() ? 'db' : 'sessionStorage'
          }
        >
          <TimesheetInspector
            {...props}
            timesheet={timesheet}
            open={open}
            onClose={onClose}
            cols={cols}
            action={action}
          />
        </GridPersistenceProvider>
      )}
    </>
  );
};

export default TimesheetInspectorWrapper;
