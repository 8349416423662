import TableColumnDefinition from '../TableColumnDefinition';

const filterHiddenCols = (
  cols: TableColumnDefinition[],
  curHidden: string[]
) => {
  const filteredNames: string[] = [];
  cols.forEach((h) => {
    if (curHidden.includes(h.name)) {
      return;
    }
    filteredNames.push(h.name);
  });
  // const filteredCols = cols.filter((h) => {
  //   if (!hidden.includes(h.name)) {
  //     return h.name;
  //   }
  //   return [];
  // });

  // const filteredNames = filteredCols.map((c) => c.name);
  return filteredNames;
};
export default filterHiddenCols;
