import { TFunction } from 'i18next';

const customRegex = /^(custom)([1-8]{1})$/;

export const groupableColumns: { [key: string]: string } = {
  projectName: 'Entities.Project.Project',
  specName: 'Entities.Machine.Job',
  hourTypeName: 'Entities.HourType.HourType',
  nonConformanceName: 'Entities.NonConformance.NonConformance',
  processScheduleDetailName:
    'Entities.ProcessScheduleDetail.ProcessScheduleDetail',
  comments: 'Entities.Timecard.comments',
  custom1: 'Entities.Timecard.TimecardCustom1',
  custom2: 'Entities.Timecard.TimecardCustom2',
  custom3: 'Entities.Timecard.TimecardCustom3',
  custom4: 'Entities.Timecard.TimecardCustom4',
  custom5: 'Entities.Timecard.TimecardCustom5',
  custom6: 'Entities.Timecard.TimecardCustom6',
  custom7: 'Entities.Timecard.TimecardCustom7',
  custom8: 'Entities.Timecard.TimecardCustom8',
};

export const checkValidCustomColumns = (columns: string[], t: TFunction) =>
  columns.filter(
    (col) =>
      (customRegex.test(col) &&
        !t(`Entities.Timecard.TimecardC${col.slice(1)}`).includes('Custom')) ||
      !customRegex.test(col)
  );
