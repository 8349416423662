import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import TimelineIcon from '@mui/icons-material/Timeline';
import {
  AuthContext,
  PageTabs,
  SettingsContext,
} from '@teto/react-component-library';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { QueryFunctionContext, QueryKey } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  betweenDate,
  getPunchIns,
  getTimecards,
  Permission,
} from 'teto-client-api';
import useLocalStorage from 'use-local-storage';
import TetoContainer from '../../components/TetoGrid/TetoContainer';
import { DateRange } from '../../helpers/dateTypes';
import ActiveTab from './ActiveTab/ActiveTab';
import HistoricalTab from './HistoricalTab/HistoricalTab';

const GeolocationPage = () => {
  const { t, ready } = useTranslation();

  const authContext = useContext(AuthContext);
  const {
    settings: { approverCanManageTime },
  } = useContext(SettingsContext);
  const canAccessTimesheets =
    authContext.hasEnterpriseLicense() &&
    (authContext.hasAnyPermission([
      Permission.View_Admin_Timecards,
      Permission.Modify_Admin_Timecards,
    ]) ||
      (approverCanManageTime && authContext.user?.isApprover));

  const navigate = useNavigate();
  const [useApiFallback, setUseApiFallback] = useLocalStorage(
    'use-api-fallback',
    false
  );

  const _doActiveQuery = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    context: QueryFunctionContext<QueryKey, any>,
    pageSize: number,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    queryData: { filters: any; orderBy: any }
  ) =>
    getPunchIns({
      pageIndex: context.pageParam,
      pageSize,
      disablePaging: false,
      orderBy: queryData.orderBy,
      filter: {
        active: true,
        ...queryData.filters,
      },
    }).then((d) => ({
      ...d,
      records: d.records,
    }));

  const _doQuery = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    context: QueryFunctionContext<QueryKey, any>,
    pageSize: number,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    queryData: { filters: any; orderBy: any } & { params: DateRange }
  ) =>
    getTimecards({
      pageIndex: context.pageParam,
      pageSize,
      disablePaging: false,
      orderBy: queryData.orderBy,
      filter: {
        date: betweenDate(queryData.params.start, queryData.params.end),
        ...queryData.filters,
      },
    }).then((d) => ({
      ...d,
      records: d.records,
    }));

  const location = useLocation();

  const geolocationTabs = {
    active: {
      title: t('Entities.PunchIn.activeTab'),
      icon: <PersonPinCircleIcon />,
      component: (
        <ActiveTab
          doQuery={_doActiveQuery}
          useApiFallback={useApiFallback}
          setUseApiFallback={setUseApiFallback}
        />
      ),
      disabled: !authContext.hasEnterpriseLicense(),
      priority: 1,
    },
    historical: {
      title: t('Entities.PunchIn.historicalTab'),
      icon: <TimelineIcon />,
      component: (
        <HistoricalTab
          doQuery={_doQuery}
          useApiFallback={useApiFallback}
          setUseApiFallback={setUseApiFallback}
        />
      ),
      priority: 2,
    },
  };
  const geolocationTabKeys = Object.keys(geolocationTabs);
  const availableTabs = geolocationTabKeys.filter((tab) =>
    location.pathname.includes(tab)
  );

  const selectedTab = availableTabs.length > 0 ? availableTabs[0] : undefined;
  useEffect(() => {
    if (availableTabs.length === 0) {
      navigate('/timeTracking/geolocation/active', { replace: true });
    }
  }, [availableTabs.length, canAccessTimesheets, navigate]);
  return (
    <TetoContainer withBottomNav>
      {ready && selectedTab && (
        <PageTabs
          customTabPanelSx={{
            overflow: 'hidden',
          }}
          tabs={geolocationTabs}
          selectedTab={selectedTab}
          handleTabChange={(e) => navigate(`/timeTracking/geolocation/${e}`)}
        />
      )}
    </TetoContainer>
  );
};

export default GeolocationPage;
