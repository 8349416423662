import Box from '@mui/material/Box';
import { SxProps, Theme } from '@mui/material/styles';
import dayjs, { Dayjs } from 'dayjs';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { getDisabledValue, onFocus, onKeyDown } from '../editingHelpers';
import { Errors, Values } from '../editorTypes';
import editorStyles from '../InlineEditingCommonStyles';
import InlineEditorProps from '../InlineEditingProps';

interface PickerEditorWrapperProps extends InlineEditorProps {
  child: React.ReactElement;
  customCellSx?: SxProps;
}

const localSx: SxProps<Theme> = {
  '& .MuiInputBase-root, & .MuiFormControl-root': {
    height: '100%',
  },
  '& .MuiFormHelperText-root': {
    fontSize: '0.9rem',
    position: 'absolute',
    zIndex: 1500,
    bottom: '-30px',
    backgroundColor: 'rgba(97, 97, 97, 0.92)',
    marginLeft: 0,
    marginRight: 0,
    color: (theme) => `${theme.palette.common.white} !important`,
    padding: (theme) => `${theme.spacing(0)} ${theme.spacing(1.75)}`,
    borderRadius: (theme) => theme.spacing(0.5),
    '& .Mui-error': {
      color: (theme) => `${theme.palette.common.white} !important`,
      '&:hover': {
        color: 'common.white',
      },
    },
  },
  '& .MuiInputBase-input': {
    minWidth: '0px !important',
  },
};

const PickerEditorWrapper = (props: PickerEditorWrapperProps) => {
  const {
    autoFocus,
    cellProps,
    editorProps,
    onCancel,
    onComplete,
    onTabNavigation,
    child,
    customCellSx,
  } = props;

  const formik = useFormikContext();
  const columnName = cellProps?.name as string;
  const isRowDisabled = getDisabledValue(
    cellProps?.data,
    editorProps?.disabled
  );
  const isCellDisabled = editorProps?.lockColumn?.(
    columnName as string,
    cellProps?.data
  );
  const { errors, values } = formik;
  const [updateComplete, setUpdateComplete] =
    useState<Dayjs | null | undefined>(undefined);
  const editHandlers = {
    onCancel,
    onComplete,
    onTabNavigation,
  };

  useEffect(() => {
    if (updateComplete && (values as Values)?.[columnName] !== updateComplete) {
      onComplete?.();
    }
  }, [columnName, onComplete, updateComplete, values]);

  const _handleChange = (val: dayjs.Dayjs | null | undefined) => {
    formik.setFieldValue(columnName, val);
  };

  const _onAccept = (val: dayjs.Dayjs | null | undefined) => {
    if ((values as Values)?.[columnName] === val) {
      onComplete?.();
    } else {
      formik.setFieldValue(columnName, val);
      setUpdateComplete((values as Values)?.[columnName] as Dayjs);
    }
  };

  const _handleKeyEvent = (e: React.KeyboardEvent<HTMLInputElement>) =>
    onKeyDown(e, editHandlers);

  const childProps = {
    autoFocus: autoFocus ?? true,
    customSx: editorStyles.input,
    disabled:
      !editorProps?.hasEditPermission || isRowDisabled || isCellDisabled,
    error: errors[columnName as keyof Errors],
    handleChange: _handleChange,
    onAccept: _onAccept,
    InputAdornmentProps: { onKeyDown: _handleKeyEvent },
    name: columnName,
    onFocus,
    onKeyDown: _handleKeyEvent,
    inputProps: {
      style: {
        minWidth: Math.max(0, (cellProps?.computedWidth as number) - 30),
      },
    },
    InputProps: {
      error: errors[columnName as keyof Errors],
    },
    size: 'small',
    value: (values as Values)?.[columnName],
  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {columnName &&
        !isRowDisabled &&
        !isCellDisabled &&
        editorProps?.hasEditPermission && (
          <Box
            sx={[
              { ...(customCellSx as { [key: string]: unknown }) },
              editorStyles.root,
              localSx,
            ]}
            data-testid="container"
          >
            {React.cloneElement(child, { ...childProps })}
          </Box>
        )}
    </>
  );
};

export default PickerEditorWrapper;
