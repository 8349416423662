import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CameraOnIcon from '@mui/icons-material/LinkedCamera';
import CameraOffIcon from '@mui/icons-material/VideocamOff';
import { useTheme } from '@mui/material';

interface BarcodeScannerStatusIconProps {
  cameraEnabled: boolean;
}

const BarcodeScannerStatusIcon = (props: BarcodeScannerStatusIconProps) => {
  const { t } = useTranslation();
  const { cameraEnabled } = props;
  const theme = useTheme();

  return (
    <Tooltip
      title={
        cameraEnabled
          ? t('forms.barcodeScanner.disable')
          : t('forms.barcodeScanner.enable')
      }
    >
      {cameraEnabled ? (
        <CameraOffIcon fill={theme.palette.primary.dark} />
      ) : (
        <CameraOnIcon fill={theme.palette.grey[500]} />
      )}
    </Tooltip>
  );
};

export default BarcodeScannerStatusIcon;
