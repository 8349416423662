import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, SxProps, Theme, Typography } from '@mui/material';

const noRecordsSx = (theme: Theme) => ({
  padding: theme.spacing(6),
  width: '100%',
  textAlign: 'center',
  color: theme.palette.text.primary,
});

interface NoRecordsProps {
  customSx?: SxProps;
}

const NoRecords = (props: NoRecordsProps) => {
  const { customSx } = props;
  const { t } = useTranslation();

  return (
    <Box
      sx={[noRecordsSx, ...(Array.isArray(customSx) ? customSx : [customSx])]}
    >
      <Typography variant="body2">
        {t('generic.message.noRecordsFound')}
      </Typography>
    </Box>
  );
};

export default NoRecords;
