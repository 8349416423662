import {
  Box,
  ClickAwayListener,
  Modal,
  Theme,
  Typography,
} from '@mui/material';
import {
  formatHoursAsHourMin,
  SettingsContext,
} from '@teto/react-component-library';
import dayjs, { Dayjs } from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import localeData from 'dayjs/plugin/localeData';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import minMax from 'dayjs/plugin/minMax';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Calendar, EventWrapperProps } from 'react-big-calendar';
import { TimeSheet } from 'teto-client-api';
import dayLocalizer from './dayjsLocalizer';
import './monthStyling.css';

dayjs.locale('en');
dayjs.extend(isSameOrBefore);
dayjs.extend(localeData);
dayjs.extend(minMax);
dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

const localizer = dayLocalizer(dayjs);

const calendarSx = (theme: Theme) => ({
  borderRadius: `${theme.shape.borderRadius}px`,
  border: `1px solid ${theme.palette.divider}`,
  height: '100%',
  // height: theme.spacing(52),

  // [theme.breakpoints.up('sm')]: {
  //   height: theme.spacing(56),
  // },

  // [theme.breakpoints.up('md')]: {
  //   height: '100%',
  // },

  '& .MuiTypography-root': {
    color: theme.palette.text.primary,
  },
  '& .rbc-today': {
    backgroundColor: theme.palette.primary.dark,
  },
  // initial day
  '& div[class="rbc-date-cell rbc-now rbc-current"]': {
    color: `${theme.palette.getContrastText(theme.palette.primary.dark)} `,
  },

  '& .rbc-now ': {
    color: `${theme.palette.getContrastText(theme.palette.primary.dark)} `,
  },
  '& .eventWrapper': {
    '& div[class="rbc-event-content"]': {
      color: `${theme.palette.getContrastText(theme.palette.primary.dark)} `,
    },
  },
  '& .rbc-header': {
    height: theme.spacing(6),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.getContrastText(theme.palette.primary.main),
    ...theme.typography.subtitle2,
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.0rem',
    },
  },
  '& .rbc-month-header': {
    overflow: 'hidden',
    borderTopLeftRadius: `${theme.shape.borderRadius}px`,
    borderTopRightRadius: `${theme.shape.borderRadius}px`,
    '& > div': {
      border: 'none',
    },

    [theme.breakpoints.down('md')]: {
      position: 'sticky',
      top: 0,
      zIndex: 30,
    },
  },
  '& .rbc-date-cell': {
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.0rem',
    },
  },
  '& .rbc-event': {
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.0rem',
    },
  },
  '& .rbc-event-content': {
    color: theme.palette.text.primary,
  },
  '& .rbc-day-bg + .rbc-day-bg': {
    borderLeft: `1px solid ${theme.palette.divider}`,
  },
  '& .rbc-month-row + .rbc-month-row': {
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  '& .rbc-off-range-bg': {
    backgroundColor: theme.palette.background.default,
  },
});
interface CalendarViewProps {
  date: dayjs.Dayjs;
  records: TimeSheet[];
  setSelectedDate: React.Dispatch<React.SetStateAction<dayjs.Dayjs>>;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
interface EventView {
  allDay: boolean;
  start: dayjs.Dayjs;
  end: dayjs.Dayjs;
  title: string | number;
}
interface ISelectedDay {
  selectedDay?: Dayjs;
  children?: React.ReactNode;
}

type IEventWrapperProps = EventWrapperProps<EventView> & ISelectedDay;

// const EventWrapper = (eventWrapperProps:IEventWrapperProps) => {
//   // this doesnt actually get the selected day
//   const { children, event, selectedDay } = eventWrapperProps;
//   return (
//     // eslint-disable-next-line react/jsx-no-useless-fragment
//     <>
//       {dayjs(event.start).format('DD/MM/YYYY')
//         === dayjs(selectedDay).format('DD/MM/YYYY') ? (
//           <div className="rbc-selected">
//             {children}
//           </div>
//         ) : (
//           children
//         )}
//     </>
//   );
// };
const style = (theme: Theme) => ({
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: `1px solid ${theme.palette.divider}`,
  boxShadow: 24,
  p: 4,
  borderRadius: theme.shape.borderRadius,
});

const CalendarView = (props: CalendarViewProps) => {
  const {
    // eslint-disable-next-line no-unused-vars
    date,
    records,
    setSelectedDate,
    setIsModalOpen,
  } = props;

  const settingsContext = useContext(SettingsContext);
  const [selectedDay, setSelectedDay] = useState<dayjs.Dayjs | undefined>();
  const [dayRecords, setDayRecords] = useState<EventView[]>([]);
  const [open, setOpen] = React.useState(false);
  const [seeMore, setSeeMore] = useState<EventView[] | undefined>(undefined);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    const calendarEvents: EventView[] = [];
    records.forEach((dt) =>
      dt.dailyHours.map((d) =>
        calendarEvents.push({
          allDay: true,
          start: d.date,
          end: d.date,
          title: `${
            d.totalHours === 0 ? '-' : formatHoursAsHourMin(d.totalHours)
          }`,
        })
      )
    );
    setDayRecords(calendarEvents);
  }, [records]);

  const setDay = (e: Date) => {
    setIsModalOpen(true);
    setSelectedDate(dayjs(e));
    setSelectedDay(dayjs(e));
  };

  const dateProp = useCallback(
    (d) => ({
      ...(dayjs(d).toDate().toString() ===
        dayjs(selectedDay).toDate().toString() && {
        className: 'rbc-selected',
      }),
    }),
    [selectedDay]
  );

  const onShowMore = useCallback(
    (events) => {
      setSeeMore(events);
      setOpen(true);
    },
    [setOpen]
  );
  return (
    <>
      <Box sx={calendarSx} data-testid="monthlyCalendarContainer">
        <Calendar
          date={date.toDate()}
          // hides react big calendar onNavigate warning message. We use our own controlled date prop with the date selector
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onNavigate={() => {}}
          localizer={localizer}
          events={dayRecords}
          toolbar={false}
          onShowMore={onShowMore}
          getNow={() => (selectedDay ? selectedDay.toDate() : dayjs().toDate())}
          drilldownView={null}
          dayPropGetter={dateProp}
          onSelectSlot={(e: { start: Date }) => setDay(e.start)}
          longPressThreshold={0}
          onSelectEvent={(e) => setDay(e.start.toDate())}
          selectable
          views={['month']}
          components={{
            // eslint-disable-next-line react/no-unstable-nested-components
            eventWrapper(eventWrapperProps: IEventWrapperProps) {
              // this doesnt actually get the selected day
              const { children, event } = eventWrapperProps;
              return (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <>
                  {dayjs(event.start).format('DD/MM/YYYY') ===
                  dayjs(selectedDay).format('DD/MM/YYYY') ? (
                    <div className="eventWrapper">{children}</div>
                  ) : (
                    children
                  )}
                </>
              );
            },
          }}
          selected={selectedDay}
        />
      </Box>
      <ClickAwayListener
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
        onClickAway={() => setOpen(false)}
      >
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ br: 1 }}
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {seeMore
                ? dayjs(seeMore[0].start).format(
                    settingsContext.settings.dateFormat
                  )
                : 'Additional data'}
            </Typography>
            <ol>
              {seeMore &&
                seeMore.map((event) => (
                  <li>
                    <Typography
                      id="modal-modal-description"
                      sx={{ mt: 2, ml: 1 }}
                    >
                      {event.title}
                    </Typography>
                  </li>
                ))}
            </ol>
          </Box>
        </Modal>
      </ClickAwayListener>
    </>
  );
};

export default CalendarView;
