import * as React from 'react';
import BOMDistribution from './BOMDistribution';
import BOMPartsOrderRelease from './BOMPartsOrderRelease';
import IssuedProcessSchedules from './IssuedProcessSchedules';
import NonBOM from './NonBOM';
import PartDetails from './PartDetails';
import Purchased from './Purchased';
import ReservedPulledInventory from './ReservedPulledInventory';
import Summary from './Summary';

interface DistributionProps {
  selected: string;
  prevSelectedTab: string | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  detail: { [key: string]: any };
}

const DistributionPanel = (props: DistributionProps) => {
  const { selected, prevSelectedTab, detail } = props;
  const defaultTab = prevSelectedTab === '' ? selected : prevSelectedTab;

  switch (defaultTab) {
    case 'summary':
      return <Summary detail={detail} />;
    case 'partDetails':
      return <PartDetails detail={detail} />;
    case 'bomDistribution':
      return <BOMDistribution detail={detail} />;
    case 'nonBOM':
      return <NonBOM detail={detail} />;
    case 'purchased':
      return <Purchased detail={detail} />;
    case 'reservedPulledInventory':
      return <ReservedPulledInventory detail={detail} />;
    case 'issuedProcessSchedules':
      return <IssuedProcessSchedules detail={detail} />;
    case 'bomPartsOrderRelease':
      return <BOMPartsOrderRelease detail={detail} />;
    default:
      return <Summary detail={detail} />;
  }
};

export default DistributionPanel;
