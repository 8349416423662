/* eslint-disable react/jsx-no-useless-fragment */
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Popper,
  SxProps,
  Theme,
  useTheme,
} from '@mui/material';
import { YesNoConfirmDialogProps } from '@teto/react-component-library';

import React from 'react';
import { useTranslation } from 'react-i18next';

type ClockInModalNotificationProps = Omit<
  YesNoConfirmDialogProps,
  'title' | 'content'
>;

interface Props extends ClockInModalNotificationProps {
  anchorEl: null | HTMLElement;
}
const sizeSx: SxProps<Theme> = {
  height: (theme) => theme.spacing(4.5),
  textTransform: 'capitalize',
};
const primarySx: SxProps<Theme> = {
  backgroundColor: 'primary.main',
  color: 'common.white',
  '&:hover': {
    backgroundColor: 'primary.light',
  },
};
const secondarySx: SxProps<Theme> = {
  backgroundColor: 'secondary.main',
  color: 'common.white',
  '&:hover': {
    backgroundColor: 'secondary.light',
  },
};
const ClockInModalNotification = (props: Props) => {
  const { onNo, onYes, open, anchorEl } = props;
  const { t, ready } = useTranslation();
  const theme = useTheme();
  return (
    <>
      {ready && anchorEl && (
        <Popper
          data-testid="clockInModalNotification"
          open={open}
          anchorEl={anchorEl}
          placement="bottom-end"
          sx={{
            marginTop: `${theme.spacing(1)} !important`,
            zIndex: 1000,
            right: '10px !important',
            left: 'unset !important',
            top: `${theme.spacing(1)} !important`,
          }}
          onResize={undefined}
          onResizeCapture={undefined}
        >
          <Box
            sx={{
              borderRadius: theme.shape.borderRadius,
              border: 1,
              p: 1,
              bgcolor: 'background.paper',
            }}
          >
            <DialogTitle>{t('dialogs.notClockedIn.title')}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {t('dialogs.notClockedIn.content')}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                color="secondary"
                size="medium"
                onClick={(e) => onNo(e)}
                sx={[sizeSx, secondarySx]}
                variant="contained"
              >
                {t('generic.no')}
              </Button>
              <Button
                color="primary"
                size="medium"
                onClick={(e) => onYes(e)}
                sx={[sizeSx, primarySx]}
                variant="contained"
              >
                {t('generic.yes')}
              </Button>
            </DialogActions>
          </Box>
        </Popper>
      )}
    </>
  );
};

export default React.memo(ClockInModalNotification);
