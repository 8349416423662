const displayList = [
  'projectName',
  'hourTypeName',
  'specName',
  'hourRate',
  'hourTime',
  'startTime',
  'endTime',
  'date',
];

const sample = [
  {
    actualHours: 39.08,
    batchId: '56712c06-370a-4480-9629-8ce5ae610db3',
    batched: true,
    billable: true,
    comments: '',
    custom1: '',
    custom2: '',
    custom7: false,
    custom8: false,
    date: '2022-04-05T00:00:00',
    doNotExport: false,
    empNumber: '10002',
    employeeFirstName: 'Nial',
    employeeFullName: 'Nial Hope',
    employeeId: 297,
    employeeLastName: 'Hope',
    exported: false,
    hourClass: 'Regular',
    hourFactor: 1,
    hourRate: 100,
    hourTime: 39.08,
    hourTypeId: 88,
    hourTypeName: '88 - Test Hour Type',
    id: 335931,
    locked: 0,
    projectId: 81585,
    projectName: '81585 - Cargo-Vator/Mast Lift\r\nMobile Personnel',
    pscCustom1: '',
    pscCustom2: '',
    pscCustom7: false,
    pscCustom8: false,
    roundedHours: 39.08,
    specId: 19,
    specName: '81585-19',
    timePeriodEndDate: '2022-04-09T23:59:59.997',
    timePeriodId: 3460,
    timePeriodStartDate: '2022-04-03T00:00:00',
    timeSheetId: 1649,
    validated: false,
  },
  {
    actualHours: 23,
    batchId: '56712c06-370a-4480-9629-8ce5ae610db3',
    batched: true,
    billable: true,
    comments: '',
    custom1: '',
    custom2: '',
    custom7: false,
    custom8: false,
    date: '2022-04-06T00:00:00',
    doNotExport: false,
    empNumber: '10002',
    employeeFirstName: 'Nial',
    employeeFullName: 'Nial Hope',
    employeeId: 297,
    employeeLastName: 'Hope',
    exported: false,
    hourClass: 'Regular',
    hourFactor: 1,
    hourRate: 100,
    hourTime: 23,
    hourTypeId: 88,
    hourTypeName: '88 - Test Hour Type',
    id: 335932,
    locked: 0,
    projectId: 81585,
    projectName: '81585 - Cargo-Vator/Mast Lift\r\nMobile Personnel',
    pscCustom1: '',
    pscCustom2: '',
    pscCustom7: false,
    pscCustom8: false,
    roundedHours: 234,
    specId: 19,
    specName: '81585-19',
    timePeriodEndDate: '2022-04-09T23:59:59.997',
    timePeriodId: 3460,
    timePeriodStartDate: '2022-04-03T00:00:00',
    timeSheetId: 1649,
    validated: false,
  },
  {
    actualHours: 5.57,
    batchId: '56712c06-370a-4480-9629-8ce5ae610db3',
    batched: true,
    billable: true,
    comments: '',
    custom1: '',
    custom2: '',
    custom7: false,
    custom8: false,
    date: '2022-04-07T00:00:00',
    doNotExport: false,
    empNumber: '10002',
    employeeFirstName: 'Nial',
    employeeFullName: 'Nial Hope',
    employeeId: 297,
    employeeLastName: 'Hope',
    exported: false,
    hourClass: 'Regular',
    hourFactor: 1,
    hourRate: 100,
    hourTime: 5.57,
    hourTypeId: 88,
    hourTypeName: '88 - Test Hour Type',
    id: 335933,
    locked: 0,
    projectId: 81585,
    projectName: '81585 - Cargo-Vator/Mast Lift\r\nMobile Personnel',
    pscCustom1: '',
    pscCustom2: '',
    pscCustom7: false,
    pscCustom8: false,
    roundedHours: 5.57,
    specId: 19,
    specName: '81585-19',
    timePeriodEndDate: '2022-04-09T23:59:59.997',
    timePeriodId: 3460,
    timePeriodStartDate: '2022-04-03T00:00:00',
    timeSheetId: 1649,
    validated: false,
  },
  {
    actualHours: 23.31,
    batched: false,
    billable: true,
    comments: '',
    custom1: '',
    custom2: '',
    custom7: true,
    custom8: false,
    date: '2022-04-05T00:00:00',
    doNotExport: false,
    empNumber: '10002',
    employeeFirstName: 'Nial',
    employeeFullName: 'Nial Hope',
    employeeId: 297,
    employeeLastName: 'Hope',
    endTime: '2022-04-06T10:54:00',
    exported: false,
    hourClass: 'Regular',
    hourFactor: 1,
    hourRate: 100,
    hourTime: 23.0,
    hourTypeId: 3,
    hourTypeName: '3 - Electrical Design',
    id: 335950,
    locked: 0,
    projectId: 81585,
    projectName: '81585 - Cargo-Vator/Mast Lift\r\nMobile Personnel',
    pscCustom1: '',
    pscCustom2: '',
    pscCustom7: false,
    pscCustom8: false,
    roundedHours: 23.3,
    specId: 10,
    specName: '81585-10 - Controls-Cell w/secondary lifts',
    startTime: '2022-04-05T11:36:00',
    timePeriodEndDate: '2022-04-09T23:59:59.997',
    timePeriodId: 3460,
    timePeriodStartDate: '2022-04-03T00:00:00',
    timeSheetId: 1649,
    timecardPunchInID: 85,
    validated: false,
  },
];

const columns = [
  {
    id: 'id',
    name: 'id',
    type: 'number',
    order: 1,
    width: 100,
    title: 'Timecard Id',
    header: 'id',
    hidden: true,
    sortable: true,
    align: 'end',
    emptyDisplay: ' - ',
    filterType: 'number',
    filterOptions: ['equal'],
    fixed: 'none',
  },

  {
    id: 'date',
    name: 'date',
    type: 'date',
    order: 2,
    width: 130,
    title: 'Date',
    header: 'date',
    hidden: false,
    sortable: true,
    align: 'end',
    emptyDisplay: ' - ',
    filterType: 'string',
    fixed: 'none',
  },

  {
    id: 'employeeFullName',
    name: 'employeeFullName',
    type: 'string',
    order: 6,
    width: 200,
    title: 'Employee Name',
    header: 'employeeFullName',
    hidden: false,
    sortable: true,
    align: 'start',
    emptyDisplay: ' - ',
    filterType: 'string',
    filterOptions: ['startsWith', 'endsWidth', 'contains', 'equal', 'notEqual'],
    fixed: 'none',
  },
  {
    id: 'empNumber',
    name: 'empNumber',
    type: 'string',
    order: 7,
    width: 120,
    title: 'Employee Number',
    header: 'empNumber',
    hidden: true,
    sortable: true,
    align: 'start',
    emptyDisplay: ' - ',
    filterType: 'string',
    filterOptions: ['contains'],
    fixed: 'none',
  },

  {
    id: 'hourTime',
    name: 'hourTime',
    type: 'hours',
    order: 10,
    width: 100,
    title: 'Hours',
    header: 'hourTime',
    hidden: false,
    sortable: true,
    align: 'start',
    emptyDisplay: ' - ',
    filterType: 'number',
    fixed: 'none',
    groupSummaryReducer: {
      initialValue: 0,
    },
  },
  {
    id: 'actualHours',
    name: 'actualHours',
    type: 'hours',
    order: 11,
    width: 100,
    title: 'Actual Hours',
    header: 'actualHours',
    hidden: true,
    sortable: true,
    align: 'start',
    emptyDisplay: ' - ',
    filterType: 'number',
    fixed: 'none',
    groupSummaryReducer: {
      initialValue: 0,
    },
  },
  {
    id: 'roundedHours',
    name: 'roundedHours',
    type: 'hours',
    order: 12,
    width: 100,
    title: 'Rounded Hours',
    header: 'roundedHours',
    hidden: true,
    sortable: true,
    align: 'start',
    emptyDisplay: ' - ',
    filterType: 'number',
    fixed: 'none',
    groupSummaryReducer: {
      initialValue: 0,
    },
  },
  {
    id: 'hourRate',
    name: 'hourRate',
    type: 'currency',
    order: 13,
    width: 100,
    title: 'Hour Rate',
    header: 'hourRate',
    hidden: true,
    sortable: true,
    align: 'start',
    emptyDisplay: ' - ',
    filterType: 'string',
    fixed: 'none',
  },

  {
    id: 'startTime',
    name: 'startTime',
    type: 'time',
    order: 18,
    width: 100,
    title: 'Start Time',
    header: 'startTime',
    hidden: false,
    sortable: true,
    align: 'end',
    emptyDisplay: ' - ',
    filterType: 'string',
    fixed: 'none',
    disableGrouping: true,
  },
  {
    id: 'endTime',
    name: 'endTime',
    type: 'time',
    order: 19,
    width: 100,
    title: 'End Time',
    header: 'endTime',
    hidden: false,
    sortable: true,
    align: 'end',
    emptyDisplay: ' - ',
    filterType: 'string',
    fixed: 'none',
    disableGrouping: true,
  },
  {
    id: 'projectId',
    name: 'projectId',
    type: 'number',
    order: 30,
    width: 100,
    title: 'Project Id',
    header: 'projectId',
    hidden: true,
    sortable: true,
    align: 'end',
    emptyDisplay: ' - ',
    filterType: 'number',
    filterOptions: ['equal'],
    fixed: 'none',
  },
  {
    id: 'projectName',
    name: 'projectName',
    type: 'foreignKey',
    order: 31,
    width: 200,
    title: 'Project',
    header: 'projectName',
    hidden: false,
    sortable: true,
    align: 'start',
    emptyDisplay: ' - ',
    filterType: 'string',
    filterOptions: ['startsWith', 'endsWidth', 'contains', 'equal', 'notEqual'],
    fixed: 'none',
  },
  {
    id: 'specId',
    name: 'specId',
    type: 'number',
    order: 40,
    width: 100,
    title: 'Job Id',
    header: 'specId',
    hidden: true,
    sortable: true,
    align: 'end',
    emptyDisplay: ' - ',
    filterType: 'number',
    filterOptions: ['equal'],
    fixed: 'none',
  },

  {
    id: 'nonConformanceId',
    name: 'nonConformanceId',
    type: 'number',
    order: 70,
    width: 100,
    title: 'Non Conformance Id',
    header: 'nonConformanceId',
    hidden: true,
    sortable: true,
    align: 'end',
    emptyDisplay: ' - ',
    filterType: 'number',
    filterOptions: ['equal'],
    fixed: 'none',
  },
  {
    id: 'nonConformanceName',
    name: 'nonConformanceName',
    type: 'foreignKey',
    order: 71,
    width: 200,
    title: 'Non Conformance',
    header: 'nonConformanceName',
    hidden: false,
    sortable: true,
    align: 'start',
    emptyDisplay: ' - ',
    filterType: 'string',
    filterOptions: ['startsWith', 'endsWidth', 'contains', 'equal', 'notEqual'],
    fixed: 'none',
  },
  {
    id: 'processScheduleDetailId',
    name: 'processScheduleDetailId',
    type: 'number',
    order: 80,
    width: 100,
    title: 'Process Schedule Id',
    header: 'processScheduleDetailId',
    hidden: true,
    sortable: true,
    align: 'end',
    emptyDisplay: ' - ',
    filterType: 'number',
    filterOptions: ['equal'],
    fixed: 'none',
  },

  {
    id: 'timecardPunchInID',
    name: 'timecardPunchInID',
    type: 'number',
    order: 161,
    width: 100,
    title: 'Punch In Id',
    header: 'timecardPunchInID',
    hidden: true,
    sortable: true,
    align: 'end',
    emptyDisplay: ' - ',
    filterType: 'number',
    filterOptions: ['equal'],
    fixed: 'none',
  },
  {
    id: 'comments',
    name: 'comments',
    type: 'string',
    order: 190,
    width: 200,
    title: 'Comments',
    header: 'comments',
    hidden: false,
    sortable: true,
    align: 'start',
    emptyDisplay: ' - ',
    filterType: 'string',
    filterOptions: ['startsWith', 'endsWidth', 'contains', 'equal', 'notEqual'],
    fixed: 'none',
  },
  {
    id: 'custom7',
    name: 'custom7',
    type: 'boolean',
    order: 206,
    width: 60,
    title: 'SRED',
    header: 'custom7',
    hidden: false,
    sortable: true,
    align: 'start',
    emptyDisplay: ' - ',
    filterType: 'boolean',
    filterOptions: ['equal'],
    fixed: 'none',
  },
];
export default sample;
export { columns, displayList };
