import React, { useContext } from 'react';
import {
  ETODateTimeField,
  SettingsContext,
} from '@teto/react-component-library';
import { SxProps } from '@mui/material/styles';
import PickerEditorWrapper from '../EditorWrappers/PickerEditorWrapper';
import InlineEditorProps from '../InlineEditingProps';

const rootSx: SxProps = {
  '& .MuiOutlinedInput-adornedEnd': {
    padding: '0px',
  },
  '& .MuiInputAdornment-positionEnd ': {
    margin: '0px',
  },
  '& .InovuaReactDataGrid__cell__editor InovuaReactDataGrid__cell__editor--date__time':
    {},
};

const ETODateTimeEditor: React.FC<InlineEditorProps> = (props) => {
  const settings = useContext(SettingsContext);
  const { cellProps } = props;
  return (
    <PickerEditorWrapper
      {...props}
      child={
        <ETODateTimeField
          name={cellProps?.name as string}
          inputFormat={settings.settings.dateTimeFormat}
        />
      }
      customCellSx={[rootSx]}
    />
  );
};

export default ETODateTimeEditor;
