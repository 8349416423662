const getMyClockInsByDayQuery = `query myClockInsByDay($startDate: DateTime, $endDate: DateTime, ) {
  myClockInsByDay(
    skip: 0
    order: { date: DESC }
    where: {
      and: [
        { date: { gte: $startDate } },
        { date: { lte: $endDate } }
      ]
    }
  ) {
    items {
      clockIns {
        id
        startTime
        endTime
        date
        totalDuration
      }
      date
      startTime
      endTime
      employee {
        name
        id
      }
      totalDuration
      workingDuration
    }
  }
}
`;

export default getMyClockInsByDayQuery;
