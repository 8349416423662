import * as Yup from 'yup';

const REQUIRED_MESSAGE = 'Required';

const YupSchema = Yup.object().shape({
  startTime: Yup.date().required(REQUIRED_MESSAGE),
  endTime: Yup.date().nullable().default(null),
});

export default YupSchema;
