import * as React from 'react';
import { useParams } from 'react-router-dom';
import MonthlyTabWrapper from './MonthlyTab/MonthlyTabWrapper';
import WeeklyGridTabWrapper from './WeeklyGridTab/WeeklyGridTabWrapper';

const TimeSheetsTab = () => {
  const { month, weekStart, weekEnd } = useParams();
  const [viewBy, setViewBy] = React.useState<string>(() => {
    if (month && (!weekStart || !weekEnd)) return 'month';
    return 'week';
  });

  switch (viewBy) {
    case 'month':
      return <MonthlyTabWrapper setViewBy={setViewBy} viewBy={viewBy} />;
    default:
      return <WeeklyGridTabWrapper setViewBy={setViewBy} viewBy={viewBy} />;
  }
};

export default React.memo(TimeSheetsTab);
