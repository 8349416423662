import { Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import dayjs from 'dayjs';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import {
  AuthContext,
  DateRangeSelector,
  getMonthDateRange,
  SettingsContext,
} from '@teto/react-component-library';
import { useTranslation } from 'react-i18next';
import { QueryFunctionContext, QueryKey } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  betweenDate,
  getTimeSheets,
  Permission,
  TimeSheet,
} from 'teto-client-api';
import TIMESHEET_QUERY_ID from '../../../../api/reactQuery/TimesheetsQueryId';
import ExportButton from '../../../../components/Buttons/ExportButton/ExportButton';
import { createColumns } from '../../../../components/TetoGrid/ModelMetaDataProcessor/ModelMetaDataProcessor';
import RDGSelectedType from '../../../../components/TetoGrid/RDGSelectedType';
import TetoGrid from '../../../../components/TetoGrid/TetoGrid';
import TetoGridRefType from '../../../../components/TetoGrid/TetoGridRefType';
import TimesheetInspectorWrapper from '../../../../components/TimeSheets/TimesheetInspector/TimesheetInspector';
import TimesheetStatusButton from '../../../../components/TimeSheets/TimesheetStatus/TimesheetStatusButton';
import { DateRange } from '../../../../helpers/dateTypes';
import ActionButton from '../../ApprovalsPage/components/ActionButton/ActionButton';
import TimesheetSelection from '../../TimesheetsDetailsPage/TimesheetsDetailPageCommonTypes';

const fabContainerSx = (theme: Theme) => ({
  bottom: theme.spacing(6.5),
  position: 'fixed',
  right: theme.spacing(2),
  zIndex: 10,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DateRanger = (data: any, format: string) => {
  const { startDate, endDate, __group } = data;
  if (__group) {
    return '-';
  }
  return (
    <Typography variant="body2" color="textPrimary">
      {`${dayjs(startDate).format(format)} - ${dayjs(endDate).format(format)}`}
    </Typography>
  );
};

const TimesheetsMonthlyTab = () => {
  const { t, ready } = useTranslation();
  const queryIdentifier = TIMESHEET_QUERY_ID;
  const TABLE_ID = t('Entities.TimeSheet.monthlyTableIdentifier');
  const settingsContext = useContext(SettingsContext);
  const authContext = useContext(AuthContext);
  const canAccessTimesheets =
    authContext.hasAnyLicense() &&
    (authContext.hasAnyPermission([
      Permission.View_Admin_Timecards,
      Permission.Modify_Admin_Timecards,
    ]) ||
      (settingsContext.settings.approverCanManageTime &&
        authContext.user?.isApprover));
  const theme = useTheme();
  const mobileSize = useMediaQuery(theme.breakpoints.down('md'));
  const [dataSource, setDataSource] = useState([]);
  const { startDate, endDate } = useParams();
  const browserLocation = useLocation();
  const navigate = useNavigate();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [selectedRows, setSelectedRows] = useState<RDGSelectedType>();
  const [refreshToken] = useState<Date | undefined>();
  const [selectedTimesheet, setSelectedTimesheet] =
    useState<TimesheetSelection | undefined>();

  const [queryDateRange, setQueryDateRange] = useState<DateRange>({
    start: startDate ? dayjs(startDate) : getMonthDateRange(dayjs()).startDate,
    end: endDate ? dayjs(endDate) : getMonthDateRange(dayjs()).endDate,
  });
  const gridRef = React.useRef<TetoGridRefType | undefined>();

  useEffect(() => {
    if (!canAccessTimesheets) navigate('/access-denied', { replace: true });
    if (!startDate || !endDate) {
      navigate(
        `/timeTracking/timesheets/month/${queryDateRange.start.format(
          'YYYY-MM-DD'
        )}/${queryDateRange.end.format('YYYY-MM-DD')}`,
        { replace: true }
      );
    }
  }, [
    browserLocation.key,
    canAccessTimesheets,
    endDate,
    navigate,
    queryDateRange.end,
    queryDateRange.start,
    settingsContext.settings.startDayOfWeek,
    startDate,
  ]);

  const _handleDateChange = (e: DateRange) => {
    setQueryDateRange({ start: e.start, end: e.end });
    navigate(
      `/timeTracking/timesheets/month/${e.start.format(
        'YYYY-MM-DD'
      )}/${e.end.format('YYYY-MM-DD')}`
    );
  };

  // eslint-disable-next-line consistent-return
  const getScreenSize = useCallback(() => {
    const screenSize = window.innerWidth;

    if (screenSize <= theme.breakpoints.values.xs) {
      return 150;
    }
    if (screenSize <= theme.breakpoints.values.sm) {
      return 150;
    }
    if (screenSize <= theme.breakpoints.values.md) {
      return 200;
    }
    if (screenSize < theme.breakpoints.values.lg) {
      return 300;
    }
    if (screenSize > theme.breakpoints.values.xl) {
      return 400;
    }
  }, [
    theme.breakpoints.values.lg,
    theme.breakpoints.values.md,
    theme.breakpoints.values.sm,
    theme.breakpoints.values.xl,
    theme.breakpoints.values.xs,
  ]);

  useEffect(() => {
    getScreenSize();
  }, [getScreenSize]);

  // eslint-disable-next-line consistent-return
  const getCommentSize = useCallback(() => {
    const screenSize = window.innerWidth;

    if (screenSize <= theme.breakpoints.values.xs) {
      return 100;
    }
    if (screenSize <= theme.breakpoints.values.sm) {
      return 100;
    }
    if (screenSize <= theme.breakpoints.values.md) {
      return 200;
    }
    if (screenSize < theme.breakpoints.values.lg) {
      return 600;
    }
    if (screenSize > theme.breakpoints.values.xl) {
      return 1200;
    }
  }, [
    theme.breakpoints.values.lg,
    theme.breakpoints.values.md,
    theme.breakpoints.values.sm,
    theme.breakpoints.values.xl,
    theme.breakpoints.values.xs,
  ]);

  useEffect(() => {
    getCommentSize();
  }, [getCommentSize]);

  const cols = useMemo(() => {
    if (ready && startDate && endDate) {
      return createColumns<TimeSheet>()
        .addColumn({
          name: 'employeeFullName',
          title: t('Entities.Employee.fullName'),
          type: 'string',
          align: 'start',
          sortable: false,
          filterType: 'string',
          filterOptions: 'simple',
          fixed: 'none',
          disableGrouping: false,
          disableColumnMenuTool: false,
          disableHideable: true,
          width: getScreenSize(),
        })
        .addColumn({
          name: 'dateRange',
          title: 'Date Range',
          type: 'custom',
          align: 'center',
          sortable: false,
          filterType: '',
          filterOptions: undefined,
          fixed: 'none',
          disableGrouping: true,
          disableColumnFilterContextMenu: true,
          disableColumnMenuTool: true,
          width: 200,
          renderFunction: (data) =>
            DateRanger(data, settingsContext.settings.dateFormat),
        })

        .addColumn({
          name: 'totalHours',
          title: t('Entities.TimeSheet.totalHours'),
          type: 'hours',
          align: 'center',
          sortable: false,
          filterType: '',
          filterOptions: undefined,
          fixed: 'none',
          disableGrouping: true,
          disableColumnFilterContextMenu: true,
          disableColumnMenuTool: true,
          width: 150,
        })
        .addColumn({
          name: 'status',
          title: t('Entities.TimeSheet.status'),
          type: 'custom',
          align: 'center',
          sortable: true,
          filterType: 'string',
          filterOptions: 'simple',
          fixed: 'none',
          editable: false,
          disableGrouping: false,
          disableColumnMenuTool: false,
          width: 150,
          groupSummaryReducer: {
            initialValue: 0,
            reducer: () => '',
          },
          headerProps: {
            className: 'gridColHeader__sort',
          },
          renderFunction: (data) => TimesheetStatusButton(data),
        })
        .addColumn({
          name: 'statusComments',
          title: t('Entities.Timecard.comments'),
          type: 'string',
          align: 'center',
          sortable: false,
          filterType: '',
          filterOptions: undefined,
          fixed: 'none',
          disableGrouping: true,
          disableColumnFilterContextMenu: true,
          disableColumnMenuTool: true,
          minWidth: mobileSize ? 200 : 400,
          emptyDisplay: '-',
          flex: 1,
          renderFunction: (data) => data.comments || '-',
        })

        .addColumn({
          name: 'action',
          title: t('generic.action'),
          type: 'button',
          align: 'center',
          sortable: false,
          editable: false,
          filterType: 'none',
          fixed: 'right',
          disableGrouping: true,
          disableHideable: true,
          disableColumnMenuTool: true,
          width: mobileSize ? 60 : 80,
          headerProps: {
            style: {
              textAlign: 'center',
            },
          },
          renderFunction: (data: TimeSheet) => (
            <ActionButton
              data={data}
              actionItems={[
                /* ...(data.status === 'Submitted'
                  ? [
                    {
                      componentName: t('Entities.TimeSheet.approve'),
                      icon: <CheckRoundedIcon sx={successIconSx} />,
                      title: t('Entities.TimeSheet.approve'),
                      handleClick: () => setSelectedTimesheet({
                        timesheet: data,
                        action: 'approve',
                      }),
                    },
                  ]
                  : []),
                ...(data.status === 'Submitted'
                  ? [
                    {
                      componentName: t('Entities.TimeSheet.reject'),
                      icon: <CloseRoundedIcon color="error" />,
                      title: t('Entities.TimeSheet.reject'),
                      handleClick: () => setSelectedTimesheet({
                        timesheet: data,
                        action: 'reject',
                      }),
                    },
                  ]
                  : []),
                ...(data.status === 'Approved'
                  ? [
                    {
                      componentName: t('Entities.TimeSheet.unapprove'),
                      icon: <UndoRoundedIcon color="action" />,
                      title: t('Entities.TimeSheet.unapprove'),
                      handleClick: () => setSelectedTimesheet({
                        timesheet: data,
                        action: 'unapprove',
                      }),
                    },
                  ]
                  : []),
                ...(data.status === 'Rejected'
                  ? [
                    {
                      componentName: t('Entities.TimeSheet.unreject'),
                      icon: <UndoRoundedIcon color="action" />,
                      title: t('Entities.TimeSheet.unreject'),
                      handleClick: () => setSelectedTimesheet({
                        timesheet: data,
                        action: 'unreject',
                      }),
                    },
                  ]
                  : []), */
                {
                  componentName: t('Entities.TimeSheet.viewDetails'),
                  icon: <ArrowForwardIosRoundedIcon color="primary" />,
                  title: t('Entities.TimeSheet.viewDetails'),
                  handleClick: () =>
                    navigate(`/timeTracking/timesheets/${data.id}`, {
                      state: browserLocation.pathname,
                    }),
                },
              ]}
            />
          ),
        })
        .finalize(t);
    }
    return [];
  }, [
    ready,
    startDate,
    endDate,
    t,
    getScreenSize,
    mobileSize,
    settingsContext.settings.dateFormat,
    navigate,
    browserLocation.pathname,
  ]);

  function _doQuery(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    context: QueryFunctionContext<QueryKey, any>,
    pageSize: number,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    queryData: { filters: any; orderBy: any } & { params: DateRange }
  ) {
    const defaultQuery = {
      pageIndex: context.pageParam,
      pageSize,
      disablePaging: false,
      orderBy: queryData.orderBy,
      filter: {
        startDate: betweenDate(
          dayjs(queryDateRange.start).subtract(6, 'day'),
          dayjs(queryDateRange.end)
        ),
        // endDate: dayjs(endDate),
        ...queryData.filters,
      },
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    return getTimeSheets(defaultQuery).then((d) => ({
      ...d,
      records: d.records,
    }));
  }

  const _handleClose = () => {
    setSelectedTimesheet(undefined);
  };

  const ExportBtn = ExportButton({ gridRef });

  return (
    <>
      {ready && cols && startDate && endDate && (
        <TetoGrid
          dataSource={dataSource}
          setDataSource={setDataSource}
          serverSideFiltering
          withBottomNav
          defaultGrouping={['employeeFullName']}
          disableGroupByToolbar
          disableConfigureButton
          ref={gridRef}
          columns={cols}
          pageSize={50000}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          refreshToken={refreshToken}
          /* checkboxColumn={{
            locked: 'start',
            defaultLocked: 'start',
            minWidth: 70,
          }} */
          externalQueryProps={queryDateRange}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          doQuery={(a, b, c) => _doQuery(a, b, c as any)}
          header={{
            leftChildren: (
              <DateRangeSelector
                dates={queryDateRange}
                onDateChange={(e) => _handleDateChange(e)}
              />
            ),
            FABPosition: fabContainerSx,
            rightChildren: [ExportBtn],
          }}
          tableIdentifier={TABLE_ID}
          queryIdentifier={queryIdentifier}
        />
      )}
      {selectedTimesheet && (
        <TimesheetInspectorWrapper
          timesheet={selectedTimesheet.timesheet}
          open={Boolean(selectedTimesheet)}
          onClose={_handleClose}
          tableIdentifier={TABLE_ID}
          action={selectedTimesheet.action}
        />
      )}
    </>
  );
};

export default TimesheetsMonthlyTab;
