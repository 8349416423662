const getPageArticleNumbers = (page: string) => {
  switch (page) {
    case page.match(/^\/timeTracking\/myTimeTracker/)?.input:
      return '5552067995924';
    case page.match(/^\/timeTracking\/timesheets/)?.input:
    case page.match(/^\/timeTracking\/approvals/)?.input:
    case page.match(/^\/timeTracking\/timecards/)?.input:
    case page.match(/^\/timeTracking\/punch-ins/)?.input:
    case page.match(/^\/timeTracking\/timeSheetApprovers/)?.input:
      return '6165328569876';
    case page.match(/\/settings/)?.input:
      return '10231621655828';
    default:
      return '5552067995924';
  }
};

export default getPageArticleNumbers;
