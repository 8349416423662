import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { MutationKey, useMutation, useQueryClient } from 'react-query';
import { unrejectTimesheet } from 'teto-client-api';
import { MessageContext } from '@teto/react-component-library';
import TIMESHEET_QUERY_ID from '../../../api/reactQuery/TimesheetsQueryId';

// https://react-query.tanstack.com/reference/useMutation

const useUnrejectTimesheet = () => {
  const queryClient = useQueryClient();
  const messageContext = useContext(MessageContext);
  const { t } = useTranslation();

  const _handleUnreject = async (props: { id: number; message: string }) => {
    const { id, message } = props;

    await unrejectTimesheet(id, message);
  };

  const mutation = useMutation<unknown, Error, { id: number; message: string }>(
    _handleUnreject as unknown as MutationKey,
    {
      onSuccess: () => {
        const queryIdentifier = TIMESHEET_QUERY_ID;
        // queryClient.invalidateQueries();
        queryClient.invalidateQueries(queryIdentifier, {
          refetchActive: true,
          refetchInactive: true,
        });
        messageContext.setSuccess(t('generic.message.timesheetUnreject'));
      },
      onError: (e) => {
        messageContext.setError(e.message);
      },
    }
  );

  return mutation;
};

export default useUnrejectTimesheet;
