import Box from '@mui/material/Box';
import { Theme, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React from 'react';
import InlineHelpLink from '../../../components/HelpLinks/InlineHelpLink/InlineHelpLink';
import useCommonStyles from './commonStyles';

interface TabItemProps {
  settingsName: string;
  secondary: string | React.ReactElement | undefined;
  inlineHelp?: {
    helpId?: string | number;
    subSectionId?: string | number;
    title?: string;
  };
}

const useStyles = (theme: Theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      justifyContent: 'space-between',
      flexDirection: 'row',
    },
  },

  TextItem: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
    },
  },
  ElementItem: {
    minHeight: theme.spacing(7),
  },
});

const TabItem = (props: TabItemProps) => {
  const { inlineHelp, settingsName, secondary } = props;
  const theme = useTheme();
  const styles = useStyles(theme);
  const commonClasses = useCommonStyles(theme);
  const secondarySX =
    typeof secondary === 'string' ? styles.TextItem : styles.ElementItem;

  return (
    <Box sx={[styles.root, commonClasses.textThemeColor, secondarySX]}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Typography variant="subtitle1">{settingsName}</Typography>
        {inlineHelp && (
          <InlineHelpLink
            helpId={inlineHelp.helpId}
            subSectionId={inlineHelp.subSectionId}
            title={inlineHelp.title}
          />
        )}
      </Box>
      <Box>
        {typeof secondary === 'string' ? (
          <Typography variant="subtitle1">{secondary}</Typography>
        ) : (
          secondary
        )}
      </Box>
    </Box>
  );
};

export default TabItem;
