/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import SettingsBackupRestoreRounded from '@mui/icons-material/SettingsBackupRestoreRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import { Box, Theme, useMediaQuery, useTheme } from '@mui/material';
import {
  AuthContext,
  getMonthDateRange,
  MessageContext,
  MonthlyDateSelector,
} from '@teto/react-component-library';
import dayjs, { Dayjs } from 'dayjs';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  betweenDate,
  getMyTimeSheets,
  Permission,
  TimeCard,
  TimeSheet,
} from 'teto-client-api';
import ActionBar from '../../../../components/ActionBar/ActionBar';
import ConfigureInspector from '../../../../components/TetoGrid/ConfigureInspector/ConfigureInspector';
import GridPersistenceContext from '../../../../components/TetoGrid/GridPersistence/GridPersistenceContext';
import GridSettings from '../../../../components/TetoGrid/GridSettings';
import filterHiddenCols from '../../../../components/TetoGrid/helpers/filterHiddenCols';
import TableColumnDefinition from '../../../../components/TetoGrid/TableColumnDefinition';
import AddHoursInspector from '../../../../components/TimeSheets/AddHoursInspector/AddHoursInspector';
import EditInspector from '../../../../components/TimeSheets/EditInspector/EditInspector';
import { formatMonthlyURLDate } from '../../../../helpers/dateHelpers';
import TimesheetViewBySelector from '../../components/TimesheetViewBySelector/TimesheetViewBySelector';
import CalendarView from './CalendarView/CalendarView';
import DailySummaryView from './DailySummaryView/DailySummaryView';
import WeeklySummaryView from './WeeklySummaryView/WeeklySummaryView';

const monthlyContainerSx = {
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
};

const gridContainerSx = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  overflowY: 'auto',
  rowGap: theme.spacing(2),
  paddingBottom: theme.spacing(8),

  [theme.breakpoints.up('lg')]: {
    flexDirection: 'row',
    columnGap: theme.spacing(2),
    overflowY: 'hidden',
    marginBottom: 0,
  },
});

const calendarSx = (theme: Theme) => ({
  width: '100%',
  [theme.breakpoints.up('lg')]: {
    height: '100%',
    width: '70%',
  },
});

const summarySx = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  width: '100%',
  rowGap: theme.spacing(2),
  '& .MuiList-padding': {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
  },

  [theme.breakpoints.up('lg')]: {
    height: '100%',
    minWidth: theme.spacing(42.5),
    width: '30%',
  },
});

interface MonthlyTabProps {
  cols: TableColumnDefinition[];
  setViewBy?: React.Dispatch<React.SetStateAction<string>>;
  viewBy?: string;
}

const URL = '/timeTracking/myTimeTracker/timesheet/monthly/';

const urlMonthToDate = (date: string) => {
  const dateMonth = date.split('-').reverse();
  dateMonth.push('01');
  return dayjs(dateMonth.join('-'));
};

const monthParmRegex = /(0[1-9]|1[0-2])-(19|2[0-9])[0-9]{2}/;

const MonthlyTab = (props: MonthlyTabProps) => {
  const { cols, setViewBy, viewBy } = props;
  const { t } = useTranslation();
  const authContext = React.useContext(AuthContext);
  const messageContext = React.useContext(MessageContext);
  const gridContext = React.useContext(GridPersistenceContext);
  const theme = useTheme();
  const isResponsive = useMediaQuery(theme.breakpoints.down('lg'));

  const [configureInspector, setConfigureInspector] =
    React.useState<boolean>(false);
  const [colVisibility, setColVisibility] = React.useState<string[]>();
  const [records, setRecords] = React.useState<TimeSheet[]>([]);
  const [newEntryModal, setNewEntryModal] = React.useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const [editingTimecard, setEditingTimecard] =
    React.useState<TimeCard | undefined>();
  const [refreshToken, setRefreshToken] = React.useState<Dayjs | undefined>();

  const navigate = useNavigate();
  const location = useLocation();
  const { month } = useParams();

  const viewBySelector = !isResponsive && (
    <TimesheetViewBySelector
      setViewBy={setViewBy}
      viewBy={viewBy}
      key="viewBySelector"
    />
  );

  const viewBySelectorMobile = isResponsive && (
    <TimesheetViewBySelector
      setViewBy={setViewBy}
      viewBy={viewBy}
      key="viewBySelectorMobile"
    />
  );

  React.useEffect(() => {
    if (!month) {
      navigate(`${URL}${formatMonthlyURLDate(dayjs())}`, { replace: true });
    } else if (!monthParmRegex.test(month as string)) {
      navigate('error');
    }
  }, [location.key, month, navigate]);

  const pageDate = month ? urlMonthToDate(month) : dayjs();
  const queryDateRange = getMonthDateRange(pageDate);

  const [selectedCalendarDate, setSelectedCalendarDate] = React.useState<Dayjs>(
    dayjs()
  );

  const {
    resetSettings,
    columnOrder,
    setColumnOrder,
    hidden,
    setHidden,
    saveSettings,
  } = gridContext;

  const monthlyQuery = useQuery(
    ['monthlyTimeTrackerQuery', refreshToken, queryDateRange],
    () =>
      getMyTimeSheets({
        disablePaging: true as const,
        filter: {
          // employeeId: authContext.user?.id,
          startDate: betweenDate(
            dayjs(queryDateRange.startDate).subtract(6, 'day'),
            dayjs(queryDateRange.endDate).add(6, 'day')
          ),
        },
      }),
    {
      onSuccess: (d) => setRecords(d.records),
    }
  );

  const _onRecordsUpdated = () => {
    messageContext.setSuccess(t('generic.message.recordAdded'));
    setNewEntryModal(false);
    setRefreshToken(dayjs());
    setEditingTimecard(undefined);
  };

  const _onRecordsDeleted = () => {
    messageContext.setSuccess(t('generic.message.recordDeleted'));
    setEditingTimecard(undefined);
    setRefreshToken(dayjs());
  };

  const _onNewEntryClosed = () => {
    setNewEntryModal(false);
  };

  const _onNewEntryClicked = () => {
    setNewEntryModal(true);
  };

  const calculatedColumns = React.useMemo<TableColumnDefinition[]>(
    () =>
      cols
        .sort((a, b) => {
          const aIndex = columnOrder.indexOf(a.name);
          const bIndex = columnOrder.indexOf(b.name);
          if (aIndex === -1) {
            return 1;
          }
          if (bIndex === -1) {
            return -1;
          }
          if (aIndex < bIndex) return 1;
          if (aIndex > bIndex) return -1;
          return 0;
        })
        .reverse()
        .map((item) => ({
          ...item,
          hidden: item.disableHideable ? false : hidden.indexOf(item.name) >= 0,
        })),
    [cols, hidden, columnOrder]
  );

  const handleSave = (e: GridSettings) => {
    setRefreshToken(dayjs());
    setConfigureInspector(false);
    setColumnOrder(e.columnOrder);
    setHidden(e.hidden);
    saveSettings();
  };

  React.useEffect(() => {
    const filteredNames = filterHiddenCols(cols, hidden);
    setColVisibility(filteredNames);
  }, [cols, hidden]);

  const rightMenuItems = [
    ...(authContext.hasPermission(Permission.Add_MyTimeTracker_Timecards)
      ? [
          {
            title: t('forms.addHours'),
            icon: <AddCircleOutlineRoundedIcon />,
            onclick: () => _onNewEntryClicked(),
          },
        ]
      : []),
    {
      title: t('generic.resetGridSettings'),
      icon: <SettingsBackupRestoreRounded />,
      confirm: {
        type: 'okCancel' as const,
        title: t('dialogs.resetGrid.title'),
        content: t('dialogs.resetGrid.content'),
      },
      onclick: () => {
        resetSettings();
        messageContext.setSuccess(t('generic.message.settingsUpdate'));
      },
    },
    {
      title: t('generic.configure'),
      icon: <SettingsRoundedIcon />,
      onclick: () => setConfigureInspector(true),
    },
  ];

  const monthDateSelector = (
    <MonthlyDateSelector
      data-testid="monthlyDateSelector"
      value={pageDate}
      onChange={(d) => navigate(`${URL}${formatMonthlyURLDate(d as Dayjs)}`)}
      key="monthlyDateSelector"
    />
  );

  const leftMenuItems = [viewBySelector, monthDateSelector];

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {viewBySelectorMobile}
      {month && (
        <Box sx={monthlyContainerSx}>
          <ActionBar
            withBottomNav
            rightChildren={rightMenuItems}
            leftChildren={leftMenuItems}
          />

          <Box sx={gridContainerSx}>
            <Box sx={calendarSx}>
              <CalendarView
                setIsModalOpen={setIsModalOpen}
                date={pageDate}
                records={monthlyQuery.data?.records ?? []}
                setSelectedDate={setSelectedCalendarDate}
              />
            </Box>
            <Box sx={summarySx}>
              <WeeklySummaryView timesheet={records} setViewBy={setViewBy} />
              <DailySummaryView
                refreshToken={refreshToken}
                columns={cols}
                colVisibility={colVisibility}
                date={selectedCalendarDate}
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                setTimecardEditing={setEditingTimecard}
              />
            </Box>
          </Box>

          {newEntryModal && (
            <AddHoursInspector
              canAdd={
                authContext.hasPermission(
                  Permission.Add_MyTimeTracker_Timecards
                ) && authContext.hasProfessionalLicense()
              }
              allowEmployeeSelection={false}
              open={newEntryModal}
              onClose={_onNewEntryClosed}
              onUpdate={() => _onRecordsUpdated()}
            />
          )}
          {editingTimecard && authContext.hasProfessionalLicense() && (
            <EditInspector
              editMyTimecards
              canEdit={authContext.hasPermission(
                Permission.Modify_MyTimeTracker_Timecards
              )}
              canDelete={authContext.hasPermission(
                Permission.Delete_MyTimeTracker_Timecards
              )}
              allowEmployeeSelection={false}
              timecard={editingTimecard}
              onUpdate={() => {
                setRefreshToken(dayjs());
                setEditingTimecard(undefined);
                messageContext.setSuccess(t('generic.message.recordUpdated'));
              }}
              onDelete={() => _onRecordsDeleted()}
              onClose={() => setEditingTimecard(undefined)}
            />
          )}
          {configureInspector && (
            <ConfigureInspector
              open={configureInspector}
              columns={calculatedColumns}
              initialColumnOrder={columnOrder}
              handleClose={() => setConfigureInspector(() => false)}
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              onReset={resetSettings}
              onColumnSettingsSave={handleSave}
              setConfigureInspector={setConfigureInspector}
            />
          )}
        </Box>
      )}
    </>
  );
};

export default React.memo(MonthlyTab);
