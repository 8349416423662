import GridOnRoundedIcon from '@mui/icons-material/GridOnRounded';
import {
  AuthContext,
  PageTabs,
  SettingsContext,
} from '@teto/react-component-library';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import { Permission } from 'teto-client-api';
import TetoContainer from '../../../components/TetoGrid/TetoContainer';
import TimesheetsMonthlyTab from './TimesheetsMonthlyTab/TimesheetsMonthlyTab';
import TimesheetsWeeklyTab from '../TimesheetsWeeklyTab/TimesheetsWeeklyTab';

const tabPanelStyleSx = {
  overflow: 'hidden',
};

const TimesheetsPage = () => {
  const { t, ready } = useTranslation();
  const authContext = useContext(AuthContext);
  const settingsContext = useContext(SettingsContext);
  const canAccessTimesheets =
    authContext.hasAnyLicense() &&
    (authContext.hasAnyPermission([
      Permission.Modify_Admin_Timecards,
      Permission.View_Admin_Timecards,
    ]) ||
      (settingsContext.settings.approverCanManageTime &&
        authContext.user?.isApprover));

  const navigate = useNavigate();
  const location = useLocation();

  const timesheetTabs = {
    week: {
      title: t('pages.timesheets.week'),
      icon: <GridOnRoundedIcon />,
      component: (
        <TimesheetsWeeklyTab
          pageName="timesheets"
          approvalsDisabled
          checkBox={false}
        />
      ),
      priority: 1,
    },
    month: {
      title: t('pages.timesheets.month'),
      icon: <PlaylistAddCheckIcon />,
      component: <TimesheetsMonthlyTab />,
      priority: 2,
    },
  };

  const timesheetTabKeys = Object.keys(timesheetTabs);
  const availableTabs = timesheetTabKeys.filter((tab) =>
    location.pathname.includes(tab)
  );

  useEffect(() => {
    // Temporary solution, should generalize this.
    if (!canAccessTimesheets) navigate('/access-denied', { replace: true });
    if (availableTabs.length === 0)
      navigate('/timeTracking/timesheets/week/', { replace: true });
  }, [availableTabs, canAccessTimesheets, navigate]);

  const selectedTab = availableTabs.length > 0 ? availableTabs[0] : undefined;

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      <TetoContainer withBottomNav>
        {ready && selectedTab && (
          <PageTabs
            customTabPanelSx={tabPanelStyleSx}
            tabs={timesheetTabs}
            selectedTab={selectedTab}
            handleTabChange={(e) => navigate(`/timeTracking/timesheets/${e}`)}
          />
        )}
      </TetoContainer>
    </>
  );
};

export default TimesheetsPage;
