import { AuthContext } from '@teto/react-component-library';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Licenses } from 'teto-client-api';
import TetoContainer from '../../../components/TetoGrid/TetoContainer';
import TimesheetsWeeklyTab from '../TimesheetsWeeklyTab/TimesheetsWeeklyTab';

const TimesheetsApprovalsPage = () => {
  const { ready } = useTranslation();
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);

  useEffect(() => {
    // Temporary solution, should generalize this.
    if (!authContext.user?.isApprover)
      navigate('/access-denied', { replace: true });
  }, [authContext.user?.isApprover, navigate]);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {authContext.user?.isApprover && (
        <TetoContainer withBottomNav>
          {ready && (
            <TimesheetsWeeklyTab
              pageName="approvals"
              disableDateRange
              defaultSort={[{ name: 'startDate', dir: -1 }]}
              disableGroupByToolbar
              defaultGrouping={['dateRange']}
              approvalsDisabled={
                !authContext.hasLicense(Licenses.TotalETOEnterprise)
              }
            />
          )}
        </TetoContainer>
      )}
    </>
  );
};

export default TimesheetsApprovalsPage;
