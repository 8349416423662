import NotificationImportantRoundedIcon from '@mui/icons-material/NotificationImportantRounded';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface ButtonObjectProps {
  // eslint-disable-next-line no-unused-vars
  onClick: (e: React.SyntheticEvent | undefined) => void;
  disabled: boolean;
}

const NotifyButton = (props: ButtonObjectProps) => {
  const { t } = useTranslation();
  const { onClick, disabled } = props;

  return {
    title: t('generic.notify'),
    componentName: t('generic.notify'),
    icon: <NotificationImportantRoundedIcon />,
    onclick: (e: React.SyntheticEvent | undefined) => onClick(e),
    disabled,
  };
};

export default NotifyButton;
