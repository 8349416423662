import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface ConfigureButtonObjectProps {
  onClick: () => void;
}

const ConfigureButtonObject = (props: ConfigureButtonObjectProps) => {
  const { t } = useTranslation();
  const { onClick } = props;

  return {
    title: t('generic.configure'),
    icon: <SettingsRoundedIcon />,
    onclick: () => onClick(),
  };
};

export default ConfigureButtonObject;
