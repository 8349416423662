import * as Yup from 'yup';

export const REQUIRED_MESSAGE = 'Required';

const YupSchema = Yup.object().shape({
  employeeId: Yup.number().required(REQUIRED_MESSAGE).min(-1, REQUIRED_MESSAGE),
  projectId: Yup.number().required(REQUIRED_MESSAGE).min(1, REQUIRED_MESSAGE),
  specId: Yup.number().required(REQUIRED_MESSAGE).min(1, REQUIRED_MESSAGE),
  hourTypeId: Yup.number().required(REQUIRED_MESSAGE).min(0, REQUIRED_MESSAGE),
  hourRate: Yup.number().optional(),
  hourFactor: Yup.number().optional(),
  processScheduleDetailId: Yup.number().when('requireProcessSchedule', {
    is: true,
    then: Yup.number().required(REQUIRED_MESSAGE).min(1, REQUIRED_MESSAGE),
  }),
  nonConformanceId: Yup.number().when('requireNonConformance', {
    is: true,
    then: Yup.number().required(REQUIRED_MESSAGE).min(1, REQUIRED_MESSAGE),
  }),
  quantity: Yup.number().when('requireQuantity', {
    is: true,
    then: Yup.number().required(REQUIRED_MESSAGE).min(1, REQUIRED_MESSAGE),
  }),
  comments: Yup.string().when('requireComments', {
    is: true,
    then: Yup.string().required(REQUIRED_MESSAGE).min(1),
  }),
  custom1: Yup.string().when('requireCustom1', {
    is: true,
    then: Yup.string().required(REQUIRED_MESSAGE),
  }),
  custom2: Yup.string().when('requireCustom2', {
    is: true,
    then: Yup.bool().required(REQUIRED_MESSAGE),
  }),
  custom3: Yup.number().when('requireCustom3', {
    is: true,
    then: Yup.number().required(REQUIRED_MESSAGE),
  }),
  custom4: Yup.number().when('requireCustom4', {
    is: true,
    then: Yup.number().required(REQUIRED_MESSAGE),
  }),
  custom5: Yup.date()
    .nullable()
    .default(null)
    .when('requireCustom5', {
      is: true,
      then: Yup.date().nullable().default(null).required(REQUIRED_MESSAGE),
    }),
  custom6: Yup.date()
    .nullable()
    .default(null)
    .when('requireCustom6', {
      is: true,
      then: Yup.date().nullable().default(null).required(REQUIRED_MESSAGE),
    }),
  custom7: Yup.bool().when('requireCustom7', {
    is: true,
    then: Yup.bool().required(REQUIRED_MESSAGE),
  }),
  custom8: Yup.bool().when('requireCustom8', {
    is: true,
    then: Yup.bool().required(REQUIRED_MESSAGE),
  }),
  pscCustom1: Yup.string().when('requirePSCCustom1', {
    is: true,
    then: Yup.string().required(REQUIRED_MESSAGE),
  }),
  pscCustom2: Yup.string().when('requirePSCCustom2', {
    is: true,
    then: Yup.bool().required(REQUIRED_MESSAGE),
  }),
  pscCustom3: Yup.number().when('requirePSCCustom3', {
    is: true,
    then: Yup.number().required(REQUIRED_MESSAGE),
  }),
  pscCustom4: Yup.number().when('requirePSCCustom4', {
    is: true,
    then: Yup.number().required(REQUIRED_MESSAGE),
  }),
  pscCustom5: Yup.date()
    .nullable()
    .default(null)
    .when('requirePSCCustom5', {
      is: true,
      then: Yup.date().nullable().default(null).required(REQUIRED_MESSAGE),
    }),
  pscCustom6: Yup.date()
    .nullable()
    .default(null)
    .when('requirePSCCustom6', {
      is: true,
      then: Yup.date().nullable().default(null).required(REQUIRED_MESSAGE),
    }),
  pscCustom7: Yup.bool().when('requirePSCCustom7', {
    is: true,
    then: Yup.bool().required(REQUIRED_MESSAGE),
  }),
  pscCustom8: Yup.bool().when('requirePSCCustom8', {
    is: true,
    then: Yup.bool().required(REQUIRED_MESSAGE),
  }),
  requireProcessSchedule: Yup.bool().required(REQUIRED_MESSAGE),
  requireNonConformance: Yup.bool().required(REQUIRED_MESSAGE),
  requireQuantity: Yup.bool().required(REQUIRED_MESSAGE),
  requireCustom1: Yup.bool().required(REQUIRED_MESSAGE),
  requireCustom2: Yup.bool().required(REQUIRED_MESSAGE),
  requireCustom3: Yup.bool().required(REQUIRED_MESSAGE),
  requireCustom4: Yup.bool().required(REQUIRED_MESSAGE),
  requireCustom5: Yup.bool().required(REQUIRED_MESSAGE),
  requireCustom6: Yup.bool().required(REQUIRED_MESSAGE),
  requireCustom7: Yup.bool().required(REQUIRED_MESSAGE),
  requireCustom8: Yup.bool().required(REQUIRED_MESSAGE),
  requirePSCCustom1: Yup.bool().required(REQUIRED_MESSAGE),
  requirePSCCustom2: Yup.bool().required(REQUIRED_MESSAGE),
  requirePSCCustom3: Yup.bool().required(REQUIRED_MESSAGE),
  requirePSCCustom4: Yup.bool().required(REQUIRED_MESSAGE),
  requirePSCCustom5: Yup.bool().required(REQUIRED_MESSAGE),
  requirePSCCustom6: Yup.bool().required(REQUIRED_MESSAGE),
  requirePSCCustom7: Yup.bool().required(REQUIRED_MESSAGE),
  requirePSCCustom8: Yup.bool().required(REQUIRED_MESSAGE),
  requireComments: Yup.bool().required(REQUIRED_MESSAGE),
});

export default YupSchema;
