import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import { AuthContext, SettingsContext } from '@teto/react-component-library';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Permission } from 'teto-client-api';

interface AddHoursButtonProps {
  onClick: () => void;
  disabled?: boolean;
}

interface AdminAddHoursButtonProps extends AddHoursButtonProps {
  employeeId?: number;
}

export const AdminAddHoursButton = (props: AdminAddHoursButtonProps) => {
  const authContext = useContext(AuthContext);
  const settingsContext = useContext(SettingsContext);

  const { disabled, employeeId } = props;

  const hasAccess = () => {
    if (!authContext.hasAnyLicense()) return false;
    if (authContext.hasPermission(Permission.Add_Admin_Timecards)) return true;
    if (
      employeeId &&
      settingsContext.settings.approverCanManageTime &&
      authContext.canApprove(employeeId)
    )
      return true;
    if (
      !employeeId &&
      authContext.user?.isApprover &&
      settingsContext.settings.approverCanManageTime
    )
      return true;
    return false;
  };

  return {
    ...AddHoursButton(props),
    disabled: disabled || !hasAccess(),
  };
};

export const ClientAddHoursButton = (props: AddHoursButtonProps) => {
  const authContext = useContext(AuthContext);
  const { disabled } = props;

  const hasAccess = () => {
    if (!authContext.hasAnyLicense()) return false;
    if (authContext.hasPermission(Permission.Add_MyTimeTracker_Timecards))
      return true;
    return false;
  };

  return {
    ...AddHoursButton(props),
    disabled: disabled || !hasAccess(),
  };
};

const AddHoursButton = (props: AddHoursButtonProps) => {
  const { t } = useTranslation();
  const { onClick, disabled } = props;

  return {
    title: t('forms.addHours'),
    icon: <AddCircleOutlineRoundedIcon />,
    disabled,
    onclick: () => onClick(),
  };
};
