import { Box, Fade } from '@mui/material';
import { AuthContext } from '@teto/react-component-library';
import React, { useContext } from 'react';
import TetoGridCommonProps from '../TetoGridCommonProps';
import GridPersistenceContext from './GridPersistenceContext';
import GridPersistenceProvider from './GridPersistenceProvider';

interface PersistenceWrapperInnerProps {
  children: React.ReactNode;
}

const PersistenceWrapper = (
  // eslint-disable-next-line react/no-unused-prop-types
  // eslint-disable-next-line @typescript-eslint/ban-types
  props: TetoGridCommonProps<{}, {}, {}> & {
    persistenceTypeOverride?: 'db' | 'localStorage' | 'sessionStorage' | 'none';
  }
) => {
  const {
    children,
    tableIdentifier,
    columns,
    defaultGrouping,
    defaultSort,
    persistenceTypeOverride,
  } = props;
  const authContext = useContext(AuthContext);

  return (
    <GridPersistenceProvider
      persistenceType={persistenceTypeOverride ?? authContext.persistenceMode()}
      tableIdentifier={tableIdentifier}
      columns={columns}
      defaultGrouping={defaultGrouping}
      defaultSort={defaultSort}
    >
      <PersistenceWrapperInner>{children}</PersistenceWrapperInner>
    </GridPersistenceProvider>
  );
};

const PersistenceWrapperInner = (props: PersistenceWrapperInnerProps) => {
  const { children } = props;
  const persistenceContext = useContext(GridPersistenceContext);

  return (
    <Fade in={persistenceContext.loaded} mountOnEnter unmountOnExit>
      <Box width="100%" height="100%" sx={{ overflow: 'auto' }}>
        {persistenceContext.loaded && children}
      </Box>
    </Fade>
  );
};

export default PersistenceWrapper;
