/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  ListItem,
  SxProps,
  Theme,
  ListItemText,
  Typography,
  List,
  Checkbox,
  ListItemIcon,
  Divider,
} from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { getEmployees } from 'teto-client-api';
import { ETOTextField, Inspector } from '@teto/react-component-library';
import { t } from 'i18next';

interface AssignEmployeesInspectorProps {
  title: string;
  record?: any;
  onClose: () => void;
  selectedEmployees: number[];
  inferredSelectedEmployees: number[];
  // eslint-disable-next-line no-unused-vars
  onAssign: (employeeId: number, record: any) => Promise<void>;
  // eslint-disable-next-line no-unused-vars
  onUnassign: (employeeId: number, record: any) => Promise<void>;
}

const inspectorRootSx: SxProps<Theme> = (theme: Theme) => ({
  marginRight: theme.spacing(-2),
  maxHeight: '95%',
  overflowY: 'auto',
});

const AssignEmployeesInspector = ({
  title,
  record,
  onClose,
  selectedEmployees,
  onAssign,
  onUnassign,
  inferredSelectedEmployees,
}: AssignEmployeesInspectorProps) => {
  const [search, setSearch] = useState('');

  const employeeQuery = useQuery(
    'approver-employees',
    () =>
      getEmployees({
        disablePaging: true,
        orderBy: { name: false },
        filter: { includeApprovers: true, active: true },
      }),
    {
      refetchOnWindowFocus: false,
    }
  );

  const filteredEmployees = useMemo(
    () =>
      !search || search === ''
        ? employeeQuery.data?.records
        : employeeQuery.data?.records.filter(
            (a) =>
              a.firstName.toLowerCase().search(search.toLowerCase()) >= 0 ||
              a.lastName.toLowerCase().search(search.toLowerCase()) >= 0 ||
              a.employeeNumber.toLowerCase().search(search.toLowerCase()) >= 0
          ),
    [employeeQuery.data, search]
  );

  return (
    <Inspector
      open={Boolean(record)}
      customSx={inspectorRootSx as SxProps}
      onClose={onClose}
      title={title}
    >
      {record && (
        <Box>
          <Box sx={{ px: 1, pb: 2 }}>
            <ETOTextField
              name="search"
              autoFocus
              label={t('generic.search')}
              value={search}
              handleChange={(e) => setSearch(e.currentTarget.value)}
              size="small"
            />
          </Box>
          <Divider />
          <List sx={{ overflowY: 'auto' }}>
            {employeeQuery.isSuccess &&
              (filteredEmployees ?? []).map((item) => (
                <ListItem key={item.id} disableGutters>
                  <ListItemIcon>
                    <Checkbox
                      color="primary"
                      disabled={inferredSelectedEmployees.includes(item.id)}
                      checked={
                        selectedEmployees.includes(item.id) ||
                        inferredSelectedEmployees.includes(item.id)
                      }
                      onChange={(e) =>
                        e.target.checked
                          ? onAssign(item.id, record)
                          : onUnassign(item.id, record)
                      }
                    />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography
                      color={
                        inferredSelectedEmployees.includes(item.id)
                          ? 'textSecondary'
                          : 'textPrimary'
                      }
                      variant="subtitle1"
                    >
                      {item.employeeNumber} - {item.firstName} {item.lastName}
                    </Typography>
                  </ListItemText>
                </ListItem>
              ))}
          </List>
        </Box>
      )}
    </Inspector>
  );
};

export default AssignEmployeesInspector;
