import {
  getEmployeeSettings,
  getEmployeeSettingsWithServerSettings,
} from 'teto-client-api';

export const getChosenEmployeeSettings = (inheritSetting: boolean) =>
  inheritSetting
    ? getEmployeeSettingsWithServerSettings()
    : getEmployeeSettings();

export default getChosenEmployeeSettings;
