import HowToVoteRounded from '@mui/icons-material/HowToVoteRounded';
import Box from '@mui/material/Box';
import { SxProps, Theme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import { Dayjs } from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface PunchInIconProps {
  id?: number | Dayjs;
}
const punchInSx: SxProps<Theme> = {
  width: (theme) => theme.spacing(3.75),
  height: '100%',
};

const PunchInIcon = (props: PunchInIconProps) => {
  const { id } = props;
  const { t } = useTranslation();
  const icon = id ? { visibility: 'visible' } : { visibility: 'hidden' };
  return (
    <Box sx={[punchInSx, icon]}>
      <Tooltip title={t('Entities.PunchIn.punchInRecord')}>
        <HowToVoteRounded
          sx={{ color: (theme) => theme.palette.secondary.light }}
        />
      </Tooltip>
    </Box>
  );
};

export default PunchInIcon;
