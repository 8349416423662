import Box from '@mui/material/Box';
import { SxProps, Theme, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { formatHoursAsHourMin } from '@teto/react-component-library';
import React, { useMemo } from 'react';

export interface DesktopHeaderProps {
  title: string;
  status?: string;
  date?: string;
  time?: number;
}

const rootSx: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  height: '100%',
};

const DesktopHeader = ({ date, status, time, title }: DesktopHeaderProps) => {
  const theme = useTheme();
  const mobileSize = useMediaQuery(theme.breakpoints.down('md'));

  const titleText = date ? `${title} ${date}` : title;
  const bottomText = useMemo(() => {
    if (status) return status;
    if ((time as number) >= 0) return formatHoursAsHourMin(time as number);
    return ' ';
  }, [status, time]);

  return (
    <Box sx={rootSx}>
      <Typography align={!status ? 'center' : 'left'}>{titleText}</Typography>
      {!mobileSize && (
        <Typography align={!status ? 'center' : 'left'}>
          {bottomText}
        </Typography>
      )}
    </Box>
  );
};

export default DesktopHeader;
