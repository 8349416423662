import { SettingsContext } from '@teto/react-component-library';
import dayjs from 'dayjs';
import { useFormikContext } from 'formik';
import React, { useContext } from 'react';
import { Errors } from '../InlineEditing/editorTypes';
import EmptyDisplay from './EmptyDisplay';
import FormatterErrorWrapper from './FormatterErrorWrapper';
import FormatterProps from './FormatterProps';

const DateTimeFormatter = (props: FormatterProps) => {
  const settingsContext = useContext(SettingsContext);
  const formik = useFormikContext();
  const {
    value,
    column: { name, emptyDisplay },
    data,
    cellProps,
  } = props;
  const { errors } = formik;
  const error = errors[name as keyof Errors];
  const element = (
    <span>
      {!value || !settingsContext.settingsLoaded ? (
        <EmptyDisplay placeHolder={emptyDisplay} />
      ) : (
        dayjs(value).format(settingsContext.settings.dateTimeFormat)
      )}
    </span>
  );
  const rowErrorIndex =
    (Object.hasOwn(data, '__editing') && data.__editing && name) === name;
  const { editorProps } = cellProps;
  switch (editorProps.inlineEditMode) {
    case 'default':
      if (editorProps.inlineEditingCondition) {
        const result = editorProps.inlineEditingCondition(
          value,
          cellProps.data
        );

        if (result?.errorIs !== false) {
          return (
            <FormatterErrorWrapper
              errorIs={result.errorIs}
              errorText={result?.errorText}
            >
              {element}
            </FormatterErrorWrapper>
          );
        }
      }
      break;

    default:
      if (error && rowErrorIndex) {
        return (
          <FormatterErrorWrapper errorIs errorText={error}>
            {element}
          </FormatterErrorWrapper>
        );
      }
      break;
  }

  return element;
};

export default DateTimeFormatter;
