const stopMyClockIn = `
mutation stopMyClockIn {
  stopMyClockIn() 
  {
    success
    id
    date
    employeeId
    startTime
    endTime
  }
}`;
export default stopMyClockIn;
