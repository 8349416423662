import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { AuthContext } from '@teto/react-component-library';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import useCommonStyles from './commonStyles';
import TabItem from './TabItem';

const AccountTab = () => {
  const authContext = useContext(AuthContext);
  const { t } = useTranslation(['generic']);

  const theme = useTheme();
  const commonStyles = useCommonStyles(theme);

  const myLicenses: string[] = authContext.getUserLicensesList();

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {authContext.user && (
        <>
          <Typography
            sx={[commonStyles.sectionTitle, commonStyles.textThemeColor]}
            variant="h6"
          >
            {t('generic.account')}
          </Typography>
          <List>
            <TabItem
              settingsName={t('generic.name')}
              secondary={`${authContext.user.firstName} ${authContext.user.lastName}`}
            />
            <TabItem
              settingsName={t('generic.version')}
              secondary={`${authContext.appVersion}`}
            />
            <TabItem
              settingsName={
                myLicenses.length > 1 ? t('auth.licenses') : t('auth.license')
              }
              secondary={
                <List sx={commonStyles.licenseList}>
                  {myLicenses.map((l) => (
                    <ListItem key={l} disableGutters>
                      {t(`license.${l}`)}
                    </ListItem>
                  ))}
                </List>
              }
            />
          </List>
        </>
      )}
    </>
  );
};

export default AccountTab;
