import { Box, FormControl } from '@mui/material';
import { ETOSelectField } from '@teto/react-component-library';
import { useFormikContext } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { QueryFunction, useQuery } from 'react-query';
import { getDisabledValue } from '../editingHelpers';
import { Errors, Values } from '../editorTypes';
import editorStyles from '../InlineEditingCommonStyles';
import InlineEditorProps from '../InlineEditingProps';
import ETODependencyEditor from './ETODependencyEditor';

const cellSx = {
  padding: 0,
  margin: 0,
  '& .MuiOutlinedInput-root': {
    height: '100%',
  },
  '& .MuiAutocomplete-root': { height: '100%' },
  '& .MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input':
    {
      padding: 0,
    },
  '& .MuiInputBase-input-MuiOutlinedInput-input': { padding: 0 },
  // '& .MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input': { padding: 0, },
  '& .MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input': {
    padding: 0,
  },
  '& .css-eozd4h-MuiAutocomplete-root .MuiOutlinedInput-root': {
    padding: '8px',
  },
};

const ETOSelectionEditor: React.FC<InlineEditorProps> = (props) => {
  const { autoFocus, cellProps, editorProps, onComplete, onTabNavigation } =
    props;
  const formik = useFormikContext();
  const [isPrimarySelected, setIsPrimarySelected] = useState<boolean>(false);
  const columnName = cellProps?.name as string;
  const data = cellProps?.data;
  const cellDependents = editorProps?.dependantColumns?.[columnName];
  const cellDependentList = cellDependents
    ? [
        ...(cellDependents?.required as string[]),
        ...(cellDependents?.optional as string[]),
      ]
    : [];

  const { errors, values, initialValues } = formik;
  const [updateComplete, setUpdateComplete] =
    useState<unknown | null | undefined>(undefined);
  const isDisabled =
    !editorProps?.hasEditPermission ||
    getDisabledValue(data, editorProps?.disabled) ||
    editorProps?.lockColumn?.(columnName, data);
  const selectorParams = editorProps?.selector?.(
    columnName as string,
    formik.values
  );
  const processForeignKey = editorProps?.processForeignKey;
  const primaryEditorRef = useRef<HTMLInputElement>();
  const divRef = useRef<HTMLDivElement>(null);
  const defaultValue =
    cellProps?.data?.[processForeignKey?.(columnName, 'key')];
  const selectValue = (values as Values)?.[
    processForeignKey?.(columnName, 'key')
  ];

  // Make Api call
  const selectorItems = useQuery(
    selectorParams?.selectorQuery?.queryKey as string | unknown[],
    selectorParams?.selectorQuery?.queryFn as QueryFunction<
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      string | unknown[] | any
    >,
    selectorParams?.selectorQuery?.queryOptions
  );

  useEffect(() => {
    if (updateComplete && (values as Values)?.[columnName] !== updateComplete) {
      onComplete?.();
    }
  }, [columnName, onComplete, updateComplete, values]);

  const _handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> // | React.KeyboardEvent<HTMLInputElement | HTMLDivElement>
  ) => {
    const selectedForeignKeyValues = selectorItems?.data.filter(
      (f: { id: string }) =>
        f.id === e.target.value || f.id.toString() === e.target.value
    )[0];

    const foreignChangedKeyValues: { [key: string]: unknown } =
      editorProps?.handleForeignKeyChange?.(
        { value: e.target.value, name: e.target.name },
        selectedForeignKeyValues
      ) as { value: string; name: string };

    if (cellDependentList.length > 0) {
      cellDependentList?.forEach((s) => {
        processForeignKey?.(s, 'reset').forEach((item: string) => {
          foreignChangedKeyValues[item] = '';
        });
      });
      formik.setValues({
        ...(values as Values),
        ...foreignChangedKeyValues,
      });
    } else {
      formik.setValues({
        ...(values as Values),
        ...foreignChangedKeyValues,
      });
      setUpdateComplete((values as Values)?.[columnName] as unknown);
    }

    e.stopPropagation();
    e.preventDefault();
    setIsPrimarySelected(true);
  };

  const _handleAccept = () => {
    setIsPrimarySelected(false);
    setUpdateComplete(' ');
  };

  const _handleReject = () => {
    setIsPrimarySelected(false);

    const resetKeyList =
      cellDependentList.length > 0
        ? [...cellDependentList, columnName]
        : [columnName];
    resetKeyList?.forEach((e) => {
      processForeignKey?.(e, 'reset').forEach((item: string) => {
        formik.setFieldValue(item, (initialValues as Values)?.[item]);
      });
    });
    setUpdateComplete((values as Values)?.[columnName] as unknown);
  };

  const dependantProps = {
    popoverProps: {
      width: divRef?.current?.offsetWidth as number,
      anchorEl: primaryEditorRef.current,
    },
    onAccept: () => _handleAccept(),
    onReject: () => _handleReject(),
    dependents: cellDependents,
    dependentSelector: editorProps?.selector,
    disabled: isDisabled,
    handleForeignKeyChange: editorProps?.handleForeignKeyChange,
    processForeignKey: editorProps?.processForeignKey,
  };

  return (
    <>
      {columnName && !isDisabled && selectorItems.data && (
        <Box
          sx={[editorStyles.root, cellSx]}
          data-testid="container"
          ref={divRef}
        >
          <FormControl sx={editorStyles.input}>
            <ETOSelectField
              autoFocus={autoFocus ?? true}
              customSx={[
                editorStyles.input,
                {
                  margin: 0,
                  padding: 'auto',
                },
              ]}
              defaultValue={defaultValue ?? selectorParams?.defaultValue}
              disabled={isDisabled}
              error={errors[columnName as keyof Errors]}
              handleChange={_handleChange}
              name={columnName}
              items={selectorItems.data ?? []}
              itemNameSelector={(item) =>
                selectorParams?.itemNameSelector?.(item) as string
              }
              itemValueSelector={(item) =>
                selectorParams?.itemValueSelector?.(item)
              }
              itemDisabledSelector={(item) =>
                selectorParams?.itemDisabledSelector?.(item) as boolean
              }
              size="small"
              value={selectValue}
              onFocus={(e) => e.target.select()}
              onKeyDown={(e) => {
                if (e.key === 'Tab') {
                  e.preventDefault();
                  onTabNavigation?.(true, e.shiftKey ? -1 : 1);
                }
                if (e.key === 'Escape') {
                  onComplete?.();
                  e.stopPropagation();
                  e.preventDefault();
                }
              }}
              inputRef={primaryEditorRef}
            />
          </FormControl>
        </Box>
      )}
      {columnName &&
        !isDisabled &&
        isPrimarySelected &&
        selectorItems.data &&
        cellDependentList.length > 0 && (
          <ETODependencyEditor {...dependantProps} />
        )}
    </>
  );
};

export default ETOSelectionEditor;
