/* eslint-disable radix */
/* eslint-disable react/jsx-props-no-spreading */
import { Box, Grid } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ETODateField, SettingsContext } from '@teto/react-component-library';
import { Dayjs } from 'dayjs';
import { FormikHelpers, useFormik } from 'formik';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AuthenticatedUser,
  Employee,
  HourType,
  Job,
  NonConformance,
  ProcessScheduleDetail,
  Project,
} from 'teto-client-api';
import { parseServerResponse } from '../../../../helpers/validationHelperREST';
import { CommonFields, CustomFields, OptionalFields } from '../Fields';
import FieldsDisableEditableType from '../FieldsDisableEditableType';
import isDisabled from '../isDisabled';
import TimeCardForm, { isCustomCaption } from '../TimeCardForm';
import TimeCardValidation from './HourFormValidation';
import HourFormValues from './HourFormValues';
import HourTimeCardRequestFormat from './HourTimeCardRequestFormat';

const hourTimeCardFormSx = {
  flex: 1,
  flexGrow: 1,
  overflowY: 'hidden',
};

const formSx = {
  height: '100%',
  width: '100%',
};

export interface HourTimeCardFormProps {
  qrEnabled: boolean;
  editMode?: {
    id: number;
    // eslint-disable-next-line no-unused-vars
    deleteFunction: (id: number) => Promise<void>;
  };
  fieldsDisableEditable:
    | boolean
    | Partial<FieldsDisableEditableType & { date: boolean }>;
  initialValues: Partial<HourFormValues> & {
    hour: number;
    date: Dayjs;
  };
  fieldCaptions: {
    // eslint-disable-next-line no-unused-vars
    [key in
      | CommonFields
      | OptionalFields
      | CustomFields
      | 'hour'
      | 'date']: string;
  };
  // eslint-disable-next-line no-unused-vars
  requireFields?: Partial<Record<OptionalFields | CustomFields, boolean>>;
  onSubmit: (
    // eslint-disable-next-line no-unused-vars
    timecards: HourTimeCardRequestFormat,
    // eslint-disable-next-line no-unused-vars
    actions: FormikHelpers<HourFormValues>
  ) => Promise<void>;
  getProjects: () => Promise<Project[]>;
  // eslint-disable-next-line no-unused-vars
  getJobs: (projectId: number) => Promise<Job[]>;
  getNonConformances: (
    // eslint-disable-next-line no-unused-vars
    projectId: number,
    // eslint-disable-next-line no-unused-vars
    specId: number
  ) => Promise<NonConformance[]>;
  getProcessSchedules: (
    // eslint-disable-next-line no-unused-vars
    projectId: number,
    // eslint-disable-next-line no-unused-vars
    specId: number
  ) => Promise<ProcessScheduleDetail[]>;
  getHourTypes: () => Promise<HourType[]>;
  getEmployees: (() => Promise<Employee[]>) | AuthenticatedUser;
  // eslint-disable-next-line no-unused-vars
  onError: (err: string | Error) => void;
  canAffect: boolean;
  canDelete: boolean;
  // eslint-disable-next-line no-unused-vars
  setFormDirty?: (val: boolean) => void;
}

function _formatOutput(formValues: HourFormValues): HourTimeCardRequestFormat {
  // remove all undefined fields the easy way
  const out = JSON.parse(
    JSON.stringify({
      date: formValues.date,
      employeeId: parseInt(formValues.employeeId as unknown as string),
      projectId: parseInt(formValues.projectId as unknown as string),
      specId: parseFloat(formValues.specId as unknown as string),
      hourTypeId: parseInt(formValues.hourTypeId as unknown as string),
      processScheduleDetailId:
        formValues.processScheduleDetailId &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (formValues.processScheduleDetailId as any) !== '-1'
          ? parseInt(formValues.processScheduleDetailId as unknown as string)
          : undefined,
      nonConformanceId:
        formValues.nonConformanceId &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (formValues.nonConformanceId as any) !== '-1'
          ? parseInt(formValues.nonConformanceId as unknown as string)
          : undefined,
      quantity: formValues.quantity,
      comments: formValues.comments,
      custom1: formValues.custom1,
      custom2: formValues.custom2,
      custom3: formValues.custom3,
      custom4: formValues.custom4,
      custom5: formValues.custom5 ?? undefined,
      custom6: formValues.custom6 ?? undefined,
      custom7: formValues.custom7,
      custom8: formValues.custom8,
      pSCCustom1: formValues.pSCCustom1,
      pSCCustom2: formValues.pSCCustom2,
      pSCCustom3: formValues.pSCCustom3,
      pSCCustom4: formValues.pSCCustom4,
      pSCCustom5: formValues.pSCCustom5 ?? undefined,
      pSCCustom6: formValues.pSCCustom6 ?? undefined,
      pSCCustom7: formValues.pSCCustom7,
      pSCCustom8: formValues.pSCCustom8,
      hour: formValues.hour,
      hourRate: formValues.hourRate,
      hourFactor: formValues.hourFactor,
    })
  );

  if (out.processScheduleDetailId <= 0) {
    delete out.processScheduleDetailId;
  }

  if (out.nonConformanceId <= 0) {
    delete out.nonConformanceId;
  }

  return out;
}

const HourTimeCardForm = (props: HourTimeCardFormProps) => {
  const settingsContext = useContext(SettingsContext);
  const { t } = useTranslation();

  const {
    getEmployees,
    qrEnabled,
    editMode,
    initialValues,
    requireFields,
    getJobs,
    getHourTypes,
    getProjects,
    getNonConformances,
    getProcessSchedules,
    fieldCaptions,
    fieldsDisableEditable,
    onSubmit,
    onError,
    canAffect,
    canDelete,
    setFormDirty,
  } = props;

  const formik = useFormik<HourFormValues>({
    enableReinitialize: true,
    validationSchema: TimeCardValidation,
    initialValues: {
      employeeId:
        typeof getEmployees === 'function'
          ? initialValues?.employeeId ?? -2
          : getEmployees.id,
      hour: initialValues?.hour ?? 0,
      hourFactor: initialValues?.hourFactor,
      hourRate: initialValues?.hourRate,
      projectId: initialValues?.projectId ?? -1,
      specId: initialValues?.specId ?? -1,
      hourTypeId: initialValues?.hourTypeId ?? -1,
      quantity: initialValues?.quantity ?? 0,
      custom1: initialValues?.custom1 ?? '',
      custom2: initialValues?.custom2 ?? '',
      custom3: initialValues?.custom3 ?? undefined,
      custom4: initialValues?.custom4 ?? undefined,
      custom5: initialValues?.custom5 ?? null,
      custom6: initialValues?.custom6 ?? null,
      custom7: initialValues?.custom7 ?? false,
      custom8: initialValues?.custom8 ?? false,
      date: initialValues?.date,
      comments: initialValues?.comments ?? '',
      processScheduleDetailId: initialValues?.processScheduleDetailId ?? -1,
      nonConformanceId: initialValues?.nonConformanceId ?? -1,
      requireComments: requireFields?.comments ?? false,
      requireCustom1:
        (requireFields?.custom1 &&
          isCustomCaption('custom1', fieldCaptions.custom1)) ??
        false,
      requireCustom2:
        (requireFields?.custom2 &&
          isCustomCaption('custom2', fieldCaptions.custom2)) ??
        false,
      requireCustom3:
        (requireFields?.custom3 &&
          isCustomCaption('custom3', fieldCaptions.custom3)) ??
        false,
      requireCustom4:
        (requireFields?.custom4 &&
          isCustomCaption('custom4', fieldCaptions.custom4)) ??
        false,
      requireCustom5:
        (requireFields?.custom5 &&
          isCustomCaption('custom5', fieldCaptions.custom5)) ??
        false,
      requireCustom6:
        (requireFields?.custom6 &&
          isCustomCaption('custom6', fieldCaptions.custom6)) ??
        false,
      requireCustom7:
        (requireFields?.custom7 &&
          isCustomCaption('custom7', fieldCaptions.custom7)) ??
        false,
      requireCustom8:
        (requireFields?.custom8 &&
          isCustomCaption('custom8', fieldCaptions.custom8)) ??
        false,
      requirePSCCustom1:
        (requireFields?.pSCCustom1 &&
          isCustomCaption('pSCCustom1', fieldCaptions.pSCCustom1)) ??
        false,
      requirePSCCustom2:
        (requireFields?.pSCCustom2 &&
          isCustomCaption('pSCCustom2', fieldCaptions.pSCCustom2)) ??
        false,
      requirePSCCustom3:
        (requireFields?.pSCCustom3 &&
          isCustomCaption('pSCCustom3', fieldCaptions.pSCCustom3)) ??
        false,
      requirePSCCustom4:
        (requireFields?.pSCCustom4 &&
          isCustomCaption('pSCCustom4', fieldCaptions.pSCCustom4)) ??
        false,
      requirePSCCustom5:
        (requireFields?.pSCCustom5 &&
          isCustomCaption('pSCCustom5', fieldCaptions.pSCCustom5)) ??
        false,
      requirePSCCustom6:
        (requireFields?.pSCCustom6 &&
          isCustomCaption('pSCCustom6', fieldCaptions.pSCCustom6)) ??
        false,
      requirePSCCustom7:
        (requireFields?.pSCCustom7 &&
          isCustomCaption('pSCCustom7', fieldCaptions.pSCCustom7)) ??
        false,
      requirePSCCustom8:
        (requireFields?.pSCCustom8 &&
          isCustomCaption('pSCCustom8', fieldCaptions.pSCCustom8)) ??
        false,
      requireNonConformance: requireFields?.nonConformanceId ?? false,
      requireProcessSchedule: requireFields?.processScheduleDetailId ?? false,
      requireQuantity: requireFields?.quantity ?? false,
      pSCCustom1: initialValues?.pSCCustom1 ?? '',
      pSCCustom2: initialValues?.pSCCustom2 ?? '',
      pSCCustom3: initialValues?.pSCCustom3,
      pSCCustom4: initialValues?.pSCCustom4,
      pSCCustom5: initialValues?.pSCCustom5 ?? null,
      pSCCustom6: initialValues?.pSCCustom6 ?? null,
      pSCCustom7: initialValues?.pSCCustom7 ?? false,
      pSCCustom8: initialValues?.pSCCustom8 ?? false,
    },
    onSubmit: (values, actions) =>
      onSubmit(_formatOutput(values), actions)
        .then(() => {
          actions.setSubmitting(false);
        })
        .catch((e) => {
          actions.setSubmitting(false);
          parseServerResponse(
            e,
            (errors) => actions.setErrors(errors),
            (error) => onError(error)
          );
        }),
  });

  useEffect(() => {
    if (setFormDirty) setFormDirty(formik.dirty);
  }, [formik.dirty, setFormDirty]);

  const leftButton = editMode
    ? {
        disabled: formik.isSubmitting || !canDelete,
        color: 'error' as const,
        text: t('generic.delete'),
        confirm: {
          type: 'okCancel' as const,
          title: t('dialogs.deleteRecord.title'),
          content: t('dialogs.deleteRecord.content'),
        },
        onClick: () => editMode.deleteFunction(editMode.id),
      }
    : undefined;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={hourTimeCardFormSx} data-testid="hours-form">
        <Box
          component="form"
          onSubmit={formik.handleSubmit}
          sx={formSx}
          autoComplete="off"
        >
          <TimeCardForm
            isEditMode={Boolean(editMode)}
            disableAutoFocus
            buttonStrip={{
              size: 'medium',
              leftButton,
              rightButton: {
                disabled: formik.isSubmitting || !canAffect,
                color: 'primary',
                text: t('generic.save'),
                type: 'submit',
              },
            }}
            qrEnabled={qrEnabled}
            canAffect={canAffect}
            errors={formik.errors}
            fieldCaptions={fieldCaptions}
            fieldsDisableEditable={fieldsDisableEditable}
            getHourTypes={getHourTypes}
            getJobs={getJobs}
            getNonConformances={getNonConformances}
            getProcessSchedules={getProcessSchedules}
            getProjects={getProjects}
            handleChange={formik.handleChange}
            isSubmitting={formik.isSubmitting}
            setFieldValue={formik.setFieldValue}
            values={formik.values}
            origValues={formik.initialValues}
            getEmployees={getEmployees}
            submitForm={formik.handleSubmit}
          >
            <Grid item xs={12}>
              <Box>
                <Box m={2}>
                  <ETODateField
                    autoFocus
                    name="date"
                    disabled={isDisabled(
                      canAffect,
                      'endTime',
                      fieldsDisableEditable
                    )}
                    label={fieldCaptions.date}
                    inputFormat={settingsContext.settings.dateFormat}
                    value={formik.values.date}
                    error={formik.errors.date as string}
                    handleChange={(e) => formik.setFieldValue('date', e)}
                  />
                </Box>
              </Box>
            </Grid>
          </TimeCardForm>
        </Box>
      </Box>
    </LocalizationProvider>
  );
};

export default HourTimeCardForm;
