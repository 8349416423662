/* eslint-disable no-unused-vars */
import { Box, Checkbox, ListItemText } from '@mui/material';
import { uniqueId } from 'lodash';
import React, { useState } from 'react';
import TableColumnDefinition from '../TableColumnDefinition';

interface FilterCheckboxProps {
  column: TableColumnDefinition;
  handleChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    curCol: TableColumnDefinition
  ) => void | undefined | null;
  disabled?: boolean;
  defaultValue: string;
}

const FilterCheckbox = (props: FilterCheckboxProps) => {
  const { column, handleChange, disabled, defaultValue } = props;
  const [checked, setChecked] = useState<boolean>(
    defaultValue ? JSON.parse(defaultValue.toLowerCase()) : false
  );

  const _handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEvent = e;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    newEvent.target = {
      ...e,
      value: e.target.value === 'false' ? 'true' : 'false',
    };
    handleChange(newEvent, column);
    setChecked(!checked); // this improves how snappy it feels;
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyItems: 'space-between',
        flexGrow: 1,
      }}
    >
      <ListItemText key={uniqueId(column.name)} primary={column.title} />

      <Checkbox
        data-testid="filterListItemCheckBox"
        key={column.name}
        name={column.name}
        checked={checked}
        onChange={(e) => _handleChange(e)}
        color="primary"
        inputProps={{
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          'aria-checked': `${checked}`,
        }}
        size="medium"
        disabled={disabled}
        value={checked}
      />
    </Box>
  );
};

export default FilterCheckbox;
