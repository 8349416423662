import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { MutationKey, useMutation, useQueryClient } from 'react-query';
import { submitTimesheet } from 'teto-client-api';
import { MessageContext } from '@teto/react-component-library';
import TIMESHEET_QUERY_ID from '../../../api/reactQuery/TimesheetsQueryId';

// https://react-query.tanstack.com/reference/useMutation

const useSubmitTimesheet = () => {
  const queryClient = useQueryClient();
  const messageContext = useContext(MessageContext);
  const { t } = useTranslation();

  const _handleSubmit = async (props: { id: number; message: string }) => {
    const { id } = props;
    await submitTimesheet(id);
  };
  const mutation = useMutation<unknown, Error, { id: number }>(
    _handleSubmit as unknown as MutationKey,
    {
      onSuccess: () => {
        const queryIdentifier = TIMESHEET_QUERY_ID;
        queryClient.invalidateQueries(queryIdentifier, {
          refetchActive: true,
          refetchInactive: true,
        });
        messageContext.setSuccess(t('generic.message.timesheetSubmit'));
      },
      onError: (e) => {
        messageContext.setError(e.message);
      },
    }
  );

  return mutation;
};

export default useSubmitTimesheet;
