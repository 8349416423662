import {
  TypeSingleSortInfo,
  TypeSortInfo,
} from '@inovua/reactdatagrid-community/types';
import { Box, SxProps, Theme } from '@mui/material';
import {
  ButtonStrip,
  ConfirmDialogs,
  ETOSelectField,
} from '@teto/react-component-library';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TableColumnDefinition from '../../TableColumnDefinition';

const sortForm: SxProps<Theme> = (theme: Theme) => ({
  flexGrow: 1,
  marginBottom: theme.spacing(7),
  overflowY: 'auto',
  paddingRight: theme.spacing(2),
  '& .MuiTextField-root': {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
});

interface SortDrawerProps {
  handleClose: () => void;
  sorting?: TypeSingleSortInfo[];
  // eslint-disable-next-line no-unused-vars
  setSorting?: (sorting: TypeSortInfo) => void;
  sortableColumns: TableColumnDefinition[];
}

const SortByPanel = (props: SortDrawerProps) => {
  const { t, ready } = useTranslation();
  const { handleClose, setSorting, sorting, sortableColumns } = props;
  const label = 'Options';

  const [sortingDirection, setSortingDirection] = useState<0 | 1 | -1>(
    sorting && sorting.length > 0 ? sorting[0].dir : 0
  );
  const [sortingDirectionOptions, setSortingDirectionLabel] = useState('');
  const [selectedCol, setSelectedCol] = useState<TableColumnDefinition[]>([]);
  const [resetDialog, setResetDialog] = useState<boolean>(false);
  const _onSave = () => {
    const newSorting: TypeSingleSortInfo[] = selectedCol.map(
      (selCol: TableColumnDefinition) => ({
        dir: sortingDirection,
        name: selCol.name,
        id: selCol.id,
        type: selCol.type,
      })
    );
    if (setSorting) setSorting(newSorting);
    handleClose();
  };

  const _handleReset = () => {
    setSelectedCol([]);
    if (setSorting) setSorting([]);
    handleClose();
  };

  useEffect(() => {
    const currentSortingDirection = () => {
      if (sorting && sorting.length > 0) {
        switch (sorting[0].dir) {
          case 1:
            return 'Ascending';
          case -1:
            return 'Descending';

          default:
            return '';
        }
      }
      return '';
    };
    const direction = currentSortingDirection();

    if (sorting && sorting.length > 0) {
      const currentCol = sortableColumns.find(
        (matchingCol) => sorting[0].id === matchingCol.id
      );
      setSelectedCol(currentCol ? [currentCol] : []);
    }
    setSortingDirectionLabel(direction);
  }, [sortableColumns, sorting]);

  return (
    <>
      {ready && (
        <Box component="form" noValidate autoComplete="off" sx={sortForm}>
          <ETOSelectField
            id="sortingInput"
            label={label}
            size="small"
            name="groupings-select"
            items={sortableColumns}
            value={selectedCol[0]}
            defaultValue={selectedCol[0]?.title}
            handleChange={(e) => {
              setSelectedCol([
                e.target.value as unknown as TableColumnDefinition,
              ]);
              // @ts-expect-error: this does exist
              document?.activeElement?.blur();
            }}
            itemNameSelector={(i) => i.title}
            itemValueSelector={(i) => i}
          />
          <ETOSelectField
            id="ascendingDescendingInput"
            label={t('generic.sortBy')}
            size="small"
            name="sorting-select"
            items={['Ascending', 'Descending']}
            value={sortingDirectionOptions}
            defaultValue={sortingDirectionOptions}
            handleChange={(e) => {
              switch (e.target.value) {
                case 'Ascending':
                  setSortingDirectionLabel('Ascending');
                  setSortingDirection(1);
                  break;
                case 'Descending':
                  setSortingDirectionLabel('Descending');
                  setSortingDirection(-1);
                  break;

                default:
                  setSortingDirection(0);
                  break;
              }
              // @ts-expect-error: this does exist
              document?.activeElement?.blur();
            }}
            itemNameSelector={(i) => i}
            itemValueSelector={(i) => i}
          />
        </Box>
      )}

      <ButtonStrip
        size="medium"
        leftButton={{
          text: t('generic.resetGridSettings'),
          onClick: () => setResetDialog(true),
          color: 'warning',
        }}
        rightButton={{
          text: t('generic.save'),
          onClick: () => _onSave(),
          color: 'primary',
        }}
      />

      {resetDialog && (
        <ConfirmDialogs
          open={resetDialog}
          title={t('dialogs.resetSort.title')}
          content={t('dialogs.resetSort.content')}
          leftButton={{
            onClick: () => setResetDialog(false),
            label: t('generic.cancel'),
          }}
          rightButton={{
            onClick: () => {
              _handleReset();
              setResetDialog(false);
            },
            label: t('generic.confirm'),
          }}
        />
      )}
    </>
  );
};

export default SortByPanel;
