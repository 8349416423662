import LockOutlined from '@mui/icons-material/LockOutlined';
import { Theme } from '@mui/material/styles';
import { AuthContext, ErrorPage } from '@teto/react-component-library';
import React, { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

const iconSx = (theme: Theme) => ({
  fontSize: '5.5rem',
  [theme.breakpoints.up('md')]: {
    fontSize: '7.5rem',
  },
});

const AdminPermissionErrorPage = () => {
  const { logout } = useContext(AuthContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const _logout = useCallback(async () => {
    await logout()
      .then(() => {
        navigate('/', { replace: true });
      })
      .catch((err) => {
        throw new Error(err);
      });
  }, [logout, navigate]);

  const error = useMemo(
    () =>
      location.pathname.indexOf('access-denied') >= 0
        ? { button: 'home', onclick: () => navigate('/') }
        : { button: 'logout', onclick: () => _logout() },
    [_logout, location.pathname, navigate]
  );

  return (
    <ErrorPage
      buttonText={t(`pages.permissionError.${error.button}`)}
      icon={<LockOutlined data-testid="icon" sx={iconSx} color="action" />}
      reason={t('pages.permissionError.reason')}
      warning={t('pages.permissionError.warning')}
      onClick={error.onclick}
    />
  );
};

export default AdminPermissionErrorPage;
