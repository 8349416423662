import { Box, Grid, ListItemText, Theme } from '@mui/material';
import { formatHoursAsHourMin } from '@teto/react-component-library';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TimeSheet } from 'teto-client-api';
import TimesheetComments from '../../../components/TimeSheets/TimesheetComments/TimesheetComments';
import TimesheetStatusText from '../../../components/TimeSheets/TimesheetStatus/TimesheetStatusText';

const userStatusSx = (theme: Theme) => ({
  color: theme.palette.text.primary,
});

const totalHourSx = (theme: Theme) => ({
  color: theme.palette.text.primary,
  textAlign: 'right',
});

const TimesheetsDetailsSummary = (timeSheet: TimeSheet) => {
  const { employeeFullName, totalHours, statusComments } = timeSheet;
  const { t } = useTranslation();
  return (
    <Grid container>
      <Grid item xs={8} md={6}>
        <Box pl={1}>
          <ListItemText sx={userStatusSx} primary={employeeFullName} />
          <TimesheetStatusText timesheet={timeSheet} />
        </Box>
      </Grid>
      <Grid item xs={4} md={6}>
        <Box pr={1}>
          <ListItemText
            sx={totalHourSx}
            primary={t('Entities.TimeSheet.totalHours')}
            secondary={formatHoursAsHourMin(totalHours)}
          />
        </Box>
      </Grid>
      {statusComments && <TimesheetComments {...timeSheet} />}
    </Grid>
  );
};

export default TimesheetsDetailsSummary;
