import { TypeFilterValue } from '@inovua/reactdatagrid-community/types';
import { Box, SxProps, Theme } from '@mui/material';
import { ButtonStrip, ConfirmDialogs } from '@teto/react-component-library';
import { uniqueId } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FilterField from '../../Filtering/FilterField';
import TableColumnDefinition from '../../TableColumnDefinition';

const filterForm: SxProps<Theme> = (theme: Theme) => ({
  flexGrow: 1,
  overflowY: 'auto',
  paddingRight: theme.spacing(2),
  bgcolor: 'inherit',
  maxHeight: '90%',
  '& .MuiTextField-root': {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
});
const rootSx: SxProps<Theme> = () => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  maxHeight: '95%',
});

interface FilterPanelProps {
  handleClose: () => void;
  filters?: TypeFilterValue;
  // eslint-disable-next-line no-unused-vars
  setFilters?: (filter: TypeFilterValue) => void;
  filterableColumns: TableColumnDefinition[];
}

const FilteringInspector = (props: FilterPanelProps) => {
  const { t, ready } = useTranslation();
  const { handleClose, setFilters, filters, filterableColumns } = props;
  const [filterBy, setFilterBy] = useState<TypeFilterValue>([]);

  const findValue = useCallback(
    (col: TableColumnDefinition) =>
      (filterBy && filterBy.find((fv) => fv.name === col.name)?.value) ?? '',
    [filterBy]
  );

  const [resetDialog, setResetDialog] = useState<boolean>(false);

  const _onSave = () => {
    if (setFilters) setFilters(filterBy);
    handleClose();
  };

  const _handleReset = () => {
    setFilterBy([]);
    if (setFilters) setFilters([]);
    handleClose();
  };

  useEffect(() => {
    setFilterBy(filters as TypeFilterValue);
  }, [filters]);

  return (
    <>
      <Box sx={rootSx}>
        {ready && (
          <Box component="form" noValidate autoComplete="off" sx={filterForm}>
            {filterableColumns.map((col) => (
              <FilterField
                filters={filterBy}
                key={uniqueId(col.id)}
                col={col}
                setFilterBy={setFilterBy}
                defaultValue={findValue(col)}
              />
            ))}
          </Box>
        )}
        <Box
          sx={{
            flexGrow: 1,
          }}
        >
          <ButtonStrip
            size="medium"
            leftButton={{
              text: t('generic.resetGridSettings'),
              onClick: () => setResetDialog(true),
              color: 'warning',
            }}
            rightButton={{
              text: t('generic.save'),
              onClick: () => _onSave(),
              color: 'primary',
            }}
          />
        </Box>
      </Box>

      {resetDialog && (
        <ConfirmDialogs
          open={resetDialog}
          title={t('dialogs.resetFilters.title')}
          content={t('dialogs.resetFilters.content')}
          leftButton={{
            onClick: () => setResetDialog(false),
            label: t('generic.cancel'),
          }}
          rightButton={{
            onClick: () => {
              _handleReset();
              setResetDialog(false);
            },
            label: t('generic.confirm'),
          }}
        />
      )}
    </>
  );
};

export default FilteringInspector;
