import IndeterminateCheckBoxRounded from '@mui/icons-material/IndeterminateCheckBoxRounded';
import { Checkbox, Tooltip } from '@mui/material';
import React from 'react';
import FormatterProps from './FormatterProps';

// eslint-disable-next-line consistent-return
const hasAnyBitOn = (options: number[], value: number) => {
  if (value === 0) {
    return false;
  }

  // eslint-disable-next-line no-plusplus, no-unreachable-loop
  for (let i = 0; i < options.length; i++) {
    // eslint-disable-next-line no-bitwise
    if ((value & i) === i) {
      return true;
    }
    return false;
  }
};

// eslint-disable-next-line consistent-return
const hasBitOn = (option: number, value: number) => {
  if (value === 0 || option === 0) return false;
  // eslint-disable-next-line no-bitwise
  if ((value & option) === option) return true;
};

// eslint-disable-next-line react/function-component-definition
const BitwiseFormatter: React.ComponentType<FormatterProps> = (
  props: FormatterProps
) => {
  const { value, column } = props;

  const options = column.bitwiseOptions ?? {};

  // Bad, must get rid of type: anys in future iterations

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const numberOptions: any[] = Object.values(options).filter(
    (o) => typeof o === 'number'
  );

  const isLocked = hasAnyBitOn(numberOptions, value);

  const reasons = numberOptions
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .filter((o: any) => hasBitOn(o, value))
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .map((val: any) => options[val])
    .join('\r\n');

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {isLocked ? (
        <Tooltip
          title={
            <div style={{ whiteSpace: 'pre-line', textAlign: 'center' }}>
              {reasons}
            </div>
          }
          arrow
        >
          <div>
            <Checkbox
              style={{ padding: 0, display: 'block', margin: '0 auto' }}
              size="small"
              checked={isLocked ?? false}
              disabled
              indeterminate={value === undefined}
              indeterminateIcon={<IndeterminateCheckBoxRounded />}
            />
          </div>
        </Tooltip>
      ) : (
        <Checkbox
          style={{ padding: 0, display: 'block', margin: '0 auto' }}
          size="small"
          checked={isLocked ?? false}
          disabled
          indeterminate={value === undefined}
          indeterminateIcon={<IndeterminateCheckBoxRounded />}
        />
      )}
    </>
  );
};

export default BitwiseFormatter;
