import * as Yup from 'yup';

export const REQUIRED_MESSAGE = 'Required';

const YupSchema = Yup.object().shape({
  toReceive: Yup.number().min(0, REQUIRED_MESSAGE),
  numberOfLabels: Yup.number().min(0, REQUIRED_MESSAGE),
});

export default YupSchema;
