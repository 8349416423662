import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getModelMetadata, ModelMetadata, TimeCard } from 'teto-client-api';
import { AuthContext, MessageContext } from '@teto/react-component-library';
import GridPersistenceProvider from '../../../../components/TetoGrid/GridPersistence/GridPersistenceProvider';
import { createColumnsFromMetaData } from '../../../../components/TetoGrid/ModelMetaDataProcessor/ModelMetaDataProcessor';
import MonthlyTab from './MonthlyTab';

interface MonthlyTabWrapperProps {
  setViewBy?: React.Dispatch<React.SetStateAction<string>>;
  viewBy?: string;
}

const MonthlyTabWrapper = (props: MonthlyTabWrapperProps) => {
  const { setViewBy, viewBy } = props;
  const authContext = useContext(AuthContext);
  const messageContext = useContext(MessageContext);
  const { t, ready } = useTranslation();
  const [metaData, setMetaData] = useState<ModelMetadata | undefined>();
  const cols = useMemo(() => {
    if (metaData && ready) {
      return createColumnsFromMetaData<TimeCard>(metaData)
        .updateDefinition('startTime', { disableGrouping: true })
        .updateDefinition('endTime', { disableGrouping: true })
        .removeProperty(
          'custom1',
          () => t('Entities.Timecard.TimecardCustom1') === 'Timecard Custom 1'
        )
        .removeProperty(
          'custom2',
          () => t('Entities.Timecard.TimecardCustom2') === 'Timecard Custom 2'
        )
        .removeProperty(
          'custom3',
          () => t('Entities.Timecard.TimecardCustom3') === 'Timecard Custom 3'
        )
        .removeProperty(
          'custom4',
          () => t('Entities.Timecard.TimecardCustom4') === 'Timecard Custom 4'
        )
        .removeProperty(
          'custom5',
          () => t('Entities.Timecard.TimecardCustom5') === 'Timecard Custom 5'
        )
        .removeProperty(
          'custom6',
          () => t('Entities.Timecard.TimecardCustom6') === 'Timecard Custom 6'
        )
        .removeProperty(
          'custom7',
          () => t('Entities.Timecard.TimecardCustom7') === 'Timecard Custom 7'
        )
        .removeProperty(
          'custom8',
          () => t('Entities.Timecard.TimecardCustom8') === 'Timecard Custom 8'
        )
        .removeProperty(
          'pSCCustom1',
          () =>
            t(
              'Entities.Process Schedule In House Transaction.CompletedLogCustom1'
            ) === 'Process Schedule In House Transaction Custom 1'
        )
        .removeProperty(
          'pSCCustom2',
          () =>
            t(
              'Entities.Process Schedule In House Transaction.CompletedLogCustom2'
            ) === 'Process Schedule In House Transaction Custom 2'
        )
        .removeProperty(
          'pSCCustom3',
          () =>
            t(
              'Entities.Process Schedule In House Transaction.CompletedLogCustom3'
            ) === 'Process Schedule In House Transaction Custom 3'
        )
        .removeProperty(
          'pSCCustom4',
          () =>
            t(
              'Entities.Process Schedule In House Transaction.CompletedLogCustom4'
            ) === 'Process Schedule In House Transaction Custom 4'
        )
        .removeProperty(
          'pSCCustom5',
          () =>
            t(
              'Entities.Process Schedule In House Transaction.CompletedLogCustom5'
            ) === 'Process Schedule In House Transaction Custom 5'
        )
        .removeProperty(
          'pSCCustom6',
          () =>
            t(
              'Entities.Process Schedule In House Transaction.CompletedLogCustom6'
            ) === 'Process Schedule In House Transaction Custom 6'
        )
        .removeProperty(
          'pSCCustom7',
          () =>
            t(
              'Entities.Process Schedule In House Transaction.CompletedLogCustom7'
            ) === 'Process Schedule In House Transaction Custom 7'
        )
        .removeProperty(
          'pSCCustom8',
          () =>
            t(
              'Entities.Process Schedule In House Transaction.CompletedLogCustom8'
            ) === 'Process Schedule In House Transaction Custom 8'
        )
        .finalize(t);
    }
    return [];
  }, [metaData, ready, t]);

  useEffect(() => {
    if (authContext.hasProfessionalLicense()) {
      getModelMetadata('timecard')
        .then((e) => setMetaData(e))
        .catch((e) => {
          messageContext.setError(
            `${t('generic.message.failMeta')}.  ${e.message}`
          );
        });
    }
  }, [authContext, messageContext, t]);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {ready && cols && metaData && (
        <GridPersistenceProvider
          tableIdentifier="MonthlyTimeTrackerView"
          columns={cols}
          persistenceType={
            authContext.hasEnterpriseLicense() ? 'db' : 'sessionStorage'
          }
        >
          <MonthlyTab cols={cols} setViewBy={setViewBy} viewBy={viewBy} />
        </GridPersistenceProvider>
      )}
    </>
  );
};

export default MonthlyTabWrapper;
