import {
  TypeGroupBy,
  TypeSortInfo,
} from '@inovua/reactdatagrid-enterprise/types';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import UndoRoundedIcon from '@mui/icons-material/UndoRounded';
import { SxProps, Theme, useMediaQuery, useTheme } from '@mui/material';
import {
  AuthContext,
  SettingsContext,
  WeeklyDateSelector,
} from '@teto/react-component-library';
import dayjs from 'dayjs';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { QueryFunctionContext, QueryKey } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  getApprovableTimeSheets,
  getTimeSheets,
  Licenses,
  Permission,
  TimeSheet,
} from 'teto-client-api';

import TIMESHEET_QUERY_ID from '../../../api/reactQuery/TimesheetsQueryId';
import ApproveMultipleTsheetsButton from '../../../components/Buttons/ApproveButtons/ApproveMultipleTsheetsButton';
import ExportButton from '../../../components/Buttons/ExportButton/ExportButton';
import {
  AddColumn,
  createColumns,
} from '../../../components/TetoGrid/ModelMetaDataProcessor/ModelMetaDataProcessor';
import RDGSelectedType from '../../../components/TetoGrid/RDGSelectedType';

import TetoGrid from '../../../components/TetoGrid/TetoGrid';
import TetoGridRefType from '../../../components/TetoGrid/TetoGridRefType';
import TimesheetInspectorWrapper from '../../../components/TimeSheets/TimesheetInspector/TimesheetInspector';
import TimesheetStatusButton from '../../../components/TimeSheets/TimesheetStatus/TimesheetStatusButton';
import { DateRange } from '../../../helpers/dateTypes';
import defaultDateRange from '../../../helpers/defaultDateRange';
import ActionButton from '../ApprovalsPage/components/ActionButton/ActionButton';
import isApprovalDisabled from '../ApprovalsPage/helpers/isApprovalDisabled';
import TimesheetSelection from '../TimesheetsDetailsPage/TimesheetsDetailPageCommonTypes';

const wrapperSx = (theme: Theme) => ({
  width: 'fit-content',
  '& .MuiSvgIcon-root': {
    fontSize: theme.spacing(3.5),
  },
  '& .MuiTextField-root': {
    width: theme.spacing(24),
  },
});

const fabContainerSx = (theme: Theme) => ({
  bottom: theme.spacing(6.5),
  position: 'fixed',
  right: theme.spacing(2),
  zIndex: 10,
});

const successIconSx = (theme: Theme) => ({
  color: theme.palette.success.main,
});

const toolbarTileSx = {
  '& h4.MuiTypography-root.MuiTypography-h4.MuiTypography-alignCenter.MuiDatePickerToolbar-title.css-3jvy96-MuiTypography-root-MuiDatePickerToolbar-title':
    {
      fontSize: '1.25rem',
    },
};

interface TimesheetsWeeklyTabProps {
  pageName: 'approvals' | 'timesheets';
  approvalsDisabled?: boolean;
  checkBox?: boolean;
  disableDateRange?: boolean;
  defaultGrouping?: TypeGroupBy;
  defaultSort?: TypeSortInfo;
  disableGroupByToolbar?: boolean;
  enableGroupColumn?: boolean;
}

const TimesheetsWeeklyTab = (props: TimesheetsWeeklyTabProps) => {
  const {
    checkBox,
    pageName,
    approvalsDisabled,
    disableDateRange,
    defaultGrouping,
    defaultSort,
    disableGroupByToolbar,
    enableGroupColumn,
  } = props;

  const { t, ready } = useTranslation();
  const [dataSource, setDataSource] = useState([]);
  const theme = useTheme();
  const mobileSize = useMediaQuery(theme.breakpoints.down('md'));
  const TABLE_ID = `${t('Entities.TimeSheet.tableIdentifier')}-${pageName}`;
  const queryIdentifier = TIMESHEET_QUERY_ID;
  const { startDate, endDate } = useParams();
  const browserLocation = useLocation();
  const navigate = useNavigate();
  const gridRef = React.useRef<TetoGridRefType | undefined>();

  const authContext = useContext(AuthContext);
  const settingsContext = useContext(SettingsContext);
  const canAccessTimesheets =
    authContext.hasAnyLicense() &&
    (authContext.hasAnyPermission([
      Permission.Modify_Admin_Timecards,
      Permission.View_Admin_Timecards,
    ]) ||
      (settingsContext.settings.approverCanManageTime &&
        authContext.user?.isApprover));

  const [selectedRows, setSelectedRows] = useState<RDGSelectedType>();
  // eslint-disable-next-line no-unused-vars
  const [refreshToken, setRefreshToken] = useState<Date | undefined>();
  const [selectedTimesheet, setSelectedTimesheet] =
    useState<TimesheetSelection | undefined>();

  useEffect(() => {
    // Temporary solution, should generalize this.
    if (!canAccessTimesheets) navigate('/access-denied', { replace: true });
    if (!startDate || !endDate) {
      const defaultRange = defaultDateRange(
        settingsContext.settings.startDayOfWeek
      );
      navigate(
        `/timeTracking/${pageName}/week/${defaultRange.start.format(
          'YYYY-MM-DD'
        )}/${defaultRange.end.format('YYYY-MM-DD')}`,
        { replace: true }
      );
    }
  }, [
    authContext,
    browserLocation.key,
    canAccessTimesheets,
    endDate,
    navigate,
    pageName,
    settingsContext.settings.startDayOfWeek,
    startDate,
  ]);

  // eslint-disable-next-line consistent-return
  const getScreenSize = useCallback(() => {
    const screenSize = window.innerWidth;

    if (screenSize <= theme.breakpoints.values.xs) {
      return 150;
    }
    if (screenSize <= theme.breakpoints.values.sm) {
      return 150;
    }
    if (screenSize <= theme.breakpoints.values.md) {
      return 200;
    }
    if (screenSize < theme.breakpoints.values.lg) {
      return 300;
    }
    if (screenSize > theme.breakpoints.values.xl) {
      return 400;
    }
  }, [
    theme.breakpoints.values.lg,
    theme.breakpoints.values.md,
    theme.breakpoints.values.sm,
    theme.breakpoints.values.xl,
    theme.breakpoints.values.xs,
  ]);

  useEffect(() => {
    getScreenSize();
  }, [getScreenSize]);

  const _buildTimeColumn = useCallback(
    (index: number, startDayOfWeek: number): AddColumn => {
      const dayOfWeek = t(
        `Entities.TimeSheet.${
          index + startDayOfWeek > 6
            ? startDayOfWeek + index - 7
            : index + startDayOfWeek
        }`
      );
      const dateOfWeek = dayjs(startDate).add(index, 'day').format('D');
      return {
        name: `${index}`,
        title: `${dayOfWeek} ${dateOfWeek}`,
        type: 'hours',
        align: 'center',
        sortable: false,
        filterType: '',
        filterOptions: undefined,
        fixed: 'none',
        disableGrouping: true,
        disableColumnFilterContextMenu: true,
        disableColumnMenuTool: false,
        width: 100,
      };
    },
    [t, startDate]
  );

  const cols = useMemo(() => {
    if (
      ready &&
      startDate &&
      endDate &&
      authContext.user?.isApprover &&
      !approvalsDisabled
    ) {
      return createColumns<TimeSheet>()
        .addColumn({
          name: 'employeeFullName',
          title: t('Entities.Employee.fullName'),
          type: 'string',
          align: 'start',
          sortable: true,
          filterType: 'string',
          filterOptions: 'simple',
          fixed: 'none',
          disableGrouping: true,
          disableColumnMenuTool: false,
          disableHideable: true,
          width: getScreenSize(),
        })
        .addColumn({
          name: 'dateRange',
          type: 'string',
          hidden: true,
          sortable: false,
          title: 'Date',
          align: 'start',
          filterType: 'string',
          fixed: 'none',
        })
        .addColumn(_buildTimeColumn(0, settingsContext.settings.startDayOfWeek))
        .addColumn(_buildTimeColumn(1, settingsContext.settings.startDayOfWeek))
        .addColumn(_buildTimeColumn(2, settingsContext.settings.startDayOfWeek))
        .addColumn(_buildTimeColumn(3, settingsContext.settings.startDayOfWeek))
        .addColumn(_buildTimeColumn(4, settingsContext.settings.startDayOfWeek))
        .addColumn(_buildTimeColumn(5, settingsContext.settings.startDayOfWeek))
        .addColumn(_buildTimeColumn(6, settingsContext.settings.startDayOfWeek))
        .addColumn({
          name: 'totalHours',
          title: t('Entities.TimeSheet.totalHours'),
          type: 'hours',
          align: 'center',
          sortable: false,
          filterType: '',
          filterOptions: undefined,
          fixed: 'none',
          disableGrouping: true,
          disableColumnFilterContextMenu: true,
          disableColumnMenuTool: true,
          width: 100,
        })
        .addColumn({
          name: 'status',
          title: t('Entities.TimeSheet.status'),
          type: 'custom',
          align: 'center',
          sortable: true,
          filterType: 'string',
          filterOptions: 'simple',
          fixed: 'none',
          disableGrouping: true,
          disableColumnMenuTool: true,
          width: 150,
          renderFunction: (data: TimeSheet) => TimesheetStatusButton(data),
        })
        .addColumn({
          name: 'action',
          title: t('generic.action'),
          type: 'button',
          align: mobileSize ? 'center' : 'end',
          sortable: false,
          editable: false,
          filterType: 'none',
          fixed: 'right',
          disableGrouping: true,
          disableHideable: true,
          disableColumnMenuTool: true,
          width: mobileSize ? 60 : 150,
          headerProps: {
            style: {
              textAlign: 'center',
            },
          },
          renderFunction: (data: TimeSheet) => (
            <ActionButton
              data={data}
              actionItems={[
                ...(data.status === 'Submitted'
                  ? [
                      {
                        componentName: t('Entities.TimeSheet.approve'),
                        icon: <CheckRoundedIcon sx={successIconSx} />,
                        title: t('Entities.TimeSheet.approve'),
                        disabled: !authContext.hasLicense(
                          Licenses.TotalETOEnterprise
                        ),
                        handleClick: () =>
                          setSelectedTimesheet({
                            timesheet: data,
                            action: 'approve',
                          }),
                      },
                    ]
                  : []),
                ...(data.status === 'Submitted'
                  ? [
                      {
                        componentName: t('Entities.TimeSheet.reject'),
                        icon: <CloseRoundedIcon color="error" />,
                        title: t('Entities.TimeSheet.reject'),
                        disabled: !authContext.hasLicense(
                          Licenses.TotalETOEnterprise
                        ),
                        handleClick: () =>
                          setSelectedTimesheet({
                            timesheet: data,
                            action: 'reject',
                          }),
                      },
                    ]
                  : []),
                ...(data.status === 'Approved'
                  ? [
                      {
                        componentName: t('Entities.TimeSheet.unapprove'),
                        icon: <UndoRoundedIcon color="action" />,
                        title: t('Entities.TimeSheet.unapprove'),
                        disabled: !authContext.hasLicense(
                          Licenses.TotalETOEnterprise
                        ),
                        handleClick: () =>
                          setSelectedTimesheet({
                            timesheet: data,
                            action: 'unapprove',
                          }),
                      },
                    ]
                  : []),
                ...(data.status === 'Rejected'
                  ? [
                      {
                        componentName: t('Entities.TimeSheet.unreject'),
                        icon: <UndoRoundedIcon color="action" />,
                        title: t('Entities.TimeSheet.unreject'),
                        disabled: !authContext.hasLicense(
                          Licenses.TotalETOEnterprise
                        ),
                        handleClick: () =>
                          setSelectedTimesheet({
                            timesheet: data,
                            action: 'unreject',
                          }),
                      },
                    ]
                  : []),
                {
                  componentName: t('Entities.TimeSheet.viewDetails'),
                  icon: <ArrowForwardIosRoundedIcon color="primary" />,
                  title: t('Entities.TimeSheet.viewDetails'),
                  handleClick: () =>
                    navigate(`/timeTracking/timesheets/${data.id}`, {
                      state: browserLocation.pathname,
                    }),
                },
              ]}
            />
          ),
        })
        .finalize(t);
    }
    if (ready && startDate && endDate) {
      return createColumns<TimeSheet>()
        .addColumn({
          name: 'employeeFullName',
          title: t('Entities.Employee.fullName'),
          type: 'string',
          align: 'start',
          sortable: true,
          filterType: 'string',
          filterOptions: 'simple',
          fixed: 'none',
          disableGrouping: true,
          disableColumnMenuTool: false,
          disableHideable: true,
          width: getScreenSize(),
        })
        .addColumn(_buildTimeColumn(0, settingsContext.settings.startDayOfWeek))
        .addColumn(_buildTimeColumn(1, settingsContext.settings.startDayOfWeek))
        .addColumn(_buildTimeColumn(2, settingsContext.settings.startDayOfWeek))
        .addColumn(_buildTimeColumn(3, settingsContext.settings.startDayOfWeek))
        .addColumn(_buildTimeColumn(4, settingsContext.settings.startDayOfWeek))
        .addColumn(_buildTimeColumn(5, settingsContext.settings.startDayOfWeek))
        .addColumn(_buildTimeColumn(6, settingsContext.settings.startDayOfWeek))
        .addColumn({
          name: 'totalHours',
          title: t('Entities.TimeSheet.totalHours'),
          type: 'hours',
          align: 'center',
          sortable: false,
          filterType: '',
          filterOptions: undefined,
          fixed: 'none',
          disableGrouping: true,
          disableColumnFilterContextMenu: true,
          disableColumnMenuTool: true,
          width: 100,
        })
        .addColumn({
          name: 'status',
          title: t('Entities.TimeSheet.status'),
          type: 'custom',
          align: 'center',
          sortable: true,
          filterType: 'string',
          filterOptions: 'simple',
          fixed: 'none',
          disableGrouping: true,
          disableColumnMenuTool: true,
          width: 150,
          renderFunction: (data: TimeSheet) => TimesheetStatusButton(data),
        })
        .addViewButton((data) =>
          navigate(`/timeTracking/${pageName}/${data.id}`, {
            state: browserLocation.pathname,
          })
        )
        .finalize(t);
    }
    return [];
  }, [
    ready,
    startDate,
    endDate,
    approvalsDisabled,
    t,
    getScreenSize,
    _buildTimeColumn,
    settingsContext.settings.startDayOfWeek,
    mobileSize,
    navigate,
    browserLocation.pathname,
    pageName,
    authContext,
  ]);

  function _doQuery(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    context: QueryFunctionContext<QueryKey, any>,
    pageSize: number,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    queryData: { filters: any; orderBy: any } & { params: DateRange }
  ) {
    const handleColumnsMap = (records: TimeSheet[]) => {
      const newCols = records.map((r: TimeSheet) => ({
        ...r,
        0: r.dailyHours[0]?.totalHours,
        1: r.dailyHours[1]?.totalHours,
        2: r.dailyHours[2]?.totalHours,
        3: r.dailyHours[3]?.totalHours,
        4: r.dailyHours[4]?.totalHours,
        5: r.dailyHours[5]?.totalHours,
        6: r.dailyHours[6]?.totalHours,
        dateRange: `${r.startDate.format(
          settingsContext.settings.dateFormat
        )} - ${r.endDate.format(settingsContext.settings.dateFormat)}`,
      }));
      return newCols;
    };
    const defaultQuery = {
      pageIndex: context.pageParam,
      pageSize,
      disablePaging: false,
      orderBy: queryData.orderBy,
      filter: disableDateRange
        ? {
            ...queryData.filters,
          }
        : {
            startDate: dayjs(startDate),
            endDate: dayjs(endDate),
            ...queryData.filters,
          },
    };
    if (
      authContext.user?.isApprover &&
      !approvalsDisabled &&
      authContext.user?.id
    ) {
      return getApprovableTimeSheets(authContext.user?.id, defaultQuery).then(
        (d) => ({
          ...d,
          records: handleColumnsMap(d.records),
        })
      );
    }
    return getTimeSheets(defaultQuery).then((d) => ({
      ...d,
      records: handleColumnsMap(d.records),
    }));
  }

  const _handleClose = () => {
    setSelectedTimesheet(undefined);
  };

  const approvalDisabledResult = isApprovalDisabled(selectedRows);

  const ApproveBtn =
    !approvalsDisabled &&
    ApproveMultipleTsheetsButton({
      selectedRows,
      setSelectedRows,
      approvalDisabled: approvalDisabledResult,
    });

  const ExportBtn = ExportButton({ gridRef });

  return (
    <>
      {ready && cols && startDate && endDate && (
        <TetoGrid
          dataSource={dataSource}
          setDataSource={setDataSource}
          disableMobileCols
          serverSideFiltering
          withBottomNav
          ref={gridRef}
          pageSize={50000}
          defaultGrouping={defaultGrouping}
          defaultSort={defaultSort}
          disableGroupByToolbar={disableGroupByToolbar}
          enableGroupColumn={enableGroupColumn}
          disableConfigureButton
          // eslint-disable-next-line react/jsx-no-bind
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          doQuery={(a, b, c) => _doQuery(a, b, c as any)}
          columns={cols}
          refreshToken={refreshToken}
          checkboxColumn={checkBox ?? true}
          externalQueryProps={{ startDate, endDate }}
          header={{
            leftChildren: !disableDateRange && (
              <WeeklyDateSelector
                data-testid="week-selector"
                onChange={(e) =>
                  navigate(
                    `/timeTracking/${pageName}/week/${e.start.format(
                      'YYYY-MM-DD'
                    )}/${e.end.format('YYYY-MM-DD')}`
                  )
                }
                weekStart={settingsContext.settings.startDayOfWeek}
                inputFormat={settingsContext.settings.dateFormat}
                value={startDate as unknown as Date}
                containerSx={wrapperSx as SxProps}
                datePickerProps={{
                  DialogProps: {
                    sx: toolbarTileSx,
                  },
                }}
              />
            ),
            rightChildren: [ApproveBtn, ExportBtn],
            FABPosition: fabContainerSx,
          }}
          tableIdentifier={TABLE_ID}
          queryIdentifier={queryIdentifier}
          setSelectedRows={setSelectedRows}
          selectedRows={selectedRows}
          enableRowExpand={false}
        />
      )}
      {selectedTimesheet && (
        <TimesheetInspectorWrapper
          timesheet={selectedTimesheet.timesheet}
          open={Boolean(selectedTimesheet)}
          onClose={_handleClose}
          tableIdentifier={TABLE_ID}
          action={selectedTimesheet.action}
        />
      )}
    </>
  );
};

export default TimesheetsWeeklyTab;
