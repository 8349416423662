import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import {
  Box,
  darken,
  SxProps,
  Theme,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  AuthContext,
  MessageContext,
  SettingsContext,
  WeeklyDateSelector,
} from '@teto/react-component-library';
import dayjs from 'dayjs';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { QueryFunctionContext, QueryKey } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Licenses,
  ModelMetadata,
  PagedResponse,
  Permission,
} from 'teto-client-api';
import { v4 as uuid } from 'uuid';
import {
  AddColumn,
  createColumns,
} from '../../components/TetoGrid/ModelMetaDataProcessor/ModelMetaDataProcessor';
import RDGSelectedType from '../../components/TetoGrid/RDGSelectedType';
import TetoContainer from '../../components/TetoGrid/TetoContainer';
import TetoGrid from '../../components/TetoGrid/TetoGrid';
import TetoGridRefType from '../../components/TetoGrid/TetoGridRefType';
import ClockInTimerContext from '../../components/TimeSheets/NavTimer/ClockInContext';
import { formatHoursAsHourMin } from '../../helpers/dateHelpers';
import defaultDateRange from '../../helpers/defaultDateRange';
import { getGraphQLClient } from '../../helpers/graphQL/graphQLClient';
import ClockIn from './ClockIn';
import AddClockInInspector from './components/AddClockInInspector';
import RowDetails from './components/RowDetails';
import {
  getClockInColumnName,
  getClockOutColumnName,
  getTotalColumnName,
} from './helpers/getClockColProps';
import getClockInsByDateQuery from './queries/getClockInsByDateQuery';

const fabContainerSx = (theme: Theme) => ({
  bottom: theme.spacing(6.5),
  position: 'fixed',
  right: theme.spacing(2),
  zIndex: 10,
});

const groupHeaderSx = (theme: Theme) => ({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  backgroundColor: darken(theme.palette.primary.main, 0.2),
  color: theme.palette.getContrastText(darken(theme.palette.primary.main, 0.2)),
});

const tableWrapSx = (theme: Theme) => ({
  '& .InovuaReactDataGrid__column-header__filter-wrapper': {
    borderTop: 'none',
    padding: `${theme.spacing(0)} !important`,
  },
  '& .InovuaReactDataGrid__row .InovuaReactDataGrid__row-details.InovuaReactDataGrid__row-details--details-grid':
    {
      padding: `${theme.spacing(1)} !important`,
    },
});

const wrapperSx = (theme: Theme) => ({
  width: 'fit-content',
  '& .MuiSvgIcon-root': {
    fontSize: theme.spacing(3.5),
  },
  '& .MuiTextField-root': {
    width: theme.spacing(24),
  },
});

const toolbarTileSx = {
  '& h4.MuiTypography-root.MuiTypography-h4.MuiTypography-alignCenter.MuiDatePickerToolbar-title.css-3jvy96-MuiTypography-root-MuiDatePickerToolbar-title':
    {
      fontSize: '1.25rem',
    },
};

interface RowUpdateIds {
  [key: number]: string;
}

const pageName = 'clock-ins';

const ClockInsPage = () => {
  const { t, ready } = useTranslation();

  const navigate = useNavigate();
  const { startDate, endDate } = useParams();

  const authContext = useContext(AuthContext);
  const messageContext = useContext(MessageContext);
  const settingsContext = useContext(SettingsContext);
  const clockInContext = useContext(ClockInTimerContext);

  const theme = useTheme();
  const mobileSize = useMediaQuery(theme.breakpoints.down('md'));

  const [refreshToken, setRefreshToken] = useState<Date | undefined>();
  const [metaData, setMetaData] = useState<ModelMetadata | undefined>();
  const [selectedRows, setSelectedRows] = useState<RDGSelectedType>();
  const [dataSource, setDataSource] = useState([]);
  const [expandedRows, setExpandedRows] = useState<{ [key: string]: boolean }>(
    () => ({})
  );
  const [editedRowIds, setEditedRowIds] =
    useState<RowUpdateIds | undefined>(undefined);

  const [expandedRowData, setExpandedRowData] =
    useState<ClockIn[] | undefined>();
  const [addClockInInspector, setAddClockInInspector] =
    useState<boolean>(false);

  const gridRef = useRef<TetoGridRefType | undefined>();

  const canAccessClockIns =
    authContext.hasEnterpriseLicense() &&
    (authContext.hasAnyPermission([
      Permission.View_Admin_Timecards,
      Permission.Modify_Admin_Timecards,
    ]) ||
      (settingsContext.settings.approverCanManageTime &&
        authContext.user?.isApprover));

  const query = `query getMetadata($typeName: String!) {
    __type(name: $typeName) {
      fields {
        name
        order
        objectType
        title
        isPrimaryKey
        sortable
        filterType
        filterOptions
        defaultVisible
        defaultWidth
        columnGrouping
        groupingAggregate
      }
    }
  }`;

  const variables = useMemo(
    () => ({
      typeName: 'PurchaseOrderDetail',
    }),
    []
  );

  useEffect(() => {
    getGraphQLClient()
      .performQueryAsColumnMetadata(
        query,
        variables,
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        (err) => {
          messageContext.setError(
            `${t('generic.message.failMeta')}.  ${err.messages[0]}`
          );
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {
          // @TODO Handle Validation
        }
      )
      .then((result) => {
        setMetaData(result);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, variables]);

  useEffect(() => {
    // Temporary solution, should generalize this.
    if (!canAccessClockIns) navigate('/access-denied', { replace: true });
    if (!startDate || !endDate) {
      const defaultRange = defaultDateRange(
        settingsContext.settings.startDayOfWeek
      );
      navigate(
        `/timeTracking/${pageName}/${defaultRange.start.format(
          'YYYY-MM-DD'
        )}/${defaultRange.end.format('YYYY-MM-DD')}`,
        { replace: true }
      );
    }
  }, [
    authContext,
    canAccessClockIns,
    endDate,
    navigate,
    settingsContext.settings.startDayOfWeek,
    startDate,
  ]);

  const { startDayOfWeek } = settingsContext.settings;

  const getGroupTitle = useCallback(
    (index: number) =>
      `${t(
        `Entities.TimeSheet.${
          index + startDayOfWeek > 6
            ? startDayOfWeek + index - 7
            : index + startDayOfWeek
        }`
      )} ${dayjs(startDate).add(index, 'day').format('D')}`,
    [startDate, startDayOfWeek, t]
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const daysOfWeekGroups: any = useMemo(
    () => [
      {
        name: 'sunday',
        header: <Box sx={groupHeaderSx}>{getGroupTitle(0)}</Box>,
      },
      {
        name: 'monday',
        header: <Box sx={groupHeaderSx}>{getGroupTitle(1)}</Box>,
      },
      {
        name: 'tuesday',
        header: <Box sx={groupHeaderSx}>{getGroupTitle(2)}</Box>,
      },
      {
        name: 'wednesday',
        header: <Box sx={groupHeaderSx}>{getGroupTitle(3)}</Box>,
      },
      {
        name: 'thursday',
        header: <Box sx={groupHeaderSx}>{getGroupTitle(4)}</Box>,
      },
      {
        name: 'friday',
        header: <Box sx={groupHeaderSx}>{getGroupTitle(5)}</Box>,
      },
      {
        name: 'saturday',
        header: <Box sx={groupHeaderSx}>{getGroupTitle(6)}</Box>,
      },
    ],
    [getGroupTitle]
  );

  const getScreenSize = useCallback(() => {
    const screenSize = window.innerWidth;
    if (screenSize > theme.breakpoints.values.xl) {
      return 100;
    }
    return 80;
  }, [theme.breakpoints.values.xl]);

  const _buildClockInColumn = useCallback(
    (index: number): AddColumn => ({
      name: getClockInColumnName(index).name,
      title: t('Entities.ClockIns.clockIn'),
      type: 'custom',
      align: 'center',
      sortable: false,
      filterType: '',
      filterOptions: undefined,
      fixed: 'none',
      disableGrouping: true,
      disableColumnFilterContextMenu: true,
      disableColumnMenuTool: true,
      width: getScreenSize(),
      group: getClockInColumnName(index).group,
      emptyDisplay: '-',
      renderFunction: (data) => {
        const { name } = getClockInColumnName(index);
        if (data[index][name]) {
          return (
            <span>
              {dayjs(data[index][name]).format(
                settingsContext.settings.timeFormat
              )}
            </span>
          );
        }
        return <span>-</span>;
      },
    }),
    [getScreenSize, settingsContext.settings.timeFormat, t]
  );

  const _buildClockOutColumn = useCallback(
    (index: number): AddColumn => ({
      name: getClockOutColumnName(index).name,
      title: t('Entities.ClockIns.clockOut'),
      type: 'custom',
      align: 'center',
      sortable: false,
      filterType: '',
      filterOptions: undefined,
      fixed: 'none',
      disableGrouping: true,
      disableColumnFilterContextMenu: true,
      disableColumnMenuTool: true,
      width: getScreenSize(),
      group: getClockOutColumnName(index).group,
      emptyDisplay: '-',
      renderFunction: (data) => {
        const { name } = getClockOutColumnName(index);
        if (data[index][name]) {
          return (
            <span>
              {dayjs(data[index][name]).format(
                settingsContext.settings.timeFormat
              )}
            </span>
          );
        }
        return <span>-</span>;
      },
    }),
    [getScreenSize, settingsContext.settings.timeFormat, t]
  );

  const _buildTotalHoursColumn = useCallback(
    (index: number): AddColumn => ({
      name: getTotalColumnName(index).name,
      title: t('generic.total'),
      type: 'custom',
      align: 'center',
      sortable: false,
      filterType: '',
      filterOptions: undefined,
      fixed: 'none',
      disableGrouping: true,
      disableColumnFilterContextMenu: true,
      disableColumnMenuTool: true,
      width: 65,
      group: getTotalColumnName(index).group,
      emptyDisplay: '-',
      renderFunction: (data) => {
        const { name } = getTotalColumnName(index);
        if (data[index][name]) {
          return <span>{formatHoursAsHourMin(data[index][name] / 60)}</span>;
        }
        return <span>-</span>;
      },
    }),
    [t]
  );

  const _onAddClockIn = () => {
    setAddClockInInspector(true);
  };

  const AddClockInBtn = {
    title: t('Entities.ClockIns.addClockIn'),
    icon: <AddCircleRoundedIcon />,
    onclick: () => _onAddClockIn(),
    disabled:
      !authContext.hasLicense(Licenses.TotalETOEnterprise) ||
      !authContext.hasAnyPermission([
        Permission.Modify_Admin_Timecards,
        Permission.Add_Admin_Timecards,
      ]),
  };

  const cols = useMemo(() => {
    if (metaData && ready) {
      return createColumns<ClockIn>()
        .addColumn({
          name: 'employee',
          title: t('Entities.Employee.fullName'),
          type: 'custom',
          align: 'start',
          editable: false,
          sortable: false,
          filterType: 'string',
          filterOptions: 'simple',
          disableColumnMenuTool: true,
          fixed: 'left',
          minWidth: mobileSize ? 135 : 180,
          flex: 1,
          renderFunction: (data) => <span>{data.employee.name}</span>,
        })
        .addColumn(_buildClockInColumn(0))
        .addColumn(_buildClockOutColumn(0))
        .addColumn(_buildTotalHoursColumn(0))
        .addColumn(_buildClockInColumn(1))
        .addColumn(_buildClockOutColumn(1))
        .addColumn(_buildTotalHoursColumn(1))
        .addColumn(_buildClockInColumn(2))
        .addColumn(_buildClockOutColumn(2))
        .addColumn(_buildTotalHoursColumn(2))
        .addColumn(_buildClockInColumn(3))
        .addColumn(_buildClockOutColumn(3))
        .addColumn(_buildTotalHoursColumn(3))
        .addColumn(_buildClockInColumn(4))
        .addColumn(_buildClockOutColumn(4))
        .addColumn(_buildTotalHoursColumn(4))
        .addColumn(_buildClockInColumn(5))
        .addColumn(_buildClockOutColumn(5))
        .addColumn(_buildTotalHoursColumn(5))
        .addColumn(_buildClockInColumn(6))
        .addColumn(_buildClockOutColumn(6))
        .addColumn(_buildTotalHoursColumn(6))
        .finalize(t);
    }
    return [];
  }, [
    metaData,
    ready,
    mobileSize,
    _buildClockInColumn,
    _buildClockOutColumn,
    _buildTotalHoursColumn,
    t,
  ]);

  const _doQuery = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-unused-vars
    context: QueryFunctionContext<QueryKey, any>,
    // eslint-disable-next-line no-unused-vars
    pageSize: number,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-unused-vars
    queryData: { filters: any; orderBy: any }
  ) =>
    getGraphQLClient()
      .performQuery(
        getClockInsByDateQuery,
        {
          startDate,
          endDate,
        },
        (err) => {
          messageContext.setError(err.messages[0]);
        },
        () => {
          // @TODO Handle Validation
        }
      )
      .then((dr) => {
        const { items } = dr.clockInsByDay;

        setExpandedRowData(items);
        let updatedRowId = {};

        const itemsMapped = Object.values(
          items.reduce(
            (
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              c: { [key: string]: any },
              e: { employee: { name: string | number } }
            ) => {
              // eslint-disable-next-line no-param-reassign
              if (!c[e.employee.name]) c[e.employee.name] = e;
              return c;
            },
            {}
          )
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ).map((h: any) => {
          const uniqueId = uuid();
          if (
            editedRowIds &&
            Object.prototype.hasOwnProperty.call(editedRowIds, h.employee.id)
          ) {
            updatedRowId = { ...updatedRowId, uniqueId: true };
          }

          return {
            employee: h.employee,
            id: uniqueId,
            clockInsId: h.employee.id,
            startTime: h.startTime,
            endTime: h.endTime,
            0: {
              date: dayjs(startDate),
              sundayClockIn:
                items.filter(
                  (item: ClockIn) =>
                    dayjs(
                      item.date.substring(0, item.date.lastIndexOf('-'))
                    ).isSame(dayjs(startDate)) &&
                    item.employee.id === h.employee.id
                ).length > 0
                  ? items.filter(
                      (item: ClockIn) =>
                        dayjs(
                          item.date.substring(0, item.date.lastIndexOf('-'))
                        ).isSame(dayjs(startDate)) &&
                        item.employee.id === h.employee.id
                    )[0].startTime
                  : undefined,
              sundayClockOut:
                items.filter(
                  (item: ClockIn) =>
                    dayjs(
                      item.date.substring(0, item.date.lastIndexOf('-'))
                    ).isSame(dayjs(startDate)) &&
                    item.employee.id === h.employee.id
                ).length > 0
                  ? items.filter(
                      (item: ClockIn) =>
                        dayjs(
                          item.date.substring(0, item.date.lastIndexOf('-'))
                        ).isSame(dayjs(startDate)) &&
                        item.employee.id === h.employee.id
                    )[0].endTime
                  : undefined,
              sundayTotal:
                items.filter(
                  (item: ClockIn) =>
                    dayjs(
                      item.date.substring(0, item.date.lastIndexOf('-'))
                    ).isSame(dayjs(startDate)) &&
                    item.employee.id === h.employee.id
                ).length > 0
                  ? items.filter(
                      (item: ClockIn) =>
                        dayjs(
                          item.date.substring(0, item.date.lastIndexOf('-'))
                        ).isSame(dayjs(startDate)) &&
                        item.employee.id === h.employee.id
                    )[0].workingDuration
                  : undefined,
            },
            1: {
              date: dayjs(startDate).add(1, 'day'),
              mondayClockIn:
                items.filter(
                  (item: ClockIn) =>
                    dayjs(
                      item.date.substring(0, item.date.lastIndexOf('-'))
                    ).isSame(dayjs(startDate).add(1, 'day')) &&
                    item.employee.id === h.employee.id
                ).length > 0
                  ? items.filter(
                      (item: ClockIn) =>
                        dayjs(
                          item.date.substring(0, item.date.lastIndexOf('-'))
                        ).isSame(dayjs(startDate).add(1, 'day')) &&
                        item.employee.id === h.employee.id
                    )[0].startTime
                  : undefined,
              mondayClockOut:
                items.filter(
                  (item: ClockIn) =>
                    dayjs(
                      item.date.substring(0, item.date.lastIndexOf('-'))
                    ).isSame(dayjs(startDate).add(1, 'day')) &&
                    item.employee.id === h.employee.id
                ).length > 0
                  ? items.filter(
                      (item: ClockIn) =>
                        dayjs(
                          item.date.substring(0, item.date.lastIndexOf('-'))
                        ).isSame(dayjs(startDate).add(1, 'day')) &&
                        item.employee.id === h.employee.id
                    )[0].endTime
                  : undefined,
              mondayTotal:
                items.filter(
                  (item: ClockIn) =>
                    dayjs(
                      item.date.substring(0, item.date.lastIndexOf('-'))
                    ).isSame(dayjs(startDate).add(1, 'day')) &&
                    item.employee.id === h.employee.id
                ).length > 0
                  ? items.filter(
                      (item: ClockIn) =>
                        dayjs(
                          item.date.substring(0, item.date.lastIndexOf('-'))
                        ).isSame(dayjs(startDate).add(1, 'day')) &&
                        item.employee.id === h.employee.id
                    )[0].workingDuration
                  : undefined,
            },
            2: {
              date: dayjs(startDate).add(2, 'day'),
              tuesdayClockIn:
                items.filter(
                  (item: ClockIn) =>
                    dayjs(
                      item.date.substring(0, item.date.lastIndexOf('-'))
                    ).isSame(dayjs(startDate).add(2, 'day')) &&
                    item.employee.id === h.employee.id
                ).length > 0
                  ? items.filter(
                      (item: ClockIn) =>
                        dayjs(
                          item.date.substring(0, item.date.lastIndexOf('-'))
                        ).isSame(dayjs(startDate).add(2, 'day')) &&
                        item.employee.id === h.employee.id
                    )[0].startTime
                  : undefined,
              tuesdayClockOut:
                items.filter(
                  (item: ClockIn) =>
                    dayjs(
                      item.date.substring(0, item.date.lastIndexOf('-'))
                    ).isSame(dayjs(startDate).add(2, 'day')) &&
                    item.employee.id === h.employee.id
                ).length > 0
                  ? items.filter(
                      (item: ClockIn) =>
                        dayjs(
                          item.date.substring(0, item.date.lastIndexOf('-'))
                        ).isSame(dayjs(startDate).add(2, 'day')) &&
                        item.employee.id === h.employee.id
                    )[0].endTime
                  : undefined,
              tuesdayTotal:
                items.filter(
                  (item: ClockIn) =>
                    dayjs(
                      item.date.substring(0, item.date.lastIndexOf('-'))
                    ).isSame(dayjs(startDate).add(2, 'day')) &&
                    item.employee.id === h.employee.id
                ).length > 0
                  ? items.filter(
                      (item: ClockIn) =>
                        dayjs(
                          item.date.substring(0, item.date.lastIndexOf('-'))
                        ).isSame(dayjs(startDate).add(2, 'day')) &&
                        item.employee.id === h.employee.id
                    )[0].workingDuration
                  : undefined,
            },
            3: {
              date: dayjs(startDate).add(3, 'day'),
              wednesdayClockIn:
                items.filter(
                  (item: ClockIn) =>
                    dayjs(
                      item.date.substring(0, item.date.lastIndexOf('-'))
                    ).isSame(dayjs(startDate).add(3, 'day')) &&
                    item.employee.id === h.employee.id
                ).length > 0
                  ? items.filter(
                      (item: ClockIn) =>
                        dayjs(
                          item.date.substring(0, item.date.lastIndexOf('-'))
                        ).isSame(dayjs(startDate).add(3, 'day')) &&
                        item.employee.id === h.employee.id
                    )[0].startTime
                  : undefined,
              wednesdayClockOut:
                items.filter(
                  (item: ClockIn) =>
                    dayjs(
                      item.date.substring(0, item.date.lastIndexOf('-'))
                    ).isSame(dayjs(startDate).add(3, 'day')) &&
                    item.employee.id === h.employee.id
                ).length > 0
                  ? items.filter(
                      (item: ClockIn) =>
                        dayjs(
                          item.date.substring(0, item.date.lastIndexOf('-'))
                        ).isSame(dayjs(startDate).add(3, 'day')) &&
                        item.employee.id === h.employee.id
                    )[0].endTime
                  : undefined,
              wednesdayTotal:
                items.filter(
                  (item: ClockIn) =>
                    dayjs(
                      item.date.substring(0, item.date.lastIndexOf('-'))
                    ).isSame(dayjs(startDate).add(3, 'day')) &&
                    item.employee.id === h.employee.id
                ).length > 0
                  ? items.filter(
                      (item: ClockIn) =>
                        dayjs(
                          item.date.substring(0, item.date.lastIndexOf('-'))
                        ).isSame(dayjs(startDate).add(3, 'day')) &&
                        item.employee.id === h.employee.id
                    )[0].workingDuration
                  : undefined,
            },
            4: {
              date: dayjs(startDate).add(4, 'day'),
              thursdayClockIn:
                items.filter(
                  (item: ClockIn) =>
                    dayjs(
                      item.date.substring(0, item.date.lastIndexOf('-'))
                    ).isSame(dayjs(startDate).add(4, 'day')) &&
                    item.employee.id === h.employee.id
                ).length > 0
                  ? items.filter(
                      (item: ClockIn) =>
                        dayjs(
                          item.date.substring(0, item.date.lastIndexOf('-'))
                        ).isSame(dayjs(startDate).add(4, 'day')) &&
                        item.employee.id === h.employee.id
                    )[0].startTime
                  : undefined,
              thursdayClockOut:
                items.filter(
                  (item: ClockIn) =>
                    dayjs(
                      item.date.substring(0, item.date.lastIndexOf('-'))
                    ).isSame(dayjs(startDate).add(4, 'day')) &&
                    item.employee.id === h.employee.id
                ).length > 0
                  ? items.filter(
                      (item: ClockIn) =>
                        dayjs(
                          item.date.substring(0, item.date.lastIndexOf('-'))
                        ).isSame(dayjs(startDate).add(4, 'day')) &&
                        item.employee.id === h.employee.id
                    )[0].endTime
                  : undefined,
              thursdayTotal:
                items.filter(
                  (item: ClockIn) =>
                    dayjs(
                      item.date.substring(0, item.date.lastIndexOf('-'))
                    ).isSame(dayjs(startDate).add(4, 'day')) &&
                    item.employee.id === h.employee.id
                ).length > 0
                  ? items.filter(
                      (item: ClockIn) =>
                        dayjs(
                          item.date.substring(0, item.date.lastIndexOf('-'))
                        ).isSame(dayjs(startDate).add(4, 'day')) &&
                        item.employee.id === h.employee.id
                    )[0].workingDuration
                  : undefined,
            },
            5: {
              date: dayjs(startDate).add(5, 'day'),
              fridayClockIn:
                items.filter(
                  (item: ClockIn) =>
                    dayjs(
                      item.date.substring(0, item.date.lastIndexOf('-'))
                    ).isSame(dayjs(startDate).add(5, 'day')) &&
                    item.employee.id === h.employee.id
                ).length > 0
                  ? items.filter(
                      (item: ClockIn) =>
                        dayjs(
                          item.date.substring(0, item.date.lastIndexOf('-'))
                        ).isSame(dayjs(startDate).add(5, 'day')) &&
                        item.employee.id === h.employee.id
                    )[0].startTime
                  : undefined,
              fridayClockOut:
                items.filter(
                  (item: ClockIn) =>
                    dayjs(
                      item.date.substring(0, item.date.lastIndexOf('-'))
                    ).isSame(dayjs(startDate).add(5, 'day')) &&
                    item.employee.id === h.employee.id
                ).length > 0
                  ? items.filter(
                      (item: ClockIn) =>
                        dayjs(
                          item.date.substring(0, item.date.lastIndexOf('-'))
                        ).isSame(dayjs(startDate).add(5, 'day')) &&
                        item.employee.id === h.employee.id
                    )[0].endTime
                  : undefined,
              fridayTotal:
                items.filter(
                  (item: ClockIn) =>
                    dayjs(
                      item.date.substring(0, item.date.lastIndexOf('-'))
                    ).isSame(dayjs(startDate).add(5, 'day')) &&
                    item.employee.id === h.employee.id
                ).length > 0
                  ? items.filter(
                      (item: ClockIn) =>
                        dayjs(
                          item.date.substring(0, item.date.lastIndexOf('-'))
                        ).isSame(dayjs(startDate).add(5, 'day')) &&
                        item.employee.id === h.employee.id
                    )[0].workingDuration
                  : undefined,
            },
            6: {
              date: dayjs(endDate),
              saturdayClockIn:
                items.filter(
                  (item: ClockIn) =>
                    dayjs(
                      item.date.substring(0, item.date.lastIndexOf('-'))
                    ).isSame(dayjs(endDate)) &&
                    item.employee.id === h.employee.id
                ).length > 0
                  ? items.filter(
                      (item: ClockIn) =>
                        dayjs(
                          item.date.substring(0, item.date.lastIndexOf('-'))
                        ).isSame(dayjs(endDate)) &&
                        item.employee.id === h.employee.id
                    )[0].startTime
                  : undefined,
              saturdayClockOut:
                items.filter(
                  (item: ClockIn) =>
                    dayjs(
                      item.date.substring(0, item.date.lastIndexOf('-'))
                    ).isSame(dayjs(endDate)) &&
                    item.employee.id === h.employee.id
                ).length > 0
                  ? items.filter(
                      (item: ClockIn) =>
                        dayjs(
                          item.date.substring(0, item.date.lastIndexOf('-'))
                        ).isSame(dayjs(endDate)) &&
                        item.employee.id === h.employee.id
                    )[0].endTime
                  : undefined,
              saturdayTotal:
                items.filter(
                  (item: ClockIn) =>
                    dayjs(
                      item.date.substring(0, item.date.lastIndexOf('-'))
                    ).isSame(dayjs(endDate)) &&
                    item.employee.id === h.employee.id
                ).length > 0
                  ? items.filter(
                      (item: ClockIn) =>
                        dayjs(
                          item.date.substring(0, item.date.lastIndexOf('-'))
                        ).isSame(dayjs(endDate)) &&
                        item.employee.id === h.employee.id
                    )[0].workingDuration
                  : undefined,
            },
          };
        });

        if (updatedRowId) {
          setExpandedRows(updatedRowId);
          setEditedRowIds(undefined);
        }

        return { records: itemsMapped };
      });

  const _handleRowExpandChange = useCallback(
    (rows?: true | { [key: string]: boolean }) => {
      setExpandedRows(rows as { [key: string]: boolean });
      return true;
    },
    []
  );

  const renderDetailsGrid = useCallback(
    ({ data }: { [key: string]: unknown }) => (
      <RowDetails
        rowDetails={data as { [key: string]: unknown }}
        expandedRowData={expandedRowData}
        setRefreshToken={setRefreshToken}
        setSubtableEditInfo={(d) => {
          const x: RowUpdateIds = {};
          const keys = Object.keys(expandedRows);
          dataSource.forEach((e: { clockInsId: number; id: string }) => {
            if (keys.indexOf(e.id) >= 0) x[e.clockInsId] = e.id;
          });
          setEditedRowIds({ ...d, ...x });
        }}
      />
    ),
    [dataSource, expandedRowData, expandedRows]
  );

  useEffect(() => {
    setRefreshToken(new Date());
  }, [clockInContext.status]);

  return (
    <>
      <TetoContainer
        withBottomNav
        customSx={{
          [theme.breakpoints.down('lg')]: {
            '.InovuaReactDataGrid__row-cell-wrap': { zIndex: 100000 },
            '.InovuaReactDataGrid__virtual-list--scrolling .InovuaReactDataGrid__row':
              {
                contain: 'none !important',
              },
          },
        }}
      >
        {ready && cols && metaData && (
          <TetoGrid
            groups={daysOfWeekGroups}
            customGridWrapSx={tableWrapSx}
            dataSource={dataSource}
            setDataSource={setDataSource}
            disableMobileCols
            virtualized={false}
            disableConfigureButton
            disableGroupByToolbar
            clientSideFiltering
            ref={gridRef}
            pageSize={50000}
            doQuery={(a, b, c) =>
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              _doQuery(a, b, c as any) as unknown as Promise<
                PagedResponse<Record<string, unknown>>
              >
            }
            columns={cols}
            refreshToken={refreshToken}
            externalQueryProps={{ startDate, endDate }}
            tableIdentifier={t('Entities.ClockIns.tableIdentifier')}
            header={{
              leftChildren: (
                <WeeklyDateSelector
                  data-testid="week-selector"
                  onChange={(e) => {
                    navigate(
                      `/timeTracking/${pageName}/${e.start.format(
                        'YYYY-MM-DD'
                      )}/${e.end.format('YYYY-MM-DD')}`
                    );
                    setExpandedRows({});
                  }}
                  weekStart={settingsContext.settings.startDayOfWeek}
                  inputFormat={settingsContext.settings.dateFormat}
                  value={startDate as unknown as Date}
                  containerSx={wrapperSx as SxProps}
                  datePickerProps={{
                    DialogProps: {
                      sx: toolbarTileSx,
                    },
                  }}
                />
              ),
              rightChildren: [AddClockInBtn],
              FABPosition: fabContainerSx as SxProps,
            }}
            setSelectedRows={setSelectedRows}
            selectedRows={selectedRows}
            withBottomNav
            filterRowHeight={0}
            expandedRows={expandedRows}
            onExpandedRowsChange={(e) => _handleRowExpandChange(e.expandedRows)}
            renderDetailsGrid={renderDetailsGrid}
            rowDetailsWidth="viewport-width"
            rowExpandHeight={265}
          />
        )}
      </TetoContainer>
      {addClockInInspector && (
        <AddClockInInspector
          open={addClockInInspector}
          setAddClockInInspector={setAddClockInInspector}
          setRefreshToken={setRefreshToken}
        />
      )}
    </>
  );
};
export default ClockInsPage;
