import { Box, SxProps, TextField } from '@mui/material';
import { formatHoursAsHourMin } from '@teto/react-component-library';
import React, { useEffect, useState } from 'react';
import editorStyles from '../InlineEditingCommonStyles';

const cellSx: SxProps = {
  padding: 0,
  margin: 0,
};

const inputSx = {
  height: '100%',
  width: '100%',
  margin: 0,
  padding: 'auto',
};

const localSx: SxProps = {
  '& .MuiInputBase-root, & .MuiFormControl-root': {
    height: '100%',
    width: '100%',
  },
  '& .MuiFormHelperText-root.Mui-error': {
    position: 'absolute',
    zIndex: 1500,
    bottom: '-22px',
  },
};

const inputRegex = /^\d+(\d*|:[0-5]?[0-9]?|\.\d*|h|m)$/i;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DefaultHoursEditor = (props: any) => {
  const {
    autoFocus,
    cellProps,
    onCancel,
    onChange,
    onComplete,
    onTabNavigation,
    onEnterNavigation,
  } = props;

  const [input, setInput] = useState<string>('0');

  const formatStartValue = (val: string | number) =>
    formatHoursAsHourMin(typeof val === 'string' ? parseFloat(val) : val);

  useEffect(() => {
    setInput(() => formatStartValue(cellProps?.value ?? 0));
  }, [cellProps?.value]);

  const _handleChange = (text: string) => {
    if (inputRegex.test(text)) {
      // formik.setFieldValue(columnName, formatInputAshours(text));
      setInput(text);
      onChange();
    } else if (text === '') {
      setInput('');
      // formik.setFieldValue(columnName, undefined);
    }
  };

  return (
    <Box sx={[editorStyles.root, cellSx, localSx]} data-testid="container">
      <TextField
        autoFocus={autoFocus}
        autoComplete="off"
        data-testid="ETOHourEditor"
        // disabled={isDisabled}
        // error={Boolean(error)}
        fullWidth
        // helperText={error}
        onBlur={onComplete}
        name={cellProps?.name}
        onChange={(e) => _handleChange(e.target.value)}
        onFocus={(
          e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
        ) => e.target.select()}
        onKeyDown={(e) => {
          if (e.key === 'Escape') {
            onCancel(e);
          }
          if (e.key === 'Enter') {
            e.preventDefault();
            onEnterNavigation(true, e.shiftKey ? -1 : 1);
          }
          if (e.key === 'Tab') {
            e.preventDefault();
            onTabNavigation(true, e.shiftKey ? -1 : 1);
          }
        }}
        size="small"
        tabIndex={0}
        value={input}
        variant="outlined"
        sx={{
          height: '100%',
        }}
        inputProps={{
          sx: inputSx,
        }}
      />
    </Box>
  );
};

export default DefaultHoursEditor;
