import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { isEmpty } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '@teto/react-component-library';
import RDGSelectedType from '../../TetoGrid/RDGSelectedType';
import filterSelectedTsheetsByStatus from '../../TimeSheets/TimesheetActions/filterSelectedTsheetsByStatus';
import useApproveMultipleTimesheets from '../../TimeSheets/TimesheetActions/useApproveMultipleTimesheets';

interface ButtonObjectProps {
  onClick?: () => void;
  selectedRows: RDGSelectedType | undefined;
  setSelectedRows: React.Dispatch<
    React.SetStateAction<RDGSelectedType | undefined>
  >;
  approvalDisabled?: boolean;
}

const useApproveMultipleTsheetsButton = (props: ButtonObjectProps) => {
  const { t } = useTranslation();
  const { onClick, selectedRows, setSelectedRows, approvalDisabled } = props;
  const [approvalDisabledState, setApprovalDisabledState] =
    useState<boolean>(true);
  const { mutate: approveMultipleTimesheets } = useApproveMultipleTimesheets();
  const authContext = useContext(AuthContext);
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const getApproveStatus = filterSelectedTsheetsByStatus(
      selectedRows,
      'Submitted'
    );

    if (getApproveStatus && !isEmpty(getApproveStatus)) {
      return setApprovalDisabledState(false);
    }
    setApprovalDisabledState(true);
  }, [selectedRows]);

  return authContext.user?.isApprover
    ? {
        title: t('Entities.TimeSheet.approve'),
        icon: <CheckRoundedIcon />,
        onclick: () => {
          // eslint-disable-next-line no-unused-expressions
          onClick ?? onClick;
          approveMultipleTimesheets(selectedRows);
          setSelectedRows(undefined);
        },
        disabled: approvalDisabled ?? approvalDisabledState,
      }
    : false;
};

export default useApproveMultipleTsheetsButton;
