import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import {
  IconButtonProps,
  TooltipProps,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { ETOIconButton, ModalNavBarItem } from '@teto/react-component-library';
import { Form, useFormikContext } from 'formik';
import React, { ReactEventHandler, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface ConfirmDialogButton {
  label: string;

  onClick: (
    // eslint-disable-next-line no-unused-vars
    e: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined,
    // eslint-disable-next-line no-unused-vars
    data: object | undefined
  ) => void;
}

interface ConfirmDialogProps {
  open: boolean;
  leftButton?: ConfirmDialogButton;
  rightButton?: ConfirmDialogButton;
  title: string;
  content: string | React.ReactNode;
  data?: object;
}

type CustomConfirmDialog = {
  type: 'custom';
} & Omit<ConfirmDialogProps, 'open'>;
export type OkCancelConfirmDialog = {
  type: 'okCancel';
  title: string;
  content: string | React.ReactNode;
  data?: object;
};
type YesNoConfirmDialog = {
  type: 'yesNo';
  title: string;
  content: string | React.ReactNode;
  data?: object;
  onNo: (
    // eslint-disable-next-line no-unused-vars
    e: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined
  ) => void;
};

export interface ActionItem {
  componentName: string;
  title?: string;
  icon: React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleClick: ReactEventHandler | any;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
  color?: 'grey' | 'success' | 'error' | 'primary' | 'secondary' | 'warning';
  confirm?: CustomConfirmDialog | YesNoConfirmDialog | OkCancelConfirmDialog;
  buttonProps?: Omit<IconButtonProps, 'color' | 'size'>;
  tooltipProps?: Omit<TooltipProps, 'children' | 'title'>;
}

interface ActionButtonProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  actionItems: ActionItem[];
  isGridEditing?: boolean;
  setIsDiscardConfirmDialog?: React.Dispatch<React.SetStateAction<boolean>>;
}

const ActionButton = (props: ActionButtonProps) => {
  const { actionItems, data, isGridEditing, setIsDiscardConfirmDialog } = props;
  const theme = useTheme();
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const formik = useFormikContext();
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const isEditingRow = Object.hasOwn(data, '__editing') && data?.__editing;

  useEffect(() => {
    if (formik.dirty) {
      setIsDirty(true);
    }
    return () => {
      setIsDirty(false);
    };
  }, [formik.dirty]);

  return (
    <>
      {isMobile && actionItems.length > 0 && !data.__group && (
        <ModalNavBarItem
          icon={<MoreHorizRoundedIcon />}
          modalMenuItems={actionItems}
        />
      )}

      {data.__group && <span>&nbsp;</span>}

      {/* Hacky approach but temporary solution */}
      {isEditingRow && setIsDiscardConfirmDialog && (
        <Form>
          <ETOIconButton
            disabled={!isDirty}
            color="success"
            size="medium"
            type="submit"
            onClick={() => formik.submitForm()}
            tooltipProps={{
              title: t('generic.saveChange'),
              placement: 'bottom-end',
            }}
          >
            <CheckRoundedIcon />
          </ETOIconButton>
          <ETOIconButton
            disabled={false}
            color="error"
            size="medium"
            type="button"
            onClick={() => setIsDiscardConfirmDialog(true)}
            tooltipProps={{
              title: t('generic.discardChange'),
              placement: 'bottom-end',
            }}
          >
            <CloseRoundedIcon />
          </ETOIconButton>
        </Form>
      )}

      {isEditingRow ||
        (!data.__group &&
          !isMobile &&
          actionItems.map((item: ActionItem) => (
            <ETOIconButton
              key={item.componentName}
              color={item.color ?? 'primary'}
              onClick={item.handleClick}
              size="medium"
              confirm={item.confirm}
              disabled={isGridEditing || item.disabled}
              type={item.type}
              buttonProps={{
                disableRipple: isGridEditing || item.disabled,
                ...(item.buttonProps as IconButtonProps),
              }}
              tooltipProps={
                {
                  title: item.title,
                  placement: 'bottom-end',
                  ...item.tooltipProps,
                } as TooltipProps
              }
            >
              {item.icon}
            </ETOIconButton>
          )))}
    </>
  );
};

export default React.memo(ActionButton);
