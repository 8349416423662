import {
  AuthContext,
  MessageContext,
  OkCancelConfirmDialog,
} from '@teto/react-component-library';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  getEmployees,
  getHourTypesForTimecard,
  getJobs,
  getNonConformances,
  getProcessScheduleDetails,
  getProjects,
  getMyPunchIns,
  PunchIn,
  stopPunchIn,
} from 'teto-client-api';
import useLocalStorage from 'use-local-storage';
import useLocationFormatted from '../../../../helpers/useLocationFormatted';
import PunchInTimerContext from '../../NavTimer/PunchInTimerContext';
import TimeCardInspector from '../../TimeCardInspector/TimeCardInspector';
import StopTimerForm from '../../TimeSheetForms/Timer/StopTimerForm';

interface StopTimeInspectorProps {
  id: number;
  onClose: () => void;
  onUpdate: () => void;
  // eslint-disable-next-line no-unused-vars
  onCancel: () => void;
  limitToSelf?: boolean;
  canUseTimer: boolean;
}

const StopTimeInspector = (props: StopTimeInspectorProps) => {
  const authContext = useContext(AuthContext);
  const { t } = useTranslation();
  const { id, onClose, onUpdate, onCancel, canUseTimer, limitToSelf } = props;

  const [timerRecord, setTimerRecord] = useState<PunchIn | undefined>();
  const messageContext = useContext(MessageContext);
  const punchInTimerContext = useContext(PunchInTimerContext);
  const [formHasChanges, setFormHasChanges] = useState(false);
  const [needsCloseConfirmation, setNeedsCloseConfirmation] = useState(false);
  const [geoInfo] = useLocationFormatted();

  const [barcodeEnabled, setBarcodeEnabled] = useLocalStorage(
    'barcode-scanner-enabled',
    false
  );

  useEffect(() => {
    const valid =
      !limitToSelf ||
      (punchInTimerContext.status === 'active' &&
        punchInTimerContext.activeTimerId);

    if (valid) {
      getMyPunchIns({ filter: { active: true }, disablePaging: true })
        .then((punchIns) => {
          setTimerRecord(punchIns.records.find((a) => a.id === id));
        })
        .catch((e) => {
          messageContext.setMessage(
            `Failed to get punch in record.  ${e.message}`,
            'error'
          );
        });
    }
  }, [
    id,
    messageContext,
    punchInTimerContext.activeTimerId,
    punchInTimerContext.status,
    limitToSelf,
  ]);

  const _onError = (err: string | Error) => {
    messageContext.setMessage(
      typeof err === 'string' ? err : err.message,
      'error'
    );
  };

  const stopAPIMethod = limitToSelf
    ? punchInTimerContext.stopMyPunchIn
    : stopPunchIn;

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {authContext.user && (
        <TimeCardInspector
          barcodeEnabled={barcodeEnabled && !geoInfo.geoError}
          lockedInspector={geoInfo.geoError}
          toggleBarcode={() => setBarcodeEnabled((v) => !v)}
          open={Boolean(id)}
          onClose={() => {
            if (formHasChanges) {
              setNeedsCloseConfirmation(true);
            } else {
              onClose();
            }
          }}
          title={t('forms.stopTimer')}
          data={[
            {
              employeeFullName:
                timerRecord?.employeeFullName ??
                `${authContext.user.firstName} ${authContext.user?.lastName}`,
              hourTypeName: timerRecord?.hourTypeName ?? '',
              projectName: timerRecord?.projectName ?? '',
              specName: timerRecord?.specName ?? '',
              punchInCoords: timerRecord?.punchInCoords ?? geoInfo.location,
              id: timerRecord?.id ?? authContext.user.id,
            },
          ]}
        >
          {id && (
            <StopTimerForm
              setFormDirty={(e) => setFormHasChanges(e)}
              id={id}
              canUseTimer={canUseTimer && !geoInfo.geoError}
              onError={_onError}
              qrEnabled={barcodeEnabled && !geoInfo.geoError}
              allowEmployeeSelection={authContext.user.isAdmin}
              getProjects={() =>
                getProjects({
                  disablePaging: true,
                  filter: {
                    active: true,
                  },
                  orderBy: {
                    id: true,
                  },
                }).then((e) => e.records)
              }
              getHourTypes={(
                projectId,
                specId,
                processScheduleDetailId,
                nonConformanceId,
                employeeId
              ) =>
                getHourTypesForTimecard({
                  disablePaging: true,
                  filter: {
                    active: true,
                    projectId: projectId ?? null,
                    specId: specId ?? null,
                    processScheduleDetailId: processScheduleDetailId ?? null,
                    nonConformanceId: nonConformanceId ?? null,
                    employeeId: employeeId ?? null,
                  },
                }).then((e) => e.records)
              }
              getJobs={(projectId: number) =>
                getJobs({
                  disablePaging: true,
                  filter: { projectId, active: true },
                  orderBy: { displayName: false },
                }).then((e) => e.records)
              }
              getNonConformances={(projectId: number, specId: number) =>
                getNonConformances({
                  disablePaging: true,
                  filter: { projectId, specId },
                  orderBy: { creationDate: true },
                }).then((e) => e.records)
              }
              getProcessSchedules={(projectId: number, specId: number) =>
                getProcessScheduleDetails({
                  disablePaging: true,
                  filter: { projectId, specId },
                  orderBy: { lastWorkedOnDate: true },
                }).then((e) => e.records)
              }
              initialValues={{
                ...timerRecord,
                employeeId: timerRecord?.employeeId || -2,
              }}
              onSubmit={(request) =>
                stopAPIMethod(id, {
                  location: geoInfo.location,
                  nonConformanceId: request.nonConformanceId
                    ? request.nonConformanceId
                    : undefined,
                  processScheduleDetailId: request.processScheduleDetailId
                    ? request.processScheduleDetailId
                    : undefined,
                  projectId: request.projectId,
                  specId: request.specId,
                  comments: request.comments,
                  quantity: request.quantity,
                  hourTypeId: request.hourTypeId,
                  custom1: request.custom1,
                  custom2: request.custom2,
                  custom3: request.custom3,
                  custom4: request.custom4,
                  custom5: request.custom5,
                  custom6: request.custom6,
                  custom7: request.custom7,
                  custom8: request.custom8,
                  pSCCustom1: request.pSCCustom1,
                  pSCCustom2: request.pSCCustom2,
                  pSCCustom3: request.pSCCustom3,
                  pSCCustom4: request.pSCCustom4,
                  pSCCustom5: request.pSCCustom5,
                  pSCCustom6: request.pSCCustom6,
                  pSCCustom7: request.pSCCustom7,
                  pSCCustom8: request.pSCCustom8,
                  hourFactor: request.hourFactor,
                  hourRate: request.hourRate,
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                } as any)
                  .then(() => {
                    onUpdate();
                  })
                  .catch((e) => {
                    if (Object.prototype.hasOwnProperty.call(e, 'errors')) {
                      // Wonky but shape of e is unknown at this point, backend changes pending
                      if (!e.errors.errors) {
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        const error = Object.values(e.errors)[0] as any;
                        if (typeof error[0] === 'string') {
                          messageContext.setError(error[0]);
                        }
                        return;
                      }
                      if (
                        e.errors.errors.hours &&
                        typeof e.errors.errors.hours[0] === 'string'
                      ) {
                        messageContext.setError(e.errors.errors.hours[0]);
                      }
                    } else {
                      _onError(e);
                    }
                  })
              }
              onCancel={onCancel}
              fieldsDisableEditable={
                geoInfo.geoError || {
                  employeeId: typeof getEmployees === 'function',
                  nonConformanceId: false,
                  processScheduleDetailId: false,
                  quantity: false,
                  comments: false,
                  custom1: false,
                  custom2: false,
                  custom3: false,
                  custom4: false,
                  custom5: false,
                  custom6: false,
                  custom7: false,
                  custom8: false,
                  hourTypeId: false,
                  hour: false,
                  specId: false,
                  projectId: false,
                  pSCCustom1: false,
                  pSCCustom2: false,
                  pSCCustom3: false,
                  pSCCustom4: false,
                  pSCCustom5: false,
                  pSCCustom6: false,
                  pSCCustom7: false,
                  pSCCustom8: false,
                }
              }
              fieldCaptions={{
                employeeId: t('Entities.Employee.Employee'),
                comments: t('forms.fields.comments'),
                custom1: t('Entities.Timecard.TimecardCustom1'),
                custom2: t('Entities.Timecard.TimecardCustom2'),
                custom3: t('Entities.Timecard.TimecardCustom3'),
                custom4: t('Entities.Timecard.TimecardCustom4'),
                custom5: t('Entities.Timecard.TimecardCustom5'),
                custom6: t('Entities.Timecard.TimecardCustom6'),
                custom7: t('Entities.Timecard.TimecardCustom7'),
                custom8: t('Entities.Timecard.TimecardCustom8'),
                hourFactor: t('Entities.Timecard.hourFactor'),
                hourRate: t('Entities.Timecard.hourRate'),
                hourTypeId: t('Entities.HourType.HourType'),
                hour: t('forms.fields.hour'),
                specId: t('Entities.Machine.Job'),
                nonConformanceId: t('Entities.NonConformance.NonConformance'),
                processScheduleDetailId: t(
                  'Entities.ProcessScheduleDetail.ProcessScheduleDetail'
                ),
                projectId: t('Entities.Project.Project'),
                quantity: t(
                  'Entities.Process Schedule In House Transaction.quantity'
                ),
                pSCCustom1: t(
                  'Entities.Process Schedule In House Transaction.CompletedLogCustom1'
                ),
                pSCCustom2: t(
                  'Entities.Process Schedule In House Transaction.CompletedLogCustom2'
                ),
                pSCCustom3: t(
                  'Entities.Process Schedule In House Transaction.CompletedLogCustom3'
                ),
                pSCCustom4: t(
                  'Entities.Process Schedule In House Transaction.CompletedLogCustom4'
                ),
                pSCCustom5: t(
                  'Entities.Process Schedule In House Transaction.CompletedLogCustom5'
                ),
                pSCCustom6: t(
                  'Entities.Process Schedule In House Transaction.CompletedLogCustom6'
                ),
                pSCCustom7: t(
                  'Entities.Process Schedule In House Transaction.CompletedLogCustom7'
                ),
                pSCCustom8: t(
                  'Entities.Process Schedule In House Transaction.CompletedLogCustom8'
                ),
              }}
              buttons={{
                right: {
                  title: 'Start Timer',
                },
              }}
              employee={authContext.user}
            />
          )}
          <OkCancelConfirmDialog
            onOk={() => {
              setNeedsCloseConfirmation(false);
              onClose();
            }}
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onCancel={() => {
              setNeedsCloseConfirmation(false);
            }}
            title={t('dialogs.closeUnsavedForm.title')}
            content={t('dialogs.closeUnsavedForm.content')}
            open={needsCloseConfirmation}
          />
        </TimeCardInspector>
      )}
    </>
  );
};

export default StopTimeInspector;
