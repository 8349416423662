import { Box, FormHelperText, SxProps, Theme, Typography } from '@mui/material';
import {
  AuthContext,
  MessageContext,
  OkCancelConfirmDialog,
  SettingsContext,
  WeeklyDateSelector,
} from '@teto/react-component-library';
import dayjs, { Dayjs } from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  addTimeCardByHours,
  getEmployee,
  getEmployees,
  getHourTypesForTimecard,
  getJobs,
  getNonConformances,
  getProcessScheduleDetails,
  getProjects,
  Permission,
} from 'teto-client-api';
import useLocalStorage from 'use-local-storage';
import useLocationFormatted from '../../../helpers/useLocationFormatted';
import InlineHelpLink from '../../HelpLinks/InlineHelpLink/InlineHelpLink';
import TimeCardInspector from '../TimeCardInspector/TimeCardInspector';
import HoursFormValues from '../TimeSheetForms/Hour/HourFormValues';
import AddHoursTimeCardForm from '../TimeSheetForms/Hours/AddHoursTimeCardForm';

interface AddHoursInspectorProps {
  open: boolean;
  allowEmployeeSelection: boolean;
  canAdd: boolean;
  initialValues?: Partial<HoursFormValues>;
  startDate?: Date;
  onClose: () => void;
  onUpdate: () => void;
}

const weekSelectorSx = (theme: Theme) => ({
  width: 'fit-content',
  '& .MuiSvgIcon-root': {
    fontSize: theme.spacing(3.5),
  },
  '& .MuiInput-root': {
    paddingLeft: theme.spacing(0),
    width: theme.spacing(24),
  },
});

const toolbarTileSx = {
  '& h4.MuiTypography-root.MuiTypography-h4.MuiTypography-alignCenter.MuiDatePickerToolbar-title.css-3jvy96-MuiTypography-root-MuiDatePickerToolbar-title':
    {
      fontSize: '1.25rem',
    },
};

const errorSx = (theme: Theme) => ({
  display: 'flex',
  columnGap: theme.spacing(2),
  alignItems: 'center',
});

const _getStartDate = (date: Dayjs, startDayOfWeek: number) =>
  date.startOf('w').add(startDayOfWeek, 'day').toDate();

const _getStopDate = (date: Dayjs, startDayOfWeek: number) =>
  date.endOf('w').add(startDayOfWeek, 'day').toDate();

const AddHoursInspector = (props: AddHoursInspectorProps) => {
  const { t } = useTranslation();
  const {
    open,
    onClose,
    onUpdate,
    allowEmployeeSelection,
    canAdd,
    initialValues,
    startDate,
  } = props;
  const [formHasChanges, setFormHasChanges] = useState(false);
  const authContext = useContext(AuthContext);
  const {
    settings: { startDayOfWeek, dateFormat, approverCanManageTime },
  } = useContext(SettingsContext);
  const messageContext = useContext(MessageContext);
  const settingsContext = useContext(SettingsContext);
  const [needsCloseConfirmation, setNeedsCloseConfirmation] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date>(
    startDate ?? new Date()
  );
  const [defaultHourType, setDefaultHourType] = useState<number | undefined>();
  const [selectedWeek, setSelectedWeek] = useState<
    { start: Date; end: Date } | undefined
  >({
    start: _getStartDate(
      dayjs(startDate) || dayjs(),
      settingsContext.settings.startDayOfWeek
    ),
    end: _getStopDate(
      dayjs(startDate) || dayjs(),
      settingsContext.settings.startDayOfWeek
    ),
  });
  const [datePickerError, setDatePickerError] = useState<string>('');
  const [geoInfo] = useLocationFormatted();
  const [barcodeEnabled, setBarcodeEnabled] = useLocalStorage(
    'barcode-scanner-enabled',
    false
  );

  useEffect(() => {
    if (startDate) {
      setSelectedDate(startDate);

      setSelectedWeek({
        start: _getStartDate(
          dayjs(startDate) || dayjs(),
          settingsContext.settings.startDayOfWeek
        ),
        end: _getStopDate(
          dayjs(startDate) || dayjs(),
          settingsContext.settings.startDayOfWeek
        ),
      });
    }
  }, [settingsContext.settings.startDayOfWeek, startDate]);

  const _onError = (err: string | Error) => {
    messageContext.setMessage(
      typeof err === 'string' ? err : err.message,
      'error'
    );
  };

  useEffect(() => {
    if (authContext.user) {
      getEmployee(authContext.user?.id)
        .then((a) => {
          setDefaultHourType(a.hourTypeId);
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.warn(err);
        });
    }
  }, [authContext.user]);

  return (
    <TimeCardInspector
      disableMap
      open={open}
      barcodeEnabled={barcodeEnabled && !geoInfo.geoError}
      toggleBarcode={() => setBarcodeEnabled((v) => !v)}
      onClose={() => {
        if (formHasChanges) {
          setNeedsCloseConfirmation(true);
        } else {
          onClose();
        }
      }}
      title={t('forms.addHours')}
      lockedInspector={geoInfo.geoError}
    >
      {geoInfo.geoError && (
        <Box data-testid="geo-error" sx={errorSx}>
          <Typography align="left" sx={{ color: 'error.main' }}>
            {t('forms.geoLocation.addHoursError')}
          </Typography>
          <InlineHelpLink
            helpId="8637679060756"
            subSectionId="allow-access-to-your-physical-location-upon-first-visit-0-1"
            title={t('forms.geoLocation.access')}
          />
        </Box>
      )}
      <WeeklyDateSelector
        datePickerProps={{
          DialogProps: {
            sx: toolbarTileSx,
          },
        }}
        disabled={geoInfo.geoError}
        weekStart={startDayOfWeek}
        customSx={weekSelectorSx as SxProps}
        value={selectedDate}
        inputFormat={dateFormat}
        onChange={(e) => {
          setSelectedDate(e.dayInWeek.toDate());
          setSelectedWeek({ start: e.start.toDate(), end: e.end.toDate() });
          setDatePickerError('');
        }}
      />
      {datePickerError && (
        <FormHelperText error>{datePickerError}</FormHelperText>
      )}
      {authContext.user && selectedWeek && (
        <AddHoursTimeCardForm
          setDatePickerError={setDatePickerError}
          canAdd={canAdd && !geoInfo.geoError}
          onError={_onError}
          setFormDirty={(e) => setFormHasChanges(e)}
          qrEnabled={barcodeEnabled && !geoInfo.geoError}
          startDate={selectedWeek?.start}
          getProjects={() =>
            getProjects({
              disablePaging: true,
              filter: {
                active: true,
              },
              orderBy: {
                id: true,
              },
            }).then((e) => e.records)
          }
          getHourTypes={(
            projectId,
            specId,
            processScheduleDetailId,
            nonConformanceId,
            employeeId
          ) =>
            getHourTypesForTimecard({
              disablePaging: true,
              filter: {
                active: true,
                projectId: projectId ?? null,
                specId: specId ?? null,
                processScheduleDetailId: processScheduleDetailId ?? null,
                nonConformanceId: nonConformanceId ?? null,
                employeeId: employeeId ?? null,
              },
            }).then((e) => e.records)
          }
          getJobs={(projectId: number) =>
            getJobs({
              disablePaging: true,
              filter: { projectId, active: true },
              orderBy: { displayName: false },
            }).then((e) => e.records)
          }
          getNonConformances={(projectId: number, specId: number) =>
            getNonConformances({
              disablePaging: true,
              filter: { projectId, specId },
              orderBy: { creationDate: true },
            }).then((e) => e.records)
          }
          getProcessSchedules={(projectId: number, specId: number) =>
            getProcessScheduleDetails({
              disablePaging: true,
              filter: { projectId, specId },
              orderBy: { lastWorkedOnDate: true },
            }).then((e) => e.records)
          }
          getEmployees={
            allowEmployeeSelection
              ? () =>
                  getEmployees({
                    disablePaging: true,
                    orderBy: { active: true, employeeNumber: false },
                  }).then((e) => {
                    if (
                      authContext.hasAnyPermission([
                        Permission.Add_Admin_Timecards,
                      ])
                    ) {
                      return e.records;
                    }

                    if (approverCanManageTime) {
                      return e.records.filter((b) =>
                        authContext.canApprove(b.id)
                      );
                    }

                    return [];
                  })
              : authContext.user
          }
          initialValues={{
            ...initialValues,
            hourTypeId: defaultHourType || -1,
            hourFactor: 1,
          }}
          onSubmit={(e) =>
            addTimeCardByHours({
              location: geoInfo.location,
              employeeId: e.employeeId,
              hourTypeId: e.hourTypeId,
              hours: e.hours,
              specId: e.specId,
              projectId: e.projectId,
              comments: e.comments,
              custom1: e.custom1,
              custom2: e.custom2,
              custom3: e.custom3,
              custom4: e.custom4,
              custom5: e.custom5,
              custom6: e.custom6,
              custom7: e.custom7,
              custom8: e.custom8,
              nonConformanceId: e.nonConformanceId ?? undefined,
              processScheduleDetailId: e.processScheduleDetailId ?? undefined,
              quantity: e.quantity,
              hourFactor: e.hourFactor,
              hourRate: e.hourRate,
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } as any).then(() => {
              onUpdate();
            })
          }
          employeeId={authContext.user?.id}
          fieldsDisableEditable={{
            employeeId: !allowEmployeeSelection,
          }}
          fieldCaptions={{
            employeeId: t('Entities.Employee.Employee'),
            comments: t('forms.fields.comments'),
            hourRate: t('Entities.Timecard.hourRate'),
            hourFactor: t('Entities.Timecard.hourFactor'),
            custom1: t('Entities.Timecard.TimecardCustom1'),
            custom2: t('Entities.Timecard.TimecardCustom2'),
            custom3: t('Entities.Timecard.TimecardCustom3'),
            custom4: t('Entities.Timecard.TimecardCustom4'),
            custom5: t('Entities.Timecard.TimecardCustom5'),
            custom6: t('Entities.Timecard.TimecardCustom6'),
            custom7: t('Entities.Timecard.TimecardCustom7'),
            custom8: t('Entities.Timecard.TimecardCustom8'),
            hourTypeId: t('Entities.HourType.HourType'),
            hour: t('Entities.Timecard.totalHours'),
            specId: t('Entities.Machine.Job'),
            nonConformanceId: t('Entities.NonConformance.NonConformance'),
            processScheduleDetailId: t(
              'Entities.ProcessScheduleDetail.ProcessScheduleDetail'
            ),
            projectId: t('Entities.Project.Project'),
            quantity: t(
              'Entities.Process Schedule In House Transaction.quantity'
            ),
            pSCCustom1: t(
              'Entities.Process Schedule In House Transaction.CompletedLogCustom1'
            ),
            pSCCustom2: t(
              'Entities.Process Schedule In House Transaction.CompletedLogCustom2'
            ),
            pSCCustom3: t(
              'Entities.Process Schedule In House Transaction.CompletedLogCustom3'
            ),
            pSCCustom4: t(
              'Entities.Process Schedule In House Transaction.CompletedLogCustom4'
            ),
            pSCCustom5: t(
              'Entities.Process Schedule In House Transaction.CompletedLogCustom5'
            ),
            pSCCustom6: t(
              'Entities.Process Schedule In House Transaction.CompletedLogCustom6'
            ),
            pSCCustom7: t(
              'Entities.Process Schedule In House Transaction.CompletedLogCustom7'
            ),
            pSCCustom8: t(
              'Entities.Process Schedule In House Transaction.CompletedLogCustom8'
            ),
          }}
        />
      )}
      <OkCancelConfirmDialog
        onOk={() => {
          setNeedsCloseConfirmation(false);
          onClose();
        }}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onCancel={() => {
          setNeedsCloseConfirmation(false);
        }}
        title={t('dialogs.closeUnsavedForm.title')}
        content={t('dialogs.closeUnsavedForm.content')}
        open={needsCloseConfirmation}
      />
    </TimeCardInspector>
  );
};

export default AddHoursInspector;
