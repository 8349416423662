import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Box, IconButton, InputAdornment, SxProps, Theme } from '@mui/material';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from 'react';
import editorStyles from '../InlineEditingCommonStyles';

const localSx: SxProps<Theme> = {
  '& .MuiInputBase-root, & .MuiFormControl-root': {
    height: '100%',
    width: '100%',
  },
  '& .MuiFormHelperText-root': {
    fontSize: '0.9rem',
    position: 'absolute',
    zIndex: 1500,
    bottom: '-30px',
    backgroundColor: 'rgba(97, 97, 97, 0.92)',
    marginLeft: 0,
    marginRight: 0,
    color: (theme) => `${theme.palette.common.white} !important`,
    padding: (theme) => `${theme.spacing(0)} ${theme.spacing(1.75)}`,
    borderRadius: (theme) => theme.spacing(0.5),
    '& .Mui-error': {
      color: (theme) => `${theme.palette.common.white} !important`,
      '&:hover': {
        color: 'common.white',
      },
    },
  },
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DefaultStringEditor = (props: any) => {
  const {
    onComplete,
    onChange,
    onCancel,
    onEnterNavigation,
    onTabNavigation,
    cellProps,
    value,
  } = props;

  const v = cellProps.inEdit ? cellProps.editValue ?? '' : value ?? '';

  const [val, setVal] = useState<string>(v);
  useEffect(() => {
    setVal(v);
  }, [v]);
  return (
    <Box sx={[editorStyles.root, localSx]}>
      <TextField
        onChange={onChange}
        autoFocus
        onBlur={onComplete}
        onFocus={cellProps.startEdit}
        onKeyDown={(e) => {
          if (e.key === 'Escape') {
            onCancel(e);
          }
          if (e.key === 'Enter') {
            e.preventDefault();
            onEnterNavigation(true, e.shiftKey ? -1 : 1);
          }
          if (e.key === 'Tab') {
            e.preventDefault();
            onTabNavigation(true, e.shiftKey ? -1 : 1);
          }
        }}
        name={cellProps.name}
        // defaultValue={defaultVal}
        value={val}
        InputProps={{
          sx: {
            paddingRight: 0,
          },
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                size="small"
                // eslint-disable-next-line no-console
                onClick={() => console.log('hello')}
              >
                <CloseRoundedIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default DefaultStringEditor;
