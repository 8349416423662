/* eslint-disable consistent-return */
import { TypeFilterValue } from '@inovua/reactdatagrid-enterprise/types';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import List from '@mui/material/List';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { ButtonStrip, ETOIconButton } from '@teto/react-component-library';
import { debounce } from 'lodash';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TableColumnDefinition from '../TableColumnDefinition';
import filterTypes from './filterTypes';
import RenderFilterFieldType from './RenderFilterFieldType';

export interface ConfirmationDialogRawProps {
  id: string;
  value: string;
  open: boolean;
  // eslint-disable-next-line no-unused-vars
  onClose: (value?: string) => void;
  options: string[];
}

const headerSx = {
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
};
const ConfirmationDialogRaw = (props: ConfirmationDialogRawProps) => {
  const { onClose, value: valueProp, open, options, ...other } = props;
  const [value, setValue] = React.useState(valueProp);
  const radioGroupRef = React.useRef<HTMLElement>(null);
  const { t } = useTranslation();
  React.useEffect(() => {
    setValue(valueProp);
  }, [valueProp, open]);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onClose(value);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth="xs"
      TransitionProps={{ onEntering: handleEntering }}
      open={open}
      {...other}
    >
      <Box sx={headerSx}>
        <DialogTitle>Filter Operators</DialogTitle>
        <ETOIconButton
          size="medium"
          color="primary"
          onClick={() => handleCancel()}
          data-testid="filterCloseButton"
          tooltipProps={{ title: 'Close' }}
          customSx={{
            mr: 2,
          }}
        >
          <CloseRoundedIcon />
        </ETOIconButton>
      </Box>
      <DialogContent>
        <RadioGroup
          ref={radioGroupRef}
          aria-label="Filter Operators"
          name="Filter Operators"
          value={value}
          onChange={handleChange}
        >
          {options.map((option) => (
            <FormControlLabel
              value={option}
              key={option}
              control={<Radio />}
              label={option}
            />
          ))}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <ButtonStrip
          size="medium"
          leftButton={{
            text: t('generic.cancel'),
            onClick: () => handleCancel(),
            color: 'secondary',
          }}
          rightButton={{
            text: t('generic.save'),
            onClick: () => handleOk(),
            color: 'primary',
          }}
        />
      </DialogActions>
    </Dialog>
  );
};

interface FilterProps {
  col: TableColumnDefinition;
  // eslint-disable-next-line no-unused-vars
  setFilterBy: (value: React.SetStateAction<TypeFilterValue>) => void;
  filters: TypeFilterValue;
  defaultValue: string;
}
interface IOperators {
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fn: any;
}

const FilterField = (props: FilterProps) => {
  const { col, filters, setFilterBy, defaultValue } = props;
  const [open, setOpen] = React.useState(false);
  const [operatorsList, setOperatorsList] = useState<string[]>([]);
  const [operatorValue, setOperatorValue] = useState('');

  const handleClickListItem = () => {
    setOpen(true);
  };

  const handleClose = (newValue?: string) => {
    setOpen(false);

    if (newValue) {
      setOperatorValue(newValue);
    }
  };

  const _updateFilter = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    curCol: TableColumnDefinition
  ) => {
    if (!filters || filters.length < 0) return null;
    const newFilters = filters.map((f) => {
      if (f.name === curCol.name) {
        return {
          value: e.target.value,
          name: curCol.name,
          type: curCol.filterType,
          operator: operatorValue,
        };
      }
      return f;
    });
    setFilterBy(newFilters);
  };

  useEffect(() => {
    const getOperatorTypes = () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const result: IOperators[] = filterTypes[col.filterType]?.operators;

      if (result) {
        const listOfOperators = result.map((op) => op.name);
        return listOfOperators;
      }
      return [];
    };
    const newList = getOperatorTypes();
    setOperatorsList(newList);
    setOperatorValue(newList[0]);
  }, [col.filterType]);

  const debounceOnChange = debounce(_updateFilter, 2000);
  return (
    <Box sx={{ width: '100%', bgcolor: 'inherit' }}>
      <List component="div" role="group">
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <RenderFilterFieldType
            col={col}
            defaultValue={defaultValue}
            handleClickListItem={handleClickListItem}
            handleChange={debounceOnChange}
          />
          <ConfirmationDialogRaw
            id="filter-operator-menu"
            open={open}
            onClose={handleClose}
            value={operatorValue}
            options={operatorsList}
          />
        </Box>
      </List>
    </Box>
  );
};
export default FilterField;
