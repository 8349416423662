import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Box, IconButton, InputAdornment, SxProps, Theme } from '@mui/material';
import TextField from '@mui/material/TextField';
import { MessageContext } from '@teto/react-component-library';
import React, { useCallback, useContext, useState } from 'react';
import editorStyles from '../InlineEditingCommonStyles';
import TInlineEditingError from '../TInlineEditingError';

const localSx: SxProps<Theme> = {
  '& .MuiInputBase-root, & .MuiFormControl-root': {
    height: '100%',
    width: '100%',
  },
  '& .MuiFormHelperText-root': {
    fontSize: '0.9rem',
    position: 'absolute',
    zIndex: 1500,
    bottom: '-30px',
    backgroundColor: 'rgba(97, 97, 97, 0.92)',
    marginLeft: 0,
    marginRight: 0,
    color: (theme) => `${theme.palette.common.white} !important`,
    padding: (theme) => `${theme.spacing(0)} ${theme.spacing(1.75)}`,
    borderRadius: (theme) => theme.spacing(0.5),
    '& .Mui-error': {
      color: (theme) => `${theme.palette.common.white} !important`,
      '&:hover': {
        color: 'common.white',
      },
    },
  },
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DefaultNumberEditor = (props: any) => {
  const {
    onComplete,
    onChange,
    onCancel,
    onEnterNavigation,
    onTabNavigation,
    cellProps,
    value,
  } = props;

  const { editorProps, data, rowIndex } = cellProps;
  const [errorState, setErrorState] = useState<TInlineEditingError>({
    errorIs: false,
    errorText: '',
  });

  const messageContext = useContext(MessageContext);
  const _onEditComplete = useCallback(
    (e: React.BaseSyntheticEvent) => {
      if (editorProps.inlineEditingCondition) {
        const result = editorProps.inlineEditingCondition(
          e.target.value,
          cellProps.data
        );
        setErrorState(result);

        if (result.errorIs === true) {
          return messageContext.setError(result.errorText);
        }
        if (result.errorIs === 'warning') {
          messageContext.setWarning(result.errorText);
        }
        if (e.target.value === '') return onCancel();
        // pull out parse int
        return onComplete({
          value: e.target.value.length === 0 ? 0 : parseInt(e.target.value, 10),
          columnId: data.name as string,
          rowIndex,
        });
      }
      return onComplete({
        value: e.target.value.length === 0 ? 0 : parseInt(e.target.value, 10),
        columnId: data.name as string,
        rowIndex,
      });
    },
    [
      cellProps.data,
      data.name,
      editorProps,
      messageContext,
      onCancel,
      onComplete,
      rowIndex,
    ]
  );

  return (
    <Box sx={[editorStyles.root, localSx]}>
      <TextField
        error={errorState.errorIs === true}
        onChange={(e) => {
          const num =
            e.target.value.length === 0 ? '' : parseInt(e.target.value, 10);
          onChange(num);
        }}
        autoFocus
        onBlur={(e) => {
          _onEditComplete(e);
        }}
        onFocus={cellProps.startEdit}
        onKeyDown={(e) => {
          if (e.key === 'Escape') {
            onCancel(e);
          }
          if (e.key === 'Enter') {
            e.preventDefault();
            onEnterNavigation(true, e.shiftKey ? -1 : 1);
          }
          if (e.key === 'Tab') {
            e.preventDefault();
            onTabNavigation(true, e.shiftKey ? -1 : 1);
          }
        }}
        name={cellProps.name}
        type="number"
        value={value ?? ''}
        InputProps={{
          ...(cellProps.name === 'numberOfLabels' && {
            inputProps: { min: 0 },
          }),
          sx: {
            paddingRight: 0,
          },
          endAdornment: (
            <InputAdornment position="end">
              <IconButton size="small" onClick={() => onCancel()}>
                <CloseRoundedIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default DefaultNumberEditor;
