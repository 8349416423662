/* eslint-disable radix */
/* eslint-disable react/jsx-props-no-spreading */
import { Box, Grid, Theme } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MessageContext } from '@teto/react-component-library';
import dayjs from 'dayjs';
import { FormikHelpers, useFormik } from 'formik';
import { isEmpty } from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AuthenticatedUser,
  Employee,
  HourType,
  Job,
  NonConformance,
  ProcessScheduleDetail,
  Project,
} from 'teto-client-api';
import { parseServerResponse } from '../../../../helpers/validationHelperREST';
import ETOHoursEntry from '../ETOHoursEntry';
import { CommonFields, CustomFields, OptionalFields } from '../Fields';
import FieldsDisableEditableType from '../FieldsDisableEditableType';
import isDisabled from '../isDisabled';
import TimeCardForm, { isCustomCaption } from '../TimeCardForm';
import TimeCardValidation from './HoursFormValidation';
import HoursFormValues, { DaysFieldValues } from './HoursFormValues';
import HoursTimeCardRequestFormat from './HoursTimeCardRequestFormat';

const hoursTimeCardFormSx = {
  flex: 1,
  flexGrow: 1,
  overflowY: 'hidden',
};

const formSx = {
  height: '100%',
  width: '100%',
};

const hoursRowSx = (theme: Theme) => ({
  display: 'flex',
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
  paddingBottom: theme.spacing(1),
  justifyContent: 'space-between',
  [theme.breakpoints.down('md')]: {
    overflowX: 'auto',
  },
});

const hoursRowErrorSx = (theme: Theme) => ({
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: theme.palette.error.main,
  borderRadius: `${theme.shape.borderRadius}px`,
  '& input': {
    color: theme.palette.error.main,
  },
  '& label': {
    color: theme.palette.error.main,
  },
  '& .MuiInputBase-root': {
    borderBottom: theme.palette.error.main,
  },
});

const hoursRowItemSx = (theme: Theme) => ({
  display: 'flex',
  flexGrow: 1,
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
  '& input': {
    textAlign: 'center',
  },
  // '& input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button': {
  //   '-webkit-appearance': 'none',
  //   margin: 0,
  // },
  [theme.breakpoints.down('md')]: {
    width: '20vw',
    flex: 0,
    display: 'block',
  },
});

interface DayField {
  [key: string]: DaysFieldValues;
}

export interface HoursTimeCardFormProps {
  qrEnabled: boolean;
  disableOverlay?: boolean;
  editMode?: {
    batchId: string;
    // eslint-disable-next-line no-unused-vars
    deleteFunction: (batchId: string) => Promise<void>;
  };
  fieldsDisableEditable: boolean | Partial<FieldsDisableEditableType>;
  initialValues: Partial<HoursFormValues> & {
    hours: {
      day1: DaysFieldValues;
      day2: DaysFieldValues;
      day3: DaysFieldValues;
      day4: DaysFieldValues;
      day5: DaysFieldValues;
      day6: DaysFieldValues;
      day7: DaysFieldValues;
    };
  };

  fieldCaptions: {
    // eslint-disable-next-line no-unused-vars
    [key in CommonFields | OptionalFields | CustomFields]: string;
  };
  // eslint-disable-next-line no-unused-vars
  requireFields?: Partial<Record<OptionalFields | CustomFields, boolean>>;

  onSubmit: (
    // eslint-disable-next-line no-unused-vars
    timecards: HoursTimeCardRequestFormat,
    // eslint-disable-next-line no-unused-vars
    actions: FormikHelpers<HoursFormValues>
  ) => Promise<void>;
  // eslint-disable-next-line no-unused-vars
  onError: (err: string | Error) => void;
  getProjects: () => Promise<Project[]>;
  // eslint-disable-next-line no-unused-vars
  getJobs: (projectId: number) => Promise<Job[]>;
  // eslint-disable-next-line no-unused-vars
  getNonConformances: (
    // eslint-disable-next-line no-unused-vars
    projectId: number,
    // eslint-disable-next-line no-unused-vars
    specId: number
  ) => Promise<NonConformance[]>;

  getProcessSchedules: (
    // eslint-disable-next-line no-unused-vars
    projectId: number,
    // eslint-disable-next-line no-unused-vars
    specId: number
  ) => Promise<ProcessScheduleDetail[]>;
  getHourTypes: (
    // eslint-disable-next-line no-unused-vars
    projectId?: number,
    // eslint-disable-next-line no-unused-vars
    specId?: number,
    // eslint-disable-next-line no-unused-vars
    processScheduleDetailId?: number,
    // eslint-disable-next-line no-unused-vars
    nonConformanceId?: number,
    // eslint-disable-next-line no-unused-vars
    employeeId?: number
  ) => Promise<HourType[]>;
  getEmployees: (() => Promise<Employee[]>) | AuthenticatedUser;
  canAffect: boolean;
  canDelete: boolean;
  // eslint-disable-next-line no-unused-vars
  setFormDirty?: (val: boolean) => void;
  // eslint-disable-next-line no-unused-vars
  handleNewTimecardCreated?: (val: boolean) => void;
  setDatePickerError?: React.Dispatch<React.SetStateAction<string>>;
}

function _formatOutput(
  formValues: HoursFormValues
): HoursTimeCardRequestFormat {
  // remove all undefined fields the easy way
  const out = JSON.parse(
    JSON.stringify({
      employeeId: parseInt(formValues.employeeId as unknown as string),
      projectId: parseInt(formValues.projectId as unknown as string),
      specId: parseFloat(formValues.specId as unknown as string),
      hourTypeId: parseInt(formValues.hourTypeId as unknown as string),
      hourRate: parseFloat(formValues.hourRate as unknown as string),
      hourFactor: parseFloat(formValues.hourFactor as unknown as string),
      processScheduleDetailId:
        formValues.processScheduleDetailId &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (formValues.processScheduleDetailId as any) !== '-1'
          ? parseInt(formValues.processScheduleDetailId as unknown as string)
          : undefined,
      nonConformanceId:
        formValues.nonConformanceId &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (formValues.nonConformanceId as any) !== '-1'
          ? parseInt(formValues.nonConformanceId as unknown as string)
          : undefined,
      quantity: formValues.quantity,
      comments: formValues.comments,
      custom1: formValues.custom1,
      custom2: formValues.custom2,
      custom3: formValues.custom3,
      custom4: formValues.custom4,
      custom5: formValues.custom5 ?? undefined,
      custom6: formValues.custom6 ?? undefined,
      custom7: formValues.custom7,
      custom8: formValues.custom8,
      pSCCustom1: formValues.pSCCustom1,
      pSCCustom2: formValues.pSCCustom2,
      pSCCustom3: formValues.pSCCustom3,
      pSCCustom4: formValues.pSCCustom4,
      pSCCustom5: formValues.pSCCustom5 ?? undefined,
      pSCCustom6: formValues.pSCCustom6 ?? undefined,
      pSCCustom7: formValues.pSCCustom7,
      pSCCustom8: formValues.pSCCustom8,
      hours: {
        [formValues.hours.day1.day.format('YYYY-MM-DD')]:
          formValues.hours.day1.hours,
        [formValues.hours.day2.day.format('YYYY-MM-DD')]:
          formValues.hours.day2.hours,
        [formValues.hours.day3.day.format('YYYY-MM-DD')]:
          formValues.hours.day3.hours,
        [formValues.hours.day4.day.format('YYYY-MM-DD')]:
          formValues.hours.day4.hours,
        [formValues.hours.day5.day.format('YYYY-MM-DD')]:
          formValues.hours.day5.hours,
        [formValues.hours.day6.day.format('YYYY-MM-DD')]:
          formValues.hours.day6.hours,
        [formValues.hours.day7.day.format('YYYY-MM-DD')]:
          formValues.hours.day7.hours,
      },
    })
  );

  if (out.processScheduleDetailId <= 0) {
    delete out.processScheduleDetailId;
  }

  if (out.nonConformanceId <= 0) {
    delete out.nonConformanceId;
  }
  return out;
}

const HoursTimeCardForm = (props: HoursTimeCardFormProps) => {
  const { t } = useTranslation();
  const messageContext = useContext(MessageContext);
  const {
    qrEnabled,
    disableOverlay,
    editMode,
    initialValues,
    requireFields,
    getJobs,
    getHourTypes,
    getProjects,
    getNonConformances,
    getProcessSchedules,
    fieldCaptions,
    fieldsDisableEditable,
    onSubmit,
    getEmployees,
    onError,
    canAffect,
    canDelete,
    setFormDirty,
    handleNewTimecardCreated,
    setDatePickerError,
  } = props;

  const _handleLocationError = (errors: { [key: string]: string }) => {
    const keys = Object.keys(errors);
    keys.forEach((k) => {
      if (k === 'location') {
        messageContext.setError(errors[k]);
      }
    });
  };
  const _handleDateError = (errors: { [key: string]: string }) => {
    if (setDatePickerError) setDatePickerError(errors.date[0]);
  };
  const formik = useFormik<HoursFormValues>({
    enableReinitialize: true,
    validationSchema: TimeCardValidation,
    validateOnBlur: false,
    validateOnMount: false,
    initialValues: {
      employeeId:
        typeof getEmployees === 'function'
          ? initialValues?.employeeId ?? -2
          : getEmployees.id,
      projectId: initialValues?.projectId ?? -1,
      specId: initialValues?.specId ?? -1,
      hourTypeId: initialValues?.hourTypeId ?? -1,
      quantity: initialValues?.quantity ?? 0,
      custom1: initialValues?.custom1 ?? '',
      custom2: initialValues?.custom2 ?? '',
      custom3: initialValues?.custom3 ?? undefined,
      custom4: initialValues?.custom4 ?? undefined,
      custom5: initialValues?.custom5 ?? null,
      custom6: initialValues?.custom6 ?? null,
      custom7: initialValues?.custom7 ?? false,
      custom8: initialValues?.custom8 ?? false,
      pSCCustom1: initialValues?.pSCCustom1 ?? '',
      pSCCustom2: initialValues?.pSCCustom2 ?? '',
      pSCCustom3: initialValues?.pSCCustom3,
      pSCCustom4: initialValues?.pSCCustom4,
      pSCCustom5: initialValues?.pSCCustom5 ?? null,
      pSCCustom6: initialValues?.pSCCustom6 ?? null,
      pSCCustom7: initialValues?.pSCCustom7 ?? false,
      pSCCustom8: initialValues?.pSCCustom8 ?? false,
      comments: initialValues?.comments ?? '',
      processScheduleDetailId: initialValues?.processScheduleDetailId ?? -1,
      nonConformanceId: initialValues?.nonConformanceId ?? -1,
      batchId:
        editMode && initialValues?.batchId ? initialValues?.batchId : undefined,
      requireComments: requireFields?.comments ?? false,
      requireCustom1:
        (requireFields?.custom1 &&
          isCustomCaption('custom1', fieldCaptions.custom1)) ??
        false,
      requireCustom2:
        (requireFields?.custom2 &&
          isCustomCaption('custom2', fieldCaptions.custom2)) ??
        false,
      requireCustom3:
        (requireFields?.custom3 &&
          isCustomCaption('custom3', fieldCaptions.custom3)) ??
        false,
      requireCustom4:
        (requireFields?.custom4 &&
          isCustomCaption('custom4', fieldCaptions.custom4)) ??
        false,
      requireCustom5:
        (requireFields?.custom5 &&
          isCustomCaption('custom5', fieldCaptions.custom5)) ??
        false,
      requireCustom6:
        (requireFields?.custom6 &&
          isCustomCaption('custom6', fieldCaptions.custom6)) ??
        false,
      requireCustom7:
        (requireFields?.custom7 &&
          isCustomCaption('custom7', fieldCaptions.custom7)) ??
        false,
      requireCustom8:
        (requireFields?.custom8 &&
          isCustomCaption('custom8', fieldCaptions.custom8)) ??
        false,
      requirePSCCustom1:
        (requireFields?.pSCCustom1 &&
          isCustomCaption('pSCCustom1', fieldCaptions.pSCCustom1)) ??
        false,
      requirePSCCustom2:
        (requireFields?.pSCCustom2 &&
          isCustomCaption('pSCCustom2', fieldCaptions.pSCCustom2)) ??
        false,
      requirePSCCustom3:
        (requireFields?.pSCCustom3 &&
          isCustomCaption('pSCCustom3', fieldCaptions.pSCCustom3)) ??
        false,
      requirePSCCustom4:
        (requireFields?.pSCCustom4 &&
          isCustomCaption('pSCCustom4', fieldCaptions.pSCCustom4)) ??
        false,
      requirePSCCustom5:
        (requireFields?.pSCCustom5 &&
          isCustomCaption('pSCCustom5', fieldCaptions.pSCCustom5)) ??
        false,
      requirePSCCustom6:
        (requireFields?.pSCCustom6 &&
          isCustomCaption('pSCCustom6', fieldCaptions.pSCCustom6)) ??
        false,
      requirePSCCustom7:
        (requireFields?.pSCCustom7 &&
          isCustomCaption('pSCCustom7', fieldCaptions.pSCCustom7)) ??
        false,
      requirePSCCustom8:
        (requireFields?.pSCCustom8 &&
          isCustomCaption('pSCCustom8', fieldCaptions.pSCCustom8)) ??
        false,
      requireNonConformance: requireFields?.nonConformanceId ?? false,
      requireProcessSchedule: requireFields?.processScheduleDetailId ?? false,
      requireQuantity: requireFields?.quantity ?? false,
      hourFactor: initialValues.hourFactor,
      hourRate: initialValues.hourRate,
      hours: {
        day1: {
          hours: (initialValues.hours.day1.hours ?? 0).toString(),
          day: initialValues.hours.day1.day,
        },
        day2: {
          hours: (initialValues.hours.day2.hours ?? 0).toString(),
          day: initialValues.hours.day2.day,
        },
        day3: {
          hours: (initialValues.hours.day3.hours ?? 0).toString(),
          day: initialValues.hours.day3.day,
        },
        day4: {
          hours: (initialValues.hours.day4.hours ?? 0).toString(),
          day: initialValues.hours.day4.day,
        },
        day5: {
          hours: (initialValues.hours.day5.hours ?? 0).toString(),
          day: initialValues.hours.day5.day,
        },
        day6: {
          hours: (initialValues.hours.day6.hours ?? 0).toString(),
          day: initialValues.hours.day6.day,
        },
        day7: {
          hours: (initialValues.hours.day7.hours ?? 0).toString(),
          day: initialValues.hours.day7.day,
        },
      },
    },
    onSubmit: (values, actions) =>
      onSubmit(_formatOutput(values), actions)
        .then(() => {
          actions.setSubmitting(false);
        })
        .catch((e) => {
          actions.setSubmitting(false);
          parseServerResponse(
            e,
            (errors) => actions.setErrors(errors),
            (error) => onError(error)
          );
          _handleLocationError(e?.errors?.errors);
          _handleDateError(e?.errors.errors);
        }),
  });

  const [isZeroTotalHours, setIsZeroTotalHours] = useState<boolean>(false);

  const totalHours = useCallback(
    () =>
      Object.entries(formik.values.hours).reduce<number>(
        (prev, current) => prev + parseFloat(current[1].hours.toString()),
        0
      ),
    [formik.values.hours]
  );

  useEffect(() => {
    if (
      (formik.dirty && totalHours() === 0) ||
      (!isEmpty(formik.errors) && totalHours() === 0)
    ) {
      setIsZeroTotalHours(true);
    }

    return () => setIsZeroTotalHours(false);
  }, [formik.dirty, formik.errors, totalHours]);

  useEffect(() => {
    if (handleNewTimecardCreated) {
      const initialHours = formik.initialValues.hours as DayField;
      const currentHours = formik.values.hours as DayField;
      Object.keys(initialHours).every((e) => {
        if (initialHours[e].hours === '0' && currentHours[e].hours !== '0') {
          handleNewTimecardCreated(true);
          return false;
        }
        return true;
      });
    }
  }, [
    formik.initialValues.hours,
    formik.values.hours,
    handleNewTimecardCreated,
  ]);

  useEffect(() => {
    if (setFormDirty) setFormDirty(formik.dirty);
  }, [formik.dirty, setFormDirty]);

  const leftButton = editMode
    ? {
        disabled: !canDelete || formik.isSubmitting,
        color: 'error' as const,
        text: t('generic.delete'),
        confirm: {
          type: 'okCancel' as const,
          title: t('dialogs.deleteRecord.title'),
          content: t('dialogs.deleteRecord.content'),
        },
        onClick: () => editMode.deleteFunction(editMode.batchId),
      }
    : undefined;

  const today = dayjs()
    .set('hour', 0)
    .set('minute', 0)
    .set('second', 0)
    .set('millisecond', 0);

  const todayInHoursList = Boolean(
    Object.entries(formik.values.hours).find((a) => a[1].day.isSame(today))
  );

  const dayToAutoFocus = todayInHoursList
    ? today
    : formik.values.hours.day1.day;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={hoursTimeCardFormSx} data-testid="hours-form">
        <Box
          component="form"
          onSubmit={formik.handleSubmit}
          sx={formSx}
          autoComplete="off"
        >
          <TimeCardForm
            isEditMode={Boolean(editMode)}
            canAffect={canAffect}
            disableAutoFocus
            getEmployees={getEmployees}
            buttonStrip={{
              size: 'medium',
              leftButton,
              rightButton: {
                disabled:
                  formik.isSubmitting ||
                  isDisabled(canAffect, 'hours', fieldsDisableEditable),
                color: 'primary',
                text: t('generic.save'),
                type: 'submit',
              },
            }}
            qrEnabled={qrEnabled}
            disableOverlay={disableOverlay}
            errors={formik.errors}
            fieldCaptions={fieldCaptions}
            fieldsDisableEditable={fieldsDisableEditable}
            getHourTypes={getHourTypes}
            getJobs={getJobs}
            getNonConformances={getNonConformances}
            getProcessSchedules={getProcessSchedules}
            getProjects={getProjects}
            handleChange={formik.handleChange}
            isSubmitting={formik.isSubmitting}
            setFieldValue={formik.setFieldValue}
            totalHours={totalHours}
            values={formik.values}
            origValues={formik.initialValues}
            submitForm={formik.handleSubmit}
          >
            <Grid item xs={12}>
              <Box sx={[hoursRowSx, isZeroTotalHours ? hoursRowErrorSx : null]}>
                <ETOHoursEntry
                  disabled={isDisabled(
                    canAffect,
                    'hours',
                    fieldsDisableEditable
                  )}
                  name="hours.day1.hours"
                  autoFocus={formik.values.hours.day1.day.isSame(
                    dayToAutoFocus
                  )}
                  customSx={hoursRowItemSx}
                  value={formik.values.hours.day1.hours}
                  day={formik.values.hours.day1.day}
                  error={formik.errors.hours?.day1?.hours}
                  handleChange={formik.handleChange}
                />
                <ETOHoursEntry
                  disabled={isDisabled(
                    canAffect,
                    'hours',
                    fieldsDisableEditable
                  )}
                  name="hours.day2.hours"
                  autoFocus={formik.values.hours.day2.day.isSame(
                    dayToAutoFocus
                  )}
                  customSx={hoursRowItemSx}
                  value={formik.values.hours.day2.hours}
                  day={formik.values.hours.day2.day}
                  error={formik.errors.hours?.day2?.hours}
                  handleChange={formik.handleChange}
                />
                <ETOHoursEntry
                  disabled={isDisabled(
                    canAffect,
                    'hours',
                    fieldsDisableEditable
                  )}
                  name="hours.day3.hours"
                  autoFocus={formik.values.hours.day3.day.isSame(
                    dayToAutoFocus
                  )}
                  customSx={hoursRowItemSx}
                  value={formik.values.hours.day3.hours}
                  day={formik.values.hours.day3.day}
                  error={formik.errors.hours?.day3?.hours}
                  handleChange={formik.handleChange}
                />
                <ETOHoursEntry
                  disabled={isDisabled(
                    canAffect,
                    'hours',
                    fieldsDisableEditable
                  )}
                  name="hours.day4.hours"
                  autoFocus={formik.values.hours.day4.day.isSame(
                    dayToAutoFocus
                  )}
                  customSx={hoursRowItemSx}
                  value={formik.values.hours.day4.hours}
                  day={formik.values.hours.day4.day}
                  error={formik.errors.hours?.day4?.hours}
                  handleChange={formik.handleChange}
                />
                <ETOHoursEntry
                  disabled={isDisabled(
                    canAffect,
                    'hours',
                    fieldsDisableEditable
                  )}
                  name="hours.day5.hours"
                  autoFocus={formik.values.hours.day5.day.isSame(
                    dayToAutoFocus
                  )}
                  customSx={hoursRowItemSx}
                  value={formik.values.hours.day5.hours}
                  day={formik.values.hours.day5.day}
                  error={formik.errors.hours?.day5?.hours}
                  handleChange={formik.handleChange}
                />
                <ETOHoursEntry
                  disabled={isDisabled(
                    canAffect,
                    'hours',
                    fieldsDisableEditable
                  )}
                  name="hours.day6.hours"
                  autoFocus={formik.values.hours.day6.day.isSame(
                    dayToAutoFocus
                  )}
                  customSx={hoursRowItemSx}
                  value={formik.values.hours.day6.hours}
                  day={formik.values.hours.day6.day}
                  error={formik.errors.hours?.day6?.hours}
                  handleChange={formik.handleChange}
                />
                <ETOHoursEntry
                  disabled={isDisabled(
                    canAffect,
                    'hours',
                    fieldsDisableEditable
                  )}
                  name="hours.day7.hours"
                  autoFocus={formik.values.hours.day7.day.isSame(
                    dayToAutoFocus
                  )}
                  customSx={hoursRowItemSx}
                  value={formik.values.hours.day7.hours}
                  day={formik.values.hours.day7.day}
                  error={formik.errors.hours?.day7?.hours}
                  handleChange={formik.handleChange}
                />
              </Box>
            </Grid>
          </TimeCardForm>
        </Box>
      </Box>
    </LocalizationProvider>
  );
};

export default HoursTimeCardForm;
