import { TimeSheet } from 'teto-client-api';
import RDGSelectedType from '../../TetoGrid/RDGSelectedType';

const filterSelectedTsheetsByStatus = (
  selectedTsheets: RDGSelectedType | undefined,
  status: string
) => {
  if (selectedTsheets) {
    const result = Object.values(selectedTsheets).filter(
      (timesheet: TimeSheet) => timesheet.status === status
    );

    return result;
  }
  return false;
};

export default filterSelectedTsheetsByStatus;
