import ReactDataGrid from '@inovua/reactdatagrid-enterprise';
import { TypeComputedProps } from '@inovua/reactdatagrid-enterprise/types';
import { ExpandMoreOutlined } from '@mui/icons-material';
import ExpandLessOutlined from '@mui/icons-material/ExpandLessOutlined';
import FilterNoneRoundedIcon from '@mui/icons-material/FilterNoneRounded';
import {
  Box,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  AppearanceContext,
  AuthContext,
  formatHoursAsHourMin,
  MessageContext,
  SettingsContext,
} from '@teto/react-component-library';
import dayjs, { Dayjs } from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useIsFetching, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import {
  cancelMyPunchIn,
  deleteTimeCard,
  getMyTimecards,
  getOrCreateTimeSheet,
  Licenses,
  PagedResponse,
  Permission,
  TimeCard,
  TimeSheet,
} from 'teto-client-api';
import useLocalStorage from 'use-local-storage';
import GridPersistenceContext from '../../../../components/TetoGrid/GridPersistence/GridPersistenceContext';
import {
  AddColumn,
  createColumns,
} from '../../../../components/TetoGrid/ModelMetaDataProcessor/ModelMetaDataProcessor';
import TetoGridRefType from '../../../../components/TetoGrid/TetoGridRefType';
import DesktopHeader, {
  DesktopHeaderProps,
} from '../../components/DesktopHeaders/DesktopHeader';

import TIMESHEET_QUERY_ID from '../../../../api/reactQuery/TimesheetsQueryId';
import ActionBar from '../../../../components/ActionBar/ActionBar';
import { ClientAddHoursButton } from '../../../../components/Buttons/AddHoursButton/AddHoursButton';
import ConfigureButtonObject from '../../../../components/Buttons/ConfigureButton/ConfigureButtonObject';
import TimerButton from '../../../../components/Buttons/TimerButton/TimerButton';
import useTimesheetSubmitButton from '../../../../components/Buttons/TimesheetSubmitButton/TimesheetSubmitButton';
import TimesheetUnsubmitButton from '../../../../components/Buttons/TimesheetUnsubmitButton/TimesheetUnsubmitButton';
import ConfigureInspector from '../../../../components/TetoGrid/ConfigureInspector/ConfigureInspector';
import useGridStyles from '../../../../components/TetoGrid/GridStyles';
import { reactDataGridLicenseKey } from '../../../../components/TetoGrid/Licensing';
import TableColumnDefinition from '../../../../components/TetoGrid/TableColumnDefinition';
import AddHoursInspector from '../../../../components/TimeSheets/AddHoursInspector/AddHoursInspector';
import TimerContext from '../../../../components/TimeSheets/NavTimer/PunchInTimerContext';
import StartTimeInspector from '../../../../components/TimeSheets/TimerInspectors/StartTimeInspector/StartTimeInspector';
import StopTimeInspector from '../../../../components/TimeSheets/TimerInspectors/StopTimeInspector/StopTimeInspector';
import TimesheetStatusText from '../../../../components/TimeSheets/TimesheetStatus/TimesheetStatusText';
import { formatWeeklyURLDate } from '../../../../helpers/dateHelpers';
import { DateRange } from '../../../../helpers/dateTypes';
import defaultDateRange from '../../../../helpers/defaultDateRange';
import GroupByTab from '../../components/GroupByTab/GroupByTab';
import MobileSubTable from '../../components/SubTable/MobileSubTable';
import SubTable from '../../components/SubTable/SubTable';
import TableGroupCell from '../../components/TableGroupCell/TableGroupCell';
import TimesheetViewBySelector from '../../components/TimesheetViewBySelector/TimesheetViewBySelector';
import WeekDateDisplay from '../../components/WeekDateDisplay/WeekDateDisplay';
import WeeklyDateSelectorWithBackArrow from './WeeklyDateSelectorWithBackArrow';
import {
  defaultGrouping,
  DESKTOP_SUB_TABLE_FIXED_HEIGHT,
  DESKTOP_SUB_TABLE_ROW_HEIGHT,
  excludedColumns,
  MOBILE_HEADER_HEIGHT,
  MOBILE_ROW_HEIGHT,
  URL,
  weekParmRegex,
} from './WeeklyGridTabConstants';
import {
  createMobileTTRecords,
  createTTDesktopRecords,
  sortGridColumnsByOrder,
} from './WeeklyGridTabHelpers';

dayjs.extend(localeData);

const headerSx = (theme: Theme) => ({
  [theme.breakpoints.down('md')]: {
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
});

const statusWrapSx = (theme: Theme) => ({
  [theme.breakpoints.down('sm')]: {
    gap: 0,
    display: 'flex',
    flexDirection: 'column',

    maxWidth: theme.spacing(12),
    '& .MuiTypography-subtitle2': {
      fontSize: '0.85rem',
    },
    alignItems: 'flex-start',
  },
});

const rootSx = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  [theme.breakpoints.down('lg')]: {
    '& .InovuaReactDataGrid__row': {
      top: '0 !important',
      position: 'relative !important',
    },

    '& .InovuaReactDataGrid--theme-default-dark, .InovuaReactDataGrid--theme-default-light ':
      {
        '& .InovuaReactDataGrid__row .InovuaReactDataGrid__row-details.InovuaReactDataGrid__row-details--details-grid':
          {
            padding: { xs: '0px !important', md: '10px !important' },
          },
      },

    '& div[data-name="sizer"]': {
      minHeight: '100% !important',
    },

    '& .InovuaReactDataGrid__row-details--details-grid': {
      overflowY: 'auto !important',
      overflowX: 'hidden !important',
    },
  },
});

export type valuesStateType = {
  projects: number[];
  hourTypes: number[];
  jobs: number[];
};

interface WeeklyGridTabProps {
  tableId: string;
  columns: TableColumnDefinition[];
  setViewBy?: React.Dispatch<React.SetStateAction<string>>;
  viewBy?: string;
  refreshGrid?: Dayjs;
  setRefreshGrid: () => void;
  setEditableTimecard: Dispatch<SetStateAction<TimeCard | undefined>>;
}

const WeeklyGridTab = (props: WeeklyGridTabProps) => {
  const {
    tableId,
    columns,
    setViewBy,
    viewBy,
    refreshGrid,
    setRefreshGrid,
    setEditableTimecard,
  } = props;
  const { t, ready } = useTranslation();
  const navigate = useNavigate();
  const messageContext = useContext(MessageContext);
  const timerContext = useContext(TimerContext);
  const {
    settings: { startDayOfWeek },
  } = useContext(SettingsContext);
  const authContext = useContext(AuthContext);
  const gridContext = useContext(GridPersistenceContext);
  const appearanceContext = useContext(AppearanceContext);
  const { columnOrder, hidden, setHidden, setColumnOrder } = gridContext;
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const { month, weekStart, weekEnd } = useParams();
  const queryIdentifier = TIMESHEET_QUERY_ID;
  const gridRef = useRef<TypeComputedProps | null>(null);
  const mainGridRef = useRef<TetoGridRefType | undefined>();
  const anchorRef = useRef<HTMLButtonElement>(null);
  const isFetchingPosts = useIsFetching(queryIdentifier);

  const canUseTimer =
    authContext.hasEnterpriseLicense() &&
    authContext.hasAnyPermission([
      Permission.Modify_MyTimeTracker_Punchcards,
      Permission.Add_MyTimeTracker_Punchcards,
    ]);

  const canAdd =
    authContext.hasAnyLicense() &&
    authContext.hasPermission(Permission.Add_MyTimeTracker_Timecards);

  const [showBackNavigation] = useState<string | undefined>(month);
  const [groupingColumns, setGroupingColumns] = useLocalStorage<string[]>(
    'timeTracker-ts-view-grouping-cols',
    defaultGrouping
  );

  const desktopRowHeight = useMemo(
    () => 16 + 40 * groupingColumns.length,
    [groupingColumns.length]
  );
  const [addHoursInspectorModal, setAddHoursInspectorModal] =
    useState<boolean>(false);
  const [startTimerModal, setStartTimerModal] = useState<boolean>(false);
  const [stopTimerModal, setStopTimerModal] = useState<boolean>(false);
  const [openConfigInspector, setOpenConfigInspector] =
    useState<boolean>(false);
  const [expandedRows, setExpandedRows] = useState<{ [key: number]: boolean }>(
    {}
  );
  const [collapseRows, setCollapsedRows] = useState<{ [key: number]: boolean }>(
    {}
  );
  const [rowHeight, setRowHeight] = useState<number>(
    isMobile ? MOBILE_ROW_HEIGHT : desktopRowHeight
  );
  const [rowItemCount, setRowItemCount] = useState<{ [key: string]: number }>(
    {}
  );

  const [selectedMobileDate, setSelectedMobileDate] =
    useState<Dayjs | undefined>(undefined);
  const [queryData, setQueryData] = useState<unknown | undefined>(undefined);
  const currentTheme =
    appearanceContext.themeVariant === 'dark'
      ? 'default-dark'
      : 'default-light';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [dataSource, setDataSource] = useState<any>([]);

  const [dateRange, setDateRange] = useState<DateRange>({
    start: weekStart
      ? dayjs(weekStart)
      : dayjs(defaultDateRange(startDayOfWeek).start),
    end: weekEnd ? dayjs(weekEnd) : dayjs(defaultDateRange(startDayOfWeek).end),
  });

  useEffect(() => {
    if (!weekStart || !weekEnd) {
      navigate(
        `${URL}${formatWeeklyURLDate(dateRange.start)}/${formatWeeklyURLDate(
          dateRange.end
        )}`,
        { replace: true }
      );
    } else if (
      !weekParmRegex.test(weekStart as string) ||
      !weekParmRegex.test(weekEnd as string)
    ) {
      navigate('error');
    }
  }, [weekEnd, weekStart, startDayOfWeek, navigate, dateRange]);

  const employeeTimeSheet = useQuery(
    [queryIdentifier, tableId, 'MyTimeTrackerTimeSheet', dateRange],
    () =>
      getOrCreateTimeSheet(dayjs(dateRange.start))
        .then((d) => d)
        .catch((e) => {
          if (e?.errors?.errors?.dateInTimeSheet) {
            messageContext.setError(e.errors.errors.dateInTimeSheet);
          } else {
            messageContext.setError(e.message ?? e);
          }
        }),
    {
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
    }
  );

  const timesheet = useMemo(
    () => ({
      id: employeeTimeSheet.data?.id ?? -1,
      status: employeeTimeSheet.data?.status,
      startDate: employeeTimeSheet.data?.startDate as Dayjs,
    }),
    [
      employeeTimeSheet.data?.id,
      employeeTimeSheet.data?.startDate,
      employeeTimeSheet.data?.status,
    ]
  );

  useLayoutEffect(() => {
    if (queryData && isMobile) {
      setDataSource(
        createMobileTTRecords(
          queryData as PagedResponse<TimeCard>,
          groupingColumns,
          dayjs(timesheet.startDate).toDate()
        ).records
      );
      if (rowHeight !== MOBILE_ROW_HEIGHT) {
        setRowHeight(MOBILE_ROW_HEIGHT);
      }
    } else if (queryData && !isMobile) {
      const result = createTTDesktopRecords(
        queryData as PagedResponse<TimeCard>,
        groupingColumns,
        startDayOfWeek
      ).records;

      setDataSource(result);

      if (rowHeight !== desktopRowHeight) setRowHeight(desktopRowHeight);
    }
  }, [
    desktopRowHeight,
    groupingColumns,
    isMobile,
    queryData,
    rowHeight,
    startDayOfWeek,
    timesheet.startDate,
  ]);

  const myTimecards = useQuery(
    [
      queryIdentifier,
      tableId,
      'MyTimeTrackerTimeCards',
      dateRange,
      employeeTimeSheet.data,
    ],
    () =>
      getMyTimecards({
        disablePaging: true,
        filter: {
          timeSheetId: employeeTimeSheet.data?.id ?? -1, // when filtering by timesheet Id - its possible to be missing records, from other timesheets that overlap from the same day
        },
      }),
    {
      onSuccess: (d) => {
        setQueryData(d);
      },
      onError: () => {
        messageContext.setError(t('generic.message.failTimecardBatch'));
      },
      enabled: Boolean(employeeTimeSheet.data),
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (refreshGrid) {
      employeeTimeSheet.refetch();
      myTimecards.refetch();
      setRefreshGrid();
    }
  }, [employeeTimeSheet, refreshGrid, setRefreshGrid, myTimecards]);

  const getScreenSize = useCallback(() => {
    const screenSize = window.innerWidth;

    if (screenSize <= theme.breakpoints.values.xs) {
      return 150;
    }
    if (screenSize <= theme.breakpoints.values.sm) {
      return 150;
    }
    if (screenSize <= theme.breakpoints.values.md) {
      return 200;
    }
    if (screenSize < theme.breakpoints.values.lg) {
      return 300;
    }
    return 400;
  }, [
    theme.breakpoints.values.lg,
    theme.breakpoints.values.md,
    theme.breakpoints.values.sm,
    theme.breakpoints.values.xs,
  ]);

  useEffect(() => {
    getScreenSize();
  }, [getScreenSize]);

  const columnHeader = (prop: DesktopHeaderProps) => (
    <DesktopHeader {...prop} />
  );

  const _handleTimecardDelete = useCallback(
    (data: TimeCard) => {
      deleteTimeCard(data.id)
        .then(() => {
          employeeTimeSheet.refetch();
          messageContext.setSuccess(t('generic.message.recordDeleted'));
        })
        .catch(() => {
          messageContext.setError(t('generic.message.failDeleteTimeCard'));
        });
    },
    [employeeTimeSheet, messageContext, t]
  );

  const subTableColumns = useMemo(() => {
    if (columns && ready) {
      return sortGridColumnsByOrder(
        columns.map((e) => ({
          ...e,
          hidden: hidden.indexOf(e.name) >= 0,
          align: e.name !== 'date' ? 'center' : e.align,
          disableColumnFilterContextMenu: true,
          disableColumnMenuTool: true,
          filterable: false,
          sortable: false,
        })),
        columnOrder
      );
    }
    return [];
  }, [columnOrder, columns, hidden, ready]);

  const _buildTimeColumn = useCallback(
    (index: number, WeekStartDay: number, startDate: Dayjs): AddColumn => {
      const title = t(
        `Entities.TimeSheet.${
          index + WeekStartDay > 6
            ? WeekStartDay + index - 7
            : index + WeekStartDay
        }`
      );
      const date = dayjs(startDate).add(index, 'day').format('D');

      return {
        name: `${index}`,
        title,
        type: 'hours',
        align: 'center',
        sortable: false,
        filterType: '',
        filterOptions: undefined,
        fixed: 'none',
        disableGrouping: true,
        disableColumnFilterContextMenu: true,
        disableHideable: true,
        disableColumnMenuTool: true,
        defaultFlex: 1,
        hidden: isMobile,
        emptyDisplay: '-',
        textAlign: 'center',
        // minWidth: 95,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        header: ({ data }: { data: any }) =>
          columnHeader({
            title,
            date,
            time: data.reduce(
              (cun: number, rec: { [key: number]: number }) => cun + rec[index],
              0
            ),
          }),
        render: ({ data }) => (
          <Typography fontWeight={500} component="h6">
            {data[index] > 0
              ? formatHoursAsHourMin(data[index] as number)
              : '-'}
          </Typography>
        ),
      };
    },
    [isMobile, t]
  );

  const cols = useMemo(() => {
    if (ready && !isMobile) {
      return createColumns<TimeSheet>()
        .addColumn({
          name: 'grouping',
          title: t('pages.timeTracker.grouping'),
          type: 'string',
          align: 'start',
          sortable: false,
          filterType: 'string',
          filterOptions: 'simple',
          fixed: 'left',
          disableGrouping: true,
          disableColumnMenuTool: true,
          disableHideable: true,
          disableColumnFilterContextMenu: true,
          draggable: false,
          hidden: isMobile,
          editable: false,
          render: ({ data }) =>
            !isMobile && <TableGroupCell grouping={data.grouping} />,
          defaultFlex: 2,
          minWidth: 170,
          header: () =>
            columnHeader({
              title: t('pages.timeTracker.grouping'),
              status: timesheet.status,
            }),
        })
        .addColumn(_buildTimeColumn(0, startDayOfWeek, timesheet.startDate))
        .addColumn(_buildTimeColumn(1, startDayOfWeek, timesheet.startDate))
        .addColumn(_buildTimeColumn(2, startDayOfWeek, timesheet.startDate))
        .addColumn(_buildTimeColumn(3, startDayOfWeek, timesheet.startDate))
        .addColumn(_buildTimeColumn(4, startDayOfWeek, timesheet.startDate))
        .addColumn(_buildTimeColumn(5, startDayOfWeek, timesheet.startDate))
        .addColumn(_buildTimeColumn(6, startDayOfWeek, timesheet.startDate))
        .addColumn({
          name: 'totalHours',
          title: t('Entities.TimeSheet.totalHours'),
          type: 'hours',
          align: 'center',
          sortable: false,
          filterType: '',
          filterOptions: undefined,
          fixed: 'none',
          disableGrouping: true,
          disableColumnFilterContextMenu: true,
          disableColumnMenuTool: true,
          defaultFlex: 1.5,
          textAlign: 'center',
          hidden: isMobile,
          // minWidth: 95,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          header: ({ data }: { data: any }) => {
            const total = data.reduce(
              (
                cum: number,
                rec: {
                  totalHours: number;
                  [key: number]: number;
                }
              ) => cum + rec.totalHours,
              0
            );

            return columnHeader({
              title: t('Entities.TimeSheet.totalHours'),
              time: total,
            });
          },
          render: ({ data }) => (
            <Typography fontWeight={500} component="h6">
              {formatHoursAsHourMin(data.totalHours as number)}
            </Typography>
          ),
        })
        .finalize(t)
        .map((c) => ({
          ...c,
          hideable: !c.disableHideable,
          groupBy: !c.disableGrouping,
          showColumnMenuTool: !c.disableColumnMenuTool,
          enableColumnFilterContextMenu: !c.disableColumnFilterContextMenu,
          header: c.header ?? c.title,
        }));
    }
    if (ready && isMobile) {
      return createColumns<TimeSheet>()
        .addColumn({
          name: 'date',
          title: t('Entities.TimeSheet.totalHours'),
          type: 'string',
          align: 'start',
          sortable: false,
          filterType: 'string',
          filterOptions: 'simple',
          fixed: 'none',
          disableGrouping: true,
          disableColumnMenuTool: true,
          disableHideable: true,
          defaultFlex: 2,
          width: getScreenSize(),
          render: ({ data }) => <span>{dayjs(data.date).format('dddd')}</span>,
        })
        .addColumn({
          name: 'totalHours',
          title: 'hours',
          type: 'hours',
          align: 'center',
          sortable: false,
          filterType: '',
          filterOptions: undefined,
          fixed: 'none',
          disableGrouping: true,
          disableColumnFilterContextMenu: true,
          disableColumnMenuTool: true,
          defaultFlex: 1,
          textAlign: 'center',
          width: 100,
          maxWidth: 250,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          header: ({ data }: { data: any }) => {
            const total = data.reduce(
              (
                cum: number,
                rec: {
                  totalHours: number;
                  [key: number]: number;
                }
              ) => cum + rec.totalHours,
              0
            );

            return columnHeader({ title: formatHoursAsHourMin(total) });
          },
          render: ({ data }) => (
            <span>{formatHoursAsHourMin(data.totalHours as number)}</span>
          ),
        })
        .finalize(t)
        .map((c) => ({
          ...c,
          hideable: !c.disableHideable,
          groupBy: !c.disableGrouping,
          showColumnMenuTool: !c.disableColumnMenuTool,
          enableColumnFilterContextMenu: !c.disableColumnFilterContextMenu,
          header: c.header ?? c.title,
        }));
    }
    return [];
  }, [
    _buildTimeColumn,
    getScreenSize,
    isMobile,
    ready,
    startDayOfWeek,
    t,
    timesheet.startDate,
    timesheet.status,
  ]);

  const _handleGroupSave = useCallback(
    (g: string[]) => {
      setOpenConfigInspector(false);
      setGroupingColumns(g);
      return Promise.resolve();
    },
    [setGroupingColumns]
  );

  const _handleGroupReset = useCallback(() => {
    setGroupingColumns(defaultGrouping);
    setOpenConfigInspector(false);
  }, [setGroupingColumns]);

  const _onRecordsUpdated = () => {
    employeeTimeSheet.refetch();
    messageContext.setSuccess(t('generic.message.recordAdded'));
    setAddHoursInspectorModal(false);
  };

  const _onStartTimerUpdated = () => {
    setStartTimerModal(false);
    timerContext.refreshTimer();
  };

  const _onStopTimer = () => {
    timerContext.refreshTimer();
    setStopTimerModal(false);
    employeeTimeSheet.refetch();
    messageContext.setSuccess(t('Entities.PunchIn.stopTimerSuccess'));
  };

  const _onCancelTimer = (id: number) => {
    cancelMyPunchIn(id)
      .then(() => {
        timerContext.refreshTimer();
        setStopTimerModal(false);
        messageContext.setSuccess(t('Entities.PunchIn.abortTimerSuccess'));
      })
      .catch(() => {
        messageContext.setError(t('Entities.PunchIn.abortTimerError'));
      });
  };

  const TimerBtn = TimerButton({
    setStartTimerModal: () => setStartTimerModal(true),
    setStopTimerModal: () => setStopTimerModal(true),
    status: employeeTimeSheet.data?.status,
  });
  const AddHoursBtn = ClientAddHoursButton({
    onClick: () => setAddHoursInspectorModal(true),
    disabled:
      !employeeTimeSheet.data ||
      (employeeTimeSheet.data?.status !== 'In Progress' &&
        employeeTimeSheet.data?.status !== 'Rejected'),
  });

  const ConfigureButton = ConfigureButtonObject({
    onClick: () => setOpenConfigInspector(true),
  });

  const SubmitBtn = useTimesheetSubmitButton({
    timesheet: employeeTimeSheet.data as TimeSheet,
    disabled:
      !employeeTimeSheet.data ||
      employeeTimeSheet.isLoading ||
      !authContext.hasPermission(Permission.Add_MyTimeTracker_Timecards) ||
      !authContext.hasLicense(Licenses.TimeEnterprise) ||
      Boolean(messageContext.message),
  });

  const UnsubmitBtn = TimesheetUnsubmitButton({
    status: employeeTimeSheet.data?.status,
    timesheetId: employeeTimeSheet.data?.id,
    disabled:
      !employeeTimeSheet.data ||
      employeeTimeSheet.isLoading ||
      !authContext.hasPermission(Permission.Add_MyTimeTracker_Timecards) ||
      !authContext.hasLicense(Licenses.TimeEnterprise) ||
      Boolean(messageContext.message),
  });

  const groupByTab = useMemo(
    () => ({
      tabLabel: t('generic.groupBy'),
      tabIcon: <FilterNoneRoundedIcon />,
      tabIndex: 1,
      tabPanel: (
        <GroupByTab
          initialGroupings={groupingColumns}
          onReset={_handleGroupReset}
          onSave={(e) => _handleGroupSave(e)}
        />
      ),
    }),
    [_handleGroupReset, _handleGroupSave, groupingColumns, t]
  );

  const _handleSelectedDate = useCallback(
    (d: Dayjs) => {
      const source = dataSource.filter(
        (e: { date: string | number | Dayjs | Date }) =>
          dayjs(e.date).isSame(dayjs(d))
      )[0];
      if (source.count > 0) {
        setExpandedRows({ [source.id]: true });
        setSelectedMobileDate(d);
      } else {
        setExpandedRows({});
        setSelectedMobileDate(undefined);
      }
    },
    [dataSource]
  );

  const handleSavedColumnSettings = useCallback(
    (data) => {
      const order = [
        ...data.columnOrder.filter((e: string) => e !== 'action'),
        'action',
      ];
      setColumnOrder(order);
      setHidden(data.hidden);
    },
    [setColumnOrder, setHidden]
  );

  const _handleRowExpandChange = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (rowData: any) => {
      setExpandedRows(rowData.expandedRows);
      setCollapsedRows(rowData.collapsedRows);
      if (isMobile) {
        if (rowData.rowExpanded) {
          setSelectedMobileDate(dayjs(rowData.data.date));
        } else setSelectedMobileDate(undefined);
      }

      return true;
    },
    [isMobile]
  );

  const handleRowExpandHeight = useCallback(
    ({ data }) => {
      if (!isMobile) {
        return (
          data.count * (DESKTOP_SUB_TABLE_ROW_HEIGHT + 2) +
          DESKTOP_SUB_TABLE_FIXED_HEIGHT +
          rowHeight +
          12
        );
      }
      if (isMobile) {
        const subColumns = subTableColumns.filter(
          (e) =>
            groupingColumns.indexOf(e.name) < 0 &&
            !e.hidden &&
            excludedColumns.indexOf(e.name) < 0
        );
        const subHeight = subColumns.length * 25 + 16;
        return (
          MOBILE_ROW_HEIGHT +
          data.count * (groupingColumns.length * 25 + 24) +
          (subHeight + 1) * rowItemCount[data.id] +
          16
        );
      }
      return 750;
    },
    [groupingColumns, isMobile, rowHeight, rowItemCount, subTableColumns]
  );

  const viewBySelector = !isMobile && (
    <TimesheetViewBySelector
      setViewBy={setViewBy}
      viewBy={viewBy}
      key="viewBySelector"
    />
  );

  const viewBySelectorMobile = isMobile && (
    <TimesheetViewBySelector
      setViewBy={setViewBy}
      viewBy={viewBy}
      key="viewBySelectorMobile"
    />
  );

  const timesheetStatus = isMobile && employeeTimeSheet.data && (
    <TimesheetStatusText
      stylesSx={statusWrapSx(theme)}
      timesheet={employeeTimeSheet.data as TimeSheet}
      key="statusText"
    />
  );

  return (
    // <Box sx={[useGridStyles, { height: '100%' }]}>
    <Box sx={[useGridStyles, rootSx as unknown as Record<string, unknown>]}>
      {viewBySelectorMobile}
      {ready && (
        <ActionBar
          anchorRef={anchorRef}
          leftChildren={[
            viewBySelector,
            <WeeklyDateSelectorWithBackArrow
              key="dateSelector"
              showBackNavigation={!!showBackNavigation}
              onChange={(e) => {
                setDateRange({ start: e.start, end: e.end });
                return navigate(
                  `${URL}${formatWeeklyURLDate(
                    e.start.toDate()
                  )}/${formatWeeklyURLDate(e.end.toDate())}`
                );
              }}
              handleBackClick={() => {
                setViewBy?.('month');
                navigate(-1);
              }}
              startOfTheWeek={dateRange}
            />,
            timesheetStatus,
          ]}
          rightChildren={
            employeeTimeSheet.data
              ? [
                  ...(authContext.hasPermission(
                    Permission.Add_MyTimeTracker_Timecards
                  )
                    ? [AddHoursBtn]
                    : []),
                  TimerBtn,
                  SubmitBtn,
                  UnsubmitBtn,
                  ConfigureButton,
                ]
              : []
          }
          withBottomNav
          headerStyles={headerSx(theme)}
        />
      )}
      {isMobile && ready && (
        <WeekDateDisplay
          handleSelectedDate={_handleSelectedDate}
          selectedDate={selectedMobileDate}
          startDate={timesheet.startDate}
        />
      )}

      {employeeTimeSheet.data && ready && (
        <ReactDataGrid
          handle={(r) => {
            gridRef.current = r ? r.current : null;
          }}
          theme={currentTheme}
          licenseKey={reactDataGridLicenseKey}
          loading={isFetchingPosts > 0}
          columns={cols}
          dataSource={dataSource}
          enableFiltering={false}
          rowHeight={rowHeight}
          headerHeight={isMobile ? MOBILE_HEADER_HEIGHT : 65}
          showEmptyRows
          showCellBorders="horizontal"
          showZebraRows
          expandedRows={expandedRows}
          collapsedRows={collapseRows}
          onExpandedRowsChange={(e) => _handleRowExpandChange(e)}
          renderDetailsGrid={({ data, rowIndex }) => {
            if (isMobile) {
              return (
                <MobileSubTable
                  {...{
                    data: (data as { [key: string]: unknown })
                      ?.mobileItems as TimeCard[],
                    columns: subTableColumns as TableColumnDefinition[],
                    onDeleteClick: (e) => _handleTimecardDelete(e),
                    onEditClick: (e) => setEditableTimecard(e),
                    setSubRowCount: (e) =>
                      setRowItemCount((d) => ({
                        ...d,
                        ...e,
                      })),
                    dataId: (data as { [key: string]: unknown })?.id as string,
                  }}
                />
              );
            }
            return (
              <SubTable
                {...{
                  data: (data as { [key: string]: unknown })
                    ?.items as TimeCard[],
                  rowId: rowIndex,
                  columns: subTableColumns as TableColumnDefinition[],
                  groupingColumns,
                  ref: mainGridRef,
                }}
              />
            );
          }}
          rowDetailsWidth={
            isMobile ? 'max-viewport-width' : 'min-viewport-width'
          }
          isRowExpandable={(e) => {
            if (!isMobile) return true;
            const { count } = e.data as { count: number };
            return count > 0;
          }}
          rowExpandHeight={handleRowExpandHeight}
          renderRowDetailsExpandIcon={() => (
            <Tooltip title={t('generic.hideContent')} leaveTouchDelay={200}>
              <ExpandLessOutlined color="primary" />
            </Tooltip>
          )}
          renderRowDetailsCollapsedIcon={() => (
            <Tooltip title={t('generic.viewContent')} leaveTouchDelay={200}>
              <ExpandMoreOutlined color="primary" />
            </Tooltip>
          )}
          virtualized={!isMobile}
          // @ts-expect-error: This does exist
          renderRowDetailsMoreIcon={() => undefined}
          multiRowExpand={!isMobile}
        />
      )}
      {stopTimerModal && ready && (
        <StopTimeInspector
          limitToSelf
          canUseTimer={canUseTimer}
          id={timerContext.activeTimerId || 0}
          onClose={() => setStopTimerModal(false)}
          onUpdate={() => _onStopTimer()}
          onCancel={() => _onCancelTimer(timerContext.activeTimerId as number)}
        />
      )}
      {addHoursInspectorModal && ready && (
        <AddHoursInspector
          canAdd={canAdd}
          allowEmployeeSelection={false}
          open={addHoursInspectorModal}
          onClose={() => setAddHoursInspectorModal(false)}
          onUpdate={() => _onRecordsUpdated()}
          startDate={dateRange.start.toDate()}
        />
      )}
      {startTimerModal && ready && (
        <StartTimeInspector
          canUseTimer={canUseTimer}
          open={startTimerModal}
          onClose={() => setStartTimerModal(false)}
          onUpdate={() => _onStartTimerUpdated()}
        />
      )}
      {openConfigInspector && ready && (
        <ConfigureInspector
          open={openConfigInspector}
          columns={subTableColumns as TableColumnDefinition[]}
          initialColumnOrder={columnOrder}
          setConfigureInspector={setOpenConfigInspector}
          handleClose={() => setOpenConfigInspector(false)}
          onReset={() => gridContext.resetSettings()}
          onColumnSettingsSave={handleSavedColumnSettings}
          tabs={[groupByTab]}
        />
      )}
    </Box>
  );
};

export default WeeklyGridTab;
