import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import HoursFormValues from './HoursFormValues';
import HoursTimeCardForm, { HoursTimeCardFormProps } from './HoursTimeCardForm';

interface AddTimeCardFormProps
  extends Omit<
    Omit<
      Omit<Omit<HoursTimeCardFormProps, 'editMode'>, 'initialValues'>,
      'canAffect'
    >,
    'canDelete'
  > {
  employeeId: number;
  startDate: Date;
  initialValues?: Partial<HoursFormValues>;
  qrEnabled: boolean;
  canAdd: boolean;
  // eslint-disable-next-line no-unused-vars
  setFormDirty?: (val: boolean) => void;
  setDatePickerError?: React.Dispatch<React.SetStateAction<string>>;
}

function _createTimeMap(startDate: Date) {
  const data = {
    day1: {
      hours: '0',
      day: dayjs(startDate),
    },
    day2: {
      hours: '0',
      day: dayjs(startDate).add(1, 'day'),
    },
    day3: {
      hours: '0',
      day: dayjs(startDate).add(2, 'day'),
    },
    day4: {
      hours: '0',
      day: dayjs(startDate).add(3, 'day'),
    },
    day5: {
      hours: '0',
      day: dayjs(startDate).add(4, 'day'),
    },
    day6: {
      hours: '0',
      day: dayjs(startDate).add(5, 'day'),
    },
    day7: {
      hours: '0',
      day: dayjs(startDate).add(6, 'day'),
    },
  };

  return data;
}

const AddTimeCardForm = (props: AddTimeCardFormProps) => {
  const {
    initialValues,
    startDate,
    qrEnabled,
    canAdd,
    fieldsDisableEditable,
    setDatePickerError,
  } = props;
  const [timeMap, setTimeMap] =
    useState<ReturnType<typeof _createTimeMap> | undefined>();

  useEffect(() => {
    setTimeMap(_createTimeMap(startDate));
  }, [startDate]);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {timeMap && (
        <HoursTimeCardForm
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          setDatePickerError={setDatePickerError}
          canAffect={canAdd}
          canDelete={false}
          qrEnabled={qrEnabled}
          initialValues={{
            ...initialValues,
            hours: timeMap,
          }}
          fieldsDisableEditable={fieldsDisableEditable}
        />
      )}
    </>
  );
};

export default AddTimeCardForm;
