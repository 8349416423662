export const getClockInColumnName = (index: number) => {
  switch (index) {
    case 6:
      return { name: 'saturdayClockIn', group: 'saturday' };
    case 5:
      return { name: 'fridayClockIn', group: 'friday' };
    case 4:
      return { name: 'thursdayClockIn', group: 'thursday' };
    case 3:
      return { name: 'wednesdayClockIn', group: 'wednesday' };
    case 2:
      return { name: 'tuesdayClockIn', group: 'tuesday' };
    case 1:
      return { name: 'mondayClockIn', group: 'monday' };
    case 0:
      return { name: 'sundayClockIn', group: 'sunday' };
    default:
      return { name: 'sundayClockIn', group: 'sunday' };
  }
};

export const getClockOutColumnName = (index: number) => {
  switch (index) {
    case 6:
      return { name: 'saturdayClockOut', group: 'saturday' };
    case 5:
      return { name: 'fridayClockOut', group: 'friday' };
    case 4:
      return { name: 'thursdayClockOut', group: 'thursday' };
    case 3:
      return { name: 'wednesdayClockOut', group: 'wednesday' };
    case 2:
      return { name: 'tuesdayClockOut', group: 'tuesday' };
    case 1:
      return { name: 'mondayClockOut', group: 'monday' };
    case 0:
      return { name: 'sundayClockOut', group: 'sunday' };
    default:
      return { name: 'sundayClockOut', group: 'sunday' };
  }
};

export const getTotalColumnName = (index: number) => {
  switch (index) {
    case 6:
      return { name: 'saturdayTotal', group: 'saturday' };
    case 5:
      return { name: 'fridayTotal', group: 'friday' };
    case 4:
      return { name: 'thursdayTotal', group: 'thursday' };
    case 3:
      return { name: 'wednesdayTotal', group: 'wednesday' };
    case 2:
      return { name: 'tuesdayTotal', group: 'tuesday' };
    case 1:
      return { name: 'mondayTotal', group: 'monday' };
    case 0:
      return { name: 'sundayTotal', group: 'sunday' };
    default:
      return { name: 'sundayTotal', group: 'sunday' };
  }
};
