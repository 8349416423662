import { TimeSheet } from 'teto-client-api';
import RDGSelectedType from '../../../../components/TetoGrid/RDGSelectedType';

const isApprovalDisabled = (selectedRows: RDGSelectedType | undefined) => {
  if (!selectedRows || Object.keys(selectedRows).length === 0) return true;
  const result = Object.values(selectedRows).some(
    (r: TimeSheet) =>
      r.status === 'In Progress' ||
      r.status === 'Approved' ||
      r.status === 'Rejected'
  );

  return result;
};

export default isApprovalDisabled;
