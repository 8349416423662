import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIos';
import CheckIcon from '@mui/icons-material/Check';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { useMediaQuery, useTheme } from '@mui/material';
import {
  ActionButton,
  AuthContext,
  MessageContext,
  SettingsContext,
} from '@teto/react-component-library';
import { isEmpty, uniqueId } from 'lodash';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { QueryFunctionContext, QueryKey, useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  createMissingTimesheet,
  getOverdueTimesheets,
  getTimeSheet,
  OverdueTimesheetsResponse,
  PagedResponse,
  Permission,
} from 'teto-client-api';
import TIMESHEET_QUERY_ID from '../../api/reactQuery/TimesheetsQueryId';
import ExportButton from '../../components/Buttons/ExportButton/ExportButton';
import { createColumns } from '../../components/TetoGrid/ModelMetaDataProcessor/ModelMetaDataProcessor';
import RDGSelectedType from '../../components/TetoGrid/RDGSelectedType';
import TetoContainer from '../../components/TetoGrid/TetoContainer';
import TetoGrid from '../../components/TetoGrid/TetoGrid';
import TetoGridRefType from '../../components/TetoGrid/TetoGridRefType';
import TimesheetInspectorWrapper from '../../components/TimeSheets/TimesheetInspector/TimesheetInspector';
import TimesheetStatusButton from '../../components/TimeSheets/TimesheetStatus/TimesheetStatusButton';
import { parseServerResponse } from '../../helpers/validationHelperREST';
import OverdueTimesheetsGridCheckbox from './components/CustomOverdueTimesheetsCheckbox';
import EmailInspector from './components/EmailInspector/EmailInspector';
import NotifyButton from './components/NotifyButton';

interface OverdueTimesheet extends OverdueTimesheetsResponse {
  employeeFullName: string;
}

// eslint-disable-next-line no-unused-vars
interface OverdueTimesheetsPageI {
  // eslint-disable-next-line react/no-unused-prop-types
  virtualized?: boolean;
}

const OverdueTimesheetsPage = () => {
  // const { virtualized } = props;

  const { t, ready } = useTranslation();
  const TABLE_ID = t('Entities.OverdueTimesheets.tableIdentifier');

  const browserLocation = useLocation();
  const navigate = useNavigate();

  const theme = useTheme();
  const mobileSize = useMediaQuery(theme.breakpoints.down('md'));

  const authContext = useContext(AuthContext);
  const messageContext = useContext(MessageContext);
  const settingsContext = useContext(SettingsContext);

  const queryIdentifier = TIMESHEET_QUERY_ID;

  const [isEmailInspectorOpen, setIsEmailInspectorOpen] =
    useState<boolean>(false);
  const [selectedRows, setSelectedRows] =
    useState<RDGSelectedType | undefined>();
  const [selectAllRowsData, setSelectAllRowsData] =
    useState<RDGSelectedType | undefined>();
  const [selectedTimesheet, setSelectedTimesheet] =
    useState<number | undefined | null>();
  const [refreshToken, setRefreshToken] = useState<Date | undefined>();
  const [dataSource, setDataSource] = useState([]);
  const gridRef = React.useRef<TetoGridRefType | undefined>();

  const canAccessTimesheets =
    authContext.hasAnyLicense() &&
    (authContext.hasAnyPermission([
      Permission.View_Admin_Timecards,
      Permission.Modify_Admin_Timecards,
    ]) ||
      (settingsContext.settings.approverCanManageTime &&
        authContext.user?.isApprover));

  useEffect(() => {
    if (!canAccessTimesheets) navigate('/access-denied', { replace: true });
  }, [canAccessTimesheets, navigate]);

  const _handleNotifyEmployee = useCallback(
    (data) => {
      setSelectedRows({ [data?.id]: { ...data } });
      setIsEmailInspectorOpen(!isEmailInspectorOpen);
    },
    [isEmailInspectorOpen]
  );

  const disableNotifyButton = () => {
    if (!selectedRows || Object.keys(selectedRows).length === 0) return true;
    return false;
  };

  const NotifyEmployeeButton = NotifyButton({
    onClick: () => setIsEmailInspectorOpen(!isEmailInspectorOpen),
    disabled: disableNotifyButton(),
  });

  // eslint-disable-next-line consistent-return
  const getCommentSize = useCallback(() => {
    const screenSize = window.innerWidth;

    if (screenSize <= theme.breakpoints.values.sm) {
      return 100;
    }
    if (screenSize <= theme.breakpoints.values.md) {
      return 100;
    }
    if (screenSize <= theme.breakpoints.values.md) {
      return 200;
    }
    if (screenSize < theme.breakpoints.values.lg) {
      return 300;
    }
    if (screenSize > theme.breakpoints.values.xl) {
      return 1200;
    }
  }, [
    theme.breakpoints.values.lg,
    theme.breakpoints.values.md,
    theme.breakpoints.values.sm,
    theme.breakpoints.values.xl,
  ]);

  const _handleCreateMissingTimesheet = (data: OverdueTimesheetsResponse) => {
    createMissingTimesheet(data.employeeId, data.startDate)
      .then((d) => {
        navigate(`/timeTracking/timesheets/${d}`, {
          state: browserLocation.pathname,
        });
      })
      .catch((e) => {
        parseServerResponse(
          e,
          (errors) => messageContext.setError(Object.values(errors)[0]),
          (error) => messageContext.setError(error)
        );
      });
  };

  const cols = useMemo(() => {
    if (ready) {
      return createColumns<OverdueTimesheet>()
        .addColumn({
          name: 'startDate',
          title: t('generic.startDate'),
          type: 'date',
          align: 'end',
          sortable: true,
          filterType: '',
          filterOptions: undefined,
          fixed: 'none',
          disableColumnFilterContextMenu: true,
          width: 144,
        })
        .addColumn({
          name: 'endDate',
          title: t('generic.endDate'),
          type: 'date',
          align: 'end',
          sortable: true,
          filterType: '',
          filterOptions: undefined,
          fixed: 'none',
          disableColumnFilterContextMenu: true,
          width: 144,
        })
        .addColumn({
          name: 'firstName',
          title: t('Entities.Employee.firstName'),
          type: 'string',
          align: 'start',
          sortable: true,
          filterType: 'string',
          filterOptions: 'simple',
          filterable: true,
          fixed: 'none',
          width: 180,
        })
        .addColumn({
          name: 'lastName',
          title: t('Entities.Employee.lastName'),
          type: 'string',
          align: 'start',
          sortable: true,
          filterType: 'string',
          filterOptions: 'simple',
          fixed: 'none',
          disableHideable: true,
          width: 180,
          filterable: true,
        })
        .addColumn({
          name: 'status',
          title: t('Entities.TimeSheet.status'),
          type: 'custom',
          align: 'center',
          sortable: true,
          filterType: 'string',
          filterOptions: 'simple',
          fixed: 'none',
          width: 160,
          renderFunction: (data: OverdueTimesheetsResponse) =>
            TimesheetStatusButton(data),
          headerProps: {
            className: 'gridColHeader__sort',
          },
        })
        .addColumn({
          name: 'overdueByDays',
          title: t('Entities.TimeSheet.overdueByDays'),
          type: 'number',
          align: 'center',
          sortable: true,
          filterType: '',
          filterOptions: undefined,
          fixed: 'none',
          width: 200,
          disableGrouping: true,
          disableColumnMenuTool: true,
          headerProps: {
            className: 'gridColHeader__sort',
          },
        })
        .addColumn({
          name: 'lastNotified',
          title: t('Entities.TimeSheet.lastNotified'),
          type: 'datetime',
          align: 'center',
          sortable: true,
          filterType: '',
          filterOptions: undefined,
          fixed: 'none',
          width: 200,
          disableGrouping: true,
          disableColumnMenuTool: true,
          emptyDisplay: '-',
          headerProps: {
            className: 'gridColHeader__sort',
          },
        })
        .addColumn({
          name: 'comments',
          title: t('Entities.Timecard.comments'),
          type: 'string',
          align: 'center',
          sortable: false,
          filterType: 'string',
          filterOptions: 'simple',
          filterable: true,
          fixed: 'none',
          disableGrouping: true,
          disableColumnMenuTool: true,
          width: getCommentSize(),
          emptyDisplay: '-',
        })
        .addColumn({
          name: 'action',
          title: t('generic.action'),
          type: 'button',
          align: mobileSize ? 'center' : 'end',
          sortable: false,
          editable: false,
          filterType: 'none',
          fixed: 'right',
          disableGrouping: true,
          disableHideable: true,
          disableColumnMenuTool: true,
          width: mobileSize ? 60 : 140,
          headerProps: {
            style: {
              textAlign: 'center',
            },
          },
          renderFunction: (data: OverdueTimesheetsResponse) => (
            <ActionButton
              actionItems={[
                ...(data.status !== 'Submitted'
                  ? [
                      {
                        componentName: t('Entities.TimeSheet.notify'),
                        icon: (
                          <NotificationImportantIcon
                            color="warning"
                            data-testid="notify-icon"
                          />
                        ),
                        title: t('Entities.TimeSheet.notify'),
                        handleClick: () => {
                          _handleNotifyEmployee(data);
                        },
                      },
                    ]
                  : []),
                ...(data.status === 'Submitted'
                  ? [
                      {
                        componentName: t('Entities.TimeSheet.approve'),
                        icon: <CheckIcon color="success" />,
                        title: t('Entities.TimeSheet.approve'),
                        handleClick: () =>
                          setSelectedTimesheet(data.timesheetId),
                      },
                    ]
                  : []),
                ...(data.missing
                  ? [
                      {
                        componentName: t('Entities.TimeSheet.createAndView'),
                        icon: <PostAddIcon />,
                        title: t('Entities.TimeSheet.createAndView'),
                        handleClick: () => _handleCreateMissingTimesheet(data),
                      },
                    ]
                  : []),
                ...(!data.missing
                  ? [
                      {
                        componentName: t('Entities.TimeSheet.viewDetails'),
                        title: t('Entities.TimeSheet.viewDetails'),
                        icon: <ArrowForwardIosRoundedIcon color="primary" />,
                        handleClick: () =>
                          navigate(
                            `/timeTracking/timesheets/${data?.timesheetId}`,
                            {
                              state: browserLocation.pathname,
                            }
                          ),
                      },
                    ]
                  : []),
              ]}
            />
          ),
        })
        .finalize(t);
    }
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    _handleNotifyEmployee,
    browserLocation.pathname,
    mobileSize,
    navigate,
    ready,
    t,
  ]);

  async function _doQuery(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-unused-vars
    context: QueryFunctionContext<QueryKey, any>,
    // eslint-disable-next-line no-unused-vars
    pageSize: number,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-unused-vars
    queryData: { filters: any; orderBy: any }
  ) {
    const d = await getOverdueTimesheets({
      approverId: authContext.user?.id,
    });
    const handleColumnsMap = () => {
      const newCols = d.map((r) => {
        let status = r?.status;
        if (!r.status && r.missing === true) {
          status = 'Missing';
        }
        return {
          ...r,
          id: uniqueId(),
          status,
        };
      });
      return newCols;
    };
    handleColumnsMap();
    return {
      ...d,
      records: handleColumnsMap(),
    };
  }

  const ExportBtn = ExportButton({ gridRef });
  const employeeTimeSheet = useQuery(
    [TABLE_ID, selectedTimesheet],
    () =>
      getTimeSheet(selectedTimesheet as number)
        .then((d) => d)
        .catch((e) => {
          // Questionable, should investigate shape of error returned to properly handle, taken from TimeTracker Weekly Tab
          if (e?.errors?.errors?.dateInTimeSheet) {
            messageContext.setError(e.errors.errors.dateInTimeSheet);
          } else {
            messageContext.setError(e.message ?? e);
          }
        }),
    {
      enabled: Boolean(selectedTimesheet),
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
    }
  );

  const CustomOnSelectionChange = useCallback(
    (selectionProps) => {
      const { selected, data } = selectionProps;

      if (Array.isArray(data) && !isEmpty(data)) {
        const filterData = data.filter(
          (selectedItem) => selectedItem?.status !== 'Submitted'
        );
        setSelectAllRowsData(() => filterData);
      }

      return setSelectedRows(() => selected);
    },
    [setSelectAllRowsData, setSelectedRows]
  );
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {ready && cols && (
        <>
          <TetoContainer>
            <TetoGrid
              dataSource={dataSource}
              setDataSource={setDataSource}
              disableMobileCols
              virtualized={false}
              ref={gridRef}
              pageSize={50000}
              externalQueryProps={{}}
              doQuery={(a, b, c) =>
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                _doQuery(a, b, c as any) as unknown as Promise<
                  PagedResponse<Record<string, unknown>>
                >
              }
              columns={cols}
              refreshToken={refreshToken}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              setSelectAllRowsData={setSelectAllRowsData}
              header={{
                rightChildren: [NotifyEmployeeButton, ExportBtn],
              }}
              tableIdentifier={TABLE_ID}
              queryIdentifier={queryIdentifier}
              checkboxColumn
              disableConfigureButton
              CustomCheckbox={OverdueTimesheetsGridCheckbox}
              CustomOnSelectionChange={CustomOnSelectionChange}
              unControlledSort
              clientSideFiltering
              showEmptyRows
            />
          </TetoContainer>
          {isEmailInspectorOpen && (
            <EmailInspector
              open={isEmailInspectorOpen}
              handleClose={() => setIsEmailInspectorOpen(false)}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              selectAllRowsData={selectAllRowsData}
              setSelectAllRowsData={setSelectAllRowsData}
              setRefreshToken={setRefreshToken}
            />
          )}
          {employeeTimeSheet.data && (
            <TimesheetInspectorWrapper
              timesheet={employeeTimeSheet.data}
              open={Boolean(selectedTimesheet)}
              onClose={() => setSelectedTimesheet(undefined)}
              tableIdentifier={TABLE_ID}
              action="approve"
            />
          )}
        </>
      )}
    </>
  );
};

export default OverdueTimesheetsPage;
