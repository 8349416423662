import dayjs, { Dayjs } from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

export const formatMonthlyURLDate = (date: Dayjs | Date | string) =>
  formatDate(date, 'MM-YYYY');

export const formatDate = (date: Dayjs | Date | string, format: string) =>
  dayjs(date).format(format);

export const formatWeeklyURLDate = (date: Dayjs | Date) =>
  formatDate(date, 'YYYY-MM-DD');

export const formatStringToDate = (date: string) => dayjs(date).toDate();

export const formatHoursAsHourMin = (hours: number): string => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let h: any = String(Math.trunc(hours)).padStart(2, '0');
  let m = String(Math.abs(Math.round((hours - h) * 60))).padStart(2, '0');
  if (m === '60') {
    h = (parseInt(h, 10) + 1).toString();
    m = '00';
  }
  return `${h}:${m}`;
};
