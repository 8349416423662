import { Theme } from '@mui/material/styles';

const useCommonStyles = (theme: Theme) => ({
  sectionTitle: {
    color: theme.palette.text.primary,
    fontWeight: 'bold',
  },
  subSectionTitle: {
    fontSize: '1.05em',
  },
  textThemeColor: {
    color: theme.palette.text.primary,
  },
  licenseList: {
    padding: theme.spacing(0),
    [theme.breakpoints.up('md')]: {
      direction: 'rtl',
    },
    '& li': {
      textTransform: 'capitalize',
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
    },
  },
  saveBtn: {
    alignSelf: 'flex-end',
    marginTop: 'auto',
  },
});

export default useCommonStyles;
