import { Theme } from '@mui/material/styles';

export const WIDTH = 250;
export const PADDING = 4;

export default {
  root: (theme: Theme) => ({
    height: '100%',
    width: '100%',
    position: 'absolute',
    zIndex: 10000,
    top: -1,
    left: 0,
    backgroundColor: theme.palette.background.default,
    color: theme.palette.getContrastText(theme.palette.background.default),
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.getContrastText(theme.palette.primary.main),
    },
    '& .MuiInputBase-input': {
      color: theme.palette.text.primary,
      fontSize: '0.9rem',
    },
  }),
  input: {
    height: '100%',
    width: '100%',
  },
  error: (theme: Theme) => ({
    border: `1px solid ${theme.palette.error.main}`,
    color: theme.palette.error.main,
  }),
  edit: (theme: Theme) => ({
    border: `1px solid ${theme.palette.primary.main}`,
  }),
  helper: (theme: Theme) => ({
    color: theme.palette.error.main,
    position: 'relative',
    zIndex: 45,
    top: theme.spacing(3.5),
  }),
};

export const storyStyles = {
  rootSx: {
    width: WIDTH,
    height: 48,
    position: 'relative',
  },
  containerSx: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-start',
    position: 'relative',
    height: 90,
    padding: PADDING,
    '& .MuiSnackbar-root.MuiSnackbar-anchorOriginBottomCenter.MuiSnackbar-root':
      {
        backgroundColor: 'red !important',
      },
  },
  popSx: {
    '& .MuiPaper-root.MuiPaper-elevation': {
      backgroundColor: 'success.main !important',
    },
  },
};
