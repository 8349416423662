import { Box, Theme } from '@mui/material';
import {
  ButtonStrip,
  ETODateTimeField,
  Inspector,
  MessageContext,
  SettingsContext,
} from '@teto/react-component-library';
import dayjs, { Dayjs } from 'dayjs';
import { t } from 'i18next';
import * as React from 'react';
import RDGSelectedType from '../../../components/TetoGrid/RDGSelectedType';
import { getGraphQLClient } from '../../../helpers/graphQL/graphQLClient';
import postUpdateClockInMutation from '../queries/postUpdateClockInMutation';

const contentSx = {
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
};

const buttonRowSx = (theme: Theme) => ({
  flex: `0 0 ${theme.spacing(7.5)}`,
  flexGrow: 0,
});

interface EditClockInsInspectorProps {
  title: string;
  open: boolean;
  onClose: () => void;
  data: RDGSelectedType;
  setRefreshToken: () => void;
}

const EditClockInsInspector = (props: EditClockInsInspectorProps) => {
  const { title, open, onClose, data, setRefreshToken } = props;
  const { id, startTime, endTime } = data;
  const messageContext = React.useContext(MessageContext);
  const settingsContext = React.useContext(SettingsContext);
  const [clockIn, setClockIn] = React.useState<Dayjs | null | undefined>(
    dayjs(startTime)
  );
  const [clockOut, setClockOut] = React.useState<Dayjs | undefined>(
    dayjs(endTime)
  );

  const variables = {
    id,
    startTime: clockIn,
    endTime: clockOut,
  };

  const _handleClockIn = (d: Dayjs | null | undefined) => {
    setClockIn(d);
  };

  const _handleClockOut = (d: Dayjs | undefined) => {
    setClockOut(d);
  };
  const _onSubmit = async () =>
    getGraphQLClient()
      .performMutation(
        postUpdateClockInMutation,
        variables,
        (err) => {
          messageContext.setError(err.messages[0]);
        },
        () => {
          // @TODO Handle Validation
        }
      )
      .then((d) => {
        if (d) {
          messageContext.setSuccess(t('generic.message.clockInsUpdate'));
          setRefreshToken();
          onClose();
        }
      });

  return (
    <Inspector open={open} title={title} onClose={onClose}>
      <Box sx={contentSx}>
        <Box mt={2}>
          <ETODateTimeField
            name="clockInTme"
            value={clockIn}
            handleChange={(d) => _handleClockIn(d)}
            label={t('Entities.ClockIns.clockIn')}
            inputFormat={settingsContext.settings.dateTimeFormat}
          />
        </Box>
        <Box mt={2}>
          <ETODateTimeField
            name="clockOutTime"
            value={clockOut}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            handleChange={(d) => _handleClockOut(d as any)}
            label={t('Entities.ClockIns.clockOut')}
            inputFormat={settingsContext.settings.dateTimeFormat}
          />
        </Box>
      </Box>
      <Box sx={buttonRowSx}>
        <ButtonStrip
          size="medium"
          rightButton={{
            text: 'Save',
            color: 'primary',
            disabled: !clockOut || !clockIn,
            onClick: () => _onSubmit(),
          }}
        />
      </Box>
    </Inspector>
  );
};

export default EditClockInsInspector;
