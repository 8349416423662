import dayjs from 'dayjs';

function parseTreeItem(
  item: Record<string, unknown>,
  results: { [key: string]: unknown },
  rootKey: string,
  depth: number,
  maxDepth: number
) {
  const newDepth = depth + 1;
  if (newDepth > maxDepth) return;
  Object.entries(item).forEach((a) => {
    if (
      Array.isArray(a[1]) ||
      typeof a[1] === 'function' ||
      a[1] == null ||
      a[1] === undefined
    )
      return;
    if (typeof a[1] === 'object') {
      if (!(a[1] instanceof Date) && !dayjs.isDayjs(a[1])) {
        parseTreeItem(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          a[1] as any,
          results,
          `${rootKey + a[0]}.`,
          newDepth,
          maxDepth
        );
      } else {
        // eslint-disable-next-line prefer-destructuring, no-param-reassign
        results[rootKey + a[0]] = a[1];
      }
    } else {
      // eslint-disable-next-line prefer-destructuring, no-param-reassign
      results[rootKey + a[0]] = a[1];
    }
  });
  return results;
}
export default (object: Record<string, unknown>, maxRecursion = 3) =>
  parseTreeItem(object, {}, '', 0, maxRecursion);
