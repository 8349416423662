import {
  TypeFilterValue,
  TypeGroupBy,
  TypeSingleSortInfo,
  TypeSortInfo,
} from '@inovua/reactdatagrid-community/types';
import SettingsBackupRestoreRounded from '@mui/icons-material/SettingsBackupRestoreRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import { MessageContext } from '@teto/react-component-library';

import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ActionBar, {
  ActionBarProps,
  CustomRightActionType,
} from '../ActionBar/ActionBar';
import ConfigureInspector from './ConfigureInspector/ConfigureInspector';
import { ConfigureTab } from './ConfigureInspector/ConfigureTab';
import GridSettings from './GridSettings';
import TableColumnDefinition from './TableColumnDefinition';

export interface GridConfigurationHeaderProps extends ActionBarProps {
  defaultConfigTab?: { tab: string; option?: string };
  disableConfigureButton?: boolean;
  disableResetButton?: boolean;
  columns: TableColumnDefinition[];
  columnOrder: string[];
  onReset: () => void;
  // eslint-disable-next-line no-unused-vars
  onSettingsUpdate: (settings: GridSettings) => void;
  configTabs?: ConfigureTab[];
  grouping?: TypeGroupBy;
  // eslint-disable-next-line no-unused-vars
  setGrouping?: (grouping: TypeGroupBy) => void;
  sorting?: TypeSingleSortInfo[];
  // eslint-disable-next-line no-unused-vars
  setSorting?: (sorting: TypeSortInfo) => void;
  filters?: TypeFilterValue;
  // eslint-disable-next-line no-unused-vars
  setFilters?: (filter: TypeFilterValue) => void;
  mobileGridOptions?: boolean;
  disableAllButtons?: boolean;
}

const GridConfigurationHeader = (props: GridConfigurationHeaderProps) => {
  const {
    defaultConfigTab,
    withBottomNav,
    headerStyles,
    FABPosition,
    columns,
    leftChildren,
    rightChildren,
    bottomChildren,
    onSettingsUpdate,
    disableConfigureButton,
    disableResetButton,
    columnOrder,
    onReset,
    configTabs,
    grouping,
    setGrouping,
    sorting,
    setSorting,
    filters,
    setFilters,
    mobileGridOptions,
    disableAllButtons,
  } = props;

  const { t } = useTranslation();
  const [configureInspector, setConfigureInspector] = useState<boolean>(false);
  const messageContext = useContext(MessageContext);

  const rightButtons = useCallback(() => {
    if (disableAllButtons) {
      const disabledButtons = rightChildren?.map((button) =>
        button
          ? { ...(button as CustomRightActionType), disabled: true }
          : false
      );
      if (disabledButtons) {
        return disabledButtons;
      }
      return [];
    }
    return rightChildren ?? [];
  }, [disableAllButtons, rightChildren]);

  const rightMenuActions = useMemo(
    () => [
      ...(rightButtons() || []),
      !disableResetButton
        ? {
            disabled: disableAllButtons,
            title: t('generic.resetGridSettings'),
            icon: <SettingsBackupRestoreRounded />,
            confirm: {
              title: t('dialogs.resetGrid.title'),
              content: t('dialogs.resetGrid.content'),
              type: 'okCancel' as const,
            },
            onclick: () => {
              onReset();
              messageContext.setSuccess(t('generic.message.settingsUpdate'));
            },
          }
        : false,
      !disableConfigureButton
        ? {
            disabled: disableAllButtons,
            title: t('generic.configure'),
            icon: <SettingsRoundedIcon />,
            onclick: () => setConfigureInspector(true),
          }
        : false,
    ],
    [
      disableAllButtons,
      disableConfigureButton,
      disableResetButton,
      messageContext,
      onReset,
      rightButtons,
      t,
    ]
  );
  // const rightMenuActions = [
  //   ...(rightButtons() || []),
  //   !disableResetButton
  //     ? {
  //         disabled: disableAllButtons,
  //         title: t('generic.resetGridSettings'),
  //         icon: <SettingsBackupRestoreRounded />,
  //         confirm: {
  //           title: t('dialogs.resetGrid.title'),
  //           content: t('dialogs.resetGrid.content'),
  //           type: 'okCancel' as const,
  //         },
  //         onclick: () => {
  //           onReset();
  //           messageContext.setSuccess(t('generic.message.settingsUpdate'));
  //         },
  //       }
  //     : false,
  //   !disableConfigureButton
  //     ? {
  //         disabled: disableAllButtons,
  //         title: t('generic.configure'),
  //         icon: <SettingsRoundedIcon />,
  //         onclick: () => setConfigureInspector(true),
  //       }
  //     : false,
  // ];

  return (
    <>
      <ActionBar
        {...props}
        headerStyles={headerStyles}
        FABPosition={FABPosition}
        leftChildren={leftChildren}
        rightChildren={rightMenuActions}
        bottomChildren={bottomChildren}
        withBottomNav={withBottomNav}
      />
      {configureInspector && (
        <ConfigureInspector
          open={configureInspector}
          columns={columns}
          initialColumnOrder={columnOrder}
          setConfigureInspector={setConfigureInspector}
          handleClose={() => setConfigureInspector(() => false)}
          onReset={onReset}
          onColumnSettingsSave={onSettingsUpdate}
          tabs={configTabs}
          grouping={grouping}
          setGrouping={setGrouping}
          sorting={sorting}
          setSorting={setSorting}
          filters={filters}
          setFilters={setFilters}
          mobileGridOptions={mobileGridOptions}
          defaultTab={defaultConfigTab}
        />
      )}
    </>
  );
};

export default React.memo(GridConfigurationHeader);
