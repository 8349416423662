import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';
import { IconButton, Tooltip } from '@mui/material';
import React from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const FilterIcon = (title: string, onClick?: any) => (
  <Tooltip title={title} arrow disableInteractive enterDelay={1000}>
    <IconButton
      sx={{
        padding: 0,
      }}
      onClick={onClick}
    >
      <FilterAltRoundedIcon color="primary" />
    </IconButton>
  </Tooltip>
);

export default FilterIcon;
