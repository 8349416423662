import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import {
  Box,
  Drawer,
  DrawerProps,
  SxProps,
  Theme,
  TooltipProps,
  Typography,
} from '@mui/material';
import { ETOIconButton } from '@teto/react-component-library';
import React from 'react';
import { useTranslation } from 'react-i18next';

export interface InspectorButtonOptionsI {
  name: string;
  // eslint-disable-next-line no-undef
  icon: JSX.Element;
  onClick: () => void;
  disabled?: boolean;
  tooltipProps?: Omit<TooltipProps, 'children'>;
}

interface InspectorProps extends Omit<DrawerProps, 'open' | 'close'> {
  title: string;
  open: boolean;
  children?: React.ReactNode;
  customSx?: SxProps<Theme>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  customButtonStripSx?: { [key: string]: any };
  options?: InspectorButtonOptionsI[];
  onClose: () => void;
  buttonStrip?: React.ReactNode;
  headerIcon?: React.ReactElement;
}

const inspectorSx = {
  position: 'relative',
  '& .MuiBackdrop-root': {
    opacity: '0.1 !important',
  },
};

const paperSx: SxProps<Theme> = (theme: Theme) => ({
  backgroundImage: 'none',
  boxShadow: '-1px 0px 4px rgba(0, 0, 0, 0.25)',
  padding: theme.spacing(3),
  width: theme.spacing(70),
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2),
    width: '100%',
    maxWidth: '100vw',
  },
});

const headerSx: SxProps = {
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
};

const headerTextSx: SxProps = {
  flexGrow: 1,
};

const headerButtonSx: SxProps = {
  alignSelf: 'flex-end',
  flexGrow: 0,
};

const mainContentSx: SxProps<Theme> = (theme: Theme) => ({
  flexGrow: 1,
  paddingTop: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden', // content should support scroll if needed
});

const buttonStripContainerSx = (theme: Theme) => ({
  bottom: theme.spacing(3),
  position: 'fixed',
  [theme.breakpoints.down('md')]: {
    bottom: theme.spacing(2),
  },
});

const Inspector = (props: InspectorProps) => {
  const {
    title,
    children,
    onClose,
    buttonStrip,
    open,
    customSx,
    options,
    customButtonStripSx,
    headerIcon,
  } = props;
  const { t } = useTranslation();

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      sx={[inspectorSx, ...(Array.isArray(customSx) ? customSx : [customSx])]}
      PaperProps={{
        sx: paperSx,
      }}
    >
      <Box sx={headerSx}>
        {headerIcon}
        <Typography variant="h6" sx={headerTextSx}>
          {title}
        </Typography>

        {options && (
          <>
            {options.map((option) => (
              <ETOIconButton
                key={option.name}
                customSx={headerButtonSx as SxProps}
                size="medium"
                color="primary"
                onClick={() => option.onClick()}
                disabled={option.disabled}
                tooltipProps={option.tooltipProps}
              >
                {option.icon}
              </ETOIconButton>
            ))}
          </>
        )}

        <ETOIconButton
          size="medium"
          color="primary"
          onClick={onClose}
          customSx={headerButtonSx as SxProps}
          tooltipProps={{ title: t('generic.close'), leaveTouchDelay: 0 }}
        >
          <CloseRoundedIcon />
        </ETOIconButton>
      </Box>

      {children && (
        <Box
          sx={[
            mainContentSx,
            ...(Array.isArray(customSx) ? customSx : [customSx]),
          ]}
        >
          {children}
        </Box>
      )}

      {buttonStrip && (
        <Box
          sx={[
            buttonStripContainerSx,
            { ...(customButtonStripSx && customButtonStripSx) },
          ]}
        >
          {buttonStrip}
        </Box>
      )}
    </Drawer>
  );
};

export default Inspector;
