/* eslint-disable func-names */
/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import TableColumnDefinition from '../TableColumnDefinition';
import BitwiseFormatter from './BitwiseFormatter';
import BoolFormatter from './BoolFormatter';
import CustomFormatter from './CustomFormatter';
import DateFormatter from './DateFormatter';
import DateTimeFormatter from './DateTimeFormatter';
import FormatterProps from './FormatterProps';
import NumberFormatter from './NumberFormatter';
import StringFormatter from './StringFormatter';
import TimeFormatter from './TimeFormatter';

export const buildCellFormatter = (col: TableColumnDefinition) => {
  switch (col.type) {
    case 'boolean':
      return function (d: FormatterProps) {
        return <BoolFormatter {...d} column={col} />;
      };
    case 'custom':
      return function (d: FormatterProps) {
        return <CustomFormatter {...d} column={col} />;
      };
    case 'button':
      return function (d: FormatterProps) {
        return <CustomFormatter disableInGroup {...d} column={col} />;
      };
    case 'date':
      return function (d: FormatterProps) {
        return <DateFormatter {...d} column={col} />;
      };
    case 'time':
      return function (d: FormatterProps) {
        return <TimeFormatter {...d} column={col} />;
      };
    case 'datetime':
      return function (d: FormatterProps) {
        return <DateTimeFormatter {...d} column={col} />;
      };
    case 'hours':
      return function (d: FormatterProps) {
        return <NumberFormatter numberFormat="hours" {...d} column={col} />;
      };
    case 'bitwise':
      return function (d: FormatterProps) {
        return <BitwiseFormatter {...d} column={col} />;
      };
    case 'number':
      return function (d: FormatterProps) {
        return <NumberFormatter numberFormat="number" {...d} column={col} />;
      };
    case 'currency':
      return function (d: FormatterProps) {
        return <NumberFormatter numberFormat="currency" {...d} column={col} />;
      };
    case 'foreignKey':
    case 'string':
      return function (d: FormatterProps) {
        return <StringFormatter {...d} column={col} />;
      };
    default:
      return function ({ value }: FormatterProps) {
        return <span>{value}</span>;
      };
  }
};
