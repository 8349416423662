import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import UndoRoundedIcon from '@mui/icons-material/UndoRounded';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TimeSheet } from 'teto-client-api';
import { CustomRightActionType } from '../../../components/ActionBar/ActionBar';
import TimesheetSelection from './TimesheetsDetailPageCommonTypes';

const AdminTimesheetActionBarButtons = (
  timesheet: TimeSheet,
  setSelectedTimesheet: React.Dispatch<
    React.SetStateAction<TimesheetSelection | undefined>
  >
): boolean | CustomRightActionType => {
  const { status } = timesheet;
  const { t } = useTranslation();
  const buttons = [
    status === 'Submitted' && {
      title: t('Entities.TimeSheet.approve'),
      icon: <CheckRoundedIcon />,
      onclick: () => setSelectedTimesheet({ timesheet, action: 'approve' }),
    },
    status === 'Submitted' && {
      title: t('Entities.TimeSheet.reject'),
      icon: <CloseRoundedIcon />,
      onclick: () => setSelectedTimesheet({ timesheet, action: 'reject' }),
    },
    status === 'Approved' && {
      title: t('Entities.TimeSheet.unapprove'),
      icon: <UndoRoundedIcon />,
      onclick: () => setSelectedTimesheet({ timesheet, action: 'unapprove' }),
    },
    status === 'Rejected' && {
      title: t('Entities.TimeSheet.unreject'),
      icon: <UndoRoundedIcon />,
      onclick: () => setSelectedTimesheet({ timesheet, action: 'unreject' }),
    },
  ] as unknown as boolean | CustomRightActionType;
  return buttons;
};

export default AdminTimesheetActionBarButtons;
