/* eslint-disable @typescript-eslint/no-explicit-any */
import ReactDataGrid from '@inovua/reactdatagrid-enterprise';

const filterTypes = {
  select: ReactDataGrid.defaultProps.filterTypes.select,
  date: ReactDataGrid.defaultProps.filterTypes.date,
  time: ReactDataGrid.defaultProps.filterTypes.date,
  datetime: ReactDataGrid.defaultProps.filterTypes.date,
  none: {
    type: '',
    emptyValue: '',
    operators: [],
  },
  bool: {
    type: 'bool',
    emptyValue: '',
    operators: [
      {
        name: 'equal',
        fn: ({ value, filterValue }: any) => value === filterValue,
      },
    ],
  },
  boolean: {
    type: 'boolean',
    emptyValue: '',
    operators: [
      {
        name: 'equal',
        fn: ({ value, filterValue }: any) => value === filterValue,
      },
    ],
  },
  number: {
    type: 'number',
    emptyValue: '',
    min: 0,
    operators: [
      {
        name: 'equal',
        fn: ({ value, filterValue }: any) => value === filterValue,
      },
      {
        name: 'notEqual',
        fn: ({ value, filterValue }: any) => value !== filterValue,
      },
      {
        name: 'greaterThan',
        fn: ({ value, filterValue }: any) =>
          !value ? false : value > filterValue,
      },
      {
        name: 'lessThan',
        fn: ({ value, filterValue }: any) =>
          !filterValue ? false : value < filterValue,
      },
    ],
  },
  currency: {
    type: 'currency',
    emptyValue: '',
    min: 0,
    operators: [
      {
        name: 'equal',
        fn: ({ value, filterValue }: any) => value === filterValue,
      },
      {
        name: 'notEqual',
        fn: ({ value, filterValue }: any) => value !== filterValue,
      },
      {
        name: 'greaterThan',
        fn: ({ value, filterValue }: any) =>
          !value ? false : value > filterValue,
      },
      {
        name: 'lessThan',
        fn: ({ value, filterValue }: any) =>
          !filterValue ? false : value < filterValue,
      },
    ],
  },
  string: {
    type: 'string',
    emptyValue: '',
    operators: [
      {
        name: 'contains',
        fn: ({
          value,
          filterValue,
        }: {
          value?: string | { [key: string]: any };
          filterValue?: string;
        }) => {
          if (!value || !filterValue) {
            return false;
          }
          if (
            typeof value === 'object' &&
            !Array.isArray(value) &&
            value !== null
          ) {
            const isValueFound =
              value.name.toLowerCase().indexOf(filterValue) >= 0;
            return isValueFound;
          }

          if (typeof value === 'string') {
            const wasValueFound =
              value?.toLowerCase().indexOf(filterValue) >= 0;
            return wasValueFound;
          }
          // (!value ? false : !filterValue || value?.toLowerCase().indexOf(filterValue) >= 0); bug #72404
        },
      },
      {
        name: 'equal',
        fn: ({ value, filterValue }: any) => value === filterValue,
      },
      {
        name: 'notEqual',
        fn: ({ value, filterValue }: any) => value !== filterValue,
      },
      {
        name: 'startsWith',
        fn: ({
          value,
          filterValue,
        }: {
          value?: string;
          filterValue?: string;
        }) =>
          !value
            ? false
            : !filterValue || value.toLowerCase().startsWith(filterValue),
      },
      {
        name: 'endsWith',
        fn: ({
          value,
          filterValue,
        }: {
          value?: string;
          filterValue?: string;
        }) =>
          !value
            ? false
            : !filterValue || value.toLowerCase().endsWith(filterValue),
      },
    ],
  },
};

export default filterTypes;
