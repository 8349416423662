import { Box, Collapse, Fade, Grid, Theme, Typography } from '@mui/material';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import dayjs from 'dayjs';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TimeSheet } from 'teto-client-api';
import { ETOIconButton, SettingsContext } from '@teto/react-component-library';

const commentsWrapSx = {
  display: 'flex',
  alignItems: 'center',
};

const commentsTitleSx = (theme: Theme) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  position: 'relative',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'row',
    maxHeight: theme.spacing(4),
  },
});

const commentsTopSx = (theme: Theme) => ({
  display: 'flex',
  alignItems: 'center',
  padding: `${theme.spacing(1)} 0`,
});

const commentsSx = (theme: Theme) => ({
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  whiteSpace: 'pre-line',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const statusDateSx = (theme: Theme) => ({
  color: theme.palette.text.disabled,
});

const expandedSx = (theme: Theme) => ({
  padding: theme.spacing(1),
  whiteSpace: 'pre-wrap',
  '& .MuiCollapse-wrapperInner': {
    maxHeight: theme.spacing(20),
    overflowY: 'auto',
  },
});

const TimesheetComments = (timeSheet: TimeSheet) => {
  const { t } = useTranslation();
  const { statusComments, statusDate } = timeSheet;
  const settingsContext = useContext(SettingsContext);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <Grid item xs={12}>
      <Box sx={commentsWrapSx}>
        <Box sx={commentsTitleSx}>
          <Box sx={commentsTopSx}>
            <ETOIconButton
              size="large"
              color="primary"
              onClick={() => setIsOpen((d) => !d)}
              tooltipProps={{
                title: t(`generic.${isOpen ? 'hideContent' : 'viewContent'}`),
                leaveTouchDelay: 0,
              }}
            >
              {isOpen ? <ExpandLessRoundedIcon /> : <ExpandMoreRoundedIcon />}
            </ETOIconButton>
            <Typography variant="subtitle2">
              {t('forms.fields.comments')}:&nbsp;
              {isOpen && (
                <Fade in={isOpen} timeout={150}>
                  <Box sx={statusDateSx}>
                    {dayjs(statusDate).format(
                      settingsContext.settings.dateFormat
                    )}
                  </Box>
                </Fade>
              )}
            </Typography>
          </Box>
        </Box>

        <Collapse
          sx={[commentsSx, isOpen ? expandedSx : null]}
          in={isOpen}
          collapsedSize={20}
        >
          <Typography variant="body2">{statusComments}</Typography>
        </Collapse>
      </Box>
    </Grid>
  );
};

export default TimesheetComments;
