import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { MutationKey, useMutation, useQueryClient } from 'react-query';
import { unsubmitTimesheet } from 'teto-client-api';
import { MessageContext } from '@teto/react-component-library';
import TIMESHEET_QUERY_ID from '../../../api/reactQuery/TimesheetsQueryId';

// https://react-query.tanstack.com/reference/useMutation

const useUnsubmitTimesheet = () => {
  const queryClient = useQueryClient();
  const messageContext = useContext(MessageContext);
  const { t } = useTranslation();

  const _handleUnsubmit = async (props: { id: number; message: string }) => {
    const { id } = props;

    await unsubmitTimesheet(id);
  };
  const mutation = useMutation<unknown, Error, { id: number }>(
    _handleUnsubmit as unknown as MutationKey,
    {
      onSuccess: () => {
        const queryIdentifier = TIMESHEET_QUERY_ID;

        queryClient.invalidateQueries(queryIdentifier, {
          refetchActive: true,
          refetchInactive: true,
        });
        messageContext.setSuccess(t('generic.message.timesheetUnsubmit'));
      },
      onError: (e) => {
        messageContext.setError(e.message);
      },
    }
  );

  return mutation;
};

export default useUnsubmitTimesheet;
