import { Box, Theme } from '@mui/material';
import {
  ButtonStrip,
  ETODateField,
  Inspector,
  MessageContext,
  SettingsContext,
} from '@teto/react-component-library';
import dayjs from 'dayjs';
import { FormikHelpers, useFormik } from 'formik';
import { t } from 'i18next';
import React, { useContext } from 'react';
import { PayPeriod, updatePayPeriod } from 'teto-client-api';
import * as Yup from 'yup';
import { parseServerResponse } from '../../../helpers/validationHelperREST';

const contentSx = {
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
};

const buttonRowSx = (theme: Theme) => ({
  flex: `0 0 ${theme.spacing(7.5)}`,
  flexGrow: 0,
});

interface EditPayPeriodInspectorProps {
  title: string;
  open: boolean;
  onClose: () => void;
  data: PayPeriod;
  setRefreshToken: React.Dispatch<React.SetStateAction<Date | undefined>>;
}

const REQUIRED_MESSAGE = 'Required';

const YupSchema = Yup.object().shape({
  startDate: Yup.object()
    .required(REQUIRED_MESSAGE)
    .nullable()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .test('date-validation', 'Invalid Date', (val: any) => dayjs.isDayjs(val)),
  endDate: Yup.object()
    .required(REQUIRED_MESSAGE)
    .nullable()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .test('date-validation', 'Invalid Date', (val: any) => dayjs.isDayjs(val)),
});

const EditPayPeriodInspector = (props: EditPayPeriodInspectorProps) => {
  const { title, open, onClose, data, setRefreshToken } = props;

  const settingsContext = useContext(SettingsContext);
  const messageContext = useContext(MessageContext);

  const formik = useFormik<PayPeriod>({
    enableReinitialize: true,
    validationSchema: YupSchema,
    initialValues: { ...data },
    onSubmit: (values, actions) => _onSubmit(values, actions),
  });

  const _onSubmit = (values: PayPeriod, actions: FormikHelpers<PayPeriod>) => {
    const { startDate, endDate, id } = values;

    if (id && startDate && endDate) {
      updatePayPeriod(id, {
        startDate: startDate.utc(true),
        endDate: endDate.utc(true),
      })
        .then(() => {
          actions.setSubmitting(false);
          setRefreshToken(new Date());
          onClose();
          messageContext.setSuccess(t('generic.message.payPeriodUpdated'));
        })
        .catch((e) => {
          actions.setSubmitting(false);
          parseServerResponse(
            e,
            (errors) => messageContext.setError(Object.values(errors)[0]),
            (error) => messageContext.setError(error)
          );
        });
    }
  };

  return (
    <Inspector open={open} title={title} onClose={onClose}>
      <Box sx={contentSx} component="form" onSubmit={formik.handleSubmit}>
        <Box mt={3}>
          <ETODateField
            name="payPeriodStartDate"
            value={formik.values.startDate}
            handleChange={(e) => formik.setFieldValue('startDate', e)}
            label={t('generic.startDate')}
            inputFormat={settingsContext.settings.dateFormat}
            clearable
            error={formik.errors.startDate as string}
          />
        </Box>
        <Box mt={3}>
          <ETODateField
            name="payPeriodEndDate"
            value={formik.values.endDate}
            handleChange={(e) => formik.setFieldValue('endDate', e)}
            label={t('generic.endDate')}
            inputFormat={settingsContext.settings.dateFormat}
            clearable
            error={formik.errors.endDate as string}
          />
        </Box>
      </Box>
      <Box sx={buttonRowSx}>
        <ButtonStrip
          size="medium"
          rightButton={{
            text: 'Save',
            color: 'primary',
            onClick: () => formik.handleSubmit(),
          }}
        />
      </Box>
    </Inspector>
  );
};

export default EditPayPeriodInspector;
