import { TypeComputedProps } from '@inovua/reactdatagrid-community/types';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import StopRoundedIcon from '@mui/icons-material/StopRounded';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import {
  AuthContext,
  MessageContext,
  SettingsContext,
} from '@teto/react-component-library';
import dayjs from 'dayjs';
import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { QueryFunctionContext, QueryKey } from 'react-query';
import { Employee, Licenses, PagedResponse, Permission } from 'teto-client-api';
import { createColumns } from '../../../components/TetoGrid/ModelMetaDataProcessor/ModelMetaDataProcessor';
import TetoGrid from '../../../components/TetoGrid/TetoGrid';
import { formatHoursAsHourMin } from '../../../helpers/dateHelpers';
import { getGraphQLClient } from '../../../helpers/graphQL/graphQLClient';
import ActionButton, {
  OkCancelConfirmDialog,
} from '../../Timesheets/ApprovalsPage/components/ActionButton/ActionButton';
import ClockIn from '../ClockIn';
import ClockInValidation from '../helpers/ClockInValidation';
import deleteClockInMutation from '../queries/deleteClockInMutation';
import postUpdateClockInMutation from '../queries/postUpdateClockInMutation';
import EditClockInsInspector from './EditClockInsInspector';
import StopClockInPopover from './StopClockInPopover';

interface RowDetailsProps {
  expandedRowData?: ClockIn[];
  rowDetails: { [key: string]: unknown };
  setRefreshToken: React.Dispatch<React.SetStateAction<Date | undefined>>;
  // eslint-disable-next-line no-unused-vars
  setSubtableEditInfo: (value?: { [key: number]: string }) => void;
}

const RowDetails = (props: RowDetailsProps) => {
  const { ready, t } = useTranslation();

  const { expandedRowData, rowDetails, setRefreshToken, setSubtableEditInfo } =
    props;

  const authContext = useContext(AuthContext);
  const messageContext = useContext(MessageContext);
  const settingsContext = useContext(SettingsContext);

  const gridRef = useRef<TypeComputedProps | null>(null);

  const [dataSource, setDataSource] = useState([]);

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isDiscardConfirmDialog, setIsDiscardConfirmDialog] =
    useState<boolean>(false);
  const [stopClockInAnchorEl, setStopClockInAnchorEl] =
    useState<HTMLButtonElement | null>(null);
  const [stoppingClockIn, setStoppingClockIn] = useState<ClockIn | undefined>();
  const [editingClockIn, setEditingClockIn] = useState<ClockIn | undefined>();

  const canModify = useMemo(
    () =>
      authContext.hasLicense(Licenses.TotalETOEnterprise) &&
      (authContext.hasPermission(Permission.Modify_Admin_Timecards) ||
        (settingsContext.settings.approverCanManageTime &&
          authContext.canApprove((rowDetails.employee as { id: number }).id))),
    [authContext, rowDetails, settingsContext.settings.approverCanManageTime]
  );

  const canDelete = useMemo(
    () =>
      authContext.hasLicense(Licenses.TotalETOEnterprise) &&
      (authContext.hasPermission(Permission.Delete_Admin_Timecards) ||
        (settingsContext.settings.approverCanManageTime &&
          authContext.canApprove((rowDetails.employee as { id: number }).id))),
    [authContext, rowDetails, settingsContext.settings.approverCanManageTime]
  );

  const canInlineEdit = useMemo(
    () =>
      authContext.hasLicense(Licenses.TotalETOEnterprise) &&
      (authContext.hasPermission(Permission.Modify_Admin_Timecards) ||
        settingsContext.settings.approverCanManageTime),
    [authContext, settingsContext.settings]
  );

  const theme = useTheme();
  const mobileSize = useMediaQuery(theme.breakpoints.down('md'));

  const _handleClockinUpdate = useCallback(() => {
    setSubtableEditInfo({
      [(rowDetails.employee as { id: number })?.id]: rowDetails.id as string,
    });
    setRefreshToken(new Date());
  }, [rowDetails, setRefreshToken, setSubtableEditInfo]);

  const _doQuery = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-unused-vars
    context: QueryFunctionContext<QueryKey, any>,
    // eslint-disable-next-line no-unused-vars
    pageSize: number,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-unused-vars
    queryData: { filters: any; orderBy: any }
  ) =>
    Promise.resolve().then(() => {
      let result: ClockIn[] = [];
      expandedRowData
        ?.filter(
          (e) => e.employee.id === (rowDetails.employee as { id: number }).id
        )
        .forEach((d) => {
          result = [...result, ...d.clockIns];
        });
      return {
        records: result,
      };
    });

  const _handleClockInDelete = useCallback(
    (data: ClockIn) => {
      getGraphQLClient()
        .performMutation(
          deleteClockInMutation,
          {
            id: data.id,
          },
          (err) => {
            messageContext.setError(err.messages[0]);
          },
          () => {
            // @TODO Handle Validation
          }
        )
        .then(() => {
          _handleClockinUpdate();
          messageContext.setSuccess(t('generic.message.deleteClockIn'));
          return Promise.resolve();
        });
    },
    [_handleClockinUpdate, messageContext, t]
  );
  const _handleStopClockInClicked = (
    e: React.MouseEvent<HTMLButtonElement>,
    data: ClockIn
  ) => {
    setStopClockInAnchorEl(e.currentTarget);
    setStoppingClockIn(data);
  };

  const _handleStopClockInClose = () => {
    setStopClockInAnchorEl(null);
    setStoppingClockIn(undefined);
  };

  const _handleEditingClockIn = (data: ClockIn) => {
    setEditingClockIn(data);
  };
  const cols = useMemo(() => {
    if (ready) {
      return createColumns<ClockIn>()
        .addColumn({
          name: 'date',
          title: t('generic.date'),
          type: 'date',
          align: 'start',
          editable: false,
          sortable: false,
          filterType: 'string',
          filterOptions: 'simple',
          fixed: 'none',
          minWidth: mobileSize ? 120 : 180,
          width: mobileSize ? 120 : 180,
          disableGrouping: true,
          disableHideable: true,
          disableColumnMenuTool: true,
        })
        .addColumn({
          name: 'startTime',
          title: t('Entities.ClockIns.clockIn'),
          type: 'time',
          align: 'start',
          editable: true,
          sortable: false,
          filterType: 'none',
          fixed: 'none',
          minWidth: 180,
          width: 180,
          defaultFlex: 1,
          disableGrouping: true,
          disableHideable: true,
          disableColumnMenuTool: true,
        })
        .addColumn({
          name: 'endTime',
          title: t('Entities.ClockIns.clockOut'),
          type: 'time',
          align: 'start',
          editable: true,
          sortable: false,
          filterType: 'none',
          fixed: 'none',
          minWidth: 180,
          width: 180,
          defaultFlex: 1,
          disableGrouping: true,
          disableHideable: true,
          disableColumnMenuTool: true,
          resizable: false,
        })
        .addColumn({
          name: 'totalDuration',
          title: t('generic.total'),
          type: 'custom',
          align: 'end',
          editable: false,
          sortable: false,
          filterType: 'none',
          fixed: 'none',
          minWidth: 65,
          width: 65,
          disableGrouping: true,
          disableHideable: true,
          disableColumnMenuTool: true,
          resizable: false,
          hidden: false,
          defaultVisible: true,
          renderFunction: (data) => {
            if (data.totalDuration) {
              return (
                <span>{formatHoursAsHourMin(data.totalDuration / 60)}</span>
              );
            }
            return <span>-</span>;
          },
        })
        .addColumn({
          name: 'action',
          title: t('generic.action'),
          type: 'button',
          align: mobileSize ? 'center' : 'end',
          sortable: false,
          editable: false,
          filterType: 'none',
          fixed: 'right',
          disableGrouping: true,
          disableHideable: true,
          disableColumnMenuTool: true,
          minWidth: mobileSize ? 65 : 115,
          width: mobileSize ? 65 : 115,
          maxWidth: mobileSize ? 65 : 115,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          renderFunction: (data: any) => (
            <ActionButton
              isGridEditing={isEditing}
              actionItems={[
                ...(!data.__editing
                  ? [
                      ...(!data.endTime
                        ? [
                            {
                              componentName: t('forms.stopTimer'),
                              icon: <StopRoundedIcon />,
                              title: t('forms.stopTimer'),
                              handleClick: (
                                e: React.MouseEvent<
                                  HTMLButtonElement,
                                  MouseEvent
                                >
                              ) => _handleStopClockInClicked(e, data),
                              disabled: !canModify,
                              color: canInlineEdit
                                ? 'error'
                                : ('grey' as 'error' | 'grey'),
                            },
                          ]
                        : [
                            {
                              componentName: t('generic.edit'),
                              title: t('forms.clockIns.editClockIn'),
                              icon: (
                                <EditIcon
                                  color={
                                    isEditing || !canModify
                                      ? 'disabled'
                                      : 'primary'
                                  }
                                />
                              ),

                              handleClick: () => _handleEditingClockIn(data),
                            },
                          ]),
                      ...(canDelete
                        ? [
                            {
                              componentName: t('generic.delete'),
                              title: t('forms.clockIns.deleteClockIn'),
                              icon: (
                                <DeleteIcon
                                  color={
                                    isEditing || !canDelete
                                      ? 'disabled'
                                      : 'error'
                                  }
                                />
                              ),
                              handleClick: () => {
                                _handleClockInDelete(data);
                              },
                              confirm: {
                                type: 'okCancel',
                                title: t('dialogs.deleteRecord.title'),
                                content: t('dialogs.deleteRecord.content'),
                                data,
                              } as OkCancelConfirmDialog,
                            },
                          ]
                        : []),
                    ]
                  : []),
              ]}
              data={data}
              setIsDiscardConfirmDialog={setIsDiscardConfirmDialog}
            />
          ),
        })
        .finalize(t);
    }
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canDelete, canModify, isEditing, mobileSize, ready, t]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const _onSubmitting = (v: any) => Promise.resolve({ ...v });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const _onSubmit = async (values: any) => {
    await getGraphQLClient()
      .performMutation(
        postUpdateClockInMutation,
        {
          id: values.id,
          startTime: dayjs(values.startTime).format(
            settingsContext.settings.dateTimeFormat
          ),
          endTime: dayjs(values.endTime).format(
            settingsContext.settings.dateTimeFormat
          ),
        },
        (err) => {
          messageContext.setError(err.messages[0]);
        },
        () => {
          // @TODO Handle Validation
        }
      )
      .then((d) => {
        const { updateClockIn } = d.data;
        if (updateClockIn !== null && !d.data.errors) {
          _handleClockinUpdate();
          messageContext.setSuccess(t('messages.recordUpdated'));
        }
      });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const _getLockedColumns = (c: string, d: any) => {
    const isNoEndTime = !d?.endTime && (c === 'endTime' || c === 'startTime');
    if (isNoEndTime) return true;
    return false;
  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <Box
      sx={{
        height: '100%',
        [theme.breakpoints.down('lg')]: {
          position: 'absolute',
          width: 'inherit',
          top: '50px',
        },
      }}
    >
      <TetoGrid
        clientSideFiltering
        columns={cols}
        dataSource={dataSource}
        disableConfigureButton
        disableGroupByToolbar
        disableMobileCols
        disableResetButton
        // eslint-disable-next-line react/jsx-no-bind
        doQuery={(a, b, c) =>
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          _doQuery(a, b, c as any) as unknown as Promise<
            PagedResponse<Record<string, unknown>>
          >
        }
        editable={
          !mobileSize
            ? {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                rowDisabled: (row: any) => !row.endTime,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onSubmitting: (v: any) => _onSubmitting(v),
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onSubmit: (values: any) => _onSubmit(values),
                // eslint-disable-next-line no-unused-vars
                onSubmitSuccess: () => {
                  _handleClockinUpdate();
                  return Promise.resolve();
                },
                hasEditPermission: canInlineEdit,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                lockColumn: (c: string, d: any) => _getLockedColumns(c, d),
                validationSchema: ClockInValidation,
                // eslint-disable-next-line no-unused-vars
                processForeignKey: (c, v) => 'null',
                formatEditableRow: (values) => ({
                  ...values,
                }),
              }
            : undefined
        }
        externalQueryProps={{}}
        filterRowHeight={0}
        header={{
          rightChildren: [],
        }}
        inlineEditMode="submitRowOnEnter"
        isDiscardConfirmDialog={isDiscardConfirmDialog}
        noHeader
        pageSize={50000}
        ref={gridRef}
        setDataSource={setDataSource}
        setIsDiscardConfirmDialog={setIsDiscardConfirmDialog}
        setIsEditing={setIsEditing}
        tableIdentifier={`${t('Entities.ClockIns.rowDetailsIndentifier')}-${
          (rowDetails?.employee as { id: number })?.id
        }`}
        virtualized={!!mobileSize}
      />

      {stoppingClockIn && (
        <StopClockInPopover
          stoppingClockIn={stoppingClockIn}
          stopClockInAnchorEl={stopClockInAnchorEl}
          handleClose={_handleStopClockInClose}
          employee={rowDetails.employee as unknown as Partial<Employee>}
          setRefreshToken={_handleClockinUpdate}
        />
      )}

      {editingClockIn && (
        <EditClockInsInspector
          title={t('Entities.ClockIns.editClockIns')}
          open={Boolean(editingClockIn)}
          onClose={() => setEditingClockIn(undefined)}
          data={editingClockIn}
          setRefreshToken={_handleClockinUpdate}
        />
      )}
    </Box>
  );
};

export default React.memo(RowDetails);
