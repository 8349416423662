import IndeterminateCheckBoxRounded from '@mui/icons-material/IndeterminateCheckBoxRounded';
import { Box, Checkbox, Theme } from '@mui/material';
import React, { useRef } from 'react';
import editorStyles from '../InlineEditingCommonStyles';

const cellSx = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& .MuiCheckbox-root': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

const activeEditSx = (theme: Theme) => ({
  border: `2px solid ${theme.palette.primary.main}`,
  borderRadius: '3px',
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ETOBooleanEditor = (props: any) => {
  const {
    cellProps,
    onCancel,
    onComplete,
    onChange,
    onTabNavigation,
    onEnterNavigation,
    value,
  } = props;

  const boxRef = useRef();

  const editSx = activeEditSx;

  return (
    <Box
      ref={boxRef}
      sx={[cellSx, editorStyles.root, editSx]}
      data-testid="checkbox-container"
    >
      <Checkbox
        sx={{
          padding: '8px',
        }}
        autoFocus={cellProps?.inEdit}
        onBlur={onComplete}
        color="primary"
        checked={value}
        // indeterminate={!editorProps?.allowUnset ? false : checked === undefined}
        indeterminateIcon={<IndeterminateCheckBoxRounded />}
        onKeyDown={(e) => {
          if (e.key === 'Escape') {
            onCancel(e);
          }
          if (e.key === 'Enter') {
            onComplete(e);
            onEnterNavigation(true, e.shiftKey ? -1 : 1);
          }
          if (e.key === 'Tab') {
            e.preventDefault();
            onTabNavigation(true, e.shiftKey ? -1 : 1);
          }
        }}
        onChange={(e, a) =>
          onChange({ ...e, target: { ...e.target, value: a } })
        }
        name={cellProps?.name as string}
        size="small"
        aria-label={cellProps?.name as string}
      />
    </Box>
  );
};

export default ETOBooleanEditor;
