/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable quote-props */
import { Box, InputBase, SxProps, Theme } from '@mui/material';
import {
  formatHoursAsHourMin,
  formatInputAshours,
} from '@teto/react-component-library';
import dayjs from 'dayjs';
import React, { useCallback, useState } from 'react';

const hoursEntrySx = {
  flexDirection: 'column',
};

const labelSx = (theme: Theme) => ({
  ...theme.typography.subtitle2,
  color: 'inherit',
  textTransform: 'uppercase',
  textAlign: 'center',
  whiteSpace: 'pre',
  '&.error': {
    color: theme.palette.error.main,
  },
  '&.currentDay': {
    color: theme.palette.primary.main,
  },
});

const errorSx = (theme: Theme) => ({
  color: theme.palette.error.main,
  borderBottom: theme.palette.error.main,
});

const inputBaseSx = (theme: Theme) => ({
  ...theme.typography.body1,
  display: 'block',
  width: theme.spacing(6),
  marginLeft: 'auto',
  marginRight: 'auto',
  paddingBottom: theme.spacing(0),
  border: '0',
  '&.currentDay': {
    color: theme.palette.primary.main,
  },
  '&:before': {
    content: '""',
    position: 'absolute',
    left: '0',
    right: '0',
    bottom: ' 0',
    margin: 'auto',
    width: '60%',
    borderBottom: '1px solid',
    borderBottomColor: 'inherit',
  },
});

const currentDaySx = (theme: Theme) => ({
  color: theme.palette.primary.main,
});

interface ETOHoursEntryProps {
  autoFocus: boolean;
  name: string;
  // eslint-disable-next-line react/no-unused-prop-types
  day: dayjs.Dayjs;
  value: string | number;
  error?: string;
  handleChange: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  >;
  disabled?: boolean;
  customSx?: SxProps<Theme>;
}

const inputRegex = /^\d+(\d*|:[0-5]?[0-9]?|\.\d*|h|m)$/i;
const outputRegex = /^\d{1,4}(:\d{2})$/;

const ETOHoursEntry = (props: ETOHoursEntryProps) => {
  const {
    name,
    handleChange,
    value,
    error,
    day,
    customSx,
    autoFocus,
    disabled,
  } = props;

  const [input, setInput] = useState<string>(
    formatHoursAsHourMin(typeof value === 'string' ? parseFloat(value) : value)
  );

  const _handleInternalChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const text = e.target.value;

      if (!text || inputRegex.test(text)) {
        setInput(() => text);
      }
    },
    []
  );

  const _onKeyPress = useCallback(
    (e: any) => {
      if (e.key === 'Enter') {
        // data has already been formatted, nothing to do.
        if (outputRegex.test(input)) {
          // eslint-disable-next-line no-useless-return
          return;
        }

        if (inputRegex.test(input)) {
          // data has not been formatted yet because no onblur has occured
          let outputText = input;
          if (input.endsWith('.')) {
            outputText = `${input}0`;
          }
          setInput(() => outputText);
          e.target.value = formatInputAshours(outputText).toString();
          handleChange(e as any);
        } else {
          e.preventDefault();
        }
      }
    },
    [handleChange, input]
  );

  const _handleFocus = useCallback((e: any) => {
    e.currentTarget.select();
  }, []);

  const _handleBlur = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      if (e === undefined) return;
      if (e?.target?.value === '') {
        setInput('0');
        e.target.value = '0';
        handleChange(e);
        return;
      }

      if (outputRegex.test(e.target.value)) {
        let outputText = e.target.value;
        if (outputText.endsWith('.')) {
          outputText = `${outputText}0`;
        }
        e.target.value = formatInputAshours(outputText).toString();
        handleChange(e);
        const result = formatInputAshours(outputText);
        setInput(() => formatHoursAsHourMin(result));
      } else if (inputRegex.test(e.target.value)) {
        let outputText = e.target.value;
        if (outputText.endsWith('.')) {
          outputText = `${outputText}0`;
        }
        e.target.value = formatInputAshours(outputText).toString();
        handleChange(e);
        const result = formatInputAshours(outputText);
        setInput(() => formatHoursAsHourMin(result));
      }
    },
    [handleChange]
  );

  return (
    <Box
      sx={[hoursEntrySx, ...(Array.isArray(customSx) ? customSx : [customSx])]}
    >
      <Box
        component="label"
        sx={[
          labelSx,
          dayjs().format('YYYY-MM-DD') === day.format('YYYY-MM-DD')
            ? currentDaySx
            : null,
          error ? errorSx : null,
        ]}
        htmlFor={`timecard-hours-${day.format('YYYY-MM-DD')}`}
        aria-labelledby={`timecard-hours-${day.format('YYYY-MM-DD')}`}
      >
        {day.format('ddd DD')}
      </Box>
      <InputBase
        autoComplete="off"
        data-testid="ETOHoursEntry"
        disabled={disabled}
        autoFocus={autoFocus}
        onKeyPress={(e) =>
          _onKeyPress(e as React.KeyboardEvent<HTMLDivElement>)
        }
        sx={[inputBaseSx, error ? errorSx : null]}
        name={name}
        onChange={(e) => _handleInternalChange(e)}
        type="string"
        id={`timecard-hours-${day.format('YYYY-MM-DD')}`}
        value={input}
        error={Boolean(error)}
        onFocus={(e) => _handleFocus(e)}
        onBlur={(e) => _handleBlur(e)}
      />
    </Box>
  );
};

export default React.memo(ETOHoursEntry);
