/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  getEmployees,
  getHourTypes,
  getJobs,
  getNonConformances,
  getProcessScheduleDetails,
  getProjects,
  TimeCard,
} from 'teto-client-api';

const getTimecardSelectorQuery = (columnName: string, data: TimeCard) => {
  switch (columnName) {
    case 'projectName':
      return {
        selectorQuery: {
          queryKey: 'projects',
          queryFn: () =>
            getProjects({
              disablePaging: true,
              filter: { active: true },
              orderBy: { id: true },
            }).then((e) => e.records),
        },
        itemNameSelector: (item: any) => item.displayName ?? item.id.toString(),
        itemValueSelector: (item: any) => item.id,
        defaultValue: -1,
      };

    case 'hourTypeName':
      return {
        selectorQuery: {
          queryKey: 'hourTypes',
          queryFn: () =>
            getHourTypes({
              disablePaging: true,
              filter: { active: true },
            }).then((e) => e.records),
        },
        itemNameSelector: (item: any) => item.displayName ?? item.id.toString(),
        itemValueSelector: (item: any) => item.id,
        defaultValue: -1,
      };

    case 'specName':
      return {
        selectorQuery: {
          queryKey: ['jobs', data.projectId],
          queryFn: () =>
            getJobs({
              disablePaging: true,
              filter: { projectId: data.projectId, active: true },
              orderBy: { displayName: false },
            }).then((e) => e.records),
          queryOptions: {
            enabled: parseInt(data.projectId as unknown as string, 10) > 0,
          },
        },
        itemNameSelector: (item: any) => item.displayName ?? '',
        itemValueSelector: (item: any) => item.id,
        defaultValue: -1,
      };

    case 'employeeId':
      return {
        selectorQuery: {
          queryKey: ['employees-employee-id'],
          queryFn: () =>
            getEmployees({
              disablePaging: true,
              orderBy: { active: true, employeeNumber: false },
            }).then((e) => e.records),
        },
        itemNameSelector: (item: any) =>
          `${!item.active ? '(inactive) ' : ''}${item.employeeNumber} - ${
            item.firstName
          } ${item.lastName}`,
        itemValueSelector: (item: any) => item.id,
        defaultValue: -2,
      };

    case 'nonConformanceName':
      return {
        selectorQuery: {
          queryKey: ['nonConformances', data?.projectId, data?.specId],
          queryFn: () =>
            getNonConformances({
              disablePaging: true,
              filter: {
                projectId: parseInt(data.projectId as unknown as string, 10),
                specId: parseFloat(data.specId as unknown as string),
              },
              orderBy: { creationDate: true },
            }).then((e) => e.records),
          queryOptions: {
            enabled:
              parseInt(data.projectId as unknown as string, 10) > 0 &&
              parseFloat(data.specId as unknown as string) > 0,
          },
        },
        itemNameSelector: (item: any) => `${item.id} - ${item.title}`,
        itemValueSelector: (item: any) => item.id,
        defaultValue: -1,
      };

    case 'processScheduleDetailName':
      return {
        selectorQuery: {
          queryKey: ['processScheduleDetails', data?.projectId, data?.specId],
          queryFn: () =>
            getProcessScheduleDetails({
              disablePaging: true,
              filter: {
                projectId: parseInt(data?.projectId as unknown as string, 10),
                specId: parseFloat(data?.specId as unknown as string),
              },
              orderBy: { lastWorkedOnDate: true },
            }).then((e) => e.records),
          queryOptions: {
            enabled:
              parseInt(data?.projectId as unknown as string, 10) > 0 &&
              parseFloat(data?.specId as unknown as string) > 0,
          },
        },
        itemNameSelector: (item: any) => `${item.displayName}`,
        itemValueSelector: (item: any) => item.id,
        defaultValue: -1,
      };

    default:
      return undefined;
  }
};
export default getTimecardSelectorQuery;
