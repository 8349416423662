import { Box, Theme } from '@mui/material';
import React from 'react';

const textSx = (theme: Theme) => ({
  color: theme.palette.text.disabled,
});

interface EmptyDisplayProps {
  placeHolder?: string;
}

const EmptyDisplay = (props: EmptyDisplayProps) => {
  const { placeHolder } = props;

  return (
    <Box component="span" sx={textSx}>
      {placeHolder}
    </Box>
  );
};

export default React.memo(EmptyDisplay);
