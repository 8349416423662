import {
  TypeFilterValue,
  TypeGroupBy,
  TypeSortInfo,
} from '@inovua/reactdatagrid-enterprise/types';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { getGridSettings, updateGridSettings } from 'teto-client-api';
import GridPersistenceContext from './GridPersistenceContext';
import { getDefault } from './gridPersistenceHelpers';
import { ColumnSizing, GridPersistence } from './GridPersistenceOptions';
import GridPersistenceProviderProps from './GridPersistenceProviderProps';

const GridPersistenceProvider = (props: GridPersistenceProviderProps) => {
  const {
    defaultGrouping,
    defaultSort,
    tableIdentifier,
    children,
    columns,
    persistenceType,
  } = props;
  const colsWithMobile = useMemo(
    () => [
      ...columns,
      {
        order: columns.length + 1,
        name: 'mobileCustomGrouping',
        title: 'Visible Headers',
        type: 'custom',
        align: 'start' as const,
        sortable: false,
        filterType: 'none',
        fixed: 'none' as const,
        disableGrouping: true,
        disableHideable: true,
        disableColumnMenuTool: false,
        width: 144,
        header: 'Visible Headers',
        id: 'mobileCustomGrouping',
        hidden: false,
        flex: undefined,
      },
      {
        order: columns.length + 2,
        name: 'mobileCustomData',
        title: 'Data',
        type: 'custom',
        align: 'start' as const,
        sortable: false,
        filterType: 'none',
        fixed: 'none' as const,
        disableGrouping: true,
        disableHideable: true,
        disableColumnMenuTool: false,
        width: 144,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        header: 'Data',
        id: 'mobileCustomData',
        hidden: false,
        flex: undefined,
      },
    ],
    [columns]
  );

  const defaultSettings = useMemo(
    () => ({ ...getDefault(colsWithMobile, defaultGrouping, defaultSort) }),
    [colsWithMobile, defaultGrouping, defaultSort]
  );

  const [state, setState] = useState<GridPersistence>(defaultSettings);

  const [loadState, setLoadState] = useState<{
    loaded: boolean;
    error: string;
  }>({
    error: '',
    loaded: false,
  });

  const saveInterval = 1000;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const saveTimer = useRef<any>(null);

  const _setColumnOrder = useCallback((order: string[]) => {
    setState((existing) => ({
      ...existing,
      columnOrder: order,
    }));
  }, []);

  const _setFilters = useCallback((filters: TypeFilterValue) => {
    setState((existing) => ({
      ...existing,
      filters,
    }));
  }, []);

  const _setSort = useCallback((sort: TypeSortInfo) => {
    setState((existing) => ({
      ...existing,
      sort,
    }));
  }, []);

  const _setHidden = useCallback((hidden: string[]) => {
    setState((existing) => ({
      ...existing,
      hidden,
    }));
  }, []);

  const _setGrouping = useCallback((grouping: TypeGroupBy) => {
    setState((existing) => ({
      ...existing,
      grouping,
    }));
  }, []);

  const _setLeftLockedColumns = useCallback((leftLockedColumns: string[]) => {
    setState((existing) => ({
      ...existing,
      leftLockedColumns,
    }));
  }, []);

  const _setRightLockedColumns = useCallback((rightLockedColumns: string[]) => {
    setState((existing) => ({
      ...existing,
      rightLockedColumns,
    }));
  }, []);

  const _setColumnSizes = useCallback((columnSizes: ColumnSizing[]) => {
    setState((existing) => ({
      ...existing,
      columnSizes,
    }));
  }, []);

  const _getLocalStorageKey = (key: string, storage: Storage) => {
    const item = storage.getItem(key);
    return item ? Promise.resolve(item) : Promise.reject();
  };

  const _setLocalStorageKey = (
    key: string,
    storage: Storage,
    settings: GridPersistence
  ) => {
    storage.setItem(key, JSON.stringify(settings));
  };

  const _onLoad = useCallback(() => {
    switch (persistenceType) {
      case 'localStorage':
        return _getLocalStorageKey(tableIdentifier, window.localStorage).then(
          (a) => JSON.parse(a)
        );
      case 'sessionStorage':
        return _getLocalStorageKey(tableIdentifier, window.sessionStorage).then(
          (a) => JSON.parse(a)
        );
      case 'db':
        return getGridSettings(tableIdentifier).then((a) =>
          JSON.parse(a.gridContent)
        );
      default:
        return Promise.reject();
    }
  }, [persistenceType, tableIdentifier]);

  const _onUpdate = useCallback(
    (key: string, settings: GridPersistence) => {
      switch (persistenceType) {
        case 'localStorage':
          _setLocalStorageKey(key, window.localStorage, settings);
          return Promise.resolve();
        case 'sessionStorage':
          _setLocalStorageKey(key, window.sessionStorage, settings);
          return Promise.resolve();
        case 'db':
          return updateGridSettings(key, JSON.stringify(settings));
        default:
          return Promise.resolve();
      }
    },
    [persistenceType]
  );

  const _saveSettings = useCallback(
    () => _onUpdate(tableIdentifier, { ...state }),
    [_onUpdate, state, tableIdentifier]
  );

  const _resetSettings = useCallback(() => {
    setState(() => defaultSettings);
    _onUpdate(tableIdentifier, defaultSettings);
    return Promise.resolve();
  }, [_onUpdate, tableIdentifier, defaultSettings]);

  useEffect(() => {
    _onLoad()
      .then((e) => {
        setLoadState({
          loaded: true,
          error: '',
        });
        setState(() => e);
      })
      .catch((e) =>
        setLoadState({
          loaded: true,
          error: e ? e.message : 'Error loading persistence state',
        })
      );
  }, [_onLoad]);

  useEffect(() => {
    if (saveTimer.current) {
      clearTimeout(saveTimer.current);
    }

    saveTimer.current = setTimeout(() => {
      _onUpdate(tableIdentifier, { ...state });
    }, saveInterval);

    return () => clearTimeout(saveTimer.current);
  }, [_onUpdate, state, tableIdentifier]);

  const gridValues = useMemo(
    () => ({
      ...state,
      ...loadState,
      resetSettings: _resetSettings,
      setColumnOrder: _setColumnOrder,
      setColumnSizes: _setColumnSizes,
      setFilters: _setFilters,
      setGrouping: _setGrouping,
      setHidden: _setHidden,
      setLeftLockedColumns: _setLeftLockedColumns,
      setRightLockedColumns: _setRightLockedColumns,
      setSort: _setSort,
      saveSettings: _saveSettings,
    }),
    [
      _resetSettings,
      _saveSettings,
      _setColumnOrder,
      _setColumnSizes,
      _setFilters,
      _setGrouping,
      _setHidden,
      _setLeftLockedColumns,
      _setRightLockedColumns,
      _setSort,
      loadState,
      state,
    ]
  );

  return (
    <GridPersistenceContext.Provider value={gridValues}>
      {children}
    </GridPersistenceContext.Provider>
  );
};

export default GridPersistenceProvider;
