/* eslint-disable @typescript-eslint/no-explicit-any */
import { CellRenderObject } from '@inovua/reactdatagrid-community/Layout/ColumnLayout/Cell/CellProps';
import { debounce } from '@mui/material';
import { MessageContext } from '@teto/react-component-library';
import { uniqueId } from 'lodash';
import React, {
  useCallback,
  useContext,
  useLayoutEffect,
  useState,
} from 'react';
import IMobileData from '../../IMobileData';
import { EditInfo } from '../IEditInfo';
import TInlineEditingError from '../TInlineEditingError';
import MobileNumberEditor from './MobileNumberEditor';

interface Props {
  data: IMobileData;
  rdgProps: CellRenderObject;
  // eslint-disable-next-line no-unused-vars
  onEditComplete: (props: EditInfo) => void;
}

const MobileEditor = (props: Props) => {
  const { data, rdgProps, onEditComplete } = props;
  const { cellProps, rowIndex, columnIndex } = rdgProps;
  const [value, setValue] = useState(cellProps.data);
  const [errorState, setErrorState] = useState<TInlineEditingError>({
    errorIs: false,
    errorText: '',
  });
  const messageContext = useContext(MessageContext);
  // const Editor = getDefaultEditor(data.type as string);
  useLayoutEffect(() => {
    setValue({
      ...cellProps.data,
      [data.name as string]: data.value === '-' ? 0 : parseInt(data.value, 10),
    });
  }, [cellProps.data, data.name, data.value]);

  const _handleChange = (e: React.BaseSyntheticEvent) => {
    setValue({
      ...cellProps.data,
      [data.name as string]: parseInt(e.target.value, 10),
    });
    if (data.inlineEditingCondition) {
      const result = data.inlineEditingCondition(
        e.target.value,
        cellProps.data
      );
      setErrorState(result);

      if (result.errorIs === true) {
        return messageContext.setError(result.errorText);
      }
      if (result.errorIs === 'warning') {
        messageContext.setWarning(result.errorText);
      }
    }
    return onEditComplete({
      value: parseInt(e.target.value, 10),
      columnId: data.name as string,
      cellProps,
      rowIndex,
      columnIndex,
      rowId: '',
      data: {},
    });
  };
  const debounceOnChange = debounce(_handleChange, 1000);

  const _onEditComplete = useCallback(
    (e: React.BaseSyntheticEvent) => {
      if (data.inlineEditingCondition) {
        const result = data.inlineEditingCondition(
          e.target.value,
          cellProps.data
        );
        setErrorState(result);

        if (result.errorIs === true) {
          return messageContext.setError(result.errorText);
        }
        if (result.errorIs === 'warning') {
          messageContext.setWarning(result.errorText);
        }
      }
      return onEditComplete({
        value: parseInt(e.target.value, 10),
        columnId: data.name as string,
        cellProps,
        rowIndex,
        columnIndex,
        rowId: '',
        data: {},
      });
    },
    [data, onEditComplete, cellProps, rowIndex, columnIndex, messageContext]
  );
  return (
    cellProps && (
      <MobileNumberEditor
        error={errorState.errorIs === true}
        sxProps={
          //  ? { borderWidth: 2, borderColor: 'warning.main' }
          errorState.errorIs === 'warning'
            ? {
                border: '2px solid #ff9800',
              }
            : undefined
        }
        key={uniqueId(data.id)}
        col={data}
        defaultValue={value[data.name as string]}
        handleChange={debounceOnChange}
        onBlur={(e: React.BaseSyntheticEvent) => _onEditComplete(e)}
        rdgProps={rdgProps}
      />
    )
  );
};

export default React.memo(MobileEditor);
