import StopRoundedIcon from '@mui/icons-material/StopRounded';
import {
  Box,
  Button,
  Grid,
  Popover,
  Typography,
  useTheme,
} from '@mui/material';
import { MessageContext, SettingsContext } from '@teto/react-component-library';
import dayjs from 'dayjs';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Employee } from 'teto-client-api';
import { getGraphQLClient } from '../../../helpers/graphQL/graphQLClient';
import ClockIn from '../ClockIn';
import stopClockInMutation from '../queries/stopClockInMutation';

interface StopClockInPopoverProps {
  stoppingClockIn: ClockIn | undefined;
  stopClockInAnchorEl: HTMLButtonElement | null;
  handleClose: () => void;
  employee?: Partial<Employee>;
  setRefreshToken: () => void;
}

const StopClockInPopover = (props: StopClockInPopoverProps) => {
  const { t } = useTranslation();
  const {
    stoppingClockIn,
    stopClockInAnchorEl,
    setRefreshToken,
    handleClose,
    employee,
  } = props;

  const messageContext = useContext(MessageContext);
  const settingsContext = useContext(SettingsContext);

  const theme = useTheme();

  const _stopClockIn = () => {
    getGraphQLClient()
      .performMutation(
        stopClockInMutation,
        {
          employeeId: employee?.id,
        },
        (err) => {
          messageContext.setError(err.messages[0]);
        },
        () => {
          // @TODO Handle Validation
        }
      )
      .then((d) => {
        if (d.data.stopClockIn.success) {
          setRefreshToken();
          handleClose();
        }
      });
  };

  return (
    <Popover
      open={Boolean(stopClockInAnchorEl)}
      anchorEl={stopClockInAnchorEl}
      onClose={handleClose}
      PaperProps={{
        sx: {
          padding: theme.spacing(1),
        },
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Typography variant="subtitle2">{`${t('generic.confirm')} ${t(
        'Entities.ClockIns.clockOut'
      )}`}</Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <Grid container xs={12}>
          <Grid item xs={12}>
            <Typography variant="caption">{`${t(
              'Entities.Employee.Employee'
            )}: ${employee?.name}`}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption">{`${t(
              'Entities.ClockIns.startTime'
            )}: ${dayjs(stoppingClockIn?.startTime).format(
              settingsContext.settings.timeFormat
            )}`}</Typography>
          </Grid>
        </Grid>
      </Box>
      <Button
        fullWidth
        color="primary"
        size="medium"
        startIcon={<StopRoundedIcon color="error" />}
        variant="contained"
        onClick={() => _stopClockIn()}
      >
        {t('Entities.ClockIns.clockOut')}
      </Button>
    </Popover>
  );
};

export default StopClockInPopover;
