import UndoRoundedIcon from '@mui/icons-material/UndoRounded';
import { MessageContext } from '@teto/react-component-library';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { TimesheetStatus } from 'teto-client-api';
import useUnsubmitTimesheet from '../../TimeSheets/TimesheetActions/UseUnsubmitTimesheet';

interface TimesheetUnsubmitButtonProps {
  onClick?: () => void;
  disabled?: boolean;
  timesheetId: number | undefined;
  status: TimesheetStatus | undefined;
}

const TimesheetUnsubmitButton = (props: TimesheetUnsubmitButtonProps) => {
  const { onClick, disabled, timesheetId, status } = props;
  const { t } = useTranslation();
  const { mutate: unsubmitTimesheet } = useUnsubmitTimesheet();
  const messageContext = useContext(MessageContext);
  return status === 'Submitted'
    ? {
        title: t('generic.unSubmit'),
        icon: <UndoRoundedIcon />,
        disabled,
        onclick: () => {
          if (!timesheetId) {
            messageContext.setError(
              `${t(
                'Entities.Timesheet.id'
              )} is not defined. Please contact your Admin.`
            );
            return;
          }
          unsubmitTimesheet({ id: timesheetId });
          // eslint-disable-next-line no-unused-expressions
          onClick ? onClick() : null;
        },
        confirm: {
          type: 'okCancel' as const,
          title: t('dialogs.unSubmitTimesheet.title'),
          content: t('dialogs.unSubmitTimesheet.content'),
        },
      }
    : false;
};

export default TimesheetUnsubmitButton;
