import Close from '@mui/icons-material/Close';
import ExpandLessOutlined from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlined from '@mui/icons-material/ExpandMoreOutlined';
import {
  Drawer,
  Theme,
  TooltipProps,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import {
  Filterhelper,
  getMyTimecards,
  TimeCard,
  toAPITDate,
} from 'teto-client-api';
import SummaryWrapper from '../../../../../components/SummaryWrapper/SummaryWrapper';
import TableColumnDefinition from '../../../../../components/TetoGrid/TableColumnDefinition';
import DailySummaryItem from './DailySummaryItem';
// eslint-disable-next-line no-unused-vars
import { displayList } from './sampleData';

interface DailySummaryViewProps {
  refreshToken: dayjs.Dayjs | undefined;
  date: Dayjs;
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  // eslint-disable-next-line no-unused-vars
  setTimecardEditing: (data: TimeCard) => void;
  colVisibility?: string[];
  columns: TableColumnDefinition[];
}

const headerSx = (theme: Theme) => ({
  '& >:first-of-type': {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  [theme.breakpoints.down('md')]: {
    paddingRight: theme.spacing(5),
  },
});
const mobileHeaderSx = (theme: Theme) => ({
  '& >:first-of-type': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    position: 'sticky',
    top: 0,
    '& button': {
      color: theme.palette.primary.contrastText,
    },
  },
  '& >:nth-of-type(2)': {
    paddingBottom: theme.spacing(0),
  },
});
const paperSx = (theme: Theme) => ({
  borderTopLeftRadius: `${theme.shape.borderRadius}px`,
  borderTopRightRadius: `${theme.shape.borderRadius}px`,
  height: '90%',
  '& .MuiList-root': {
    padding: 0,
  },
});

const desktopSx = (theme: Theme) => ({
  borderRadius: `${theme.shape.borderRadius}px`,
  border: `1px solid ${theme.palette.divider}`,
  minHeight: '3%',
});

const formatDay = (value: Dayjs) => {
  const dayValue = parseInt(value.format('D'), 10);
  let result = `${value.format('dddd')} ${dayValue}`;

  if (dayValue > 3 && dayValue < 21) result += 'th';
  else {
    switch (dayValue % 10) {
      case 1:
        result += 'st';
        break;
      case 2:
        result += 'nd';
        break;
      case 3:
        result += 'rd';
        break;
      default:
        result += 'th';
    }
  }
  return result;
};

const DailySummaryView = (props: DailySummaryViewProps) => {
  const {
    date,
    isModalOpen,
    setIsModalOpen,
    setTimecardEditing,
    colVisibility,
    columns,
    refreshToken,
  } = props;
  const [isDesktopExpanded, setIsDesktopExpanded] = useState<boolean>(true);
  const [records, setRecords] = useState<TimeCard[]>();
  const theme = useTheme();
  const { t } = useTranslation();

  const isSmall = useMediaQuery(theme.breakpoints.down('md'));
  const containerSize = isSmall ? mobileHeaderSx : desktopSx;
  const desktopIcons = !isDesktopExpanded
    ? {
        icon: <ExpandMoreOutlined />,
        toolTip: t('generic.viewContent'),
      }
    : {
        icon: <ExpandLessOutlined />,
        toolTip: t('generic.hideContent'),
      };

  const _handleSummaryToggle = () => {
    setIsDesktopExpanded((old) => !old);
  };

  const handleEditButtonClick = (record: TimeCard) => {
    setTimecardEditing(record);
    setIsModalOpen(false);
  };

  const getTotalHourTime = <T extends { hourTime: number }>(
    array: T[]
  ): number => array.reduce((total, item) => total + item.hourTime, 0);

  const dataQuery = useQuery(
    ['employeeDailtItemTimeTrackerQuery', date, refreshToken],
    () =>
      getMyTimecards({
        disablePaging: true as const,
        filter: {
          date: toAPITDate(dayjs(date)) as unknown as Filterhelper,
        },
      }),
    {
      onSuccess: (d) => setRecords(d.records),
    }
  );

  const totalHoursArray = dataQuery.data?.records ?? [{ hourTime: 0 }];
  const categoryList: string[] = colVisibility ?? displayList;

  const summary =
    records &&
    records.map((i) => (
      <DailySummaryItem
        columns={columns}
        key={i.id}
        timeCard={i}
        displayList={categoryList}
        onClick={() => handleEditButtonClick(i as unknown as TimeCard)}
      />
    ));

  const wrapper = (
    <SummaryWrapper
      customSx={[headerSx, containerSize]}
      icon={isSmall ? <Close /> : desktopIcons.icon}
      title={` ${formatDay(dayjs(date))}`}
      total={getTotalHourTime(totalHoursArray)}
      onClick={
        isSmall ? () => setIsModalOpen(false) : () => _handleSummaryToggle()
      }
      tooltipProps={
        {
          title: !isSmall ? desktopIcons.toolTip : undefined,
          placement: 'bottom-end',
        } as TooltipProps
      }
    >
      {summary && summary.length > 0 ? summary : undefined}
    </SummaryWrapper>
  );

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {!isSmall ? (
        wrapper
      ) : (
        <Drawer
          anchor="bottom"
          onClose={() => setIsModalOpen(false)}
          open={isModalOpen}
          PaperProps={{
            sx: paperSx,
          }}
        >
          {wrapper}
        </Drawer>
      )}
    </>
  );
};

export default DailySummaryView;
