const startMyClockIn = `
mutation startMyClockIn() {
  startMyClockIn() {
    success
    id
    date
    employeeId
    startTime
  }
}`;
export default startMyClockIn;
