import ChevronRight from '@mui/icons-material/ChevronRight';
import ExpandLessOutlined from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlined from '@mui/icons-material/ExpandMoreOutlined';
import {
  Box,
  ListItem,
  Theme,
  TooltipProps,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  ETOIconButton,
  formatHoursAsHourMin,
  SettingsContext,
} from '@teto/react-component-library';
import dayjs, { Dayjs } from 'dayjs';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { TimeSheet } from 'teto-client-api';
import SummaryWrapper from '../../../../../components/SummaryWrapper/SummaryWrapper';
import TimesheetStatusText from '../../../../../components/TimeSheets/TimesheetStatus/TimesheetStatusText';
import {
  formatMonthlyURLDate,
  formatWeeklyURLDate,
} from '../../../../../helpers/dateHelpers';

interface WeeklySummaryViewProps {
  timesheet: TimeSheet[];
  setViewBy?: React.Dispatch<React.SetStateAction<string>>;
}

const headerSx = (theme: Theme) => ({
  '& >:first-of-type': {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
});

const mobileHeaderSx = (theme: Theme) => ({
  '& >:first-of-type': {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    '& button': {
      color: theme.palette.primary.contrastText,
    },
  },
});
const desktopSx = (theme: Theme) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: `${theme.shape.borderRadius}px`,
  minHeight: '50%',
});

const listItemSx = (theme: Theme) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: theme.spacing(8),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(1),
  width: '100%',
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.getContrastText(theme.palette.primary.main),
    '& .MuiTypography-root': {
      color: theme.palette.getContrastText(theme.palette.primary.main),
    },
    '& button': {
      color: theme.palette.getContrastText(theme.palette.primary.main),
    },
  },
});
const weeklyWrapSx = {
  display: 'flex',
  justifyContent: 'space-between',
  flexGrow: 1,
};

const topWeeklySx = {
  display: 'flex',
  alignItems: 'center',
  width: '100%',
};

const leftWeeklySx = {
  flexGrow: 1,
};
const rightWeeklySx = {
  alignItems: 'center',
  display: 'flex',
  flexGrow: 0,
};

const URL = '/timeTracking/myTimeTracker/timesheet/monthly/';

const getTotalHours = <T extends { totalHours: number }>(array: T[]): number =>
  array.reduce((total, item) => total + item.totalHours, 0);

const WeeklySummaryView = (props: WeeklySummaryViewProps) => {
  const { timesheet, setViewBy } = props;
  const settingsContext = useContext(SettingsContext);
  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const containerSize = mobile ? mobileHeaderSx : desktopSx;

  const _handleSummaryToggle = () => {
    setIsExpanded((old) => !old);
  };

  const formatDateRange = (start: Dayjs, end: Dayjs) => {
    const format = settingsContext.settings.dateFormat;
    return `${start.format(format)} - ${end.format(format)}`;
  };
  const _handleClick = (i: TimeSheet) => {
    const months = formatMonthlyURLDate(dayjs(i?.startDate));
    const end = formatWeeklyURLDate(dayjs(i?.endDate));
    const start = formatWeeklyURLDate(dayjs(i?.startDate));
    navigate(`${URL}${months}/weekly/${start}/${end}`);
    if (setViewBy) {
      setViewBy('week');
    }
  };
  const summary = timesheet?.map((i) => (
    <ListItem
      sx={listItemSx}
      button
      key={i?.id}
      onClick={() => _handleClick(i)}
      component="div"
    >
      <Box component="div" sx={weeklyWrapSx}>
        <Box component="div" sx={topWeeklySx}>
          <Box component="div" sx={leftWeeklySx}>
            <Typography color="textPrimary" variant="body1">
              {formatDateRange(dayjs(i?.startDate), dayjs(i?.endDate))}
            </Typography>
            <TimesheetStatusText timesheet={i} />
          </Box>
        </Box>

        <Box component="div" sx={rightWeeklySx}>
          <Typography color="textPrimary" variant="body1">
            {formatHoursAsHourMin(i?.totalHours)}
          </Typography>
        </Box>
      </Box>

      <ETOIconButton
        color="primary"
        size={mobile ? 'large' : 'medium'}
        tooltipProps={{
          title: t('generic.viewDetail'),
        }}
      >
        <ChevronRight />
      </ETOIconButton>
    </ListItem>
  ));

  const weeklyIcon = isExpanded
    ? {
        icon: <ExpandLessOutlined />,
        toolTip: t('generic.hideContent'),
      }
    : {
        icon: <ExpandMoreOutlined />,
        toolTip: t('generic.viewContent'),
      };

  return (
    <SummaryWrapper
      customSx={[headerSx, containerSize]}
      icon={mobile ? undefined : weeklyIcon.icon}
      title="Weekly Summary"
      total={getTotalHours(timesheet)}
      onClick={mobile ? undefined : () => _handleSummaryToggle()}
      tooltipProps={
        {
          title: mobile ? undefined : weeklyIcon.toolTip,
          placement: 'bottom-end',
        } as TooltipProps
      }
    >
      {summary.length > 0 ? summary : undefined}
    </SummaryWrapper>
  );
};

export default React.memo(WeeklySummaryView);
