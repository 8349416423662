/* eslint-disable react/jsx-props-no-spreading */

import LockIcon from '@mui/icons-material/Lock';
import { Box, SxProps, Theme, Typography } from '@mui/material';
import {
  AuthContext,
  MessageContext,
  OkCancelConfirmDialog,
  SettingsContext,
} from '@teto/react-component-library';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  deleteTimeCard,
  deleteTimeCardBatch,
  getEmployees,
  getHourTypesForTimecard,
  getJobs,
  getMyTimecards,
  getNonConformances,
  getProcessScheduleDetails,
  getProjects,
  getTimecards,
  Permission,
  TimeCard,
  updateTimeCardByHours,
  updateTimeCardByHoursBatch,
  updateTimeCardByTime,
} from 'teto-client-api';
import useLocalStorage from 'use-local-storage';
import GeolocationInfo from '../../../helpers/IGeolocationInfo';
import { parseServerResponse } from '../../../helpers/validationHelperREST';
import InlineHelpLink from '../../HelpLinks/InlineHelpLink/InlineHelpLink';
import TimeCardInspector from '../TimeCardInspector/TimeCardInspector';
import EditHourTimeCardForm from '../TimeSheetForms/Hour/EditHourTimeCardForm';
import HourTimeCardRequestFormat from '../TimeSheetForms/Hour/HourTimeCardRequestFormat';
import EditHoursTimeCardForm from '../TimeSheetForms/Hours/EditHoursTimeCardForm';
import HoursTimeCardRequestFormat from '../TimeSheetForms/Hours/HoursTimeCardRequestFormat';
import EditTimeBasedTimeCardForm from '../TimeSheetForms/Time/EditTimeBasedTimeCardForm';
import TimeBasedTimeCardRequestFormat from '../TimeSheetForms/Time/TimeBasedTimeCardRequestFormat';

interface EditInspectorProps {
  timecard?: TimeCard;
  onClose: () => void;
  onUpdate: () => void;
  onDelete: () => void;
  allowEmployeeSelection: boolean;
  editMyTimecards?: boolean;
  canEdit: boolean;
  canDelete: boolean;
  disableEmployee?: boolean;
}

const errorSx = (theme: Theme) => ({
  display: 'flex',
  columnGap: theme.spacing(2),
  alignItems: 'center',
});

type InspectorState =
  | 'loading'
  | 'loaded-hours-singular'
  | 'loaded-hours-batch'
  | 'loaded-timer'
  | 'error';

const submitHoursTimecardForm = (
  batchId: string,
  e: HoursTimeCardRequestFormat
) =>
  updateTimeCardByHoursBatch(batchId, {
    employeeId: e.employeeId,
    hourTypeId: e.hourTypeId,
    hours: e.hours,
    specId: e.specId,
    projectId: e.projectId,
    comments: e.comments,
    custom1: e.custom1,
    custom2: e.custom2,
    custom3: e.custom3,
    custom4: e.custom4,
    custom5: e.custom5,
    custom6: e.custom6,
    custom7: e.custom7,
    custom8: e.custom8,
    nonConformanceId: e.nonConformanceId ?? undefined,
    processScheduleDetailId: e.processScheduleDetailId ?? undefined,
    quantity: e.quantity,
    hourFactor: e.hourFactor,
    hourRate: e.hourRate,
    location: e.location,
  });

// eslint-disable-next-line no-unused-vars
const submitHourTimecardForm = (id: number, e: HourTimeCardRequestFormat) =>
  updateTimeCardByHours(id, {
    employeeId: e.employeeId,
    hourTypeId: e.hourTypeId,
    hours: parseFloat(e.hour),
    date: e.date,
    specId: e.specId,
    projectId: e.projectId,
    comments: e.comments,
    custom1: e.custom1,
    custom2: e.custom2,
    custom3: e.custom3,
    custom4: e.custom4,
    custom5: e.custom5,
    custom6: e.custom6,
    custom7: e.custom7,
    custom8: e.custom8,
    nonConformanceId: e.nonConformanceId ?? undefined,
    processScheduleDetailId: e.processScheduleDetailId ?? undefined,
    quantity: e.quantity,
    hourFactor: e.hourFactor,
    hourRate: e.hourRate,
  });

// eslint-disable-next-line no-unused-vars
const submitTimerTimecardForm = (
  id: number,
  e: TimeBasedTimeCardRequestFormat
) =>
  updateTimeCardByTime(id, {
    employeeId: e.employeeId,
    hourTypeId: e.hourTypeId,
    startTime: e.startTime,
    endTime: e.endTime,
    specId: e.specId,
    projectId: e.projectId,
    comments: e.comments,
    custom1: e.custom1,
    custom2: e.custom2,
    custom3: e.custom3,
    custom4: e.custom4,
    custom5: e.custom5,
    custom6: e.custom6,
    custom7: e.custom7,
    custom8: e.custom8,
    nonConformanceId: e.nonConformanceId ?? undefined,
    processScheduleDetailId: e.processScheduleDetailId ?? undefined,
    quantity: e.quantity,
    hourFactor: e.hourFactor,
    hourRate: e.hourRate,
  });

const lockedIconSx: SxProps<Theme> = (theme: Theme) => ({
  paddingRight: theme.spacing(0.5),
});

const EditInspector = (props: EditInspectorProps) => {
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const {
    settings: { startDayOfWeek, geotaggingMode, approverCanManageTime },
  } = useContext(SettingsContext);
  const messageContext = useContext(MessageContext);

  const [inspectorState, setInspectorState] =
    useState<InspectorState>('loading');
  const [timecards, setTimecards] = useState<TimeCard[]>([]);
  const [formHasChanges, setFormHasChanges] = useState(false);
  const [needsCloseConfirmation, setNeedsCloseConfirmation] = useState(false);
  const [hasNewTimecard, setHasNewTimecard] = useState<boolean>(false);
  const [geoInfo, setGeoInfo] = useState<GeolocationInfo>({
    geoError: false,
    location: null,
  });

  const {
    onClose,
    timecard,
    onUpdate,
    onDelete,
    allowEmployeeSelection,
    canDelete,
    canEdit,
    editMyTimecards,
    disableEmployee,
  } = props;
  const [barcodeEnabled, setBarcodeEnabled] = useLocalStorage(
    'barcode-scanner-enabled',
    false
  );

  const timecardStatus = useMemo(
    () =>
      timecards.some((TC) => TC.locked !== 0)
        ? {
            locked: true,
            icon: <LockIcon sx={lockedIconSx} color="action" />,
          }
        : { locked: false, icon: undefined },
    [timecards]
  );

  useEffect(() => {
    if (hasNewTimecard) {
      if (geotaggingMode === 'Require on All') {
        navigator?.geolocation.getCurrentPosition(
          (position) =>
            setGeoInfo({
              geoError: false,
              location: `${position.coords.latitude}, ${position.coords.longitude}`,
            }),
          () => setGeoInfo({ geoError: true, location: null })
        );
      }
      if (
        geotaggingMode === 'Require on Punch Ins' ||
        geotaggingMode === 'Optional'
      ) {
        navigator?.geolocation.getCurrentPosition((position) =>
          setGeoInfo({
            geoError: false,
            location: `${position.coords.latitude}, ${position.coords.longitude}`,
          })
        );
      }
    }
  }, [hasNewTimecard, geotaggingMode]);

  useEffect(() => {
    setInspectorState('loading');
    if (!timecard) {
      return;
    }

    if (!timecard.timecardPunchInID && timecard.batchId) {
      if (editMyTimecards) {
        getMyTimecards({
          disablePaging: true,
          filter: {
            batchId: timecard.batchId,
          },
        })
          .then((a) => {
            setInspectorState('loaded-hours-batch');
            setTimecards(a.records);
          })
          .catch(() => {
            setInspectorState('error');
            messageContext.setError('Failed to get timecards in batch');
          });
      } else {
        getTimecards({
          disablePaging: true,
          filter: {
            batchId: timecard.batchId,
            employeeId: timecard.employeeId,
          },
        })
          .then((a) => {
            setInspectorState('loaded-hours-batch');
            setTimecards(a.records);
          })
          .catch(() => {
            setInspectorState('error');
            messageContext.setError('Failed to get timecards in batch');
          });
      }
    } else if (timecard.timecardPunchInID) {
      setTimecards([timecard]);
      setInspectorState('loaded-timer');
    } else {
      setTimecards([timecard]);
      setInspectorState('loaded-hours-singular');
    }
  }, [messageContext, timecard, editMyTimecards]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const _onUpdated = () => {
    onUpdate();
  };

  const _delete = () => {
    switch (inspectorState) {
      case 'loaded-hours-batch':
        if (timecards.length > 0 && timecards[0].batchId) {
          return deleteTimeCardBatch(timecards[0].batchId)
            .then(() => _onUpdated())
            .catch(() =>
              messageContext.setError('Failed to delete timecard batch.')
            );
        }
        return Promise.reject(new Error('No timecards or batchId present'));
      case 'loaded-hours-singular':
      case 'loaded-timer':
        if (timecards.length > 0) {
          return deleteTimeCard(timecards[0].id)
            .then(() => _onUpdated())
            .catch(() => messageContext.setError('Failed to delete timecard.'));
        }
        return Promise.reject(new Error('No timecards or batchId present'));
      case 'loading':
      default:
        return Promise.resolve();
    }
  };

  const _onError = useCallback(
    (err: string | Error) => {
      messageContext.setMessage(
        typeof err === 'string' ? err : err.message,
        'error'
      );
    },
    [messageContext]
  );

  const _batchedErrors = useCallback(
    (err: { [key: string]: string }) => {
      if (Object.keys(err).indexOf('location') >= 0) {
        setGeoInfo({ geoError: true, location: null });
        setHasNewTimecard(true);
        setBarcodeEnabled(false);
      }
      setInspectorState('error');
      messageContext.setError('Failed to save timecards in batch');
    },
    [messageContext, setBarcodeEnabled, setGeoInfo]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const commonProps = {
    canDelete,
    canEdit,
    qrEnabled: barcodeEnabled && !timecardStatus.locked,
    getProjects: () =>
      getProjects({ disablePaging: true, orderBy: { id: true } }).then(
        (e) => e.records
      ),
    getHourTypes: (
      projectId?: number,
      specId?: number,
      processScheduleDetailId?: number,
      nonConformanceId?: number,
      employeeId?: number
    ) =>
      getHourTypesForTimecard({
        disablePaging: true,
        filter: {
          active: true,
          projectId: projectId ?? null,
          specId: specId ?? null,
          processScheduleDetailId: processScheduleDetailId ?? null,
          nonConformanceId: nonConformanceId ?? null,
          employeeId: employeeId ?? null,
        },
      }).then((e) => e.records),
    getJobs: (projectId: number) =>
      getJobs({ disablePaging: true, filter: { projectId } }).then(
        (e) => e.records
      ),
    getNonConformances: (projectId: number, specId: number) =>
      getNonConformances({
        disablePaging: true,
        filter: { projectId, specId },
      }).then((e) => e.records),
    getProcessSchedules: (projectId: number, specId: number) =>
      getProcessScheduleDetails({
        disablePaging: true,
        filter: { projectId, specId },
      }).then((e) => e.records),
    deleteFunction: () => _delete().then(() => onDelete()),
    employeeId: authContext.user?.id,
    onError: _onError,
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const _submit = (
    e:
      | HoursTimeCardRequestFormat
      | HourTimeCardRequestFormat
      | TimeBasedTimeCardRequestFormat
  ) => {
    switch (inspectorState) {
      case 'loaded-hours-batch':
        if (timecards.length > 0 && timecards[0].batchId) {
          return submitHoursTimecardForm(timecards[0].batchId, {
            ...e,
            location: geoInfo.location,
          } as unknown as HoursTimeCardRequestFormat);
        }
        return Promise.reject(new Error('No timecards or batchId present'));
      case 'loaded-hours-singular':
        if (timecards.length > 0) {
          return submitHourTimecardForm(
            timecards[0].id,
            e as HourTimeCardRequestFormat
          );
        }
        return Promise.reject(new Error('No timecards present'));
      case 'loaded-timer':
        if (timecards.length > 0) {
          return submitTimerTimecardForm(
            timecards[0].id,
            e as TimeBasedTimeCardRequestFormat
          );
        }
        return Promise.reject(new Error('No timecards present'));
      default:
        return Promise.reject(new Error('NOT IMPLEMENTED'));
    }
  };

  const _renderForm = useMemo(() => {
    switch (inspectorState) {
      case 'loaded-hours-batch':
        return (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {authContext.user && timecards.length > 0 && timecards[0].batchId && (
              <EditHoursTimeCardForm
                setFormDirty={(e) => setFormHasChanges(e)}
                handleNewTimecardCreated={setHasNewTimecard}
                disableOverlay={geoInfo.geoError}
                {...commonProps}
                getEmployees={
                  allowEmployeeSelection
                    ? () =>
                        getEmployees({
                          disablePaging: true,
                          orderBy: { active: true, employeeNumber: false },
                        }).then((e) => {
                          if (
                            authContext.hasAnyPermission([
                              Permission.Add_Admin_Timecards,
                            ])
                          ) {
                            return e.records;
                          }

                          if (approverCanManageTime) {
                            return e.records.filter((b) =>
                              authContext.canApprove(b.id)
                            );
                          }

                          return [];
                        })
                    : authContext.user
                }
                fieldCaptions={{
                  employeeId: t('Entities.Employee.Employee'),
                  hourFactor: t('Entities.Timecard.hourFactor'),
                  hourRate: t('Entities.Timecard.hourRate'),
                  comments: t('forms.fields.comments'),
                  custom1: t('Entities.Timecard.TimecardCustom1'),
                  custom2: t('Entities.Timecard.TimecardCustom2'),
                  custom3: t('Entities.Timecard.TimecardCustom3'),
                  custom4: t('Entities.Timecard.TimecardCustom4'),
                  custom5: t('Entities.Timecard.TimecardCustom5'),
                  custom6: t('Entities.Timecard.TimecardCustom6'),
                  custom7: t('Entities.Timecard.TimecardCustom7'),
                  custom8: t('Entities.Timecard.TimecardCustom8'),
                  pSCCustom1: t(
                    'Entities.Process Schedule In House Transaction.CompletedLogCustom1'
                  ),
                  pSCCustom2: t(
                    'Entities.Process Schedule In House Transaction.CompletedLogCustom2'
                  ),
                  pSCCustom3: t(
                    'Entities.Process Schedule In House Transaction.CompletedLogCustom3'
                  ),
                  pSCCustom4: t(
                    'Entities.Process Schedule In House Transaction.CompletedLogCustom4'
                  ),
                  pSCCustom5: t(
                    'Entities.Process Schedule In House Transaction.CompletedLogCustom5'
                  ),
                  pSCCustom6: t(
                    'Entities.Process Schedule In House Transaction.CompletedLogCustom6'
                  ),
                  pSCCustom7: t(
                    'Entities.Process Schedule In House Transaction.CompletedLogCustom7'
                  ),
                  pSCCustom8: t(
                    'Entities.Process Schedule In House Transaction.CompletedLogCustom8'
                  ),
                  hourTypeId: t('Entities.HourType.HourType'),
                  hour: t('Entities.Timecard.totalHours'),
                  specId: t('Entities.Machine.Job'),
                  nonConformanceId: t('Entities.NonConformance.NonConformance'),
                  processScheduleDetailId: t(
                    'Entities.ProcessScheduleDetail.ProcessScheduleDetail'
                  ),
                  projectId: t('Entities.Project.Project'),
                  quantity: t(
                    'Entities.Process Schedule In House Transaction.quantity'
                  ),
                }}
                timecards={timecards}
                batchId={timecards[0].batchId}
                weekStart={startDayOfWeek}
                onSubmit={(e) =>
                  _submit(e)
                    .then(() => {
                      _onUpdated();
                    })
                    .catch((a) => {
                      parseServerResponse(
                        a,
                        (errors) => _batchedErrors(errors),
                        (error) => _onError(error)
                      );
                    })
                }
                fieldsDisableEditable={
                  geoInfo.geoError || {
                    employeeId: disableEmployee,
                    hour: true,
                  }
                }
              />
            )}
          </>
        );
      case 'loaded-hours-singular':
        return (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {authContext.user && timecards.length > 0 && !timecards[0].batchId && (
              <EditHourTimeCardForm
                setFormDirty={(e) => setFormHasChanges(e)}
                {...commonProps}
                getEmployees={
                  allowEmployeeSelection
                    ? () =>
                        getEmployees({
                          disablePaging: true,
                          orderBy: { active: true, employeeNumber: false },
                        }).then((e) => {
                          if (
                            authContext.hasAnyPermission([
                              Permission.Add_Admin_Timecards,
                            ])
                          ) {
                            return e.records;
                          }

                          if (approverCanManageTime) {
                            return e.records.filter((b) =>
                              authContext.canApprove(b.id)
                            );
                          }

                          return [];
                        })
                    : authContext.user
                }
                fieldCaptions={{
                  employeeId: t('Entities.Employee.Employee'),
                  comments: t('forms.fields.comments'),
                  hourFactor: t('Entities.Timecard.hourFactor'),
                  hourRate: t('Entities.Timecard.hourRate'),
                  custom1: t('Entities.Timecard.TimecardCustom1'),
                  custom2: t('Entities.Timecard.TimecardCustom2'),
                  custom3: t('Entities.Timecard.TimecardCustom3'),
                  custom4: t('Entities.Timecard.TimecardCustom4'),
                  custom5: t('Entities.Timecard.TimecardCustom5'),
                  custom6: t('Entities.Timecard.TimecardCustom6'),
                  custom7: t('Entities.Timecard.TimecardCustom7'),
                  custom8: t('Entities.Timecard.TimecardCustom8'),
                  hourTypeId: t('Entities.HourType.HourType'),
                  hour: t('forms.fields.hour'),
                  date: t('forms.fields.date'),
                  specId: t('Entities.Machine.Job'),
                  nonConformanceId: t('Entities.NonConformance.NonConformance'),
                  processScheduleDetailId: t(
                    'Entities.ProcessScheduleDetail.ProcessScheduleDetail'
                  ),
                  projectId: t('Entities.Project.Project'),
                  quantity: t(
                    'Entities.Process Schedule In House Transaction.quantity'
                  ),
                  pSCCustom1: t(
                    'Entities.Process Schedule In House Transaction.CompletedLogCustom1'
                  ),
                  pSCCustom2: t(
                    'Entities.Process Schedule In House Transaction.CompletedLogCustom2'
                  ),
                  pSCCustom3: t(
                    'Entities.Process Schedule In House Transaction.CompletedLogCustom3'
                  ),
                  pSCCustom4: t(
                    'Entities.Process Schedule In House Transaction.CompletedLogCustom4'
                  ),
                  pSCCustom5: t(
                    'Entities.Process Schedule In House Transaction.CompletedLogCustom5'
                  ),
                  pSCCustom6: t(
                    'Entities.Process Schedule In House Transaction.CompletedLogCustom6'
                  ),
                  pSCCustom7: t(
                    'Entities.Process Schedule In House Transaction.CompletedLogCustom7'
                  ),
                  pSCCustom8: t(
                    'Entities.Process Schedule In House Transaction.CompletedLogCustom8'
                  ),
                }}
                timecards={timecards}
                weekStart={startDayOfWeek}
                onSubmit={(
                  e:
                    | TimeBasedTimeCardRequestFormat
                    | HoursTimeCardRequestFormat
                    | HourTimeCardRequestFormat
                ) =>
                  _submit(e).then(() => {
                    _onUpdated();
                  })
                }
                fieldsDisableEditable={
                  geoInfo.geoError || {
                    employeeId: disableEmployee,
                  }
                }
              />
            )}
          </>
        );
      case 'loaded-timer':
        return (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {authContext.user &&
              timecards.length > 0 &&
              !timecards[0].batchId &&
              timecards[0].startTime && (
                <EditTimeBasedTimeCardForm
                  setFormDirty={(e) => setFormHasChanges(e)}
                  getEmployees={
                    allowEmployeeSelection
                      ? () =>
                          getEmployees({
                            disablePaging: true,
                            orderBy: { active: true, employeeNumber: false },
                          }).then((e) => {
                            if (
                              authContext.hasAnyPermission([
                                Permission.Add_Admin_Timecards,
                              ])
                            ) {
                              return e.records;
                            }

                            if (approverCanManageTime) {
                              return e.records.filter((b) =>
                                authContext.canApprove(b.id)
                              );
                            }

                            return [];
                          })
                      : authContext.user
                  }
                  {...commonProps}
                  fieldCaptions={{
                    employeeId: t('Entities.Employee.Employee'),
                    comments: t('forms.fields.comments'),
                    hourFactor: t('Entities.Timecard.hourFactor'),
                    hourRate: t('Entities.Timecard.hourRate'),
                    custom1: t('Entities.Timecard.TimecardCustom1'),
                    custom2: t('Entities.Timecard.TimecardCustom2'),
                    custom3: t('Entities.Timecard.TimecardCustom3'),
                    custom4: t('Entities.Timecard.TimecardCustom4'),
                    custom5: t('Entities.Timecard.TimecardCustom5'),
                    custom6: t('Entities.Timecard.TimecardCustom6'),
                    custom7: t('Entities.Timecard.TimecardCustom7'),
                    custom8: t('Entities.Timecard.TimecardCustom8'),
                    hourTypeId: t('Entities.HourType.HourType'),
                    hour: t('Entities.Timecard.totalHours'),
                    date: t('forms.fields.date'),
                    startTime: t('forms.fields.startTime'),
                    endTime: t('forms.fields.endTime'),
                    specId: t('Entities.Machine.Job'),
                    nonConformanceId: t(
                      'Entities.NonConformance.NonConformance'
                    ),
                    processScheduleDetailId: t(
                      'Entities.ProcessScheduleDetail.ProcessScheduleDetail'
                    ),
                    projectId: t('Entities.Project.Project'),
                    quantity: t(
                      'Entities.Process Schedule In House Transaction.quantity'
                    ),
                    pSCCustom1: t(
                      'Entities.Process Schedule In House Transaction.CompletedLogCustom1'
                    ),
                    pSCCustom2: t(
                      'Entities.Process Schedule In House Transaction.CompletedLogCustom2'
                    ),
                    pSCCustom3: t(
                      'Entities.Process Schedule In House Transaction.CompletedLogCustom3'
                    ),
                    pSCCustom4: t(
                      'Entities.Process Schedule In House Transaction.CompletedLogCustom4'
                    ),
                    pSCCustom5: t(
                      'Entities.Process Schedule In House Transaction.CompletedLogCustom5'
                    ),
                    pSCCustom6: t(
                      'Entities.Process Schedule In House Transaction.CompletedLogCustom6'
                    ),
                    pSCCustom7: t(
                      'Entities.Process Schedule In House Transaction.CompletedLogCustom7'
                    ),
                    pSCCustom8: t(
                      'Entities.Process Schedule In House Transaction.CompletedLogCustom8'
                    ),
                  }}
                  timecards={timecards}
                  weekStart={startDayOfWeek}
                  onSubmit={(e) =>
                    _submit(e).then(() => {
                      _onUpdated();
                    })
                  }
                  fieldsDisableEditable={{
                    employeeId: disableEmployee,
                    hour: true,
                    startTime: !allowEmployeeSelection
                      ? !authContext.hasPermission(
                          Permission.Modify_MyTimeTracker_Timecards
                        )
                      : !authContext.hasPermission(
                          Permission.Modify_Admin_Timecards
                        ) &&
                        !(
                          approverCanManageTime &&
                          authContext.canApprove(timecards[0].employeeId)
                        ),
                    endTime: !allowEmployeeSelection
                      ? !authContext.hasPermission(
                          Permission.Modify_MyTimeTracker_Timecards
                        )
                      : !authContext.hasPermission(
                          Permission.Modify_Admin_Timecards
                        ) &&
                        !(
                          approverCanManageTime &&
                          authContext.canApprove(timecards[0].employeeId)
                        ),
                  }}
                />
                // eslint-disable-next-line
              )}
          </>
        );
      default:
        return <div />;
    }
  }, [
    inspectorState,
    authContext,
    timecards,
    geoInfo.geoError,
    commonProps,
    allowEmployeeSelection,
    t,
    startDayOfWeek,
    disableEmployee,
    approverCanManageTime,
    _submit,
    _onUpdated,
    _batchedErrors,
    _onError,
  ]);

  const _renderInspectorTitle = () => {
    switch (timecards.length) {
      case 1:
        if (timecards[0].locked) {
          return t('forms.lockedTimecards');
        }
        return t('forms.editTimecard');
      default:
        if (timecardStatus.locked) {
          return t('forms.lockedTimecards');
        }
        return t('forms.editTimecards');
    }
  };

  return (
    <TimeCardInspector
      data={timecards}
      barcodeEnabled={
        barcodeEnabled && (!timecardStatus.locked || !geoInfo.geoError)
      }
      toggleBarcode={() => setBarcodeEnabled((v) => !v)}
      open={Boolean(timecard)}
      onClose={() => {
        if (formHasChanges) {
          setNeedsCloseConfirmation(true);
        } else {
          onClose();
        }
      }}
      title={_renderInspectorTitle()}
      lockedInspector={timecardStatus.locked || geoInfo.geoError}
      headerIcon={timecardStatus.icon}
    >
      {geoInfo.geoError && (
        <Box sx={errorSx} data-testid="geo-error">
          <Typography align="left" sx={{ color: 'error.main' }}>
            {t('forms.geoLocation.addHoursError')}
          </Typography>
          <InlineHelpLink
            helpId="8637679060756"
            subSectionId="allow-access-to-your-physical-location-upon-first-visit-0-1"
            title={t('forms.geoLocation.access')}
          />
        </Box>
      )}
      {_renderForm}
      <OkCancelConfirmDialog
        onOk={() => {
          setNeedsCloseConfirmation(false);
          onClose();
        }}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onCancel={() => {
          setNeedsCloseConfirmation(false);
        }}
        title={t('dialogs.closeUnsavedForm.title')}
        content={t('dialogs.closeUnsavedForm.content')}
        open={needsCloseConfirmation}
      />
    </TimeCardInspector>
  );
};

export default EditInspector;
