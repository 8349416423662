import dayjs from 'dayjs';
import { PagedResponse, TimeCard } from 'teto-client-api';
import { v4 as uuid } from 'uuid';
import TableColumnDefinition from '../../../../components/TetoGrid/TableColumnDefinition';
import dateGrouper from '../../../../helpers/dateGrouper';
import { groupRecords } from '../../../../helpers/groupHelper';

const calculateDailyTotal = (
  index: number,
  startDayOfWeek: number,
  items: TimeCard[]
) => {
  let dayPos = index + startDayOfWeek;
  if (dayPos > 6) dayPos -= 7; // overflowed the week, need to go back to the start

  return items
    .filter(
      (a) =>
        a.date &&
        ((dayjs.isDayjs(a.date) && a.date.weekday() === dayPos) ||
          dayjs(a.date).weekday() === dayPos)
    )
    .reduce((partialSum, tc) => tc.hourTime + partialSum, 0);
};

export const createTTDesktopRecords = (
  data: PagedResponse<TimeCard>,
  columnList: string[],
  settingsStartDayOfWeek: number
) => {
  if (data.totalRecords > 0) {
    const records = groupRecords(columnList, data.records).map((g) => {
      const grouping: (
        | string
        | number
        | boolean
        | dayjs.Dayjs
        | string[]
        | null
        | undefined
      )[][] = [];
      g?.groupColumns?.forEach((e) =>
        grouping.push([[e], g.items[0][e as keyof TimeCard]])
      );

      const daysHours = {
        0: calculateDailyTotal(0, settingsStartDayOfWeek, g.items),
        1: calculateDailyTotal(1, settingsStartDayOfWeek, g.items),
        2: calculateDailyTotal(2, settingsStartDayOfWeek, g.items),
        3: calculateDailyTotal(3, settingsStartDayOfWeek, g.items),
        4: calculateDailyTotal(4, settingsStartDayOfWeek, g.items),
        5: calculateDailyTotal(5, settingsStartDayOfWeek, g.items),
        6: calculateDailyTotal(6, settingsStartDayOfWeek, g.items),
      };

      return {
        grouping,
        totalHours: g.items.reduce((cum, rec) => cum + rec.hourTime, 0),
        items: g.items,
        ...daysHours,
        maxExceeded: data.maxExceeded,
        id: uuid(),
        count: g.items.length,
      };
    });
    return { ...data, records };
  }
  return data;
};

export const createMobileTTRecords = (
  data: PagedResponse<TimeCard>,
  columnList: string[],
  startDate: Date
) => {
  const records = dateGrouper(
    columnList as string[],
    startDate,
    data.records
  ).map((e) => {
    const group: Record<string, unknown>[] = [];
    e.groups.forEach((f) => {
      const totalHours = f.items.reduce(
        (s, i) => s + (i?.actualHours as number),
        0
      );
      group.push({ ...f, totalHours });
    });

    return {
      date: dayjs(e.date),
      mobileItems: group,
      totalHours: e.totalHours,
      count: e.groups.length,
      maxExceeded: data.maxExceeded,
      id: uuid(),
    };
  });
  return { records, maxExceeded: data.maxExceeded, id: uuid() };
};

export const sortGridColumnsByOrder = (
  columns: TableColumnDefinition[],
  order: string[]
) =>
  columns.sort((a, b) => {
    const aIndex = order.indexOf(a.name);
    const bIndex = order.indexOf(b.name);
    if (aIndex === -1) {
      return 1;
    }
    if (bIndex === -1) {
      return -1;
    }
    if (aIndex < bIndex) return -1;
    if (aIndex > bIndex) return 1;
    return 0;
    // eslint-disable-next-line array-callback-return
  });
