import React from 'react';
import { ETOTextField, ETOTextFieldProps } from '@teto/react-component-library';
import { SxProps } from '@mui/material/styles';
import StandardEditorWrapper from '../EditorWrappers/StandardEditorWrapper';
import InlineEditorProps from '../InlineEditingProps';

const cellSx: SxProps = {
  '& .InovuaReactDataGrid__cell__editor InovuaReactDataGrid__cell__editor--string':
    {},
};

export type ETOStringEditorProps = InlineEditorProps &
  Partial<Omit<ETOTextFieldProps, 'value'>>;

const ETOStringEditor: React.FC<ETOStringEditorProps> = (props) => {
  const { cellProps, multiline, maxRows } = props;

  return (
    <StandardEditorWrapper
      {...props}
      child={
        <ETOTextField
          multiline={multiline ?? false}
          name={cellProps?.name as string}
          maxRows={maxRows ?? 1}
        />
      }
      customCellSx={cellSx}
    />
  );
};

export default ETOStringEditor;
